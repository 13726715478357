import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button, Layout, Menu, Spin, Tooltip } from 'antd';
import { RightOutlined, LeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { HOME_MENU, HOME_MENU_GROUP, ROLE_TENANT_OWNER, TENANT_KEY_TRIAL } from '../../constants';
import { useMenu, buildSubPathNameList, convertToNestedMenu, convertNestedMenuToGroupMenu } from '../../common';

import './style.scss';

/**
 * Organization Settings
 */
export const HomeSidebar = () => {
  const location = useLocation();

  // For language
  const [t] = useTranslation('akaat');

  // For menu
  const { convertGroupMenuToAntMenuItems } = useMenu();

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const loadingGlobalUserInfo = useStoreState(state => state.global.loadingGlobalUserInfo);

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // Component state
  const [menus, setMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  /**
   * Set menus
   */
  useEffect(() => {
    const userPermissions =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.length
        ? [...globalUserInfo?.permissions]
        : [];

    const allMenus = HOME_MENU.filter(item => {
      if (item.isHideOnSidebar) return false;
      if (item.permission && !userPermissions.includes(item.permission)) return false;
      if (Array.isArray(item.permissions) && !userPermissions.some(p => item.permissions.includes(p))) return false;
      if (item.id === 'subscription' && globalTenant?.tenantKey === TENANT_KEY_TRIAL) return false;
      if (item.id === 'subscription' && globalTenant?.currentUserRole?.roleKey !== ROLE_TENANT_OWNER) return false;
      return true;
    });
    const nestedMenu = convertToNestedMenu(allMenus, null);
    const groupMenu = convertNestedMenuToGroupMenu(nestedMenu, HOME_MENU_GROUP);

    const newMenus = convertGroupMenuToAntMenuItems({
      groupMenu,
      restSubMenu: { popupClassName: 'c-left-sidebar-sub-menu-popup' }
    });

    setMenus(newMenus);
  }, [location, globalTenant, globalUserInfo]);

  /**
   * Set open keys when expand sidebar menus
   */
  useEffect(() => {
    if (!(Array.isArray(menus) && menus.length)) return;

    if (collapsed) {
      setOpenKeys([]);
      return;
    }

    const allOpenKeys = [];

    menus.forEach(group => {
      if (Array.isArray(group.children) && group.children.length) {
        group.children.forEach(item => {
          allOpenKeys.push(item.key);
        });
      }
    });

    setTimeout(() => {
      setOpenKeys(allOpenKeys);
    }, 300);
  }, [menus, collapsed, location.pathname]);

  /**
   * Render aciton bottom
   */
  const renderActionBottom = () => {
    return (
      <div
        className="box-toggle-collapse"
        style={{ width: collapsed ? 80 : 220 }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Tooltip
          title={collapsed ? t('common.expand') : t('common.collapse')}
          placement="right"
          destroyTooltipOnHide={true}
        >
          <Button
            type="link"
            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
            className="btn-toggle-collapse text-hover-primary"
          ></Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      theme="light"
      width={220}
      className="c-left-sidebar"
      onCollapse={() => setCollapsed(!collapsed)}
    >
      {!globalUserInfo?.id && <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingGlobalUserInfo} />}

      <Menu
        items={menus}
        selectedKeys={buildSubPathNameList(location.pathname)}
        openKeys={openKeys}
        mode="inline"
        theme="light"
        onOpenChange={setOpenKeys}
      />

      {renderActionBottom()}
    </Layout.Sider>
  );
};
