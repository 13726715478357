import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState, useStoreActions } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE } from '../../../../constants';
import {
  debounce,
  useEditableCell,
  getObjectByValue,
  useUnassignedUser,
  reactSessionStorage,
  convertUserForSubmitData,
  handleSetLastSavedValueToSession
} from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { UserAvatar } from '../../../user-avatar';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import JiraAssignToFormItem from './jira-assign-to-form-item';
import JiraAssignToKeyboardEvents from './jira-assign-to-keyboard-events';

const JiraAssignToField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  const timerRef = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // Use editable cell
  const { getLastUserOptionInSessionStorage } = useEditableCell();

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For jira integration
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastOption = lastSavedValue[formItemName];

    let originValue = objectPath.get(row, field?.refName);
    originValue = originValue?.id || originValue?.id !== UNASSIGNED.value ? originValue : null;

    return lastOption !== undefined ? lastOption?.status : originValue;
  }, [row, field, formItemName, isOpenDropdown]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const newUserList = Array.isArray(userList) && userList.length ? [...userList] : [];

    let newOptions = newUserList.map(item => {
      return {
        label: <UserAvatar user={item} />,
        value: item?.id,
        user: item,
        disabled: item?.id === val?.id
      };
    });

    if (val?.id && !newOptions.some(item => item?.value === val?.id)) {
      const currentOption = {
        label: <UserAvatar user={val} />,
        value: val?.id,
        user: val,
        disabled: true
      };

      newOptions = [currentOption, ...newOptions];
    }

    newOptions = [UNASSIGNED, ...newOptions];

    return newOptions;
  }, [val, row, field, userList]);

  /**
   * Handle get user list
   */
  const handleGetUserList = async keyword => {
    try {
      if (!row?.source?.jira?.id) return;

      setLoading(true);

      const res = await getAssignableUserList({
        username: keyword
      });

      setUserList(res);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  /**
   * On search
   */
  const onSearch = async keyword => {
    if (!isOpenDropdown) return;

    // Reset
    setUserList([]);

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(
      debounce(() => handleGetUserList(keyword)),
      300
    );
  };

  /**
   * When dropdown is open: Handle get list states
   */
  useEffect(() => {
    if (isOpenDropdown) {
      setUserList([]);
      setTimeout(() => onSearch(''), 100);
    }
  }, [isOpenDropdown]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) return null;

    const val = objectPath.get(row, field?.refName);

    return getObjectByValue(val?.id, options);
  }, [row, field, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastOption = getLastUserOptionInSessionStorage({ formItemName });
    return lastOption !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value === UNASSIGNED.value && !defaultOption?.value) {
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newVal = convertUserForSubmitData(opt?.user) || { username: '' };

      handleSetLastSavedValueToSession({ [formItemName]: newVal });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: newVal }, row, field });
      }
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    options,
    autoFocus: true,
    showSearch: true,
    allowClear: false,
    filterOption: false,
    loading,
    placeholder: placeholder || t('common.searchEmail'),
    disabled: isReadOnly,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSearch,
    onSelect: (val, option) => handleSave(option),
    onClear: () => handleSave(null),
    onClick: () => handleOpen()
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const option = getCurrentOption(currentOption);
    const label = option?.value ? <UserAvatar user={option?.user} /> : UNASSIGNED?.label;

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div
          className={`cell-text cursor-text ${editingCell?.x && editingCell?.y ? '' : 'hide-after'}`}
          onClick={handleOpen}
        >
          <span className="cell-text-value min-h-22 text-truncate">{label || props?.placeholder}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink row={row} field={field}>
          {label}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {label}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return label;
    }

    // Readonly
    else {
      return (
        <div className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{label}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <JiraAssignToFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <JiraAssignToKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText()}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper status-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default JiraAssignToField;
