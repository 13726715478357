import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../common';

export default {
  /**
   * State
   */
  searchFieldList: null,
  loadingSearchFieldList: false,

  /**
   * Action: Set field list for search
   */
  setSearchFieldList: action((state, payload) => {
    if (!(state?.searchFieldList !== undefined)) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.searchFieldList = [];
      return;
    }

    state.searchFieldList = payload;
  }),

  /**
   * Action: Set loading field list for search
   */
  setLoadingSearchFieldList: action((state, payload) => {
    if (!(state?.loadingSearchFieldList !== undefined)) return;

    state.loadingSearchFieldList = payload;
  }),

  /**
   * Action: Call api to get field list for search
   */
  getSearchFieldList: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get search field list: Invalid Payload');
      }

      action.setLoadingSearchFieldList(true);

      const { typeOfField, projectId, tenantId } = payload;
      const url = `${ENDPOINTS.SEARCH_GET_FIELD}/tenant-${tenantId}/project-${projectId}?type=${typeOfField}`;
      const res = await Http.get(url).then(res => res.data);

      if (!res) action.setSearchFieldList([]);

      action.setSearchFieldList(res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSearchFieldList(false);
    }
  })
};
