import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button, Layout, Menu, Spin, Tooltip } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  ApartmentOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';

import env from '../../env';
import { buildSubPathNameList } from '../../common';

import './style.scss';

export const TenantListSidebar = () => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];

  // For language
  const [t] = useTranslation('akaat');

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const loadingGlobalUserInfo = useStoreState(state => state.global.loadingGlobalUserInfo);

  // For global tenant store
  const myTenantList = useStoreState(state => state.global.myTenantList);

  // Component state
  const [menus, setMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);

  /**
   * Set menus
   */
  useEffect(() => {
    if (!(Array.isArray(myTenantList) && myTenantList.length)) return;

    // For homepage (Example: location.pathname === '/tenant-key')
    const newMenus = myTenantList.map(item => {
      const path = `/${env.REACT_APP_PREFIX_PATH}${item?.tenantKey}`;

      return {
        key: path,
        id: `menu-key-${item?.tenantKey}`,
        label: (
          <a href={path} title={t(`${item?.name}`)} className="title">
            {t(`${item?.name}`)}
          </a>
        ),
        disabled: !item?.active,
        icon: <ApartmentOutlined />
      };
    });

    setMenus(newMenus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTenantList]);

  /**
   * Render aciton bottom
   */
  const renderActionBottom = () => {
    return (
      <div
        className="box-toggle-collapse"
        style={{ width: collapsed ? 80 : 220 }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Tooltip
          title={collapsed ? t('header.organizationSettings') : null}
          placement="right"
          destroyTooltipOnHide={true}
        >
          <a
            href={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/tenant-overview`}
            title={!collapsed ? t('header.organizationSettings') : null}
            className="btn-link text-hover-primary"
            onClick={e => e.stopPropagation()}
          >
            <SettingOutlined />
            <span className="text-truncate">{collapsed ? null : t('header.organizationSettings')}</span>
          </a>
        </Tooltip>

        <Tooltip
          title={collapsed ? t('common.expand') : t('common.collapse')}
          placement="right"
          destroyTooltipOnHide={true}
        >
          <Button
            type="link"
            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
            className="btn-toggle-collapse text-hover-primary"
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      theme="light"
      width={220}
      className="c-left-sidebar"
      onCollapse={() => setCollapsed(!collapsed)}
    >
      {!globalUserInfo?.id && <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingGlobalUserInfo} />}

      <Menu
        items={menus}
        selectedKeys={buildSubPathNameList(location.pathname)}
        openKeys={openKeys}
        mode="inline"
        theme="light"
        onOpenChange={setOpenKeys}
      />

      {renderActionBottom()}
    </Layout.Sider>
  );
};
