import { Button, Divider, Form, Modal, Row, Spin, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { SaveOutlined, FullscreenOutlined, FullscreenExitOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { arrayMoveImmutable } from 'array-move';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_NAME,
  SS_NEW_TEST_STEPS,
  TESTMAN_PLATFORM_ID,
  SYSTEM_FIELD_ORDER_ID,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_ATTACHMENTS,
  SYSTEM_FIELD_DESCRIPTION,
  SYSTEM_FIELD_REFERENCE_FOLDER,
  INTEGRATION_FROM_TEST_PLAN_FOLDER,
  INTEGRATION_FROM_REPOSITORY_FOLDER
} from '../../constants';
import {
  useFile,
  jsonParse,
  reactSessionStorage,
  handleBeforeSubmitForm,
  scrollToFirstClassName,
  convertUserForSubmitData,
  convertEstimatedTimeToMinutes
} from '../../common';
import { BasicPasteUploadFile } from '../basic-paste-upload-file';
import { WorkItemField } from '../work-item-field';

const RelatedToAddWorkTicketModal = ({
  fromModule,
  visible,
  relation,
  className = '',
  onCancel,
  onPrevious,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For upload
  const { getUploadPath } = useFile();

  // For global store
  const loadingObjectiveItem = useStoreState(state => state.global.loadingObjectiveItem);
  const fieldListData = useStoreState(state => state.global.fieldList)?.[relation?.workTicketType];
  const loadingRelationItem = useStoreState(state => state.global.loadingRelationItem);
  const loadingFieldList = useStoreState(state => state.global.loadingFieldList);
  const getFieldList = useStoreActions(action => action.global.getFieldList);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);

  // For store action
  const createTestCase = useStoreActions(action => action.testcase.createTestCase);
  const getRepositoryList = useStoreActions(action => action.repositoryTree.getRepositoryList);
  const createWorkTicket = useStoreActions(action => action.managerWorkItem.createWorkTicket);
  const loadingTestCaseItem = useStoreState(state => state.testcase.loadingItem);
  const loadingWorkTicketItem = useStoreState(state => state.managerWorkItem.loadingWorkTicketItem);

  // Component state
  const [workTicket, setWorkTicket] = useState({});
  const [currentTestStepList, setCurrentTestStepList] = useState([]);
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]);
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [currentEditorAttachFileList, setCurrentEditorAttachFiles] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Set work ticket
   */
  useEffect(() => {
    if (!Array.isArray(globalProcessList) || !globalProcessList.length || !relation?.workTicketType) return;

    const tickeType = globalProcessList.find(process => process?.workTicketId === relation.workTicketType);

    setWorkTicket(tickeType);
  }, [globalProcessList, relation]);

  /**
   * Compute: field list
   */
  const fieldList = useMemo(() => {
    if (!(Array.isArray(fieldListData) && fieldListData.length)) return;

    const attachments = {
      name: 'Attachments',
      refName: SYSTEM_FIELD_ATTACHMENTS,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      type: FIELD_SYSTEM,
      isInsert: true
    };

    let newFieldList = [...fieldListData, attachments];

    if (newFieldList.some(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS)) {
      const oldIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS);
      const descriptionFieldIndex = newFieldList.findIndex(field => field.refName === SYSTEM_FIELD_DESCRIPTION);

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, descriptionFieldIndex + 1);
    }

    return newFieldList;
  }, [fieldListData]);

  /**
   * Set initial primitive field
   */
  useEffect(() => {
    if (!relation) return;

    form.setFieldsValue({
      [SYSTEM_FIELD_NAME]: relation.item
    });
  }, [form, relation]);

  /**
   * Get field list and foder test case
   */
  useEffect(() => {
    if (relation?.workTicketType === WORK_ITEM_TESTCASE_ID) {
      getRepositoryList();
    }

    if (!relation?.workTicketType || Array.isArray(fieldList) || fieldList?.length || fieldList) return;

    getFieldList(relation.workTicketType);
  }, [relation, fieldList, getFieldList, getRepositoryList]);

  /**
   * On change current attachment list
   */
  const onChangeCurrentAttachmentList = async attachments => {
    await getFullFilesInfo(attachments);
    setCurrentAttachmentList(attachments);
  };

  /**
   * On attach file
   */
  const onEditorAttachFiles = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) return;

    const newList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList, ...attachments]
        : [...attachments];

    setCurrentEditorAttachFiles(newList);
  };

  /**
   * On search
   */
  const hanldeSubmit = async values => {
    if (!relation?.relationType) return;

    if (!values || loadingWorkTicketItem || loadingTestCaseItem) return;

    const formData = {
      workTicketType: relation?.workTicketType
    };

    // Push system field to form data
    Object.keys(values).forEach(key => {
      const field = fieldList.find(item => item?.refName === key);

      if (values[key] && field && field.refName) {
        switch (field?.refName) {
          case SYSTEM_FIELD_TAG: {
            if (Array.isArray(values[key]) && values[key].length) {
              formData[field.refName] = values[key].join(',');
            }
            break;
          }
          case SYSTEM_FIELD_REFERENCE_FOLDER: {
            formData[field.refName] = values[key];
            break;
          }

          default: {
            switch (field?.componentType) {
              case COMPONENT_TYPE.SUGGESTION: {
                formData[field.refName] = jsonParse(values[key]);
                break;
              }

              case COMPONENT_TYPE.PICKLIST: {
                formData[field.refName] = values[key];
                break;
              }

              case COMPONENT_TYPE.USER: {
                if (Array.isArray(projectUserList) && projectUserList.length) {
                  const user = projectUserList.find(u => u?.username === values[key]);
                  formData[field.refName] = convertUserForSubmitData(user);
                }

                break;
              }

              case COMPONENT_TYPE.TIME_TRACKING: {
                formData[field.refName] = convertEstimatedTimeToMinutes(values[key]);
                break;
              }

              case COMPONENT_TYPE.DATE:
              case COMPONENT_TYPE.DATE_TIME: {
                formData[field.refName] = moment(values[key]).format();
                break;
              }

              case COMPONENT_TYPE.NUMBER: {
                formData[field.refName] = +values[key];
                break;
              }

              default: {
                formData[field.refName] = values[key];
                break;
              }
            }
          }
        }
      }
    });

    // Test steps
    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    let newSessionTestStepList = newTestStepsInSession['new'];
    newSessionTestStepList =
      Array.isArray(newSessionTestStepList) && newSessionTestStepList.length ? [...newSessionTestStepList] : [];

    const testStepList =
      Array.isArray(currentTestStepList) && currentTestStepList.length ? [...currentTestStepList] : [];

    formData[SYSTEM_FIELD_TEST_STEPS] = [...testStepList, ...newSessionTestStepList].map((s, idx) => {
      const newStep = { ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 };
      delete newStep.key;
      delete newStep.isNew;
      return newStep;
    });

    // Attachments
    const attachmentList =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : [];

    const editorAttachFileList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList]
        : [];

    formData[SYSTEM_FIELD_ATTACHMENTS] = [...attachmentList, ...editorAttachFileList];

    let res = null;
    if (relation?.workTicketType === WORK_ITEM_TESTCASE_ID) {
      const resTestCase = await createTestCase(formData);
      res = resTestCase?.data;
    } else {
      {
        res = await createWorkTicket(formData);
      }
    }

    if (res?.key) {
      const formData = { ...res };

      if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
        formData.integrationSystem = TESTMAN_PLATFORM_ID;
      }

      onSubmit(formData);
    }

    // Handle delete multiple attachments
    if (Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length) {
      deleteFiles({ globalTenant, globalProject, ids: [...deteledAttachmentIds] });
      setDeteledAttachmentIds([]);
    }
  };

  return (
    <>
      <Modal
        open={visible}
        width={1160}
        maskClosable={false}
        keyboard={false}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header"
        className={`c-related-to-add-work-ticket-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">
              {t('relationTo.relateToSomething', { name: workTicket?.name })}
            </h3>

            <Row align="middle" justify="end" wrap={false} className="box-extra">
              <Tooltip
                open={visibleFullScreenTooltip}
                title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                placement={fullScreen ? 'bottomRight' : 'bottom'}
                destroyTooltipOnHide={true}
                overlayStyle={{ pointerEvents: 'none' }}
                onOpenChange={setVisibleFullScreenTooltip}
              >
                <Button
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  type="link"
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                    setVisibleFullScreenTooltip(false);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <Spin
              indicator={<Loading3QuartersOutlined spin />}
              spinning={loadingFieldList || loadingRelationItem || loadingWorkTicketItem || loadingObjectiveItem}
            >
              <Form
                id="form-add-work-ticket"
                form={form}
                layout="vertical"
                onFinish={value => {
                  setTimeout(() => scrollToFirstClassName('ant-form-item-has-error', 0), 300);
                  hanldeSubmit(value);
                }}
              >
                {Array.isArray(fieldList) &&
                  fieldList.length &&
                  fieldList
                    .filter(field => field.isInsert)
                    .map((field, index) => {
                      return (
                        <WorkItemField
                          workTicketId={relation?.workTicketType}
                          key={index}
                          form={form}
                          field={field}
                          currentTestStepList={currentTestStepList}
                          onChangeCurrentTestStepList={setCurrentTestStepList}
                          showRepositoryFolderField={true}
                          uploadPath={getUploadPath(relation?.workTicketType)}
                          currentAttachmentList={currentAttachmentList}
                          onChangeCurrentAttachmentList={setCurrentAttachmentList}
                          onChangeDeteledAttachmentIds={ids =>
                            setDeteledAttachmentIds([...deteledAttachmentIds, ...ids])
                          }
                          onEditorAttachFiles={onEditorAttachFiles}
                        />
                      );
                    })}
              </Form>
            </Spin>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button onClick={onPrevious}>{t('common.previous')}</Button>

            <Button
              form="form-add-work-ticket"
              htmlType="submit"
              className="ml-2"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => handleBeforeSubmitForm(form)}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      </Modal>

      {visible && (
        <BasicPasteUploadFile
          attachments={currentAttachmentList}
          acceptMineTypes={['image/']}
          uploadPath={getUploadPath(relation?.workTicketType)}
          onChangeAttachments={onChangeCurrentAttachmentList}
        />
      )}
    </>
  );
};

export default RelatedToAddWorkTicketModal;
