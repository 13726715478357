import { Button, Divider, Form, Modal, Row, Spin, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  EditOutlined,
  SaveOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'use-query-params';
import objectPath from 'object-path';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { arrayMoveImmutable } from 'array-move';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_ATTACHMENTS,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_DESCRIPTION,
  SYSTEM_FIELD_NAME,
  SYSTEM_FIELD_TEST_STEPS,
  FIELD_TEST_STEP_RAW,
  SYSTEM_FIELD_REFERENCE_FOLDER,
  AUTOMATION_STATUS,
  SYSTEM_FIELD_AUTOMATION_STATUS,
  SS_NEW_TEST_STEPS,
  SYSTEM_FIELD_ORDER_ID,
  SYSTEM_FIELD_VERSION,
  REST_API_STATUS_CODE
} from '../../../../constants';
import {
  useFile,
  jsonParse,
  useEditableCell,
  reactSessionStorage,
  scrollToFirstClassName,
  convertUserForSubmitData,
  findItemAndParentsOnTree,
  trackEventAzureWithDuration,
  convertEstimatedTimeToMinutes,
  checkTicketTypeForRelationField
} from '../../../../common';
import useTestcase from '../hooks/use-testcase';
import { PlusCircle } from '../../../../assets/svg-icons';
import { BasicPasteUploadFile } from '../../../../components';
import { WorkItemField } from '../../../../components/work-item-field';

export default function TestCaseModal({
  fromModule = 'ADD_MODAL',
  editingTestCase = null,
  visible,
  isReadOnly,
  className = '',
  onAdded,
  onUpdated,
  onCancel,
  ...rest
}) {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For query params on url
  const [queryParams] = useQueryParams({
    treeKey: StringParam
  });

  // For hooks
  const appInsights = useAppInsightsContext();
  const { getUploadPath } = useFile();
  const { handleGetFieldValue } = useEditableCell();
  const { getAllFilesInfoForTestcase } = useTestcase();

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);

  // For repository tree
  const tree = useStoreState(state => state.repositoryTree.tree);

  // For testcase
  const createTestCase = useStoreActions(action => action.testcase.createTestCase);
  const updateTestCase = useStoreActions(action => action.testcase.updateTestCase);
  const updateTestCaseVersion = useStoreActions(action => action.testcase.updateTestCaseVersion);
  const loadingItem = useStoreState(state => state.testcase.loadingItem);
  const setTestStepRawInfo = useStoreActions(action => action.testcase.setTestStepRawInfo);
  const testStepRawInfo = useStoreState(state => state.testcase.testStepRawInfo);

  // For test case version store
  const createTestCaseVersion = useStoreActions(action => action.testcaseVersion.createTestCaseVersion);

  // For global store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  // Component state
  const [currentTestStepList, setCurrentTestStepList] = useState([]);
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]);
  const [isGetAllFullFileInfo, setIsGetAllFullFileInfo] = useState(false);
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [currentEditorAttachFileList, setCurrentEditorAttachFiles] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Compute: field list
   */
  const fieldList = useMemo(() => {
    const fields = ticketListData?.[WORK_ITEM_TESTCASE_ID]?.fields;

    if (!(Array.isArray(fields) && fields.length)) return;

    const attachments = {
      name: 'Attachments',
      refName: SYSTEM_FIELD_ATTACHMENTS,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      type: FIELD_SYSTEM,
      isInsert: true
    };

    let newFieldList = [...fields, attachments].map(field => {
      let currentValue = objectPath.get(editingTestCase, field.refName);

      if (field.componentType === COMPONENT_TYPE.TEST_STEPS) {
        field.isInsert = true;
      }

      return { ...field, currentValue };
    });

    if (newFieldList.some(field => field.componentType === COMPONENT_TYPE.TEST_STEPS)) {
      const oldIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.TEST_STEPS);
      const descriptionFieldIndex = newFieldList.findIndex(field => field.refName === SYSTEM_FIELD_DESCRIPTION);
      const nameFieldIndex = newFieldList.findIndex(field => field.refName === SYSTEM_FIELD_NAME);
      const targetIndex = descriptionFieldIndex !== -1 ? descriptionFieldIndex : nameFieldIndex;

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, targetIndex + 1);
    }

    if (newFieldList.some(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS)) {
      const oldIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS);
      const testStepsIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.TEST_STEPS);

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, testStepsIndex + 1);
    }

    return newFieldList;
  }, [ticketListData, editingTestCase]);

  /**
   * Set value for edit form
   */
  useEffect(() => {
    if (
      !editingTestCase ||
      !ticketListData ||
      !(Array.isArray(fieldList) && fieldList.length) ||
      !checkTicketTypeForRelationField({ fieldList, ticketListData })
    ) {
      return;
    }

    fieldList.forEach(field => {
      if (
        field?.isInsert &&
        field?.componentType !== COMPONENT_TYPE.TEST_STEPS &&
        field?.componentType !== COMPONENT_TYPE.ATTACHMENTS
      ) {
        const val = handleGetFieldValue({ field, item: editingTestCase, ticketListData });

        form.setFieldsValue({ [field?.refName]: val });
      }
    });
  }, [editingTestCase, fieldList, form, ticketListData]);

  /**
   * Get all full files info
   * Set current test step list
   * Set current attachment list
   */
  useEffect(() => {
    if (!editingTestCase?.key) return;

    (async () => {
      let newTestSteps = objectPath.get(editingTestCase, SYSTEM_FIELD_TEST_STEPS);
      newTestSteps = Array.isArray(newTestSteps) && newTestSteps.length ? [...newTestSteps] : [];

      let newTestcaseAttachments = objectPath.get(editingTestCase, SYSTEM_FIELD_ATTACHMENTS);
      newTestcaseAttachments =
        Array.isArray(newTestcaseAttachments) && newTestcaseAttachments.length ? [...newTestcaseAttachments] : [];

      if (!isGetAllFullFileInfo) {
        await getAllFilesInfoForTestcase(editingTestCase);
        setCurrentTestStepList(newTestSteps);
        setCurrentAttachmentList(newTestcaseAttachments);
        setIsGetAllFullFileInfo(true);
      }
    })();
  }, [editingTestCase, isGetAllFullFileInfo]);

  /**
   * On change current attachment list
   */
  const onChangeCurrentAttachmentList = async attachments => {
    await getFullFilesInfo(attachments);
    setCurrentAttachmentList(attachments);
  };

  /**
   * On attach file
   */
  const onEditorAttachFiles = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) return;

    const newList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList, ...attachments]
        : [...attachments];

    setCurrentEditorAttachFiles(newList);
  };

  /**
   * Handle submit testcase
   */
  const onSubmitTestCase = async values => {
    if (
      !values ||
      (fromModule == 'ADD_MODAL' && !queryParams?.treeKey) ||
      (fromModule == 'ADD_MODAL' && !(Array.isArray(tree) && tree.length)) ||
      !(Array.isArray(fieldList) && fieldList.length)
    ) {
      return;
    }

    const treeItem = queryParams?.treeKey ? findItemAndParentsOnTree(tree, queryParams?.treeKey) : null;

    let formData = {};

    if (editingTestCase?.key) {
      formData = {
        key: editingTestCase?.key
      };
    } else {
      formData = {
        [SYSTEM_FIELD_REFERENCE_FOLDER]: treeItem?.item?._id
      };
    }

    // Push system field to form data
    Object.keys(values).forEach(key => {
      const field = fieldList.find(item => item?.refName === key);

      if (values[key] && field && field.refName) {
        switch (field?.refName) {
          case SYSTEM_FIELD_TAG: {
            if (Array.isArray(values[key]) && values[key].length) {
              formData[field.refName] = values[key].join(',');
            }

            break;
          }

          default: {
            switch (field?.componentType) {
              case COMPONENT_TYPE.SUGGESTION: {
                formData[field.refName] = jsonParse(values[key]);
                break;
              }

              case COMPONENT_TYPE.PICKLIST: {
                formData[field.refName] = values[key];
                break;
              }

              case COMPONENT_TYPE.USER: {
                if (Array.isArray(projectUserList) && projectUserList.length) {
                  const user = projectUserList.find(u => u?.username === values[key]);
                  formData[field.refName] = convertUserForSubmitData(user);
                }

                break;
              }

              case COMPONENT_TYPE.TIME_TRACKING: {
                formData[field.refName] = convertEstimatedTimeToMinutes(values[key]);
                break;
              }

              case COMPONENT_TYPE.DATE:
              case COMPONENT_TYPE.DATE_TIME: {
                formData[field.refName] = moment(values[key]).format();
                break;
              }

              case COMPONENT_TYPE.RELATION: {
                formData[field.refName] = values[key];
                break;
              }

              case COMPONENT_TYPE.NUMBER: {
                formData[field.refName] = +values[key];
                break;
              }

              default: {
                formData[field.refName] = values[key];
                break;
              }
            }
          }
        }
      }
    });

    // Test steps
    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    let newSessionTestStepList = newTestStepsInSession[editingTestCase?.key || 'new'];
    newSessionTestStepList =
      Array.isArray(newSessionTestStepList) && newSessionTestStepList.length ? [...newSessionTestStepList] : [];

    const testStepList =
      Array.isArray(currentTestStepList) && currentTestStepList.length ? [...currentTestStepList] : [];

    formData[SYSTEM_FIELD_TEST_STEPS] = [...testStepList, ...newSessionTestStepList].map((s, idx) => {
      const newStep = { ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 };
      delete newStep.key;
      delete newStep.isNew;
      return newStep;
    });

    // Attachments
    const attachmentList =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : [];

    // Editor attachments
    const editorAttachFileList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList]
        : [];

    formData[SYSTEM_FIELD_ATTACHMENTS] = [...attachmentList, ...editorAttachFileList];

    // Test steps raw (monaco editor) => For add/edit
    if (testStepRawInfo?.[FIELD_TEST_STEP_RAW]) {
      formData[FIELD_TEST_STEP_RAW] = testStepRawInfo?.[FIELD_TEST_STEP_RAW];
    }

    // Test steps raw (monaco editor) => For add
    if (fromModule == 'ADD' && testStepRawInfo?.[FIELD_TEST_STEP_RAW]) {
      formData[SYSTEM_FIELD_AUTOMATION_STATUS] = testStepRawInfo?.testStepErrors?.length
        ? AUTOMATION_STATUS['NOT-READY'].name
        : AUTOMATION_STATUS.READY.name;

      formData.testStepErrors = testStepRawInfo?.testStepErrors;
    }

    // Test steps raw (monaco editor) => For add/edit
    if (testStepRawInfo?.[FIELD_TEST_STEP_RAW]) {
      formData[FIELD_TEST_STEP_RAW] = testStepRawInfo?.[FIELD_TEST_STEP_RAW];
    }

    // Test steps raw (monaco editor) => For add
    if (fromModule == 'ADD_MODAL' && testStepRawInfo?.[FIELD_TEST_STEP_RAW]) {
      formData[SYSTEM_FIELD_AUTOMATION_STATUS] = testStepRawInfo?.testStepErrors?.length
        ? AUTOMATION_STATUS['NOT-READY'].name
        : AUTOMATION_STATUS.READY.name;

      formData.testStepErrors = testStepRawInfo?.testStepErrors;
    }

    let res = null;

    // For update testcase-version
    if (window.location.pathname.includes('/test-repo/testcase-version')) {
      res = await updateTestCaseVersion(formData);
      if (typeof onUpdated === 'function') onUpdated();
    }

    // For update testcase
    else if (fromModule === 'EDIT_MODAL' || fromModule === 'QUICK_EDIT_MODAL') {
      if (values?.[SYSTEM_FIELD_VERSION] !== objectPath.get(editingTestCase, SYSTEM_FIELD_VERSION)) {
        const res2 = await createTestCaseVersion({
          checkExist: true,
          testCaseId: editingTestCase?._id,
          testCaseKey: editingTestCase?.key,
          [SYSTEM_FIELD_VERSION]: values?.[SYSTEM_FIELD_VERSION]
        });

        if (res2?.status === REST_API_STATUS_CODE.conflic) {
          form.scrollToField(SYSTEM_FIELD_VERSION);
          const versionInput = document.querySelector(`input#${SYSTEM_FIELD_VERSION}`);

          if (versionInput) {
            versionInput.scrollIntoView();
            versionInput.focus();
          }
        } else if (res2?.status === REST_API_STATUS_CODE.success) {
          const newFormData = { ...formData };
          delete newFormData[SYSTEM_FIELD_VERSION];
          res = await updateTestCase(newFormData);
        }
      } else {
        res = await updateTestCase(formData);
      }

      if (typeof onUpdated === 'function') onUpdated();
    }

    // For add testcase
    else if (fromModule === 'ADD_MODAL') {
      trackEventAzureWithDuration(appInsights, 'create_manual_test_case', { numTestCases: 1 });
      res = await createTestCase(formData);
      if (typeof onAdded === 'function') onAdded(res?.data);
    }

    // Handle delete multiple attachments
    if (Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length) {
      deleteFiles({ globalTenant, globalProject, ids: [...deteledAttachmentIds] });
      setDeteledAttachmentIds([]);
    }

    if (res) {
      setTestStepRawInfo(null);
      onCancel();
    }
  };

  /**
   * Testcase form
   */
  const TestcaseForm = (
    <Form
      form={form}
      layout="vertical"
      onFinish={values => {
        onSubmitTestCase(values);
        scrollToFirstClassName('ant-form-item-has-error', 0);
      }}
    >
      <div className="modal-body-with-scroll" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingItem}>
            {Array.isArray(fieldList) &&
              fieldList.length > 0 &&
              fieldList
                .filter(field => field?.isInsert)
                .map((field, index) => {
                  return (
                    <WorkItemField
                      key={index}
                      form={form}
                      workTicketId={WORK_ITEM_TESTCASE_ID}
                      field={field}
                      editingItemKey={editingTestCase?.key}
                      currentTestStepRaw={editingTestCase}
                      currentTestStepList={currentTestStepList}
                      onChangeCurrentTestStepList={setCurrentTestStepList}
                      hasGetFullFilesInfo={false} // false => Because all the data here has been retrieved: "getAllFilesInfoForTestcase()"
                      uploadPath={getUploadPath(WORK_ITEM_TESTCASE_ID)}
                      currentAttachmentList={currentAttachmentList}
                      isReadOnly={isReadOnly}
                      onChangeCurrentAttachmentList={onChangeCurrentAttachmentList}
                      onChangeDeteledAttachmentIds={ids => setDeteledAttachmentIds([...deteledAttachmentIds, ...ids])}
                      onEditorAttachFiles={onEditorAttachFiles}
                    />
                  );
                })}
          </Spin>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button
            type="text"
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
          >
            {isReadOnly ? t('common.close') : t('common.cancel')}
          </Button>

          {!isReadOnly && (
            <Button
              className="ml-2"
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => scrollToFirstClassName('ant-form-item-has-error')}
            >
              {fromModule === 'ADD_MODAL' ? t('common.create') : t('common.save')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );

  return (
    <>
      {fromModule === 'QUICK_EDIT_MODAL' ? (
        <>{editingTestCase?.key && TestcaseForm}</>
      ) : (
        <Modal
          open={visible}
          width={1160}
          maskClosable={false}
          keyboard={false}
          footer={null}
          forceRender
          centered // For "modal-fixed-header"
          wrapClassName="modal-fixed-header"
          className={`c-test-case-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
            fullScreen ? 'full-screen-modal' : ''
          } ${className}`}
          onCancel={onCancel}
          {...rest}
        >
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">
                {fromModule === 'ADD_MODAL' ? (
                  <>
                    <PlusCircle /> {t('testRepo.addTestcase')}
                  </>
                ) : (
                  <>
                    <EditOutlined /> {t('testRepo.editTestCase')}
                  </>
                )}
              </h3>

              <Row align="middle" justify="end" wrap={false} className="box-extra">
                <Tooltip
                  open={visibleFullScreenTooltip}
                  title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                  placement={fullScreen ? 'bottomRight' : 'bottom'}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                  onOpenChange={setVisibleFullScreenTooltip}
                >
                  <Button
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    type="link"
                    className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setVisibleFullScreenTooltip(false);
                    }}
                  />
                </Tooltip>
              </Row>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>

          {fromModule === 'EDIT_MODAL' ? <>{editingTestCase?.key && TestcaseForm}</> : TestcaseForm}
        </Modal>
      )}

      {visible && (
        <BasicPasteUploadFile
          attachments={currentAttachmentList}
          acceptMineTypes={['image/']}
          uploadPath={getUploadPath(WORK_ITEM_TESTCASE_ID)}
          onChangeAttachments={onChangeCurrentAttachmentList}
        />
      )}
    </>
  );
}
