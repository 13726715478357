import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Modal, Button, Divider, Row, Tooltip } from 'antd';
import {
  EditOutlined,
  SaveOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';

import { FULL_DATE_FORMAT } from '../../constants';
import { removeTokenToRawHtml } from '../../common';
import { UserAvatar, BasicEditor } from '../../components';

export const UpdateCommentModal = ({
  visible,
  editingComment,
  uploadPath,
  isReadOnly,
  loadingItem,
  className = '',
  onSaveComment,
  onEditorAttachFiles,
  onCancel,
  ...rest
}) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleEditor, setVisibleEditor] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  /**
   * Set editing comment to form
   */
  useEffect(() => {
    if (!editingComment) return;

    form.setFieldsValue({
      content: editingComment.content
    });
  }, [form, editingComment]);

  /**
   * On submit
   */
  const onSubmit = values => {
    const formData = {
      _id: editingComment?._id
    };

    if (values.content) {
      formData.content = values.content;
    }

    onSaveComment(formData);
  };

  return (
    <Modal
      open={visible}
      width={1260}
      maskClosable={false}
      keyboard={false}
      footer={null}
      forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      className={`c-update-comment-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
        fullScreen ? 'full-screen-modal' : ''
      } ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <Form
        form={form}
        labelCol={{ sm: 6, md: 4 }}
        wrapperCol={{ sm: 18, md: 20 }}
        labelAlign="left"
        onFinish={onSubmit}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">
              <EditOutlined /> {t('comment.editComment')}
            </h3>

            <Row align="middle" justify="end" wrap={false} className="box-extra">
              <Tooltip
                open={visibleFullScreenTooltip}
                title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                placement={fullScreen ? 'bottomRight' : 'bottom'}
                destroyTooltipOnHide={true}
                overlayStyle={{ pointerEvents: 'none' }}
                onOpenChange={setVisibleFullScreenTooltip}
              >
                <Button
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  type="link"
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                    setVisibleFullScreenTooltip(false);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingItem}>
              <Form.Item label={t('workItem.createdBy')} className="mb-0">
                <UserAvatar user={editingComment?.createdBy} restName={{ className: 'font-weight-medium' }} />
              </Form.Item>

              <Form.Item label={t('workItem.createdAt')} className="mb-0">
                <span className="font-weight-medium">
                  {editingComment?.createdAt && moment(editingComment?.createdAt).isValid()
                    ? moment(editingComment?.createdAt).format(FULL_DATE_FORMAT)
                    : ''}
                </span>
              </Form.Item>

              {visibleEditor && (
                <Form.Item
                  label={t('comment.comment')}
                  name="content"
                  rules={[{ required: true, whitespace: true, message: t('message.required') }]}
                  className="mb-0"
                >
                  <BasicEditor
                    rawHtml={editingComment?.content}
                    uploadPath={uploadPath}
                    restEditor={{ placeholder: t('comment.enterComment') }}
                    className="pt-2"
                    onBlur={() => form.validateFields(['content'])}
                    onEditorAttachFiles={file => {
                      if (typeof onEditorAttachFiles === 'function') onEditorAttachFiles(file);
                    }}
                    onEditorRawHtmlChange={val => {
                      form.setFieldsValue({ content: removeTokenToRawHtml({ rawHtml: val }) });
                    }}
                  />
                </Form.Item>
              )}
            </Spin>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button id="cancel-comment-button_modal" type="text" onClick={onCancel}>
              {t('common.cancel')}
            </Button>

            <Button
              id="save-comment-button_modal"
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
              loading={loadingItem}
              disabled={isReadOnly}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
