import { action, thunk } from 'easy-peasy';
import { notification } from 'antd';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { handleError, Http } from '../../../../common';

export const model = {
  /**
   * State
   */
  treeData: null, // Tree data from api
  tree: null, //  Tree for show on UI
  expandedKeys: null,
  selectedTreeItem: null,
  currentNode: null,
  dateList: null,
  loadingTreeData: false,

  /**
   * Action: Set tree data
   */
  setTreeData: action((state, payload) => {
    if (state?.treeData === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.treeData = [];
      return;
    }

    state.treeData = payload;
  }),

  /**
   * Action: Set tree (ant design tree, tree on UI)
   */
  setTree: action((state, payload) => {
    if (state?.tree === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.tree = [];
      return;
    }

    state.tree = payload;
  }),

  /**
   * Action: Set expanded keys
   */
  setExpandedKeys: action((state, payload) => {
    if (state?.expandedKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedKeys = [];
      return;
    }

    state.expandedKeys = payload;
  }),

  /**
   * Action: Set selected tree item
   */
  setSelectedTreeItem: action((state, payload) => {
    if (state?.selectedTreeItem === undefined) return;

    state.selectedTreeItem = payload;
  }),

  /**
   * Action: Set current node
   */
  setCurrentNode: action((state, payload) => {
    if (state?.currentNode === undefined) return;

    state.currentNode = payload;
  }),

  /**
   * Action: Set date list
   */
  setDateList: action((state, payload) => {
    if (state?.dateList === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.dateList = [];
      return;
    }

    state.dateList = payload;
  }),

  /**
   * Action: Set loading tree data
   */
  setLoadingTreeData: action((state, payload) => {
    if (state?.loadingTreeData === undefined) return;

    state.loadingTreeData = payload;
  }),

  /**
   * Action: Call api to get test plan tree data
   */
  getTestPlanTreeData: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTreeData(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/get-tree`;
      const res = await Http.get(url).then(res => res.data);

      action.setTreeData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTreeData(false);
    }
  }),

  /**
   * Action: Call api to update status test run
   */
  updateFieldConfigTestRunByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.key) throw new Error('Update field config test run by key: No key');

      action.setLoadingTreeData(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}/${payload.type}/${payload.key}`;
      const res = await Http.put(url, payload.body).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTreeData(false);
    }
  }),

  /**
   * Action: Call api to separate folder
   */
  separateFolder: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('Separate folder: Invalid payload');

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/auto-separate`;
      const res = await Http.put(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:common.successful'),
        description: i18next.t('akaat:message.yourChangesHaveBeenAppliedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
    }
  })
};
