import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Input, Tooltip, Form, Select } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { COMPONENT_TYPE, NUMBER_PATTERN } from '../../constants';

// For string and number type
export const FieldStringNumber = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.referenceField || !(Array.isArray(currentValues) && currentValues.length)) return null;

    const newField = [...currentValues].find(item => item.referenceField === field.referenceField);

    if (
      !(
        (field.componentType === COMPONENT_TYPE.STRING && newField?.value) ||
        (field.componentType === COMPONENT_TYPE.NUMBER && new RegExp(NUMBER_PATTERN).test(newField?.value))
      )
    ) {
      return null;
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const DropdownContent = ({ field, currentField, handleSetVisibleDropdown, onClear, onApply }) => {
    const [form] = Form.useForm();

    // Component state
    const [currentOperation, setCurrentOperation] = useState(null);

    /**
     * Set value to form
     */
    useEffect(() => {
      if (!field?.referenceField) return;

      const newCurrentOperation = currentField?.operation
        ? currentField.operation
        : Array.isArray(field.supportedOperations) && field.supportedOperations.length
        ? field.supportedOperations[0]
        : '~';

      form.setFieldsValue({ val: currentField ? currentField.value : null });
      setCurrentOperation(newCurrentOperation);
    }, [form, field, currentField]);

    /**
     * On submit
     */
    const onSubmit = values => {
      handleSetVisibleDropdown(false);
      onApply(values?.val, currentOperation);
    };

    return (
      <Form
        form={form}
        className="ant-dropdown-menu c-field-string-number-dropdown p-0"
        onClick={e => e.stopPropagation()}
        onFinish={onSubmit}
      >
        <Form.Item
          name="val"
          rules={[
            {
              pattern: field.componentType === COMPONENT_TYPE.NUMBER ? new RegExp(NUMBER_PATTERN) : null,
              message: t('message.fieldMustBeANumber')
            }
          ]}
          wrapperCol={{ xs: 24 }}
          style={{ padding: '8px 12px 5px 12px' }}
          className="mb-0"
        >
          <Input
            addonBefore={
              field.supportedOperations ? (
                <Select
                  value={currentOperation}
                  options={
                    Array.isArray(field.supportedOperations) && field.supportedOperations.length
                      ? field.supportedOperations.map(sub => {
                          return {
                            label: sub,
                            value: sub
                          };
                        })
                      : []
                  }
                  dropdownMatchSelectWidth={false}
                  onSelect={setCurrentOperation}
                />
              ) : (
                ''
              )
            }
            autoFocus
            autoComplete="off"
            onPressEnter={() => form.submit()}
          />
        </Form.Item>

        <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

        <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
          <Button
            size="small"
            className="w-auto ml-2"
            onClick={() => {
              handleSetVisibleDropdown(false);
              onClear();
            }}
          >
            {t('common.clear')}
          </Button>

          <Button htmlType="submit" type="primary" size="small" className="w-auto ml-2">
            {t('common.apply')}
          </Button>
        </div>
      </Form>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          currentField
            ? `${t(`workItem.${field.name}`)}: ${currentField.operation} ${currentField.value}`
            : `${t(`workItem.${field.name}`)}: ${t('common.all')}`
        }
        className={`btn-field field-string-number ${className}`}
        {...rest}
      >
        {currentField ? (
          <>
            <span className="text-truncate">
              {t(`workItem.${field.name}`)}: {currentField.operation} {currentField.value}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="text-truncate">
              {t(`workItem.${field.name}`)}: {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [
            {
              key: 'menu',
              label: (
                <DropdownContent
                  field={field}
                  currentField={currentField}
                  handleSetVisibleDropdown={setVisibleDropdown}
                  onClear={onClear}
                  onApply={onApply}
                />
              ),
              className: 'p-0'
            }
          ],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ width: 290 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={setVisibleDropdown}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
