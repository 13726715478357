import i18next from 'i18next';
import { notification } from 'antd';

import { getObjectByValue, jsonParse } from '../common';
import { HTTP_STATUS_CODES } from '../constants';

/**
 * Handle error
 */
export const handleError = err => {
  if (!err) return;

  // For throw new Error(your_message_string) on model.js
  if (typeof err === 'object' && err.message) {
    notification.error({
      message: i18next.t('akaat:common.error'),
      description: err.message
    });
  } else {
  }
};

/**
 * Handle throw error for Jira
 */
export const handleJiraThrowError = res => {
  if (res?.errno) {
    throw new Error(res?.errno);
  } else if (res?.statusCode && res?.statusCode !== 200) {
    notification.error({
      message: HTTP_STATUS_CODES.some(item => item.value === res.statusCode)
        ? getObjectByValue(res.statusCode, HTTP_STATUS_CODES)?.label
        : i18next.t('akaat:common.error')
    });
  } else if (res && typeof res === 'string' && (res.includes('errorMessages') || res.includes('errors'))) {
    const err = jsonParse(res);

    if (
      (Array.isArray(err?.body?.errorMessages) && err?.body?.errorMessages.length) ||
      Object.keys(err?.body?.errors).length
    ) {
      throw new Error(err?.body?.errorMessages[0] || Object.values(err?.body?.errors)[0]);
    }
  } else {
  }
};
