import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import OutsideClickHandler from 'react-outside-click-handler';

import { URL_PATTERN, SS_LAST_SAVED_VALUE } from '../../../../constants';
import { checkValidField, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { ExternalLink } from '../../../../assets/svg-icons';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import UrlFormItem from './url-form-item';

const UrlField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [editingCell, setEditingCell] = useState(null);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => objectPath.get(row, field?.refName), [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      tableForm.setFieldsValue({ [formItemName]: getCurrentValue(defaultVal) });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultVal]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    setEditingCell({ x, y });
  };

  /**
   * Handle close
   */
  const handleClose = val => {
    setCurrentValue(val);
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async val => {
    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !new RegExp(URL_PATTERN).test(val))) {
      handleClose(defaultValue);
      return;
    }

    if (val !== defaultValue) {
      handleSetLastSavedValueToSession({ [formItemName]: val });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: val }, row, field });
      }
    }

    handleClose(val);
  };

  /**
   * Input props
   */
  const inputProps = {
    autoFocus: !row?.isNew,
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    placeholder: placeholder || t('common.enterValue'),
    onChange: e => setCurrentValue(e?.target?.value),
    onBlur: e => handleSave(e?.target?.value),
    onClick: () => {
      if (row?.isNew) return;

      const triggerFocusElement = document.querySelector(
        `.trigger-focus-element[data-form-item-name="${formItemName}"]`
      );
      triggerFocusElement?.classList.remove('focused');
    },
    onPressEnter: e => {
      if (row?.isNew) handleSave(e?.target?.value);
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val = getCurrentValue(currentValue);

    const externalLinkButton = new RegExp(URL_PATTERN).test(val) && (
      <div className="btn-external-link-wrapper btn-external-link-wrapper btn-action-editable-cell-wrapper">
        <a
          href={val}
          title={val}
          target="_blank"
          rel="noreferrer"
          className="btn-external-link btn-action-editable-cell allow-click ant-btn ant-btn-sm"
          onClick={e => e.stopPropagation()}
        >
          <ExternalLink />
        </a>
      </div>
    );

    const link = (
      <a href={val} title={val} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
        {val}
      </a>
    );

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={val || ''} className="cell-text hide-after cursor-text" onClick={handleOpen}>
          <span className="cell-text-value min-h-22 text-truncate">{val || ''}</span>
          {externalLinkButton}
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={val || ''} row={row} field={field}>
          {val || ''}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={val || ''} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {val || ''}
          </RelationLink>

          {externalLinkButton}
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return link;
    }

    // Readonly
    else {
      return (
        <div title={val || ''} className="cell-text cursor-default hide-after">
          <div className="cell-text-value min-h-22 text-truncate">{link}</div>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <UrlFormItem
        x={x}
        y={y}
        field={field}
        tableForm={tableForm}
        formItemName={formItemName}
        inputProps={inputProps}
        setEditingCell={setEditingCell}
        handleSave={handleSave}
        closeAndSetLastValue={closeAndSetLastValue}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };
  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && renderFormItem()}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper url-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new url-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default UrlField;
