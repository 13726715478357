import * as monaco from 'monaco-editor';
import {
  CompletionItemKind,
  CompletionItemTag,
  DiagnosticSeverity,
  DiagnosticTag,
  InsertTextFormat,
  MarkupKind
} from 'vscode-languageserver-types';

export function convertMonacoRangeToProtocolRange(range) {
  return {
    start: {
      line: range.startLineNumber - 1,
      character: range.startColumn - 1
    },
    end: { line: range.endLineNumber - 1, character: range.endColumn - 1 }
  };
}

export function convertMonacoPositionToProtocolPosition(position) {
  return { line: position.lineNumber - 1, character: position.column - 1 };
}

export function convertMonacoTriggerKindToProtocolTriggerKind(triggerKind) {
  switch (triggerKind) {
    case monaco.languages.CompletionTriggerKind.Invoke:
      return 1;
    case monaco.languages.CompletionTriggerKind.TriggerCharacter:
      return 2;
    default:
      return 3;
  }
}

export function convertMonacoCompletionContextToProtocolCompletionContext(context) {
  return {
    triggerKind: convertMonacoTriggerKindToProtocolTriggerKind(context.triggerKind),
    triggerCharacter: context.triggerCharacter
  };
}

export function convertMonacoCompletionLabelToProtocolLabel(label) {
  if (typeof label === 'string') {
    return label;
  }
  const cil = label;
  return cil.label;
}

export function convertMonacoCompletionItemKindToMonacoToProtocolCompletionItemKind(kind) {
  switch (kind) {
    case monaco.languages.CompletionItemKind.Method:
      return CompletionItemKind.Method;
    case monaco.languages.CompletionItemKind.Function:
      return CompletionItemKind.Function;
    case monaco.languages.CompletionItemKind.Constructor:
      return CompletionItemKind.Constructor;
    case monaco.languages.CompletionItemKind.Field:
      return CompletionItemKind.Field;
    case monaco.languages.CompletionItemKind.Variable:
      return CompletionItemKind.Variable;
    case monaco.languages.CompletionItemKind.Class:
      return CompletionItemKind.Class;
    case monaco.languages.CompletionItemKind.Interface:
      return CompletionItemKind.Interface;
    case monaco.languages.CompletionItemKind.Module:
      return CompletionItemKind.Module;
    case monaco.languages.CompletionItemKind.Property:
      return CompletionItemKind.Property;
    case monaco.languages.CompletionItemKind.Unit:
      return CompletionItemKind.Unit;
    case monaco.languages.CompletionItemKind.Value:
      return CompletionItemKind.Value;
    case monaco.languages.CompletionItemKind.Enum:
      return CompletionItemKind.Enum;
    case monaco.languages.CompletionItemKind.Keyword:
      return CompletionItemKind.Keyword;
    case monaco.languages.CompletionItemKind.Snippet:
      return CompletionItemKind.Snippet;
    case monaco.languages.CompletionItemKind.Color:
      return CompletionItemKind.Color;
    case monaco.languages.CompletionItemKind.File:
      return CompletionItemKind.File;
    case monaco.languages.CompletionItemKind.Reference:
      return CompletionItemKind.Reference;
    case monaco.languages.CompletionItemKind.Folder:
      return CompletionItemKind.Folder;
    case monaco.languages.CompletionItemKind.EnumMember:
      return CompletionItemKind.EnumMember;
    case monaco.languages.CompletionItemKind.Constant:
      return CompletionItemKind.Constant;
    case monaco.languages.CompletionItemKind.Struct:
      return CompletionItemKind.Struct;
    case monaco.languages.CompletionItemKind.Event:
      return CompletionItemKind.Event;
    case monaco.languages.CompletionItemKind.Operator:
      return CompletionItemKind.Operator;
    case monaco.languages.CompletionItemKind.TypeParameter:
      return CompletionItemKind.TypeParameter;
    default:
      return CompletionItemKind.Text;
  }
}

export function convertMonacoCompletionItemTagToProtocolCompletionItemTag(tag) {
  switch (tag) {
    case monaco.languages.CompletionItemTag.Deprecated:
    default:
      return CompletionItemTag.Deprecated;
  }
}

export function convertMonacoMarkdownStringToProtocolMarkupContent(documentation) {
  if (documentation) {
    if (typeof documentation === 'string') {
      return { value: documentation, kind: MarkupKind.PlainText };
    }
    const doc = documentation;
    return { value: doc.value, kind: MarkupKind.Markdown };
  }
  return documentation;
}

export function convertMonacoInsertTextRulesToProtocolInsertTextFormat(rule) {
  switch (rule) {
    case monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet:
      return InsertTextFormat.Snippet;
    default:
      return InsertTextFormat.PlainText;
  }
}

export function convertMonacoSingleEditOperationToProtocolAdditionalTextEdit(textEdit) {
  return {
    range: convertMonacoRangeToProtocolRange(textEdit.range),
    newText: textEdit.text === null ? '' : textEdit.text
  };
}

export function convertMonacoCommandToProtocolCommand(command) {
  return {
    title: command.title,
    command: command.id,
    arguments: command.arguments
  };
}

function calculateTextEdit(completionItem) {
  if (monaco.Range.isIRange(completionItem.range)) {
    return {
      newText: completionItem.insertText,
      range: convertMonacoRangeToProtocolRange(completionItem.range)
    };
  }
  return {
    newText: completionItem.insertText,
    insert: convertMonacoRangeToProtocolRange(completionItem.range.insert),
    replace: convertMonacoRangeToProtocolRange(completionItem.range.replace)
  };
}

export function convertMonacoCompletionItemToProtocolCompletionItem(completionItem) {
  return {
    label: convertMonacoCompletionLabelToProtocolLabel(completionItem.label),
    kind: convertMonacoCompletionItemKindToMonacoToProtocolCompletionItemKind(completionItem.kind),
    tags: completionItem.tags
      ? completionItem.tags.map(tag => convertMonacoCompletionItemTagToProtocolCompletionItemTag(tag))
      : undefined,
    detail: completionItem.detail,
    documentation: convertMonacoMarkdownStringToProtocolMarkupContent(completionItem.documentation),
    preselect: completionItem.preselect,
    sortText: completionItem.sortText,
    filterText: completionItem.filterText,
    insertText: completionItem.insertText,
    insertTextFormat: completionItem.insertTextRules
      ? convertMonacoInsertTextRulesToProtocolInsertTextFormat(completionItem.insertTextRules)
      : undefined,
    textEdit: calculateTextEdit(completionItem),
    additionalTextEdits: completionItem.additionalTextEdits
      ? completionItem.additionalTextEdits.map(item =>
          convertMonacoSingleEditOperationToProtocolAdditionalTextEdit(item)
        )
      : undefined,
    commitCharacters: completionItem.commitCharacters,
    command: completionItem.command ? convertMonacoCommandToProtocolCommand(completionItem.command) : undefined,
    data: completionItem.data
  };
}

export function convertMonacoSeverityToProtocolSeverity(severity) {
  switch (severity) {
    case monaco.MarkerSeverity.Error:
      return DiagnosticSeverity.Error;
    case monaco.MarkerSeverity.Warning:
      return DiagnosticSeverity.Warning;
    case monaco.MarkerSeverity.Info:
      return DiagnosticSeverity.Information;
    default:
      return DiagnosticSeverity.Hint;
  }
}

function convertMonacoMarkerCodeToDiagnosticCode(code) {
  if (code) {
    if (typeof code === 'string') {
      return code;
    }
    return code.value;
  }
  return '';
}

export function convertMonacoMarkerTagToProtocolDiagnosticTag(tag) {
  switch (tag) {
    case monaco.MarkerTag.Deprecated:
      return DiagnosticTag.Deprecated;
    default:
      return DiagnosticTag.Unnecessary;
  }
}

export function convertMonacoMarkerRelatedInformationToProtocolDiagnosticRelatedInformation(info) {
  return {
    location: {
      uri: info.resource.toString(),
      range: {
        start: {
          line: info.startLineNumber - 1,
          character: info.startColumn - 1
        },
        end: { line: info.endLineNumber - 1, character: info.endColumn - 1 }
      }
    },
    message: info.message
  };
}

export function convertMonacoMarkerDataToProtocolDiagnostic(marker) {
  return {
    range: {
      start: {
        line: marker.startLineNumber - 1,
        character: marker.startColumn - 1
      },
      end: { line: marker.endLineNumber - 1, character: marker.endColumn - 1 }
    },
    severity: convertMonacoSeverityToProtocolSeverity(marker.severity),
    code: convertMonacoMarkerCodeToDiagnosticCode(marker.code),
    source: marker.source,
    message: marker.message,
    tags: marker.tags ? marker.tags.map(tag => convertMonacoMarkerTagToProtocolDiagnosticTag(tag)) : undefined,
    relatedInformation: marker.relatedInformation
      ? marker.relatedInformation.map(info =>
          convertMonacoMarkerRelatedInformationToProtocolDiagnosticRelatedInformation(info)
        )
      : undefined
  };
}

export function convertMonacoCodeActionContextToProtocolCodeActionContext(context) {
  return {
    diagnostics: context.markers.map(marker => convertMonacoMarkerDataToProtocolDiagnostic(marker))
  };
}

export function convertMonacoColorToProtocolColor(color) {
  return {
    red: color.red,
    green: color.green,
    blue: color.blue,
    alpha: color.alpha
  };
}

export function convertMonacoCodeLensToProtocolCodeLens(codeLen) {
  return {
    range: convertMonacoRangeToProtocolRange(codeLen.range),
    command: codeLen.command ? convertMonacoCommandToProtocolCommand(codeLen.command) : undefined,
    data: codeLen.data
  };
}

export function convertMonacoFormattingOptionToProtocolFormattingOption(option) {
  return {
    tabSize: option.tabSize,
    insertSpaces: option.insertSpaces
  };
}

export function convertMonacoReferenceContextToProtocolReferenceContext(context) {
  return {
    includeDeclaration: context.includeDeclaration
  };
}
