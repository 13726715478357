import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import { handleKeyboardEvents } from '../../editable-cell-utils';

const HtmlFormItem = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  inputProps,
  setEditingCell,
  closeAndSetLastValue,
  restFormItem,
  restField
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Input.TextArea {...inputProps} {...restField} />
    </Form.Item>
  );
};

export default HtmlFormItem;
