import React, { useState, useEffect } from 'react';
import { JsonParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Table, Spin, Pagination } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { PAGE_SIZES, DEFAULT_ORDER } from '../../constants';
import vi from './custom-language/vi.js';

export const BasicTable = ({
  columns = [],
  data = [],
  total = 0,
  currentPage,
  currentLimit,
  rowKey = 'id',
  rowClassName,
  defaultOrderBy = DEFAULT_ORDER,
  allowChangeQueryParamsUrl,
  loading,
  isDisabledChangeTable,
  locale = null,
  className = '',
  showCustomPagination,
  restCustomPagination,
  restPagination,
  tableFunction,
  onSortChange,
  onBeforeTableChange,
  onTableChange,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For query params on url
  const [, setQueryParams] = useQueryParams({
    page: NumberParam,
    limit: NumberParam,
    order: StringParam,
    filters: JsonParam
  });

  // For global language store
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // Component state
  const [page, setPage] = useState(null);
  const [limit, setLimit] = useState(null);

  /**
   * Set table function
   * For: Call child function from parent component
   */
  useEffect(() => {
    if (tableFunction) {
      tableFunction.current = {
        handleTableChange
      };
    }
  }, [tableFunction]);

  /**
   * Watching change of current page
   */
  useEffect(() => {
    if (!currentPage) return;

    setPage(currentPage);
  }, [currentPage]);

  /**
   * Watching change of current limit
   */
  useEffect(() => {
    if (!currentLimit) return;

    setLimit(currentLimit);
  }, [currentLimit]);

  /**
   * Handle table change
   */
  const handleTableChange = (pagination, filters, sorter, extra, moreInfo) => {
    if (isDisabledChangeTable) return;

    if (tableFunction && typeof onBeforeTableChange === 'function' && !moreInfo?.isConfirmedBeforeChange) {
      onBeforeTableChange(pagination, filters, sorter, extra);
      return;
    }

    const page = pagination?.current;
    const limit = pagination?.pageSize;
    const order = sorter?.order
      ? `${sorter?.field}|${sorter?.order === 'ascend' ? 'ASC' : 'DESC'}`
      : allowChangeQueryParamsUrl
      ? `default=${defaultOrderBy}`
      : defaultOrderBy;
    let newFilters = { ...(filters || {}) };

    Object.keys(newFilters).forEach(key => {
      if (!newFilters[key]) delete newFilters[key];
    });

    const params = {
      page,
      limit,
      order,
      filters: newFilters
    };

    setPage(page);
    setLimit(limit);

    if (allowChangeQueryParamsUrl) {
      setQueryParams({
        page,
        limit,
        order,
        filters: Object.keys(newFilters).length ? newFilters : undefined
      });
    }

    if (typeof onSortChange === 'function') {
      onSortChange(sorter);
    }

    if (typeof onTableChange === 'function') {
      onTableChange(params);
    }
  };

  if (!Array.isArray(data)) return;

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        rowKey={rowKey} // Fix Warning: Each child in a list should have a unique "key" prop and design
        rowClassName={rowClassName}
        loading={{
          spinning: !!loading,
          indicator: <Spin indicator={<Loading3QuartersOutlined spin />} />
        }}
        pagination={
          !showCustomPagination &&
          page &&
          limit && {
            total,
            current: page,
            pageSize: limit,
            pageSizeOptions: PAGE_SIZES,
            showSizeChanger: true,
            showTotal: (totalnumber, range) => {
              return t('common.showTotalOfTable', { start: range[0], end: range[1], total: totalnumber });
            },
            className: 'c-basic-table-pagination ant-table-pagination ant-table-pagination-right',
            ...restPagination
          }
        }
        locale={globalLanguage === 'vi' ? { ...vi, ...locale } : locale} // Custom language (on table) for Vietnamese
        className={`c-basic-table ${className}`}
        onChange={handleTableChange}
        {...rest}
      />

      {showCustomPagination && page && limit && (
        <>
          <Pagination
            total={total}
            current={page}
            pageSize={limit}
            pageSizeOptions={PAGE_SIZES}
            showSizeChanger={true}
            showTotal={(totalnumber, range) => {
              return t('common.showTotalOfTable', { start: range[0], end: range[1], total: totalnumber });
            }}
            className={`ant-table-pagination ant-table-pagination-right c-basic-table-pagination custom-pagination ${
              restCustomPagination?.className || ''
            }`}
            onChange={(current, pageSize) => {
              const pagination = { current, pageSize };
              handleTableChange(pagination);
            }}
            {...restCustomPagination}
          />
        </>
      )}
    </>
  );
};
