import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Modal, Row, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { SaveOutlined, EditOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import objectPath from 'object-path';
import { arrayMoveImmutable } from 'array-move';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_ATTACHMENTS,
  SYSTEM_FIELD_DESCRIPTION
} from '../../../../constants';
import {
  useFile,
  jsonParse,
  useEditableCell,
  convertUserForSubmitData,
  convertEstimatedTimeToMinutes,
  checkTicketTypeForRelationField
} from '../../../../common';
import { BasicPasteUploadFile } from '../../../../components';
import { WorkItemField } from '../../../../components/work-item-field';

export default function EditWorkTicketModal({
  qKey,
  fromModule = 'QUICK_EDIT_MODAL',
  workTicketId,
  visible,
  onCancel,
  detailWorkTicket,
  isReadOnly,
  className = '',
  ...rest
}) {
  const urlParams = useParams();
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For form hook
  const { handleGetFieldValue } = useEditableCell();

  // For upload
  const { getUploadPath } = useFile();

  // For global store
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For store action
  const getWorkTicketById = useStoreActions(action => action.managerWorkItem.getWorkTicketById);
  const updateWorkTicketByKey = useStoreActions(action => action.managerWorkItem.updateWorkTicketByKey);
  const loadingWorkTicketItem = useStoreState(state => state.managerWorkItem.loadingWorkTicketItem);
  const query = useStoreState(state => state.managerWorkItem.query);
  const getWorkTicketList = useStoreActions(action => action.managerWorkItem.getWorkTicketList);

  // Component state
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]);
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [isGetAllFullFileInfo, setIsGetAllFullFileInfo] = useState(false);
  const [currentEditorAttachFileList, setCurrentEditorAttachFiles] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Compute: field list
   */
  const fieldList = useMemo(() => {
    const fields = ticketListData?.[urlParams?.refName]?.fields;

    if (!(Array.isArray(fields) && fields.length)) return;

    const attachments = {
      name: 'Attachments',
      refName: SYSTEM_FIELD_ATTACHMENTS,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      type: FIELD_SYSTEM,
      isInsert: true
    };

    let newFieldList = [...fields, attachments].map(field => {
      let currentValue = objectPath.get(detailWorkTicket, field.refName);
      return { ...field, currentValue };
    });

    if (newFieldList.some(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS)) {
      const oldIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS);
      const descriptionFieldIndex = newFieldList.findIndex(field => field.refName === SYSTEM_FIELD_DESCRIPTION);

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, descriptionFieldIndex + 1);
    }

    return newFieldList;
  }, [urlParams, ticketListData, detailWorkTicket]);

  /**
   * Set value for edit form
   */
  useEffect(() => {
    if (
      !detailWorkTicket ||
      !ticketListData ||
      !(Array.isArray(fieldList) && fieldList.length) ||
      !checkTicketTypeForRelationField({ fieldList, ticketListData })
    ) {
      return;
    }

    fieldList.forEach(field => {
      const originValue = objectPath.get(detailWorkTicket, field?.refName);

      if (
        field?.isInsert &&
        field?.componentType !== COMPONENT_TYPE.TEST_STEPS &&
        field?.componentType !== COMPONENT_TYPE.ATTACHMENTS
      ) {
        const val = handleGetFieldValue({ field, item: detailWorkTicket, ticketListData });

        form.setFieldsValue({ [field?.refName]: val });
      }
    });
  }, [detailWorkTicket, fieldList, form, ticketListData]);

  /**
   * Get all files info
   * Set current attachment list
   */
  useEffect(() => {
    if (!detailWorkTicket?.key) return;

    (async () => {
      if (!isGetAllFullFileInfo) {
        let newAttachments = objectPath.get(detailWorkTicket, SYSTEM_FIELD_ATTACHMENTS);
        await getFullFilesInfo(newAttachments);
        setCurrentAttachmentList(newAttachments);
        setIsGetAllFullFileInfo(true);
      }
    })();
  }, [detailWorkTicket, isGetAllFullFileInfo]);

  /**
   * On change current attachment list
   */
  const onChangeCurrentAttachmentList = async attachments => {
    await getFullFilesInfo(attachments);
    setCurrentAttachmentList(attachments);
  };

  /**
   * On attach file
   */
  const onEditorAttachFiles = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) return;

    const newList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList, ...attachments]
        : [...attachments];

    setCurrentEditorAttachFiles(newList);
  };

  /**
   * On submit
   */

  const onSubmit = async values => {
    if (!values || loadingWorkTicketItem) return;

    let formData = {};
    const dataCreateInit = {
      // workTicketType: urlParams?.refName,
      // status: initialStatusId,
      // properties: {},
      name: values['system_field_name'],
      description: values['system_field_description']
    };
    formData = {
      // ...values,
      ...formData,
      ...dataCreateInit
    };

    values = Object.keys(values).forEach(key => {
      const field = fieldList.find(item => item?.refName === key);

      if ((values[key] && field && field.refName) || field.refName === SYSTEM_FIELD_ASSIGN_TO) {
        switch (field?.refName) {
          case SYSTEM_FIELD_TAG: {
            if (Array.isArray(values[key]) && values[key].length) {
              formData[field.refName] = values[key].join(',');
            }
            break;
          }

          default: {
            switch (field?.componentType) {
              case COMPONENT_TYPE.SUGGESTION: {
                formData[field.refName] = jsonParse(values[key]);
                break;
              }

              case COMPONENT_TYPE.PICKLIST: {
                formData[field.refName] = values[key];
                break;
              }

              case COMPONENT_TYPE.USER: {
                formData[field.refName] = values[key];

                const user = projectUserList.find(u => u?.username === values[key]);
                formData[field.refName] = convertUserForSubmitData(user);

                break;
              }

              case COMPONENT_TYPE.TIME_TRACKING: {
                formData[field.refName] = convertEstimatedTimeToMinutes(values[key]);
                break;
              }

              case COMPONENT_TYPE.DATE:
              case COMPONENT_TYPE.DATE_TIME: {
                formData[field.refName] = moment(values[key]).format();
                break;
              }

              default: {
                formData[field.refName] = values[key];
                break;
              }
            }
          }
        }
      }
    });

    // Attachments
    const attachmentList =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : [];

    const editorAttachFileList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList]
        : [];

    formData[SYSTEM_FIELD_ATTACHMENTS] = [...attachmentList, ...editorAttachFileList];

    const res = await updateWorkTicketByKey({ data: formData, id: urlParams?.id || qKey });

    if (res) {
      fromModule === 'QUICK_EDIT_MODAL' ? getWorkTicketList(query) : getWorkTicketById({ id: urlParams?.id || qKey });
      onCancel();
    }

    // Handle delete multiple attachments
    if (Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length) {
      deleteFiles({ globalTenant, globalProject, ids: [...deteledAttachmentIds] });
      setDeteledAttachmentIds([]);
    }
  };

  const FormEditWorkTicket = (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <div className="modal-body-with-scroll" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          {Array.isArray(fieldList) &&
            fieldList.length > 0 &&
            fieldList
              .filter(field => field.isInsert)
              .map((field, index) => {
                return (
                  <WorkItemField
                    key={index}
                    form={form}
                    field={field}
                    workTicketId={workTicketId}
                    currentAttachmentList={currentAttachmentList}
                    uploadPath={getUploadPath(urlParams?.refName)}
                    hasGetFullFilesInfo={false} // false => Because all the data here has been retrieved: "getAllFilesInfoForTestcase()"
                    isReadOnly={isReadOnly}
                    onChangeCurrentAttachmentList={setCurrentAttachmentList}
                    onChangeDeteledAttachmentIds={ids => setDeteledAttachmentIds([...deteledAttachmentIds, ...ids])}
                    onEditorAttachFiles={onEditorAttachFiles}
                  />
                );
              })}
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button id="cancel-ticket-button" type="text" onClick={onCancel}>
            {isReadOnly ? t('common.close') : t('common.cancel')}
          </Button>

          {!isReadOnly && (
            <Button id="submit-ticket-button" className="ml-2" htmlType="submit" type="primary" icon={<SaveOutlined />}>
              {t('common.save')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
  return (
    <>
      {fromModule === 'QUICK_EDIT_MODAL' ? (
        <>{detailWorkTicket?.key && FormEditWorkTicket}</>
      ) : (
        <Modal
          open={visible}
          width={1160}
          maskClosable={false}
          keyboard={false}
          footer={null}
          forceRender
          centered // For "modal-fixed-header"
          wrapClassName="modal-fixed-header"
          className={`c-edit-work-ticket-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
            fullScreen ? 'full-screen-modal' : ''
          } ${className}`}
          onCancel={onCancel}
          {...rest}
        >
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">
                <EditOutlined /> {t('common.edit')}
              </h3>

              <Row align="middle" justify="end" wrap={false} className="box-extra">
                <Tooltip
                  open={visibleFullScreenTooltip}
                  title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                  placement={fullScreen ? 'bottomRight' : 'bottom'}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                  onOpenChange={setVisibleFullScreenTooltip}
                >
                  <Button
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    type="link"
                    className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setVisibleFullScreenTooltip(false);
                    }}
                  />
                </Tooltip>
              </Row>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>
          {FormEditWorkTicket}
        </Modal>
      )}

      {visible && !isReadOnly && (
        <BasicPasteUploadFile
          attachments={currentAttachmentList}
          acceptMineTypes={['image/']}
          uploadPath={getUploadPath(urlParams?.refName)}
          onChangeAttachments={onChangeCurrentAttachmentList}
        />
      )}
    </>
  );
}
