import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form } from 'antd';
import { useStoreState } from 'easy-peasy';
import objectPath from 'object-path';
import _debounce from 'lodash/debounce';

import {
  PRIORITIES,
  URL_PATTERN,
  NUMBER_PATTERN,
  COMPONENT_TYPE,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_NAME,
  SYSTEM_FIELD_PRIORITY
} from '../../constants';
import {
  useField,
  debounce,
  useUnassignedUser,
  getPrePathLinkToTicket,
  filterOptionForUserField,
  convertMinutesToShortTime
} from '../../common';
import { UserAvatar, EditableInput, EditableSelectOption, StatusLabelForWorkflow } from '../../components';
import { EditableSelectMultiOption } from '../editable-select-multi-option';

let typingTimerOfSearch = 0;

export const CustomField = ({ field, form, uploadPath, isReadOnly = false, onSave, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For hooks
  const { getSuggestionData, getRelationLabel } = useField();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For global store
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const loadingProjectUserList = useStoreState(state => state.global.loadingProjectUserList);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // Component state
  const [suggestionData, setSuggestionData] = useState({});
  const [suggestionParams, setSuggestionParams] = useState({});
  const [suggestionKeyword, setSuggestionKeyword] = useState({});
  const [userSearchText, setUserSearchText] = useState('');

  /**
   * Compute user list (users no in tenant)
   */
  const userList = useMemo(() => {
    const usersByRole = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];

    if (userSearchText === '') {
      return usersByRole;
    }

    const newList = [...usersByRole].filter(item => {
      return filterOptionForUserField(userSearchText, { item });
    });

    return newList;
  }, [userSearchText, projectUserList, loadingProjectUserList]);

  /**
   * Handle get suggestion data
   */
  const handleGetSuggestionData = ({ field, page, keyword, isLoadType }) => {
    getSuggestionData({
      field,
      page,
      keyword,
      isLoadType,
      setSuggestionData,
      suggestionData,
      setSuggestionParams,
      suggestionParams,
      suggestionKeyword
    });
  };

  /**
   * On search suggestion data
   */
  const onSearchSuggestionData = useCallback(
    _debounce(({ field, keyword, isLoadType }) => {
      handleGetSuggestionData({ field, keyword, isLoadType });
    }, 500),
    []
  );

  /**
   * Render field item
   */
  const renderFieldItem = () => {
    if (field?.componentType === COMPONENT_TYPE.STRING) {
      const rules = [
        { required: field?.mandatory, whitespace: field?.mandatory, message: t('message.required') },
        {
          min: field?.refName === SYSTEM_FIELD_NAME && field?.mandatory ? 2 : null,
          message: t('message.minLength', { min: 2 })
        }
      ];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            className={`c-custom-field type-string ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              form={form}
              defaultVal={field?.currentValue}
              restFormItem={{ name: field?.name, rules }}
              iShowTitle={true}
              isReadOnly={isReadOnly}
              placeholder={t('common.enterValue')}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.OPTION) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-option ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              form={form}
              defaultVal={field?.currentValue}
              options={
                Array.isArray(field?.data) && field?.data.length
                  ? field?.data.map(item => ({
                      label: item.label,
                      value: item.value
                    }))
                  : []
              }
              restFormItem={{ name: field?.name, rules }}
              restField={{ showSearch: true, allowClear: !field?.mandatory }}
              iShowTitle={true}
              isReadOnly={isReadOnly}
              placeholder={t('common.pleaseSelect')}
              onSelect={val => form.setFieldsValue({ [field?.refName]: val })}
              onClear={val => form.setFieldsValue({ [field?.refName]: val })}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.PICKLIST) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];
      const options =
        Array.isArray(field?.data) && field?.data.length
          ? field?.data.map(item => ({
              label: item.label,
              value: item.value
            }))
          : [];
      let defaultVal =
        Array.isArray(field?.currentValue) && field?.currentValue.length
          ? field?.currentValue.filter(v => options.some(o => o?.value === v))
          : [];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-picklist ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableSelectMultiOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              form={form}
              defaultVal={defaultVal}
              options={options}
              restFormItem={{ name: field?.name, rules }}
              restField={{ showSearch: true, allowClear: !field?.mandatory }}
              isReadOnly={isReadOnly}
              placeholder={t('common.pleaseSelect')}
              onChange={val => form.setFieldsValue({ [field?.refName]: val })}
              onClear={() => form.setFieldsValue({ [field?.refName]: [] })}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.STATUS) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            rules={rules}
            className={`c-custom-field type-status ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            {field?.currentValue ? (
              <StatusLabelForWorkflow
                status={field?.currentValue}
                workTicketId={field?.ticketId}
                allowChangeStatus={false}
                showViewWorkflowButton={true}
              />
            ) : (
              <span className="text-gray">N/A</span>
            )}
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.USER) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            rules={rules}
            className={`c-custom-field type-user ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            {isReadOnly ? (
              field?.currentValue ? (
                <div className="text-truncate">
                  <UserAvatar user={field?.currentValue} />
                </div>
              ) : (
                <span className="text-gray">N/A</span>
              )
            ) : (
              <EditableSelectOption
                restValueText={{ id: `value_${field?.refName}-field` }}
                restEditButton={{ id: `edit-button_${field?.refName}-field` }}
                defaultVal={field?.currentValue || UNASSIGNED.value}
                options={
                  Array.isArray(userList) && userList.length
                    ? [
                        UNASSIGNED,
                        ...userList.map(item => {
                          return {
                            label: <UserAvatar user={item} />,
                            value: item.username
                          };
                        })
                      ]
                    : [UNASSIGNED]
                }
                restField={{
                  filterOption: false,
                  optionFilterProp: 'value',
                  allowClear: !field?.mandatory,
                  dropdownMatchSelectWidth: false
                }}
                loading={loadingProjectUserList}
                placeholder={t('common.pleaseSelect')}
                isReadOnly={isReadOnly}
                onSearch={val => {
                  clearTimeout(typingTimerOfSearch);

                  typingTimerOfSearch = setTimeout(
                    debounce(() => setUserSearchText(val)),
                    300
                  );
                }}
                onSelect={val => onSave({ [field?.refName]: val })}
              />
            )}
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.PRIORITY) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-priority ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            {field?.currentValue ? (
              <EditableSelectOption
                restValueText={{ id: `value_${field?.refName}-field` }}
                restEditButton={{ id: `edit-button_${field?.refName}-field` }}
                form={form}
                defaultVal={field?.currentValue}
                options={PRIORITIES}
                hasPrefixIcon={true}
                restFormItem={{
                  name: SYSTEM_FIELD_PRIORITY,
                  rules
                }}
                restField={{ allowClear: !field?.mandatory }}
                placeholder={t('common.pleaseSelect')}
                isReadOnly={isReadOnly}
              />
            ) : (
              <span className="text-gray">N/A</span>
            )}
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-time-tracking ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            {field?.currentValue ? (
              <EditableInput
                restValueText={{ id: `value_${field?.refName}-field` }}
                restEditButton={{ id: `edit-button_${field?.refName}-field` }}
                restSaveButton={{ id: `save-button_${field?.refName}-field` }}
                restCloseButton={{ id: `close-button_${field?.refName}-field` }}
                form={form}
                defaultVal={
                  /^[0-9]*$/.test(field?.currentValue) ? convertMinutesToShortTime(field?.currentValue) : null
                }
                restFormItem={{
                  name: field?.name,
                  rules: rules
                }}
                restField={{ allowClear: !field?.mandatory }}
                iShowTitle={true}
                isReadOnly={isReadOnly}
                placeholder={t('workItem.enterEstimatedTime')}
              />
            ) : (
              <span className="text-gray">N/A</span>
            )}
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.DATE || field?.componentType === COMPONENT_TYPE.DATE_TIME) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-date-time-date ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              type={field?.componentType === COMPONENT_TYPE.DATE ? 'DATEPICKER' : 'DATETIMEPICKER'}
              form={form}
              defaultVal={field?.currentValue}
              restFormItem={{ name: field?.refName, rules }}
              restField={{ allowClear: !field?.mandatory }}
              iShowTitle={true}
              isReadOnly={isReadOnly}
              onSave={val => {
                onSave({
                  [field?.refName]: val && moment(val).isValid() ? moment(val).format() : null
                });
              }}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.NUMBER) {
      const rules = [
        { required: field?.mandatory, message: t('message.required') },
        { pattern: NUMBER_PATTERN, message: t('message.fieldMustBeANumber') }
      ];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-number ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              form={form}
              defaultVal={field?.currentValue}
              restFormItem={{ name: field?.refName, rules }}
              iShowTitle={true}
              isReadOnly={isReadOnly}
              placeholder={t('common.enterValue')}
              onChange={() => form.validateFields([field?.refName])}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.URL) {
      const rules = [
        { required: field?.mandatory, message: t('message.required') },
        { pattern: URL_PATTERN, message: t('message.invalidFormat') }
      ];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-url ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              form={form}
              defaultVal={field?.currentValue}
              restFormItem={{ name: field?.refName, rules }}
              iShowTitle={true}
              isLink={true}
              isReadOnly={isReadOnly}
              placeholder={t('common.enterValue')}
              onChange={() => form.validateFields([field?.refName])}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.HTML) {
      const rules = [{ required: field?.mandatory, whitespace: field?.mandatory, message: t('message.required') }];

      return (
        <>
          <Form.Item
            labelCol={{ span: 24 }}
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            required={field?.mandatory}
            className={`c-custom-field type-html ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              type="EDITOR"
              form={form}
              defaultVal={field?.currentValue}
              restFormItem={{ name: field?.refName, rules }}
              restField={{ rows: 10 }}
              uploadPath={uploadPath}
              isReadOnly={isReadOnly}
              placeholder={t('common.enterValue')}
              className="pt-1"
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.SUGGESTION || field?.componentType === COMPONENT_TYPE.RELATION) {
      const rules = [{ required: field?.mandatory, message: t('message.required') }];

      let fields = ticketListData?.[field?.lookup?.workTicketId]?.fields;
      fields = Array.isArray(fields) && fields.length ? [...fields] : [];
      const workFlow = ticketListData?.[field?.lookup?.workTicketId]?.workFlow;

      const fieldToDisplay = fields.find(f => f?.refName === field?.data?.displayField);
      const relationItem = field?.currentValue;
      const relationItemKey = field?.currentValue?.[SYSTEM_FIELD_KEY];
      const relationLabel = getRelationLabel({ relationItem, fieldToDisplay, workFlow });

      const link = (
        <a
          title={`${relationItemKey} - ${relationLabel?.plainText || ''}`}
          href={`${getPrePathLinkToTicket({ workTicketId: field?.lookup?.workTicketId, noGoToTestcaseVersion: true })}${
            relationItem?.[SYSTEM_FIELD_KEY]
          }`}
          target="_blank"
          rel="noreferrer"
          className={`component-type-${fieldToDisplay?.componentType} ref-name-${fieldToDisplay?.refName} text-truncate`}
          onClick={e => e.stopPropagation()}
        >
          {relationItemKey}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
        </a>
      );

      const defaultValueForSuggestionField = relationItem?.[SYSTEM_FIELD_KEY] ? (
        link
      ) : isReadOnly ? (
        <span className="text-gray">N/A</span>
      ) : (
        <span className="text-gray">{t('common.pleaseSelect')}</span>
      );

      const options =
        Array.isArray(suggestionData?.[field?.refName]?.rows) && suggestionData?.[field?.refName]?.rows?.length
          ? suggestionData?.[field?.refName]?.rows?.map(item => {
              const key = item?.[SYSTEM_FIELD_KEY];
              const relationLabel = getRelationLabel({ relationItem: item, fieldToDisplay, workFlow });

              return {
                title: `${key} - ${relationLabel?.plainText || ''}`,
                label: (
                  <div className="text-truncate">
                    {key}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
                  </div>
                ),
                value: item?.[field?.data?.fieldValue]
              };
            })
          : [];

      return (
        <>
          <Form.Item
            label={
              <span id={`label_${field?.refName}-field`} title={field?.name}>
                {field?.name}
              </span>
            }
            name={field?.refName}
            className={`c-custom-field type-relation ${field?.mandatory ? 'has-icon-required' : ''} ${className}`}
            {...rest}
          >
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              form={form}
              defaultVal={objectPath.get(relationItem, field?.data?.fieldValue)}
              defaultValueForSuggestionField={defaultValueForSuggestionField}
              options={options}
              restFormItem={{ name: field?.name, rules }}
              restField={{
                optionFilterProp: 'title',
                showSearch: true,
                allowClear: !field?.mandatory,
                filterOption: false,
                loading: loadingSuggestion,
                onPopupScroll: e => {
                  if (loadingSuggestion) return;

                  if (
                    e?.target?.scrollTop + e?.target?.offsetHeight === e?.target?.scrollHeight &&
                    !suggestionParams?.[field?.refName]?.isLoadedAll
                  ) {
                    const page = suggestionParams?.[field?.refName]?.page;
                    handleGetSuggestionData({ field, page: page + 1, isLoadType: 'SCROLL' });
                  }
                },
                onSearch: keyword => {
                  setSuggestionKeyword({ ...suggestionKeyword?.[field?.refName], [field?.refName]: keyword });
                  onSearchSuggestionData({ field, keyword, isLoadType: 'SEARCH' });
                }
              }}
              isReadOnly={isReadOnly}
              placeholder={t('common.pleaseSelect')}
              onFocus={() => handleGetSuggestionData({ field, page: 1, isLoadType: 'SCROLL' })}
              onSelect={val => form.setFieldsValue({ [field?.refName]: val })}
              onSave={val => onSave({ [field?.refName]: val })}
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return renderFieldItem();
};
