import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  testCaseExportedFieldList: [],
  loadingTestCaseExportedFieldList: false,
  loadingTestCaseExportedData: false,

  /**
   * Action: Set test case exported field list
   */
  setTestCaseExportedFieldList: action((state, payload) => {
    if (state?.testCaseExportedFieldList === undefined) return;

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.testCaseExportedFieldList = [];
      return;
    }

    state.testCaseExportedFieldList = payload.data;
  }),

  /**
   * Action: Set loading test case exported field list
   */
  setLoadingTestCaseExportedFieldList: action((state, payload) => {
    if (state?.loadingTestCaseExportedFieldList === undefined) return;

    state.loadingTestCaseExportedFieldList = payload;
  }),

  /**
   * Action: Set loading test case exported data
   */
  setLoadingTestCaseExportedData: action((state, payload) => {
    if (state?.loadingTestCaseExportedData === undefined) return;

    state.loadingTestCaseExportedData = payload;
  }),

  /**
   * Action: Call api to get test case exported excel testcase
   */
  getTestCaseExportedFieldList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingTestCaseExportedFieldList(true);

      const { tenantId, projectId } = payload;
      const url = `${ENDPOINTS.EXPORT_GET_FIELD}/tenant-${tenantId}/project-${projectId}?type=repository`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseExportedFieldList(res);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestCaseExportedFieldList(false);
    }
  }),

  /**
   * Action: Call api to get test case exported csv testcase
   */
  getTestCaseExportedCSVData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingTestCaseExportedData(true);

      const { collectionName, fieldList, option } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EXPORT}/csv/${collectionName}?${option}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, { fieldList }, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestCaseExportedData(false);
    }
  }),

  /**
   * Action: Call api to get test case exported data
   */
  getTestCaseExportedExcelData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingTestCaseExportedData(true);

      const { collectionName, fieldList, option } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EXPORT}/excel/${collectionName}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, { fieldList, option }, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestCaseExportedData(false);
    }
  })
};
