import React, { useEffect, useMemo, useState } from 'react';
import { Button, Divider, Form, Spin } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { SaveOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { arrayMoveImmutable } from 'array-move';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_NAME,
  SYSTEM_FIELD_ATTACHMENTS,
  SYSTEM_FIELD_DESCRIPTION
} from '../../constants';
import {
  useFile,
  jsonParse,
  scrollToFirstClassName,
  convertUserForSubmitData,
  convertEstimatedTimeToMinutes
} from '../../common';
import { BasicPasteUploadFile } from '../basic-paste-upload-file';
import { WorkItemField } from '../../components/work-item-field';

const primitiveFieldName = {
  name: [SYSTEM_FIELD_NAME],
  description: [SYSTEM_FIELD_DESCRIPTION]
};

export default ({ onPrevious, defect, handleCreateRelation, loading = false }) => {
  // For language
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For upload
  const { getUploadPath } = useFile();

  // For global store
  const loadingObjectiveItem = useStoreState(state => state.global.loadingObjectiveItem);
  const fieldListData = useStoreState(state => state.global.fieldList)?.[defect?.workItemType];
  const loadingRelationItem = useStoreState(state => state.global.loadingRelationItem);
  const loadingFieldList = useStoreState(state => state.global.loadingFieldList);
  const getFieldList = useStoreActions(action => action.global.getFieldList);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);

  // For store action
  const loadingWorkTicketItem = useStoreState(state => state.managerWorkItem.loadingWorkTicketItem);

  // Component state
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]);
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [currentEditorAttachFileList, setCurrentEditorAttachFiles] = useState([]);

  /**
   * Compute: field list
   */
  const fieldList = useMemo(() => {
    if (!(Array.isArray(fieldListData) && fieldListData.length)) return;

    const attachments = {
      name: 'Attachments',
      refName: SYSTEM_FIELD_ATTACHMENTS,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      type: FIELD_SYSTEM,
      isInsert: true
    };

    let newFieldList = [...fieldListData, attachments];

    if (newFieldList.some(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS)) {
      const oldIndex = newFieldList.findIndex(field => field.componentType === COMPONENT_TYPE.ATTACHMENTS);
      const descriptionFieldIndex = newFieldList.findIndex(field => field.refName === SYSTEM_FIELD_DESCRIPTION);

      newFieldList = arrayMoveImmutable(newFieldList, oldIndex, descriptionFieldIndex + 1);
    }

    return newFieldList;
  }, [fieldListData]);

  /**
   * Set initial primitive field
   */
  useEffect(() => {
    if (!defect) return;

    form.setFieldsValue({
      [primitiveFieldName.name]: defect.item
    });
  }, [defect]);

  /**
   * Get field list
   */
  useEffect(() => {
    if (!defect?.workItemType || Array.isArray(fieldList) || fieldList?.length || fieldList) return;

    getFieldList(defect.workItemType);
  }, [defect, fieldList]);

  /**
   * On change current attachment list
   */
  const onChangeCurrentAttachmentList = async attachments => {
    await getFullFilesInfo(attachments);
    setCurrentAttachmentList(attachments);
  };

  /**
   * On attach file
   */
  const onEditorAttachFiles = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) return;

    const newList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList, ...attachments]
        : [...attachments];

    setCurrentEditorAttachFiles(newList);
  };

  /**
   * On search
   */
  const hanldeSubmit = async values => {
    if (!values || loadingWorkTicketItem || !defect?.workItemType) return;

    const primitiveValue = {};

    if (values[primitiveFieldName.name]) {
      primitiveValue.name = values[primitiveFieldName.name];
    }

    if (values[primitiveFieldName.description]) {
      primitiveValue.description = values[primitiveFieldName.description];
    }

    const formData = {
      workTicketType: defect?.workItemType,
      ...primitiveValue
    };

    // Push field to form data
    Object.keys(values).forEach(key => {
      const field = fieldList.find(item => item?.refName === key);

      if (values[key] && field && field.refName) {
        switch (field?.refName) {
          case SYSTEM_FIELD_TAG: {
            if (Array.isArray(values[key]) && values[key].length) {
              formData[field.refName] = values[key].join(',');
            }
            break;
          }

          default: {
            switch (field?.componentType) {
              case COMPONENT_TYPE.SUGGESTION: {
                formData[field.refName] = jsonParse(values[key]);
                break;
              }

              case COMPONENT_TYPE.PICKLIST: {
                formData[field.refName] = values[key];
                break;
              }

              case COMPONENT_TYPE.USER: {
                if (Array.isArray(projectUserList) && projectUserList.length) {
                  const user = projectUserList.find(u => u?.username === values[key]);

                  formData[field.refName] = convertUserForSubmitData(user);
                }

                break;
              }

              case COMPONENT_TYPE.TIME_TRACKING: {
                formData[field.refName] = convertEstimatedTimeToMinutes(values[key]);
                break;
              }

              case COMPONENT_TYPE.DATE:
              case COMPONENT_TYPE.DATE_TIME: {
                formData[field.refName] = moment(values[key]).format();
                break;
              }

              default: {
                formData[field.refName] = values[key];
                break;
              }
            }
          }
        }
      }
    });

    // Attachments
    const attachmentList =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : [];

    const editorAttachFileList =
      Array.isArray(currentEditorAttachFileList) && currentEditorAttachFileList.length
        ? [...currentEditorAttachFileList]
        : [];

    formData[SYSTEM_FIELD_ATTACHMENTS] = [...attachmentList, ...editorAttachFileList];

    // Handle delete multiple attachments
    if (Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length) {
      deleteFiles({ globalTenant, globalProject, ids: [...deteledAttachmentIds] });
      setDeteledAttachmentIds([]);
    }

    handleCreateRelation(formData);
  };

  return (
    <>
      <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          <Form
            id="defectAkaatManagerForm"
            form={form}
            layout="vertical"
            onFinish={value => {
              setTimeout(() => scrollToFirstClassName('ant-form-item-has-error', 0), 300);
              hanldeSubmit(value);
            }}
          >
            <Spin
              indicator={<Loading3QuartersOutlined spin />}
              spinning={
                loadingFieldList || loadingRelationItem || loadingWorkTicketItem || loadingObjectiveItem || loading
              }
            >
              {Array.isArray(fieldList) &&
                fieldList.length &&
                fieldList
                  .filter(field => field.isInsert)
                  .map((field, index) => {
                    return (
                      <WorkItemField
                        key={index}
                        form={form}
                        field={field}
                        uploadPath={getUploadPath(defect?.workItemType)}
                        currentAttachmentList={currentAttachmentList}
                        onChangeCurrentAttachmentList={setCurrentAttachmentList}
                        onChangeDeteledAttachmentIds={ids => setDeteledAttachmentIds([...deteledAttachmentIds, ...ids])}
                        onEditorAttachFiles={onEditorAttachFiles}
                      />
                    );
                  })}
            </Spin>
          </Form>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button onClick={onPrevious}>{t('common.previous')}</Button>

          <Button
            className="ml-2"
            htmlType="submit"
            form="defectAkaatManagerForm"
            type="primary"
            icon={<SaveOutlined />}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>

      {defect?.workItemType && (
        <BasicPasteUploadFile
          attachments={currentAttachmentList}
          acceptMineTypes={['image/']}
          uploadPath={getUploadPath(defect?.workItemType)}
          onChangeAttachments={onChangeCurrentAttachmentList}
        />
      )}
    </>
  );
};
