import React, { useRef, useEffect, useState, useMemo } from 'react';
import objectPath from 'object-path';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

import { FULL_DATE_FORMAT, SS_LAST_SAVED_VALUE } from '../../../../constants';
import { checkValidField, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import DatetimeKeyboardEvents from './datetime-keyboard-events';
import DatetimeFormItem from './datetime-form-item';

const DatetimeField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  const refInput = useRef(null);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenCalendar(false);
    }

    setEditingCell(info);
  };

  /**
   * Open calendar when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenCalendar(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => {
    const val = objectPath.get(row, field?.refName);

    return val && moment(val).isValid() ? moment(val) : null;
  }, [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const val = getCurrentValue(defaultVal);
      const validDate = val && moment(val).isValid() ? moment(val) : null;
      tableForm.setFieldsValue({ [formItemName]: validDate });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultVal]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenCalendar(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = date => {
    if (row?.isNew) {
      setIsOpenCalendar(false);
      setCurrentValue(date);
      return;
    }

    setIsOpenCalendar(false);
    setVisibleFormItem(false);
    setCurrentValue(date);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
    refInput?.current && refInput.current.focus({ cursor: 'end' });
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async val => {
    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !(val && moment(val).isValid()))) {
      handleClose(defaultValue);
      return;
    }

    const newValue = val && moment(val).isValid() ? moment(val).format() : null;
    const oldValue = defaultValue && moment(defaultValue).isValid() ? moment(defaultValue).format() : null;

    if (newValue !== oldValue) {
      handleSetLastSavedValueToSession({ [formItemName]: newValue });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: newValue }, row, field });
      }
    }

    handleClose(val);
  };

  /**
   * Handle open change
   */
  const handleOpenChange = visible => {
    if (row?.isNew) {
      setIsOpenCalendar(visible);
      return;
    }

    setIsOpenCalendar(visible);
    setTimeout(() => handleSave(currentValue), 200);
  };

  /**
   * Input props
   */
  const inputProps = {
    ref: refInput,
    open: isOpenCalendar,
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    format: FULL_DATE_FORMAT,
    showTime: { format: 'HH:mm' },
    placeholder: placeholder || FULL_DATE_FORMAT,
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onOpenChange: handleOpenChange,
    onChange: val => {
      setCurrentValue(val);
      if (row?.isNew) handleSave(val);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onClick: () => {
      if (!row?.isNew) handleOpen();
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const val = getCurrentValue(currentValue);
    const dateString = val && moment(val).isValid() ? moment(val).format(FULL_DATE_FORMAT) : '';

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={dateString} className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value min-h-22 text-truncate">{dateString || props?.placeholder}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={dateString} row={row} field={field}>
          {dateString}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={dateString} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {dateString}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <span title={dateString}>{dateString}</span>;
    }

    // Readonly
    else {
      return (
        <div title={dateString} className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{dateString}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <DatetimeFormItem
        field={field}
        formItemName={formItemName}
        inputProps={inputProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <DatetimeKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{FULL_DATE_FORMAT}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={handleSetEditingCell}
          />
        )}

        <div className={`field-wrapper datetime-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new datetime-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler
      onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell: handleSetEditingCell })}
    >
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default DatetimeField;
