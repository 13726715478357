import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import env from './env';

export const reactPlugin = new ReactPlugin();

let appInsights;
if (env.REACT_APP_ENV === 'production') {
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        'InstrumentationKey=cddfcb6b-afaa-4023-8d11-d9697e3e27ae;IngestionEndpoint=https://southeastasia-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southeastasia.livediagnostics.monitor.azure.com/',
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
}

export { appInsights };
