import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { ORDER_BY_KEY_DESC } from '../../constants';
import { debounce } from '../../common';

let typingTimerOfSearch = 0;

export const FieldSuggestion = ({
  field,
  currentValues,
  allowSearch = true,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [formData, setFormData] = useState([]);
  const [options, setOptions] = useState([]);
  const [formOption, setFormOption] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (
      !field?.refName ||
      !field?.data?.url ||
      !(Array.isArray(suggestion?.[field?.refName]?.data) && suggestion?.[field?.refName]?.data.length)
    ) {
      setOptions([]);
      return;
    }

    const newOptionList = suggestion[field.refName].data.map(item => {
      return {
        label: objectPath.get(item, field.data.fieldLabel),
        value: objectPath.get(item, field.data.fieldValue)
      };
    });

    setOptions(newOptionList);
  }, [t, field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (!field?.refName || !(Array.isArray(currentValues) && currentValues.length)) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.refName === field.refName);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(
      item => currentValue.includes(item.value) || currentValue.includes(Number(item.value))
    );
    return newSelectedOptions?.length ? newSelectedOptions : currentValue;
  }, [field, options, currentValues]);

  /**
   * On visible change
   */
  const onVisibleChange = async visible => {
    setVisibleDropdown(visible);

    if (visible) setSelectedOptions(currentSelectedOptions);

    if (visible && field?.refName && field?.data?.url && !suggestion?.[field.refName]?.loadedCount) {
      const res = await getSuggestion({
        referenceField: field.refName,
        url: field.data.url,
        limit: 10,
        page: 1,
        order: ORDER_BY_KEY_DESC,
        group: field.data.fieldValue,
        select: `${field.data.fieldValue} ${field.data.fieldLabel}`
      });

      setFormOption({
        limit: 10,
        page: 1
      });

      setFormData({
        rows: res?.rows || [],
        count: res?.count || 0
      });
    }
    if (!visible) {
      handleSearch('');
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (loadingSuggestion || !currentOption?.value) return;

    let newSelectedValues =
      Array.isArray(selectedOptions) && selectedOptions.length
        ? [...selectedOptions].filter(item => item.value !== currentOption.value)
        : [];

    if (checked) newSelectedValues = [...newSelectedValues, currentOption];

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) return;

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const queryObj = { $or: [] };

        if (field.data?.suggestionBy && Array.isArray(field.data.suggestionBy) && field.data.suggestionBy.length) {
          field.data.suggestionBy.forEach(sub => {
            queryObj.$or.push({
              [sub]: { $regex: val, $options: 'i' }
            });
          });
        }

        getSuggestion({
          referenceField: field.refName,
          url: field.data?.url,
          page: 0,
          limit: 10,
          order: ORDER_BY_KEY_DESC,
          filter: queryObj.$or.length ? queryObj : {},
          group: field.data.fieldValue,
          select: `${field.data.fieldValue} ${field.data.fieldLabel}`
        });
      }),
      300
    );
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    const selectedValue =
      Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.map(item => item.value) : [];

    return (
      <div className="ant-dropdown-menu c-field-suggestion-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingSuggestion}>
          {allowSearch && (
            <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
              <Input
                placeholder={t('common.search')}
                suffix={<SearchOutlined />}
                autoFocus
                autoComplete="off"
                allowClear
                size="small"
                onChange={e => handleSearch(e?.target?.value)}
              />
            </div>
          )}

          {!(Array.isArray(options) && options.length > 0) && (
            <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                onClick={e => e.stopPropagation()}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
              </li>
            </ul>
          )}

          {Array.isArray(options) && options.length > 0 && (
            <ul
              className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
              onClick={e => e.stopPropagation()}
              onScroll={async e => {
                const { target } = e;
                if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
                  // if not load all;
                  if (formData?.rows?.length < formData?.count) {
                    const currentPage = formOption?.page + 1;

                    const res = await getSuggestion({
                      referenceField: field?.refName,
                      url: field?.data.url,
                      filter: field?.data.filter || null,
                      limit: 10,
                      page: currentPage,
                      group: field?.data.fieldValue,
                      order: ORDER_BY_KEY_DESC,
                      select: `${field.data.fieldValue} ${field.data.fieldLabel}`
                    });

                    const newData = {
                      rows: [...formData?.rows, ...res?.rows],
                      count: res?.count
                    };

                    setOptions(
                      newData.rows.map(item => {
                        return {
                          label: objectPath.get(item, field.data.fieldLabel),
                          value: objectPath.get(item, field.data.fieldValue)
                        };
                      })
                    );
                    setFormData(newData);
                  }
                }
              }}
            >
              {options.map(item => (
                <li
                  key={item.value}
                  title={item.label}
                  className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox
                    checked={selectedValue.includes(item.value)}
                    className="w-100"
                    onChange={e => handleChangeSelected(e?.target?.checked, item)}
                  >
                    {item.label}
                  </Checkbox>
                </li>
              ))}
            </ul>
          )}

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
                handleSearch('');
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                !selectedOptions.length && handleSearch('');
                const newSelectedOptions = selectedOptions.map(selected => selected.value);
                onApply(newSelectedOptions);
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          Array.isArray(currentSelectedOptions) && currentSelectedOptions.length
            ? `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${currentSelectedOptions.map(item => item.label).join(', ')}`
            : `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${t('common.all')}`
        }
        className={`btn-field field-suggestion btn-toggle-dropdown-with-checkbox-list ${className}`}
        {...rest}
      >
        {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {currentSelectedOptions.map(item => item.label).join(', ')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        onOpenChange={onVisibleChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
