import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { TEST_ITEM_COLORS, ENGINE_TEST_RESULT_STATUSES } from '../../constants';
import { getObjectByValue, getTotalUnicodeIndexOfString } from '../../common';
import { BasicProgressBar } from '../../components';

import './style.scss';

export const TestResultStatusProgress = ({ jobStatuses, loading = false, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For query params on url
  const [queryParams] = useQueryParams({
    treeKey: StringParam
  });

  // Component state
  const [progressData, setProgressData] = useState({});

  /**
   * Set progress data
   */
  useEffect(() => {
    if (!(Array.isArray(jobStatuses) && jobStatuses.length)) {
      setProgressData({});
      return;
    }

    const newProgressData = convertToJobStatus(jobStatuses, queryParams?.treeKey);

    setProgressData(newProgressData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobStatuses, queryParams]);

  /**
   * Convert to job status
   *
   * @param {array} jobStatusList - Example: [{"Not Executed":1,"Pass":2,"WIP":1,"Fail":1,"total":6},{"WIP":2,"Not Executed":1,"Pass":2,"total":5}]
   */
  const convertToJobStatus = (jobStatusList, treeKey) => {
    if (!(Array.isArray(jobStatusList) && jobStatusList.length)) return;

    let statusData = {};

    if (!treeKey || treeKey === 'id-all') {
      const arr = jobStatusList.map(item => Object.values(item)[0]); // Example: [{"Not Executed":1,"Pass":2,"WIP":1,"Fail":1,"total":6},{"WIP":2,"Not Executed":1,"Pass":2,"total":5}]

      statusData = arr.reduce((accumulator, current) => {
        if (!accumulator) return current; // First loop

        const newItem = { ...accumulator };
        const accumulatorKeys = Object.keys(accumulator);

        Object.keys(current).forEach(key => {
          const countAccumulator = newItem[key] > 0 ? newItem[key] : 0;
          const countCurrent = current[key] > 0 ? current[key] : 0;

          if (accumulatorKeys.includes(key)) {
            newItem[key] = countAccumulator + countCurrent;
          } else {
            newItem[key] = countCurrent;
          }
        });

        return newItem;
      }, null); // Example: {Not Executed: 2, STOPPED: 1, Pass: 4, WIP: 3, Fail: 1, …} {"Not Executed":2,"Pass":4,"WIP":3,"Fail":1,"total":11}
    } else {
      statusData = Object.values(jobStatusList[0])[0]; // Example: {"WIP":1,"Pass":2,"Not Executed":1,"Fail":1,"total":6}
    }

    const progressData = {
      total: statusData?.total
    };
    const progressList = [];

    if (statusData !== null && typeof statusData === 'object' && Object.keys(statusData).length > 0) {
      Object.keys(statusData).forEach(key => {
        if (key === 'total') return;

        const found = getObjectByValue(key, ENGINE_TEST_RESULT_STATUSES);

        const newItem = {
          statusKey: key,
          label: found ? t(found.label) : key,
          value: statusData[key],
          color: found ? found.color : TEST_ITEM_COLORS[getTotalUnicodeIndexOfString(key) % TEST_ITEM_COLORS.length]
        };

        progressList.push(newItem);
      });
    }

    progressData.data = [...progressList].sort((a, b) => {
      if (a.statusKey > b.statusKey) return 1;
      if (a.statusKey < b.statusKey) return -1;
      return 0;
    });

    return progressData;
  };

  return (
    <>
      <div className={`c-test-result-status-progress ${className}`} {...rest}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
          <div className="box-progress">
            <Row>
              <Col xs={24} md={11}>
                <Row>
                  <Col flex="auto">
                    <div className="txt-number">{progressData?.total > 0 ? progressData?.total : 0}</div>
                    <div className="txt-label">Total</div>
                  </Col>

                  {Array.isArray(progressData?.data) &&
                    progressData?.data.length > 0 &&
                    progressData?.data.map(item => (
                      <Col key={item.label} flex="auto" className="px-2">
                        <div className="txt-number">{item.value}</div>
                        <div className="txt-label">{item.label}</div>
                      </Col>
                    ))}
                </Row>
              </Col>

              <Col xs={24} md={2}></Col>

              <Col xs={24} md={11}>
                <BasicProgressBar progressData={progressData} showItemTooltip={true} className="mb-3" />

                <Row>
                  {Array.isArray(progressData?.data) &&
                    progressData?.data.length > 0 &&
                    progressData?.data.map(item => {
                      const found = getObjectByValue(item.statusKey, ENGINE_TEST_RESULT_STATUSES);

                      return (
                        <div key={item.statusKey} className="box-status-dot mr-4 mb-1">
                          <span
                            className="mr-2"
                            style={{
                              background: found
                                ? found.color
                                : TEST_ITEM_COLORS[
                                    getTotalUnicodeIndexOfString(item.statusKey) % TEST_ITEM_COLORS.length
                                  ]
                            }}
                          ></span>

                          {found ? t(found.label) : item.statusKey}
                        </div>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    </>
  );
};
