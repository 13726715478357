import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Avatar, Input, Select, DatePicker } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  NUMBER_PATTERN,
  JIRA_DATA_TYPE_STRING,
  JIRA_DATA_TYPE_ARRAY,
  JIRA_DATA_TYPE_DATE,
  JIRA_DATA_TYPE_NUMBER,
  JIRA_DATA_TYPE_USER,
  JIRA_DATA_TYPE_OPTION,
  JIRA_DATA_TYPE_PRIORITY,
  JIRA_DATA_TYPE_TIMETRACKING,
  ESTIMATED_TIME_PATTERN,
  JIRA_DATA_TYPE_ATTACHMENT,
  ENDPOINTS,
  JIRA_DATA_TYPE_DATE_TIME,
  JIRA_DATA_TYPE_SPRINT_ID,
  JIRA_DATA_EPIC_LINK_ID,
  JIRA_DATA_PRODUCT_ID,
  JIRA_DATA_TYPE_ISSUELINKS,
  JIRA_DATA_TYPE_ISSUELINK_TYPES_ID
} from '../../constants';
import { debounce } from '../../common';
import { SafeInnerHtml } from '../safe-inner-html';

import './styles.scss';

let typingTimerOfSearch = 0;

export const JiraCustomField = ({ field, config = {}, form, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  const JIRA_DOAMIN = `https://${config.host}`;
  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // For jira integration store
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);
  const getSuggestionList = useStoreActions(action => action.jiraIntegration.getSuggestionList);
  const suggestionList = useStoreState(state => state.jiraIntegration.suggestionList);
  const assignableUserList = useStoreState(state => state.jiraIntegration.assignableUserList);
  const loadingAssignableUserList = useStoreState(state => state.jiraIntegration.loadingAssignableUserList);

  /**
   * Return html
   */
  if (field?.schema?.type === JIRA_DATA_TYPE_STRING) {
    const rules = [{ required: field?.mandatory, whitespace: true, message: t('message.required') }];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Input style={{ width: '50%' }} placeholder={t('common.enterValue')} />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_TIMETRACKING) {
    const rules = [
      { required: field?.mandatory, message: t('message.required') },
      { pattern: ESTIMATED_TIME_PATTERN, message: t('message.invalidFormat') }
    ];

    return (
      <>
        <Form.Item
          label={field?.name}
          tooltip={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
          className="mb-3"
          name={field?.fieldId}
          rules={rules}
          validateTrigger={['onBlur']}
        >
          <Input style={{ width: '50%' }} placeholder={t('common.enterValue')} />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.system === JIRA_DATA_TYPE_ATTACHMENT) {
    return;
    // const rules = [{ required: field?.mandatory, message: t('message.required') }];

    // return (
    //   <>
    //     <Form.Item
    //       label={field?.name}
    //       name={field?.fieldId}
    //       rules={rules}
    //       className="c-work-item-field type-attachments"
    //       {...rest}
    //     >
    //       <BasicUploadMultipleFiles
    //         uploadPath={`${ENDPOINTS.INTEGRATION}/${tenantPath}/${projectPath}/${ENDPOINTS.JIRA_ISSUE_USER_ATTACHMENT}`}
    //         // attachments={
    //         //   Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : []
    //         // }
    //         directlyDeleteAttachment={false}
    //         onChangeAttachments={list => {
    //           // if (typeof onChangeCurrentAttachmentList === 'function') onChangeCurrentAttachmentList(list);
    //           // if (typeof onChange === 'function') onChange(list);
    //         }}
    //         onChangeDeteledAttachmentIds={list => {
    //           // if (typeof onChangeDeteledAttachmentIds === 'function') onChangeDeteledAttachmentIds(list);
    //         }}
    //       />
    //     </Form.Item>
    //   </>
    // );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_USER) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            loading={loadingAssignableUserList}
            style={{ width: '50%' }}
            onSearch={val => {
              clearTimeout(typingTimerOfSearch);

              typingTimerOfSearch = setTimeout(
                debounce(() => {
                  getAssignableUserList({
                    username: val
                  });
                }),
                500
              );
            }}
            onFocus={() => {
              if (!Array.isArray(assignableUserList) || !assignableUserList.length) {
                getAssignableUserList({
                  username: ''
                });
              }
            }}
            optionFilterProp="value"
            showSearch
            allowClear
            filterOption={false}
            placeholder={t('common.searchUser')}
            className="w-100"
          >
            {Array.isArray(assignableUserList) &&
              assignableUserList.length &&
              assignableUserList.map(user => {
                return (
                  <Select.Option value={user?.name || user?.accountId} key={user?.key} title={user?.displayName}>
                    <Avatar size={18} src={user?.avatarUrls['16x16']} className="mr-1" /> {user?.displayName}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.system === JIRA_DATA_TYPE_ISSUELINKS) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];
    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Form.Item name={[field?.fieldId, 'linkedType']} noStyle>
            <Select
              style={{ width: '25%' }}
              onFocus={() => {
                getSuggestionList({
                  fieldId: JIRA_DATA_TYPE_ISSUELINK_TYPES_ID,
                  autoCompleteUrl: `${JIRA_DOAMIN}${ENDPOINTS.JIRA_REST_API_ISSUE_LINK_TYPES}`,
                  projectKey: globalProject?.projectKey,
                  tenantKey: globalTenant?.tenantKey,
                  value: ''
                });
              }}
              optionFilterProp="value"
              showSearch
              filterOption={false}
              placeholder={t('common.pleaseSelect')}
              // className="w-100"
            >
              {Array.isArray(suggestionList?.issueLinkTypes) && suggestionList?.issueLinkTypes.length
                ? suggestionList?.issueLinkTypes.map(item => {
                    return (
                      <>
                        <Select.Option value={item?.id} key={item?.id} title={item?.name}>
                          {item?.name}
                        </Select.Option>
                      </>
                    );
                  })
                : []}
            </Select>
          </Form.Item>
          <Form.Item name={[field?.fieldId, 'issue']} className="mt-2 mb-0">
            <Select
              style={{ width: '50%' }}
              onSearch={val => {
                clearTimeout(typingTimerOfSearch);

                typingTimerOfSearch = setTimeout(
                  debounce(() => {
                    getSuggestionList({
                      fieldId: field?.fieldId,
                      autoCompleteUrl: field.autoCompleteUrl,
                      projectKey: globalProject?.projectKey,
                      tenantKey: globalTenant?.tenantKey,
                      value: val
                    });
                  }),
                  500
                );
              }}
              onFocus={() => {
                if (!Array.isArray(suggestionList[field.fieldId]) || !suggestionList[field.fieldId].length) {
                  getSuggestionList({
                    fieldId: field?.fieldId,
                    autoCompleteUrl: field.autoCompleteUrl,
                    projectKey: globalProject?.projectKey,
                    tenantKey: globalTenant?.tenantKey,
                    value: ''
                  });
                }
              }}
              optionFilterProp="value"
              showSearch
              allowClear
              filterOption={false}
              placeholder={t('common.searchIssue')}
              // className="w-100"
            >
              {Array.isArray(suggestionList?.[field.fieldId]?.sections?.[0]?.issues) &&
                suggestionList?.[field.fieldId]?.sections?.[0]?.issues.length &&
                suggestionList?.[field.fieldId]?.sections?.[0]?.issues.map(item => {
                  return (
                    <Select.Option value={item?.id} key={item?.key} title={item?.summary}>
                      <span className="mr-1">
                        <img src={`${JIRA_DOAMIN}${item.img}`} alt="" />
                      </span>
                      <span className="mr-1">{item?.keyHtml}</span>
                      {item?.summaryText}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form.Item>
      </>
    );
  }

  if (field?.fieldId === JIRA_DATA_TYPE_SPRINT_ID) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];
    const autoCompleteUrl = `${JIRA_DOAMIN}/rest/greenhopper/1.0/sprint/picker?query=`;
    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            options={[
              {
                label: 'Suggestions',
                options:
                  Array.isArray(suggestionList[field.fieldId]?.suggestions) &&
                  suggestionList[field.fieldId]?.suggestions?.length
                    ? suggestionList[field.fieldId].suggestions.map(item => ({
                        label: item.name,
                        value: item.id
                      }))
                    : []
              },
              {
                label: 'All Sprints',
                options:
                  Array.isArray(suggestionList[field.fieldId]?.allMatches) &&
                  suggestionList[field.fieldId]?.allMatches?.length
                    ? suggestionList[field.fieldId].allMatches.map(item => ({
                        label: item.name,
                        value: item.id
                      }))
                    : []
              }
            ]}
            onSearch={val => {
              clearTimeout(typingTimerOfSearch);

              typingTimerOfSearch = setTimeout(
                debounce(() => {
                  getSuggestionList({
                    fieldId: field?.fieldId,
                    autoCompleteUrl,
                    projectKey: globalProject?.projectKey,
                    tenantKey: globalTenant?.tenantKey,
                    value: val
                  });
                }),
                500
              );
            }}
            onFocus={() => {
              if (!Array.isArray(suggestionList[field.fieldId]) || !suggestionList[field.fieldId].length) {
                getSuggestionList({
                  fieldId: field?.fieldId,
                  autoCompleteUrl,
                  projectKey: globalProject?.projectKey,
                  tenantKey: globalTenant?.tenantKey,
                  value: ''
                });
              }
            }}
            optionFilterProp="label"
            showSearch
            allowClear
            placeholder={t('common.pleaseSelect')}
          />
        </Form.Item>
      </>
    );
  }
  if (field?.fieldId === JIRA_DATA_PRODUCT_ID) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];
    const autoCompleteUrl = `${JIRA_DOAMIN}/rest/api/2/search?jql=issuetype = Product&fields=summary`;
    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            options={
              Array.isArray(suggestionList[field.fieldId]?.issues) && suggestionList[field.fieldId]?.issues?.length
                ? suggestionList[field.fieldId].issues.map(item => ({
                    label: `${item.fields.summary} [${item.key}]`,
                    value: item.id
                  }))
                : []
            }
            onFocus={() => {
              if (!Array.isArray(suggestionList[field.fieldId]) || !suggestionList[field.fieldId].length) {
                getSuggestionList({
                  fieldId: field?.fieldId,
                  autoCompleteUrl,
                  projectKey: globalProject?.projectKey,
                  tenantKey: globalTenant?.tenantKey,
                  value: ''
                });
              }
            }}
            optionFilterProp="label"
            allowClear
            placeholder={t('common.pleaseSelect')}
          />
        </Form.Item>
      </>
    );
  }

  if (field?.fieldId === JIRA_DATA_EPIC_LINK_ID) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];
    const autoCompleteUrl = `${JIRA_DOAMIN}/rest/greenhopper/1.0/epics?searchQuery=&projectKey=${config.projectKey}&maxResults=100`;

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            options={
              Array.isArray(suggestionList[field.fieldId]?.epicLists) &&
              suggestionList[field.fieldId]?.epicLists?.length
                ? suggestionList[field.fieldId]?.epicLists?.map(epic => ({
                    label: epic.listDescriptor,
                    options:
                      Array.isArray(epic.epicNames) && epic.epicNames?.length
                        ? epic.epicNames.map(item => ({
                            label: item.name,
                            value: item.key
                          }))
                        : []
                  }))
                : []
            }
            onSearch={val => {
              clearTimeout(typingTimerOfSearch);

              typingTimerOfSearch = setTimeout(
                debounce(() => {
                  getSuggestionList({
                    fieldId: field?.fieldId,
                    autoCompleteUrl,
                    projectKey: globalProject?.projectKey,
                    tenantKey: globalTenant?.tenantKey,
                    value: val
                  });
                }),
                500
              );
            }}
            onFocus={() => {
              if (!Array.isArray(suggestionList[field.fieldId]) || !suggestionList[field.fieldId].length) {
                getSuggestionList({
                  fieldId: field?.fieldId,
                  autoCompleteUrl,
                  projectKey: globalProject?.projectKey,
                  tenantKey: globalTenant?.tenantKey,
                  value: ''
                });
              }
            }}
            optionFilterProp="label"
            showSearch
            allowClear
            placeholder={t('common.pleaseSelect')}
          />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_ARRAY) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            mode="tags"
            options={
              Array.isArray(field?.['allowedValues']) && field?.['allowedValues'].length
                ? field?.['allowedValues'].map(item => ({
                    label: item.name || item.value,
                    value: item.id
                  }))
                : Array.isArray(suggestionList[field.fieldId]?.suggestions) &&
                  suggestionList[field.fieldId]?.suggestions?.length
                ? suggestionList[field.fieldId].suggestions.map(item => ({
                    label: item.label,
                    value: item.label
                  }))
                : []
            }
            onSearch={val => {
              clearTimeout(typingTimerOfSearch);

              typingTimerOfSearch = setTimeout(
                debounce(() => {
                  getSuggestionList({
                    fieldId: field?.fieldId,
                    autoCompleteUrl: field.autoCompleteUrl,
                    projectKey: globalProject?.projectKey,
                    tenantKey: globalTenant?.tenantKey,
                    value: val
                  });
                }),
                500
              );
            }}
            onFocus={() => {
              if (
                (!Array.isArray(suggestionList[field.fieldId]) || !suggestionList[field.fieldId].length) &&
                field.autoCompleteUrl
              ) {
                getSuggestionList({
                  fieldId: field?.fieldId,
                  autoCompleteUrl: field.autoCompleteUrl,
                  projectKey: globalProject?.projectKey,
                  tenantKey: globalTenant?.tenantKey,
                  value: ''
                });
              }
            }}
            optionFilterProp="label"
            showSearch
            allowClear
            placeholder={t('common.pleaseSelect')}
          />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_DATE || field?.schema?.type === JIRA_DATA_TYPE_DATE_TIME) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <DatePicker
            showTime={`${field?.schema?.type === JIRA_DATA_TYPE_DATE ? false : true}`}
            style={{ width: '50%' }}
            format={`${field?.schema?.type === JIRA_DATA_TYPE_DATE ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'}`}
            placeholder={`${field?.schema?.type === JIRA_DATA_TYPE_DATE ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm'}`}
            onChange={date => form.setFieldsValue({ [field?.fieldId]: moment(date) })}
          />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_OPTION) {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];
    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Select
            style={{ width: '50%' }}
            options={
              Array.isArray(field?.['allowedValues']) && field?.['allowedValues'].length
                ? field?.['allowedValues'].map(item => ({
                    label: item.value,
                    value: item.id
                  }))
                : []
            }
            optionFilterProp="label"
            showSearch
            allowClear
            placeholder={t('common.pleaseSelect')}
          />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_NUMBER) {
    const rules = [
      { required: field?.mandatory, message: t('message.required') },
      { pattern: NUMBER_PATTERN, message: t('message.fieldMustBeANumber') }
    ];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules} className="mb-3" {...rest}>
          <Input style={{ width: '50%' }} placeholder={t('common.enterValue')} className="w-100" />
        </Form.Item>
      </>
    );
  }

  if (field?.schema?.type === JIRA_DATA_TYPE_PRIORITY) {
    const rules = [
      { required: field?.mandatory, message: t('message.required') },
      { pattern: NUMBER_PATTERN, message: t('message.fieldMustBeANumber') }
    ];

    return (
      <>
        <Form.Item label={t('workItem.priority')} name="priority" rules={rules}>
          <Select style={{ width: '50%' }} placeholder={t('common.pleaseSelect')} filterOption={false}>
            {field?.allowedValues?.map(priority => {
              return (
                <Select.Option value={priority?.id} key={priority?.id} title={priority?.name}>
                  <img src={priority?.iconUrl} width={16} alt="Priority Icon" />{' '}
                  <span className="ml-1">{priority?.name}</span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </>
    );
  } else {
    const rules = [{ required: field?.mandatory, message: t('message.required') }];

    return (
      <>
        <Form.Item label={field?.name} name={field?.fieldId} rules={rules}>
          <Input style={{ width: '50%' }} placeholder={t('common.enterValue')} />
        </Form.Item>
      </>
    );
  }
};
