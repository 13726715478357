import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { Tag } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE } from '../../../../constants';
import { reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import PicklistKeyboardEvents from './picklist-keyboard-events';
import PicklistFormItem from './picklist-form-item';

const PicklistField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Options
   */
  const options = useMemo(() => {
    return Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];
  }, [field]);

  /**
   * Get options by values
   */
  const getOptionsByValues = vals => {
    if (!(Array.isArray(options) && options.length) || !(Array.isArray(vals) && vals.length)) return [];

    const selectedOptions = options.filter(item => vals.includes(item?.value));

    return selectedOptions;
  };

  /**
   * Default option
   */
  const defaultOpts = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) return null;

    const vals = objectPath.get(row, field?.refName);
    const selectedOptions = getOptionsByValues(vals);

    return selectedOptions;
  }, [row, field, options]);

  /**
   * Current options
   */
  const [currentOptions, setCurrentOptions] = useState(defaultOpts);

  /**
   * Set current options by default value
   */
  useEffect(() => setCurrentOptions(defaultOpts), [defaultOpts]);

  /**
   * Get current options
   */
  const getCurrentOptions = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastOption = getOptionsByValues(lastValue);

    return lastValue !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const options = getCurrentOptions(defaultOpts);
      const values = Array.isArray(options) && options.length ? options.map(item => item?.value) : [];

      tableForm.setFieldsValue({ [formItemName]: values });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpts]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opts => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOptions(opts);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOptions(opts);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOptions = getCurrentOptions(defaultOpts);
    handleClose(defaultOptions);
  };

  /**
   * Handle save
   */
  const handleSave = opts => {
    const defaultOptions = getCurrentOptions(defaultOpts);

    if (field?.mandatory && !(Array.isArray(opts) && opts.length)) {
      handleClose(defaultOptions);
      return;
    }

    const newValues = Array.isArray(opts) && opts.length ? [...opts].map(item => item?.value) : null;
    const oldValues =
      Array.isArray(defaultOptions) && defaultOptions.length ? [...defaultOptions].map(item => item?.value) : null;

    if (JSON.stringify(newValues) !== JSON.stringify(oldValues)) {
      handleSetLastSavedValueToSession({ [formItemName]: newValues });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: newValues }, row, field });
      }
    }

    handleClose(opts);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleSave(currentOptions), 200);
  };

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const code = e.code;
    const ctrl = e.ctrlKey ? e.ctrlKey : code === 'Control' ? true : false;
    const hasOpenedSelectDropdown = document.querySelector('.ant-select-open');

    // Ctrl + Enter & hasOpenedSelectDropdown & isOpen => Save
    if (ctrl && (code === 'Enter' || code === 'NumpadEnter') && hasOpenedSelectDropdown) {
      e.stopPropagation();

      const vals =
        Array.isArray(currentOptions) && currentOptions.length ? currentOptions.map(item => item?.value) : null;
      tableForm.setFieldsValue({ [formItemName]: vals }); // Don't remove last item when Ctrl + Enter

      handleSave(currentOptions);
    }
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    mode: 'multiple',
    options: Array.isArray(options) && options.length ? [...options] : [],
    optionFilterProp: 'label',
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    placeholder: placeholder || t('common.search'),
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onChange: values => {
      const selectedOptions = getOptionsByValues(values);
      setCurrentOptions(selectedOptions);
      if (row?.isNew) handleSave(selectedOptions);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onClick: () => {
      if (!row?.isNew) handleOpen();
    },
    onKeyDown: e => {
      if (!row?.isNew) handleKeyDown(e);
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const options = getCurrentOptions(currentOptions);
    const newOptions = Array.isArray(options) && options.length ? [...options] : [];
    const title = newOptions.map(item => item?.label).join(', ');
    const labels = newOptions.length ? newOptions.map((item, idx) => <Tag key={idx}>{item?.label}</Tag>) : null;

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={title || ''} className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value min-h-22 text-truncate">{labels || props?.placeholder}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={title || ''} row={row} field={field}>
          {labels}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={title || ''} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {labels}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <span title={title || ''}>{labels}</span>;
    }

    // Readonly
    else {
      return (
        <div title={title || ''} className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{labels}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <PicklistFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <PicklistKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              currentOptions={currentOptions}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{t('common.search')}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOptions)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper picklist-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new picklist-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default PicklistField;
