import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, sleep } from '../../../../common';

export const model = {
  /**
   * State
   */
  previewData: [],
  percentImport: 0,
  loadingImportTestcaseExcel: false,
  loadingPreviewImportTestcaseExcel: false,

  /**
   * Action: Set preview data
   */
  setPreviewData: action((state, payload) => {
    if (state?.previewData === undefined) return;

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.previewData = [];
      return;
    }

    state.previewData = payload.data;
  }),

  /**
   * Action: Set percent import
   */
  setPercentImport: action((state, payload) => {
    state.percentImport = payload;
  }),

  /**
   * Action: Set loading import testcase excel
   */
  setLoadingImportTestcaseExcel: action((state, payload) => {
    if (state?.loadingImportTestcaseExcel === undefined) return;

    state.loadingImportTestcaseExcel = payload;
  }),

  /**
   * Action: Set loading preview import testcase excel
   */
  setLoadingPreviewTestcaseExcel: action((state, payload) => {
    if (state?.loadingPreviewImportTestcaseExcel === undefined) return;

    state.loadingPreviewImportTestcaseExcel = payload;
  }),

  /**
   * Action: Call api to preview import testcase excel
   */
  previewImportTestcaseExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingPreviewTestcaseExcel(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_PREVIEW}/test-case`;
      const res = await Http.post(url, payload).then(res => res.data);

      if (res?.data) {
        action.setPreviewData(res.data);
        return res.data;
      }
      action.setLoadingPreviewTestcaseExcel(false);
    } catch (err) {
      if (err?.status === 400 && err.data?.message.includes('Sheet name is not exist')) {
        return err;
      }
      handleError(err);
    } finally {
      action.setLoadingPreviewTestcaseExcel(false);
    }
  }),

  /**
   * Action: Call api to import test case
   */
  importTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload) && payload.length)) return;

      action.setLoadingImportTestcaseExcel(true);

      const rootPayload = Array.isArray(payload) && payload.length ? [...payload] : [];

      let countChunk = 0;
      let index = 0;
      while (payload.length) {
        const newPayload = payload.splice(0, 10);
        countChunk = newPayload.length + countChunk;

        if (index === 0) {
          setTimeout(() => {
            action.setPercentImport(Math.round((countChunk * 100) / rootPayload.length));
          }, 1000);
        }
        const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_IMPORT}`;
        await Http.post(url, newPayload).then(res => res.data);

        if (index > 0) {
          action.setPercentImport(((countChunk * 100) / rootPayload.length).toFixed(2));
        }
        index++;
      }

      await sleep(1000);
      action.setLoadingImportTestcaseExcel(false);

      notification.success({
        message: i18next.t('akaat:testRepo.messageCreatingTestCase'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      await sleep(1000);
      action.setLoadingImportTestcaseExcel(false);
    }
  })
};
