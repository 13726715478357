import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Empty, Popconfirm, Select, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { SYSTEM_FIELD_KEY } from '../../constants';
import { Drag2 } from '../../assets/svg-icons';
import { SafeInnerHtml } from '../safe-inner-html';

export function SortableComponent({
  fromModule,
  columns,
  remainingColumns,
  setActiveColumns,
  onApply,
  onCancel,
  onRevertToConfigFolder,
  invisibleTitle,
  noFixedKeyField
}) {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [items, setItems] = useState([]);
  const [remainingItems, setRemainingItems] = useState([]);

  /**
   * Set items
   */
  useEffect(() => {
    setItems(columns);
  }, [columns]);

  /**
   * Set remaining items
   */
  useEffect(() => {
    setRemainingItems(remainingColumns);
  }, [remainingColumns]);

  /**
   * Check show remove button
   */
  const checkShowRemoveButton = ({ item, noFixedKeyField }) => {
    if (noFixedKeyField && item?.dataIndex === SYSTEM_FIELD_KEY) return true;

    return !item?.isRemove && item?.dataIndex !== SYSTEM_FIELD_KEY;
  };

  /**
   * Render drag button
   */
  const renderDragButton = props => <Drag2 className={`btn-drag cursor-move font-size-12 ${props?.className || ''}`} />;

  /**
   * Drag handle
   */
  const DragHandle = SortableHandle(() => (
    <Tooltip
      title={t('common.dragDropToReorder')}
      placement="left"
      destroyTooltipOnHide={true}
      overlayStyle={{ pointerEvents: 'none' }}
    >
      {renderDragButton()}
    </Tooltip>
  ));

  /**
   * Sortable item
   */
  const SortableItem = SortableElement(props => {
    if (!props?.item) return;

    const { item, itemIndex } = props;

    return (
      <div
        className={`field-btn ant-dropdown-menu-item ant-dropdown-menu-item-only-child ${
          itemIndex === columns.length - 1 ? 'latest-element' : ''
        } ${item?.isRemove ? 'style-disabled' : ''}`}
      >
        <div className="text-truncate">
          {item?.dataIndex === SYSTEM_FIELD_KEY && !noFixedKeyField ? (
            renderDragButton({ className: 'invisible' })
          ) : (
            <DragHandle />
          )}

          <span title={item?.plainTitle} className="text-truncate txt-title pl-2">
            {item?.title}
          </span>
        </div>

        {checkShowRemoveButton({ item, noFixedKeyField }) ? (
          <div className="pl-2">
            <Tooltip
              placement="top"
              title={t('common.remove')}
              destroyTooltipOnHide={true}
              overlayStyle={{ pointerEvents: 'none' }}
            >
              <Button
                type="link"
                danger
                icon={<DeleteOutlined className="font-size-12" />}
                size="small"
                className="btn-delete"
                onClick={() => removeColumnByIndex(itemIndex)}
              />
            </Tooltip>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  });

  /**
   * Sortable list
   */
  const SortableList = SortableContainer(props => {
    return (
      <div
        className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical"
        style={{ maxHeight: 300, overflow: 'hidden auto' }}
      >
        {!invisibleTitle && (
          <div
            className={
              'ant-dropdown-menu-item ant-dropdown-menu-item-only-child text-gray font-weight-medium font-size-12 text-italic cursor-default px-3 py-2'
            }
            onClick={e => e.stopPropagation()}
          >
            {fromModule === 'EXECUTE_TEST_SCHEDULE' ? (
              <span>{t('process.configureDisplayableFields')}</span>
            ) : (
              <div>
                <div className="w-100">{t('process.customizeGridViewLayoutForTheCurrentBrowserSession')}</div>
                <div className="w-100">
                  <Popconfirm
                    title={t('message.areYouSureRevert')}
                    placement="top"
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    zIndex={1080}
                    onConfirm={onRevertToConfigFolder}
                  >
                    <Tooltip
                      placement="top"
                      title={t('process.clickToRevert')}
                      destroyTooltipOnHide={true}
                      overlayStyle={{ pointerEvents: 'none' }}
                    >
                      <SafeInnerHtml
                        html={t('process.youCanRevertToConfiguredGridViewForFolder')}
                        className="text-gray"
                      />
                    </Tooltip>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
        )}

        {props?.list.map((item, index) => (
          <SortableItem key={index} index={index} itemIndex={index} item={item} />
        ))}
      </div>
    );
  });

  /**
   * On sort end
   */
  const onSortEnd = props => {
    if (props?.oldIndex === props?.newIndex) return;

    let newItems = Array.isArray(items) && items.length ? [...items] : [];

    newItems = arrayMoveImmutable(newItems, props?.oldIndex, props?.newIndex);
    newItems = newItems.map((item, idx) => ({ ...item, orderId: idx + 1 }));

    setItems(newItems);
  };

  /**
   * Remove column by index
   */
  const removeColumnByIndex = index => {
    const items = [...columns];
    items.splice(index, 1);
    setActiveColumns(items);
  };

  return (
    <div className="wrapper-active-column">
      {Array.isArray(items) && items.length > 0 ? (
        <SortableList list={items} useDragHandle helperClass="row-dragging-field-2" onSortEnd={onSortEnd} />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      <div className="wrapper-footer ant-dropdown-menu cursor-default">
        <Divider />

        <div className="add-new-column">
          <Select
            dropdownMatchSelectWidth={true}
            value={null}
            options={
              Array.isArray(remainingItems) && remainingItems.length
                ? [...remainingItems]?.map(column => {
                    return {
                      label: column.title,
                      value: column.dataIndex,
                      title: column.plainTitle,
                      column
                    };
                  })
                : []
            }
            filterOption={(keyword, option) => {
              keyword = `${typeof keyword === 'string' && keyword ? keyword : ''}`;

              return (
                typeof option?.column?.plainTitle === 'string' &&
                option?.column?.plainTitle.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
              );
            }}
            showSearch
            placeholder={t('common.addNewColumn')}
            onSelect={(val, option) => setActiveColumns([...items, option.column])}
          />
        </div>

        <div className="button-action">
          <Button size="small" type="text" className="w-auto" onClick={onCancel}>
            {t('common.cancel')}
          </Button>

          <Button type="primary" size="small" className="w-auto" onClick={() => onApply(items)}>
            {t('common.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
}
