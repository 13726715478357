import React from 'react';
import objectPath from 'object-path';

import {
  AUTOMATION_STATUS,
  SYSTEM_FIELD_AUTOMATION_STATUS,
  SYSTEM_FIELD_TEST_CASE_VERSION
} from '../../../../constants';
import { StatusLabel } from '../../../status-label';

const AutomationStatusField = ({ workTicketId, row, field, disableEditingCells }) => {
  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val =
      objectPath.get(row, field?.refName) ||
      objectPath.get(row, `${SYSTEM_FIELD_TEST_CASE_VERSION}.${SYSTEM_FIELD_AUTOMATION_STATUS}`);
    const status = AUTOMATION_STATUS[val];
    const simpleElement = <StatusLabel status={status} />;

    if (disableEditingCells) {
      return simpleElement;
    }

    return (
      <div className="cell-text cursor-default hide-after">
        <span className="cell-text-value">{simpleElement}</span>
      </div>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return <div className={`field-wrapper automation-status-field`}>{renderCellText()}</div>;
};

export default AutomationStatusField;
