import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal, Row, Tooltip } from 'antd';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { SettingFilled, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

import { moveObjectToFirst, reactLocalStorage } from '../../common';
import FormRelatedToJira from './form-relatedTo-jira';
import { ColumnChooserV2 } from '../column-chooser-v2';

const RelatedToAddJiraModal = ({
  fromModule,
  visible,
  workItem,
  relation = {},
  className = '',
  onPrevious,
  onCancel,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('akaat');

  const fieldListCreateIssueMeta = useStoreState(state => state.jiraIntegration.fieldListCreateIssueMeta);

  const [jiraFields, setJiraFields] = useState([]);
  const [jiraDefaultFields, setJiraDefaultFields] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  useEffect(() => {
    if (!Array.isArray(fieldListCreateIssueMeta) || !fieldListCreateIssueMeta.length) return;

    const localFieldKeys = reactLocalStorage.getObject(`${relation.workTicketType}-${relation.jiraIssueTypeId}`);
    let newFields = fieldListCreateIssueMeta
      .map(field => {
        if (field.fieldId == 'summary' || field.fieldId == 'description' || field.required) {
          field.isRemove = true;
        }
        return {
          ...field,
          title: field.name,
          dataIndex: field.fieldId,
          mandatory: field.required
        };
      })
      .filter(field => field.fieldId !== 'issuetype' && field.fieldId !== 'project');

    setJiraFields(newFields);

    if (Array.isArray(localFieldKeys) && localFieldKeys.length) {
      const localFields = localFieldKeys.map(key => {
        return newFields.find(field => field.fieldId === key);
      });
      setJiraDefaultFields(localFields);
    } else {
      newFields = newFields.filter(
        field => field.required || field.fieldId == 'summary' || field.fieldId == 'description'
      );
      newFields = moveObjectToFirst(newFields, 'fieldId', 'description');
      newFields = moveObjectToFirst(newFields, 'fieldId', 'summary');

      setJiraDefaultFields(newFields);
    }
  }, [fieldListCreateIssueMeta]);

  /**
   * Save to localstorage when save config
   * @param {*} values
   */
  const handleChangeActiveFields = values => {
    reactLocalStorage.setObject(`${relation.workTicketType}-${relation.jiraIssueTypeId}`, values);
    const localFields = values.map(key => {
      return jiraFields.find(field => field.fieldId === key);
    });
    setJiraDefaultFields(localFields);
  };

  return (
    <>
      <Modal
        open={visible}
        width={1160}
        keyboard={false}
        maskClosable={false}
        footer={null}
        forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header modal-body-with-scroll" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
        className={`c-related-to-add-jira-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">{t('integration.createIssue')}</h3>

            <Row align="middle" justify="end" wrap={false} className="box-extra">
              <ColumnChooserV2
                icon={<SettingFilled className="text-gray-2 text-hover-primary transition" />}
                toolTipTitle="process.configureDisplayableFields"
                columns={jiraFields}
                defaultActiveColumnKeys={jiraDefaultFields?.map(field => field?.dataIndex)}
                fromModule="EXECUTE_TEST_SCHEDULE"
                onChangeActiveColumns={handleChangeActiveFields}
              />

              <Tooltip
                open={visibleFullScreenTooltip}
                title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                placement={fullScreen ? 'bottomRight' : 'bottom'}
                destroyTooltipOnHide={true}
                overlayStyle={{ pointerEvents: 'none' }}
                onOpenChange={setVisibleFullScreenTooltip}
              >
                <Button
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  type="link"
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                    setVisibleFullScreenTooltip(false);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <FormRelatedToJira
          customFields={jiraDefaultFields?.filter(
            field => field?.fieldId !== 'summary' && field?.fieldId !== 'description'
          )}
          fromModule={fromModule}
          workItem={workItem}
          relation={relation}
          onPrevious={onPrevious}
          onSubmit={onSubmit}
        />
      </Modal>
    </>
  );
};

export default RelatedToAddJiraModal;
