import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Form, Select } from 'antd';

const JiraPriorityFormItem = ({ field, formItemName, selectProps, restFormItem, restField }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For jira integration
  const jiraPriorityList = useStoreState(state => state.jiraIntegration.priorityList);

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Select {...selectProps} {...restField}>
        {Array.isArray(jiraPriorityList) &&
          jiraPriorityList.length > 0 &&
          jiraPriorityList.map(item => (
            <Select.Option key={item.value} label={item.label} value={item.value}>
              {item.icon}
              {item.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default JiraPriorityFormItem;
