import { action } from 'easy-peasy';

export const model = {
  /**
   * State
   */
  testConfigTree: [],
  testConfigVariableTree: [],
  selectedTreeItem: {},
  loadingTreeData: false,

  /**
   * Action: Set selected tree item
   */
  setSelectedTreeItem: action((state, payload) => {
    if (state?.selectedTreeItem === undefined) return;

    state.selectedTreeItem = payload;
  }),

  /**
   * Action: Set test config tree
   */
  setTestConfigTree: action((state, payload) => {
    if (state?.testConfigTree === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.testConfigTree = [];
      return;
    }

    state.testConfigTree = payload;
  }),

  /**
   * Action: Set test config variable tree
   */
  setTestConfigVariableTree: action((state, payload) => {
    if (state?.testConfigVariableTree === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.testConfigVariableTree = [];
      return;
    }

    state.testConfigVariableTree = payload;
  })
};
