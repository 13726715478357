import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
// import { StringParam, useQueryParams } from 'use-query-params';

import env from '../../env';
import {
  TENANT_KEY_TRIAL,
  HOME_MENU,
  PROJECT_ENGINE_MENU,
  PROJECT_MANAGER_MENU,
  PROJECT_SETTING_MENU,
  ROLE_APP_ACCESS_MANAGER,
  ROLE_APP_ACCESS_TESTENGINE,
  ROLE_TENANT_OWNER
} from '../../constants';
import { useKeycloak, getParentMenuByPathname } from '../../common';

export const PrivateRoutes = ({ component: Component, children: Children, forPage, ...rest }) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For keycloak
  const { keycloak } = useKeycloak();

  // // For query params on url
  // const [queryParams] = useQueryParams({
  //   code: StringParam,
  //   state: StringParam,
  //   session_state: StringParam
  // });

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  return (
    <Route
      render={routeProps => {
        const currentMenu = getParentMenuByPathname(location.pathname, [
          ...HOME_MENU,
          ...PROJECT_ENGINE_MENU,
          ...PROJECT_MANAGER_MENU,
          ...PROJECT_SETTING_MENU
        ]);
        const managerMenu = getParentMenuByPathname(location.pathname, PROJECT_MANAGER_MENU);
        const engineMenu = getParentMenuByPathname(location.pathname, PROJECT_ENGINE_MENU);
        const userPermissions =
          Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.length
            ? [...globalUserInfo?.permissions]
            : [];

        // /**
        //  * After login on Xone
        //  */
        // if (queryParams?.code && queryParams?.state && queryParams?.session_state) {
        //   return;
        // }

        // Need to login
        if (keycloak && !keycloak?.authenticated) {
          keycloak.login();
          return;
        }

        // Logged in, but not allow access
        if (!(forPage === 'firstPagePage' || forPage === 'homepage')) {
          if (
            (currentMenu?.permission && !userPermissions.includes(currentMenu?.permission)) ||
            (Array.isArray(currentMenu?.permissions) &&
              !userPermissions.some(p => currentMenu?.permissions.includes(p))) ||
            (managerMenu && !userPermissions.includes(ROLE_APP_ACCESS_MANAGER)) ||
            (engineMenu && !userPermissions.includes(ROLE_APP_ACCESS_TESTENGINE)) ||
            (currentMenu?.id === 'subscription' && globalTenant?.tenantKey === TENANT_KEY_TRIAL) ||
            (currentMenu?.id === 'subscription' && globalTenant?.currentUserRole?.roleKey !== ROLE_TENANT_OWNER)
          ) {
            return <Redirect to={`/${env.REACT_APP_PREFIX_PATH}${tenantPath || '_'}/${projectPath || '_'}/403`} />;
          }
        }

        // Allow access, go to next
        if (Component) {
          return <Component {...routeProps} />;
        }

        // Allow access, go to next
        if (Children) {
          return <Children {...routeProps} />;
        }
      }}
      {...rest}
    />
  );
};
