import { action, thunk } from 'easy-peasy';
import queryString from 'query-string';
import qs from 'qs';

import { ENDPOINTS, SHORT_DATE_FORMAT } from '../../../../constants';
import { buildQueryForFilterMongo, handleError, Http, jsonParse } from '../../../../common';
import moment from 'moment';

export const model = {
  /**
   * State
   */
  testCaseExecutionProgressData: [],
  trendChartByStatusData: {},
  trendChartByUserData: [],
  mostExecutedTestcasesData: [],
  mostFailedTestcasesData: [],
  plannedVsExecutedData: {},
  query: {},

  // Loading for each chart
  loadingReport: false,
  loadingReportMemberDaily: false,
  loadingReportBurndown: false,
  loadingReportMostExecuteTestCase: false,
  loadingReportMostFailTestCase: false,
  loadingReportPlanned: false,
  loadingReportTrendStatus: false,
  loadingReportTrendUser: false,

  loadingDashboardList: false,
  loadingDashboardItem: false,
  data: [],
  total: 0,
  dashboard: {},
  dashboardChart: [],
  testCaseDailyChart: [],
  dataBurndownChart: [],
  totalTestRunsBurndownChart: 0,

  /**
   * Action: Set data testing burndown chart
   *
   */
  setDataBurndownChart: action((state, payload) => {
    if (state?.dataBurndownChart === undefined) return;

    if (!payload && !Object.keys(payload).length) {
      state.dataBurndownChart = [];
      return;
    }

    state.dataBurndownChart = payload;
  }),

  /**
   * Action: Set data testing burndown chart
   *
   */
  setTotalTestRunBurndownChart: action((state, payload) => {
    state.totalTestRunsBurndownChart = payload;
  }),

  /**
   * Action: Set test case for daily chart
   *
   */
  setTestCaseDailyChart: action((state, payload) => {
    if (state?.testCaseDailyChart === undefined) return;

    if (!payload && !Object.keys(payload).length) {
      state.testCaseDailyChart = [];
      return;
    }

    state.testCaseDailyChart = payload;
  }),

  /**
   * Action: Set dashboard data
   */
  setDashboardData: action((state, payload) => {
    if (state?.dashboard === undefined) return;

    state.dashboard = payload;
  }),

  /**
   * Action: Set dashboard chart data
   */
  setDashboardChart: action((state, payload) => {
    if (state?.dashboardChart === undefined) return;

    state.dashboardChart = payload;
  }),

  /**
   * Action: Set test case execution progress data
   */
  setTestCaseExecutionProgressData: action((state, payload) => {
    if (state?.testCaseExecutionProgressData === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length)) {
      state.testCaseExecutionProgressData = [];
      return;
    }

    const cloneTestCaseExecutionProgress = jsonParse(JSON.stringify(payload));

    state.testCaseExecutionProgressData = cloneTestCaseExecutionProgress
      .filter(item => item._id)
      .map((item, index) => {
        return {
          id: index + 1,
          plan: {
            id: item._id,
            name: item.groupBy
          },
          data: item.data
        };
      });
  }),

  /**
   * Action: Set trend chart by status data
   */
  setTrendChartByStatusData: action((state, payload) => {
    if (state?.trendChartByStatusData === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length)) {
      state.trendChartByStatusData = {};
      return;
    }

    state.trendChartByStatusData = payload;
  }),

  /**
   * Action: Set trend chart by user data
   */
  setTrendChartByUserData: action((state, payload) => {
    if (state?.trendChartByUserData === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.trendChartByUserData = [];
      return;
    }

    state.trendChartByUserData = payload.map(item => {
      return {
        ...item.user,
        count: item.count
      };
    });
  }),

  /**
   * Action: Set most executed test cases data
   */
  setMostExecutedTestCasesData: action((state, payload) => {
    if (state?.mostExecutedTestcasesData === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostExecutedTestcasesData = [];
      return;
    }

    state.mostExecutedTestcasesData = payload;
  }),

  /**
   * Action: Set most failed test cases data
   */
  setMostFailedTestCasesData: action((state, payload) => {
    if (state?.mostFailedTestcasesData === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostFailedTestcasesData = [];
      return;
    }

    state.mostFailedTestcasesData = payload;
  }),

  /**
   * Action: Set planned vs executed data
   */
  setPlannedVsExecutedData: action((state, payload) => {
    if (state?.plannedVsExecutedData === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length)) {
      state.plannedVsExecutedData = {};
      return;
    }

    state.plannedVsExecutedData = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading report
   */
  setLoadingReport: action((state, payload) => {
    if (state?.loadingReport === undefined) return;

    state.loadingReport = payload;
  }),

  /**
   * Action: Set loading member daily report
   */

  setLoadingReportMemberDaily: action((state, payload) => {
    if (state?.loadingReportMemberDaily === undefined) return;

    state.loadingReportMemberDaily = payload;
  }),

  /**
   * Action: Set loading burndown report
   */
  setLoadingReportBurndown: action((state, payload) => {
    if (state?.loadingReportBurndown === undefined) return;

    state.loadingReportBurndown = payload;
  }),

  /**
   * Action: Set loading most executed test case report
   */
  setLoadingReportMostExecuteTestCase: action((state, payload) => {
    if (state?.loadingReportMostExecuteTestCase === undefined) return;

    state.loadingReportMostExecuteTestCase = payload;
  }),

  /**
   * Action: Set loading most fail test case report
   */
  setLoadingReportMostFailTestCase: action((state, payload) => {
    if (state?.loadingReportMostFailTestCase === undefined) return;

    state.loadingReportMostFailTestCase = payload;
  }),

  /**
   * Action: Set loading planned report
   */
  setLoadingReportPlanned: action((state, payload) => {
    if (state?.loadingReportPlanned === undefined) return;

    state.loadingReportPlanned = payload;
  }),

  /**
   * Action: Set loading trend status report
   */
  setLoadingReportTrendStatus: action((state, payload) => {
    if (state?.loadingReportTrendStatus === undefined) return;

    state.loadingReportTrendStatus = payload;
  }),

  /**
   * Action: Set loading trend user report
   */
  setLoadingReportTrendUser: action((state, payload) => {
    if (state?.loadingReportTrendUser === undefined) return;

    state.loadingReportTrendUser = payload;
  }),

  /**
   * Action: Set loading dashboard list
   */
  setLoadingDashboardList: action((state, payload) => {
    if (state?.loadingDashboardList === undefined) return;

    state.loadingDashboardList = payload;
  }),

  /**
   * Action: Set loading dashboard item
   */
  setLoadingDashboardItem: action((state, payload) => {
    if (state?.loadingDashboardItem === undefined) return;

    state.loadingDashboardItem = payload;
  }),

  /**
   * Action: Call api to Get dashboard list
   */
  getDashboardData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get dashboard data: Invalid Params');
      }

      let isSetQuery = true;

      if (!payload?.isSetQuery) {
        isSetQuery = false;
      }

      action.setLoadingDashboardList(true);

      if (isSetQuery) action.setQuery(payload);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}`;
      const res = await Http.get(url).then(res => res.data);

      action.setDashboardData(res?.data);
      action.setDashboardChart(res?.data?.charts);

      // const charts = Array.isArray(res?.data?.charts) && res?.data?.charts.length ? [...res?.data?.charts] : [];
      // const newData = {
      //   ...res?.data,
      //   charts: charts.filter(c => c?.chartId !== 'chart-testing-burndown') // To do
      // };

      // action.setDashboardData(newData);
      // action.setDashboardChart(newData?.charts);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to delete dashboard by id
   */
  deleteChartById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Delete chart by id: Invalid Params');
      }

      if (!payload) {
        throw new Error('Delete chart by id: No ID');
      }

      action.setLoadingDashboardList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}${ENDPOINTS.CHART}/${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to delete dashboard by id
   */
  createDashboard: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create dashboard: Invalid Params');
      }

      if (!payload) {
        throw new Error('Create dashboard: No Body');
      }
      action.setLoadingDashboardItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}${ENDPOINTS.CHART}`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardItem(false);
    }
  }),

  /**
   * Action: Call api to Get test case execution progress list
   */
  getTestCaseExecutionProgressList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test case execution progress list: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_TEST_CASE_EXECUTION_PROGRESS
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseExecutionProgressData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  /**
   * Action: Call api to Get trend chart by status
   */
  getTrendChartByStatus: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart by status: Invalid Payload');
      }
      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_TREND_CHART_BY_STATUS
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTrendChartByStatusData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  /**
   * Action: Call api to Get trend chart by user
   */
  getTrendChartByUser: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart by status: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_TREND_CHART_BY_USER
      }?${queryString.stringify(payload)}`;

      const res = await Http.get(url).then(res => res.data);

      action.setTrendChartByUserData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  /**
   * Action: Call api to Get chart by most executed testcase
   */
  getTrendChartMostExecuted: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most executed: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const defaultPath = `${helpers.getStoreState()?.global?.globalTenant.tenantKey}/${
        helpers.getStoreState()?.global?.globalProject.projectKey
      }`;

      if (!payload?.filter?.path) {
        payload.filter = payload.filter || {}; // Ensure that payload.filter exists
        payload.filter.path = {
          $regex: defaultPath,
          $options: 'si'
        };
      }
      const sortDesOrAcs = payload.order === 'DESC' ? -1 : 1;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_MOST_EXECUTED_TEST_CASES
      }?${buildQueryForFilterMongo(payload)}&sortExecutingTime=${sortDesOrAcs}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostExecutedTestCasesData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  /**
   * Action: Call api to Get chart by most failed testcase
   */
  getTrendChartMostFailed: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most failed: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }
      const sortDesOrAcs = payload.order === 'DESC' ? -1 : 1;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_MOST_FAILED_TEST_CASES
      }?${buildQueryForFilterMongo(payload)}&sortExecutingTime=${sortDesOrAcs}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostFailedTestCasesData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  /**
   * Action: Call api to Get chart by plan and executed
   */
  getChartByPlanAndExecuted: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most failed: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_PLANNED_VS_EXECUTED
      }?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setPlannedVsExecutedData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) action.setLoadingReport(false);
    }
  }),

  /**
   * Action: Call api to update chart list
   */
  updateChartListBeautiful: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) {
        throw new Error('Invalid Report ID');
      }
      action.setLoadingDashboardList(true);

      payload = payload.map(el => {
        return { name: el.name, chartId: el.chartId, chartType: el.chartType, status: el.status };
      });
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}/chart`;
      await Http.put(url, payload).then(res => res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to get test run list
   */
  getTestCaseFromRunList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most failed: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      let newPayload = {
        user: payload.user,
        today: payload.dateSelected,
        tenantKey: globalTenant?.tenantKey,
        projectKey: globalProject?.projectKey
      };

      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_DAILY_MEMBER
      }?${qs.stringify(newPayload)}`;

      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseDailyChart(res?.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  // Action Testing burndown chart

  getDataBurndownChart: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get data burndown chart: Invalid Payload');
      }
      if (!payload?.filter?.path || !payload?.filter?.from || !payload?.filter?.to) {
        // throw new Error('Get data burndown chart: Invalid Payload');
        return;
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}/report/chart${
        ENDPOINTS.REPORT_TESTING_BURNDOWN
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      await action.setDataBurndownChart(res?.data?.dataChart);
      action.setTotalTestRunBurndownChart(res?.data?.totalTestPlan);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  })
};
