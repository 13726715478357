import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Button, Input, DatePicker, Tooltip, Row, Modal } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';

import { URL_PATTERN, FULL_DATE_FORMAT, SHORT_DATE_FORMAT, SYSTEM_FIELD_ESTIMATEDTIME } from '../../constants';
import {
  useKeycloak,
  addTokenToRawHtml,
  removeTokenToRawHtml,
  convertMinutesToShortTime,
  convertRawHtmlToPlainText,
  convertEstimatedTimeToMinutes
} from '../../common';
import { BasicEditor } from '../basic-editor';
import { SafeInnerHtml } from '../safe-inner-html';

import './style.scss';

export const EditableInput = ({
  type = 'TEXT', // TEXT, TEXTAREA, DATEPICKER, DATETIMEPICKER, RANGE_DATEPICKER, EDITOR. Default is TEXT
  form,
  defaultVal = '',
  iShowTitle = false,
  isShowEditingIcon = false,
  isLink,
  isReadOnly = false,
  placeholder,
  confirmSave,
  className = '',
  uploadPath,
  onEditorAttachFiles,
  restFormItem,
  restField,
  restEditor,
  restBoxEditableText,
  restValueText,
  restEditButton,
  restSaveButton,
  restCloseButton,
  onOpen,
  onClose,
  onChange,
  onBlur,
  onClear,
  onSave,
  ...rest
}) => {
  const refInput = useRef(null);
  const refSaveButton = useRef(null);
  const refCloseButton = useRef(null);

  // For keycloak
  const { keycloak } = useKeycloak();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultVal);
  const [isFocusedEditor, setIsFocusedEditor] = useState(false);
  const [isHoverCloseButton, setIsHoverCloseButton] = useState(false);
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [visibleEditor, setVisibleEditor] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Convert minutes to short time
   */
  useEffect(() => {
    if (!isEditing) {
      if (new RegExp(`${SYSTEM_FIELD_ESTIMATEDTIME}$`, 'g').test(restFormItem?.name)) {
        const et = convertMinutesToShortTime(convertEstimatedTimeToMinutes(currentValue));
        setCurrentValue(et || '');
      }
    }
  }, [isEditing, restFormItem?.name, currentValue]);

  /**
   * Focus to input after open
   */
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        refInput?.current && refInput.current.focus({ cursor: 'end' });
        setVisibleCalendar(true);
      }, 200);
    }
  }, [isEditing]);

  /**
   * Handle open
   */
  const handleOpen = e => {
    e.stopPropagation();

    if (isReadOnly) return;

    setTimeout(() => {
      if (typeof onOpen === 'function') onOpen();

      if (restFormItem?.name && typeof form?.setFieldsValue === 'function') {
        form.setFieldsValue({ [restFormItem.name]: defaultVal });
      }

      setIsHoverCloseButton(false);
      setIsEditing(true);
    }, 300);
  };

  /**
   * Handle close
   */
  const handleClose = val => {
    if (typeof onClose === 'function') onClose();

    setIsHoverCloseButton(false);
    setIsEditing(false);
    setCurrentValue(val);
  };

  /**
   * Handle save
   */
  const handleSave = val => {
    if (!isFormValid() || isHoverCloseButton) return;

    const inValidDate = moment(val).isSame(defaultVal, 'day') || (!val && !defaultVal);
    let newVal = val;

    if ((type === 'DATEPICKER' && inValidDate) || `${val}` === `${defaultVal}`) {
      handleClose(defaultVal);
      return;
    }

    if ((type === 'DATETIMEPICKER' && inValidDate) || `${val}` === `${defaultVal}`) {
      handleClose(defaultVal);
      return;
    }

    if (type === 'EDITOR' && !isFocusedEditor) {
      handleClose(val);
      return;
    }

    if (type === 'EDITOR' && val) {
      newVal = removeTokenToRawHtml({ rawHtml: val });
    }

    if (confirmSave) {
      if (!document.querySelector('.c-confirm-save-modal')) {
        Modal.confirm({
          title: t('common.confirm'),
          content: <SafeInnerHtml html={t('message.ifYouCancelNowYouWillLoseYourChanges')} />,
          icon: <WarningOutlined className="text-danger" />,
          autoFocusButton: null,
          maskClosable: true,
          width: 500,
          className: 'c-confirm-save-modal',
          okText: t('common.save'),
          okButtonProps: { type: 'primary', icon: <SaveOutlined /> },
          cancelText: t('common.cancel'),
          onOk: () => {
            if (typeof onSave === 'function') onSave(newVal);
            handleClose(val);
            Modal.destroyAll();
          },
          ...confirmSave,
          onCancel: () => {
            handleClose(val);
            if (typeof confirmSave?.onCancel === 'function') confirmSave?.onCancel();
            Modal.destroyAll();
          }
        });
      }

      return;
    }

    if (typeof onSave === 'function') onSave(newVal);
    handleClose(val);
  };

  /**
   * Check validation
   */
  const isFormValid = () => {
    if (!(restFormItem?.name && typeof form?.getFieldError === 'function')) return true;

    const errors = form.getFieldError(restFormItem.name);

    if (Array.isArray(errors) && errors.length) return false;

    return true;
  };

  /**
   * Render save button
   */
  const renderSaveButton = props => {
    return (
      <Tooltip placement="top" title={t('common.save')} destroyTooltipOnHide={true}>
        <Button
          ref={refSaveButton}
          type="primary"
          ghost
          icon={<SaveOutlined />}
          size="small"
          className={`bg-white ${props?.className || ''} ${restSaveButton?.className || ''}`}
          onClick={e => {
            e.stopPropagation();
            handleSave(currentValue);
          }}
          {...restSaveButton}
        />
      </Tooltip>
    );
  };

  /**
   * Render close button
   */
  const renderCloseButton = props => {
    return (
      <Tooltip placement="top" title={t('common.close')} destroyTooltipOnHide={true}>
        <Button
          ref={refCloseButton}
          title={t('common.close')}
          icon={<CloseOutlined />}
          size="small"
          className={`${props?.className || ''} ${restCloseButton?.className || ''}`}
          onClick={e => {
            e.stopPropagation();
            handleClose(defaultVal);
          }}
          {...restCloseButton}
        />
      </Tooltip>
    );
  };

  /**
   * Render date extra footer
   */
  const renderDateExtraFooter = () => {
    return (
      <div style={{ position: 'absolute', right: 0, bottom: 0, transform: 'translate(0, 100%)', lineHeight: 1 }}>
        {renderCloseButton()}
      </div>
    );
  };

  /**
   * Input props
   */
  const inputProps = {
    ref: refInput,
    placeholder: placeholder || t('common.enterValue'),
    disabled: isReadOnly,
    onChange: e => {
      if (typeof onChange === 'function') onChange(e);

      setCurrentValue(e?.target?.value);
    },
    onBlur: e => {
      if (typeof onBlur === 'function') onBlur(e);

      handleSave(e?.target?.value);
    },
    onKeyDown: e => {
      if (type === 'TEXT' && (e?.key === 'Enter' || e?.keyCode === 13)) {
        e.preventDefault();
        e.stopPropagation();
        handleSave(e.target?.value);
      }

      if (e?.key === 'Escape' || e?.keyCode === 27) {
        handleClose(defaultVal);
      }
    }
  };

  /**
   * Date Picker props
   */
  const datePickerProps = {
    ref: refInput,
    open: visibleCalendar,
    format: SHORT_DATE_FORMAT,
    placeholder: placeholder || SHORT_DATE_FORMAT,
    disabled: isReadOnly,
    renderExtraFooter: renderDateExtraFooter,
    onChange: e => {
      if (typeof onChange === 'function') onChange(e);

      setCurrentValue(e?.target?.value ? e?.target?.value : e);

      if (restFormItem?.name) form.validateFields([restFormItem.name]);
    },
    onSelect: val => handleSave(val),
    onOpenChange: isVisible => setVisibleCalendar(isVisible),
    onBlur: e => {
      if (typeof onBlur === 'function') onBlur(e);

      handleSave(e?.target?.value);
    },
    onClear: () => {
      if (typeof onClear === 'function') onClear(null);

      handleClose(null);
    }
  };

  /**
   * Date Time Picker props
   */
  const dateTimePickerProps = {
    ref: refInput,
    open: visibleCalendar,
    format: FULL_DATE_FORMAT,
    showTime: { format: 'HH:mm' },
    placeholder: placeholder || FULL_DATE_FORMAT,
    disabled: isReadOnly,
    renderExtraFooter: renderDateExtraFooter,
    onChange: e => {
      if (typeof onChange === 'function') onChange(e);

      setCurrentValue(e?.target?.value);

      if (restFormItem?.name) form.validateFields([restFormItem.name]);
    },
    onSelect: val => handleSave(val),
    onOpenChange: isVisible => setVisibleCalendar(isVisible),
    onBlur: e => {
      if (typeof onBlur === 'function') onBlur(e);

      handleSave(e?.target?.value);
    },
    onClear: () => {
      if (typeof onClear === 'function') onClear(null);

      handleClose(null);
    }
  };

  /**
   * Range Date Picker props
   */
  const rangeDatePickerProps = {
    ref: refInput,
    open: visibleCalendar,
    format: SHORT_DATE_FORMAT,
    disabled: isReadOnly,
    renderExtraFooter: () => (
      <div className="text-right">
        <Button type="primary" icon={<SaveOutlined />} size="small" onClick={() => handleSave(currentValue)}>
          {t('common.save')}
        </Button>
      </div>
    ),
    onCalendarChange: val => {
      if (typeof onChange === 'function') onChange(val);
      setCurrentValue(val);
    },
    onOpenChange: isVisible => {
      setVisibleCalendar(isVisible);

      if (!isVisible) {
        setTimeout(() => handleSave(currentValue), 200);
      }
    },
    onClear: () => {
      if (typeof onClear === 'function') onClear(null);

      handleClose(null);
    }
  };

  /**
   * Editor props
   */
  const editorProps = {
    rawHtml: defaultVal,
    autoFocus: true,
    uploadPath,
    isReadOnly,
    restEditor: {
      placeholder: placeholder || t('common.enterValue')
    },
    onFocus: () => setIsFocusedEditor(true),
    onEditorRawHtmlChange: val => {
      if (typeof onChange === 'function') onChange(val);

      setCurrentValue(val);
    },
    onEditorAttachFiles: val => {
      if (typeof onEditorAttachFiles === 'function') onEditorAttachFiles(val);
    }
  };

  /**
   * Render box editable text
   */
  const renderBoxEditableText = () => {
    const validDate = currentValue && moment(currentValue).isValid();

    const validRangeDate =
      Array.isArray(currentValue) &&
      currentValue[0] &&
      moment(currentValue[0]).isValid() &&
      currentValue[1] &&
      moment(currentValue[1]).isValid();

    let title = '';

    if (type === 'DATEPICKER' && validDate) {
      title = moment(currentValue).format(SHORT_DATE_FORMAT);
    } else if (type === 'DATETIMEPICKER' && validDate) {
      title = moment(currentValue).format(FULL_DATE_FORMAT);
    } else if (type === 'RANGE_DATEPICKER' && validRangeDate) {
      title = `${moment(currentValue[0]).format(SHORT_DATE_FORMAT)} ⇀ ${moment(currentValue[1]).format(
        SHORT_DATE_FORMAT
      )}`;
    } else if (type === 'EDITOR' && currentValue) {
      title = convertRawHtmlToPlainText(currentValue);
    } else if (new RegExp(`${SYSTEM_FIELD_ESTIMATEDTIME}$`, 'g').test(restFormItem?.name)) {
      title = currentValue || t('common.unestimated');
    } else if ((type === 'TEXT' || type === 'TEXTAREA') && currentValue) {
      title = currentValue;
    }

    return (
      <div
        onClick={handleOpen}
        className={`box-txt-label min-h-22 ${restBoxEditableText?.className || ''}`}
        {...restBoxEditableText}
      >
        <div
          title={iShowTitle ? title : null}
          className={`txt-label cursor-text ${type === 'TEXTAREA' ? 'text-pre-line' : ''} ${
            restValueText?.className || ''
          }`}
          {...restValueText}
        >
          {type === 'DATEPICKER' && (
            <>
              {!validDate && isReadOnly ? (
                <span className="text-gray">N/A</span>
              ) : !validDate ? (
                <span className="text-gray">{placeholder || t('common.enterValue')}</span>
              ) : (
                title
              )}
            </>
          )}

          {type === 'DATETIMEPICKER' && (
            <>
              {!validDate && isReadOnly ? (
                <span className="text-gray">N/A</span>
              ) : !validDate ? (
                <span className="text-gray">{placeholder || t('common.enterValue')}</span>
              ) : (
                title
              )}
            </>
          )}

          {type === 'RANGE_DATEPICKER' && (
            <>
              {!validRangeDate && isReadOnly ? (
                <span className="text-gray">N/A</span>
              ) : !validRangeDate ? (
                <span className="text-gray">{placeholder || t('common.enterValue')}</span>
              ) : (
                title
              )}
            </>
          )}

          {type === 'EDITOR' && (
            <>
              {!currentValue && isReadOnly ? (
                <span className="text-gray">N/A</span>
              ) : !currentValue ? (
                <span className="text-gray">{placeholder || t('common.enterValue')}</span>
              ) : (
                <SafeInnerHtml html={addTokenToRawHtml({ rawHtml: currentValue, token: keycloak?.token })} />
              )}
            </>
          )}

          {(type === 'TEXT' || type === 'TEXTAREA') && (
            <>
              {!currentValue && isReadOnly ? (
                <span className="text-gray">N/A</span>
              ) : !currentValue && new RegExp(`${SYSTEM_FIELD_ESTIMATEDTIME}$`, 'g').test(restFormItem?.name) ? (
                t('common.unestimated')
              ) : !currentValue ? (
                <span className="text-gray">{placeholder || t('common.enterValue')}</span>
              ) : isLink && new RegExp(URL_PATTERN).test(currentValue) ? (
                <a
                  href={currentValue}
                  target="_blank"
                  style={{ wordBreak: 'break-all' }}
                  onClick={e => e.stopPropagation()}
                  rel="noreferrer"
                >
                  {title}
                </a>
              ) : (
                title
              )}
            </>
          )}
        </div>

        {!isReadOnly && (
          <Button
            type="link"
            icon={<EditOutlined />}
            size="small"
            className={`btn-open-editable ${restEditButton?.className || ''}`}
            onClick={handleOpen}
            {...restEditButton}
          />
        )}
      </div>
    );
  };

  /**
   * Render extra
   */
  const renderExtra = extra => {
    return (
      <Row justify="space-between" wrap={false} className="mt-1">
        <div className="editable-extra">{extra}</div>

        {isEditing && !(type === 'DATEPICKER' || type === 'RANGE_DATEPICKER' || type === 'DATETIMEPICKER') && (
          <div className="editable-action">
            {renderSaveButton({ className: 'ml-1' })}
            {renderCloseButton({ className: 'ml-1' })}
          </div>
        )}
      </Row>
    );
  };

  /**
   * On hover or leave close button
   */
  const onHoverOrLeaveCloseButton = e => {
    if (e?.type === 'mouseover') setIsHoverCloseButton(true);
    if (e?.type === 'mouseleave') setIsHoverCloseButton(false);
  };

  /**
   * Event listener: Close button
   */
  useEffect(() => {
    if (!refCloseButton?.current) return;

    const closeButton = refCloseButton.current;

    closeButton.addEventListener('mouseover', onHoverOrLeaveCloseButton);
    closeButton.addEventListener('mouseleave', onHoverOrLeaveCloseButton);

    return () => {
      closeButton.removeEventListener('mouseover', onHoverOrLeaveCloseButton);
      closeButton.removeEventListener('mouseleave', onHoverOrLeaveCloseButton);
    };
  });

  return (
    <div
      className={`c-editable-input ${isEditing ? 'is-editing' : ''} ${
        isShowEditingIcon ? 'is-show-editing-icon' : ''
      } ${isReadOnly ? 'is-read-only' : ''} ${className}`}
      onClick={e => e.stopPropagation()}
      {...rest}
    >
      <>
        {(type === 'DATEPICKER' ||
          type === 'DATETIMEPICKER' ||
          type === 'RANGE_DATEPICKER' ||
          type === 'TEXTAREA' ||
          type === 'TEXT') &&
          isEditing &&
          inputProps?.ref && (
            <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem} extra={renderExtra(restFormItem?.extra)}>
              {type === 'DATEPICKER' ? (
                <DatePicker {...datePickerProps} {...restField} />
              ) : type === 'DATETIMEPICKER' ? (
                <DatePicker {...dateTimePickerProps} {...restField} />
              ) : type === 'RANGE_DATEPICKER' ? (
                <DatePicker.RangePicker {...rangeDatePickerProps} {...restField} />
              ) : type === 'TEXTAREA' ? (
                <Input.TextArea {...inputProps} {...restField} />
              ) : (
                <Input {...inputProps} {...restField} />
              )}
            </Form.Item>
          )}

        {type === 'EDITOR' && isEditing && (
          <div className="basic-editor-wrapper">
            {visibleEditor && <BasicEditor {...editorProps} {...restEditor} />}

            <Form.Item
              wrapperCol={{ xs: 24 }}
              {...restFormItem}
              className={`is-hide-control-input ${restFormItem?.className || ''}`}
              extra={renderExtra(restFormItem?.extra)}
            >
              <Input {...restField} />
            </Form.Item>
          </div>
        )}
      </>

      {!isEditing && renderBoxEditableText()}
    </div>
  );
};
