import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { SYSTEM_FIELD_ATTACHMENTS } from '../../constants';
import { BasicUploadMultipleFiles } from '../basic-upload-multiple-files';

const BoxAttachments = ({
  workTicketId,
  editingItem,
  uploadPath,
  hasGetFullFilesInfo,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  isCompareVersion = false,
  isOldVersion,
  listChange = [],
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);

  /**
   * Compute attachments
   */
  const attachments = useMemo(() => {
    return Array.isArray(editingItem?.[SYSTEM_FIELD_ATTACHMENTS]) && editingItem?.[SYSTEM_FIELD_ATTACHMENTS].length
      ? [...editingItem?.[SYSTEM_FIELD_ATTACHMENTS]]
      : [];
  }, [editingItem]);

  /**
   * On change attachments
   */
  const onChangeAttachments = async attachments => {
    await getFullFilesInfo(attachments);
    onSubmit({ [SYSTEM_FIELD_ATTACHMENTS]: attachments });
  };

  /**
   * Get class name
   */
  const getClassNameForKey = (isOldVersion, key, listChange) => {
    if (Array.isArray(listChange) && listChange.length > 1) {
      if (listChange.includes(key)) {
        if (isOldVersion) {
          return 'bg-old-version';
        } else {
          return 'bg-new-version';
        }
      }
    }
    return '';
  };

  return (
    <div
      id="anchorAttachments"
      className={`c-detail-components type-attachments collapse-item mb-4 ${className}`}
      {...rest}
    >
      <h5 className="btn-toggle ant-typography mb-0">
        <span id="toggle-attachments-button" onClick={() => onChangeCollapse('anchorAttachments')}>
          <>{collapseKeys.includes('anchorAttachments') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
          <span>{t('anchor.anchorAttachments')}</span>
        </span>
      </h5>

      <div
        className={`collapse-body ${collapseKeys.includes('anchorAttachments') ? 'show' : ''} ${
          isCompareVersion ? getClassNameForKey(isOldVersion, SYSTEM_FIELD_ATTACHMENTS, listChange) : ''
        }`}
      >
        <div className="pt-3">
          <BasicUploadMultipleFiles
            editingItem={editingItem}
            uploadPath={uploadPath}
            hasGetFullFilesInfo={hasGetFullFilesInfo}
            attachments={attachments}
            isReadOnly={isReadOnly}
            onChangeAttachments={onChangeAttachments}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export default BoxAttachments;
