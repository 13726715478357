import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin, Tabs } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { WORK_ITEM_SEARCH_FIELD_LABEl_RENDER } from '../../constants/fields/manager-fields';
import { JIRA_PLATFORM_ID, TESTMAN_PLATFORM_ID } from '../../constants';
import { debounce } from '../../common';

let typingTimerOfSearch = 0;

export const FieldStatus = ({
  isTestTicket,
  field,
  currentValues,
  defaultSystem = TESTMAN_PLATFORM_ID,
  allowSearch = true,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For jira integration
  const workflowStatusList = useStoreState(state => state.jiraIntegration.workflowStatusList);
  const loadingWorkflowStatusList = useStoreState(state => state.jiraIntegration.loadingWorkflowStatusList);
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [system, setSystem] = useState(defaultSystem);

  /**
   * Set option list
   */
  useEffect(() => {
    if (
      !system ||
      (system === TESTMAN_PLATFORM_ID && !(Array.isArray(field?.data?.listStates) && field?.data?.listStates.length))
    ) {
      return;
    }

    const newOptionList = getOptions();
    setOptions(newOptionList);
  }, [t, field, system, workflowStatusList]);
  /**
   * Get options
   */
  const getOptions = () => {
    let newOptionList = [];
    if (Array.isArray(workflowStatusList) && workflowStatusList.length) {
      newOptionList.push(
        ...workflowStatusList.map(item => {
          return {
            label: item?.name,
            value: item?.id,
            color: item?.color,
            background: item?.background,
            option: item,
            systemType: JIRA_PLATFORM_ID
          };
        })
      );
    }

    if (Array.isArray(field?.data?.listStates) && field?.data?.listStates.length) {
      newOptionList.push(
        ...field.data.listStates.map(item => {
          return {
            label: t(item?.name),
            value: item?.id,
            color: item?.color,
            background: item?.background,
            option: item,
            systemType: TESTMAN_PLATFORM_ID
          };
        })
      );
    }
    return newOptionList;
  };

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (
      !field?.refName ||
      !(Array.isArray(currentValues) && currentValues.length) ||
      !(Array.isArray(options) && options.length)
    ) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.refName === field.refName);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(
      item => currentValue.includes(item.value) || currentValue.includes(Number(item.value))
    );

    setSelectedOptions(newSelectedOptions);

    return newSelectedOptions;
  }, [field, options, currentValues, system]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) setSelectedOptions(currentSelectedOptions);
    if (!visible) handleSearch('');
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (!currentOption?.value) return;

    let newSelectedValues = selectedOptions.filter(item => options.some(item1 => item1.value === item.value));

    if (checked) newSelectedValues = [...newSelectedValues, currentOption];
    else newSelectedValues = [...newSelectedValues, currentOption].filter(item => item.value !== currentOption.value);

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = async val => {
    if (!field?.refName) return;

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        let originList = [];
        originList = getOptions();
        const newOptionList = originList
          .filter(item => typeof item.label === 'string' && item.label.toLowerCase().indexOf(val.toLowerCase()) !== -1)
          .map(item => {
            return {
              label: t(item.label),
              value: `${item.value}`,
              color: item.color,
              background: item.background,
              systemType: system
            };
          });
        setOptions(newOptionList);
      }),
      300
    );
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    const selectedValue =
      Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.map(item => item.value) : [];

    const integrationSystems =
      Array.isArray(integrationSystemList) && integrationSystemList.length ? [...integrationSystemList] : [];

    const tabItems = integrationSystems
      .filter(item => {
        if (isTestTicket && item?.id !== TESTMAN_PLATFORM_ID) return false;
        return true;
      })
      .map(item => {
        return {
          key: item?.id,
          label: item?.title,
          children: (
            <>
              {allowSearch && (
                <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
                  <Input
                    placeholder={t('common.search')}
                    suffix={<SearchOutlined />}
                    autoFocus
                    autoComplete="off"
                    allowClear
                    size="small"
                    bordered={false}
                    onChange={e => handleSearch(e?.target?.value)}
                  />
                </div>
              )}

              {!(Array.isArray(options) && options.length > 0) && (
                <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
                  <li
                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                    onClick={e => e.stopPropagation()}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
                  </li>
                </ul>
              )}

              {Array.isArray(options) && options.length > 0 && (
                <ul
                  className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
                  onClick={e => e.stopPropagation()}
                >
                  {options
                    .filter(item => item?.systemType === system)
                    .map(item => (
                      <li
                        key={item?.value}
                        title={item?.label}
                        className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                        onClick={e => e.stopPropagation()}
                      >
                        <Checkbox
                          checked={selectedValue.includes(item?.value)}
                          className="w-100"
                          onChange={e => handleChangeSelected(e?.target?.checked, item)}
                        >
                          {WORK_ITEM_SEARCH_FIELD_LABEl_RENDER(field?.refName, item) || item?.label}
                        </Checkbox>
                      </li>
                    ))}
                </ul>
              )}
            </>
          )
        };
      });
    return (
      <div className="ant-dropdown-menu c-field-status-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingWorkflowStatusList}>
          <Tabs
            items={tabItems}
            activeKey={system}
            size="small"
            tabBarStyle={{ padding: '2px 12px 0' }}
            className="ant-tabs-nav-mb-1"
            onChange={setSystem}
          />

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                onApply(selectedOptions.map(item => item.value));
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          Array.isArray(currentSelectedOptions) && currentSelectedOptions.length
            ? `${t(`workItem.${field.name}`)}: ${currentSelectedOptions.map(item => item.label).join(', ')}`
            : `${t(`workItem.${field.name}`)}: ${t('common.all')}`
        }
        className={`btn-field field-status btn-toggle-dropdown-with-checkbox-list ${className}`}
        {...rest}
      >
        {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {currentSelectedOptions.map(item => item.label).join(', ')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <div className="c-field-select">
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </div>
  );
};
