import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';
import _debounce from 'lodash/debounce';
import { Col, Row } from 'antd';

import {
  PAGE_SIZE,
  DEFAULT_ORDER,
  SS_EDITING_CELL,
  SS_LAST_SAVED_VALUE,
  SYSTEM_FIELD_TEST_CONFIG,
  SS_LOADED_ALL_SUGGESTION_INFO
} from '../../../../constants';
import {
  removeDuplicate,
  getObjectByValue,
  reactSessionStorage,
  handleSetLastSavedValueToSession
} from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { TestEnvironmentVariableListPopover } from '../../../../components';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import SuggestionKeyboardEvents from './suggestion-keyboard-events';
import SuggestionFormItem from './suggestion-form-item';

const SuggestionField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onGetSuggestion,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For suggestion store
  const allSuggestion = useStoreState(state => state.global.allSuggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : objectPath.get(row, field?.refName);
  }, [row, field, formItemName]);

  /**
   * Field label
   * Field value
   */
  const fieldLabel = useMemo(() => field?.data?.fieldLabel, [field]);
  const fieldValue = useMemo(() => field?.data?.fieldValue, [field]);

  /**
   * Render label for test config
   */
  const renderLabelForTestConfig = item => {
    return (
      <Row
        align="middle"
        justify="space-between"
        wrap={false}
        className="item-test-environment-variable text-truncate w-100"
      >
        <Col style={{ flex: '0 0 calc(100% - 15px)', width: '0 0 calc(100% - 15px)' }} className="text-truncate pr-2">
          {item?.[fieldLabel]}
        </Col>
        <Col style={{ flex: '0 0 15px', width: '15px' }}>
          <TestEnvironmentVariableListPopover testEnvironment={item} restPopover={{ zIndex: 1060 }} />
        </Col>
      </Row>
    );
  };

  /**
   * Options
   */
  const options = useMemo(() => {
    if (!field?.data?.url || !fieldLabel || !fieldValue) return [];

    let rows = allSuggestion?.[field?.refName]?.rows;
    rows = Array.isArray(rows) && rows.length ? [...rows] : [];

    const currentSuggestion = val?.[fieldValue]
      ? [
          {
            label: field?.refName === SYSTEM_FIELD_TEST_CONFIG ? renderLabelForTestConfig(val) : val?.[fieldLabel],
            title: val?.[fieldLabel],
            value: val?.[fieldValue],
            item: val
          }
        ]
      : [];

    const suggestionList = [...rows].map(item => {
      return {
        label: field?.refName === SYSTEM_FIELD_TEST_CONFIG ? renderLabelForTestConfig(item) : item?.[fieldLabel],
        title: item?.[fieldLabel],
        value: item?.[fieldValue],
        suggestion: item
      };
    });

    const newOptions = removeDuplicate([...currentSuggestion, ...suggestionList], 'value');

    return newOptions;
  }, [val, row, field, fieldLabel, fieldValue, allSuggestion]);

  /**
   * Handle get suggestion
   */
  const handleGetSuggestion = ({ row, field, keyword }) => {
    if (!field?.data?.url) return;

    const sessionInfo = reactSessionStorage.getObject(SS_LOADED_ALL_SUGGESTION_INFO, {});
    const params = {};

    if (field?.data?.params) {
      Object.keys(field?.data?.params).forEach(key => {
        params[key] = objectPath.get(row, field?.data?.params[key]);
      });
    }

    const referenceField = field?.refName;
    const newParams = {
      params,
      referenceField,
      url: field?.data?.url,
      filter: { ...field?.data?.filter },
      group: field?.data?.fieldValue,
      limit: PAGE_SIZE,
      page: 1,
      order: DEFAULT_ORDER
    };

    if (keyword) {
      newParams.keyword = keyword;
      newParams.filter = {
        ...newParams.filter
      };

      if (Array.isArray(field?.data?.suggestionBy) && field?.data?.suggestionBy.length) {
        field?.data?.suggestionBy.forEach(k => {
          newParams.filter[k] = {
            $regex: keyword,
            $options: 'i'
          };
        });
      }
    }

    reactSessionStorage.setObject(SS_LOADED_ALL_SUGGESTION_INFO, {
      ...(sessionInfo || {}),
      [field.refName]: keyword
    });

    if (typeof onGetSuggestion === 'function') onGetSuggestion(newParams);
  };

  /**
   * When dropdown is open: Get suggestion
   */
  useEffect(() => {
    if (field?.data?.url && isOpenDropdown) {
      const sessionInfo = reactSessionStorage.getObject(SS_LOADED_ALL_SUGGESTION_INFO, {});

      setSearchValue(sessionInfo?.[field.refName]);

      if (!Object.keys(sessionInfo).includes(field.refName)) {
        handleGetSuggestion({ row, field, keyword: '' });
      }
    }
  }, [isOpenDropdown, row, field]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!val?.[fieldValue] || !(Array.isArray(options) && options.length)) return null;

    return getObjectByValue(val[fieldValue], options);
  }, [val, fieldValue, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(null);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastOption =
      lastValue !== undefined
        ? {
            label:
              field?.refName === SYSTEM_FIELD_TEST_CONFIG
                ? renderLabelForTestConfig(lastValue)
                : lastValue?.[fieldLabel],
            title: lastValue?.[fieldLabel],
            value: lastValue?.[fieldValue],
            suggestion: lastValue
          }
        : opt;

    return lastOption;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    // For new row
    if (row?.isNew) {
      setIsOpenDropdown(true);
      reactSessionStorage.setObject(SS_EDITING_CELL, { x, y, hasOpenedSelectDropdown: true });
    }

    // For existed row
    else {
      const triggerFocusElement = document.querySelector(
        `.trigger-focus-element[data-form-item-name="${formItemName}"]`
      );
      triggerFocusElement?.classList.remove('focused');

      setIsOpenDropdown(true);
      setVisibleFormItem(true);
      handleSetEditingCell({ x, y, hadJustClicked: true });
      reactSessionStorage.setObject(SS_EDITING_CELL, { x, y, hasOpenedSelectDropdown: true });
    }
  };

  /**
   * Handle open when has opened
   */
  useEffect(() => {
    const editingCellLocal = reactSessionStorage.getObject(SS_EDITING_CELL, {});

    if (editingCellLocal?.x === x && editingCellLocal?.y === y && editingCellLocal?.hasOpenedSelectDropdown) {
      handleOpen();
    }
  }, [x, y, row]);

  /**
   * Handle close
   */
  const handleClose = opt => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOption(opt);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newVal = opt?.suggestion || null;
      handleSetLastSavedValueToSession({ [formItemName]: newVal });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: newVal }, row, field });
      }
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Handle search
   */
  const handleSearch = useCallback(
    _debounce(keyword => handleGetSuggestion({ row, field, keyword }), 300),
    [row, field]
  );

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    options,
    filterOption: false,
    searchValue,
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    placeholder: placeholder || t('common.search'),
    loading: loadingSuggestion,
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => {
      if (!row?.isNew) handleSave(option);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onChange: (key, option) => {
      if (row?.isNew) handleSave(option);
    },
    onSearch: setSearchValue,
    onKeyUp: e => handleSearch(e?.target?.value),
    onClick: handleOpen
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const option = getCurrentOption(currentOption);

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div
          title={option?.title || ''}
          className={`cell-text cursor-text ${field?.refName === SYSTEM_FIELD_TEST_CONFIG ? 'hide-after' : ''}`}
          onClick={handleOpen}
        >
          <span className="cell-text-value min-h-22 text-truncate">{option?.label || props?.placeholder}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={option?.title || ''} row={row} field={field}>
          {option?.label}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={option?.title || ''} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {option?.label}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <span title={option?.title || ''}>{option?.label}</span>;
    }

    // Readonly
    else {
      return (
        <div title={option?.title || ''} className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{option?.label}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <SuggestionFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <SuggestionKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{t('common.pleaseSelect')}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper suggestion-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new suggestion-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default SuggestionField;
