import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Divider, Form, Input, Modal, Row, Spin, Tooltip } from 'antd';
import {
  UploadOutlined,
  FileImageOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';

import { getFullFileInfoFromInputFile } from '../../common';
import { BasicPreviewFile } from '../basic-preview-file';

import './style.scss';

export const PrepareAttachScreenshotModal = ({
  visible,
  file,
  loadingUpload,
  className = '',
  onFormChange,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [fileInfo, setFileInfo] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Current extension
   */
  const extension = useMemo(() => {
    const extension = typeof file?.name === 'string' ? file?.name.split('.').pop() : '';
    return extension;
  }, [file]);

  /**
   * Set values to form
   */
  useEffect(() => {
    const newFileName = typeof file?.name === 'string' ? file?.name.split('.').shift() : '';

    form.setFieldsValue({
      fileName: newFileName || `${t('common.screenshot')} - ${moment().format('YYYY-MM-DD-HH-mm-ss')}`
    });

    if (file) {
      (async () => {
        const res = await getFullFileInfoFromInputFile({ file });
        setFileInfo(res);
      })();
    }
  }, [file]);

  return (
    <>
      <Modal
        open={visible}
        width={720}
        maskClosable={false}
        keyboard={false}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="c-prepare-attach-screenshot-modal-wrapper modal-fixed-header"
        className={`c-prepare-attach-screenshot-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <Form form={form} labelCol={{ sm: 6, md: 6 }} wrapperCol={{ sm: 17, md: 16 }} onFinish={onSubmit}>
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">
                <FileImageOutlined className="mr-1" />
                {t('common.attachScreenshot')}
              </h3>

              <Row align="middle" justify="end" wrap={false} className="box-extra">
                <Tooltip
                  open={visibleFullScreenTooltip}
                  title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                  placement={fullScreen ? 'bottomRight' : 'bottom'}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                  onOpenChange={setVisibleFullScreenTooltip}
                >
                  <Button
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    type="link"
                    className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setVisibleFullScreenTooltip(false);
                    }}
                  />
                </Tooltip>
              </Row>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>

          <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
            <div className="form-wrapper p-4">
              <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingUpload}>
                <Form.Item
                  label={t('workItem.name')}
                  name="fileName"
                  validateTrigger="onBlur"
                  rules={[{ required: true, whitespace: true, message: t('message.required') }]}
                  extra={t('common.youCanChangeNameForYourFileImagine')}
                  className="mb-2"
                >
                  <Input
                    placeholder={t('workItem.enterName')}
                    onChange={e => onFormChange({ fileName: `${e?.target?.value}.${extension}` })}
                    addonAfter={extension ? `.${extension}` : null}
                  />
                </Form.Item>

                <BasicPreviewFile fileInfo={fileInfo} showHeader={false} onlyPreviewFileOnClient={true} />
              </Spin>
            </div>
          </div>

          <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
            <Divider className="mt-0 mb-3" />

            <div className="text-right">
              <Button type="text" onClick={onCancel}>
                {t('common.cancel')}
              </Button>

              <Button htmlType="submit" type="primary" icon={<UploadOutlined />} disabled={loadingUpload}>
                {t('common.upload')}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
