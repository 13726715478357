import React from 'react';
import i18next from 'i18next';
import { Form, Select } from 'antd';

import { PRIORITIES, SYSTEM_FIELD_LATEST_RESULT, SYSTEM_FIELD_PRIORITY, SYSTEM_FIELD_STATUS } from '../../constants';
import { StatusLabel } from '../../components';

export const WORK_ITEM_FIELD_RENDER = ({ field, isReadOnly = false, restField, onChangeCallback, ...rest }) => {
  if (!field?.refName) return;

  const name = rest?.name ? rest?.name : field?.refName;

  switch (field?.name) {
    case SYSTEM_FIELD_PRIORITY: {
      return (
        <Form.Item
          label={i18next.t('akaat:workItem.priority')}
          name={name}
          rules={[{ required: field?.mandatory, message: i18next.t('akaat:message.required') }]}
          className="mb-3"
          initialValue={field?.defaultValue}
          {...rest}
        >
          <Select
            optionFilterProp="label"
            showSearch
            allowClear
            placeholder={i18next.t('akaat:common.pleaseSelect')}
            disabled={isReadOnly}
            className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
            onChange={e => {
              if (typeof onChangeCallback === 'function') onChangeCallback(e);
            }}
            {...restField}
          >
            {PRIORITIES.map(item => (
              <Select.Option key={item.value} title={item.label} value={item.value}>
                {item.icon}
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    default:
      break;
  }
};

export const WORK_ITEM_SEARCH_FIELD_LABEl_RENDER = (refName, item) => {
  switch (refName) {
    case SYSTEM_FIELD_PRIORITY: {
      const systemPriority = PRIORITIES.find(pri => pri.value === item.value);
      return (
        <>
          {systemPriority ? (
            <span key={item.value} title={systemPriority.label} className="text-truncate">
              {systemPriority.icon}
              {systemPriority.label}
            </span>
          ) : (
            <span key={item.value} title={item?.label} className="text-truncate">
              {item?.icon}
              {item?.label}
            </span>
          )}
        </>
      );
    }

    case SYSTEM_FIELD_STATUS: {
      return (
        <>
          <StatusLabel status={item?.option} />
        </>
      );
    }
    case SYSTEM_FIELD_LATEST_RESULT: {
      const getStyle = item => {
        return {
          color: item.color ? `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})` : 'rgb(0, 0, 0)',
          background: item.background
            ? `rgb(${item.background.r}, ${item.background.g}, ${item.background.b})`
            : 'rgb(240, 240, 240)'
        };
      };
      return (
        <div className="c-status-label">
          <span title={item?.label || ''} style={getStyle(item)} className="status-label text-truncate">
            {item?.label || ''}
          </span>
        </div>
      );
    }
  }
};
