import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../../../../common';

export const model = {
  /**
   * State
   */
  loadingTestResultJobRunnerExportedData: false,
  fieldListResultJobRunner: [],
  loadingFieldListJobRunner: false,

  /**
   * Action: Set loading test result exported data
   */
  setLoadingTestResultJobRunnerExportedData: action((state, payload) => {
    if (state?.loadingTestResultJobRunnerExportedData === undefined) return;

    state.loadingTestResultJobRunnerExportedData = payload;
  }),

  /**
   * Action: Call api to get test result exported data
   */
  getTestResultJobRunnerExportedData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload?.fieldList) && payload.fieldList.length)) {
        throw new Error('Fields export empty');
      }

      action.setLoadingTestResultJobRunnerExportedData(true);

      const { fieldList, jobRunnerId, order } = payload;
      const filterOption = {
        order
      };
      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_TEST_RESULT
      }${ENDPOINTS.AUTOMATION_EXPORT_EXCEL}/${jobRunnerId}?${buildQueryForFilterMongo(filterOption)}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, fieldList, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestResultJobRunnerExportedData(false);
    }
  }),

  /**
   * Action: Call api to get test exported data for csv
   */
  getExportedCSVTestResultJobRunnerData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload?.fieldList) && payload.fieldList.length)) {
        throw new Error('Fields export empty');
      }

      if (!payload.dataSource) {
        throw new Error('Invalid datasource');
      }

      action.setLoadingTestResultJobRunnerExportedData(true);

      const { fieldList, jobRunnerId, order } = payload;
      const filterOption = {
        order
      };

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_TEST_RESULT
      }${ENDPOINTS.AUTOMATION_EXPORT_CSV}/${jobRunnerId}?${buildQueryForFilterMongo(filterOption)}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, fieldList, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestResultJobRunnerExportedData(false);
    }
  })
};
