import * as monaco from 'monaco-editor';
import {
  DiagnosticSeverity,
  CompletionItemTag,
  MarkupContent,
  InsertTextFormat,
  TextEdit,
  CompletionItemKind,
  DiagnosticTag,
  CreateFile,
  RenameFile,
  DeleteFile,
  TextDocumentEdit,
  MarkedString,
  SymbolKind,
  SymbolTag,
  DocumentHighlightKind,
  FoldingRangeKind
} from 'vscode-languageserver-types';

export function convertProtocolRangeToMonacoRange(range) {
  return {
    startLineNumber: range.start.line + 1,
    startColumn: range.start.character + 1,
    endLineNumber: range.end.line + 1,
    endColumn: range.end.character + 1
  };
}

export function convertProtocolDiagnosticRelatedInformationToMonacoMarkerRelatedInformation(info) {
  return {
    resource: monaco.Uri.parse(info.location.uri),
    startLineNumber: info.location.range.start.line + 1,
    startColumn: info.location.range.start.character + 1,
    endLineNumber: info.location.range.end.line + 1,
    endColumn: info.location.range.end.character + 1,
    message: info.message
  };
}

export function convertProtocolDiagnosticSeverityToMonacoMarkerSeverity(severity) {
  if (severity) {
    switch (severity) {
      case DiagnosticSeverity.Error:
        return monaco.MarkerSeverity.Error;
      case DiagnosticSeverity.Warning:
        return monaco.MarkerSeverity.Warning;
      case DiagnosticSeverity.Information:
        return monaco.MarkerSeverity.Info;
      default:
        return monaco.MarkerSeverity.Hint;
    }
  } else {
    return monaco.MarkerSeverity.Hint;
  }
}

export function convertProtocolDiagnosticTagToMonacoMarkerTag(tag) {
  switch (tag) {
    case DiagnosticTag.Deprecated:
      return monaco.MarkerTag.Deprecated;
    default:
      return monaco.MarkerTag.Unnecessary;
  }
}

export function convertProtocolDiagnosticToMonacoMarkerData(diagnostic) {
  return {
    code: diagnostic.code ? diagnostic.code.toString() : undefined,
    severity: convertProtocolDiagnosticSeverityToMonacoMarkerSeverity(diagnostic.severity),
    message: diagnostic.message,
    source: diagnostic.source,
    tags: diagnostic.tags ? diagnostic.tags.map(tag => convertProtocolDiagnosticTagToMonacoMarkerTag(tag)) : undefined,
    startLineNumber: diagnostic.range.start.line + 1,
    startColumn: diagnostic.range.start.character + 1,
    endLineNumber: diagnostic.range.end.line + 1,
    endColumn: diagnostic.range.end.character + 1,
    relatedInformation: diagnostic.relatedInformation
      ? diagnostic.relatedInformation.map(info =>
          convertProtocolDiagnosticRelatedInformationToMonacoMarkerRelatedInformation(info)
        )
      : undefined
  };
}

export function convertProtocoloCompletionItemTagToMonacoCompletionItemTag(tag) {
  switch (tag) {
    case CompletionItemTag.Deprecated:
    default:
      return monaco.languages.CompletionItemTag.Deprecated;
  }
}

export function convertProtocolMarkupContentToMonacoMarkdownString(documentation) {
  if (MarkupContent.is(documentation)) {
    return { value: documentation.value };
  }
  return documentation;
}

export function convertProtocolInsertTextRuleToMonacoInsertTextRule(rule) {
  switch (rule) {
    case InsertTextFormat.Snippet:
      return monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet;
    default:
      return monaco.languages.CompletionItemInsertTextRule.KeepWhitespace;
  }
}

export function convertProtocolCompletionItemKindToMonacoCompletionItemKind(kind) {
  if (kind) {
    switch (kind) {
      case CompletionItemKind.Method:
        return monaco.languages.CompletionItemKind.Method;
      case CompletionItemKind.Function:
        return monaco.languages.CompletionItemKind.Function;
      case CompletionItemKind.Constructor:
        return monaco.languages.CompletionItemKind.Constructor;
      case CompletionItemKind.Field:
        return monaco.languages.CompletionItemKind.Field;
      case CompletionItemKind.Variable:
        return monaco.languages.CompletionItemKind.Variable;
      case CompletionItemKind.Class:
        return monaco.languages.CompletionItemKind.Class;
      case CompletionItemKind.Interface:
        return monaco.languages.CompletionItemKind.Interface;
      case CompletionItemKind.Module:
        return monaco.languages.CompletionItemKind.Module;
      case CompletionItemKind.Property:
        return monaco.languages.CompletionItemKind.Property;
      case CompletionItemKind.Unit:
        return monaco.languages.CompletionItemKind.Unit;
      case CompletionItemKind.Value:
        return monaco.languages.CompletionItemKind.Value;
      case CompletionItemKind.Enum:
        return monaco.languages.CompletionItemKind.Enum;
      case CompletionItemKind.Keyword:
        return monaco.languages.CompletionItemKind.Keyword;
      case CompletionItemKind.Snippet:
        return monaco.languages.CompletionItemKind.Snippet;
      case CompletionItemKind.Color:
        return monaco.languages.CompletionItemKind.Color;
      case CompletionItemKind.File:
        return monaco.languages.CompletionItemKind.File;
      case CompletionItemKind.Reference:
        return monaco.languages.CompletionItemKind.Reference;
      case CompletionItemKind.Folder:
        return monaco.languages.CompletionItemKind.Folder;
      case CompletionItemKind.EnumMember:
        return monaco.languages.CompletionItemKind.EnumMember;
      case CompletionItemKind.Constant:
        return monaco.languages.CompletionItemKind.Constant;
      case CompletionItemKind.Struct:
        return monaco.languages.CompletionItemKind.Struct;
      case CompletionItemKind.Event:
        return monaco.languages.CompletionItemKind.Event;
      case CompletionItemKind.Operator:
        return monaco.languages.CompletionItemKind.Operator;
      case CompletionItemKind.TypeParameter:
        return monaco.languages.CompletionItemKind.TypeParameter;
      default:
        return monaco.languages.CompletionItemKind.Text;
    }
  }
  return monaco.languages.CompletionItemKind.Text;
}

export function convertProtocolAdditionalTextEditToMonacoSingleEditOperation(textEdit) {
  return {
    range: convertProtocolRangeToMonacoRange(textEdit.range),
    text: textEdit.newText
  };
}

export function convertProtocolCommandToMonacoCommand(command) {
  return {
    id: command.command,
    title: command.title,
    arguments: command.arguments
  };
}

function getCompletionItemInsertText(completionItem, defaultRange) {
  if (completionItem.textEdit) {
    if (TextEdit.is(completionItem.textEdit)) {
      return {
        insertText: completionItem.textEdit.newText,
        range: convertProtocolRangeToMonacoRange(completionItem.textEdit.range)
      };
    }
    return {
      insertText: completionItem.textEdit.newText,
      range: {
        insert: convertProtocolRangeToMonacoRange(completionItem.textEdit.insert),
        replace: convertProtocolRangeToMonacoRange(completionItem.textEdit.replace)
      }
    };
  }
  if (completionItem.insertText) {
    return { insertText: completionItem.insertText, range: defaultRange };
  }
  return { insertText: '', range: defaultRange };
}

export function convertProtocolCompletionItemToMonacoCompletionItem(completionItem, defaultRange) {
  const calculateInsert = getCompletionItemInsertText(completionItem, defaultRange);
  const labelArr = completionItem.label.split('+');
  return {
    label:
      labelArr.length > 1
        ? {
            label: labelArr[0],
            detail: labelArr[1],
            description: labelArr[2]
          }
        : completionItem.label,
    kind: convertProtocolCompletionItemKindToMonacoCompletionItemKind(completionItem.kind),
    tags: completionItem.tags
      ? completionItem.tags.map(tag => convertProtocoloCompletionItemTagToMonacoCompletionItemTag(tag))
      : undefined,
    detail: completionItem.detail,
    documentation: convertProtocolMarkupContentToMonacoMarkdownString(completionItem.documentation),
    sortText: completionItem.sortText,
    filterText: completionItem.filterText,
    preselect: completionItem.preselect,
    insertText: calculateInsert.insertText,
    insertTextRules: completionItem.insertTextFormat
      ? convertProtocolInsertTextRuleToMonacoInsertTextRule(completionItem.insertTextFormat)
      : undefined,
    range: calculateInsert.range,
    commitCharacters: completionItem.commitCharacters,
    additionalTextEdits: completionItem.additionalTextEdits
      ? completionItem.additionalTextEdits.map(edit =>
          convertProtocolAdditionalTextEditToMonacoSingleEditOperation(edit)
        )
      : undefined,
    command: completionItem.command ? convertProtocolCommandToMonacoCommand(completionItem.command) : undefined,
    data: completionItem.data
  };
}

export function convertProtocolCompletionListToMonacoCompletionList(completionList, defaultRange) {
  return {
    suggestions: completionList.items.map(item =>
      convertProtocolCompletionItemToMonacoCompletionItem(item, defaultRange)
    ),
    incomplete: completionList.isIncomplete
  };
}

export function convertProtocolCompletionItemListToMonacoCompletionList(completionList, defaultRange) {
  return {
    suggestions: completionList.map(item => convertProtocolCompletionItemToMonacoCompletionItem(item, defaultRange)),
    incomplete: false
  };
}

export function convertProtocolChangeAnnotationToWorkspaceEditMetaData(change) {
  return {
    label: change.label,
    needsConfirmation: change.needsConfirmation ? change.needsConfirmation : false,
    description: change.description
  };
}

export function convertProtocolTextEditToMonacoTextEdit(edit) {
  return {
    range: convertProtocolRangeToMonacoRange(edit.range),
    text: edit.newText
  };
}

function convertProtocolWorkspaceEditChangeToMonacoWorkspaceTextEdit(uri, change, metadata) {
  return {
    resource: monaco.Uri.parse(uri),
    metadata,
    textEdit: convertProtocolTextEditToMonacoTextEdit(change),
    versionId: -1
  };
}

export function convertProtocolWorkspaceEditToMonacoWorkspaceEdit(edit) {
  const edits = [];

  const workspaceEditMetaDataMapping = {};
  if (edit.changeAnnotations) {
    Object.keys(edit.changeAnnotations).forEach(id => {
      const annotation = edit.changeAnnotations[id];
      workspaceEditMetaDataMapping[id] = convertProtocolChangeAnnotationToWorkspaceEditMetaData(annotation);
    });
  }

  if (edit.documentChanges) {
    edit.documentChanges.forEach(change => {
      if (CreateFile.is(change)) {
        //  Not support
      } else if (RenameFile.is(change)) {
        //  Not support
      } else if (DeleteFile.is(change)) {
        //  Not support
      } else if (TextDocumentEdit.is(change)) {
        change.edits.forEach(tde => {
          edits.push(
            convertProtocolWorkspaceEditChangeToMonacoWorkspaceTextEdit(
              change.textDocument.uri,
              tde,
              workspaceEditMetaDataMapping[change.textDocument.uri]
            )
          );
        });
      }
    });
  } else if (edit.changes) {
    Object.keys(edit.changes).forEach(uri => {
      edit.changes[uri].forEach(change => {
        edits.push(
          convertProtocolWorkspaceEditChangeToMonacoWorkspaceTextEdit(uri, change, workspaceEditMetaDataMapping[uri])
        );
      });
    });
  }
  return { edits };
}

export function convertProtocolCodeActionToMonacoCodeAction(codeAction) {
  return {
    command: codeAction.command ? convertProtocolCommandToMonacoCommand(codeAction.command) : undefined,
    diagnostics: codeAction.diagnostics
      ? codeAction.diagnostics.map(diagnostic => convertProtocolDiagnosticToMonacoMarkerData(diagnostic))
      : undefined,
    disabled: codeAction.disabled ? codeAction.disabled.reason : undefined,
    edit: codeAction.edit ? convertProtocolWorkspaceEditToMonacoWorkspaceEdit(codeAction.edit) : undefined,
    isPreferred: codeAction.isPreferred,
    kind: codeAction.kind,
    title: codeAction.title
  };
}

export function convertProtocolCommandToMonacoCodeAction(command) {
  return {
    command: convertProtocolCommandToMonacoCommand(command),
    title: command.title,
    kind: 'quickfix'
  };
}

export function convertProtocolTextEditToMonacoIIdentifiedSingleEditOperation(edit) {
  return {
    text: edit.newText,
    range: convertProtocolRangeToMonacoRange(edit.range)
  };
}

function convertProtocolMarkedStringToString(ms) {
  if (typeof ms === 'string') {
    return ms;
  }
  return `\`\`\`${ms.language}\n${ms.value}\n\`\`\``;
}

function convertProtocolHoverContentsToMonacoContents(contents) {
  if (Array.isArray(contents)) {
    return contents.map(ms => {
      return { value: convertProtocolMarkedStringToString(ms) };
    });
  }
  if (MarkedString.is(contents)) {
    return [{ value: convertProtocolMarkedStringToString(contents) }];
  }
  return [convertProtocolMarkupContentToMonacoMarkdownString(contents)];
}

export function convertProtocolHoverToMonacoHover(hover) {
  return {
    contents: convertProtocolHoverContentsToMonacoContents(hover.contents),
    range: hover.range ? convertProtocolRangeToMonacoRange(hover.range) : undefined
  };
}

export function convertProtocolColorPresentationToMonacoColorPresentation(presentation) {
  return {
    label: presentation.label,
    textEdit: presentation.textEdit ? convertProtocolTextEditToMonacoTextEdit(presentation.textEdit) : undefined,
    additionalTextEdits: presentation.additionalTextEdits
      ? presentation.additionalTextEdits.map(edit => convertProtocolTextEditToMonacoTextEdit(edit))
      : undefined
  };
}

export function convertProtocolColorToMonacoColor(color) {
  return {
    red: color.red,
    green: color.green,
    blue: color.blue,
    alpha: color.alpha
  };
}

export function convertProtocolColorInformationToMonacoColorInformation(info) {
  return {
    range: convertProtocolRangeToMonacoRange(info.range),
    color: convertProtocolColorToMonacoColor(info.color)
  };
}

export function convertProtocolSymbolKindToMonacoSymbolKind(kind) {
  switch (kind) {
    case SymbolKind.Array:
      return monaco.languages.SymbolKind.Array;
    case SymbolKind.Boolean:
      return monaco.languages.SymbolKind.Boolean;
    case SymbolKind.Class:
      return monaco.languages.SymbolKind.Class;
    case SymbolKind.Constant:
      return monaco.languages.SymbolKind.Constant;
    case SymbolKind.Constructor:
      return monaco.languages.SymbolKind.Constructor;
    case SymbolKind.Enum:
      return monaco.languages.SymbolKind.Enum;
    case SymbolKind.EnumMember:
      return monaco.languages.SymbolKind.EnumMember;
    case SymbolKind.Event:
      return monaco.languages.SymbolKind.Event;
    case SymbolKind.Field:
      return monaco.languages.SymbolKind.Field;
    case SymbolKind.File:
      return monaco.languages.SymbolKind.File;
    case SymbolKind.Function:
      return monaco.languages.SymbolKind.Function;
    case SymbolKind.Interface:
      return monaco.languages.SymbolKind.Interface;
    case SymbolKind.Key:
      return monaco.languages.SymbolKind.Key;
    case SymbolKind.Method:
      return monaco.languages.SymbolKind.Method;
    case SymbolKind.Module:
      return monaco.languages.SymbolKind.Module;
    case SymbolKind.Namespace:
      return monaco.languages.SymbolKind.Namespace;
    case SymbolKind.Number:
      return monaco.languages.SymbolKind.Number;
    case SymbolKind.Object:
      return monaco.languages.SymbolKind.Object;
    case SymbolKind.Operator:
      return monaco.languages.SymbolKind.Operator;
    case SymbolKind.Package:
      return monaco.languages.SymbolKind.Package;
    case SymbolKind.Property:
      return monaco.languages.SymbolKind.Property;
    case SymbolKind.String:
      return monaco.languages.SymbolKind.String;
    case SymbolKind.Struct:
      return monaco.languages.SymbolKind.Struct;
    case SymbolKind.TypeParameter:
      return monaco.languages.SymbolKind.TypeParameter;
    case SymbolKind.Variable:
      return monaco.languages.SymbolKind.Variable;
    default:
      return monaco.languages.SymbolKind.Null;
  }
}

export function convertProtocolSymbolTagToMonacoSymbolTag(tag) {
  return SymbolTag.Deprecated;
}

export function convertProtocolDocumentSymbolToMonacoDocumentSymbol(symbol) {
  return {
    name: symbol.name,
    detail: symbol.detail ? symbol.detail : '',
    kind: convertProtocolSymbolKindToMonacoSymbolKind(symbol.kind),
    tags: symbol.tags ? symbol.tags.map(tag => convertProtocolSymbolTagToMonacoSymbolTag(tag)) : [],
    range: convertProtocolRangeToMonacoRange(symbol.range),
    selectionRange: convertProtocolRangeToMonacoRange(symbol.selectionRange),
    children: symbol.children
      ? symbol.children.map(child => convertProtocolDocumentSymbolToMonacoDocumentSymbol(child))
      : undefined
  };
}

export function convertProtocolSymbolInformationToMonacoDocumentSymbol(info) {
  return {
    name: info.name,
    kind: convertProtocolSymbolKindToMonacoSymbolKind(info.kind),
    tags: info.tags ? info.tags.map(tag => convertProtocolSymbolTagToMonacoSymbolTag(tag)) : [],
    containerName: info.containerName,
    detail: '',
    range: convertProtocolRangeToMonacoRange(info.location.range),
    selectionRange: convertProtocolRangeToMonacoRange(info.location.range)
  };
}

export function convertProtocolCodeLensToMonacoCodeLens(codeLen) {
  return {
    range: convertProtocolRangeToMonacoRange(codeLen.range),
    command: codeLen.command ? convertProtocolCommandToMonacoCommand(codeLen.command) : undefined,
    data: codeLen.data
  };
}

export function convertProtocolLocationToMonacoLocation(location) {
  return {
    uri: monaco.Uri.parse(location.uri),
    range: convertProtocolRangeToMonacoRange(location.range)
  };
}

export function convertProtocolLocationLinkToMonacoLocationLink(link) {
  return {
    uri: monaco.Uri.parse(link.targetUri),
    range: convertProtocolRangeToMonacoRange(link.targetSelectionRange),
    originSelectionRange: link.originSelectionRange
      ? convertProtocolRangeToMonacoRange(link.originSelectionRange)
      : undefined,
    targetSelectionRange: link.targetSelectionRange
      ? convertProtocolRangeToMonacoRange(link.targetSelectionRange)
      : undefined
  };
}

export function convertProtocolDocumentHighlightKindToMonacoDocumentHighlightKind(kind) {
  switch (kind) {
    case DocumentHighlightKind.Read:
      return monaco.languages.DocumentHighlightKind.Read;
    case DocumentHighlightKind.Write:
      return monaco.languages.DocumentHighlightKind.Write;
    default:
      return monaco.languages.DocumentHighlightKind.Text;
  }
}

export function convertProtocolDocumentHighlightToMonacoDocumentHighlight(highlight) {
  return {
    range: convertProtocolRangeToMonacoRange(highlight.range),
    kind: highlight.kind ? convertProtocolDocumentHighlightKindToMonacoDocumentHighlightKind(highlight.kind) : undefined
  };
}

export function convertProtocolFoldingRangeKindToMonacoFoldingRangeKind(kind) {
  switch (kind) {
    case FoldingRangeKind.Comment:
      return monaco.languages.FoldingRangeKind.Comment;
    case FoldingRangeKind.Imports:
      return monaco.languages.FoldingRangeKind.Imports;
    case FoldingRangeKind.Region:
      return monaco.languages.FoldingRangeKind.Region;
    default:
      return undefined;
  }
}

export function convertProtocolFoldingRangeToMonacoFoldingRange(folding) {
  return {
    start: folding.startLine + 1,
    end: folding.endLine + 1,
    kind: folding.kind ? convertProtocolFoldingRangeKindToMonacoFoldingRangeKind(folding.kind) : undefined
  };
}

export function convertProtocolSelectionRangeToMonacoSelectionRange(selection) {
  return {
    range: convertProtocolRangeToMonacoRange(selection.range)
  };
}

export function convertProtocolParameterInformationToMonacoParameterInformation(info) {
  return {
    label: info.label,
    documentation: info.documentation
      ? convertProtocolMarkupContentToMonacoMarkdownString(info.documentation)
      : undefined
  };
}

export function convertProtocolSignatureInformationToMonacoSignatureInformation(info) {
  return {
    label: info.label,
    activeParameter: info.activeParameter,
    documentation: info.documentation
      ? convertProtocolMarkupContentToMonacoMarkdownString(info.documentation)
      : undefined,
    parameters: info.parameters
      ? info.parameters.map(param => convertProtocolParameterInformationToMonacoParameterInformation(param))
      : []
  };
}

export function convertProtocolSignatureHelpToMonacoSignatureHelp(help) {
  return {
    activeSignature: help.activeSignature ? help.activeSignature : 0,
    activeParameter: help.activeParameter ? help.activeParameter : 0,
    signatures: help.signatures.map(signature =>
      convertProtocolSignatureInformationToMonacoSignatureInformation(signature)
    )
  };
}

export function convertProtocolSemanticTokenLegendToMonacoSemanticTokenLegend(legend) {
  return {
    tokenTypes: legend.tokenTypes,
    tokenModifiers: legend.tokenModifiers
  };
}
