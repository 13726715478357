import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin, Tabs } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import objectPath from 'object-path';
import { arrayMoveImmutable } from 'array-move';

import { JIRA_PLATFORM_ID, SYSTEM_FIELD_ASSIGN_TO, TESTMAN_PLATFORM_ID } from '../../constants';
import { useUnassignedUser, convertToFullName } from '../../common';
import { UserAvatar } from '../user-avatar';

export const FieldUser = ({
  isTestTicket,
  field,
  defaultSystem = TESTMAN_PLATFORM_ID,
  currentValues,
  allowSearch = true,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For global project
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const loadingProjectUserList = useStoreState(state => state.global.loadingProjectUserList);

  // For jira integration
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);
  const assignableUserList = useStoreState(state => state.jiraIntegration.assignableUserList);
  const loadingAssignableUserList = useStoreState(state => state.jiraIntegration.loadingAssignableUserList);
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [defaultList, setDefaultList] = useState({});
  const [system, setSystem] = useState();

  /**
   * Set default system
   */
  useEffect(() => {
    setSystem(defaultSystem);
  }, [defaultSystem]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (!system) return;

    const setListUser = async () => {
      let newOptionList = await getOptions('', 'init');

      if (newOptionList.some(c => c.id === globalUserInfo.id)) {
        const oldIndex = newOptionList.findIndex(i => i.id === globalUserInfo.id);
        newOptionList = arrayMoveImmutable(newOptionList, oldIndex, 0);
      }

      setOptions(newOptionList);
    };

    setListUser();
  }, [field, system, assignableUserList]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (
      !field?.refName ||
      !(Array.isArray(currentValues) && currentValues.length) ||
      !(Array.isArray(options) && options.length)
    ) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.refName === field.refName);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(item => currentValue.includes(item.value));

    setSelectedOptions(newSelectedOptions);

    if (currentValue.includes('{}')) {
      return [UNASSIGNED, ...newSelectedOptions];
    } else {
      return newSelectedOptions;
    }
  }, [field, options, currentValues, defaultList, system]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) setSelectedOptions(currentSelectedOptions);
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (loadingProjectUserList || !currentOption?.value) return;

    let newSelectedValues = [...selectedOptions];

    if (checked) {
      newSelectedValues = [...newSelectedValues, currentOption];
    } else {
      newSelectedValues = newSelectedValues.filter(item => item.value !== currentOption.value);
    }

    setSelectedOptions(newSelectedValues);
  };

  const getOptions = async (val = '', fromFunction) => {
    let newOptionList = [];

    if (system === JIRA_PLATFORM_ID) {
      let userList = [];
      if (fromFunction === 'init') {
        userList = assignableUserList;
      } else
        userList = await getAssignableUserList({
          username: val
        });

      if (Array.isArray(userList) && userList.length) {
        newOptionList.push(...userList);
        setDefaultList({
          [JIRA_PLATFORM_ID]: newOptionList
        });
      }
    }

    if (Array.isArray(projectUserList) && projectUserList.length) {
      newOptionList.push(
        ...projectUserList.map(item => {
          return {
            ...item,
            fullName: convertToFullName(item),
            label: objectPath.get(item, field?.data?.fieldLabel),
            value: objectPath.get(item, field?.data?.fieldValue),
            systemType: TESTMAN_PLATFORM_ID
          };
        })
      );
    }
    return newOptionList;
  };

  /**
   * Handle search
   */
  const handleSearch = async val => {
    setSearchValue(val);

    const newOptionList = (await getOptions(val)).filter(
      option =>
        option?.fullName?.toLowerCase()?.indexOf(val.toLowerCase()) >= 0 ||
        option?.title?.toLowerCase()?.indexOf(val.toLowerCase()) >= 0 ||
        option?.email?.toLowerCase()?.indexOf(val.toLowerCase()) >= 0 ||
        option?.username?.toLowerCase()?.indexOf(val.toLowerCase()) >= 0
    );

    setOptions(newOptionList);
  };

  /**
   * Handle show/hide field unassigned
   */
  const checkFieldUnassigned = useMemo(() => {
    if (searchValue.length > 0 && UNASSIGNED.value.toLocaleLowerCase().search(searchValue.toLocaleLowerCase()) === -1) {
      return false;
    } else {
      return true;
    }
  }, [searchValue]);

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    let selectedValue =
      Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.map(item => item.value) : [];

    const integrationSystems =
      Array.isArray(integrationSystemList) && integrationSystemList.length ? [...integrationSystemList] : [];

    const tabItems = integrationSystems
      .filter(item => {
        if (isTestTicket && item?.id !== TESTMAN_PLATFORM_ID) return false;
        return true;
      })
      .map(item => {
        return {
          key: item?.id,
          label: item?.title,
          children: (
            <>
              {allowSearch && (
                <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
                  <Input
                    placeholder={t('common.search')}
                    suffix={<SearchOutlined />}
                    autoFocus
                    autoComplete="off"
                    allowClear
                    size="small"
                    bordered={false}
                    value={searchValue}
                    onChange={e => handleSearch(e?.target?.value)}
                  />
                </div>
              )}

              {!(Array.isArray(options) && options.length > 0) && !checkFieldUnassigned && (
                <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
                  <li
                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                    onClick={e => e.stopPropagation()}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
                  </li>
                </ul>
              )}

              {Array.isArray(options) && (
                <ul
                  style={{ overflowX: 'auto' }}
                  className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
                  onClick={e => e.stopPropagation()}
                >
                  {[
                    field?.originRefName === SYSTEM_FIELD_ASSIGN_TO && checkFieldUnassigned && (
                      <li
                        key={UNASSIGNED.value}
                        title={UNASSIGNED.value}
                        className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                        onClick={e => e.stopPropagation()}
                      >
                        <Checkbox
                          checked={selectedValue.includes(UNASSIGNED.value)}
                          className="ant-checkbox-top-0 w-100"
                          onChange={e => handleChangeSelected(e?.target?.checked, UNASSIGNED)}
                        >
                          {UNASSIGNED.label}
                        </Checkbox>
                      </li>
                    ),
                    ...options
                      .filter(item => item?.systemType === system)
                      .map(item => (
                        <li
                          key={item?.value}
                          title={item?.label}
                          className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                          onClick={e => e.stopPropagation()}
                        >
                          <Checkbox
                            checked={selectedValue.includes(item?.value)}
                            className="ant-checkbox-top-0 w-100"
                            onChange={e => handleChangeSelected(e?.target?.checked, item)}
                          >
                            <UserAvatar user={item} style={{ verticalAlign: '-0.3em' }} />
                          </Checkbox>
                        </li>
                      ))
                  ]}
                </ul>
              )}
            </>
          )
        };
      });

    return (
      <div className="ant-dropdown-menu c-field-user-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin
          indicator={<Loading3QuartersOutlined spin />}
          spinning={loadingProjectUserList || loadingAssignableUserList}
        >
          <Tabs
            items={tabItems}
            defaultActiveKey={system}
            onChange={setSystem}
            size="small"
            tabBarStyle={{ padding: '2px 12px 0' }}
            className="ant-tabs-nav-mb-1"
          />

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                handleSearch('');
                onClear();
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                onApply(selectedOptions.map(item => (item?.value === UNASSIGNED.value ? '{}' : item?.value)));
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          Array.isArray(currentSelectedOptions) && currentSelectedOptions.length
            ? `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${currentSelectedOptions
                .map(item => (item.value === UNASSIGNED.value ? UNASSIGNED.value : item.username))
                .join(', ')}`
            : `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${t('common.all')}`
        }
        className={`btn-field field-user btn-toggle-dropdown-with-checkbox-list ${className}`}
        {...rest}
      >
        {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              :{' '}
              {currentSelectedOptions
                .map(item => (item.value === UNASSIGNED.value ? UNASSIGNED.value : item.username))
                .join(', ')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <div className="c-field-user">
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </div>
  );
};
