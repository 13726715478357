import { action, thunk } from 'easy-peasy';

import { ENDPOINTS, LS_CURRENT_PROJECT } from '../../constants';
import { Http, keycloak, handleError, reactLocalStorage } from '../../common';

export default {
  /**
   * State
   */
  globalUserInfo: null,
  loadingGlobalUserInfo: false,

  /**
   * Action: Set global user info
   */
  setGlobalUserInfo: action((state, payload) => {
    if (!(state?.globalUserInfo !== undefined)) return;

    const newData = JSON.stringify(payload);
    const oldData = JSON.stringify(state.globalUserInfo);

    if (payload && newData !== oldData) {
      state.globalUserInfo = payload;
    }
  }),

  /**
   * Action: Set loading global user info
   */
  setLoadingGlobalUserInfo: action((state, payload) => {
    if (!(state?.loadingGlobalUserInfo !== undefined)) return;

    state.loadingGlobalUserInfo = payload;
  }),

  /**
   * Action: Call api to get global user info
   */
  getGlobalUserInfo: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingGlobalUserInfo(true);

      const { token, tenantKey, projectKey, hasReload } = payload;
      const configs = { headers: {} };
      let currentUserInfo = null;
      let currentTenant = null;
      let currentMyTenantList = null;
      let hasTenant = false;
      let currentProject = null;
      let currentTemplateList = null;
      let currentMyProjectList = null;
      let currentProjectUser = null;
      let permissions = null;

      if (token) configs.headers.Authorization = `Bearer ${token}`;

      // ==========> Get user info
      const user = await keycloak?.loadUserProfile();

      // ==========> Get tenant list
      const url2 = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MY_TENANTS}`;
      const tenants = await Http.get(url2, configs).then(res => res.data);

      hasTenant = Array.isArray(tenants?.data) && tenants?.data.length > 0 && tenants?.data.some(item => item?.id);

      if (hasTenant) {
        currentMyTenantList = tenants.data;
      }

      // ==========> Get global tenant
      if (tenantKey && hasTenant) {
        // When the url has a valid tenantKey
        const found = currentMyTenantList.find(item => item.tenantKey === tenantKey);

        if (found) currentTenant = { ...found };
      } else if (!tenantKey && hasTenant && currentMyTenantList[0]?.tenantKey) {
        // For first page (location.pathname === '/')
        currentTenant = { ...currentMyTenantList[0] };
      } else if (!hasTenant) {
        // When this user has no tenant (userTenant = [])
        currentTenant = { tenantKey: 'public' };
      } else {
      }

      // ==========> Get project list by tenant Id
      if (currentTenant?.id) {
        const url3 = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MY_PROJECTS}/${currentTenant.tenantKey}`;
        const projects = await Http.get(url3, configs).then(res => res.data);
        const hasProject = Array.isArray(projects?.data) && projects?.data.length > 0;

        if (hasProject) {
          currentMyProjectList = [...projects.data];
        }

        // Remove currentProject in local storege (If not match)
        const localProject = reactLocalStorage.getObject(LS_CURRENT_PROJECT, {});

        if (hasProject && !currentMyProjectList.some(item => item.id === localProject?.id)) {
          reactLocalStorage.remove(LS_CURRENT_PROJECT);
        }

        // When the url has a valid projectKey
        const foundProject =
          projectKey && hasProject ? currentMyProjectList.find(item => item.projectKey === projectKey) : undefined;

        if (foundProject) currentProject = { ...foundProject };

        //  Get permission list
        const url5 = `${ENDPOINTS.IDENTITY}${ENDPOINTS.PERMISSION}/${currentTenant.tenantKey}/${
          currentProject?.projectKey || '-'
        }${ENDPOINTS.MY_PERMISSIONS}`;
        const permissionData = await Http.get(url5).then(res => res.data);

        permissions =
          Array.isArray(permissionData?.data) && permissionData?.data.length ? [...permissionData.data] : null;
      }

      // ==========> After go to project
      if (currentTenant?.id && currentProject?.id) {
        // Get project user list
        const res = await action.getProjectUserList({
          tenantKey: currentTenant.tenantKey,
          projectKey: currentProject.projectKey
        });
        const newProjectUserList = Array.isArray(res?.data) && res?.data.length ? [...res?.data] : [];
        currentProjectUser = newProjectUserList.find(u => u?.id === user?.id);
      }

      currentUserInfo = {
        ...user,
        currentProjectUser,
        permissions
      };

      // Set values to store
      action.setGlobalUserInfo(currentUserInfo);
      action.setGlobalTenant(currentTenant);
      action.setMyTenantList(currentMyTenantList);
      action.setGlobalProject(currentProject);
      action.setMyProjectList(currentMyProjectList);
      action.setIsGettedMyProjectList(true);
      action.setGlobalTemplateList(currentTemplateList);

      // For save currentProject to local storage
      reactLocalStorage.setObject(LS_CURRENT_PROJECT, {
        id: currentProject?.id,
        name: currentProject?.name,
        projectKey: currentProject?.projectKey
      });

      // For reload page
      if (hasReload) window.location.reload();

      return currentUserInfo;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingGlobalUserInfo(false);
    }
  }),
  /**
   * Get token expried = 1d
   */
  getToken: thunk(async (action, payload = {}) => {
    const url = '/token';
    try {
      const res = await Http.post(url, payload).then(res => res.data);
      if (res?.token) return res.token;
    } catch (err) {
      handleError(err);
    } finally {
    }
  })
};
