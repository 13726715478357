import React, { useEffect, useState } from 'react';
import { Button, Row, Modal, Spin, Divider, Tooltip } from 'antd';
import {
  SettingOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';

import { COMPONENT_TYPE, SYSTEM_FIELD_KEY } from '../../constants';
import { reactSessionStorage } from '../../common';
import { SafeInnerHtml } from '../safe-inner-html';
import { ConfigGridViewContents } from '../config-grid-view-contents';

export const ModalConfigGridView = ({
  open,
  workticketId,
  fieldList,
  fieldConfig,
  treeKey,
  sessionKey = null,
  loading = false,
  className = '',
  setOpen,
  updateFieldConfig,
  handleVisibleDetailRelation,
  onCancel,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shownFields, setShownFields] = useState(null);
  const [moreFields, setMoreFields] = useState([]);
  const [intermediaryShowFields, setIntermediaryShowFields] = useState(null);
  const [page, setPage] = useState(null);
  const [sortAttribute, setSortAttribute] = useState(null);
  const [sortOrder, setSortOrder] = useState('DESC');
  const [visibleTestSteps, setVisibleTestSteps] = useState(false);
  const [visibleDetailRelation, setVisibleDetailRelation] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Get field remaining
   */
  const getFieldRemaining = (fieldList = [], shownFields = []) => {
    const shownFieldRefNameList =
      Array.isArray(shownFields) && shownFields.length ? shownFields.map(item => item.refName) : [];

    return Array.isArray(fieldList) ? fieldList.filter(item => !shownFieldRefNameList.includes(item.refName)) : [];
  };

  /**
   * When mounted: Set field list
   */
  useEffect(() => {
    if (!(fieldConfig !== null && typeof fieldConfig === 'object' && Object.keys(fieldConfig).length)) return;

    const newFieldList = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];
    const newShownFields =
      Array.isArray(fieldConfig.defaultShow) && fieldConfig.defaultShow.length
        ? [...fieldConfig.defaultShow].map((item, index) => {
            const foundField = newFieldList.find(fi => fi.refName === item.refName);

            return {
              ...(foundField || {}),
              orderId: index + 1
            };
          })
        : [];

    setVisibleDetailRelation(fieldConfig.visibleDetailRelation);
    setPage(fieldConfig.defaultPageSize);
    setSortAttribute(fieldConfig.defaultOrder?.refName);
    setSortOrder(fieldConfig.defaultOrder?.order);
    setVisibleTestSteps(fieldConfig.visibleTestSteps);
    setShownFields(newShownFields);
  }, [open, fieldConfig]);

  /**
   * Set more fields
   * Set shown fields
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) return;

    let newShownFields = (
      intermediaryShowFields
        ? intermediaryShowFields
        : Array.isArray(fieldConfig.defaultShow) && fieldConfig.defaultShow.length
        ? [...fieldConfig.defaultShow]
        : []
    )
      .map((item, index) => {
        let foundField = fieldList.find(fi => fi.refName === item.refName);

        if (visibleDetailRelation && foundField?.componentType === COMPONENT_TYPE.RELATION) {
          if (foundField?.data?.displayField) {
            foundField = fieldList.find(f => f.refName === `${foundField.refName}.${foundField.data?.displayField}`);
          }
        }

        return {
          ...(foundField || {}),
          orderId: index + 1
        };
      })
      .filter(field => field.componentType);

    if (newShownFields.some(item => item?.refName === SYSTEM_FIELD_KEY)) {
      const oldIndex = newShownFields.findIndex(item => item?.refName === SYSTEM_FIELD_KEY);
      newShownFields = arrayMoveImmutable(newShownFields, oldIndex, 0);
    }

    const newMoreFields = getFieldRemaining(fieldList, newShownFields);

    setMoreFields(newMoreFields);
    setShownFields(newShownFields);
  }, [fieldList, visibleDetailRelation, intermediaryShowFields]);

  /**
   * Handle ok
   */
  const handleOk = async (isSetSession = false) => {
    const newShownFields = Array.isArray(shownFields) && shownFields.length ? [...shownFields] : [];
    let defaultShow = newShownFields.map(item => ({ refName: item?.refName }));

    if (!defaultShow.some(item => item?.refName === SYSTEM_FIELD_KEY)) {
      defaultShow = [{ refName: SYSTEM_FIELD_KEY }, ...defaultShow];
    }

    if (defaultShow.some(item => item?.refName === SYSTEM_FIELD_KEY) && defaultShow[0]?.refName !== SYSTEM_FIELD_KEY) {
      const oldIndex = defaultShow.findIndex(item => item?.refName === SYSTEM_FIELD_KEY);
      defaultShow = arrayMoveImmutable(defaultShow, oldIndex, 0);
    }

    let formData = {
      defaultPageSize: page,
      defaultShow,
      visibleDetailRelation,
      visibleTestSteps,
      defaultOrder: {
        refName: sortAttribute,
        order: sortOrder
      }
    };

    if (isSetSession) {
      const sessionConfig = reactSessionStorage.getObject(sessionKey, {});
      reactSessionStorage.setObject(sessionKey, {
        ...sessionConfig,
        [treeKey]: formData
      });
    }

    const res = await updateFieldConfig(formData);

    if (res) {
      setOpen(false);
      setConfirmLoading(false);
    }
  };

  /**
   * Handle cancel
   */
  const handleCancel = () => {
    onCancel();
    setOpen(false);
    setIntermediaryShowFields(null);
  };

  /**
   * On add field to list
   */
  const onAddFieldToList = item => {
    if (!item?.refName) return;

    const oldShownFields = Array.isArray(shownFields) && shownFields.length ? [...shownFields] : [];
    let newShownFields = item?.refName === SYSTEM_FIELD_KEY ? [item, ...oldShownFields] : [...oldShownFields, item];
    newShownFields.map((fi, index) => ({ ...fi, orderId: index + 1 }));

    // Set show field in drag
    setShownFields(newShownFields);
    setIntermediaryShowFields(newShownFields);

    // Set remaining field
    const newMoreFields = getFieldRemaining(fieldList, newShownFields);
    setMoreFields(newMoreFields);
  };

  /**
   * On change visible detail relation
   */
  const onChangeVisibleDetailRelation = val => {
    setVisibleDetailRelation(val);
    handleVisibleDetailRelation({ ...fieldConfig, visibleDetailRelation: val });
  };

  /**
   * On re order field
   */
  const onReOrderField = newShownFields => {
    setIntermediaryShowFields(newShownFields);
    setShownFields(newShownFields);
  };

  /**
   * On remove field to list
   */
  const onRemoveFieldToList = index => {
    const newShownFields = [...shownFields];
    newShownFields.splice(index, 1);

    setIntermediaryShowFields(newShownFields);
    setShownFields(newShownFields);
  };

  /**
   * Show apply session modal
   */
  const showApplySessionModal = () => {
    Modal.confirm({
      title: t('process.applyForYourCurrentBrowserSession'),
      content: <SafeInnerHtml html={t('process.applyForYourCurrentBrowserSessionNote')} />,
      width: 680,
      autoFocusButton: null,
      maskClosable: true,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      onOk: () => handleOk(true)
    });
  };

  /**
   * Handle check session
   */
  const handleCheckSession = () => {
    if (loading) return;

    const sessionConfig = reactSessionStorage.getObject(sessionKey, {});
    const sessionDefaultConfig = sessionConfig?.[treeKey];

    if (sessionDefaultConfig) {
      showApplySessionModal();
    } else {
      handleOk();
    }
  };

  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      width={1000}
      maskClosable={false}
      keyboard={false}
      footer={null}
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
      className={`c-config-grid-view-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
        fullScreen ? 'full-screen-modal' : ''
      } ${className}`}
      onCancel={handleCancel}
      {...rest}
    >
      <div className="px-4 pt-3">
        <Row justify="space-between" align="middle">
          <h3 className="text-primary font-weight-medium font-size-16 m-0">
            <SettingOutlined className="mr-1" />
            {t('process.configureGridViewOfFolder')}
          </h3>

          <Row align="middle" justify="end" wrap={false} className="box-extra">
            <Tooltip
              open={visibleFullScreenTooltip}
              title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
              placement={fullScreen ? 'bottomRight' : 'bottom'}
              destroyTooltipOnHide={true}
              overlayStyle={{ pointerEvents: 'none' }}
              onOpenChange={setVisibleFullScreenTooltip}
            >
              <Button
                icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                type="link"
                className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                onClick={() => {
                  setFullScreen(!fullScreen);
                  setVisibleFullScreenTooltip(false);
                }}
              />
            </Tooltip>
          </Row>
        </Row>

        <Divider className="mt-3 mb-0" />
      </div>

      <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
            <div className="text-note text-italic mb-3" style={{ marginTop: -10 }}>
              {t('process.configureGridViewOfFolderNote')}
            </div>

            <ConfigGridViewContents
              workticketId={workticketId}
              fieldList={fieldList}
              moreFields={moreFields}
              setMoreFields={setMoreFields}
              shownFields={shownFields}
              page={page}
              onChangePage={setPage}
              sortAttribute={sortAttribute}
              onChangeSortAttribute={setSortAttribute}
              sortOrder={sortOrder}
              onChangeSortOrder={setSortOrder}
              visibleTestSteps={visibleTestSteps}
              onChangeVisibleTestSteps={setVisibleTestSteps}
              visibleDetailRelation={visibleDetailRelation}
              onChangeVisibleDetailRelation={onChangeVisibleDetailRelation}
              onAddFieldToList={onAddFieldToList}
              onReOrderField={onReOrderField}
              onRemoveFieldToList={onRemoveFieldToList}
            />
          </Spin>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button type="text" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>

          <Button htmlType="submit" type="primary" onClick={handleCheckSession}>
            {t('common.ok')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfigGridView;
