import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import objectPath from 'object-path';
import { useStoreActions } from 'easy-peasy';

import {
  SYSTEM_FIELD_KEY,
  FROM_MODULE_TEST_PLAN,
  FROM_MODULE_TEST_REPO,
  FROM_MODULE_TEST_DATA,
  FROM_MODULE_WORK_TICKET,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TESTCASE_ID,
  WORK_ITEM_TEST_RESULT_ID
} from '../../../../constants';
import { useEditableCell, getPrePathLinkToTicket } from '../../../../common';
import RelationLink from '../../relation-link';

const KeyField = ({ workTicketId, fromModule, row, field, disableEditingCells, restKeyField, onReload }) => {
  const urlParams = useParams();

  // For language
  const [t] = useTranslation('akaat');

  // For hooks
  const { checkHasNewItems, showConfirmDiscardChangesModal } = useEditableCell();

  // For global store
  const setQuickEditingDrawer = useStoreActions(action => action.global.setQuickEditingDrawer);

  /**
   * Prefix path
   */
  const prefixPath = useMemo(() => {
    if (!fromModule) return;

    switch (fromModule) {
      case FROM_MODULE_TEST_PLAN:
        return getPrePathLinkToTicket({ workTicketId: WORK_ITEM_TEST_RUN_ID });

      case FROM_MODULE_TEST_REPO:
        return getPrePathLinkToTicket({
          workTicketId: WORK_ITEM_TESTCASE_ID,
          noGoToTestcaseVersion: !restKeyField?.hasGoToTestcaseVersion
        });

      case FROM_MODULE_TEST_DATA:
        return getPrePathLinkToTicket({ workTicketId: WORK_ITEM_TEST_RESULT_ID });

      case FROM_MODULE_WORK_TICKET:
        return getPrePathLinkToTicket({ workTicketId: urlParams?.refName });

      default:
        return;
    }
  }, [fromModule, urlParams]);

  /**
   * On ok discard changes
   */
  const onOkDiscardChanges = key => {
    if (typeof onReload === 'function') onReload();
    setTimeout(() => setQuickEditingDrawer({ visible: true, key }), 800);
  };

  /**
   * On before open quick editing drawer
   */
  const onBeforeOpenQuickEditingDrawer = key => {
    if (!checkHasNewItems()) {
      setQuickEditingDrawer({ visible: true, key });
      return;
    }

    showConfirmDiscardChangesModal({ onOk: () => onOkDiscardChanges(key) });
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val = objectPath.get(row, field?.refName);
    let link = `${prefixPath}${val}`;

    if (!restKeyField?.noAddQueryParamsToKeyLink) {
      link = `${prefixPath}${val}${window.location.search}`;
    }

    // For new row
    if (row?.isNew) {
      return null;
    }

    // For key field
    else if (field?.refName === SYSTEM_FIELD_KEY && disableEditingCells) {
      return (
        <Link
          to={link}
          title={val || ''}
          className="min-h-22 text-primary text-hover-dark-primary text-truncate cursor-pointer"
          onClick={e => e.stopPropagation()}
        >
          {val}
        </Link>
      );
    }

    // For key field
    else if (field?.refName === SYSTEM_FIELD_KEY && !disableEditingCells && restKeyField?.disableQuickEditing) {
      return (
        <div className="cell-text text-truncate hide-after">
          <Link
            to={link}
            title={val || ''}
            className="cell-text-value min-h-22 allow-click text-primary text-hover-dark-primary text-truncate cursor-pointer"
            onClick={e => e.stopPropagation()}
          >
            {val}
          </Link>
        </div>
      );
    }

    // For key field
    else if (field?.refName === SYSTEM_FIELD_KEY && !disableEditingCells && !restKeyField?.disableQuickEditing) {
      return (
        <div className="cell-text hide-after">
          <span
            title={val || ''}
            className="cell-text-value min-h-22 allow-click text-primary text-hover-dark-primary text-truncate cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              onBeforeOpenQuickEditingDrawer(val);
            }}
          >
            {val}
          </span>

          <Tooltip
            title={t('common.goToDetail')}
            placement="top"
            destroyTooltipOnHide={true}
            mouseEnterDelay={0.3}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <div className="btn-go-to-detail-wrapper btn-action-editable-cell-wrapper">
              <Link
                to={link}
                className="btn-go-to-detail btn-action-editable-cell allow-click ant-btn ant-btn-sm"
                onClick={e => e.stopPropagation()}
              >
                {t('common.open')}
              </Link>
            </div>
          </Tooltip>
        </div>
      );
    }

    // Relation field
    else if (disableEditingCells) {
      return (
        <RelationLink title={val || ''} row={row} field={field}>
          {val || ''}
        </RelationLink>
      );
    }

    // Relation field
    else {
      return (
        <div title={val || ''} className="cell-text text-truncate">
          <RelationLink row={row} field={field}>
            {val || ''}
          </RelationLink>
        </div>
      );
    }
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return <div className="field-wrapper key-field">{renderCellText()}</div>;
};

export default KeyField;
