import React from 'react';

import { COMPONENT_TYPE, SYSTEM_FIELD_ASSIGN_TO } from '../../../constants';
import JiraAssignToField from './jira-assign-to-field';
import JiraUserField from './jira-user-field';
import JiraStatusField from './jira-status-field';
import JiraPriorityField from './jira-priority-field';

const JiraCell = ({ workTicketId, row, field, ...rest }) => {
  switch (field?.refName) {
    case SYSTEM_FIELD_ASSIGN_TO: {
      return <JiraAssignToField row={row} field={field} {...rest} />;
    }

    default: {
      switch (field?.componentType) {
        case COMPONENT_TYPE.USER: {
          return <JiraUserField row={row} field={field} {...rest} />;
        }

        case COMPONENT_TYPE.STATUS: {
          return <JiraStatusField row={row} field={field} {...rest} />;
        }

        case COMPONENT_TYPE.PRIORITY: {
          return <JiraPriorityField row={row} field={field} {...rest} />;
        }

        default: {
          return <div style={{ visibility: 'hidden' }}>N/A</div>;
        }
      }
    }
  }
};

export default JiraCell;
