import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _debounce from 'lodash/debounce';
import { Form, Button, Input, DatePicker, Tooltip, Row, Modal, Dropdown, Empty, Select, Menu } from 'antd';
import { SaveOutlined, DownOutlined, SearchOutlined, CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';

import {
  PAGE_SIZE,
  PRIORITIES,
  URL_PATTERN,
  DEFAULT_ORDER,
  NUMBER_PATTERN,
  COMPONENT_TYPE,
  JIRA_PLATFORM_ID,
  SYSTEM_FIELD_TAG,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  ORDER_BY_KEY_DESC,
  SYSTEM_FIELD_STATUS,
  ESTIMATED_TIME_PATTERN,
  SYSTEM_FIELD_ASSIGN_TO,
  WORK_ITEM_TEST_RESULT_ID,
  SYSTEM_FIELD_LATEST_RESULT
} from '../../constants';
import {
  checkValidField,
  removeDuplicate,
  getObjectByValue,
  useUnassignedUser,
  filterOptionForUserField,
  convertUserForSubmitData,
  convertMinutesToShortTime,
  convertEstimatedTimeToMinutes
} from '../../common';
import { ArrowsRepeat, Play } from '../../assets/svg-icons';
import { UserAvatar, StatusLabel, SafeInnerHtml } from '../../components';

import './style.scss';

export const BulkChangeTickets = ({
  fieldList,
  fieldConfig,
  workTicketId,
  buttonTooltip,
  disabled,
  className = '',
  onVisibleBulkChangeDropdown,
  onSave,
  ...rest
}) => {
  const [form] = Form.useForm();

  // For language
  const [t, i18n] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For global
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const getAllSuggestion = useStoreActions(action => action.global.getAllSuggestion);
  const allSuggestion = useStoreState(state => state.global.allSuggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // For jira integration
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);
  const getWorkflowStatusList = useStoreActions(action => action.jiraIntegration.getWorkflowStatusList);
  const workflowStatusList = useStoreState(state => state.jiraIntegration.workflowStatusList);
  const getPriorityList = useStoreActions(action => action.jiraIntegration.getPriorityList);
  const jiraPriorityList = useStoreState(state => state.jiraIntegration.priorityList);
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);
  const assignableUserList = useStoreState(state => state.jiraIntegration.assignableUserList);
  const loadingAssignableUserList = useStoreState(state => state.jiraIntegration.loadingAssignableUserList);

  // Component State
  const [currentFieldList, setCurrentFieldList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Get default field list
   */
  const getDefaultFieldList = ({ fieldList, fieldConfig }) => {
    if (
      !(Array.isArray(fieldList) && fieldList.length) ||
      !(Array.isArray(fieldConfig?.defaultShow) && fieldConfig?.defaultShow.length)
    ) {
      return [];
    }

    const externalFields = fieldList.filter(f => f?.externalSystem);
    const newCurrentFieldList = [];

    fieldConfig?.defaultShow.forEach(item => {
      const foundField = fieldList.find(f => f?.refName === item?.refName && !f?.externalSystem);

      if (foundField) {
        newCurrentFieldList.push(foundField);
      }
    });

    return [...externalFields, ...newCurrentFieldList];
  };

  /**
   * Set current field list
   */
  useEffect(() => {
    if (
      !(Array.isArray(fieldList) && fieldList.length) ||
      !(Array.isArray(fieldConfig?.defaultShow) && fieldConfig?.defaultShow.length)
    ) {
      return;
    }

    setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
  }, [fieldList, fieldConfig]);

  /**
   * Handle get data for jira
   */
  const handleGetDataForJira = () => {
    const integrationSystems =
      Array.isArray(integrationSystemList) && integrationSystemList.length ? [...integrationSystemList] : [];
    const jiraExternalSystem = integrationSystems?.find(item => item?.id === JIRA_PLATFORM_ID);

    if (jiraExternalSystem) {
      getWorkflowStatusList();
      getPriorityList();
      getAssignableUserList({
        username: ''
      });
    }
  };

  /**
   * Handle open change
   */
  const handleOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setKeyword('');
      setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
      handleGetDataForJira();
    }

    if (typeof onVisibleBulkChangeDropdown === 'function') onVisibleBulkChangeDropdown(visible);
  };

  /**
   * Handle get suggestion
   */
  const handleGetSuggestion = ({ field, keyword }) => {
    if (!field?.data?.url) return;

    const newParams = {
      referenceField: field?.refName,
      url: field?.data?.url,
      filter: { ...field?.data?.filter },
      group: field?.data?.fieldValue,
      limit: PAGE_SIZE,
      page: 1,
      order: DEFAULT_ORDER
    };

    if (keyword) {
      newParams.keyword = keyword;
      newParams.filter = {
        ...newParams.filter,
        $or: []
      };

      let suggestionBy = field?.data?.suggestionBy;
      suggestionBy = Array.isArray(suggestionBy) && suggestionBy.length ? [...suggestionBy] : [];

      if (!suggestionBy.includes('name')) suggestionBy.push('name');

      suggestionBy.forEach(k => {
        newParams.filter.$or.push({
          [k]: {
            $regex: `.*${keyword}.*`,
            $options: 'i'
          }
        });
      });
    }

    if (field?.componentType === COMPONENT_TYPE.RELATION) {
      const select = [
        '_id',
        'key',
        'name',
        field?.data?.fieldLabel,
        field?.data?.fieldValue,
        field?.data?.displayField
      ];

      newParams.select = removeDuplicate(select, '').join(' ');
      newParams.order = ORDER_BY_KEY_DESC;
    }

    getAllSuggestion(newParams);
  };

  /**
   * On search suggestion
   */
  const onSearchSuggestion = useCallback(
    _debounce(({ field, keyword }) => handleGetSuggestion({ field, keyword }), 300),
    []
  );

  /**
   * On search jira users
   */
  const onSearchJiraUsers = useCallback(
    _debounce(keyword => getAssignableUserList({ username: keyword }), 300),
    []
  );

  /**
   * Get form data
   */
  const getFormData = async ({ val, field }) => {
    if (!field?.refName) return;

    const valid = await checkValidField({ form, formItemName: field?.refName });
    let newVal = null;
    let isClearValue = false;

    if (!valid) return;

    // For external system: jira
    if (field?.externalSystem === JIRA_PLATFORM_ID) {
      switch (field?.componentType) {
        case COMPONENT_TYPE.USER: {
          if (field?.mandatory && !val) return;

          let newValue = null;

          if (val === UNASSIGNED.value) {
            newValue = null;
          } else {
            const newUserList =
              Array.isArray(assignableUserList) && assignableUserList.length ? [...assignableUserList] : [];
            const user = newUserList.find(u => u?.id === val);
            newValue = user?.id ? convertUserForSubmitData(user) : null;
          }

          newVal = newValue || { username: '' };
          isClearValue = !newValue;

          break;
        }

        case COMPONENT_TYPE.STATUS: {
          if (field?.mandatory && !val) return;
          newVal = val || null;
          isClearValue = !newVal;
          break;
        }

        case COMPONENT_TYPE.PRIORITY: {
          const jiraPriorities =
            Array.isArray(jiraPriorityList) && jiraPriorityList.length ? [...jiraPriorityList] : [];

          if (field?.mandatory && !jiraPriorities.some(p => p?.value === val)) return;

          newVal = val || null;
          isClearValue = !newVal;

          break;
        }

        default: {
        }
      }
    }

    // For internal system
    else {
      switch (field?.refName) {
        case SYSTEM_FIELD_LATEST_RESULT:
        case `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`: {
          if (field?.mandatory && !val) return;
          newVal = val || null;
          isClearValue = !newVal;
          break;
        }

        case SYSTEM_FIELD_TAG: {
          if (field?.mandatory && !(Array.isArray(val) && val.length)) return;
          newVal = Array.isArray(val) && val.length ? [...val].join(',') : '';
          isClearValue = !newVal;
          break;
        }

        default: {
          switch (field?.componentType) {
            case COMPONENT_TYPE.DATE:
            case COMPONENT_TYPE.DATE_TIME: {
              if (field?.mandatory && !(val && moment(val).isValid())) return;
              const newValue = val && moment(val).isValid() ? moment(val).format() : null;
              newVal = newValue;
              isClearValue = !newValue;
              break;
            }

            case COMPONENT_TYPE.TIME_TRACKING: {
              if (field?.mandatory && !new RegExp(ESTIMATED_TIME_PATTERN).test(val)) return;
              const minutes = convertEstimatedTimeToMinutes(val);
              newVal = minutes;
              isClearValue = !minutes;
              break;
            }

            case COMPONENT_TYPE.USER: {
              if (field?.mandatory && !val) return;

              let newValue = null;

              if (val === UNASSIGNED.value) {
                newValue = {};
              } else {
                const projectUsers =
                  Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
                const user = projectUsers.find(u => u?.username === val);
                newValue = user?.username ? convertUserForSubmitData(user) : null;
              }

              newVal = newValue || null;
              isClearValue = !newVal;

              break;
            }

            case COMPONENT_TYPE.PRIORITY: {
              if (field?.mandatory && !getObjectByValue(val, PRIORITIES)) return;
              newVal = val || null;
              isClearValue = !newVal;
              break;
            }

            case COMPONENT_TYPE.OPTION: {
              const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];
              if (field?.mandatory && !getObjectByValue(val, options)) return;
              newVal = val || null;
              isClearValue = !newVal;
              break;
            }

            case COMPONENT_TYPE.PICKLIST: {
              if (field?.mandatory && !(Array.isArray(val) && val.length)) return;
              newVal = Array.isArray(val) && val.length ? [...val] : null;
              isClearValue = !newVal;
              break;
            }

            case COMPONENT_TYPE.SUGGESTION: {
              if (field?.mandatory && !val) return;

              let rows = allSuggestion?.[field?.refName]?.rows;
              rows = Array.isArray(rows) && rows.length ? [...rows] : [];
              const found = rows.find(r => r?.[field?.data?.fieldValue] === val);

              newVal = found || null;
              isClearValue = !newVal;

              break;
            }

            case COMPONENT_TYPE.RELATION: {
              if (field?.mandatory && !val) return;

              newVal = val || null;
              isClearValue = !newVal;

              break;
            }

            case COMPONENT_TYPE.URL: {
              if (field?.mandatory && !new RegExp(URL_PATTERN).test(val)) return;
              newVal = val || null;
              isClearValue = !newVal;
              break;
            }

            case COMPONENT_TYPE.NUMBER: {
              if (field?.mandatory && !new RegExp(NUMBER_PATTERN).test(val)) return;
              newVal = val || null;
              isClearValue = !newVal && !new RegExp(NUMBER_PATTERN).test(newVal);
              break;
            }

            case COMPONENT_TYPE.HTML:
            case COMPONENT_TYPE.STRING:
            case COMPONENT_TYPE.STATUS: {
              if (field?.mandatory && !val) return;
              newVal = val || null;
              isClearValue = !newVal;
              break;
            }

            default: {
            }
          }
        }
      }
    }

    const newFormData = { formData: { [field?.refName]: newVal }, field, isClearValue };

    return newFormData;
  };

  /**
   * Handle save
   */
  const handleSave = async ({ val, field }) => {
    const newData = await getFormData({ val, field });
    const isExecuted =
      field?.refName === SYSTEM_FIELD_LATEST_RESULT ||
      field?.refName === `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`;

    if (!newData?.formData && !newData?.field) return;

    setVisibleDropdown(false);

    Modal.confirm({
      title: isExecuted ? t('common.confirmExecute') : t('common.confirmSave'),
      content: newData?.isClearValue
        ? t('message.areYouSureClearValue')
        : isExecuted
        ? t('message.areYouSureExecute')
        : t('message.areYouSureSave'),
      autoFocusButton: null,
      maskClosable: true,
      okText: isExecuted ? t('testPlan.execute') : t('common.save'),
      okButtonProps: { type: 'primary', icon: isExecuted ? <Play /> : <SaveOutlined /> },
      cancelText: t('common.cancel'),
      onOk: async () => {
        await onSave(newData);
        document.querySelector('.c-bulk-change-tickets .trigger-reset-form')?.click();
      },
      onCancel: () => {}
    });
  };

  /**
   * Render apply button
   */
  const renderApplyButton = field => {
    return (
      <Button
        type="primary"
        className="ml-2"
        onClick={async e => {
          e.stopPropagation();
          handleSave({ val: form.getFieldValue(field?.refName), field });
        }}
      >
        {t('common.apply')}
      </Button>
    );
  };

  /**
   * Get sub menus
   */
  const getSubMenus = ({ field, workTicketId }) => {
    if (!field?.refName || !workTicketId) return [];

    // For external system: jira
    if (field?.externalSystem === JIRA_PLATFORM_ID) {
      switch (field?.componentType) {
        case COMPONENT_TYPE.USER: {
          const newUserList =
            Array.isArray(assignableUserList) && assignableUserList.length ? [...assignableUserList] : [];
          let options = [...newUserList].map(u => ({ label: <UserAvatar user={u} />, value: u?.id }));
          options = [UNASSIGNED, ...options];

          return [
            {
              key: `${field?.externalSystem}-sub-menus-${field?.refName}`,
              label: (
                <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                  <Form.Item
                    name={field?.refName}
                    rules={[{ required: field?.mandatory, message: t('message.required') }]}
                    className="mb-0"
                  >
                    <Select
                      options={options}
                      filterOption={false}
                      showSearch
                      allowClear={!field?.mandatory}
                      placeholder={t('common.searchEmail')}
                      className="w-200"
                      loading={loadingAssignableUserList}
                      onKeyDown={e => e?.stopPropagation()}
                      onSearch={onSearchJiraUsers}
                    />
                  </Form.Item>
                  {renderApplyButton(field)}
                </Row>
              ),
              className: 'bg-white cursor-default p-0'
            }
          ];
        }

        case COMPONENT_TYPE.STATUS: {
          const listStates =
            Array.isArray(workflowStatusList) && workflowStatusList.length ? [...workflowStatusList] : [];

          return listStates.map(s => {
            return {
              key: `${field?.externalSystem}-sub-menus-${field?.refName}-${s?.id}`,
              label: <StatusLabel status={s} />,
              title: s?.name,
              onClick: () => handleSave({ val: s?.id, field })
            };
          });
        }

        case COMPONENT_TYPE.PRIORITY: {
          const jiraPriorities =
            Array.isArray(jiraPriorityList) && jiraPriorityList.length ? [...jiraPriorityList] : [];

          return jiraPriorities.map(item => {
            return {
              key: `${field?.externalSystem}-sub-menus-${field?.refName}-${item?.value}`,
              label: item?.label,
              title: item?.label,
              icon: item?.icon,
              onClick: () => handleSave({ val: item?.value, field })
            };
          });
        }

        default: {
          return [];
        }
      }
    }

    // For internal system
    switch (field?.refName) {
      case SYSTEM_FIELD_LATEST_RESULT:
      case `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`: {
        let listStates = ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow?.listStates;
        listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

        return listStates.map(s => {
          return {
            key: `sub-menus-${field?.refName}-${s?.id}`,
            label: <StatusLabel status={s} />,
            title: s?.name,
            onClick: () => handleSave({ val: s?.id, field })
          };
        });
      }

      case SYSTEM_FIELD_TAG: {
        return [
          {
            key: `sub-menus-${field?.refName}`,
            label: (
              <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                <Form.Item
                  name={field?.refName}
                  rules={[{ required: field?.mandatory, message: t('message.required') }]}
                  className="mb-0"
                >
                  <Select
                    mode="tags"
                    options={[]}
                    optionFilterProp="label"
                    showSearch
                    allowClear={!field?.mandatory}
                    placeholder={t('common.enterValue')}
                    className="w-200"
                    onKeyDown={e => e?.stopPropagation()}
                  />
                </Form.Item>
                {renderApplyButton(field)}
              </Row>
            ),
            className: 'bg-white cursor-default p-0'
          }
        ];
      }

      default: {
        switch (field?.componentType) {
          case COMPONENT_TYPE.HTML: {
            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <Input.TextArea
                        rows={6}
                        autoComplete="off"
                        placeholder={t('common.enterValue')}
                        allowClear={!field?.mandatory}
                        style={{ width: 300 }}
                        className="textarea-resize-none"
                        onKeyDown={e => e?.stopPropagation()}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.DATE:
          case COMPONENT_TYPE.DATE_TIME: {
            const format = field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
            const showTime = field?.componentType === COMPONENT_TYPE.DATE ? false : { format: 'HH:mm' };

            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <DatePicker
                        format={format}
                        showTime={showTime}
                        placeholder={format}
                        showSearch
                        allowClear={!field?.mandatory}
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.TIME_TRACKING: {
            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[
                        { required: field?.mandatory, whitespace: field?.mandatory, message: t('message.required') },
                        { pattern: ESTIMATED_TIME_PATTERN, message: t('message.invalidFormat') }
                      ]}
                      validateTrigger={['onBlur']}
                      className="mb-0"
                    >
                      <Input
                        autoComplete="off"
                        placeholder={t('common.enterValue')}
                        allowClear={!field?.mandatory}
                        suffix={
                          <Tooltip
                            title={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
                            placement="top"
                            destroyTooltipOnHide={true}
                            overlayClassName="disabled-outside-click"
                          >
                            <InfoCircleOutlined className="text-gray ml-2" />
                          </Tooltip>
                        }
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                        onBlur={async e => {
                          await form.validateFields([field?.refName]);
                          const et = convertMinutesToShortTime(convertEstimatedTimeToMinutes(e?.target?.value));
                          form.setFieldsValue({ [field?.refName]: et || null });
                        }}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.USER: {
            const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
            let options = [...projectUsers].map(u => ({ label: <UserAvatar user={u} />, value: u?.username }));

            if (field?.refName === SYSTEM_FIELD_ASSIGN_TO) {
              options = [UNASSIGNED, ...options];
            }

            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <Select
                        options={options}
                        filterOption={filterOptionForUserField}
                        showSearch
                        allowClear={!field?.mandatory}
                        placeholder={t('common.pleaseSelect')}
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.STATUS: {
            let listStates = ticketListData?.[workTicketId]?.workFlow?.listStates;
            listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

            return listStates.map(s => {
              return {
                key: `sub-menus-${field?.refName}-${s?.id}`,
                label: <StatusLabel status={s} />,
                title: s?.name,
                onClick: () => handleSave({ val: s?.id, field })
              };
            });
          }

          case COMPONENT_TYPE.PRIORITY: {
            return PRIORITIES.map(item => {
              return {
                key: `sub-menus-${field?.refName}-${item?.value}`,
                label: item?.label,
                title: item?.label,
                icon: item?.icon,
                onClick: () => handleSave({ val: item?.value, field })
              };
            });
          }

          case COMPONENT_TYPE.OPTION: {
            const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];

            return [
              {
                key: field?.refName,
                key: `sub-menus-${field?.refName}}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <Select
                        options={options}
                        optionFilterProp="label"
                        showSearch
                        allowClear={!field?.mandatory}
                        placeholder={t('common.pleaseSelect')}
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.PICKLIST: {
            const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];

            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <Select
                        options={options}
                        mode="multiple"
                        optionFilterProp="label"
                        showSearch
                        allowClear={!field?.mandatory}
                        placeholder={t('common.pleaseSelect')}
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                      >
                        {Array.isArray(options) && options.length
                          ? options.map(item => (
                              <Select.Option key={item.value} label={item.label} value={item.value}>
                                {item.label}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.SUGGESTION:
          case COMPONENT_TYPE.RELATION: {
            let rows = allSuggestion?.[field?.refName]?.rows;
            rows = Array.isArray(rows) && rows.length ? [...rows] : [];

            const options = [...rows].map(item => {
              return {
                label: item?.[field?.data?.fieldLabel],
                value: item?.[field?.data?.fieldValue]
              };
            });

            return [
              {
                key: field?.refName,
                key: `sub-menus-${field?.refName}}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[{ required: field?.mandatory, message: t('message.required') }]}
                      className="mb-0"
                    >
                      <Select
                        options={options}
                        filterOption={false}
                        showSearch
                        allowClear={!field?.mandatory}
                        placeholder={t('common.searchName')}
                        className="w-200"
                        loading={loadingSuggestion}
                        onKeyDown={e => e?.stopPropagation()}
                        onDropdownVisibleChange={visible => {
                          if (visible) onSearchSuggestion({ field, keyword: '' });
                        }}
                        onKeyUp={e => onSearchSuggestion({ field, keyword: e?.target?.value })}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          case COMPONENT_TYPE.STRING:
          case COMPONENT_TYPE.NUMBER:
          case COMPONENT_TYPE.URL: {
            const patternRule = {};

            if (field?.componentType === COMPONENT_TYPE.NUMBER) {
              patternRule.pattern = NUMBER_PATTERN;
              patternRule.message = t('message.fieldMustBeANumber');
            } else if (field?.componentType === COMPONENT_TYPE.URL) {
              patternRule.pattern = URL_PATTERN;
              patternRule.message = t('message.invalidFormat');
            }

            return [
              {
                key: `sub-menus-${field?.refName}`,
                label: (
                  <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
                    <Form.Item
                      name={field?.refName}
                      rules={[
                        { required: field?.mandatory, whitespace: field?.mandatory, message: t('message.required') },
                        patternRule
                      ]}
                      className="mb-0"
                    >
                      <Input
                        autoComplete="off"
                        placeholder={t('common.enterValue')}
                        allowClear={!field?.mandatory}
                        className="w-200"
                        onKeyDown={e => e?.stopPropagation()}
                      />
                    </Form.Item>
                    {renderApplyButton(field)}
                  </Row>
                ),
                className: 'bg-white cursor-default p-0'
              }
            ];
          }

          default: {
            return [];
          }
        }
      }
    }
  };

  /**
   * Get menu items
   */
  const getMenuItems = () => {
    if (!(Array.isArray(currentFieldList) && currentFieldList.length) || !workTicketId) {
      return [
        {
          key: 'empty',
          label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-3" />
        }
      ];
    }

    const items = [...currentFieldList].map(field => {
      const title = field?.exactName
        ? field?.exactName
        : i18n.exists(`akaat:workItem.${field?.refName}`)
        ? t(`workItem.${field?.refName}`)
        : field?.name;

      return {
        key:
          field?.externalSystem === JIRA_PLATFORM_ID
            ? `${field?.externalSystem}-${field?.refName}`
            : `${field?.refName}`,
        label: (
          <span title={title} className="d-block text-truncate">
            {field?.externalSystem && (
              <span title={field?.externalSystem} className={`external-system-label ${field?.externalSystem} mr-1`}>
                <span className="text-truncate">{field?.externalSystem}</span>
              </span>
            )}
            {title}
          </span>
        ),
        popupClassName: 'c-bulk-change-tickets-submenu style-like-as-dropdown-menu',
        children: getSubMenus({ field, workTicketId })
      };
    });

    return items;
  };

  /**
   * On show all fields
   */
  const onShowAllFields = () => {
    const fields = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];
    const defaultFields = getDefaultFieldList({ fieldList, fieldConfig });
    const allFields = [...defaultFields];

    fields.forEach(f => {
      if (!defaultFields.some(sub => sub?.refName === f?.refName) && !f?.externalSystem) {
        allFields.push(f);
      }
    });

    setCurrentFieldList(allFields);
  };

  /**
   * On filter fields
   */
  const onFilterFields = useCallback(
    _debounce(keyword => {
      setKeyword(keyword);

      if (!keyword) {
        setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
        return;
      }

      keyword = `${typeof keyword === 'string' && keyword ? keyword : ''}`;

      const fields = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];

      const newCurrentFieldList = fields.filter(f => {
        const name = `${typeof f?.name === 'string' && f?.name ? f?.name : ''}`;
        const refName = `${typeof f?.refName === 'string' && f?.refName ? f?.refName : ''}`;

        return (
          name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
          refName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        );
      });

      setCurrentFieldList(newCurrentFieldList);
    }, 300),
    [fieldList, fieldConfig]
  );

  /**
   * Render dropdown
   */
  const renderDropdown = () => {
    return (
      <>
        <Menu
          items={[
            {
              key: 'search',
              label: (
                <Input
                  placeholder={t('common.searchField')}
                  size="small"
                  allowClear
                  suffix={<SearchOutlined />}
                  className="w-100"
                  onClick={e => e.stopPropagation()}
                  onChange={e => onFilterFields(e?.target?.value)}
                />
              ),
              className: 'bg-white cursor-default'
            }
          ]}
          selectable={false}
          className="style-like-as-dropdown-menu"
        />

        <Menu
          items={getMenuItems()}
          selectable={false}
          triggerSubMenuAction="click"
          className="style-like-as-dropdown-menu"
        />

        {Array.isArray(fieldList) &&
          fieldList.length > 0 &&
          Array.isArray(currentFieldList) &&
          currentFieldList.length > 0 &&
          currentFieldList.length < fieldList.length &&
          !keyword && (
            <Menu
              items={[
                {
                  key: 'moreField',
                  label: (
                    <>
                      {t('common.viewMoreFields', { count: fieldList.length - currentFieldList.length })}{' '}
                      <DownOutlined className="font-size-10" />
                    </>
                  ),
                  className: 'text-primary',
                  onClick: onShowAllFields
                }
              ]}
              selectable={false}
              className="style-like-as-dropdown-menu"
            />
          )}
      </>
    );
  };

  return (
    <div className={`c-bulk-change-tickets ${className}`} {...rest}>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false,
          triggerSubMenuAction: 'click',
          className: 'c-bulk-change-tickets-menu'
        }}
        dropdownRender={node => (
          <Form form={form} layout="vertical">
            {node}
          </Form>
        )}
        placement="bottomLeft"
        trigger={['click']}
        destroyPopupOnHide={true}
        overlayClassName="c-bulk-change-tickets-dropdown"
        disabled={disabled}
        onOpenChange={handleOpenChange}
      >
        <Tooltip placement="top" destroyTooltipOnHide={true} {...buttonTooltip}>
          <Button icon={<ArrowsRepeat />} disabled={disabled} className="no-animation">
            {t('common.bulkChange')} <CaretDownOutlined className="font-size-12 ml-1" />
          </Button>
        </Tooltip>
      </Dropdown>

      <div className="trigger-reset-form" onClick={() => form.resetFields()} />
    </div>
  );
};
