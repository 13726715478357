import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserCircle } from '../../assets/svg-icons';

export const useUnassignedUser = () => {
  // For language
  const [t] = useTranslation('akaat');

  // Unassigned user
  const UNASSIGNED = {
    label: (
      <span title={t('common.unassigned')} className="text-unassigned">
        <UserCircle /> {t('common.unassigned')}
      </span>
    ),
    value: t('common.unassigned')
  };

  return [UNASSIGNED];
};
