import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../common';

export default {
  /**
   * State
   */
  tenantUserList: null, // All of users in current tenant
  tenantUserQuery: null,
  loadingTenantUserList: false,
  loadingTenantUserItem: false,

  /**
   * Action: Set tenant user list
   */
  setTenantUserList: action((state, payload) => {
    if (!(state?.tenantUserList !== undefined)) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.tenantUserList = [];
    }

    state.tenantUserList = payload;
  }),

  /**
   * Action: Set tenant user query
   */
  setTenantUserQuery: action((state, payload) => {
    if (state?.tenantUserQuery === undefined) return;

    state.tenantUserQuery = payload;
  }),

  /**
   * Action: Set loading tenant user list
   */
  setLoadingTenantUserList: action((state, payload) => {
    if (!(state?.loadingTenantUserList !== undefined)) return;

    state.loadingTenantUserList = payload;
  }),

  /**
   * Action: Set loading tenant user item
   */
  setLoadingTenantUserItem: action((state, payload) => {
    if (!(state?.loadingTenantUserItem !== undefined)) return;

    state.loadingTenantUserItem = payload;
  }),

  /**
   * Action: Call api to get tenant user list
   */
  getTenantUserList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTenantUserList(true);
      action.setTenantUserQuery(payload);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${
        ENDPOINTS.USERS
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTenantUserList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserList(false);
    }
  }),

  /**
   * Action: Call api to add user to tenant
   */
  addUserToTenant: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.userId) throw new Error('Add user to tenant: No User ID');

      action.setLoadingTenantUserItem(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${ENDPOINTS.USERS}/${userId}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserItem(false);
    }
  }),

  /**
   * Action: Call api to remove user from tenant
   */
  removeUserFromTenant: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('Remove user from tenant: No User ID');

      action.setLoadingTenantUserItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${ENDPOINTS.USERS}/${payload}`;
      await Http.delete(url).then(res => res.data);

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserItem(false);
    }
  }),

  /**
   * Action: Call api to change role of a user in tenant
   */
  changeRoleOfUserInTenant: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.userId) throw new Error('Change role of user in tenant: No User ID');

      action.setLoadingTenantUserItem(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${ENDPOINTS.USERS}/${userId}/role`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserItem(false);
    }
  }),

  /**
   * Action: Call api to add user to trial tenant
   */
  addUserToTrialTenant: thunk(async (action, payload) => {
    try {
      if (!payload?.userId) throw new Error('Add user to trial tenant: No User ID');

      action.setLoadingTenantUserItem(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.TENANT}${ENDPOINTS.USERS}/${userId}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserItem(false);
    }
  }),

  /**
   * Action: Call api update product role and tenant role
   */
  updateProductRoleAndUserRole: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTenantRoleList(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${ENDPOINTS.USERS}/${userId}/product-role`;

      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantUserItem(false);
    }
  })
};
