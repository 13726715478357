import React from 'react';

const TriggerFocusElement = ({ x, y, formItemName, editingCell, isEditing, setEditingCell, className = '' }) => {
  return (
    <div
      data-form-item-name={formItemName}
      data-x={x}
      data-y={y}
      className={`trigger-focus-element ${isEditing ? 'editing' : ''} ${
        editingCell?.focusTo === formItemName ? 'focused' : ''
      } ${className}`}
      onClick={() => setEditingCell({ x, y, focusTo: formItemName })}
    />
  );
};

export default TriggerFocusElement;
