import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS, ENGINE_JOB_STATUS_CANCELLED } from '../../../../constants';
import {
  Http,
  handleError,
  removeDuplicate,
  buildQueryForParamsUrl,
  buildQueryForFilterMongo
} from '../../../../common';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  editingJob: {},
  query: {},
  jobNameList: [],
  loadingList: false,
  loadingItem: false,
  taskJob: [],
  currentDetailTask: null,
  currentAgent: {},
  jobClone: {},

  /**
   * Action: Set job list
   */
  setJobClone: action((state, payload) => {
    if (payload === undefined) return;

    state.jobClone = payload;
  }),

  /**
   * Action: Set job list
   */
  setJobList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing job
   */
  setEditingJob: action((state, payload) => {
    if (state?.editingJob === undefined) return;

    state.editingJob = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set job name list
   */
  setJobNameList: action((state, payload) => {
    if (state?.jobNameList === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.jobNameList = [];
      return;
    }

    const newJobNameList = payload.rows.map(item => {
      return {
        label: item.name,
        value: item.name
      };
    });

    state.jobNameList = removeDuplicate(newJobNameList, 'value');
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get job list
   */
  getJobList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;
      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.isAutoGetListByInterval) {
        action.setLoadingList(true);
        action.setQuery(payload);
      }

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get job list by filter
   */
  getJobListByFilter: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;
      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get job by Id
   */
  getJobById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;
      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('There are no Job Id');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingJob(res?.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create job
   */
  createJob: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('There are no Job Id');

      action.setLoadingItem(true);

      const newPayload = { ...payload };

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}?projectKey=${project.projectKey}`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      if (res) {
        notification.success({
          message: i18next.t('akaat:job.messageCreatingJob'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update job
   */
  updateJob: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      delete newPayload.fromModule;

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/${newPayload.id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:job.messageUpdatingJob'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete job
   */
  deleteJob: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:job.messageDeletingJob'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to run job
   */
  runJob: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      const { id, sessionId } = payload;
      const config = {
        headers: {
          sessionId
        }
      };
      action.setLoadingItem(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB_EXECUTE}/${id}`;
      const res = await Http.post(url, {}, config);

      return {
        res
      };
    } catch (error) {
      const status = error.response?.status;
      if (status === 409) {
        return {
          error
        };
      } else handleError(error);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to cancel job
   */
  cancelJob: thunk(async (action, payload) => {
    try {
      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const newPayload = { status: ENGINE_JOB_STATUS_CANCELLED };
      const url = `${ENDPOINTS.AUTOMATION_JOB}/${payload}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:job.messageCancellingJob'),
        description: i18next.t('akaat:job.cancelledSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Add task to job
   */
  clearTaskFromJob: action(state => {
    state.taskJob = [];
  }),

  /**
   * Action: Add task to job
   */
  pushTaskToJob: action((state, payload) => {
    if (!payload) throw new Error('Invalid Payload');
    state.taskJob.push({
      ...payload,
      index: state.taskJob.length
    });
  }),

  /**
   * Action: Remove task from job
   */
  removeTaskFromJob: action((state, payload) => {
    if (!payload) throw new Error('Invalid Payload');

    state.taskJob = state.taskJob.filter(task => task.index !== payload.index);
  }),

  /**
   * Action: Set current detail task
   */
  setCurrentDetailTask: action((state, payload) => {
    state.currentDetailTask = payload || null;
  }),

  /**
   * Action: Update task of job
   */
  editTaskOfJob: action((state, payload) => {
    if (!payload) throw new Error('Invalid Payload');
    state.taskJob = state.taskJob.map(task => {
      if (task.index === payload.index) {
        return payload;
      }
      return task;
    });
  }),

  /**
   * Action: Call api to revoke token
   */
  revokeToken: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('There are no Job Id');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/execute/${payload}/revoke`;
      const res = await Http.post(url).then(res => res.data);

      if (res) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:job.tokenSuccessfullyGenerated')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
