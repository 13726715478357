import { action, thunk } from 'easy-peasy';
import mime from 'mime-types';
import { v4 as uuidv4 } from 'uuid';

import env from '../../env';
import { ENDPOINTS, JIRA_PLATFORM_ID } from '../../constants';
import { Http, keycloak, handleError, getBase64FromBlob } from '../../common';

export default {
  /**
   * State
   */
  loadingFile: false,
  globalFullFilesInfo: {},

  /**
   * Action: Set loading preview file
   */
  setLoadingFile: action((state, payload) => {
    if (!(state?.loadingFile !== undefined)) return;

    state.loadingFile = payload;
  }),

  /**
   * Action: Set a file info to global state
   */
  setFileInfoToGlobalState: action((state, payload) => {
    if (!(state?.globalFullFilesInfo !== undefined)) return;

    if (payload?.isPublicFile) {
      state.globalFullFilesInfo[payload.src] = payload;
    } else if (payload?.id) {
      state.globalFullFilesInfo[payload.id] = payload;
    }
  }),

  /**
   * Action: Set global full files info
   */
  setGlobalFullFilesInfo: action((state, payload) => {
    if (!(state?.globalFullFilesInfo !== undefined)) return;

    state.globalFullFilesInfo = payload || {};
  }),

  /**
   * Action: Call api to get file by id
   */
  getFileById: thunk(async (action, payload) => {
    try {
      if (!payload?.fileId) throw new Error('Get file by id: No file ID');

      if (!payload?.tenantKey || !payload?.projectKey) {
        throw new Error('Get file by id: There are no tenant key or no project key');
      }

      action.setLoadingFile(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${payload?.tenantKey}/${payload?.projectKey}${ENDPOINTS.FILE}/${payload?.fileId}`;
      const config = { responseType: 'blob' };
      const res = await Http.get(url, config);

      return res;
    } catch (err) {
      return err;
    } finally {
      action.setLoadingFile(false);
    }
  }),

  /**
   * Action: Call api to delete multiple files
   */
  deleteFiles: thunk(async (action, payload) => {
    try {
      if (!(Array.isArray(payload?.ids) && payload?.ids.length)) throw new Error('Delete files: No file id list');

      if (!payload?.globalTenant?.tenantKey || !payload?.globalProject?.projectKey) {
        throw new Error('Delete files: There are no tenant key or no project key');
      }

      action.setLoadingFile(true);

      const ids = [...payload.ids];
      const tenantKey = payload?.globalTenant?.tenantKey;
      const projectKey = payload?.globalProject?.projectKey;

      for (let i = 0; i < ids.length; i++) {
        const fileId = ids[i];
        const url = `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${tenantKey}/${projectKey}${ENDPOINTS.FILE}/${fileId}`;
        await Http.delete(url);
      }

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingFile(false);
    }
  }),

  /**
   * Action: Get file base64 by file id
   */
  getFileBase64ByFileId: thunk(async (action, payload) => {
    try {
      if (!payload?.fileId) throw new Error('Get file base64 by file id: No file id');

      if (!payload?.tenantKey || !payload?.projectKey) {
        throw new Error('Get file base64 by file id: There are no tenant key or no project key');
      }

      const { tenantKey, projectKey, fileId } = payload;
      const response = await action.getFileById({ tenantKey, projectKey, fileId });

      const type = response?.data?.type;
      let base64 = null;

      if (typeof type === 'string' && type.includes('image/') && response?.status === 200) {
        base64 = await getBase64FromBlob(response?.data);
      }

      return { response, base64 };
    } catch (err) {
      handleError(err);
      return { response };
    }
  }),

  /**
   * Action: Get full file info by id
   */
  getFullFileInfoById: thunk(async (action, payload) => {
    try {
      if (!payload?.file?.id) throw new Error('Get full file info by id: No file ID');

      if (!payload?.projectKey || !payload?.tenantKey) {
        throw new Error('Get full file info by id: There are no tenant key or no project key');
      }

      const { tenantKey, projectKey, file } = payload;
      const res = await action.getFileBase64ByFileId({ tenantKey, projectKey, fileId: file.id });

      const newFileInfo = {};
      const fileName =
        file.name && typeof file.name === 'string'
          ? file.name
          : file.previewUrl && typeof file.previewUrl === 'string'
          ? file.previewUrl.substring(file.previewUrl.lastIndexOf('/') + 1)
          : '';
      const type = mime.lookup(fileName);
      const extensionInFilename = /\./.test(fileName) ? fileName.split('.').pop() : undefined;
      const src =
        typeof type === 'string' && (type.includes('video/') || type.includes('audio/'))
          ? `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${tenantKey}/${projectKey}${ENDPOINTS.FILE}/${file.id}?authorization=${keycloak?.token}`
          : res?.base64;

      newFileInfo.id = `${file.id}`;
      newFileInfo.src = src;
      newFileInfo.fileName = fileName;
      newFileInfo.type = type || extensionInFilename;
      newFileInfo.extension = extensionInFilename;
      newFileInfo.previewUrl = `/${env.REACT_APP_PREFIX_PATH}${tenantKey}/${projectKey}/files/${file.id}/${fileName}`;
      newFileInfo.createdAt = file.createdAt;
      newFileInfo.size = res?.response?.data?.size;
      newFileInfo.hasError = res?.response?.status !== 200;
      newFileInfo.fileNotFound = res?.response?.status === 404;

      return newFileInfo;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Get full files info
   * @returns {array}
   */
  getFullFilesInfo: thunk(async (action, payload, helpers) => {
    try {
      if (!(Array.isArray(payload) && payload.length)) return;

      const globalProject = helpers.getStoreState().global?.globalProject;
      const globalTenant = helpers.getStoreState().global?.globalTenant;
      const globalFullFilesInfo = helpers.getStoreState().global?.globalFullFilesInfo;
      const newAttachments = payload.filter(item => !Object.keys(globalFullFilesInfo).includes(item?.id));
      const newFullFilesInfo = [];

      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Get full files info: There are no tenant key or no project key');
      }

      if (!newAttachments.length) return;

      for (let i = 0; i < newAttachments.length; i++) {
        const file = newAttachments[i];
        let newFileInfo = {};

        // For external system: jira
        if (file?.id && file?.externalSystem === JIRA_PLATFORM_ID) {
          newFileInfo = { ...file };

          const fileName =
            typeof file?.filename === 'string' ? file?.filename.substring(file?.filename.lastIndexOf('/') + 1) : '';
          const type = mime.lookup(file?.mimeType || fileName);
          const extensionInFilename = /\./.test(fileName) ? fileName.split('.').pop() : undefined;

          newFileInfo.fileName = fileName;
          newFileInfo.type = type || extensionInFilename;
          newFileInfo.extension = extensionInFilename;
          newFileInfo.previewUrl = file?.previewUrl;
          newFileInfo.createdAt = file?.created;
          newFileInfo.size = file?.size;
        }

        // For private attachment
        else if (file?.id) {
          const res = await action.getFullFileInfoById({
            tenantKey: globalTenant?.tenantKey,
            projectKey: globalProject?.projectKey,
            file
          });

          newFileInfo = res;
        }

        // For public attachment
        else {
          const src = file;
          const fileName = typeof src === 'string' ? src.substring(src.lastIndexOf('/') + 1) : '';
          const type = mime.lookup(fileName);
          const extensionInFilename = /\./.test(fileName) ? fileName.split('.').pop() : undefined;

          newFileInfo.id = uuidv4();
          newFileInfo.isPublicFile = true;
          newFileInfo.src = src;
          newFileInfo.fileName = fileName;
          newFileInfo.type = type || extensionInFilename;
          newFileInfo.extension = extensionInFilename;
        }

        action.setFileInfoToGlobalState(newFileInfo);
        newFullFilesInfo.push(newFileInfo);
      }

      return newFullFilesInfo;
    } catch (err) {
      handleError(err);
    }
  })
};
