import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

import { StatusLabel } from '../../../status-label';

const JiraStatusFormItem = ({ field, formItemName, options, selectProps, restFormItem, restField }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Select {...selectProps} {...restField}>
        {Array.isArray(options) && options.length
          ? options.map(item => (
              <Select.Option
                key={item.value}
                label={item.label}
                value={item.value}
                status={item.status}
                disabled={item.disabled}
                className="item-status"
              >
                <StatusLabel status={item.status} />
              </Select.Option>
            ))
          : null}
      </Select>
    </Form.Item>
  );
};

export default JiraStatusFormItem;
