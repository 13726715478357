import { action, thunk } from 'easy-peasy';

import { DATA_SOURCES, ENDPOINTS, FIELD_SYSTEM, WORK_ITEM_TEST_RESULT_ID } from '../../constants';
import { Http, filterTestResultFields, handleError } from '../../common';

export default {
  /**
   * State
   */
  fieldList: {},
  loadingFieldList: false,

  /**
   * Action: Set field list for search
   */
  setFieldList: action((state, payload) => {
    if (!(state?.fieldList !== undefined)) return;

    if (!payload?.dataSource) {
      state.fieldList = {};
      return;
    }

    if (!(Array.isArray(payload.data) && payload.data.length)) {
      state.fieldList[payload.dataSource] = [];
      return;
    }

    const fieldList = [];

    payload.data.forEach(field => {
      if (field.refName)
        fieldList.push({
          ...field,
          name: field.name,
          referenceField: field.refName,
          isSystemField: field.refName.split('.')[0] === FIELD_SYSTEM ? true : false
        });
    });

    state.fieldList[payload.dataSource] = fieldList;
  }),

  /**
   * Action: Set field list for search
   */
  setFieldListManager: action((state, payload) => {
    if (!(state?.fieldList !== undefined)) return;

    if (!payload?.workTicketId) {
      state.fieldList = {};
      return;
    }

    if (!(Array.isArray(payload.data) && payload.data.length)) {
      state.fieldList[payload.workTicketId] = [];
      return;
    }

    const result = payload.data.map(field => {
      if (field?.refName) {
        return {
          ...field,
          refName: field.type !== FIELD_SYSTEM ? `${field.refName}` : field.refName,
          referenceField: field.refName
            .split('_')
            .filter((field, index) => index > 1)
            .join('_')
        };
      }

      return undefined;
    });

    state.fieldList[payload.workTicketId] = result;
  }),

  /**
   * Action: Set loading field list
   */
  setLoadingFieldList: action((state, payload) => {
    if (!(state?.loadingFieldList !== undefined)) return;

    state.loadingFieldList = payload;
  }),

  /**
   * Action: Call api to get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Get field list: Invalid data source');

      action.setLoadingFieldList(true);

      let url = '';

      if (DATA_SOURCES.ENGINE[payload]) {
        url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_COLLECTION}/field/${payload}`;

        const res = await Http.get(url).then(res => res.data);

        action.setFieldList({
          dataSource: payload,
          data: res?.data
        });
      } else {
        url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TEMPLATE}/${payload}`;

        const res = await Http.get(url).then(res => res.data);
        let newFiels = Array.isArray(res?.data?.fields) && res?.data?.fields.length ? [...res?.data?.fields] : [];

        if (payload === WORK_ITEM_TEST_RESULT_ID) {
          newFiels = filterTestResultFields(newFiels);
        }

        action.setFieldListManager({
          workTicketId: payload,
          data: newFiels
        });

        return newFiels;
      }
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingFieldList(false);
    }
  })
};
