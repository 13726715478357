import React from 'react';
import { Empty } from 'antd';

export default {
  filterTitle: 'Bộ ',
  filterConfirm: 'Đồng ý',
  filterReset: 'Tạo Lại',
  filterEmptyText: 'Bộ lọc rỗng',
  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
  selectAll: 'Chọn tất cả trên trang hiện tại',
  selectInvert: 'Chọn Ngược Lại',
  selectionAll: 'Chọn Tất Cả',
  sortTitle: 'Sắp xếp',
  expand: 'Mở rộng hàng',
  collapse: 'Thu nhỏ hàng',
  triggerDesc: 'Nhấn để sắp xếp giảm dần',
  triggerAsc: 'Nhấn để sắp xếp tăng dần',
  cancelSort: 'Nhấn để hủy sắp xếp'
};
