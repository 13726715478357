import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { SafeInnerHtml } from '../safe-inner-html';

import env from '../../env';

import './style.scss';

export const ErrorFileNotFound = () => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <div className="c-error-file-not-found">
      <Row gutter={20} align="middle">
        <Col xs={24} md={12} className="text-center">
          <div className="box-detail">
            <div className="txt-error">{t('common.fileNotFound')}</div>
            <SafeInnerHtml html={t('message.fileNotFound')} className="txt-desc" />
          </div>
        </Col>

        <Col xs={24} md={12} className="text-center">
          <img
            src={`/${env.REACT_APP_PREFIX_PATH}images/img-file-not-found.png`}
            alt="File not found"
            className="img-file-not-found w-100"
          />
        </Col>
      </Row>
    </div>
  );
};
