import React, { useEffect, useMemo, useState } from 'react';
import objectPath from 'object-path';
import { Col, Row } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { COMPONENT_TYPE } from '../../constants';
import { removeDuplicate, checkTicketTypeForRelationField } from '../../common';
import { CustomField } from '../custom-field';

const BoxCustomFields = ({
  workTicketId,
  fieldList,
  editingItem,
  form,
  uploadPath,
  isReadOnly,
  onSubmit,
  className = '',
  ...rest
}) => {
  // For ticket type store
  const getTicketTypeById = useStoreActions(action => action.global.getTicketTypeById);
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [currentFieldList, setCurrentFieldList] = useState([]);

  /**
   * Get ticket type by id
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return;
    }

    (async () => {
      if (JSON.stringify(fieldList) !== JSON.stringify(currentFieldList)) {
        setCurrentFieldList(fieldList);

        const relationFields = fieldList.filter(
          field => field?.componentType === COMPONENT_TYPE.RELATION && field?.lookup?.workTicketId
        );
        let relationWorkTicketIds = relationFields.map(field => field?.lookup?.workTicketId);
        relationWorkTicketIds = removeDuplicate(relationWorkTicketIds, '');

        for (let i = 0; i < relationWorkTicketIds.length; i++) {
          const id = relationWorkTicketIds[i];

          if (!Object.keys(ticketListData || {}).includes(id)) {
            await getTicketTypeById(id);
          }
        }
      }
    })();
  }, [fieldList, ticketListData, getTicketTypeById]);

  /**
   * Compute: Current custom fields
   */
  const currentCustomFields = useMemo(() => {
    if (
      !editingItem ||
      !(Array.isArray(fieldList) && fieldList.length) ||
      !checkTicketTypeForRelationField({ fieldList, ticketListData })
    ) {
      return;
    }

    const newCurrentCustomFields = fieldList.map(field => {
      const currentValue = objectPath.get(editingItem, field?.refName);

      return { ...field, currentValue };
    });

    return newCurrentCustomFields;
  }, [fieldList, ticketListData, editingItem]);

  return (
    <Row gutter={10} className={`c-detail-components type-custom-fields ${className}`} {...rest}>
      {Array.isArray(currentCustomFields) &&
        currentCustomFields.length > 0 &&
        currentCustomFields.map(item => {
          return (
            <Col key={item.id} xs={24} lg={12}>
              <CustomField
                field={item}
                form={form}
                uploadPath={uploadPath}
                isReadOnly={isReadOnly}
                labelCol={{ sm: 8 }}
                wrapperCol={{ sm: 16 }}
                className="label-truncate mb-0"
                onSave={onSubmit}
              />
            </Col>
          );
        })}
    </Row>
  );
};

export default BoxCustomFields;
