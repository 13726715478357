import React, { useEffect, useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import {
  JIRA_PLATFORM_ID,
  TESTMAN_PLATFORM_ID,
  INTEGRATION_FROM_REPOSITORY_FOLDER,
  INTEGRATION_FROM_TEST_PLAN_FOLDER
} from '../../constants';
import RelatedToRelationshipModal from './relatedTo-relationship-modal';
import RelatedToAddWorkTicketModal from './relatedTo-add-work-ticket-modal';
import RelatedToAddJiraModal from './relatedTo-add-jira-modal';

export const RelatedTo = ({
  fromModule,
  workItem,
  title,
  folder,
  onRelated,
  relatedMultiple = false,
  onCancel,
  ...rest
}) => {
  // For related to
  const [t] = useTranslation('akaat');

  // For global store
  const createRelation = useStoreActions(action => action.global.createRelation);
  const createRelationForRepositoryFolder = useStoreActions(action => action.global.createRelationForRepositoryFolder);
  const createRelationForTestSuiteFolder = useStoreActions(action => action.global.createRelationForTestSuiteFolder);

  // Component state
  const [visibleRelatedToRelationshipModal, setVisibleRelatedToRelationshipModal] = useState(false);
  const [visibleRelatedToAddWorkItemModal, setVisibleRelatedToAddWorkItemModal] = useState(false);
  const [visibleRelatedToAddJiraModal, setVisibleRelatedToAddJiraModal] = useState(false);
  const [relation, setRelation] = useState({});

  useEffect(() => {
    if (relatedMultiple) {
      setVisibleRelatedToRelationshipModal(true);
    }
  }, [relatedMultiple]);

  /**
   * For repository folder
   * Set visible related to relationship modal
   */
  useEffect(() => {
    if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
      setVisibleRelatedToRelationshipModal(true);
    }
  }, [fromModule]);

  /**
   * Handle next to modal
   */
  const handleNextModal = values => {
    if (values.integrationSystem === TESTMAN_PLATFORM_ID) {
      setVisibleRelatedToAddWorkItemModal(true);
    }

    if (values.integrationSystem === JIRA_PLATFORM_ID) {
      setVisibleRelatedToAddJiraModal(true);
    }
  };

  /**
   * Handle cancel
   */
  const handleCancel = () => {
    setVisibleRelatedToRelationshipModal(false);

    if (typeof onCancel === 'function') onCancel();
  };

  /**
   * Handle submit form
   */
  const onSubmit = async values => {
    if (!values?.key) return;

    const source = Array.isArray(workItem) ? workItem.map(item => item.key) : workItem?.key;
    const target = values.key;
    const formData = {
      relType: relation?.relationType,
      source,
      target
    };
    let formDataForFolder = {};

    if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
      formDataForFolder = {
        integrationSystem: values.integrationSystem,
        folderId: folder?._id,
        relType: relation?.relationType,
        target
      };

      if (values.integrationSystem === JIRA_PLATFORM_ID) {
        formDataForFolder = { ...formDataForFolder, ...values };
        delete formDataForFolder.key;
        delete formDataForFolder.target;
      }
    }

    if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER) {
      await createRelationForRepositoryFolder(formDataForFolder);
    } else if (fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
      await createRelationForTestSuiteFolder(formDataForFolder);
    } else {
      await createRelation(formData);
    }

    setVisibleRelatedToAddWorkItemModal(false);
    onRelated();
  };

  return (
    <>
      {!relatedMultiple && (
        <Button
          icon={<LinkOutlined />}
          shape="round"
          className="mr-2"
          onClick={e => {
            e.stopPropagation();
            setVisibleRelatedToRelationshipModal(true);
          }}
          {...rest}
        >
          {t('relationTo.relateTo')}
        </Button>
      )}

      {visibleRelatedToRelationshipModal && (
        <RelatedToRelationshipModal
          fromModule={fromModule}
          title={title}
          workItem={workItem}
          relation={relation}
          setRelation={setRelation}
          visible={visibleRelatedToRelationshipModal}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          onNext={val => {
            setRelation({
              ...relation,
              ...val
            });
            handleNextModal(val);
          }}
          className={`${visibleRelatedToAddWorkItemModal ? 'd-none' : 'd-inline-block'} `}
        />
      )}

      {visibleRelatedToAddWorkItemModal && (
        <RelatedToAddWorkTicketModal
          fromModule={fromModule}
          visible={visibleRelatedToAddWorkItemModal}
          relation={relation}
          onPrevious={() => setVisibleRelatedToAddWorkItemModal(false)}
          onCancel={() => {
            setVisibleRelatedToAddWorkItemModal(false);
            onRelated();
          }}
          onSubmit={onSubmit}
        />
      )}

      {visibleRelatedToAddJiraModal && (
        <RelatedToAddJiraModal
          fromModule={fromModule}
          title={title}
          relation={relation}
          workItem={workItem}
          visible={visibleRelatedToAddJiraModal}
          onPrevious={() => setVisibleRelatedToAddJiraModal(false)}
          onCancel={() => {
            setVisibleRelatedToAddJiraModal(false);
            onRelated();
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
