import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Input, Tooltip, Form, Select } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { ESTIMATED_TIME_PATTERN, NUMBER_PATTERN, SPECIAL_CHARACTERS_PATTERN, COMPONENT_TYPE } from '../../constants';
import { convertMinutesToShortTime } from '../../common';
import { SafeInnerHtml } from '../safe-inner-html';

// For string and number type
export const FieldStringNumber = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.refName || !(Array.isArray(currentValues) && currentValues.length)) return null;

    const newField = [...currentValues].find(item => item.refName === field.refName);

    if (newField && newField?.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      newField.value = convertMinutesToShortTime(newField.value);
    }

    if (
      !(
        (field.componentType === COMPONENT_TYPE.STRING && newField?.value) ||
        (field.componentType === COMPONENT_TYPE.NUMBER && new RegExp(NUMBER_PATTERN).test(newField?.value)) ||
        (field.componentType === COMPONENT_TYPE.HTML && newField?.value) ||
        (field.componentType === COMPONENT_TYPE.TIME_TRACKING &&
          (new RegExp(ESTIMATED_TIME_PATTERN).test(convertMinutesToShortTime(newField?.value)) ||
            convertMinutesToShortTime(newField?.value) === t('common.unestimated')))
      )
    ) {
      return null;
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const DropdownContent = ({ field, currentField, handleSetVisibleDropdown, onClear, onApply }) => {
    const [form] = Form.useForm();

    // Component state
    const [currentOperation, setCurrentOperation] = useState(null);
    const [currentValueField, setCurrentValueField] = useState(null);

    /**
     * Set value to form
     */
    useEffect(() => {
      if (!field?.refName) return;

      let newCurrentOperation;

      if (currentField?.operation) newCurrentOperation = currentField?.operation;
      else newCurrentOperation = field?.supportedOperations[0];

      form.setFieldsValue({
        // val: currentValueField
        //   ? /\.\*(.*)\.\*/.exec(currentValueField)?.[1]
        //     ? /\.\*(.*)\.\*/.exec(currentValueField)[1]
        //     : currentValueField
        //   : null
        val: Array.isArray(currentField?.value) ? null : currentField?.value
      });

      setCurrentValueField(currentField?.value);
      setCurrentOperation(newCurrentOperation);
    }, [form, field, currentField]);

    /**
     * On submit
     */
    const onSubmit = values => {
      if (currentOperation === 'in' || currentOperation === 'not in') {
        onApply(currentValueField, currentOperation);
      } else {
        onApply(values?.val, currentOperation);
      }

      setTimeout(() => handleSetVisibleDropdown(false), 300);
    };

    return (
      <Form
        form={form}
        className="ant-dropdown-menu c-field-string-number"
        onClick={e => e.stopPropagation()}
        onFinish={onSubmit}
      >
        {currentOperation === 'in' || currentOperation === 'not in' ? (
          <div className="operation-field-string-number mb-0" style={{ padding: '8px 12px 5px 12px' }}>
            <Select
              value={currentOperation}
              options={
                Array.isArray(field.supportedOperations) && field.supportedOperations.length
                  ? field.supportedOperations.map(sub => {
                      return {
                        label: sub,
                        value: sub
                      };
                    })
                  : []
              }
              onSelect={val => {
                setCurrentValueField([]);
                setCurrentOperation(val);
                form.setFieldsValue({ val: '' });
              }}
            />

            <Select
              style={{ width: '243px' }}
              mode="tags"
              value={currentValueField}
              options={[]}
              onChange={newValue => {
                setCurrentValueField(newValue);
              }}
              placeholder={t('common.pleaseSelect')}
              maxTagCount={2}
            />
          </div>
        ) : (
          <Form.Item
            name="val"
            rules={[
              {
                pattern: field.componentType === COMPONENT_TYPE.NUMBER ? new RegExp(NUMBER_PATTERN) : null,
                message: t('message.fieldMustBeANumber')
              },
              {
                pattern: field.componentType === COMPONENT_TYPE.STRING ? new RegExp(SPECIAL_CHARACTERS_PATTERN) : null,
                message: t('message.fieldNotContainCharacters')
              },
              {
                pattern: field.componentType === COMPONENT_TYPE.TIME_TRACKING ? ESTIMATED_TIME_PATTERN : null,
                message: t('message.invalidFormat')
              }
            ]}
            wrapperCol={{ xs: 24 }}
            style={{ padding: '8px 12px 5px 12px' }}
            className="mb-0"
          >
            <Input
              addonBefore={
                field.supportedOperations ? (
                  <Select
                    value={currentOperation}
                    options={
                      Array.isArray(field.supportedOperations) && field.supportedOperations.length
                        ? field.supportedOperations.map(sub => {
                            return {
                              label: sub,
                              value: sub
                            };
                          })
                        : []
                    }
                    dropdownMatchSelectWidth={false}
                    onSelect={val => {
                      setCurrentValueField([]);
                      setCurrentOperation(val);
                    }}
                  />
                ) : (
                  ''
                )
              }
              suffix={
                field.componentType === COMPONENT_TYPE.TIME_TRACKING ? (
                  <Tooltip
                    title={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
                    placement="top"
                    destroyTooltipOnHide={true}
                  >
                    <InfoCircleOutlined className="text-gray ml-2" />
                  </Tooltip>
                ) : null
              }
              autoFocus
              autoComplete="off"
              onPressEnter={() => form.submit()}
            />
          </Form.Item>
        )}

        <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

        <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
          <Button
            size="small"
            className="w-auto ml-2"
            onClick={() => {
              handleSetVisibleDropdown(false);
              onClear();
            }}
          >
            {t('common.clear')}
          </Button>

          <Button htmlType="submit" type="primary" size="small" className="w-auto ml-2">
            {t('common.apply')}
          </Button>
        </div>
      </Form>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    const fieldName = i18n.exists(`akaat:workItem.${field.originRefName}`)
      ? t(`workItem.${field.originRefName}`)
      : field.name;

    const textValue = currentField
      ? /\.\*(.*)\.\*/.exec(currentField?.value)?.[1]
        ? /\.\*(.*)\.\*/.exec(currentField?.value)[1]
        : currentField?.value
      : null;

    return (
      <Button
        title={`${fieldName}: ${textValue ? currentField.operation : t('common.all')} ${textValue || ''}`}
        className={`btn-field field-string-number ${className}`}
        {...rest}
      >
        {currentField ? (
          <>
            <span className="txt-label text-truncate">
              {fieldName}: {textValue ? currentField.operation : ''}{' '}
              {Array.isArray(textValue) ? textValue.join(',') : textValue}
            </span>
            <CaretDownOutlined className="ic-caret-down ml-1" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>
            <CaretDownOutlined className="ic-caret-down ml-1" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [
            {
              key: 'menu',
              label: (
                <DropdownContent
                  field={field}
                  currentField={currentField}
                  handleSetVisibleDropdown={setVisibleDropdown}
                  onClear={onClear}
                  onApply={onApply}
                />
              ),
              className: 'p-0'
            }
          ]
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ minWidth: 290 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={setVisibleDropdown}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
