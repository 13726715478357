import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE } from '../../../../constants';
import {
  useEditableCell,
  getObjectByValue,
  reactSessionStorage,
  checkIsUserInProject,
  convertUserForSubmitData,
  filterOptionForUserField,
  handleSetLastSavedValueToSession
} from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { UserAvatar } from '../../../user-avatar';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import UserKeyboardEvents from './user-keyboard-events';
import UserFormItem from './user-form-item';

const UserField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Use editable cell
  const { getLastUserOptionInSessionStorage } = useEditableCell();

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : objectPath.get(row, field?.refName);
  }, [row, field, formItemName]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject = checkIsUserInProject({ user: val, projectUserList: projectUsers });

    const currentUser = val?.username
      ? [
          {
            label: <UserAvatar user={val} inactive={!isUserInProject} />,
            value: val?.username,
            disabled: !isUserInProject,
            user: val
          }
        ]
      : [];

    const userList = [...projectUsers]
      .filter(u => u?.username !== val?.username)
      .map(u => ({ label: <UserAvatar user={u} />, value: u?.username, user: u }));

    const newOptions = [...currentUser, ...userList];

    return newOptions;
  }, [val, projectUserList]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!val?.username || !(Array.isArray(options) && options.length)) return null;

    return getObjectByValue(val.username, options);
  }, [val, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastOption = getLastUserOptionInSessionStorage({ formItemName, projectUserList });
    return lastOption !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOption(opt);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newVal = convertUserForSubmitData(opt?.user);
      handleSetLastSavedValueToSession({ [formItemName]: newVal });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: newVal }, row, field });
      }
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    options,
    filterOption: filterOptionForUserField,
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    placeholder: placeholder || t('common.search'),
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => {
      if (!row?.isNew) handleSave(option);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onChange: (val, option) => {
      if (row?.isNew) handleSave(option);
    },
    onClick: () => {
      if (!row?.isNew) handleOpen();
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const option = getCurrentOption(currentOption);
    const isUserInProject = checkIsUserInProject({ user: option?.user, projectUserList });
    const label = option?.value ? <UserAvatar user={option?.user} inactive={!isUserInProject} /> : '';

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div
          className={`cell-text cursor-text ${editingCell?.x && editingCell?.y ? '' : 'hide-after'}`}
          onClick={handleOpen}
        >
          <span className="cell-text-value min-h-22 text-truncate">{label || props?.placeholder}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink row={row} field={field}>
          {label}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {label}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return label;
    }

    // Readonly
    else {
      return (
        <div className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{label}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <UserFormItem
        field={field}
        formItemName={formItemName}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <UserKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{t('common.search')}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper user-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new user-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default UserField;
