import _debounce from 'lodash/debounce';

/**
 * Scroll to focused element
 */
const scrollToFocusedElement = _debounce(elm => {
  if (!elm?.dataset.formItemName || document.querySelector('.disable-scroll-to-focused-element')) return;
  document.querySelector(`[data-form-item-name="${elm?.dataset.formItemName}"]`)?.scrollIntoView();
}, 300);

/**
 * Go left right
 */
export const goLeftRight = async ({ e, x, y, actionType, setEditingCell }) => {
  if (isNaN(x) || isNaN(y) || !actionType) return;

  e?.stopPropagation();
  e?.preventDefault();

  const triggerRowList = document.querySelectorAll(`.trigger-focus-element[data-x="${x}"]`);

  if (!(Array.isArray(Array.from(triggerRowList)) && Array.from(triggerRowList).length)) return;

  const currentIndex = Array.from(triggerRowList).findIndex(item => +item.dataset.x === x && +item.dataset.y === y);
  const step = actionType === 'RIGHT' ? 1 : actionType === 'LEFT' ? -1 : 0;
  const newElement = triggerRowList[currentIndex + step];

  // For go to left or right
  if (newElement) {
    if (typeof setEditingCell === 'function') setEditingCell(null);
    newElement.click();
    scrollToFocusedElement(newElement);
  }

  // For go to new row
  else {
    const triggerAllRowList = document.querySelectorAll('.trigger-focus-element');
    const newTriggerAllRowList = Array.from(triggerAllRowList);

    if (!(Array.isArray(newTriggerAllRowList) && newTriggerAllRowList.length)) return;

    const currentIndex = newTriggerAllRowList.findIndex(item => +item.dataset.x === x && +item.dataset.y === y);
    const newElement = triggerAllRowList[currentIndex + step];

    if (actionType === 'RIGHT' && newElement) {
      if (typeof setEditingCell === 'function') setEditingCell(null);
      newElement?.click();
      scrollToFocusedElement(newElement);
    } else if (actionType === 'LEFT' && newElement) {
      if (typeof setEditingCell === 'function') setEditingCell(null);
      newElement?.click();
      scrollToFocusedElement(newElement);
    }
  }
};

/**
 * Go up down
 */
export const goUpDown = ({ e, x, y, actionType, setEditingCell }) => {
  if (isNaN(x) || isNaN(y) || !actionType) return;

  e?.stopPropagation();
  e?.preventDefault();

  const step = actionType === 'DOWN' ? 1 : actionType === 'UP' ? -1 : 0;
  const newElement = document.querySelector(`.trigger-focus-element[data-x="${x + step}"][data-y="${y}"]`);

  if (newElement) {
    if (typeof setEditingCell === 'function') setEditingCell(null);
    newElement.click();
  }
};

/**
 * On outside click
 */
export const onOutsideClick = ({ e, tableForm, x, y, setEditingCell }) => {
  if (
    !e ||
    !tableForm ||
    isNaN(x) ||
    isNaN(y) ||
    typeof setEditingCell !== 'function' ||
    e?.target.closest('.ant-picker-dropdown') ||
    document.querySelector('.ant-select-open') ||
    document.querySelector('.disabled-outside-click')
  ) {
    return;
  }

  // const isEditing = document.querySelector('.trigger-focus-element.editing');
  const currentElement = document.querySelector(`[data-x="${x}"][data-y="${y}"]`);
  const formItemName = currentElement?.dataset.formItemName;
  const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);

  // Exit editing when the form has any errors
  if (!e?.target?.classList.contains('cell-text')) {
    const errors = tableForm.getFieldsError();

    if (Array.isArray(errors) && errors.length && errors.some(e => Array.isArray(e?.errors) && e?.errors.length)) {
      setEditingCell(null);
    }
  }

  // When editing, click outsite (not ".cell-text") => focus
  if (
    !e?.target?.classList.contains('cell-text') &&
    triggerFocusElement?.classList.contains('editing') &&
    !triggerFocusElement?.classList.contains('focused')
  ) {
    triggerFocusElement?.classList.add('focused');
    document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
  }

  // Click to other "cell-text", close current
  else if (e?.target?.classList.contains('cell-text') && e?.target?.dataset.formItemName !== formItemName) {
    setEditingCell(null);
  }

  // Click outsite, no "cell-text", close current
  else if (
    !e?.target?.classList.contains('cell-text') &&
    document.querySelector('.trigger-focus-element.editing.focused')
  ) {
    setEditingCell(null);
    document.querySelector('.trigger-reload-table-data-helper.need-to-reload')?.click();
    document.querySelector('.trigger-set-children-data-to-parent-data.has-quick-edit')?.click();
  }
};

/**
 * Allowed character codes
 */
export const ALLOWED_CHARACTER_CODES = [
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'Backspace',
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyE',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyJ',
  'KeyK',
  'KeyL',
  'KeyM',
  'KeyN',
  'KeyO',
  'KeyP',
  'KeyQ',
  'KeyR',
  'KeyS',
  'KeyT',
  'KeyU',
  'KeyV',
  'KeyW',
  'KeyX',
  'KeyY',
  'KeyZ',
  'BracketLeft',
  'BracketRight',
  'Semicolon',
  'Quote',
  'Backslash',
  'Comma',
  'Period',
  'Slash',
  'Insert',
  'Home',
  'End',
  'Numpad1',
  'Numpad2',
  'Numpad3',
  'Numpad4',
  'Numpad5',
  'Numpad6',
  'Numpad7',
  'Numpad8',
  'Numpad9',
  'Numpad0',
  'NumpadDivide',
  'NumpadMultiply',
  'NumpadSubtract+',
  'NumpadAdd',
  'NumpadDecimal'
];

/**
 * Handle keyboard events
 */
export const handleKeyboardEvents = ({ e, x, y, formItemName, tableForm, setEditingCell, closeAndSetLastValue }) => {
  if (!e || isNaN(x) || isNaN(y) || !formItemName || !tableForm || typeof setEditingCell !== 'function') {
    return;
  }

  const code = e.code;
  const ctrl = e.ctrlKey ? e.ctrlKey : code === 'Control' ? true : false;
  const shift = e.shiftKey ? e.shiftKey : code === 'Shift' ? true : false;
  const alt = e.altKey ? e.altKey : code === 'Alt' ? true : false;
  const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
  const hasFocused = triggerFocusElement?.classList.contains('focused');
  const isEditing = triggerFocusElement?.classList.contains('editing');

  if (hasFocused || isEditing) {
    const errors = tableForm.getFieldsError();

    // Esc => Exit
    if (
      code === 'Escape' &&
      Array.isArray(errors) &&
      errors.length &&
      errors.some(e => Array.isArray(e?.errors) && e?.errors.length)
    ) {
      e.stopPropagation();
      e.preventDefault();

      setEditingCell(null);
      if (typeof closeAndSetLastValue === 'function') closeAndSetLastValue();

      return;
    }
  }

  // When editing, no focus
  if (isEditing && !hasFocused) {
    const triggerSaveElement = document.querySelector(`.trigger-save-element[data-form-item-name="${formItemName}"]`);

    // Left
    if (shift && code === 'Tab') {
      triggerSaveElement?.click();
      goLeftRight({ e, x, y, actionType: 'LEFT', setEditingCell });
    }

    // Right
    else if (code === 'Tab') {
      triggerSaveElement?.click();
      goLeftRight({ e, x, y, actionType: 'RIGHT', setEditingCell });
    }

    // Down
    else if (code === 'Enter' || code === 'NumpadEnter') {
      triggerSaveElement?.click();
      triggerFocusElement.classList.add('focused');
      document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
    }

    // Esc
    else if (code === 'Escape') {
      triggerSaveElement?.click();
      triggerFocusElement.classList.add('focused');
      document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
    }
  }

  // When focused
  else if (hasFocused) {
    // Left
    if (code === 'ArrowLeft' || (shift && code === 'Tab')) {
      goLeftRight({ e, x, y, actionType: 'LEFT', setEditingCell });
    }

    // Right
    else if (code === 'ArrowRight' || code === 'Tab') {
      goLeftRight({ e, x, y, actionType: 'RIGHT', setEditingCell });
    }

    // Up
    else if (code === 'ArrowUp') {
      goUpDown({ e, x, y, actionType: 'UP', setEditingCell });
    }

    // Down
    else if (code === 'ArrowDown') {
      goUpDown({ e, x, y, actionType: 'DOWN', setEditingCell });
    }

    // Enter
    else if (code === 'Enter' || code === 'NumpadEnter') {
      setTimeout(() => triggerFocusElement.classList.remove('focused'), 0);
    }

    // Esc
    else if (code === 'Escape' && isEditing) {
      e.stopPropagation();
      e.preventDefault();

      setEditingCell(null);
      document.querySelector('.trigger-reload-table-data-helper.need-to-reload')?.click();
      document.querySelector('.trigger-set-children-data-to-parent-data.has-quick-edit')?.click();
    }

    // Switch from focus to edit
    if (ctrl || alt || ALLOWED_CHARACTER_CODES.includes(code)) {
      e.stopPropagation();
      triggerFocusElement.classList.remove('focused');
    }
  }
};
