import React, { Children, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Tooltip, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Drag, TreeFolder } from '../../assets/svg-icons';

import './style.scss';

const MIN_WIDTH = 150;
let LeftSite = null;
let RightSite = null;

const ResizePanels = ({
  defaultLeftWidth, // px
  leftProps,
  rightProps,
  className = '',
  children,
  ...rest
}) => {
  const wrapperRef = useRef();
  const leftRef = useRef();
  const rightRef = useRef();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [leftWidth, setLeftWidth] = useState(0); // px
  const [rightWidth, setRightWidth] = useState(0); // px
  const [initialPosition, setInitialPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [leftWidthTemp, setLeftWidthTemp] = useState(null); // px
  const [collapsed, setCollapsed] = useState(false);

  /**
   * Set children
   */
  Children.forEach(children, child => {
    if (child.type === ResizePanelsLeft) LeftSite = child;
    if (child.type === ResizePanelsRight) RightSite = child;
  });

  /**
   * Set width
   */
  useEffect(() => {
    if (defaultLeftWidth <= 0) return;

    setLeftWidthTemp(defaultLeftWidth);
  }, [defaultLeftWidth]);

  /**
   * On mouse down
   * Start drag
   */
  const onMouseDown = e => {
    setInitialPosition(e.clientX);
    setIsDragging(true);
  };

  /**
   * On touch start
   * Start drag
   */
  const onTouchStart = e => {
    setInitialPosition(e.touches[0].clientX);
    setIsDragging(true);
  };

  /**
   * On mouse move
   * Dragging
   */
  const onMouseMove = e => {
    if (!isDragging) return;

    handleDrag(e.clientX);
  };

  /**
   * On touch move
   * Dragging
   */
  const onTouchMove = e => {
    if (!isDragging) return;

    handleDrag(e.touches[0].clientX);
  };

  /**
   * End drag
   */
  const onEndDrag = () => {
    if (isDragging) setIsDragging(false);
  };

  /**
   * Handle move
   */
  const handleDrag = clientX => {
    if (isDragging && leftWidth && initialPosition && wrapperRef?.current) {
      const wrapperWidth = wrapperRef.current.clientWidth;
      const newLeftWidth = leftWidth + clientX - initialPosition;
      const newRightWidth = wrapperWidth - newLeftWidth;

      setInitialPosition(clientX);

      if (newLeftWidth < MIN_WIDTH) {
        setLeftWidth(MIN_WIDTH);
        setRightWidth(wrapperWidth - MIN_WIDTH);
        return;
      }

      if (newLeftWidth > wrapperWidth - MIN_WIDTH) {
        setLeftWidth(wrapperWidth - MIN_WIDTH);
        setRightWidth(MIN_WIDTH);
        return;
      }

      setLeftWidth(newLeftWidth);
      setRightWidth(newRightWidth);
    }
  };

  /**
   * On collapse
   */
  const onCollapse = () => {
    if (!leftRef?.current || !rightRef?.current) return;

    leftRef.current.style.maxWidth = '40px';
    leftRef.current.style.flex = '40px';

    rightRef.current.style.maxWidth = 'calc(100% - 40px)';
    rightRef.current.style.flex = 'calc(100% - 40px)';

    setCollapsed(true);
  };

  /**
   * On expand
   */
  const onExpand = () => {
    if (!wrapperRef?.current) return;

    const wrapperWidth = wrapperRef.current.clientWidth;

    onSetWidth(250, wrapperWidth - 250, wrapperWidth);
    setCollapsed(false);
  };

  /**
   * On set width
   */
  const onSetWidth = (leftWidth, rightWidth, wrapperWidth) => {
    if (!leftRef?.current || !rightRef?.current) return;

    leftRef.current.style.maxWidth = `${(leftWidth / wrapperWidth) * 100}%`;
    leftRef.current.style.flex = `0 0 ${(leftWidth / wrapperWidth) * 100}%`;

    rightRef.current.style.maxWidth = `${(rightWidth / wrapperWidth) * 100}%`;
    rightRef.current.style.flex = `0 0 ${(rightWidth / wrapperWidth) * 100}%`;
  };

  /**
   * Handle key press
   */
  const handleKeyDown = e => {
    const event = e || window.event;

    const key = event.which || event.keyCode;
    const ctrl = event.ctrlKey ? event.ctrlKey : key === 17 ? true : false;

    if (ctrl && key === 219) {
      // 219 is [
      onCollapse();
    } else if (ctrl && key === 221) {
      // 221 is ]
      onExpand();
    } else {
    }
  };

  /**
   * Set width
   */
  useEffect(() => {
    if (!wrapperRef?.current || !leftRef?.current || !rightRef?.current) return;

    const wrapperWidth = wrapperRef.current.clientWidth;

    if (leftWidthTemp) {
      setLeftWidth(leftWidthTemp);
      setRightWidth(wrapperWidth - leftWidthTemp);
      setLeftWidthTemp(null);

      return;
    }

    if (!leftWidth && !rightWidth) {
      const newLeftWidth = leftRef.current.clientWidth;

      setLeftWidth(newLeftWidth);
      setRightWidth(wrapperWidth - newLeftWidth);

      return;
    }

    onSetWidth(leftWidth, rightWidth, wrapperWidth);
  }, [wrapperRef, leftRef, rightRef, leftWidth, rightWidth, leftWidthTemp]);

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('mouseup', onEndDrag);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('mouseup', onEndDrag);
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Row ref={wrapperRef} className={`c-resize-panels ${collapsed ? 'is-collapsed' : ''} ${className}`} {...rest}>
      <Col
        ref={leftRef}
        {...leftProps}
        className={`resize-panel-left pr-3 ${leftProps && leftProps.className ? leftProps.className : ''}`}
      >
        <div className="resize-panel-content">{LeftSite}</div>

        <div className="resize-panel-divider">
          {collapsed ? (
            <div className="btn-expand" onClick={onExpand}>
              <TreeFolder className="ic-tree-folder" />

              <Tooltip
                title={
                  <>
                    {t('common.expand')} <Typography.Text code>{'Ctrl + ]'}</Typography.Text>
                  </>
                }
                placement="right"
                destroyTooltipOnHide={true}
              >
                <RightOutlined className="ic-right-outlined" />
              </Tooltip>
            </div>
          ) : (
            <>
              <div
                className="resize-control"
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
                onTouchEnd={onEndDrag}
              >
                <Drag className="ic-drag" />
              </div>

              <Tooltip
                title={
                  <>
                    {t('common.collapse')} <Typography.Text code>{'Ctrl + ['}</Typography.Text>
                  </>
                }
                placement="right"
                destroyTooltipOnHide={true}
              >
                <LeftOutlined className="btn-collapse" onClick={onCollapse} />
              </Tooltip>
            </>
          )}
        </div>
      </Col>

      <Col
        ref={rightRef}
        {...rightProps}
        className={`resize-panel-right pl-3 ${rightProps && rightProps.className ? rightProps.className : ''}`}
      >
        {RightSite}
      </Col>
    </Row>
  );
};

// Multiple children in a component
const ResizePanelsLeft = ({ children }) => <>{children}</>;
const ResizePanelsRight = ({ children }) => <>{children}</>;

ResizePanels.Left = ResizePanelsLeft;
ResizePanels.Right = ResizePanelsRight;

export { ResizePanels };
