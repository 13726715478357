import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';
import moment from 'moment';
import objectPath from 'object-path';
import { Tag } from 'antd';

import {
  PAGE_SIZE,
  PRIORITIES,
  COMPONENT_TYPE,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_TAG,
  ORDER_BY_KEY_DESC
} from '../../constants';
import { getObjectByValue, removeDuplicate, convertMinutesToShortTime, convertRawHtmlToPlainText } from '../utils';
import { UserAvatar, StatusLabel } from '../../components';

export const useField = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);

  /**
   * Add keyword to filter
   */
  const addKeywordToFilter = ({ field, keyword }) => {
    let newFilter = {};

    if (keyword && Array.isArray(field?.data?.suggestionBy) && field?.data?.suggestionBy.length) {
      newFilter = {
        ...field?.data?.filter,
        $or: []
      };

      field?.data?.suggestionBy.forEach(k => {
        newFilter.$or.push({
          [k]: {
            $regex: `.*${keyword}.*`,
            $options: 'i'
          }
        });
      });
    } else {
      newFilter = { ...field?.data?.filter };
    }

    return newFilter;
  };

  /**
   * Get form data
   */
  const getSuggestionData = async ({
    field,
    page,
    keyword,
    isLoadType,
    setSuggestionData,
    suggestionData,
    setSuggestionParams,
    suggestionParams,
    suggestionKeyword
  }) => {
    if (!field?.data) return;

    switch (field?.componentType) {
      case COMPONENT_TYPE.SUGGESTION:
      case COMPONENT_TYPE.RELATION: {
        const select = ['_id', 'key', field?.data?.fieldLabel, field?.data?.displayField];
        const newParams = {
          referenceField: field?.refName,
          url: field?.data?.url,
          filter: { ...field?.data?.filter },
          group: field?.data?.fieldValue,
          limit: PAGE_SIZE,
          page: 1,
          order: ORDER_BY_KEY_DESC,
          select: removeDuplicate(select, '').join(' '),
          isLoadType
        };

        // For scroll
        if (isLoadType === 'SCROLL') {
          newParams.page = page;
          newParams.filter = addKeywordToFilter({ field, keyword: suggestionKeyword?.[field?.refName] });
        }

        // For search text
        else if (isLoadType === 'SEARCH') {
          // Reset page before search
          if (suggestionParams?.[field?.refName]?.isLoadType === 'SCROLL') {
            newParams.page = 1;
          }

          newParams.filter = addKeywordToFilter({ field, keyword });
        }

        const res = await getSuggestion(newParams);
        const newRows = Array.isArray(res?.rows) && res?.rows.length ? [...res?.rows] : [];

        // Load complete
        if (!newRows.length) {
          newParams.isLoadedAll = true;
        }

        setSuggestionParams({
          [field?.refName]: { ...suggestionParams?.[field?.refName], ...newParams }
        });

        setSuggestionData({
          [field?.refName]: {
            rows: newParams.page === 1 ? [...newRows] : [...suggestionData?.[field?.refName]?.rows, ...newRows],
            count: !isNaN(res?.count) ? res?.count : 0
          }
        });

        break;
      }

      default:
        break;
    }
  };

  /**
   * Render relation label
   */
  const getRelationLabel = ({ relationItem, fieldToDisplay, workFlow }) => {
    let plainText = '';
    let label = '';

    if (!relationItem?.[SYSTEM_FIELD_KEY]) {
      return { label, plainText };
    }

    const val = objectPath.get(relationItem, fieldToDisplay?.refName);

    switch (fieldToDisplay?.refName) {
      case SYSTEM_FIELD_TAG: {
        plainText = val || '';
        label =
          val && typeof val === 'string'
            ? val.split(',').map((item, idx) => (
                <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
                  {item}
                </Tag>
              ))
            : '';
        break;
      }

      default: {
        switch (fieldToDisplay?.componentType) {
          case COMPONENT_TYPE.HTML: {
            const newPlainText = convertRawHtmlToPlainText(val);
            plainText = newPlainText;
            label = newPlainText;
            break;
          }

          case COMPONENT_TYPE.DATE: {
            const newVal = val && moment(val).isValid() ? moment(val).format(SHORT_DATE_FORMAT) : '';
            plainText = newVal;
            label = newVal;
            break;
          }

          case COMPONENT_TYPE.DATE_TIME: {
            const newVal = val && moment(val).isValid() ? moment(val).format(FULL_DATE_FORMAT) : '';
            plainText = newVal;
            label = newVal;
            break;
          }

          case COMPONENT_TYPE.TIME_TRACKING: {
            const newVal = convertMinutesToShortTime(val) || t('common.unestimated');
            plainText = newVal;
            label = newVal;
            break;
          }

          case COMPONENT_TYPE.USER: {
            const newVal = val?.username ? <UserAvatar user={val} className="text-truncate" /> : '';
            plainText = val?.username;
            label = newVal;
            break;
          }

          case COMPONENT_TYPE.STATUS: {
            let listStates = workFlow?.listStates;
            listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];
            const foundStatus = val?.id ? listStates.find(s => s?.id === val?.id) : null;

            plainText = '';
            label = <StatusLabel status={foundStatus || workFlow?.defaultState} />;

            break;
          }

          case COMPONENT_TYPE.PRIORITY: {
            const newVal = getObjectByValue(val, PRIORITIES);

            plainText = newVal?.label;
            label = (
              <Row title={newVal?.label || ''} align="middle" wrap={false}>
                {newVal?.icon}
                <span className="text-truncate">{newVal?.label}</span>
              </Row>
            );

            break;
          }

          case COMPONENT_TYPE.OPTION: {
            if (val) {
              const options =
                Array.isArray(fieldToDisplay?.data) && fieldToDisplay?.data.length ? [...fieldToDisplay?.data] : [];
              const newVal = getObjectByValue(val, options);

              plainText = newVal?.label || '';
              label = newVal?.label || '';
            }

            break;
          }

          case COMPONENT_TYPE.PICKLIST: {
            if (Array.isArray(val) && val.length) {
              const options =
                Array.isArray(fieldToDisplay?.data) && fieldToDisplay?.data.length ? [...fieldToDisplay?.data] : [];
              const newVal = options.filter(item => val.includes(item?.value));

              plainText = newVal.map(item => item?.label).join(', ');
              label = newVal.length
                ? newVal.map((item, idx) => (
                    <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
                      {item?.label}
                    </Tag>
                  ))
                : null;
            }

            break;
          }

          case COMPONENT_TYPE.SUGGESTION: {
            plainText = val?.[fieldToDisplay?.data?.displayField] || '';
            label = val?.[fieldToDisplay?.data?.displayField] || '';

            break;
          }

          default: {
            plainText = val || '';
            label = val || '';
          }
        }
      }
    }

    return { label, plainText };
  };

  return { getSuggestionData, getRelationLabel };
};
