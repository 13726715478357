import { action, thunk } from 'easy-peasy';

import {
  DATA_SOURCES,
  DATA_SOURCE_AGENT,
  DATA_SOURCE_JOB,
  DATA_SOURCE_JOB_RUNNER,
  DATA_SOURCE_TEST_RESULT
} from '../../../../constants';
import { handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  query: {},
  loadingList: false,

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Call api to get test data list
   */
  getTestDataList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.query) throw new Error('Invalid valid');

      const { query, currentDataSource } = payload;

      action.setLoadingList(true);
      action.setQuery(query);

      switch (currentDataSource) {
        case DATA_SOURCES.ENGINE[DATA_SOURCE_TEST_RESULT]: {
          await helpers.getStoreActions()['engineTestResult'].getTestResultListByFilter(query);
          break;
        }
        case DATA_SOURCES.ENGINE[DATA_SOURCE_JOB]: {
          await helpers.getStoreActions()['scheduleJob'].getJobListByFilter(query);
          break;
        }
        case DATA_SOURCES.ENGINE[DATA_SOURCE_AGENT]: {
          await helpers.getStoreActions()['engineAgent'].getAgentListByFilter(query);
          break;
        }
        case DATA_SOURCES.ENGINE[DATA_SOURCE_JOB_RUNNER]: {
          await helpers.getStoreActions()['engineJobRunner'].getJobRunnerListByFilter(query);
          break;
        }
        default:
          break;
      }
      return;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  })
};
