import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, parseRsql, buildQueryForParamsUrl } from '../../../../common';

export const model = {
  /**
   * State
   */
  monthlyTransitionsData: {},
  jobLatestResults: [],
  jobLatestResultsQuery: {},
  loadingMonthlyTransitionsData: false,
  loadingJobLatestResults: false,

  /**
   * Action: Set monthly transitions
   */
  setMonthlyTransitionsData: action((state, payload) => {
    if (state?.monthlyTransitionsData === undefined) return;

    state.monthlyTransitionsData = payload;
  }),

  /**
   * Action: Set job latest results
   */
  setJobLatestResults: action((state, payload) => {
    if (state?.jobLatestResults === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.jobLatestResults = [];
      return;
    }

    state.jobLatestResults = payload;
  }),

  /**
   * Action: Set job latest results query
   */
  setJobLatestResultsQuery: action((state, payload) => {
    if (state?.jobLatestResultsQuery === undefined) return;

    state.jobLatestResultsQuery = payload;
  }),

  /**
   * Action: Set loading monthly transitions data
   */
  setLoadingMonthlyTransitionsData: action((state, payload) => {
    if (state?.loadingMonthlyTransitionsData === undefined) return;

    state.loadingMonthlyTransitionsData = payload;
  }),

  /**
   * Action: Set loading job latest results
   */
  setLoadingJobLatestResults: action((state, payload) => {
    if (state?.loadingJobLatestResults === undefined) return;

    state.loadingJobLatestResults = payload;
  }),

  /**
   * Action: Call api to get monthly transitions
   */
  getMonthlyTransitions: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingMonthlyTransitionsData(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.LICENSE_TENANT_TRANSACTION}/verify`;
      const res = await Http.get(url).then(res => res.data);

      action.setMonthlyTransitionsData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingMonthlyTransitionsData(false);
    }
  }),

  /**
   * Action: Call api to get job latest results
   */
  getJobLatestResults: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload.aql)) throw new Error('Invalid aql'); // aql is invalid

      action.setLoadingJobLatestResults(true);
      action.setJobLatestResultsQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB
      }/summary/job-latest-result?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobLatestResults(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingJobLatestResults(false);
    }
  })
};
