import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tooltip } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

import { BasicPreviewFile } from '../basic-preview-file';

import './style.scss';

export const PreviewFileModal = ({
  visible,
  fileInfo,
  attachments,
  onlyPreviewFileOnClient,
  className = '',
  onCancel,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For component state
  const [currentIndex, setCurrentIndex] = useState();

  /**
   * Attachment list
   */
  const attachmentList = useMemo(() => {
    return Array.isArray(attachments) && attachments.length ? [...attachments] : [];
  }, [attachments]);

  /**
   * Set current file info
   */
  useEffect(() => {
    if (!fileInfo?.id || !(Array.isArray(attachmentList) && attachmentList.length)) {
      return;
    }

    const index = attachmentList.findIndex(item => item?.id === fileInfo?.id);

    setCurrentIndex(index !== -1 ? index : undefined);
  }, [fileInfo, attachmentList]);

  /**
   * On prev next
   */
  const onPrevNext = step => {
    const newIndex = currentIndex + step;

    if (newIndex >= 0 && newIndex <= attachmentList.length - 1) {
      setCurrentIndex(newIndex);
    }
  };

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const code = e.code;

    // Left
    if (code === 'ArrowLeft') {
      onPrevNext(-1);
    }

    // Right
    else if (code === 'ArrowRight') {
      onPrevNext(1);
    }
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Modal
      open={visible}
      width="100%"
      footer={null}
      forceRender
      centered
      wrapClassName="modal-fixed-header"
      className={`c-preview-file-modal ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      {/^[0-9]*$/.test(currentIndex) && currentIndex >= 0 && (
        <>
          <button type="button" disabled={currentIndex <= 0} className="btn-prev" onClick={() => onPrevNext(-1)}>
            <Tooltip
              placement="top"
              title={currentIndex <= 0 ? null : <span className="text-nowrap">{t('common.previous')}</span>}
              destroyTooltipOnHide={true}
            >
              <CaretLeftOutlined />
            </Tooltip>
          </button>

          <button
            type="button"
            disabled={currentIndex >= attachmentList.length - 1}
            className="btn-next"
            onClick={() => onPrevNext(1)}
          >
            <Tooltip
              placement="top"
              title={
                currentIndex >= attachmentList.length - 1 ? null : (
                  <span className="text-nowrap">{t('common.next')}</span>
                )
              }
              destroyTooltipOnHide={true}
            >
              <CaretRightOutlined />
            </Tooltip>
          </button>
        </>
      )}

      <BasicPreviewFile
        fileInfo={attachmentList[currentIndex]}
        onlyPreviewFileOnClient={onlyPreviewFileOnClient}
        showCloseButton={true}
        onClose={onCancel}
      />
    </Modal>
  );
};
