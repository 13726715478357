import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Button } from 'antd';
import objectPath from 'object-path';

import { JIRA_PLATFORM_ID, SYSTEM_FIELD_STATUS } from '../../constants';
import { UpdateTicketStatusModal } from '../update-ticket-status-modal';

import './style.scss';

export const StepButtonsForWorkflow = ({
  workTicketId,
  editingItem,
  loading,
  className = '',
  isReadOnly = false,
  uploadPath,
  onEditorAttachFiles,
  onSubmit,
  ...rest
}) => {
  // For workfow store
  const workflowByWorkItem = useStoreState(state => state.workflow.workflowByWorkItem);

  // For jira integration
  const workflowTransitionList = useStoreState(state => state.jiraIntegration.workflowTransitionList);

  // Component state
  const [stepsForCurrentStatus, setStepsForCurrentStatus] = useState([]);
  const [workflow, setWorkflow] = useState([]);
  const [visibleUpdateTicketStatusModal, onSetVisibleUpdateTicketStatusModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  /**
   * Set workflow
   */
  useEffect(() => {
    if (!workflowByWorkItem || !workTicketId) return;

    setWorkflow(workflowByWorkItem[workTicketId]);
  }, [workflowByWorkItem, workTicketId]);

  /**
   * Set steps for current status
   */
  useEffect(() => {
    switch (editingItem?.externalSystem) {
      case JIRA_PLATFORM_ID: {
        const steps = workflowTransitionList.map(item => {
          return {
            from: item.id,
            name: item.name,
            to: item.to.id,
            toStatus: item.to,
            systemType: JIRA_PLATFORM_ID
          };
        });
        setStepsForCurrentStatus(steps);

        break;
      }

      default: {
        if (
          !editingItem?.[SYSTEM_FIELD_STATUS] ||
          !(Array.isArray(workflow?.listSteps) && workflow?.listSteps.length)
        ) {
          return;
        }

        const steps = [...workflow.listSteps].filter(
          item => item.from === objectPath.get(editingItem, `${SYSTEM_FIELD_STATUS}.id`)
        );

        setStepsForCurrentStatus(steps);
        break;
      }
    }
  }, [workflow, editingItem, workflowTransitionList]);

  /**
   * On open change status modal
   */
  const onOpenUpdateTicketStatusModal = step => {
    setCurrentStep(step);
    onSetVisibleUpdateTicketStatusModal(true);
  };

  /**
   * On close change status modal
   */
  const onCloseUpdateTicketStatusModal = () => {
    setCurrentStep(null);
    onSetVisibleUpdateTicketStatusModal(false);
  };

  /**
   * On change status
   */
  const onChangeStatus = formData => {
    if (!formData) return;

    const newStatus = workflow?.listStates.find(state => state.id === currentStep?.to);
    const newFormData = {
      ...formData,
      [SYSTEM_FIELD_STATUS]: newStatus?.id
    };

    setTimeout(() => onCloseUpdateTicketStatusModal(), 300);
    onSubmit(newFormData);
  };

  return (
    <>
      <div className={`c-steps-buttons-for-workflow ${className}`} {...rest}>
        {Array.isArray(stepsForCurrentStatus) &&
          stepsForCurrentStatus.length > 0 &&
          editingItem?.externalSystem !== JIRA_PLATFORM_ID &&
          stepsForCurrentStatus.map((item, index) => (
            <Button
              key={index}
              id={`to-status-${item?.to}`}
              shape="round"
              className={index === 0 ? 'ml-2' : 'ml-1'}
              disabled={isReadOnly}
              onClick={() => {
                switch (editingItem?.externalSystem) {
                  case JIRA_PLATFORM_ID:
                    break;

                  default:
                    onOpenUpdateTicketStatusModal(item);
                    break;
                }
              }}
            >
              {item.name}
            </Button>
          ))}
      </div>

      {visibleUpdateTicketStatusModal && (
        <UpdateTicketStatusModal
          visible={visibleUpdateTicketStatusModal}
          workflow={workflow}
          currentStep={currentStep}
          editingItem={editingItem}
          uploadPath={uploadPath}
          loading={loading}
          onChangeStatus={onChangeStatus}
          onEditorAttachFiles={onEditorAttachFiles}
          onCancel={onCloseUpdateTicketStatusModal}
        />
      )}
    </>
  );
};
