import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import { ENDPOINTS } from '../../../../constants';
import { Http, buildQuery, handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  query: {},
  editingConnection: {},
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set connection list
   */
  setConnectionList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing connection
   */
  setEditingConnection: action((state, payload) => {
    if (state?.editingConnection === undefined) return;

    state.editingConnection = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get connection list
   */
  getConnectionList: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.INTEGRATION}?q=${buildQuery(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setConnectionList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get connection by Id
   */
  getConnectionById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/integration/${payload}`;
      const res = await Http.post(url, payload).then(res => res.data);

      action.setEditingConnection(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create connection
   */
  createConnection: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AZURE}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:azureIntegration.messageCreatingConnection'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update connection
   */
  updateConnection: thunk(async (action, payload) => {
    try {
      if (!payload?.id) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { id } = newPayload;
      delete newPayload.id;

      const url = `${ENDPOINTS.INTEGRATION}/${id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:azureIntegration.messageUpdatingConnection'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete connection
   */
  deleteConnection: thunk(async (action, payload) => {
    try {
      if (!payload) throw new Error('Invalid Payload');

      const url = `${ENDPOINTS.INTEGRATION}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:azureIntegration.messageDeletingConnection'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  })
};
