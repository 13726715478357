/**
 * Roles
 */

// production
export const ROLE_APP_ACCESS_TESTENGINE = 'app.access_testengine'; // For check
export const ROLE_APP_ACCESS_MANAGER = 'app.access_manager'; // For check
export const ROLE_TENANT_TRIAL_TESTENGINE = 'role.tenant.trial.testengine'; // Add to trial tenant
export const ROLE_TENANT_TRIAL_MANAGER = 'role.tenant.trial.manager'; // Add to trial tenant
export const ROLE_TENANT_USER_TESTENGINE = 'role.tenant.user.testengine'; // Add to specific tenant
export const ROLE_TENANT_USER_MANAGER = 'role.tenant.user.manager'; // Add to specific tenant
export const ROLE_TENANT_USER_STUDIO = 'role.tenant.user.studio'; // Add to specific tenant

// tenant
export const ROLE_TENANT_OWNER = 'role.tenant.owner';
export const ROLE_TENANT_ADMIN = 'role.tenant.admin';
export const ROLE_TENANT_TRIAL = 'role.tenant.trial';
export const ROLE_TENANT_USER = 'role.tenant.user';

// project
export const ROLE_PROJECT_OWNER = 'role.project.owner';
export const ROLE_PROJECT_MANAGER = 'role.project.manager';
export const ROLE_PROJECT_TEST_LEADER = 'role.project.test_leader';
export const ROLE_PROJECT_TESTER = 'role.project.tester';
export const ROLE_PROJECT_STACK_HOLDER = 'role.project.stack_holder';
