import React from 'react';
import { FrownOutlined } from '@ant-design/icons';

import './style.scss';

export const BasicError = ({ messageText = '', codeText = 'ERROR', bottomButton, className = '', ...rest }) => {
  return (
    <div className={`c-basic-error ${className}`} {...rest}>
      {messageText && (
        <div className="txt-title">
          <div className="text">{messageText}</div>
          <div className="line">
            <FrownOutlined />
          </div>
        </div>
      )}

      <div className="txt-code">{codeText}</div>

      {bottomButton && <div className="box-btn">{bottomButton}</div>}
    </div>
  );
};
