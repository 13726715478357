import { Col, Row, Input, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const [t] = useTranslation('akaat');

  return (
    <Row gutter={10}>
      <Input.Group compact>
        <Col span={8}>
          <Form.Item name={['item', 'text']}>
            <Input placeholder={t('common.textToDisplay')}></Input>
          </Form.Item>
        </Col>

        <Col span={16} style={{ width: '100%' }}>
          <Form.Item rules={[{ required: true, message: t('message.required') }]} name={['item', 'url']}>
            <Input placeholder={t('common.url')}></Input>
          </Form.Item>
        </Col>
      </Input.Group>
    </Row>
  );
};
