import { jsonParse } from './utils';

/**
 * React local storage util
 */
export const reactLocalStorage = {
  set(key, value) {
    localStorage[key] = value;
    return localStorage[key];
  },

  get(key) {
    return localStorage[key];
  },

  setObject(key, value) {
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },

  getObject(key, defaultValue) {
    return jsonParse(localStorage[key] || JSON.stringify(defaultValue));
  },

  remove(key) {
    return localStorage.removeItem(key);
  },

  clear() {
    return localStorage.clear();
  }
};
