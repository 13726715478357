import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button } from 'antd';
import { HomeOutlined, LogoutOutlined } from '@ant-design/icons';

import env from '../../env';
import { useKeycloak } from '../../common';
import { BasicError } from '../basic-error';

export const Error403 = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For keycloak
  const { keycloak } = useKeycloak();

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  return (
    <div className="c-error-403">
      <BasicError
        messageText={t('message.youDoNotHavePermissionToAccess')}
        codeText="403 ERROR"
        bottomButton={
          <>
            <Button size="large" href={`/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey || ''}`}>
              <HomeOutlined /> {t('common.goBackHome')}
            </Button>

            <Button size="large" onClick={() => keycloak?.logout()}>
              <LogoutOutlined /> {t('header.logout')}
            </Button>
          </>
        }
      />
    </div>
  );
};
