import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
import i18next from 'i18next';
import objectPath from 'object-path';
import { Form, Button, Tooltip, Row, Col, Input, Popconfirm, Select } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

import {
  PRIORITIES,
  COMPONENT_TYPE,
  PRIORITY_MEDIUM,
  SYSTEM_FIELD_PRIORITY,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_TEST_CONFIG,
  TEST_PLAN_TREE_TYPE_CYCLE,
  TEST_PLAN_TREE_TYPE_SUITE,
  SYSTEM_FIELD_LATEST_RESULT,
  TEST_PLAN_TREE_TYPE_RELEASE
} from '../../constants';
import { useFile, useEditableCell, reactLocalStorage } from '../../common';
import { PlusOutlined } from '../../assets/svg-icons';
import { SafeInnerHtml, WorkItemField } from '../../components';

import './style.scss';

export const AttributeList = ({
  fromModule,
  workTicketId,
  form,
  isSetDefaultFromParent,
  isReadOnly,
  className = '',
  onChangeField,
  leftColumnProp,
  rightColumnProp,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  //Global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For upload
  const { getUploadPath } = useFile();

  // For test run hook
  const { handleGetFieldValue } = useEditableCell();

  // For global store
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const projectUserList = useStoreState(state => state.global.projectUserList);

  // For test plan tree store
  const currentNode = useStoreState(state => state.testPlanTree.currentNode);

  // For test plan release store
  const editingRelease = useStoreState(state => state.testPlanRelease.editingRelease);

  // For test plan cycle store
  const editingCycle = useStoreState(state => state.testPlanCycle.editingCycle);

  // For test plan test suite store
  const editingSuite = useStoreState(state => state.testPlanSuite.editingSuite);

  // Component state
  const [editingItem, setEditingItem] = useState(null);
  const [attributeList, setAttributeList] = useState([]);

  /**
   * Compute: Available insert fields
   */
  const availableInsertFields = useMemo(() => {
    let fields = ticketListData?.[workTicketId]?.fields;

    if (!(Array.isArray(fields) && fields.length)) return [];

    fields = fields
      .filter(item => {
        return (
          item?.refName &&
          item?.isInsert &&
          item?.refName !== SYSTEM_FIELD_LATEST_RESULT &&
          item?.refName !== SYSTEM_FIELD_TEST_CONFIG &&
          item?.componentType !== COMPONENT_TYPE.TEST_STEPS &&
          item?.componentType !== COMPONENT_TYPE.STATUS &&
          item?.componentType !== COMPONENT_TYPE.RELATION
        );
      })
      .map(item => {
        const newItem = { ...item };

        if (item.componentType === COMPONENT_TYPE.HTML) {
          newItem.keepRawHtml = true;
        }

        return newItem;
      });

    return fields;
  }, [ticketListData, workTicketId]);

  /**
   * Set editing release to form
   */
  useEffect(() => {
    if (!(Array.isArray(availableInsertFields) && availableInsertFields.length)) return;

    let newEditingItem = {};
    let attributes = {};

    // For add new (add new cycle/test-suite)
    if (isSetDefaultFromParent) {
      attributes = { ...(currentNode?.attributes || {}) };
    }

    // Release
    else if (currentNode?.treeType === TEST_PLAN_TREE_TYPE_RELEASE) {
      newEditingItem = { ...(editingRelease || {}) };
      attributes = { ...(editingRelease?.attributes || {}) };
    }

    // Cycle
    else if (currentNode?.treeType === TEST_PLAN_TREE_TYPE_CYCLE) {
      newEditingItem = { ...(editingCycle || {}) };
      attributes = { ...(editingCycle?.attributes || {}) };
    }

    // Test suite
    else if (currentNode?.treeType === TEST_PLAN_TREE_TYPE_SUITE) {
      newEditingItem = { ...(editingSuite || {}) };
      attributes = { ...(editingSuite?.attributes || {}) };
    }

    delete attributes.testConfig;
    delete attributes.build;

    if (Object.keys(attributes).length) {
      const newAttributeList = [];
      const secondAttributes = {};

      // For handleGetFieldValue
      // Split properties of custom fields
      // Example: Before {​properties.f68268: 'abc'}​
      //          After {properties: {f68268: 'abc'}}
      Object.keys(attributes).forEach(refName => {
        if (/^properties\./.test(refName)) {
          objectPath.set(secondAttributes, refName, attributes[refName]);
        } else {
          secondAttributes[refName] = attributes[refName];
        }
      });

      Object.keys(attributes).forEach(refName => {
        const uuid = uuidv4();
        const field = availableInsertFields.find(f => f.refName === refName);
        const value = handleGetFieldValue({ field, item: secondAttributes, ticketListData });

        form.setFieldsValue({
          [`attribute-key-${uuid}`]: refName,
          [`attribute-value-${uuid}`]: value
        });

        newAttributeList.push({ uuid, value, field });
      });

      setAttributeList(newAttributeList);
    }

    if (fromModule === 'IMPORT_FILE_MODAL') {
      // case import
      const localStorageAttribute = reactLocalStorage.get('newAttributeList');

      if (localStorageAttribute) {
        const newAttributeList = [];
        const localStorage = JSON.parse(localStorageAttribute);
        const typeTest = localStorage.find(
          storage =>
            storage?.type === workTicketId &&
            storage?.tenantKey === globalTenant.tenantKey &&
            storage?.projectKey === globalProject.projectKey
        );

        if (typeTest) {
          typeTest.columns.forEach(column => {
            const uuid = uuidv4();
            const field = availableInsertFields.find(f => f?.refName === column?.key);
            const value = column?.value;

            form.setFieldsValue({
              [`attribute-key-${uuid}`]: column?.key,
              [`attribute-value-${uuid}`]: value
            });

            newAttributeList.push({ uuid, value, field });
            setAttributeList(newAttributeList);
          });
        }
      }
    }

    setEditingItem(newEditingItem);
  }, [
    form,
    fromModule,
    workTicketId,
    ticketListData,
    availableInsertFields,
    isSetDefaultFromParent,
    currentNode,
    editingRelease,
    editingCycle,
    editingSuite
  ]);

  /**
   * Get rest field
   */
  const getRestField = item => {
    if (!item?.field?.refName) return;

    const props = {};

    if (item.field.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      props.suffix = (
        <Tooltip
          placement="top"
          title={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
          destroyTooltipOnHide={true}
        >
          <InfoCircleOutlined className="text-gray" />
        </Tooltip>
      );
    }

    if (item.field.componentType === COMPONENT_TYPE.HTML) {
      props.rawHtml = item.value;
    }

    return props;
  };

  /**
   * On change field key
   */
  const onChangeFieldKey = (uuid, field) => {
    if (!uuid) return;

    let newAttributeList = Array.isArray(attributeList) && attributeList.length ? [...attributeList] : [];
    const index = newAttributeList.findIndex(a => a.uuid === uuid);

    if (index === -1) return;

    newAttributeList[index].field = field;

    setAttributeList(newAttributeList);
    form.resetFields([`attribute-value-${uuid}`]);

    if (typeof onChangeField === 'function') onChangeField();
  };

  const setLocalStorage = (item, data) => {
    if (fromModule !== 'IMPORT_FILE_MODAL') return; // not case import

    const { field, uuid } = item;
    let value = data;

    //prepare data
    switch (field.refName) {
      case SYSTEM_FIELD_TEST_STEPS: {
        value = data.map(testStep => {
          return {
            step: { value: testStep?.step },
            expectedResult: { value: testStep?.expectedResult },
            testData: { value: testStep?.testData },
            orderId: { value: testStep?.orderId }
          };
        });
        break;
      }

      case SYSTEM_FIELD_PRIORITY: {
        const priority = PRIORITIES.find(p => p.value == data.toString());
        value = priority ? priority.label : PRIORITY_MEDIUM;
        break;
      }

      case SYSTEM_FIELD_ASSIGN_TO: {
        if (Array.isArray(projectUserList) && projectUserList.length) {
          const user = projectUserList.find(
            userInfo => userInfo?.username?.toUpperCase() === data.toString().toUpperCase()
          );

          if (user) value = { value: data.toString() };
        }

        break;
      }

      default: {
        const valueField = form.getFieldValue(`attribute-value-${uuid}`);
        if (valueField) value = valueField;
        break;
      }
    }
    //
    const localStorageAttribute = reactLocalStorage.get('newAttributeList');
    if (!localStorageAttribute) {
      const typeTest = {
        tenantKey: globalTenant.tenantKey,
        projectKey: globalProject.projectKey,
        type: workTicketId,
        columns: [
          {
            key: field.refName,
            name: field.name.toLowerCase().replaceAll(' ', ''),
            value: value
          }
        ]
      };
      const attributeData = [typeTest];
      reactLocalStorage.set('newAttributeList', JSON.stringify(attributeData));
    } else {
      const localStorage = JSON.parse(localStorageAttribute);
      const typeTest = localStorage.filter(
        storage =>
          storage?.type === workTicketId &&
          storage?.tenantKey === globalTenant.tenantKey &&
          storage?.projectKey === globalProject.projectKey
      );
      if (!typeTest.length > 0) {
        const typeTest = {
          tenantKey: globalTenant.tenantKey,
          projectKey: globalProject.projectKey,
          type: workTicketId,
          columns: [
            {
              key: field.refName,
              name: field.name.toLowerCase().replaceAll(' ', ''),
              value: value
            }
          ]
        };
        const attributeData = typeTest;
        localStorage.push(attributeData);
        reactLocalStorage.set('newAttributeList', JSON.stringify(localStorage));
      }
      const column = typeTest[0]?.columns?.filter(cl => cl.key === field?.refName);
      if (column?.length > 0) {
        column[0].value = value;
      } else {
        typeTest[0].columns.push({
          key: field.refName,
          name: field.name.toLowerCase().replaceAll(' ', ''),
          value: value
        });
      }
      reactLocalStorage.set('newAttributeList', JSON.stringify(localStorage));
    }
  };

  /**
   * On remove row from attributes
   */
  const onRemoveRowFromAttributes = item => {
    if (!item?.uuid) return;

    if (fromModule === 'IMPORT_FILE_MODAL') {
      // case import
      const localStorageAttribute = reactLocalStorage.get('newAttributeList');
      if (localStorageAttribute) {
        const localStorage = JSON.parse(localStorageAttribute);
        const typeTest = localStorage.find(
          storage =>
            storage?.type === workTicketId &&
            storage?.tenantKey === globalTenant.tenantKey &&
            storage?.projectKey === globalProject.projectKey
        );
        const column = typeTest.columns.filter(cl => cl.key !== item?.field?.refName);
        typeTest.columns = column;
        reactLocalStorage.set('newAttributeList', JSON.stringify(localStorage));
      }
    }

    const newAttributeList = [...attributeList].filter(sub => sub.uuid !== item.uuid);

    setAttributeList(newAttributeList);

    form.resetFields([`attribute-key-${item.uuid}`, `attribute-value-${item.uuid}`]);

    if (typeof onChangeField === 'function') onChangeField();
  };

  /**
   * Render field
   */
  const renderField = item => {
    if (!item?.field?.refName) return <Input readOnly />;

    return (
      <div
        className="box-field position-relative"
        onClick={() => {
          const el = document.getElementById(`visual-field-${item.uuid}`);
          if (el) setTimeout(() => el.remove(), 200);
        }}
      >
        {item.field.componentType === COMPONENT_TYPE.RELATION && item.value !== undefined && (
          <Input
            value={editingItem?.attributeLabels?.[item.field.refName]?.label || item.value}
            id={`visual-field-${item.uuid}`}
            readOnly={isReadOnly}
            className="ant-input visual-field position-absolute"
          />
        )}

        <WorkItemField
          name={`attribute-value-${item.uuid}`}
          form={form}
          field={item.field}
          workTicketId={workTicketId}
          initialValue={undefined}
          restField={getRestField(item)}
          wrapperCol={{ xs: 24 }}
          uploadPath={getUploadPath(workTicketId)}
          isReadOnly={isReadOnly}
          onChange={info => {
            setLocalStorage(item, info);
            if (typeof onChangeField === 'function') onChangeField(info);
          }}
        />
      </div>
    );
  };

  /**
   * Render row
   */
  const renderRow = (item, rowIndex) => {
    if (!item?.uuid) return null;

    const selectedFieldList = Array.isArray(attributeList) && attributeList.length ? [...attributeList] : [];
    const selectedFieldRefNameList = selectedFieldList.filter(a => a.field?.refName).map(a => a.field?.refName);
    const otherSelectedFieldList = selectedFieldList
      .filter(a => a.field?.refName && a.field?.refName !== item.field?.refName)
      .map(a => a.field?.refName);

    const hasSelected = item.field?.refName;

    return (
      <Row key={item.uuid} className={`attribute-row-item ${rowIndex === 0 ? 'first-row' : ''}`}>
        <Col xs={8} className="col-field-key pr-4" {...leftColumnProp}>
          <Form.Item name={`attribute-key-${item.uuid}`} wrapperCol={{ xs: 24 }} className="mb-0">
            <Select
              options={
                Array.isArray(availableInsertFields) &&
                availableInsertFields.length &&
                availableInsertFields
                  .filter(field => {
                    if (hasSelected && otherSelectedFieldList.includes(field?.refName)) return false;
                    if (!hasSelected && selectedFieldRefNameList.includes(field?.refName)) return false;
                    return true;
                  })
                  .map(field => {
                    return {
                      label: i18next.exists(`akaat:workItem.${field.refName}`)
                        ? t(`workItem.${field.refName}`)
                        : field.name,
                      value: field.refName,
                      item: field
                    };
                  })
              }
              optionFilterProp="label"
              showSearch
              allowClear
              placeholder={t('common.selectKey')}
              disabled={isReadOnly}
              className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
              onChange={(val, option) => onChangeFieldKey(item.uuid, option?.item)}
            />
          </Form.Item>
        </Col>

        <Col xs={16} className="col-field-value" {...rightColumnProp}>
          <Row>
            <Col flex="1 1 calc(100% - 26px)">{renderField(item)}</Col>

            {!isReadOnly && (
              <Col flex="0 0 26px" style={{ paddingTop: 7 }}>
                <Row align="middle" justify="end">
                  <Tooltip
                    placement="top"
                    title={t('common.delete')}
                    destroyTooltipOnHide={true}
                    overlayStyle={{ pointerEvents: 'none' }}
                  >
                    {item.field?.refName ? (
                      <Popconfirm
                        title={t('testPlan.areYouSureDeleteThisAttribute')}
                        onConfirm={() => onRemoveRowFromAttributes(item)}
                        okText={t('common.delete')}
                        cancelText={t('common.cancel')}
                      >
                        <Button
                          type="link"
                          danger
                          icon={<DeleteOutlined />}
                          size="small"
                          className="border-transparent bg-transparent h-auto w-auto p-0"
                          onClick={e => e.stopPropagation()}
                        />
                      </Popconfirm>
                    ) : (
                      <Button
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                        size="small"
                        className="border-transparent bg-transparent h-auto w-auto p-0"
                        onClick={e => {
                          e.stopPropagation();
                          onRemoveRowFromAttributes(item);
                        }}
                      />
                    )}
                  </Tooltip>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  /**
   * Render add an attribute button
   */
  const renderAddAnAttributeButton = () => {
    const currentAttributeList = Array.isArray(attributeList) && attributeList.length ? [...attributeList] : [];
    const currentAvailableInsertFields =
      Array.isArray(availableInsertFields) && availableInsertFields.length ? [...availableInsertFields] : [];

    if (currentAttributeList.length >= currentAvailableInsertFields.length) return;

    return (
      <Button
        type="link"
        icon={<PlusOutlined />}
        className={`text-hover-dark-primary border-transparent h-auto w-auto p-0 ${
          currentAttributeList.length ? 'mt-3' : ''
        }`}
        onClick={() => {
          setAttributeList([...currentAttributeList, { uuid: uuidv4() }]);

          if (typeof onChangeField === 'function') onChangeField();
        }}
      >
        {t('testPlan.addAnAttribute')}
      </Button>
    );
  };

  return (
    <>
      <div className={`c-attribute-list ${className}`} {...rest}>
        {Array.isArray(attributeList) && attributeList.length > 0 && attributeList.map(renderRow)}

        {!isReadOnly && renderAddAnAttributeButton()}
      </div>
    </>
  );
};
