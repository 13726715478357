import Keycloak from 'keycloak-js';

import env from '../../env';

/**
 * Create keycloak
 */
export const keycloak = new Keycloak({
  url: /^http/.test(env.REACT_APP_KEYCLOAK_URL)
    ? env.REACT_APP_KEYCLOAK_URL
    : `${window.location.protocol}//${window.location.hostname}${env.REACT_APP_KEYCLOAK_URL}`,
  realm: env.REACT_APP_KEYCLOAK_REALM,
  clientId: env.REACT_APP_KEYCLOAK_CLIENT_ID
});
