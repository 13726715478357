import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE } from '../../../../constants';
import { sleep, checkValidField, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import AttachmentsFormItem from './attachments-form-item';
import { BasicUploadMultipleFiles } from '../../../basic-upload-multiple-files';

const AttachmentsField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  restAttachmentsField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [editingCell, setEditingCell] = useState(null);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => objectPath.get(row, field?.refName), [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      tableForm.setFieldsValue({ [formItemName]: getCurrentValue(defaultVal) });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultVal]);

  /**
   * Handle close
   */
  const handleClose = val => {
    setCurrentValue(val);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.click();
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async val => {
    tableForm.setFieldsValue({ [formItemName]: Array.isArray(val) && val.length ? val : null });

    await sleep(200);

    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !val)) {
      handleClose(defaultValue);
      return;
    }

    if (val !== defaultValue) {
      handleSetLastSavedValueToSession({ [formItemName]: val });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: val }, row, field });
      }
    }

    handleClose(val);
  };

  /**
   * On change deteled attachment ids
   */
  const onChangeDeteledAttachmentIds = ids => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    let attachments = Array.isArray(lastValue) && lastValue.length ? [...lastValue] : [];
    attachments = attachments.filter(a => !ids.includes(a?.id));

    handleSetLastSavedValueToSession({ [formItemName]: attachments });

    if (typeof restAttachmentsField?.onChangeDeteledAttachmentIds === 'function') {
      restAttachmentsField?.onChangeDeteledAttachmentIds(ids);
    }
  };

  /**
   * Render cell text
   */
  const renderAttachmentsUploadInput = () => {
    const val = getCurrentValue(currentValue);

    return (
      <div data-form-item-name={formItemName} data-x={x} data-y={y} className="box-attachments-upload-input">
        <BasicUploadMultipleFiles
          attachments={val}
          maxCount={restAttachmentsField?.maxCount}
          uploadPath={restAttachmentsField?.uploadPath}
          hasGetFullFilesInfo={restAttachmentsField?.hasGetFullFilesInfo}
          directlyDeleteAttachment={restAttachmentsField?.directlyDeleteAttachment}
          isReadOnly={isReadOnly}
          className="on-table"
          restAttachmentList={{ className: 'on-table' }}
          onChangeAttachments={handleSave}
          onChangeDeteledAttachmentIds={onChangeDeteledAttachmentIds}
        />
      </div>
    );
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <AttachmentsFormItem
        x={x}
        y={y}
        field={field}
        tableForm={tableForm}
        formItemName={formItemName}
        setEditingCell={setEditingCell}
        closeAndSetLastValue={closeAndSetLastValue}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && renderFormItem()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <div
            data-form-item-name={formItemName}
            data-x={x}
            data-y={y}
            className={`trigger-focus-element ${isEditing ? 'editing' : ''} ${
              editingCell?.focusTo === formItemName ? 'focused' : ''
            }`}
            onClick={() => {
              setEditingCell({ x, y, focusTo: formItemName });
            }}
          />
        )}

        <div className={`field-wrapper attachments-field ${className}`} {...rest}>
          {renderAttachmentsUploadInput()}
          {!isReadOnly && renderField()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderAttachmentsUploadInput();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new attachments-field ${className}`} {...rest}>
        {renderAttachmentsUploadInput()}
        {renderFormItem()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default AttachmentsField;
