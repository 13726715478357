import { getObjectByValue } from '../utils';

export const useStatus = () => {
  // For language
  // const [t] = useTranslation('akaat');

  // Unknown
  const UNKNOWN = {
    // name: t('common.unknown'),
    // type: 'done',
    // id: 'custom.wi.state.unknown',
    // color: { r: 141, g: 141, b: 141, a: 1 },
    // background: { r: 238, g: 238, b: 238, a: 1 },
    // position: {},
    // description: 'Unknown',
    // isDefault: false
  };

  /**
   * Get status from workflow by id
   */
  const getStatusFromWorkflowById = (originStatus, workflow) => {
    if (!(Array.isArray(workflow?.listStates) && workflow?.listStates.length)) {
      return UNKNOWN;
    }

    const foundStatus = getObjectByValue(originStatus?.id, workflow.listStates, 'id');
    let status = foundStatus || workflow.defaultState || UNKNOWN;

    if (originStatus?.id && !foundStatus) status = UNKNOWN;

    return status;
  };

  return { getStatusFromWorkflowById };
};
