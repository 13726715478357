import React, { Suspense, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { StoreProvider } from 'easy-peasy';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import en from './translations/en.json';
import fr from './translations/fr.json';
import ja from './translations/ja.json';
import vi from './translations/vi.json';

import 'antd/dist/antd.min.css';
import './assets/scss/style.scss';

import env from './env';
import { LS_LANGUAGE } from './constants';
import { store, useKeycloak, reactLocalStorage } from './common';
import { reactPlugin } from './app-insights';
import { RouterLoading } from './components';
import Layout from './layout';
import Pages from './pages';

// Init i18next - Language
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: { akaat: en },
    fr: { akaat: fr },
    ja: { akaat: ja },
    vi: { akaat: vi }
  }
});

function App() {
  const localLanguage = reactLocalStorage.get(LS_LANGUAGE);

  // For keycloak
  const { initialized } = useKeycloak();

  /**
   * For using https
   */
  if (
    window.location.protocol !== 'https:' &&
    (env.REACT_APP_FORCE_HTTPS === 'true' || env.REACT_APP_FORCE_HTTPS === true)
  ) {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }

  /**
   * Set language to i18next
   */
  useEffect(() => {
    let lang = 'en';

    if (localLanguage) {
      lang = localLanguage;
    } else {
      reactLocalStorage.set(LS_LANGUAGE, lang);
    }

    i18next.changeLanguage(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Waiting for keycloak is initialized
   */
  if (!initialized) {
    return <RouterLoading />;
  }

  return (
    <div className="App">
      <QueryParamProvider ReactRouterRoute={Route}>
        <StoreProvider store={store}>
          <Suspense fallback={<RouterLoading />}>
            <I18nextProvider i18n={i18next}>
              {/* <ReactKeycloakProviderCustom> */}
              <AppInsightsContext.Provider value={reactPlugin}>
                <Layout>
                  <Pages />
                </Layout>
              </AppInsightsContext.Provider>
              {/* </ReactKeycloakProviderCustom> */}
            </I18nextProvider>
          </Suspense>
        </StoreProvider>
      </QueryParamProvider>
    </div>
  );
}

export default App;
