import React from 'react';
import { BellOutlined, AppstoreOutlined } from '@ant-design/icons';
import { ExtensionsIcon, ImportExportIcon, Monitors, ProcessIcon } from '../../assets/svg-icons';

import env from '../../env';
import {
  P_PROJECT_SETTING_SETUP_TEAM,
  P_PROJECT_SETTING_USER_SETUP,
  P_PROJECT_SETTING_NOTIFICATION_SETUP,
  P_PROJECT_SETTING_INTEGRATION_SETUP,
  P_PROJECT_SETTING_TEST_CONFIG_SETUP,
  P_PROJECT_SETTING_PROCESS,
  P_PROJECT_SETTING_IMPORT_EXPORT_SETUP
} from '../permissions';
import { Users, UserLock, DotWithArrows, ScrewdriverWrenchSolid } from '../../assets/svg-icons';

const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

/**
 * Project setting menu
 */
export const PROJECT_SETTING_MENU = [
  {
    id: 'general',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/general`,
    name: 'menu.general',
    icon: <AppstoreOutlined />
  },
  {
    id: 'members',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/members`,
    name: 'menu.members',
    icon: <UserLock />,
    permission: P_PROJECT_SETTING_USER_SETUP
  },
  {
    id: 'teams',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/teams`,
    name: 'menu.teams',
    icon: <Users />,
    permission: P_PROJECT_SETTING_SETUP_TEAM
  },
  {
    id: 'teamDetail',
    parentId: 'teams',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/teams/detail/:teamKey`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_PROJECT_SETTING_SETUP_TEAM
  },

  {
    id: 'notifications',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/notifications`,
    name: 'menu.notifications',
    icon: <BellOutlined />,
    permission: P_PROJECT_SETTING_NOTIFICATION_SETUP
  },
  {
    id: 'notificationsDetail',
    parentId: 'notifications',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/notifications/detail/:id`,
    name: 'menu.notifications',
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_PROJECT_SETTING_NOTIFICATION_SETUP
  },
  {
    id: 'integration',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/integration`,
    name: 'menu.integration',
    icon: <DotWithArrows />,
    permission: P_PROJECT_SETTING_INTEGRATION_SETUP
  },
  {
    id: 'testConfiguration',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/test-configuration`,
    name: 'menu.testConfiguration',
    icon: <ScrewdriverWrenchSolid />,
    permission: P_PROJECT_SETTING_TEST_CONFIG_SETUP
  },
  {
    id: 'importTemplate',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/import_export-template`,
    name: 'menu.importExportTemplate',
    icon: <ImportExportIcon />,
    permission: P_PROJECT_SETTING_IMPORT_EXPORT_SETUP
  },
  {
    id: 'exportTemplate',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/import_export-template/export`,
    name: 'menu.importExportTemplate',
    icon: <ImportExportIcon />,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_PROJECT_SETTING_IMPORT_EXPORT_SETUP
  },
  {
    id: 'process',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/process`,
    name: 'menu.process',
    icon: <ProcessIcon />,
    permission: P_PROJECT_SETTING_PROCESS
  },
  {
    id: 'processDetail',
    parentId: 'process',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/process/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_PROJECT_SETTING_PROCESS
  },
  {
    id: 'agentPools',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/agent-pools`,
    name: 'menu.agentTestFlow',
    icon: <Monitors />
  },
  {
    id: 'extensions',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/extensions`,
    name: 'menu.extensions',
    icon: <ExtensionsIcon />
  },
  {
    id: 'agentPoolsDetail',
    parentId: 'agentPools',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/agent-pools/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'agentDetail',
    parentId: 'agentPools',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/agent-pools/agent/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  }
];
