import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../common';

export default {
  /**
   * State
   */
  relationTypeList: [],
  loadingRelationTypeList: false,

  /**
   * Action: Set global relation type list
   */
  setRelationTypeList: action((state, payload) => {
    if (!(state?.relationTypeList !== undefined)) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.relationTypeList = null;
      return;
    }

    state.relationTypeList = payload;
  }),

  /**
   * Action: Set loading relation type list
   */
  setLoadingRelationTypeList: action((state, payload) => {
    if (!(state?.loadingRelationTypeList !== undefined)) return;

    state.loadingRelationTypeList = payload;
  }),

  /**
   * Action: Call api to get relation type list
   */
  getRelationTypeList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }
      action.setLoadingRelationTypeList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.RELATION_TYPE}`;
      const res = await Http.get(url).then(res => res.data);

      action.setRelationTypeList(res?.data?.rows);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRelationTypeList(false);
    }
  })
};
