import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { BasicRelationItem } from '../basic-relation-item';

const BoxRelatedTickets = ({
  workTicketId,
  editingItem,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onReload,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For relation store
  const getRelationList = useStoreActions(action => action.global.getRelationList);
  const setRelationList = useStoreActions(action => action.global.setRelationList);
  const data = useStoreState(state => state.global.relationList);
  const loadingRelationTypeList = useStoreState(state => state.global.loadingRelationTypeList);

  /**
   * Get relation list
   */
  useEffect(() => {
    if (!editingItem?.key) return;

    getRelationList({
      key: editingItem.key,
      query: {
        page: null,
        limit: null,
        order: null
      }
    });
  }, [editingItem, getRelationList]);

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setRelationList(null);
    };
  }, [setRelationList]);

  return (
    <div
      id="anchorRelatedTickets"
      className={`c-detail-components type-related-tickets collapse-item mb-4 ${className}`}
      {...rest}
    >
      <h5 className="btn-toggle ant-typography mb-0">
        <span id="toggle-related-tickets-button" onClick={() => onChangeCollapse('anchorRelatedTickets')}>
          <>{collapseKeys.includes('anchorRelatedTickets') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
          <span>{t('anchor.anchorRelatedTickets')}</span>
        </span>
      </h5>

      <div className={`collapse-body ${collapseKeys.includes('anchorRelatedTickets') ? 'show' : ''}`}>
        <div className="pt-2">
          <BasicRelationItem
            data={data}
            onReload={onReload}
            isReadOnly={isReadOnly}
            loadingList={loadingRelationTypeList}
          />
        </div>
      </div>
    </div>
  );
};

export default BoxRelatedTickets;
