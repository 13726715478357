import { AutoComplete, Empty, Input, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TESTMAN_PLATFORM_ID, JIRA_PLATFORM_ID } from '../../../constants';
import { debounce, escapeRegExpMongodb } from '../../../common';

let typingTimerOfSearch = 0;
let typingTimerOfLazyLoad = 0;

const placeholderText = {
  [JIRA_PLATFORM_ID]: 'relationTo.enterTheExistingWorkTicketName',
  [TESTMAN_PLATFORM_ID]: 'relationTo.enterTheExistingIssueName'
};

export default ({ relation, workItem, form }) => {
  const [t] = useTranslation('akaat');

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const objectiveList = useStoreState(state => state.global.objectiveList);
  const objectiveTotal = useStoreState(state => state.global.objectiveTotal);
  const loadingRelationTypeList = useStoreState(state => state.global.loadingRelationTypeList);
  const getObjectiveList = useStoreActions(action => action.global.getObjectiveList);
  const setObjectiveList = useStoreActions(action => action.global.setObjectiveList);
  const relationList = useStoreState(state => state.global.relationList);

  // For jira store
  const searchJiraByJql = useStoreActions(action => action.jiraIntegration.searchByJql);
  const setIssueList = useStoreActions(action => action.jiraIntegration.setIssueList);
  const jiraIssueList = useStoreState(state => state.jiraIntegration.data);
  const loadingListJira = useStoreState(state => state.jiraIntegration.loadingList);

  // Component state
  const [workTicketList, setWorkTicketList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [page, setPage] = useState({
    jira: 1,
    manager: 1
  });

  useEffect(() => {
    if (!relation.jiraIssueType?.name) return;

    setWorkTicketList([]);
    setSearchValue('');
  }, [relation.jiraIssueType]);

  /**
   * Set jira defect item list
   */
  useEffect(() => {
    if (!Array.isArray(jiraIssueList)) return;

    const options = jiraIssueList.map(item => {
      return {
        title: `${item?.key}: ${item?.fields?.summary}`,
        label: <span className="text-truncate">{`${item?.key}: ${item?.fields?.summary}`}</span>,
        value: `${item?.key}: ${item?.fields?.summary}`,
        item
      };
    });

    setWorkTicketList(options);
  }, [jiraIssueList]);

  /**
   * Set objective list
   */
  useEffect(() => {
    if (!Array.isArray(objectiveList) || !objectiveList.length) {
      setWorkTicketList([]);
    }

    const options = objectiveList.map(item => {
      const itself = item?.key === workItem?.key;
      const isExist = relationList?.some(relation => item._id === relation?.target?._id);
      const label = item.name
        ? `${item?.workTicketType?.name} - ${item.key} - ${item.name}`
        : `${item?.workTicketType?.name} - ${item.key}`;

      return {
        label: (
          <Tooltip title={isExist ? t('relationTo.thisItemHasBeenRelatedTo') : label}>
            <span className="text-truncate">{label}</span>
          </Tooltip>
        ),
        value: label,
        item,
        disabled: itself || isExist
      };
    });

    setWorkTicketList(options);
  }, [objectiveList, relationList, workItem]);

  /**
   * Handle search by keyword
   */
  const handleSearchByKeyword = (val, actionType) => {
    if (loadingListJira || !globalProject?.projectKey || !globalTenant.tenantKey || !relation) return;

    if (typeof val === 'string') {
      val = val.trim();
    }

    setPage({
      jira: 0,
      manager: 0
    });
    setObjectiveList([]);

    if (actionType === 'TYPING') {
      clearTimeout(typingTimerOfSearch);

      typingTimerOfSearch = setTimeout(
        debounce(() => {
          if (val === '') {
            setWorkTicketList([]);
            form.setFieldsValue({ item: '' });
            return;
          }

          if (relation.integrationSystem === JIRA_PLATFORM_ID) {
            getDataFromJira(val);
          }

          if (relation.integrationSystem === TESTMAN_PLATFORM_ID) {
            getDataFromManager(val, page.manager);
          }
        }),
        300
      );
    } else {
      if (val === '') {
        setWorkTicketList([]);
        form.setFieldsValue({ item: '' });
      }

      form.setFieldsValue({ item: val });
    }
  };

  const getDataFromJira = val => {
    if (!val || !relation) return;

    let jiraType = '';

    if (relation?.jiraIssueType?.name) {
      jiraType = `AND type = '${relation.jiraIssueType.name}'`;
    }

    const jql = `text ~ '${val}' ${jiraType}`;

    searchJiraByJql({
      jql,
      projectKey: globalProject.projectKey,
      tenantKey: globalTenant.tenantKey
    });
  };

  const getDataFromManager = async (val, page) => {
    if (!val || !relation) return;

    const limit = 100 * page;

    if (page > 1 && limit - 10 > objectiveTotal) return;

    const query = {
      filter: {
        $or: [
          {
            name: { $regex: escapeRegExpMongodb(val), $options: 'i' }
          },
          {
            key: val
          }
        ],
        'workTicketType.id': relation.workTicketType
      },
      limit
    };

    clearTimeout(typingTimerOfLazyLoad);

    typingTimerOfLazyLoad = setTimeout(
      debounce(() => {
        getObjectiveList(query);
      }),
      300
    );
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setObjectiveList([]);
      setIssueList([]);
    };
  }, [setObjectiveList, setIssueList]);

  /**
   * Handle get more suggestion
   */
  const handleGetMoreSuggestion = async () => {
    const currentPage = page.manager + 1;
    await getDataFromManager(searchValue, currentPage);
    setPage({ ...page, manager: currentPage });
  };

  return (
    <>
      <AutoComplete
        value={searchValue}
        options={workTicketList}
        allowClear
        className="has-enter-and-clear-button"
        notFoundContent={searchValue && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        onSelect={(value, option) => {
          setSearchValue(value);
          handleSearchByKeyword(option.item);
        }}
        onSearch={val => {
          setSearchValue(val);
          handleSearchByKeyword(val, 'TYPING');
        }}
        onPopupScroll={async e => {
          if (e?.target?.scrollTop + e?.target?.offsetHeight === e?.target?.scrollHeight) {
            handleGetMoreSuggestion();
          }
        }}
      >
        <Input.Search
          placeholder={t(placeholderText[relation.integrationSystem])}
          loading={loadingListJira || loadingRelationTypeList}
        />
      </AutoComplete>
    </>
  );
};
