import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../../../common';

export const model = {
  /**
   * State
   */
  editingComment: {},
  query: {},
  isVisibleEditor: false,
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set editing comment
   */
  setEditingComment: action((state, payload) => {
    if (state?.editingComment === undefined) return;

    state.editingComment = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set is visible editor
   */
  setIsVisibleEditor: action((state, payload) => {
    if (state?.isVisibleEditor === undefined) return;

    state.isVisibleEditor = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get comment list
   */
  getCommentList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.filter) throw new Error('There are no filter');

      action.setLoadingList(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.COMMENT}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get comment by Id
   */
  getCommentById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('There are no id');

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.COMMENT}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingComment(res?.data);

      return res;
    } catch (err) {
      handleError(err);
      setTimeout(() => window.location.reload(), 1000);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create comment
   */
  createComment: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.COMMENT}`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update comment
   */
  updateComment: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) throw new Error('There are no _id');

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.COMMENT}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:comment.messageUpdatingComment'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete comment
   */
  deleteComment: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) throw new Error('There are no _id');

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.COMMENT}/${payload._id}`;

      await Http.delete(url, {}).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:comment.messageDeletingComment'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  })
};
