import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Layout, Menu, Dropdown, Row } from 'antd';
import { UserOutlined, LoginOutlined, LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';

import env from '../../env';
import {
  ROLE_APP_ACCESS_TESTENGINE,
  ROLE_APP_ACCESS_MANAGER,
  PRODUCT_KEY_ON_URL_ENGINE,
  PRODUCT_KEY_ON_URL_MANAGER
} from '../../constants';
import { useKeycloak, convertToFullName } from '../../common';
import { UserAvatar } from '../../components';

import './style.scss';

const isShowLabelBeta = false;

export const MainHeader = () => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];
  const thirdPath = location.pathname.split('/')[env.REACT_APP_PREFIX_PATH ? 4 : 3];

  // For keycloak
  const { keycloak } = useKeycloak();

  // For language
  const [t] = useTranslation('akaat');

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For global user tenant action
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // For global production
  const globalProductionKey = useStoreState(state => state.global.globalProductionKey);

  // For global project store
  const globalProject = useStoreState(state => state.global.globalProject);

  /**
   * Product menus
   */
  const productMenus = useMemo(() => {
    const items = [];

    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER)) {
      items.push({
        key: PRODUCT_KEY_ON_URL_MANAGER,
        label: (
          <Link
            to={{
              pathname: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/${PRODUCT_KEY_ON_URL_MANAGER}/dashboard`
            }}
          >
            {t('header.management')}
          </Link>
        )
      });
    }

    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_TESTENGINE)) {
      items.push({
        key: PRODUCT_KEY_ON_URL_ENGINE,
        label: (
          <Link
            to={{
              pathname: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/${PRODUCT_KEY_ON_URL_ENGINE}/summary`
            }}
          >
            {t('header.runTimeEngine')}
          </Link>
        )
      });
    }

    return items;
  }, [globalUserInfo, tenantPath, projectPath]);

  /**
   * Account menus
   */
  const accountMenus = useMemo(() => {
    const items = [];

    if (globalUserInfo?.id) {
      items.push({
        key: 'profile',
        label: (
          <a href={`${env.REACT_APP_AKAAT_URL}/profile`} target="_blank" rel="noreferrer">
            <UserOutlined /> {t('header.myProfile')}
          </a>
        )
      });
    }

    if (keycloak?.authenticated) {
      items.push({
        key: 'logout',
        label: (
          <a rel="noreferrer" onClick={() => keycloak?.logout()}>
            <LogoutOutlined /> {t('header.logout')}
          </a>
        )
      });
    }

    if (!keycloak?.authenticated) {
      items.push({
        key: 'login',
        label: (
          <a rel="noreferrer" onClick={() => keycloak?.login()}>
            <LoginOutlined /> {t('header.login')}
          </a>
        )
      });
    }

    return items;
  }, [globalUserInfo, keycloak?.authenticated]);

  /**
   * Render user name
   */
  const renderUserName = () => {
    const fullName = convertToFullName(globalUserInfo);
    const name = globalUserInfo?.name
      ? globalUserInfo?.name
      : fullName
      ? fullName
      : globalUserInfo?.username
      ? globalUserInfo?.username
      : globalUserInfo?.email;

    return (
      <span className="mr-1">
        {t('header.hello')}, <span className="font-weight-medium">{name || t('header.guest')}</span>
      </span>
    );
  };

  return (
    <>
      <Layout.Header className={`c-main-header ${isShowLabelBeta ? 'beta-version' : ''}`}>
        {isShowLabelBeta && (
          <div className="label-beta" style={{ display: 'none' }}>
            <span>BETA</span>
          </div>
        )}

        <Row className="flex-grow-1">
          <div className="logo pl-3" style={{ width: 220 }}>
            <a href={`/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey || ''}`}>
              <img src={`/${env.REACT_APP_PREFIX_PATH}images/logo.png`} alt="AkaAT Logo" height="40px" />
            </a>
          </div>

          {projectPath &&
            projectPath !== '_settings' &&
            projectPath === globalProject?.projectKey &&
            thirdPath !== '_settings' && (
              <Menu
                mode="horizontal"
                selectedKeys={[globalProductionKey]}
                items={productMenus}
                className="flex-grow-1"
              />
            )}
        </Row>

        <Row>
          {/* <SearchForm /> */}

          {/* <LanguageDropdown restDropdown={{ placement: 'bottom' }} className="ml-3" /> */}

          <Dropdown
            menu={{ items: accountMenus }}
            trigger={['click']}
            destroyPopupOnHide={true}
            placement="bottomRight"
            className="btn-dropdown-account ml-3"
          >
            <Row align="middle" className="ant-dropdown-link cursor-pointer">
              {renderUserName()}

              <UserAvatar user={globalUserInfo} restAvatar={{ size: 30 }} hideName={true} />

              <CaretDownOutlined className="ic-arrow" />
            </Row>
          </Dropdown>
        </Row>
      </Layout.Header>
    </>
  );
};
