import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../common';

export default {
  /**
   * State
   */
  jiraCreateIssueMeta: [],
  jiraAssignableUserList: [],
  loadingJiraIssueMeta: false,
  loadingAssignableUserList: false,
  loadingJiraIssueItem: false,

  /**
   * Action: Set loading jira issue meta
   */
  setLoadingJiraIssueMeta: action((state, payload) => {
    if (state?.loadingJiraIssueMeta === undefined) return;

    state.loadingJiraIssueMeta = payload;
  }),

  /**
   * Action: Set loading jira isue list
   */
  setLoadingJiraAssignableUserList: action((state, payload) => {
    if (state?.loadingAssignableUserList === undefined) return;

    state.loadingAssignableUserList = payload;
  }),

  /**
   * Action: Set loading jira assignable user
   */
  setLoadingJiraIssueItem: action((state, payload) => {
    if (state?.loadingJiraIssueItem === undefined) return;

    state.loadingJiraIssueItem = payload;
  }),

  /**
   * Action: Set jira issue type meta data
   */
  setJiraCreateIssueMeta: action((state, payload) => {
    if (!(state?.jiraCreateIssueMeta !== undefined)) return;

    if (!(Array.isArray(payload?.issuetypes) || !payload?.issuetypes.length)) {
      state.jiraCreateIssueMeta = [];
      return;
    }

    state.jiraCreateIssueMeta = payload?.issuetypes;
  }),

  /**
   * Action: Set jira user assignable list
   */
  setJiraAssignableUserList: action((state, payload) => {
    if (!(state?.jiraAssignableUserList !== undefined)) return;

    if (!(Array.isArray(payload) || !payload.length)) {
      state.jiraAssignableUserList = [];
      return;
    }

    state.jiraAssignableUserList = payload;
  }),

  /**
   * Action: Call api get jira issue type list
   */
  getJiraCreateIssueMeta: thunk(async (action, payload) => {
    try {
      if (!payload?.projectId) throw new Error('Get jira create issue meta: Invalid Payload');

      action.setLoadingJiraIssueMeta(true);

      const url = `${ENDPOINTS.JIRA_ISSUE_CREATE_META}?projectId=${payload.projectId}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJiraCreateIssueMeta(res);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingJiraIssueMeta(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  getJiraAssignableUserList: thunk(async (action, payload) => {
    try {
      if (!payload?.projectId) throw new Error('Get jira assignable user list: Invalid project id');

      action.setLoadingJiraAssignableUserList(true);

      const url = `${ENDPOINTS.JIRA_ISSUE_USER_ASSIGNABLE}?projectId=${payload.projectId}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJiraAssignableUserList(res);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingJiraAssignableUserList(false);
    }
  }),

  /**
   * Action: Call api get user assignable list
   */
  jiraCreateIssue: thunk(async (action, payload) => {
    try {
      if (!payload?.projectId || !payload?.formData) throw new Error('Jira create issue: Invalid project id');

      action.setLoadingJiraIssueItem(true);

      const url = `${ENDPOINTS.JIRA_ISSUE}?projectId=${payload.projectId}`;
      const res = await Http.post(url, payload.formData).then(res => res.data);

      // action.setJiraAssignableUserList(res);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingJiraIssueItem(false);
    }
  })
};
