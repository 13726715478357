import { action, thunk } from 'easy-peasy';
import moment from 'moment';

import { ENDPOINTS, FIELD_SYSTEM, WORK_ITEM_TEST_RESULT_ID } from '../../constants';
import { Http, filterTestResultFields, handleError, reactSessionStorage } from '../../common';

export default {
  /**
   * State
   */
  ticketType: {},
  ticketListData: {},
  loadingTicketTypeList: false,
  loadingTicketTypeItem: false,

  /**
   * Action: Set field list for search
   */
  setFieldList: action((state, payload) => {
    if (!(state?.fieldList !== undefined)) return;

    if (!payload?.dataSource) {
      state.fieldList = {};
      return;
    }

    if (!(Array.isArray(payload.data) && payload.data.length)) {
      state.fieldList[payload.dataSource] = [];
      return;
    }

    const fieldList = [];

    payload.data.forEach(field => {
      if (field.refName)
        fieldList.push({
          ...field,
          name: field.name,
          referenceField: field.refName,
          isSystemField: field.refName.split('.')[0] === FIELD_SYSTEM ? true : false
        });
    });

    state.fieldList[payload.dataSource] = fieldList;
  }),

  /**
   * Action: Set loading field list
   */
  setLoadingFieldList: action((state, payload) => {
    if (!(state?.loadingFieldList !== undefined)) return;

    state.loadingFieldList = payload;
  }),

  /**
   * Action: Set data field list
   */
  setTicketType: action((state, payload) => {
    if (!(state?.ticketType !== undefined)) return;

    state.ticketType = payload;
  }),

  /**
   * Action: Set ticket list data follow ticket id
   */
  setTicketListData: action((state, payload) => {
    if (!(state?.ticketListData !== undefined)) return;

    if (!payload?.workTicketId) {
      state.ticketListData = {};
      return;
    }

    if (!payload.data) {
      state.ticketListData[payload.workTicketId] = [];
      return;
    }

    state.ticketListData[payload.workTicketId] = payload.data;
  }),

  /**
   * Action: Call api to get field list
   */
  getTicketTypeById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Get ticket type by id: Invalid data source');

      action.setLoadingFieldList(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getTicketTypeByIdTimer', {});
      const currentTime = timer?.[payload];

      if (moment().clone().unix() <= currentTime) return;

      reactSessionStorage.setObject('getTicketTypeByIdTimer', {
        ...timer,
        [payload]: moment().clone().add(0.25, 'second').unix() // Nếu vừa call api get một workTicketId, thì 0.25 giây sau mới được get tiếp workTicketId đó.
      });

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TEMPLATE}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      let newData = { ...res?.data };
      let newFiels = Array.isArray(newData?.fields) && newData?.fields.length ? [...newData?.fields] : [];

      if (payload === WORK_ITEM_TEST_RESULT_ID) {
        newFiels = filterTestResultFields(newFiels);
      }

      newData = {
        ...newData,
        fields: newFiels
      };

      action.setTicketType(newData);

      action.setTicketListData({
        workTicketId: payload,
        data: newData
      });

      action.setFieldListManager({
        workTicketId: payload,
        data: newData?.fields
      });

      helpers.getStoreActions()['workflow'].setWorkflowByWorkItem({
        workTicketId: payload,
        data: newData?.workFlow
      });

      return newData;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingFieldList(false);
    }
  })
};
