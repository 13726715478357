import { action, thunk } from 'easy-peasy';
import { notification } from 'antd';
import i18next from 'i18next';

import { ENDPOINTS, TRACEABILITY_TYPE } from '../../../../constants';
import { Http, buildQueryForFilterMongo, handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: null,
  query: null,
  expandedRowKeys: [],
  loadingList: false,
  loadingItem: false,
  levelConfig: null,
  traceabilityConfig: null,
  filterConfig: null,
  editingTracebility: {},
  traceabilityType: TRACEABILITY_TYPE.BROWSER,
  ticketByInitial: {
    columnKeys: []
  },
  summary: {},
  fullScreen: false,

  /**
   * Action: Set expanded row keys
   */
  setExpandedRowKeys: action((state, payload) => {
    if (state?.expandedRowKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedRowKeys = [];
      return;
    }

    state.expandedRowKeys = payload;
  }),
  /**
   * Action: Set traceability list
   */
  setTraceabilityList: action((state, payload) => {
    if (state?.data === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.data = payload;
  }),

  /**
   * Action: Set traceability list
   */

  setEditingTracebility: action((state, payload) => {
    if (state?.editingTracebility === undefined) return;

    state.editingTracebility = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;
    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set Filter Config
   */
  setFilterConfig: action((state, payload) => {
    if (state?.filterConfig === undefined) return;

    state.filterConfig = payload;
  }),

  /**
   * Action: Set Types Traceability
   */
  setTraceabilityType: action((state, payload) => {
    if (state?.traceabilityType === undefined) return;

    state.traceabilityType = payload;
  }),

  /**
   * Action: Set Level Config
   */
  setLevelConfig: action((state, payload) => {
    if (state?.levelConfig === undefined) return;

    state.levelConfig = payload;
  }),

  /**
   * Action: Set traceability Config
   */
  setTraceabilityConfig: action((state, payload) => {
    if (state?.traceabilityConfig === undefined) return;

    state.traceabilityConfig = payload;
  }),

  setTicketByInitial: action((state, payload) => {
    if (state?.ticketByInitial === undefined) return;

    state.ticketByInitial = { ...state.ticketByInitial, ...payload };
  }),

  /**
   * Action: Set summary
   */
  setSummary: action((state, payload) => {
    if (!(state?.summary !== undefined)) return;

    if (!payload) {
      state.summary = {};
      return;
    }

    state.summary = payload;
  }),

  /**
   * Action: Set full screen
   */
  setFullScreen: action((state, payload) => {
    if (state?.fullScreen === undefined) return;

    state.fullScreen = payload;
  }),

  /**
   * Action: Call api to get traceability list
   */
  getTraceabilityList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingList(true);
      action.setQuery(payload);

      //global
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}?${payload.queryString}`;

      const res = await Http.get(url).then(res => res.data);
      let newData = Array.isArray(res?.data?.rows) && res.data.rows.length ? [...res.data.rows] : [];

      action.setTraceabilityList(newData);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get traceability list
   */
  getTraceabilityListBySearch: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingList(true);
      action.setQuery(payload);

      //global
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}?${payload.queryString}`;

      const res = await Http.get(url).then(res => res.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get traceability detail
   */
  getTraceabilityDetail: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingItem(true);
      action.setQuery(payload);

      //global
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}/${payload}`;

      const res = await Http.get(url).then(res => res.data);

      action.setEditingTracebility(res.data);

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get relation matrix list
   */
  getRelationBrowserList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.RELATION
      }/by-work-ticket-type/preview?${buildQueryForFilterMongo(payload.query)}`;
      const res = await Http.post(url, payload.body).then(res => res.data);

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get relation matrix list
   */
  getRelationMatrixList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.RELATION}/by-work-ticket-type/preview`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  addTraceability: thunk(async (action, payload, helpers) => {
    try {
      // action.setLoadingList(true);
      // action.setQuery(payload);

      //global
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}`;

      const res = await Http.post(url, payload).then(res => res.data);
      notification.success({
        message: i18next.t('akaat:traceability.messageCreatingTraceability'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  editTraceability: thunk(async (action, payload, helpers) => {
    try {
      // action.setLoadingList(true);
      // action.setQuery(payload);

      //global
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}/${payload.id}`;

      const res = await Http.put(url, payload).then(res => res.data);
      notification.success({
        message: i18next.t('akaat:traceability.messageUpdatingTraceability'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      // action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to remove traceability
   */
  deleteTraceability: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('No Traceability');

      action.setLoadingList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}/${payload}`;
      await Http.delete(url).then(res => res.data);

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to clone traceability
   */
  cloneTraceability: thunk(async (action, payload, helpers) => {
    try {
      const traceabilityId = payload._id;
      delete payload._id;

      if (!payload) throw new Error('No Traceability');

      action.setLoadingList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const url = `${ENDPOINTS.MANAGER}/traceability/${globalTenant?.tenantKey}/${globalProject?.projectKey}/${traceabilityId}/clone`;
      return await Http.post(url, payload).then(res => res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get traceability matrix list
   */
  getTraceabilityMatrixList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.workTicketId) throw new Error('Get traceability summary: There are no work ticket id');

      action.setLoadingList(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TRACEABILITY}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/work-ticket-type/${payload.workTicketId}?${buildQueryForFilterMongo(payload.query)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get traceability matrix summary
   */
  getTraceabilityMatrixSummary: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.workTicketId) throw new Error('Get traceability matrix summary: There are no work ticket id');

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TRACEABILITY}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/work-ticket-type/${payload.workTicketId}/ticket-type-is-cover?${buildQueryForFilterMongo(payload.query)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  })
};
