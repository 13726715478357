import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import { DATA_SOURCE_FIELDS } from '../../constants';

export const DataSourceSelection = ({ className = '', currentDataSource, setCurrentDataSource, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <>
      <Select
        value={currentDataSource}
        options={DATA_SOURCE_FIELDS.map(item => {
          return {
            label: t(item.label),
            value: item.value,
            item
          };
        })}
        placeholder={t('common.pleaseSelect')}
        dropdownMatchSelectWidth={false}
        className={`c-data-source-selection ${className}`}
        onSelect={setCurrentDataSource}
        {...rest}
      />
    </>
  );
};
