import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../../../common';

export const model = {
  /**
   * State
   */
  query: {},
  loadingList: false,

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Call api to get history list
   */
  getHistoryList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.filter) throw new Error('Get history list of test result: There are no filter');

      action.setLoadingList(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.HISTORY}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  })
};
