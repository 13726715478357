import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Filedownload from 'js-file-download';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import env from '../../env';
import { ErrorFileNotFound } from '../error-file-not-found';
import PreviewHeader from './preview-header';
import ViewFile from './view-file';

import './style.scss';

export const BasicPreviewFile = ({
  fileInfo,
  onlyPreviewFileOnClient,
  showCloseButton,
  showHeader = true,
  className = '',
  onClose,
  ...rest
}) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For file store
  const getFileById = useStoreActions(action => action.global.getFileById);
  const loadingFile = useStoreState(state => state.global.loadingFile);

  // For component state
  const [currentFileInfo, setCurrentFileInfo] = useState(null);

  /**
   * Set current file info
   */
  useEffect(() => {
    if (!fileInfo?.id) {
      setCurrentFileInfo(null);
      return;
    }

    const type = fileInfo.type && typeof fileInfo.type === 'string' ? fileInfo.type : '';

    setCurrentFileInfo({ ...fileInfo, type });
  }, [fileInfo]);

  /**
   * On download
   */
  const onDownload = async () => {
    if (!currentFileInfo?.id) return;

    const res = await getFileById({ tenantKey: tenantPath, projectKey: projectPath, fileId: currentFileInfo?.id });

    if (res?.data) Filedownload(res?.data, currentFileInfo?.fileName);
  };

  /**
   * Return to not found
   * For preview file page
   */
  if (currentFileInfo?.hasError && window.location.pathname.includes('/files/')) {
    return <ErrorFileNotFound />;
  }

  return (
    <div className={`c-basic-preview-file ${className}`} {...rest}>
      <Spin indicator={<Loading3QuartersOutlined spin className="font-size-30" />} spinning={loadingFile}>
        <div className="box-preview-file">
          {showHeader && (
            <PreviewHeader
              fileInfo={currentFileInfo}
              onlyPreviewFileOnClient={onlyPreviewFileOnClient}
              showCloseButton={showCloseButton}
              onDownload={onDownload}
              onClose={onClose}
            />
          )}

          <ViewFile
            fileInfo={currentFileInfo}
            setFileInfo={setCurrentFileInfo}
            onlyPreviewFileOnClient={onlyPreviewFileOnClient}
            onDownload={onDownload}
          />
        </div>
      </Spin>
    </div>
  );
};
