import React, { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLocation } from 'react-router-dom';

import env from '../../env';
import {
  WORK_ITEM_DEFECT_ID,
  PRODUCT_KEY_ON_URL_MANAGER,
  RELATED_TYPE_DEFECT_ID,
  JIRA_PLATFORM_ID,
  EXTERNAL_ID
} from '../../constants';
import CreateDefectModal1 from './create-defect-modal-1';
import CreateDefectModal2 from './create-defect-modal-2';

import './styles.scss';

export const BasicDefect = ({
  onSubmit,
  title = '',
  testStep = [],
  loading,
  workItemId,
  workTicketId,
  currentWorkItemKey,
  workItemKeys = [],
  visible,
  remoteLink = null,
  setVisibleDefectModal
}) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For global store
  const createRelation = useStoreActions(action => action.global.createRelation);
  const createObjective = useStoreActions(action => action.global.createObjective);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);

  // For work ticket
  const createWorkTicket = useStoreActions(action => action.managerWorkItem.createWorkTicket);

  // For component state
  const [visibleCreateDefectModal2, setVisibleCreateDefectModal2] = useState(false);
  const [defect, setDefect] = useState({
    item: '',
    defectSystem: '',
    workItemType: WORK_ITEM_DEFECT_ID,
    jiraIssueType: ''
  });

  // Submit form
  const handleSubmit = async data => {
    if (!data) return;

    await onSubmit(data);

    setVisibleDefectModal(false);
    setVisibleCreateDefectModal2(false);
  };

  const handleCreateRelation = async data => {
    if (!data) return;

    let defectLink;

    switch (data.type) {
      case EXTERNAL_ID:
      case JIRA_PLATFORM_ID: {
        let formData = data?.formData;
        delete data?.formData;
        const wtt = globalProcessList.find(process => process.workTicketId === workTicketId);
        const objectiveObj = await createObjective({
          name: data.name,
          workTicketType: globalProcessList.find(process => process.workTicketId === workTicketId),
          external: true,
          externalData: data
        });

        if (objectiveObj?.key) {
          const source = currentWorkItemKey;
          const target = objectiveObj.key;

          await createRelation({
            relType: RELATED_TYPE_DEFECT_ID,
            source,
            target
          });
          defectLink = {
            ...data,
            formData,
            key: objectiveObj.key
          };
        }
        break;
      }

      default: {
        if (workItemKeys) {
          if (data?._id) {
            const source = currentWorkItemKey;
            const target = data.key;

            await createRelation({
              relType: RELATED_TYPE_DEFECT_ID,
              source,
              target
            });

            defectLink = {
              id: data._id,
              key: data.key,
              name: data.name,
              type: PRODUCT_KEY_ON_URL_MANAGER,
              link: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${WORK_ITEM_DEFECT_ID}/detail/${data.key}`
            };
          } else {
            const res2 = await createWorkTicket({ ...data, relationKeys: workItemKeys, hideSuccessMessage: true });

            if (res2) {
              defectLink = {
                id: res2._id,
                name: data.name,
                key: res2.key,
                type: PRODUCT_KEY_ON_URL_MANAGER,
                link: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${WORK_ITEM_DEFECT_ID}/detail/${res2.key}`
              };
            }
          }
        }

        break;
      }
    }
    handleSubmit(defectLink);
  };
  return (
    <>
      {visible && (
        <CreateDefectModal1
          workItemId={workItemId}
          loading={loading}
          title={title}
          testStep={testStep}
          onSubmit={handleSubmit}
          handleCreateRelation={handleCreateRelation}
          setDefect={val => setDefect({ ...defect, ...val })}
          defect={defect}
          visible={visible}
          onCancel={() => setVisibleDefectModal(false)}
          onNext={() => {
            setVisibleCreateDefectModal2(true);
          }}
        />
      )}
      {visibleCreateDefectModal2 && (
        <CreateDefectModal2
          loading={loading}
          title={title}
          remoteLink={remoteLink}
          testStep={testStep}
          onSubmit={handleSubmit}
          handleCreateRelation={handleCreateRelation}
          defect={defect}
          visible={visibleCreateDefectModal2}
          onPrevious={() => {
            setVisibleCreateDefectModal2(false);
          }}
          onCancel={() => {
            setVisibleDefectModal(false);
            setVisibleCreateDefectModal2(false);
          }}
        />
      )}
    </>
  );
};
