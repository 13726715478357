import React, { useState } from 'react';
import { Button, Col, Empty, Row, Tooltip, Select, Dropdown, Switch, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { PAGE_SIZES, WORK_ITEM_TESTCASE_ID, WORK_ITEM_TEST_RUN_ID } from '../../constants';
import { PlusOutlined } from '../../assets/svg-icons';
import FieldList from './field-list';
import { SafeInnerHtml } from '../safe-inner-html';

export const ConfigGridViewContents = ({
  isFromProcessSettingModule,
  workticketId,
  fieldList,
  moreFields,
  setMoreFields,
  shownFields,
  page,
  onChangePage,
  sortAttribute,
  onChangeSortAttribute,
  sortOrder,
  onChangeSortOrder,
  visibleTestSteps,
  onChangeVisibleTestSteps,
  visibleDetailRelation,
  onChangeVisibleDetailRelation,
  allowQuickExecute,
  onChangeAllowQuickExecute,
  onAddFieldToList,
  onReOrderField,
  onRemoveFieldToList,
  className = '',
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // Component state
  const [visibleSearchMoreField, setVisibleSearchMoreField] = useState(true);
  const [visibleDropDownMoreField, setVisibleDropDownMoreField] = useState();

  /**
   * Get field remaining
   */
  const getFieldRemaining = (fieldList = [], shownFields = []) => {
    const shownFieldRefNameList =
      Array.isArray(shownFields) && shownFields.length ? shownFields.map(item => item.refName) : [];

    return Array.isArray(fieldList) ? fieldList.filter(item => !shownFieldRefNameList.includes(item.refName)) : [];
  };

  /**
   * On search more fields
   */
  const onSearchMoreFields = val => {
    if (!Array.isArray(fieldList) || !fieldList.length || typeof val !== 'string') return;

    const fields = getFieldRemaining(fieldList, shownFields);

    const result = fields.filter(field => {
      const name = i18n.exists(`akaat:workItem.${field.refName}`) ? t(`workItem.${field.refName}`) : field.name;

      return name?.toLowerCase()?.includes(val?.toLowerCase());
    });

    setMoreFields(result);
  };

  /**
   * Render more field dropdown
   */
  const renderMoreFieldDropdown = () => {
    const MoreFieldMenus = (
      <>
        <div className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
          <div
            key="search"
            className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child py-2"
            style={{ minHeight: 42 }}
          >
            {visibleSearchMoreField && (
              <Input
                suffix={<SearchOutlined className="text-gray-2" />}
                autoFocus
                placeholder={t('common.search')}
                onChange={e => onSearchMoreFields(e?.target?.value)}
              />
            )}
          </div>

          <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
            {Array.isArray(moreFields) && moreFields.length > 0 ? (
              moreFields.map((item, index) => {
                const title = i18n.exists(`akaat:workItem.${item.refName}`) ? t(`workItem.${item.refName}`) : item.name;

                return (
                  <div
                    key={index}
                    title={title}
                    className="field-btn ant-dropdown-menu-item ant-dropdown-menu-item-only-child text-truncate"
                    onClick={() => {
                      onAddFieldToList(item);
                      setVisibleDropDownMoreField(true);
                      setVisibleSearchMoreField(false);
                      setTimeout(() => setVisibleSearchMoreField(true), 0);
                    }}
                  >
                    {title}
                  </div>
                );
              })
            ) : (
              <div key="empty" className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        </div>
      </>
    );

    return (
      <Dropdown
        open={visibleDropDownMoreField}
        placement="bottomRight"
        menu={{
          items: [{ key: 'menu', label: MoreFieldMenus, className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        overlayStyle={{ width: 250 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={open => setVisibleDropDownMoreField(open)}
      >
        <Tooltip placement="top" title={t('process.addAFieldToList')} destroyTooltipOnHide={true}>
          <Button type="primary" icon={<PlusOutlined />} className="ml-3"></Button>
        </Tooltip>
      </Dropdown>
    );
  };

  return (
    <div className={`c-config-grid-view-contents ${className}`} {...rest}>
      <Row justify="space-between" align="bottom">
        <Col>
          <Row align="bottom">
            <div className="mb-3 mr-4">
              <div className="font-weight-medium mb-1">{t('process.entriesPerPage')}</div>
              <Select
                value={page}
                optionFilterProp="title"
                placeholder={t('common.pleaseSelect')}
                style={{ minWidth: 150 }}
                onSelect={onChangePage}
              >
                {PAGE_SIZES.map(page => (
                  <Select.Option key={page} title={`${page} / ${t('common.page')}`} value={page}>
                    {`${page} / ${t('common.page')}`}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="mb-3 mr-4">
              <div className="font-weight-medium mb-1">{t('process.defaultSortAttribute')}</div>
              <Select
                value={sortAttribute}
                options={
                  Array.isArray(fieldList) && fieldList.length
                    ? fieldList.map(item => ({
                        label: i18n.exists(`akaat:workItem.${item.refName}`)
                          ? t(`workItem.${item.refName}`)
                          : item.name,
                        value: item.refName
                      }))
                    : []
                }
                optionFilterProp="title"
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder={t('common.pleaseSelect')}
                style={{ minWidth: 160 }}
                onSelect={onChangeSortAttribute}
              />
            </div>

            <div className="mb-3">
              <div className="font-weight-medium mb-1">{t('process.defaultSortOrder')}</div>
              <Select
                value={sortOrder}
                options={[
                  { label: 'ASC', value: 'ASC' },
                  { label: 'DESC', value: 'DESC' }
                ]}
                optionFilterProp="title"
                placeholder={t('common.pleaseSelect')}
                style={{ minWidth: 120 }}
                onSelect={onChangeSortOrder}
              />
            </div>
          </Row>
        </Col>

        <Col>
          <div className="mb-3">{renderMoreFieldDropdown()}</div>
        </Col>
      </Row>

      {(workticketId === WORK_ITEM_TESTCASE_ID || workticketId === WORK_ITEM_TEST_RUN_ID) && (
        <Row align="middle" className="mb-2">
          <Switch className="mr-2" size="small" checked={visibleTestSteps} onChange={onChangeVisibleTestSteps} />
          <span className="font-weight-medium">{t('process.showTestStep')}</span>
        </Row>
      )}

      <Row align="middle" className="mb-2">
        <Switch
          className="mr-2"
          size="small"
          checked={visibleDetailRelation}
          onChange={onChangeVisibleDetailRelation}
        />
        <span className="font-weight-medium">{t('process.showDetailsOfRelationField')}</span>
      </Row>

      {workticketId === WORK_ITEM_TEST_RUN_ID && isFromProcessSettingModule && (
        <Row align="middle" className="mb-3">
          <Switch className="mr-2" size="small" checked={allowQuickExecute} onChange={onChangeAllowQuickExecute} />
          <span className="font-weight-medium">
            {t('process.allowQuickExecuteTestExecution')}
            <Tooltip
              title={<SafeInnerHtml html={t('process.allowQuickExecuteTestExecutionNote')} />}
              placement="top"
              destroyTooltipOnHide={true}
            >
              <InfoCircleOutlined className="text-gray ml-2" />
            </Tooltip>
          </span>
        </Row>
      )}

      <FieldList shownFields={shownFields} onReOrderField={onReOrderField} onRemoveFieldToList={onRemoveFieldToList} />
    </div>
  );
};
