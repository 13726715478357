import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Dropdown, Popconfirm, Row } from 'antd';
import { CopyFilled, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import { ABOVE, BELOW } from '../test-step-draggable-row-table-constants';
import { Paste } from '../../../assets/svg-icons';

const RowAction = ({
  row,
  index,
  clipboardStepKeys = [],
  inClipboard,
  onCopyOne,
  onPasteOne,
  onDeleteMultipleRows
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Paste menus
   */
  const pasteMenus = useMemo(() => {
    const items = [
      {
        key: 'pasteRowsAbove',
        label: t('common.pasteRowsAbove'),
        disabled: !clipboardStepKeys.length,
        onClick: () => onPasteOne({ row, index, type: ABOVE })
      },
      {
        key: 'pasteRowsBelow',
        label: t('common.pasteRowsBelow'),
        disabled: !clipboardStepKeys.length,
        onClick: () => onPasteOne({ row, index, type: BELOW })
      }
    ];

    return items;
  }, [row, index, clipboardStepKeys]);

  return (
    <Row justify="end" align="middle" wrap={false} className="c-row-action px-0" style={{ minWidth: 24 * 3 }}>
      <div className="btn-action-editable-cell-wrapper" style={{ minWidth: 24 }}>
        {!row?.isNew && (
          <Tooltip
            title={inClipboard ? t('common.copied') : t('common.copy')}
            placement="top"
            destroyTooltipOnHide={true}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              icon={inClipboard ? <CopyFilled /> : <CopyOutlined />}
              type="link"
              size="small"
              className="btn-action-editable-cell border-0 bg-transparent ml-1"
              onClick={() => onCopyOne({ row, inClipboard })}
            />
          </Tooltip>
        )}
      </div>

      <div className="btn-action-editable-cell-wrapper" style={{ minWidth: 24 }}>
        {!row?.isNew && (
          <Dropdown
            menu={{ items: pasteMenus, selectable: false }}
            placement="bottom"
            trigger={['click']}
            destroyPopupOnHide={true}
            disabled={!clipboardStepKeys.length}
          >
            <Tooltip
              title={t('common.paste')}
              placement="top"
              destroyTooltipOnHide={true}
              overlayStyle={{ pointerEvents: 'none' }}
            >
              <Button
                size="small"
                type="link"
                icon={<Paste />}
                className="btn-action-editable-cell border-0 bg-transparent ml-1"
                disabled={!clipboardStepKeys.length}
              />
            </Tooltip>
          </Dropdown>
        )}
      </div>

      <div className="btn-action-editable-cell-wrapper" style={{ minWidth: 24 }}>
        <Popconfirm
          title={t('message.areYouSureDelete2')}
          placement="top"
          zIndex={1080}
          okText={t('common.yes')}
          cancelText={t('common.no')}
          onConfirm={() => onDeleteMultipleRows([row])}
        >
          <Tooltip
            title={t('common.delete')}
            placement="top"
            destroyTooltipOnHide={true}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              icon={<DeleteOutlined />}
              danger
              type="link"
              size="small"
              className="btn-action-editable-cell border-0 bg-transparent ml-1"
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        </Popconfirm>
      </div>
    </Row>
  );
};

export default RowAction;
