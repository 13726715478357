import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../common';

export default {
  /**
   * State
   */
  tenantProjectList: null, // All of projects in current tenant
  loadingTenantProjectList: false,

  /**
   * Action: Set tenant project list
   */
  setTenantProjectList: action((state, payload) => {
    if (!(state?.tenantProjectList !== undefined)) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.tenantProjectList = [];
    }

    state.tenantProjectList = payload;
  }),

  /**
   * Action: Set loading tenant project list
   */
  setLoadingTenantProjectList: action((state, payload) => {
    if (!(state?.loadingTenantProjectList !== undefined)) return;

    state.loadingTenantProjectList = payload;
  }),

  /**
   * Action: Call api to get tenant project list
   */
  getTenantProjectList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTenantProjectList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}/projects`;
      const res = await Http.get(url).then(res => res.data);

      action.setTenantProjectList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantProjectList(false);
    }
  })
};
