import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Layout, Row } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { PRODUCT_KEY_MANAGER, PRODUCT_KEY_TESTENGINE, ROLE_TENANT_OWNER } from '../constants';
import { RouterLoading, MainHeader, MainFooter } from '../components';

export default ({ expiredProductlicense }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  /**
   * Compute product name
   */
  const productName = useMemo(() => {
    return expiredProductlicense === PRODUCT_KEY_MANAGER
      ? t('akaat:subscription.testManagement')
      : expiredProductlicense === PRODUCT_KEY_TESTENGINE
      ? t('akaat:subscription.testEngine')
      : '';
  }, [t, expiredProductlicense]);

  return (
    <Suspense fallback={<RouterLoading />}>
      <Layout className="l-expired-license">
        <MainHeader />

        <Layout className="main-body">
          <Row justify="center" align="middle" className="px-2" style={{ minHeight: 'calc(100vh - 98px)' }}>
            <div className="py-5 mx-auto" style={{ maxWidth: 500 }}>
              <div className="font-weight-medium font-size-18 text-center mb-2">
                <CloseCircleOutlined className="vertical-align-middle font-size-20 text-danger mr-1" />{' '}
                {t('akaat:subscription.yourLicenseDecreased')}
              </div>

              <div className="font-size-16">
                {globalTenant?.currentUserRole?.roleKey === ROLE_TENANT_OWNER
                  ? t('akaat:subscription.yourLicenseDecreasedNoteForTenantOwner', { product: productName })
                  : t('akaat:subscription.yourLicenseDecreasedNoteForUser', { product: productName })}
              </div>
            </div>
          </Row>
          <MainFooter />
        </Layout>
      </Layout>
    </Suspense>
  );
};
