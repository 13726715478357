import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, DatePicker } from 'antd';

const DatetimeFormItem = ({ field, formItemName, inputProps, restFormItem, restField }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <DatePicker {...inputProps} {...restField} />
    </Form.Item>
  );
};

export default DatetimeFormItem;
