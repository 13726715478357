import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: null,
  total: 0,
  editingTemplate: null,
  query: null,
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set template list
   */
  setTemplateList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing template
   */
  setEditingTemplate: action((state, payload) => {
    if (state?.editingTemplate === undefined) return;

    state.editingTemplate = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get template list
   */
  getTemplateList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.IMPORT_MAPPING}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTemplateList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get template by Id
   */
  getTemplateById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTemplate(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create template
   */
  createTemplate: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT_MAPPING}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageCreatingTemplate'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update template
   */
  updateTemplate: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) throw new Error('No ID');

      const { _id } = payload;
      delete payload._id;

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${_id}`;
      const res = await Http.put(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageUpdatingTemplate'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete template
   */
  deleteTemplate: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('No ID');

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageDeletingTemplate'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  })
};
