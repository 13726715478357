import React from 'react';

import { Error404 } from '../components';

export default () => {
  return (
    <div className="l-blank">
      <Error404 />
    </div>
  );
};
