import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Dropdown, Popconfirm, Row } from 'antd';
import { CopyFilled, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

import { ABOVE, BELOW } from '../test-step-draggable-row-table-constants';
import { Paste } from '../../../assets/svg-icons';

const TopToolBtns = ({
  selectedRows,
  clipboardSteps,
  clipboardStepKeys,
  setClipboardSteps,
  onPasteMulti,
  onDeleteMultipleRows
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Has selected rows
   * Has clipboard steps
   */
  const hasSelectedRows = useMemo(() => Array.isArray(selectedRows) && selectedRows.length > 0, [selectedRows]);
  const hasClipboardSteps = useMemo(() => Array.isArray(clipboardSteps) && clipboardSteps.length > 0, [clipboardSteps]);

  /**
   * Has copied multi step
   */
  const hasCopiedMultiStep = useMemo(() => {
    if (!hasSelectedRows || !hasClipboardSteps) return false;

    const isCopiedMulti =
      clipboardStepKeys.length === selectedRows.length && selectedRows.every(s => clipboardStepKeys.includes(s?.key));

    return isCopiedMulti;
  }, [hasSelectedRows, hasClipboardSteps, clipboardStepKeys, selectedRows]);

  /**
   * Paste menus
   */
  const pasteMenus = useMemo(() => {
    const items = [
      {
        key: 'pasteRowsAbove',
        label: t('common.pasteRowsAbove'),
        disabled: !hasCopiedMultiStep,
        onClick: () => onPasteMulti(ABOVE)
      },
      {
        key: 'pasteRowsBelow',
        label: t('common.pasteRowsBelow'),
        disabled: !hasCopiedMultiStep,
        onClick: () => onPasteMulti(BELOW)
      }
    ];

    return items;
  }, [hasCopiedMultiStep]);

  return (
    <Row align="middle" style={{ position: 'absolute', top: 0, left: 0 }}>
      <Tooltip
        title={hasCopiedMultiStep ? t('common.copied') : t('common.copy')}
        placement="top"
        destroyTooltipOnHide={true}
        overlayStyle={{ pointerEvents: 'none' }}
      >
        <Button
          type="link"
          icon={hasCopiedMultiStep ? <CopyFilled /> : <CopyOutlined />}
          className="border-transparent mr-1"
          disabled={!hasSelectedRows}
          onClick={() => setClipboardSteps(hasClipboardSteps ? [] : selectedRows)}
        />
      </Tooltip>

      <Dropdown
        menu={{ items: pasteMenus, selectable: false }}
        placement="bottom"
        trigger={['click']}
        destroyPopupOnHide={true}
        disabled={!hasCopiedMultiStep}
      >
        <Tooltip
          title={t('common.paste')}
          placement="top"
          destroyTooltipOnHide={true}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button type="link" icon={<Paste />} className="border-transparent" disabled={!hasCopiedMultiStep} />
        </Tooltip>
      </Dropdown>

      <Popconfirm
        title={t('message.areYouSureDelete', { name: t('workItem.testSteps') })}
        placement="top"
        okText={t('common.yes')}
        cancelText={t('common.no')}
        zIndex={1080}
        disabled={!hasSelectedRows}
        onConfirm={() => onDeleteMultipleRows(selectedRows)}
      >
        <Tooltip placement="top" title={t('common.delete')} overlayStyle={{ pointerEvents: 'none' }}>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            disabled={!hasSelectedRows}
            className="border-transparent"
          />
        </Tooltip>
      </Popconfirm>
    </Row>
  );
};

export default TopToolBtns;
