import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import OutsideClickHandler from 'react-outside-click-handler';
import { Row } from 'antd';

import { PRIORITIES, SS_LAST_SAVED_VALUE } from '../../../../constants';
import { getObjectByValue, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import PriorityKeyboardEvents from './priority-keyboard-events';
import PriorityFormItem from './priority-form-item';

const PriorityField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    const val = objectPath.get(row, field?.refName);
    return getObjectByValue(val, PRIORITIES);
  }, [row, field]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastOption = getObjectByValue(lastValue, PRIORITIES);

    return lastValue !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOption(opt);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, PRIORITIES)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      handleSetLastSavedValueToSession({ [formItemName]: opt?.value || null });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: opt?.value || null }, row, field });
      }
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    optionFilterProp: 'label',
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    placeholder: placeholder || t('common.search'),
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => {
      if (!row?.isNew) handleSave(option);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onChange: (val, option) => {
      if (row?.isNew) handleSave(option);
    },
    onClick: () => {
      if (!row?.isNew) handleOpen();
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const option = getCurrentOption(currentOption);
    const label = (
      <Row title={option?.label || ''} align="middle" wrap={false}>
        {option?.icon}
        <span className="text-truncate">{option?.label}</span>
      </Row>
    );

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value min-h-22 text-truncate">{label}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink row={row} field={field}>
          {label}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {label}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return label;
    }

    // Readonly
    else {
      return (
        <div className="cell-text cursor-default hide-after">
          <div className="cell-text-value min-h-22 text-truncate">{label}</div>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <PriorityFormItem
        field={field}
        formItemName={formItemName}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <PriorityKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{t('common.search')}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper priority-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new priority-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default PriorityField;
