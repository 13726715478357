import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreActions } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';
import { Modal } from 'antd';

import { REST_API_STATUS_CODE, SS_LAST_SAVED_VALUE, SYSTEM_FIELD_VERSION } from '../../../../constants';
import { checkValidField, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import TestcaseVersionFormItem from './testcase-version-form-item';
import { SafeInnerHtml } from '../../../safe-inner-html';

const TestcaseVersionField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For test case version store
  const createTestCaseVersion = useStoreActions(action => action.testcaseVersion.createTestCaseVersion);

  // Component state
  const [editingCell, setEditingCell] = useState(null);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => objectPath.get(row, field?.refName), [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      tableForm.setFieldsValue({ [formItemName]: getCurrentValue(defaultVal) });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultVal]);

  /**
   * Handle focus to input
   */
  const handleFocusToInput = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
    document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
  };

  /**
   * Create test case version
   */
  const handleCreateTestCaseVersion = async () => {
    if (!row?.key || !currentValue) return;

    try {
      const res = await createTestCaseVersion({
        checkExist: true,
        testCaseId: row._id,
        testCaseKey: row.key,
        [SYSTEM_FIELD_VERSION]: currentValue
      });

      // Revert to previous value
      if (res?.status === REST_API_STATUS_CODE.conflic) {
        const val = getCurrentValue(defaultVal);
        tableForm.setFieldsValue({ [formItemName]: val });

        handleClose(val);
      }

      // Success => Close
      else if (res?.status === REST_API_STATUS_CODE.success) {
        handleSetLastSavedValueToSession({ [formItemName]: currentValue });
        handleClose(currentValue);

        if (typeof onSave === 'function') onSave({}); // Don't emit anything
      }
    } catch (error) {
      console.error(error);
    } finally {
      handleFocusToInput();
    }
  };

  /**
   * Handle open
   */
  const handleOpen = () => {
    setEditingCell({ x, y });
  };

  /**
   * Handle close
   */
  const handleClose = val => {
    setCurrentValue(val);
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async (val, e) => {
    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !val)) {
      handleClose(defaultValue);
      return;
    }

    // For update existed row
    if (!row?.isNew && val !== defaultValue) {
      if (!document.querySelector('.c-confirm-upgrade-version-modal')) {
        e?.stopPropagation();

        Modal.confirm({
          title: (
            <SafeInnerHtml
              html={t('testRepo.noteUpgradeVersion', { version: val })}
              className="font-weight-normal font-size-14"
            />
          ),
          autoFocusButton: 'ok',
          maskClosable: true,
          okText: t('common.yes'),
          cancelText: t('common.no'),
          className: 'c-confirm-upgrade-version-modal disabled-outside-click',
          onOk: handleCreateTestCaseVersion,
          onCancel: () => {
            tableForm.setFieldsValue({ [formItemName]: defaultValue });
            handleClose(defaultValue);
            handleFocusToInput();
          }
        });
      }
    }

    // For update new row
    else if (row?.isNew && val !== defaultValue) {
      handleSetLastSavedValueToSession({ [formItemName]: val });

      if (typeof onSave === 'function') {
        onSave({ formData: { [field?.refName]: val }, row, field });
      }

      handleClose(val);
    }

    // Other
    else {
      handleClose(defaultValue);
    }
  };

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const confirmUpgradeVersionModal = document.querySelector('.c-confirm-upgrade-version-modal');
    if (confirmUpgradeVersionModal) return;

    const code = e.code;
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');
    const isEditing = triggerFocusElement?.classList.contains('editing');

    // Enter & isEditing => Open confirm
    if (isEditing && !hasFocused && (code === 'Enter' || code === 'NumpadEnter')) {
      handleSave(e?.target?.value, e);
    }
  };

  /**
   * Input props
   */
  const inputProps = {
    autoFocus: !row?.isNew,
    autoComplete: 'off',
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    placeholder: placeholder || t('common.enterValue'),
    onChange: e => setCurrentValue(e?.target?.value),
    onBlur: e => handleSave(e?.target?.value),
    onClick: () => {
      if (row?.isNew) return;

      const triggerFocusElement = document.querySelector(
        `.trigger-focus-element[data-form-item-name="${formItemName}"]`
      );
      triggerFocusElement?.classList.remove('focused');
    },
    onKeyDown: e => {
      if (!row?.isNew) handleKeyDown(e);
    },
    onPressEnter: e => {
      if (row?.isNew) handleSave(e?.target?.value);
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val = getCurrentValue(currentValue);

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={val || ''} className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value min-h-22 text-truncate">{val || ''}</span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={val || ''} row={row} field={field}>
          {val || ''}
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={val || ''} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            {val || ''}
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <span title={val || ''}>{val || ''}</span>;
    }

    // Readonly
    else {
      return (
        <div title={val || ''} className="cell-text cursor-default hide-after">
          <span className="cell-text-value min-h-22 text-truncate">{val || ''}</span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <TestcaseVersionFormItem
        x={x}
        y={y}
        field={field}
        tableForm={tableForm}
        formItemName={formItemName}
        inputProps={inputProps}
        setEditingCell={setEditingCell}
        handleSave={handleSave}
        closeAndSetLastValue={closeAndSetLastValue}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && renderFormItem()}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper testcase-version-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new testcase-version-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default TestcaseVersionField;
