import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Tooltip, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import {
  DATA_TYPE,
  COMPONENT_TYPE,
  OPERATION_MAPPING,
  UTC_DATE_FORMAT,
  SYSTEM_FIELD_VERSION,
  SYSTEM_FIELD_TEST_CONFIG,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_STATUS,
  JIRA_PLATFORM_ID,
  TESTMAN_PLATFORM_ID,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TEST_RESULT_ID,
  WORK_ITEM_TESTCASE_ID
} from '../../constants';
import {
  jsonParse,
  parseCodeQSKeepType,
  getKeyByValueInObject,
  convertEstimatedTimeToMinutes,
  reactSessionStorage
} from '../../common';
import useIntegrationSystem from '../../common/hooks/use-integration-system';
import { ClearFilter } from '../../assets/svg-icons';
import { MoreDropdown } from './more-dropdown';
import { FieldStringNumber } from './field-string-number';
import { FieldDate } from './field-date';
import { FieldSelect } from './field-select';
import { FieldStatus } from './field-status';
import { FieldSuggestion } from './field-suggestion';
import { FieldUser } from './field-user';
import { FieldTestPlanning } from './field-test-planning';
import { FieldFolder } from './field-folder';
import { FieldPriority } from './field-priority';
import { FieldRelation } from './field-relation';

import './style.scss';

export const BasicManagerSearch = ({
  workTicketId,
  fieldList,
  fieldConfig = {},
  filterProp,
  allowChangeQueryParamsUrl = true,
  noPageParam,
  loading,
  className = '',
  onChangeFilter,
  onSearch,
  functionsInside,
  onBeforeSearchChange,
  ...rest
}) => {
  useIntegrationSystem();
  const [searchForm] = Form.useForm();

  // For query params on url
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    filter: StringParam
  });

  // For language
  const [t, i18n] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const setSuggestion = useStoreActions(action => action.global.setSuggestion);

  // For integration store
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);
  const getWorkflowStatusList = useStoreActions(action => action.jiraIntegration.getWorkflowStatusList);
  const getPriorityList = useStoreActions(action => action.jiraIntegration.getPriorityList);
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For test data list
  const loadingFieldList = useStoreState(state => state.global.loadingFieldList);

  // Component state
  const [isSetCurrentValues, setIsSetCurrentValues] = useState(false);
  const [currentValues, setCurrentValues] = useState([]); // Example: [{ referenceField: 'status', value: ['PASS'] }]
  const [currentMoreFieldRefNames, setCurrentMoreFieldRefNames] = useState([]);
  const [filter, setFilter] = useState('');
  const [fieldData, setFieldData] = useState([]);
  const [defaultShow, setDefaultShow] = useState([]);
  const [defaultSystem, setDefaultSystem] = useState();

  /**
   * Is test ticket
   */
  const isTestTicket = useMemo(() => {
    return (
      workTicketId === WORK_ITEM_TESTCASE_ID ||
      workTicketId === WORK_ITEM_TEST_RUN_ID ||
      workTicketId === WORK_ITEM_TEST_RESULT_ID
    );
  }, [workTicketId]);

  /**
   * Set functions inside
   * For: Call child function from parent component
   */
  useEffect(() => {
    if (functionsInside) {
      functionsInside.current = {
        handleSearchChange
      };
    }
  }, [functionsInside]);

  /**
   * Handle search change
   */
  const handleSearchChange = (filter, moreInfo) => {
    if (functionsInside && typeof onBeforeSearchChange === 'function' && !moreInfo?.isConfirmedBeforeChange) {
      onBeforeSearchChange(filter);
      return;
    }

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter });
    } else if (typeof onSearch === 'function') {
      onSearch({ filterString: filter });
    }

    if (typeof onChangeFilter === 'function') onChangeFilter(filter);
  };

  /**
   * Set default system
   */
  useEffect(() => {
    if (!(Array.isArray(integrationSystemList) && integrationSystemList.length)) return;

    const newDefaultSystem = integrationSystemList?.find(item => item?.id === TESTMAN_PLATFORM_ID);

    if (!newDefaultSystem?.id) return;

    if (integrationSystemList?.some(item => item?.id === JIRA_PLATFORM_ID)) {
      getWorkflowStatusList();
      getPriorityList();
      getAssignableUserList({
        username: ''
      });
    }

    setDefaultSystem(newDefaultSystem?.id);
  }, [integrationSystemList]);

  /**
   * Get data for jira
   */
  useEffect(() => {
    if (!integrationSystemList?.some(item => item?.id === JIRA_PLATFORM_ID)) return;

    getWorkflowStatusList();
    getPriorityList();
    getAssignableUserList({
      username: ''
    });
  }, [integrationSystemList]);

  /**
   * Set field list
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) return;

    let allField = [];
    let defaultList = [];

    fieldList.forEach(field => {
      if (field.invisible) return;

      const newField = { ...field };

      if (field.componentType === COMPONENT_TYPE.RELATION && field.refName === SYSTEM_FIELD_LATEST_RESULT) {
        newField.componentType = COMPONENT_TYPE.STATUS;
        newField.dataType = DATA_TYPE.STATUS;
      }

      if (field.componentType === SYSTEM_FIELD_STATUS && field.workTicketId) {
        newField.data = {
          ...newField.data,
          ...ticketListData[field.workTicketId]?.workFlow,
          workTicketId: field.workTicketId
        };
      }
      if (
        field.data?.fieldValue &&
        (field.dataType === DATA_TYPE.USER ||
          field.dataType === DATA_TYPE.STATUS ||
          field.refName === SYSTEM_FIELD_TEST_CONFIG)
      ) {
        let newRefName = `${field.refName}.${field.data.fieldValue}`;

        if (field.refName === SYSTEM_FIELD_LATEST_RESULT) {
          newField.componentType = COMPONENT_TYPE.STATUS;
          newField.dataType = DATA_TYPE.STATUS;
          newRefName = `${field.refName}.${field.data.fieldValue}`;
        }

        allField.push({ ...newField, refName: newRefName, originRefName: field.refName });
      } else {
        allField.push({ ...newField, originRefName: field.refName });
      }
    });

    if (!Array.isArray(fieldConfig?.defaultShow) || !fieldConfig?.defaultShow?.length) return;

    defaultList = fieldConfig.defaultShow
      .map(field => {
        return {
          ...allField.find(d => d.originRefName === field.refName || d.refName === field.refName),
          isDefaultSearch: true
        };
      })
      .filter(field => field?.refName);

    setDefaultShow(defaultList);
    setFieldData(allField);
  }, [fieldList, fieldConfig]);
  /**
   * Handle set current values
   */
  const handleSetCurrentValues = async params => {
    if (!params || !(Array.isArray(fieldData) && fieldData.length)) return;

    setFilter(params.filter);

    // Parse value filter keep type
    const filterObj = parseCodeQSKeepType(params.filter);
    const newCurrentValues = [];

    for (let index = 0; index < Object.keys(filterObj).length; index++) {
      const key = Object.keys(filterObj)[index];
      const filterValue = filterObj[key];

      if (key === OPERATION_MAPPING.and) {
        filterValue.forEach(value => {
          const field = fieldList.find(field => field.refName === Object.keys(value)[0]);

          if (!field) return;

          const operationLabel = getKeyByValueInObject('$and', OPERATION_MAPPING);

          newCurrentValues.push({
            refName: field.refName,
            value,
            componentType: field.componentType,
            operation: operationLabel
          });
        });
        continue;
      }

      const found = fieldData.find(fi => fi.refName === key);

      if (!found) return null;

      if (JSON.stringify(filterValue) == JSON.stringify(OPERATION_MAPPING.isNull)) {
        newCurrentValues.push({
          refName: key,
          value: '0',
          componentType: found.componentType,
          operation: 'isNull'
        });
      }

      // For string, number
      else if (
        (found.componentType === COMPONENT_TYPE.STRING ||
          found.componentType === COMPONENT_TYPE.NUMBER ||
          found.componentType === COMPONENT_TYPE.HTML ||
          found.componentType === COMPONENT_TYPE.TIME_TRACKING) &&
        filterValue !== null &&
        typeof filterValue === 'object' &&
        Object.keys(filterValue).length
      ) {
        const newValue = /\.\*(.*)\.\*/.exec(Object.values(filterValue)[0])?.[1] ?? Object.values(filterValue)[0];
        const operationValue = Object.keys(filterValue)[0];
        const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

        newCurrentValues.push({
          refName: key,
          value: typeof newValue === 'object' ? Object.values(newValue) : newValue,
          componentType: found.componentType,
          operation: operationLabel
        });
      }

      // For date, datetime
      else if (found.componentType === COMPONENT_TYPE.DATE || found.componentType === COMPONENT_TYPE.DATE_TIME) {
        const lteValue = Object.keys(filterValue)?.includes('$lte') ? filterValue?.$lte : null;
        const gteValue = Object.keys(filterValue)?.includes('$gte') ? filterValue?.$gte : null;
        const ltValue = filterValue?.['$not']
          ? Object.keys(filterValue?.['$not'])?.includes('$lt')
            ? filterValue?.['$not']?.$lt
            : null
          : null;
        const gtValue = filterValue?.['$not']
          ? Object.keys(filterValue?.['$not'])?.includes('$gt')
            ? filterValue?.['$not']?.$gt
            : null
          : null;

        const newValue = Object.values(filterValue)[0];
        const newDate = newValue && moment(newValue).isValid() ? moment(newValue).local() : null;

        // For in range
        if (lteValue && moment(lteValue).isValid() && gteValue && moment(gteValue).isValid()) {
          newCurrentValues.push({
            refName: key,
            value: [moment(gteValue).local(), moment(lteValue).local()],
            componentType: found.componentType,
            operation: 'in range'
          });
        }

        // For not in range
        else if (ltValue && moment(ltValue).isValid() && gtValue && moment(gtValue).isValid()) {
          newCurrentValues.push({
            refName: key,
            value: [moment(gtValue).local(), moment(ltValue).local()],
            componentType: found.componentType,
            operation: 'not in range'
          });
        }

        // For other: Example: =, <, >, <=, >=
        else if (newDate) {
          const operationValue = Object.keys(filterValue)[0];
          const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

          newCurrentValues.push({
            refName: key,
            value: newDate,
            componentType: found.componentType,
            operation: operationLabel
          });
        } else {
        }
      }

      // For picklist
      else if (
        (found.componentType === COMPONENT_TYPE.PICKLIST ||
          found.componentType === COMPONENT_TYPE.SUGGESTION ||
          found.componentType === COMPONENT_TYPE.RELATION ||
          found.componentType === COMPONENT_TYPE.PRIORITY ||
          found.componentType === COMPONENT_TYPE.OPTION ||
          found.componentType === COMPONENT_TYPE.STATUS ||
          found.componentType === COMPONENT_TYPE.USER) &&
        Array.isArray(filterValue.$in) &&
        filterValue.$in.length
      ) {
        const operationLabel = getKeyByValueInObject('$in', OPERATION_MAPPING);

        newCurrentValues.push({
          refName: key,
          value: filterValue.$in,
          componentType: found.componentType,
          operation: operationLabel
        });

        if (found.data?.url && found.originRefName !== SYSTEM_FIELD_LATEST_RESULT) {
          await getSuggestion({
            refName: key,
            url: found.data.url,
            page: null,
            limit: null,
            order: null,
            group: found.data.fieldValue,
            ...(found.componentType === COMPONENT_TYPE.RELATION
              ? { filter: { 'workTicketType.id': found.lookup.workTicketId } }
              : {})
          });
        }
      } else {
      }
    }

    setCurrentValues(newCurrentValues);
    handleSetCurrentMoreFieldRefNames(filterObj);
  };

  /**
   * Handle set current more field ref names
   */
  const handleSetCurrentMoreFieldRefNames = filterObj => {
    const newCurrentMoreFieldRefNames =
      Array.isArray(currentMoreFieldRefNames) && currentMoreFieldRefNames.length ? [...currentMoreFieldRefNames] : [];
    const newFieldData = Array.isArray(fieldData) && fieldData.length ? [...fieldData] : [];

    if (filterObj) {
      Object.keys(filterObj).forEach(key => {
        const found = newFieldData.find(
          item => !defaultShow?.some(s => s.refName === item.refName) && item.refName === key
        );

        if (key === OPERATION_MAPPING.and) {
          filterObj[key].map(obj => {
            if (!defaultShow?.some(s => s.refName == Object.keys(obj)[0])) {
              const folderField = newFieldData.find(field => field.refName === Object.keys(obj)[0]);
              if (folderField) {
                newCurrentMoreFieldRefNames.push(folderField.refName);
              }
            }
          });
        } else if (found && !newCurrentMoreFieldRefNames.includes(found.refName)) {
          newCurrentMoreFieldRefNames.push(found.refName);
        } else {
        }
      });
    }

    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
  };

  /**
   * Watching change of queryParams on url
   */
  useEffect(() => {
    if (!queryParams || !(Array.isArray(fieldData) && fieldData.length)) return;

    let newQuery = {};
    const newQueryParams = allowChangeQueryParamsUrl ? queryParams : { filter: filterProp };

    if (newQueryParams?.filter) {
      newQuery.filter = newQueryParams?.filter;
    }

    if (!isSetCurrentValues) {
      handleSetCurrentValues(newQuery);
      setIsSetCurrentValues(true);

      return;
    } else if (
      // ==========> For change query params on url
      newQuery?.filter !== filter
    ) {
      handleSetCurrentValues(newQuery);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, searchForm, queryParams, filterProp, filter, isSetCurrentValues, setQueryParams]);

  /**
   * Validate valid JSON
   *
   * @param {object} rule - Rule of validation
   * @param {string} value - Value of input
   */
  const validateValidJson = async (rule, value) => {
    if (!value) return Promise.resolve();

    value = value.trim();

    if (!jsonParse(value)) return Promise.reject(t('message.invalidFormat'));

    return Promise.resolve();
  };

  /**
   * Convert to filter string
   */
  const convertToFilterString = values => {
    if (!(Array.isArray(values) && values.length)) return;

    const queryObj = {};

    for (let index = 0; index < values.length; index++) {
      const item = values[index];

      const operation = OPERATION_MAPPING[item.operation];
      let value = item.value;

      if (!operation) {
        queryObj[item.refName] = value;
        break;
      }

      if (item.operation !== 'isNull' && (value === null || value === undefined)) return;

      if (item.componentType === COMPONENT_TYPE.DATE) {
        if (Array.isArray(value) && value.every(sub => sub && moment(sub).isValid())) {
          value = value.map(val => moment.utc(val).format(UTC_DATE_FORMAT));
        } else if (value && moment(value).isValid()) {
          const currentVal = moment(value).clone();

          value = moment(currentVal)
            .add(moment().format('HH'), 'hours')
            .add(moment().format('mm'), 'minutes')
            .add(moment().format('ss'), 'seconds');

          value = moment.utc(value).format(UTC_DATE_FORMAT);
        }
      }

      if (item.componentType === COMPONENT_TYPE.DATE_TIME) {
        if (Array.isArray(value) && value.every(sub => sub && moment(sub).isValid())) {
          value = value.map(val => moment.utc(val).format('YYYY-MM-DDTHH:mm[Z]'));
        } else if (value && moment(value).isValid()) {
          value = moment.utc(value).format('YYYY-MM-DDTHH:mm[Z]');
        }
      }

      if (item.componentType === COMPONENT_TYPE.DATE_TIME || item.componentType === COMPONENT_TYPE.DATE) {
        if (item.operation === 'in range' && Array.isArray(item.value) && item.value.length) {
          value = {
            $gte: value[0], // >=
            $lte: value[1] // <=
          };
        } else if (item.operation === 'not in range' && Array.isArray(item.value) && item.value.length) {
          value = {
            $not: {
              $gt: value[0], // <
              $lt: value[1] // >
            }
          };
        } else {
          value = {
            [OPERATION_MAPPING[item.operation]]: value
          };
        }

        queryObj[item.refName] = value;

        continue;
      }

      if (item.componentType === COMPONENT_TYPE.STRING) {
        if (item.operation === '~') {
          value = { $regex: `.*${value}.*`, $options: 'i' };
        } else {
          value = { [operation]: value };
        }

        queryObj[item.refName] = value;

        continue;
      }

      // for field nest operator under operator(example: {$in:[{$regex}]})
      if (operation === OPERATION_MAPPING.and) {
        if (queryObj[operation]) {
          queryObj[operation] = [...queryObj[operation], value];
        } else queryObj[operation] = [value];
        continue;
      } else if (item.operation === 'isNull') {
        value = operation;
      } else if (item.operation === '~') {
        value = { $regex: `.*${value}.*`, $options: 'i' };
      } else if (item.componentType === COMPONENT_TYPE.TIME_TRACKING) {
        value = { [operation]: convertEstimatedTimeToMinutes(value) };
      } else {
        value = { [operation]: value };
      }

      queryObj[item.refName] = value;
    }

    const newFilter = qs.stringify(queryObj, { encode: false }) || undefined;

    return newFilter;
  };

  /**
   * On apply
   */
  const onApply = ({ field, value, operation }) => {
    if (!field?.refName) return;

    let values = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];
    const handleRefName = refName => {
      if (refName === 'latestResult') {
        return 'latestResult.status.id'; //check in db(test-runs table to see status id)
      }
      return refName;
    };

    const newVal = {
      refName: handleRefName(field.refName),
      componentType: field.componentType,
      value,
      operation
    };

    values = [...values].filter(item => item.refName !== field.refName); // Remove first
    values = [...values, newVal]; // Then, add new
    const newFilterString = convertToFilterString(values);

    handleSearchChange(newFilterString);
  };

  /**
   * Handle clear for more fields dropdown
   */
  const handleClearForMoreFieldsDropdown = (clearedRefNames, hasSetCurrentMoreFieldRefNames) => {
    if (!(Array.isArray(clearedRefNames) && clearedRefNames.length)) return;

    // Set current more field ref names
    if (hasSetCurrentMoreFieldRefNames) {
      const newCurrentMoreFieldRefNames =
        Array.isArray(currentMoreFieldRefNames) && currentMoreFieldRefNames.length
          ? [...currentMoreFieldRefNames].filter(refName => !clearedRefNames.includes(refName))
          : [];

      setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
    }

    // Change filter
    const values =
      Array.isArray(currentValues) && currentValues.length
        ? [...currentValues].filter(item => !clearedRefNames.includes(item?.refName))
        : [];

    const newFilterString = convertToFilterString(values);

    handleSearchChange(newFilterString);
  };

  /**
   * Handle ok for more fields dropdown
   */
  const handleOkForMoreFieldsDropdown = selectedFields => {
    const originCurrentMoreFieldRefNames =
      Array.isArray(currentMoreFieldRefNames) && currentMoreFieldRefNames.length ? [...currentMoreFieldRefNames] : [];

    // Set current more field ref names
    const newCurrentMoreFieldRefNames =
      Array.isArray(selectedFields) && selectedFields.length ? [...selectedFields] : [];
    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);

    // On clear
    const clearedRefNames = originCurrentMoreFieldRefNames.filter(
      refName => !newCurrentMoreFieldRefNames.includes(refName)
    );
    handleClearForMoreFieldsDropdown(clearedRefNames, false);
  };

  /**
   * On remove from more fields
   * Click to: "Remove this condition"
   */
  const onRemoveThisConditionFromMoreFields = field => {
    if (!field?.refName) return;

    const newCurrentMoreFieldRefNames =
      Array.isArray(currentMoreFieldRefNames) && currentMoreFieldRefNames.length
        ? [...currentMoreFieldRefNames].filter(item => item !== field.refName)
        : [];

    onClearValueOfAField(field);
    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
  };

  /**
   * On clear all filters
   */
  const onClearAllFilters = () => {
    handleSearchChange(undefined);
  };

  /**
   * On clear value of a field
   */
  const onClearValueOfAField = field => {
    if (!field?.refName) return;

    onApply({ field, value: undefined });
  };

  /**
   * Render field item
   */
  const renderFieldItem = field => {
    if (!field?.refName) return null;

    if (field.refName === SYSTEM_FIELD_VERSION) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (
      field.componentType === COMPONENT_TYPE.STRING ||
      field.componentType === COMPONENT_TYPE.NUMBER ||
      field.componentType === COMPONENT_TYPE.HTML ||
      field.componentType === COMPONENT_TYPE.TIME_TRACKING
    ) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.DATE || field.componentType === COMPONENT_TYPE.DATE_TIME) {
      return (
        <FieldDate
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.PICKLIST || field.componentType === COMPONENT_TYPE.OPTION) {
      return (
        <FieldSelect
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.PRIORITY) {
      return (
        <FieldPriority
          isTestTicket={isTestTicket}
          defaultSystem={defaultSystem}
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.TEST_PLANNING) {
      return (
        <FieldTestPlanning
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'and' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.FOLDER) {
      return (
        <FieldFolder
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'and' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.STATUS) {
      return (
        <FieldStatus
          isTestTicket={isTestTicket}
          field={field}
          defaultSystem={defaultSystem}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.SUGGESTION) {
      return (
        <FieldSuggestion
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.RELATION) {
      return (
        <FieldRelation
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.USER) {
      return (
        <FieldUser
          isTestTicket={isTestTicket}
          defaultSystem={defaultSystem}
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }
  };

  /**
   * Render more button
   */
  const renderMoreButton = () => {
    const optionsMoreData =
      Array.isArray(fieldData) && fieldData.length
        ? fieldData
            .filter(item => !defaultShow.some(show => show.refName === item.refName))
            .map(item => {
              return {
                label: i18n.exists(`akaat:workItem.${item.originRefName}`)
                  ? t(`workItem.${item.originRefName}`)
                  : item.name,
                value: item.refName
              };
            })
        : [];

    return (
      <MoreDropdown
        currentValues={currentMoreFieldRefNames}
        originOptions={optionsMoreData}
        onOk={handleOkForMoreFieldsDropdown}
        onClear={val => handleClearForMoreFieldsDropdown(val, true)}
      />
    );
  };

  /**
   * Render right button
   */
  const RightButton = () => {
    return (
      <Row align="middle">
        <Tooltip title={t('search.clearAllFilters')} placement="topRight" destroyTooltipOnHide={true}>
          <Button
            type="link"
            className="border-transparent text-hover-dark-primary line-height-1 my-1 ml-2 px-0"
            onClick={onClearAllFilters}
          >
            <ClearFilter className="font-size-18" />
          </Button>
        </Tooltip>
      </Row>
    );
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setSuggestion([]);
    };
  }, [setSuggestion]);

  return (
    <div className={`c-basic-manager-search ${className}`} {...rest}>
      <Row>
        <Col flex="1 1 auto" className="col-fields">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingFieldList}>
            <div>
              <Row gutter="20">
                <Col flex="1 1 auto">
                  {/* For default fields */}
                  {Array.isArray(defaultShow) && defaultShow.length > 0 && (
                    <Row align="middle">
                      {defaultShow
                        .filter(item => !currentMoreFieldRefNames.includes(item.refName))
                        .map(item => (
                          <div key={item.refName} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}

                      <div className="my-1 mr-2">{renderMoreButton()}</div>
                    </Row>
                  )}

                  {/* For more fields */}
                  {Array.isArray(fieldData) &&
                    fieldData.length > 0 &&
                    Array.isArray(currentMoreFieldRefNames) &&
                    currentMoreFieldRefNames.length > 0 && (
                      <Row align="middle">
                        {fieldData
                          .filter(item => currentMoreFieldRefNames.includes(item.refName))
                          .map(item => (
                            <div key={item.refName} className="my-1 mr-2">
                              {renderFieldItem(item)}
                            </div>
                          ))}
                      </Row>
                    )}
                </Col>

                <Col>
                  <RightButton />
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
