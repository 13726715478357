import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

import { PRIORITIES } from '../../../../constants';

const PriorityFormItem = ({ field, formItemName, selectProps, restFormItem, restField }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Select {...selectProps} {...restField}>
        {PRIORITIES.map(item => (
          <Select.Option key={item.value} label={item.label} value={item.value}>
            {item.icon}
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PriorityFormItem;
