import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: null,
  query: null,
  editingNotification: null,
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set notification list
   */
  setNotificationList: action((state, payload) => {
    if (state?.data === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.data = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set editing notification
   */
  setEditingNotification: action((state, payload) => {
    if (state?.editingNotification === undefined) return;

    state.editingNotification = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get notification list
   */
  getNotificationList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.EVENT}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.EVENT_NOTIFICATION
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setNotificationList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get notification by Id
   */
  getNotificationById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('No ID');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.EVENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EVENT_NOTIFICATION}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingNotification(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to editing notification
   */
  updateNotification: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('No Id');

      action.setLoadingItem(true);
      action.setLoadingList(true);

      const url = `${ENDPOINTS.EVENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EVENT_NOTIFICATION}`;
      const res = await Http.post(url, payload).then(res => res.data);

      action.setEditingNotification(res?.data);
      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update channel
   */
  updateChannel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('No ID');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.EVENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EVENT_NOTIFICATION}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:notification.messageUpdatingChannel'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete channel
   */
  deleteChannel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('No ID');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.EVENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EVENT_NOTIFICATION}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:notification.messageDeletingChannel'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
