import React, { useEffect, useState } from 'react';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import SyncOutlined from '@ant-design/icons/SyncOutlined';

import './styles.scss';
import { Button, List, Progress, Tag } from 'antd';
import { SocketInstance } from '../../socket';
import { ORDER_BY_CREATED_AT_DESC, PAGE_SIZE_50, INVISIBLE_PROGRESS_BAR } from '../../constants';
import env from '../../env';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { reactLocalStorage } from '../../common';
import { useHistory } from 'react-router-dom';

function ProgressBar() {
  const history = useHistory();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  const getAgentList = useStoreActions(action => action.engineAgent.getAgentList);
  const agentList = useStoreState(state => state.engineAgent.data);

  const [isFrameVisible, setIsFrameVisible] = useState(true);
  const [socket, setSocket] = useState(false);
  const [visibleCloseProgress, setVisibleCloseProgress] = useState(false);
  const [progressList, setProgressList] = useState([]);

  const toggleFrameVisibility = () => {
    setIsFrameVisible(!isFrameVisible);
  };

  useEffect(() => {
    if (!Array.isArray(progressList) || !progressList.length) return;

    const remaining = progressList.reduce((accumulator, currentValue) => {
      return accumulator + (currentValue.total - currentValue.done);
    }, 0);

    if (remaining === 0) setVisibleCloseProgress(true);
  }, [progressList]);

  useEffect(() => {
    if (progressList.length) {
      getAgentList({
        page: 1,
        limit: PAGE_SIZE_50,
        order: ORDER_BY_CREATED_AT_DESC,
        orderType: 'object'
      });
    }
  }, [progressList]);

  useEffect(() => {
    const socketInstance = new SocketInstance(env.REACT_APP_AKAAT_MANAGER_NAMESPACE, null, {
      authorization: 'internal'
    }).socket();

    setSocket(socketInstance);
    socketInstance.connect();

    socketInstance.emit('testman-room', { tenantKey: tenantPath, projectKey: projectPath, action: 'progress' });

    socketInstance.on('progress', onProgressStreams);

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  /**
   * Handle stream progress to show progress bar
   * @param {*} value
   */
  const onProgressStreams = value => {
    setProgressList(value);
  };

  return (
    <div className="c-progress-bar">
      {progressList.length ? (
        <div className="wrap-float-widget pr-3">
          <div className="progress-frame-widget collapse">
            <div className="wrap-progress">
              <div
                className="header cursor-pointer"
                onClick={() => {
                  history.push({
                    pathname: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan`
                  });
                }}
              >
                <div>
                  <div className="font-weight-medium">Progress Bar</div>
                  {!isFrameVisible && (
                    <div className="font-weight-normal">{progressList.length} jobs are executing</div>
                  )}
                </div>

                <div style={{ gap: '6px', display: 'flex' }}>
                  {isFrameVisible ? (
                    <Button
                      size="small"
                      onClick={e => {
                        toggleFrameVisibility(e.target.value);
                        e.stopPropagation();
                      }}
                      icon={<DownOutlined className="collapse-button" />}
                    />
                  ) : (
                    <Button
                      size="small"
                      onClick={e => {
                        toggleFrameVisibility(e.target.value);
                        e.stopPropagation();
                      }}
                      icon={<UpOutlined className="collapse-button" />}
                    />
                  )}
                  <Button
                    size="small"
                    onClick={e => {
                      setProgressList([]);
                      e.stopPropagation();
                    }}
                    icon={<CloseOutlined className="collapse-button" />}
                  />
                </div>
              </div>
              {isFrameVisible ? (
                <div className="progress-frame-widget expanse">
                  <div className="font-weight-medium">Executing {progressList.length} jobs</div>

                  <List
                    // grid={{ gutter: 16, column: 1 }}
                    dataSource={progressList}
                    className="agent-list"
                    renderItem={item => (
                      <List.Item>
                        <span style={{ width: '120px' }}>
                          {item?.done}/{item?.total} Test cases
                        </span>
                        <Progress
                          showInfo={false}
                          strokeColor={'#4CAF50'}
                          percent={((item?.done * 100) / item?.total).toFixed(2)}
                        />
                        {(item?.done * 100) / item?.total === 100 ? (
                          <CheckCircleOutlined style={{ color: '#52c41a' }} />
                        ) : (
                          <SyncOutlined className="text-primary" spin />
                        )}

                        <div className="agent">
                          <Tag color="#108ee9">{agentList?.find(a => a.id === item.agentId)?.name}</Tag>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProgressBar;
