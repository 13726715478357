import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';

import { AttachmentPlus } from '../../assets/svg-icons';
import { BasicUploadMultipleFilesModal } from '../basic-upload-multiple-files-modal';

export const BasicAttachmentCount = ({
  isProtectedFile,
  onlyPreviewFileOnClient,
  attachments,
  uploadPath,
  hasGetFullFilesInfo,
  maxCount,
  directlyDeleteAttachment = true,
  enabledPasteToUpload = true,
  isReadOnly = false,
  className = '',
  onChangeAttachments,
  onChangeDeteledAttachmentIds,
  onCloseBasicUploadMultipleFilesModal,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleBasicUploadMultipleFilesModal, setVisibleBasicUploadMultipleFilesModal] = useState(false);

  return (
    <>
      {isReadOnly && !(Array.isArray(attachments) && attachments.length) ? (
        <Tooltip
          placement="top"
          title={t('common.noAttachment')}
          destroyTooltipOnHide={true}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <span className="btn-action-editable-cell-wrapper">
            <span className="c-basic-attachment-count btn-action-editable-cell d-inline-block vertical-align-middle text-nowrap text-gray-2 px-2">
              <AttachmentPlus className="mr-1" />0
            </span>
          </span>
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          title={t('workItem.attachments')}
          destroyTooltipOnHide={true}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <div className="btn-action-editable-cell-wrapper">
            <Button
              type="link"
              size="small"
              icon={<AttachmentPlus className="mr-1" />}
              className={`c-basic-attachment-count btn-action-editable-cell border-0 bg-transparent px-2 ${className}`}
              onClick={e => {
                e?.stopPropagation();
                setVisibleBasicUploadMultipleFilesModal(true);
              }}
              {...rest}
            >
              {Array.isArray(attachments) ? attachments.length : 0}
            </Button>
          </div>
        </Tooltip>
      )}

      {visibleBasicUploadMultipleFilesModal && (
        <BasicUploadMultipleFilesModal
          visible={visibleBasicUploadMultipleFilesModal}
          isProtectedFile={isProtectedFile}
          onlyPreviewFileOnClient={onlyPreviewFileOnClient}
          attachments={attachments}
          uploadPath={uploadPath}
          hasGetFullFilesInfo={hasGetFullFilesInfo}
          maxCount={maxCount}
          directlyDeleteAttachment={directlyDeleteAttachment}
          enabledPasteToUpload={enabledPasteToUpload}
          isReadOnly={isReadOnly}
          onChangeAttachments={val => {
            if (typeof onChangeAttachments === 'function') onChangeAttachments(val);
          }}
          onChangeDeteledAttachmentIds={ids => {
            if (typeof onChangeDeteledAttachmentIds === 'function') onChangeDeteledAttachmentIds(ids);
          }}
          onCancel={() => {
            if (typeof onCloseBasicUploadMultipleFilesModal === 'function') onCloseBasicUploadMultipleFilesModal();
            setVisibleBasicUploadMultipleFilesModal(false);
          }}
        />
      )}
    </>
  );
};
