import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import MonacoEditor from './monaco-editor';

const BoxMonacoEditor = ({
  fromModule,
  currentTestStepRaw,
  onSubmitTestStepRaw,
  isReadOnly,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [valueEditor, setValueEditor] = useState(currentTestStepRaw?.testStepRaw);
  const [errorEditor, setErrorEditor] = useState([]);

  return (
    <div className={`c-box-monaco-editor ${className}`} {...rest}>
      <MonacoEditor
        fromModule={fromModule}
        currentTestStepRaw={currentTestStepRaw}
        valueEditor={valueEditor || ''}
        isReadOnly={isReadOnly}
        setValueEditor={setValueEditor}
        setErrorEditor={setErrorEditor}
      />

      {fromModule === 'TESTCASE_DETAIL' && !isReadOnly && (
        <Row align="middle" justify="end" className="pt-3">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            size="small"
            className="ml-2"
            onClick={() => onSubmitTestStepRaw({ values: valueEditor, errors: errorEditor })}
          >
            {t('common.save')}
          </Button>
        </Row>
      )}
    </div>
  );
};

export default BoxMonacoEditor;
