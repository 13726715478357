import React from 'react';

const CloneItemHelper = ({ onCloneItem }) => {
  return (
    <div
      className="c-clone-item-helper"
      onClick={e => {
        if (typeof onCloneItem === 'function') {
          onCloneItem(e?.target?.dataset?.key);
          setTimeout(() => document.querySelector('.c-clone-item-helper')?.setAttribute('data-key', ''), 300);
        }
      }}
    />
  );
};

export default CloneItemHelper;
