import { useEffect } from 'react';

import { ALLOWED_CHARACTER_CODES, handleKeyboardEvents } from '../../editable-cell-utils';

const DateKeyboardEvents = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  setEditingCell,
  handleOpen,
  handleClose,
  handleSave,
  closeAndSetLastValue
}) => {
  /**
   * Add effect to selected date
   */
  const addEffectToSelectedDate = () => {
    const pickerCellWithEffect = document.querySelector('.ant-picker-cell.effect-flash');
    const pickerCellSelected = document.querySelector('.ant-picker-cell-selected:not(.effect-flash)');
    const pickerCellToday = document.querySelector('.ant-picker-cell-today:not(.effect-flash)');

    // Add "effect-flash" to selected date
    if (pickerCellSelected && !pickerCellWithEffect) {
      pickerCellSelected?.classList.add('effect-flash');
      setTimeout(() => pickerCellSelected?.classList.remove('effect-flash'), 1000);
    }

    // Add "effect-flash" to today
    else if (pickerCellToday && !pickerCellWithEffect) {
      pickerCellToday?.classList.add('effect-flash');
      setTimeout(() => pickerCellToday?.classList.remove('effect-flash'), 1000);
    }
  };

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const code = e.code;
    const ctrl = e.ctrlKey ? e.ctrlKey : code === 'Control' ? true : false;
    const shift = e.shiftKey ? e.shiftKey : code === 'Shift' ? true : false;
    const alt = e.altKey ? e.altKey : code === 'Alt' ? true : false;
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');
    const isEditing = triggerFocusElement?.classList.contains('editing');
    const boxVisualInput = document.querySelector('.c-editable-cell .box-visual-input');
    const datePickerDropdown = document.querySelector('.ant-picker-dropdown');

    // Delete & has "focused" class => Don't open, keep "focused" class, save
    if (!field?.mandatory && hasFocused && (code === 'Delete' || code === 'NumpadDecimal')) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.resetFields([formItemName]);
      handleSave(null);
      return;
    }

    // When show "box-visual-input"
    else if (hasFocused && boxVisualInput) {
      // Open
      if (code === 'Enter' || code === 'NumpadEnter' || ctrl || alt || ALLOWED_CHARACTER_CODES.includes(code)) {
        handleOpen();
        return;
      }
    }

    // When editing, no focused, press Shift + Tab => Don't go left
    else if (!hasFocused && isEditing && shift && code === 'Tab') {
      e.stopPropagation();
      return;
    }

    // When show calendar
    else if (datePickerDropdown && !datePickerDropdown.classList.contains('ant-picker-dropdown-hidden')) {
      // Enter && show calendar && empty value => Close + Save
      if ((code === 'Enter' || code === 'NumpadEnter') && !e?.target?.value) {
        e.preventDefault();
        e.stopPropagation();

        tableForm?.setFieldsValue({ [formItemName]: null });
        handleClose(null);
        setTimeout(() => handleSave(null), 200);
        return;
      }

      // Tab => When pressing tab, add flash effect to selected date on the calendar
      else if (code === 'Tab' && !(shift && code === 'Tab')) {
        e.stopPropagation();
        e.preventDefault();

        addEffectToSelectedDate();
        return;
      }
    }

    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return null;
};

export default DateKeyboardEvents;
