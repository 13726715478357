import React, { Suspense } from 'react';
import { Layout } from 'antd';

import { LeftSidebar, MainHeader, MainFooter, RouterLoading } from '../components';
import ProgressBar from '../components/floating/progress-bar';
import { getParentMenuByPathname } from '../common';
import { useLocation } from 'react-router-dom';
import { PROJECT_MANAGER_MENU } from '../constants';

const { Content } = Layout;

export default ({ children }) => {
  const location = useLocation();
  return (
    <Suspense fallback={<RouterLoading />}>
      <Layout className="l-default">
        <MainHeader />

        <Layout className="main-body">
          <LeftSidebar />

          <Layout>
            {getParentMenuByPathname(location.pathname, [...PROJECT_MANAGER_MENU]) ? <ProgressBar /> : null}

            <Content className="main-content">
              {/* <MaintenanceAlert className="mt-3 mx-3" /> */}
              {children}
            </Content>
            <MainFooter />
          </Layout>
        </Layout>
      </Layout>
    </Suspense>
  );
};
