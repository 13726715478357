import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Empty, Input, Checkbox, Menu } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';

import { debounce, checkDifferentTwoList } from '../../common';

let typingTimerOfSearch = 0;

export const MoreDropdown = ({
  currentValues,
  originOptions,
  className = '',
  restDropdown,
  onOk,
  onClear,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  /**
   * Set option list
   */
  useEffect(() => {
    if (!(Array.isArray(originOptions) && originOptions.length)) {
      setCurrentOptions([]);
      return;
    }

    setCurrentOptions(originOptions);
  }, [originOptions]);

  /**
   * Get selected options
   */
  const getSelectedOptions = (currentValues, currentOptions) => {
    if (!(Array.isArray(currentOptions) && currentOptions.length)) return [];

    const newCurrentValues = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];
    const newSelectedOptions = currentOptions.filter(item => newCurrentValues.includes(item.value));

    return newSelectedOptions;
  };

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      const newSelectedOptions = getSelectedOptions(currentValues, currentOptions);
      setSelectedOptions(newSelectedOptions);

      const newOriginOptions = Array.isArray(originOptions) && originOptions.length ? [...originOptions] : [];
      setIsCheckAll(newSelectedOptions.length === newOriginOptions.length);
      setIsIndeterminate(newSelectedOptions.length > 0 && newSelectedOptions.length < newOriginOptions.length);
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, option) => {
    if (!option?.value) return;

    // For: Set selected options
    let newSelectedOptions = Array.isArray(selectedOptions) && selectedOptions.length ? [...selectedOptions] : [];

    if (checked) {
      newSelectedOptions = [...newSelectedOptions, option];
    } else {
      newSelectedOptions = [...newSelectedOptions].filter(item => item.value !== option.value);
    }

    setSelectedOptions(newSelectedOptions);

    // For: Check all
    const newOriginOptions = Array.isArray(originOptions) && originOptions.length ? [...originOptions] : [];
    setIsCheckAll(newSelectedOptions.length === newOriginOptions.length);
    setIsIndeterminate(newSelectedOptions.length > 0 && newSelectedOptions.length < newOriginOptions.length);
  };

  /**
   * On check all fields
   */
  const onCheckAllFields = isChecked => {
    const newOriginOptions = Array.isArray(originOptions) && originOptions.length ? [...originOptions] : [];

    setSelectedOptions(isChecked ? newOriginOptions : []);
    setIsIndeterminate(false);
    setIsCheckAll(isChecked);
  };

  /**
   * Handle search when search keyword is empty
   */
  const handleSearchSearch = val => {
    if (!(Array.isArray(originOptions) && originOptions.length)) return;

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        if (!val) {
          setCurrentOptions(originOptions);
          return;
        }

        const newOptionList = [...originOptions].filter(
          item => typeof item.label === 'string' && item.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );

        setCurrentOptions(newOptionList);
      }),
      300
    );
  };

  /**
   * Handle clear
   */
  const handleClear = () => {
    setVisibleDropdown(false);
    setIsCheckAll(false);
    setIsIndeterminate(false);
    setSelectedOptions(null);

    const clearedRefNames =
      Array.isArray(originOptions) && originOptions.length ? [...originOptions].map(item => item?.value) : [];

    onClear(clearedRefNames);
  };

  /**
   * Handle ok
   */
  const handleOk = () => {
    const newSelectedRefNames = Array.isArray(selectedOptions) ? selectedOptions.map(item => item.value) : [];

    onOk(newSelectedRefNames);
    setVisibleDropdown(false);
  };

  /**
   * Compute: Disabled ok button
   */
  const disabledOkButton = useMemo(() => {
    const oldOptions =
      Array.isArray(currentValues) && currentValues.length ? currentValues.map(value => ({ value })) : [];
    const newOptions = Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions : [];

    const hasDifferent = checkDifferentTwoList(oldOptions, newOptions, ['value'], ['value']);

    return !hasDifferent;
  }, [currentValues, selectedOptions]);

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map(item => item.value) : [];

    const items = [
      {
        key: 'search',
        label: (
          <Input
            placeholder={t('common.searchField')}
            suffix={<SearchOutlined />}
            allowClear
            size="small"
            onChange={e => handleSearchSearch(e?.target?.value)}
          />
        ),
        className: 'bg-transparent'
      }
    ];

    if (!(Array.isArray(currentOptions) && currentOptions.length)) {
      items.push({
        key: 'empty',
        label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />,
        className: 'bg-transparent'
      });
    } else {
      items.push(
        {
          key: 'checkAll',
          label: (
            <Checkbox
              checked={isCheckAll}
              indeterminate={isIndeterminate}
              className="w-100"
              onChange={e => onCheckAllFields(e?.target?.checked)}
            >
              <span title={t('common.checkAll')}>{t('common.checkAll')}</span>
            </Checkbox>
          )
        },
        { type: 'divider' },
        {
          key: 'fields',
          label: (
            <Menu
              items={currentOptions.map(item => {
                return {
                  key: item?.value,
                  label: (
                    <Checkbox
                      checked={selectedValues.includes(item?.value)}
                      className="w-100"
                      onChange={e => handleChangeSelected(e?.target?.checked, item)}
                    >
                      <span title={item.label} style={{ lineHeight: 1.579 }}>
                        {item.label}
                      </span>
                    </Checkbox>
                  )
                };
              })}
              selectable={false}
              triggerSubMenuAction="click"
              className="style-like-as-dropdown-menu checkbox-dropdown-menu"
            />
          ),
          className: 'bg-white cursor-default p-0'
        }
      );
    }

    items.push(
      { type: 'divider' },
      {
        key: 'box-footer',
        label: (
          <>
            <Button
              size="small"
              className="w-auto ml-2"
              disabled={!(Array.isArray(selectedOptions) && selectedOptions.length)}
              onClick={handleClear}
            >
              {t('common.clear')}
            </Button>

            <Button type="primary" size="small" className="w-auto ml-2" disabled={disabledOkButton} onClick={handleOk}>
              {t('common.apply')}
            </Button>
          </>
        ),
        className: 'bg-transparent text-right cursor-default'
      }
    );

    return items;
  }, [selectedOptions, currentOptions, isCheckAll, isIndeterminate, disabledOkButton]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{ items: menus, selectable: false }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style"
        overlayStyle={{ width: 250 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button className={`btn-toggle-dropdown-with-checkbox-list btn-field ${className}`} {...rest}>
          <span className="text-truncate">{t('common.more')}</span> <CaretDownOutlined className="ic-caret-down" />
        </Button>
      </Dropdown>
    </>
  );
};
