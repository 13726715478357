import { action, thunk } from 'easy-peasy';
import HandleBars from 'handlebars';

import { Http, handleError, buildQueryForFilterMongo } from '../../common';

export default {
  /**
   * State
   */
  suggestion: {},
  allSuggestion: {},
  loadingSuggestion: false,

  /**
   * Action: Set all global project
   */
  setSuggestion: action((state, payload) => {
    if (!(state?.suggestion !== undefined) || !payload?.referenceField) return;

    let loadedCount = state.suggestion[payload.referenceField]?.loadedCount;
    const newLoadedCount = !loadedCount ? 1 : ++loadedCount;

    state.suggestion = {
      ...state.suggestion,
      [payload.referenceField]: {
        loadedCount: newLoadedCount,
        data: Array.isArray(payload.data) && payload.data.length ? payload.data : []
      }
    };
  }),

  /**
   * Action: Set all suggestion
   */
  setAllSuggestion: action((state, payload) => {
    if (!(state?.allSuggestion !== undefined)) return;

    if (!payload) {
      state.allSuggestion = {};
      return;
    }

    if (payload?.referenceField && payload?.isClear) {
      state.allSuggestion[payload?.referenceField] = {};
      return;
    }

    if (payload?.referenceField) {
      state.allSuggestion[payload.referenceField] = {
        keyword: payload.keyword,
        rows: payload.rows
      };
    }
  }),

  /**
   * Action: Set loading suggestion
   */
  setLoadingSuggestion: action((state, payload) => {
    if (!(state?.loadingSuggestion !== undefined)) return;

    state.loadingSuggestion = payload;
  }),

  /**
   * Action: Call api to set suggestion
   */
  getSuggestion: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.url) throw new Error('Get suggestion: There are no url');

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingSuggestion(true);

      const urlTemplate = HandleBars.compile(payload.url);
      const urlHandle = urlTemplate({
        projectKey: project.projectKey,
        tenantKey: tenant.tenantKey,
        projectId: project.id,
        ...payload.params
      });
      let options = '';
      if (buildQueryForFilterMongo(payload)) {
        options = `?${buildQueryForFilterMongo(payload)}`;
      }
      const url = `${urlHandle}${options}`;
      let res = await Http.get(url).then(res => res.data);

      if (!res) {
        action.setSuggestion({
          referenceField: payload.referenceField,
          data: null
        });
      }

      action.setSuggestion({
        referenceField: payload.referenceField,
        data: res?.data?.rows || res?.data
      });

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSuggestion(false);
    }
  }),

  /**
   * Action: Call api to get all suggestion
   */
  getAllSuggestion: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.url) throw new Error('Get all suggestion: There are no url');

      const newPayload = { ...payload };
      const { keyword } = newPayload;
      delete newPayload.keyword;

      const oldAllSuggestion = helpers.getState()?.allSuggestion;
      const referenceField = payload.referenceField;
      const currentSuggestionData = oldAllSuggestion?.[referenceField];

      if (Object.keys(oldAllSuggestion || {}).includes(referenceField) && currentSuggestionData?.keyword === keyword) {
        return;
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingSuggestion(true);

      const urlTemplate = HandleBars.compile(payload.url);
      const urlHandle = urlTemplate({
        projectKey: project.projectKey,
        tenantKey: tenant.tenantKey,
        projectId: project.id,
        ...payload.params
      });
      let options = '';
      const newPayload2 = buildQueryForFilterMongo(payload);

      if (newPayload2) {
        options = `?${newPayload2}`;
      }

      const url = `${urlHandle}${options}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAllSuggestion({
        referenceField,
        keyword: payload.keyword,
        rows: res?.data?.rows || res?.data
      });

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSuggestion(false);
    }
  })
};
