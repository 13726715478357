import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { HomeOutlined, LeftOutlined } from '@ant-design/icons';

import env from '../../env';

import './index.scss';

export const Error404 = () => {
  //For route
  const history = useHistory();

  // For language
  const [t] = useTranslation('akaat');

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  return (
    <div className="c-error-404">
      <img src={`/${env.REACT_APP_PREFIX_PATH}images/404.png`} alt="not found result" />
      <div className="not-found-description">
        <h1>Page not found</h1>
        <p className="divider-40">{t('message.pageNotFound')}</p>
        <div className="box-btn">
          <Button size="large" onClick={() => history.goBack()}>
            <LeftOutlined /> {t('common.goBack')}
          </Button>
          <Button size="large" href={`/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey || ''}`}>
            <HomeOutlined /> {t('common.goBackHome')}
          </Button>
        </div>
      </div>
    </div>
  );
};
