import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, buildQueryForFilterMongo, sleep, jsonParse } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  previewData: [],
  editingMap: {},
  percentImport: 0,
  query: {},
  loadingList: false,
  loadingItem: false,
  loadingImportTestRunExcel: false,
  loadingPreviewImportTestRunExcel: false,

  /**
   * Action: Set map list
   */
  setMapList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set preview data
   */
  setPreviewData: action((state, payload) => {
    if (state?.previewData === undefined) return;

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.previewData = [];
      return;
    }

    state.previewData = payload.data;
  }),

  setPercentImport: action((state, payload) => {
    state.percentImport = payload;
  }),

  /**
   * Action: Set editing map
   */
  setEditingMap: action((state, payload) => {
    if (state?.editingMap === undefined) return;

    state.editingMap = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading import test run excel
   */
  setLoadingImportTestRunExcel: action((state, payload) => {
    if (state?.loadingImportTestRunExcel === undefined) return;

    state.loadingImportTestRunExcel = payload;
  }),

  /**
   * Action: Set loading preview import test run excel
   */
  setLoadingPreviewTestRunExcel: action((state, payload) => {
    if (state?.loadingPreviewImportTestRunExcel === undefined) return;

    state.loadingPreviewImportTestRunExcel = payload;
  }),

  /**
   * Action: Call api to get map list
   */
  getMapList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.IMPORT_MAPPING
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMapList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get map by Id
   */
  getMapById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingMap(res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create map
   */
  createMap: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_MAPPING}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageCreatingMap'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update map
   */
  updateMap: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('Invalid Payload');

      const { id } = payload;
      delete payload.id;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${id}`;
      const res = await Http.put(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageUpdatingMap'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete map
   */
  deleteMap: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      // ==========> Delete map
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageDeletingMap'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to preview import test run excel
   */
  previewImportTestRunExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingPreviewTestRunExcel(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_PREVIEW}`;
      const res = await Http.post(url, payload).then(res => res.data);

      if (res?.data) {
        action.setPreviewData(res.data);
        return res.data;
      }
    } catch (err) {
      if (err?.status === 400 && err.data?.message.includes('Sheet name is not exist')) {
        return err;
      }
      handleError(err);
    } finally {
      action.setLoadingPreviewTestRunExcel(false);
    }
  }),

  /**
   * Action: Call api to import test run
   */
  importTestRun: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      let noShowSuccessMessage = false;

      if (!(Array.isArray(payload) && payload.length)) return;

      action.setLoadingImportTestRunExcel(true);
      const rootPayload = jsonParse(JSON.stringify(payload));
      let countChunk = 0;
      let index = 0;
      while (payload.length) {
        const newPayload = payload.splice(0, 50);
        countChunk = newPayload.length + countChunk;

        if (index === 0) {
          setTimeout(() => {
            action.setPercentImport(Math.round((countChunk * 100) / rootPayload.length));
          }, 1000);
        }
        const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTRUN_IMPORT}`;
        await Http.post(url, newPayload).then(res => res.data);

        if (index > 0) {
          action.setPercentImport(((countChunk * 100) / rootPayload.length).toFixed(2));
        }
        index++;
      }

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageCreatingTestCase'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      await sleep(1000);
      action.setLoadingImportTestRunExcel(false);
      return true;
    } catch (err) {
      handleError(err);
    } finally {
      await sleep(1000);
      action.setLoadingImportTestRunExcel(false);
      return true;
    }
  })
};
