import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError, buildQueryForFilterMongo } from '../../common';

export default {
  /**
   * State
   */
  objectiveList: [],
  objectiveTotal: 0,
  editingObjective: {},
  loadingObjectiveList: false,
  loadingObjectiveItem: false,

  /**
   * Action: Set global objective list
   */
  setObjectiveList: action((state, payload) => {
    if (!(state?.objectiveList !== undefined)) return;

    if (!(Array.isArray(payload?.rows) && payload?.rows?.length)) {
      state.objectiveList = [];
      state.objectiveTotal = 0;
      return;
    }

    state.objectiveList = payload.rows;
    state.objectiveTotal = payload.count;
  }),

  /**
   * Action: Set loading objective list
   */
  setLoadingObjectiveList: action((state, payload) => {
    if (!(state?.loadingRelationTypeList !== undefined)) return;

    state.loadingRelationTypeList = payload;
  }),

  /**
   * Action: Set loading objective item
   */
  setLoadingObjectiveItem: action((state, payload) => {
    if (!(state?.loadingObjectiveItem !== undefined)) return;

    state.loadingObjectiveItem = payload;
  }),

  /**
   * Action: Set editing objective
   */
  setEditingObjective: action((state, payload) => {
    if (state?.editingObjective === undefined) return;

    state.editingObjective = payload;
  }),

  /**
   * Action: Call api to get objective list
   */
  getObjectiveList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }
      action.setLoadingObjectiveList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.OBJECTIVE
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setObjectiveList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingObjectiveList(false);
    }
  }),

  /**
   * Action: Call api to get objective list
   */
  getObjectiveById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }
      action.setLoadingObjectiveItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.OBJECTIVE}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingObjective(res?.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingObjectiveItem(false);
    }
  }),

  /**
   * Action: Call api to create objective
   */
  createObjective: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) {
        throw new Error('Create objective: Invalid Payload');
      }
      action.setLoadingObjectiveItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.OBJECTIVE}`;
      const res = await Http.post(url, payload).then(res => res.data);

      action.setEditingObjective(res?.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingObjectiveItem(false);
    }
  })
};
