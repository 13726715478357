import { action, thunk } from 'easy-peasy';

import { ENDPOINTS, ENGINE_PLANNING_JOB_CICD, ENGINE_PLANNING_JOB_SCHEDULE } from '../../../../../constants';
import { Http, parseRsql, handleError } from '../../../../../common';

export const model = {
  /**
   * State
   */
  treeData: {}, // Tree data from api
  tree: [], //  Tree for show on UI
  editingFolder: {},
  selectedTreeItem: {},
  loadingTreeData: false,

  /**
   * Action: Set tree data
   */
  setTreeData: action((state, payload) => {
    if (state?.treeData === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length)) {
      state.treeData = {};
      return;
    }

    state.treeData = payload;
  }),

  /**
   * Action: Set tree (ant design tree, tree on UI)
   */
  setTree: action((state, payload) => {
    if (state?.tree === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.tree = [];
      return;
    }

    state.tree = payload;
  }),

  /**
   * Action: Set editing folder
   */
  setEditingFolder: action((state, payload) => {
    if (state?.editingFolder === undefined) return;

    state.editingFolder = payload;
  }),

  /**
   * Action: Set selected tree item
   */
  setSelectedTreeItem: action((state, payload) => {
    if (state?.selectedTreeItem === undefined) return;

    state.selectedTreeItem = payload;
  }),

  /**
   * Action: Set loading tree data
   */
  setLoadingTreeData: action((state, payload) => {
    if (state?.loadingTreeData === undefined) return;

    state.loadingTreeData = payload;
  }),

  /**
   * Action: Call api to get test run tree data
   */
  getJobRunnerTreeData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload?.aql)) {
        throw new Error('Invalid aql'); // aql is invalid
      }

      if (!payload?.isGetByInterval) {
        action.setLoadingTreeData(true);
      }

      const { hasGetJobStatuses } = payload ? payload : {};

      let res1 = null;
      let res2 = null;

      const url1 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/all?order[updatedAt]=DESC`;

      res1 = Http.get(url1).then(res => res.data);

      if (hasGetJobStatuses) {
        const url2 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/job/summary`;

        res2 = Http.get(url2).then(res => res.data);
      }

      const promises = await Promise.all([res1, res2]);

      const data = {
        [ENGINE_PLANNING_JOB_SCHEDULE]: promises[0]?.data?.rows
          ? promises[0]?.data?.rows.filter(job => job?.type === ENGINE_PLANNING_JOB_SCHEDULE)
          : [],
        [ENGINE_PLANNING_JOB_CICD]: promises[0]?.data?.rows
          ? promises[0]?.data?.rows.filter(job => job?.type === ENGINE_PLANNING_JOB_CICD)
          : [],
        jobStatuses: promises[1]?.data
      };

      action.setTreeData(data);

      return promises;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTreeData(false);
    }
  })
};
