import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE, SYSTEM_FIELD_ASSIGN_TO } from '../../../../constants';
import { jsonParse, getObjectByValue, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { StatusLabel } from '../../../status-label';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import StatusKeyboardEvents from './status-keyboard-events';
import StatusFormItem from './status-form-item';

const StatusField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  isEditableField,
  disableEditingCells,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  /**
   * State list
   */
  const listStates = useMemo(() => {
    let newListStates = ticketListData?.[workTicketId]?.workFlow?.listStates;
    return Array.isArray(newListStates) && newListStates.length ? [...newListStates] : [];
  }, [workTicketId, ticketListData]);

  /**
   * Step list
   */
  const listSteps = useMemo(() => {
    let newListSteps = ticketListData?.[workTicketId]?.workFlow?.listSteps;
    return Array.isArray(newListSteps) && newListSteps.length ? [...newListSteps] : [];
  }, [workTicketId, ticketListData]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastOption = lastSavedValue[formItemName];

    return lastOption !== undefined ? lastOption?.status : objectPath.get(row, field?.refName);
  }, [row, field, formItemName, isOpenDropdown]);

  /**
   * Options
   */
  const options = useMemo(() => {
    let newOptions = [];

    // For new row
    if (row?.isNew) {
      newOptions = listStates.map(item => {
        return {
          label: item?.name,
          value: item?.id,
          status: item
        };
      });
    }

    // For existed row
    else {
      const currentStepList = [...listSteps].filter(item => item?.from === val?.id);
      const currentStatus = listStates.find(item => item?.id === val?.id);

      const newListStates = currentStepList.map(item => {
        const status = listStates.find(s => s?.id === item?.to);
        const step = {
          ...item,
          toStatus: listStates.find(s => s?.id === item?.to)
        };

        return {
          label: status?.name,
          value: status?.id,
          status,
          step
        };
      });

      const currentOption = {
        label: currentStatus?.name,
        value: currentStatus?.id,
        status: currentStatus,
        disabled: true
      };

      newOptions = [currentOption, ...newListStates];
    }

    return newOptions;
  }, [val, row, listStates, listSteps]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) return null;
    const val = objectPath.get(row, field?.refName);
    return getObjectByValue(val?.id, options);
  }, [row, field, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastOption = lastSavedValue[formItemName];

    return lastOption !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOption(opt);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      // For new row
      if (row?.isNew) {
        const newCurrentOption = getObjectByValue(opt?.value, options);

        handleSetLastSavedValueToSession({ [formItemName]: newCurrentOption });

        if (typeof onSave === 'function') {
          onSave({ formData: { [field?.refName]: opt?.value }, row, field });
        }

        handleClose(opt);
      }

      // For existed row
      else {
        const helper = document.querySelector('.c-update-ticket-status-modal-helper');

        helper?.setAttribute('data-x', x);
        helper?.setAttribute('data-y', y);
        helper?.setAttribute('data-form-item-name', formItemName);
        helper?.setAttribute('data-work-ticket-id', workTicketId);
        helper?.setAttribute('data-current-value', defaultOption?.value);
        helper?.setAttribute('data-current-step', JSON.stringify({ to: opt?.step?.to, name: opt?.step?.name }));

        document.querySelector('.c-update-ticket-status-modal-helper .trigger-open')?.click();
      }
    }
  };

  /**
   * On change status
   */
  const onChangeStatus = () => {
    const helper = document.querySelector('.c-update-ticket-status-modal-helper');
    const textElm = document.querySelector('.c-update-ticket-status-modal-helper .text');

    if (!helper || !textElm) return;

    const formData = jsonParse(textElm.innerHTML || {});
    const currentStep = jsonParse(helper.dataset.currentStep || {});

    const newStatusId = currentStep?.to;
    const newCurrentOption = getObjectByValue(newStatusId, options);
    const newFormData = {
      ...formData,
      [field?.refName]: newStatusId
    };

    document.querySelector('.c-update-ticket-status-modal-helper .trigger-close')?.click();

    const assignToFormItemName = `key-${row?.key}-${SYSTEM_FIELD_ASSIGN_TO}`;

    handleSetLastSavedValueToSession({
      [formItemName]: newCurrentOption,
      [assignToFormItemName]: formData[SYSTEM_FIELD_ASSIGN_TO]
    });

    if (typeof onSave === 'function') {
      onSave({ formData: newFormData, row, field });
    }

    handleClose(newCurrentOption);

    setTimeout(() => {
      const querySelector1 = `.trigger-focus-element[data-form-item-name="${formItemName}"]`;
      document.querySelector(querySelector1)?.click();

      const querySelector2 = `.trigger-render-field-element[data-form-item-name="${assignToFormItemName}"]`;
      document.querySelector(querySelector2)?.click();
    }, 500);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    optionFilterProp: 'label',
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: false,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => {
      if (!row?.isNew) handleSave(option);
    },
    onClear: () => {
      if (!row?.isNew) handleSave(null);
    },
    onChange: (val, option) => {
      if (row?.isNew) handleSave(option);
    },
    onClick: () => {
      if (!row?.isNew) handleOpen();
    }
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const option = getCurrentOption(currentOption);

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={option?.label || ''} className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value text-truncate">
            <StatusLabel status={option?.status} />
          </span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink title={option?.label || ''} row={row} field={field}>
          <StatusLabel status={option?.status} />
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div title={option?.label || ''} className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            <StatusLabel status={option?.status} />
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <StatusLabel status={option?.status} />;
    }

    // Readonly
    else {
      return (
        <div title={option?.label || ''} className="cell-text cursor-default hide-after">
          <span className="cell-text-value text-truncate">
            <StatusLabel status={option?.status} />
          </span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <StatusFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render trigger change status element
   */
  const renderTriggerChangeStatusElement = () => {
    return (
      <div
        data-form-item-name={formItemName}
        data-x={x}
        data-y={y}
        className="trigger-change-status-element"
        onClick={onChangeStatus}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <StatusKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText()}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />

        {renderTriggerChangeStatusElement()}
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper status-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new status-field ${className}`} {...rest}>
        {isReadOnly ? (
          renderCellText()
        ) : (
          <>
            {renderFormItem()}
            {renderTriggerChangeStatusElement()}
          </>
        )}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default StatusField;
