import { PRIORITIES } from '../../constants';
import React from 'react';

export const WORK_ITEM_SEARCH_FIELD_LABEl_RENDER = (referenceField, item) => {
  switch (referenceField) {
    case 'priority': {
      return (
        <>
          {PRIORITIES.map(priority => {
            if (priority.value === item.value) {
              return (
                <>
                  {priority.icon}
                  {priority.label}
                </>
              );
            }
          })}
        </>
      );
    }
  }
};
