import React from 'react';

import { JIRA_PLATFORM_ID } from '../../constants';
import BoxCustomFields from './box-custom-fields';
import BoxRelatedTickets from './box-related-tickets';
import BoxDescription from './box-description';
import BoxAttachments from './box-attachments';
import BoxJiraPeople from './external-system-jira/box-jira-people';
import BoxPeople from './box-people';
import BoxDates from './box-dates';
import BoxAutomation from './box-automation';

export const DetailComponents = ({ type, hasGetFullFilesInfo, className = '', ...rest }) => {
  switch (type) {
    case 'CUSTOM_FIELDS': {
      return <BoxCustomFields {...rest} />;
    }

    case 'RELATED_TICKETS': {
      return <BoxRelatedTickets {...rest} />;
    }

    case 'DESCRIPTION': {
      return <BoxDescription {...rest} />;
    }

    case 'ATTACHMENTS': {
      return <BoxAttachments hasGetFullFilesInfo={hasGetFullFilesInfo} {...rest} />;
    }

    case 'PEOPLE': {
      return rest?.editingItem?.externalSystem === JIRA_PLATFORM_ID ? (
        <BoxJiraPeople {...rest} />
      ) : (
        <BoxPeople {...rest} />
      );
    }

    case 'DATE': {
      return <BoxDates {...rest} />;
    }

    case 'AUTOMATION': {
      return <BoxAutomation {...rest} />;
    }

    default:
      return null;
  }
};
