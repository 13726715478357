import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import './style.scss';

export const EditableSelectMultiOption = ({
  form,
  defaultVal = '',
  options,
  isShowEditingIcon = false,
  isReadOnly = false,
  disabled = false,
  placeholder,
  className = '',
  restFormItem,
  restField,
  restBoxEditableText,
  restValueText,
  restEditButton,
  onOpen,
  onClose,
  onSearch,
  onChange,
  onClear,
  onSave,
  ...rest
}) => {
  const refInput = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemValues, setSelectedItemValues] = useState([]);

  /**
   * On set selected items
   */
  const onSetSelectedItems = values => {
    if (!(Array.isArray(options) && options.length) || !(Array.isArray(values) && values.length)) {
      setSelectedItems([]);
      setSelectedItemValues([]);
      return;
    }

    let valuesMapping = [];
    let valuesNotMapping = [];

    values.forEach(item => {
      if (options.includes(option => option.value === item)) {
        valuesMapping.push(item);
      } else {
        valuesNotMapping.push({
          label: item,
          value: item
        });
      }
    });

    setSelectedItems([...valuesMapping, ...valuesNotMapping]);
    setSelectedItemValues(values);
  };

  /**
   * Set current value by default value
   */
  useEffect(() => {
    if (!(Array.isArray(options) && options.length)) return;

    onSetSelectedItems(defaultVal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal, options]);

  /**
   * Focus to input after get list
   */
  useEffect(() => {
    refInput?.current && refInput.current.focus({ cursor: 'end' });
  }, [options]);

  /**
   * Focus to input after open
   */
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        setIsOpen(true);
        refInput?.current && refInput.current.focus({ cursor: 'end' });
      }, 200);
    }
  }, [isEditing]);

  /**
   * Handle open
   */
  const handleOpen = e => {
    e.stopPropagation();

    if (isReadOnly) return;

    setTimeout(() => {
      if (typeof onOpen === 'function') onOpen();

      if (restFormItem?.name && typeof form?.setFieldsValue === 'function') {
        form.setFieldsValue({ [restFormItem.name]: defaultVal });
      }

      setIsEditing(true);
    }, 300);
  };

  /**
   * Handle close
   */
  const handleClose = () => {
    if (typeof onClose === 'function') onClose();

    setIsOpen(false);
    setIsEditing(false);

    if (!isFormValid()) onSetSelectedItems(defaultVal);
  };

  /**
   * Handle save
   */
  const handleSave = val => {
    if (!isFormValid()) return;

    if (JSON.stringify(val) === JSON.stringify(defaultVal)) {
      handleClose();
      return;
    }

    if (typeof onSave === 'function') onSave(val);

    handleClose();
  };

  /**
   * Check validation
   */
  const isFormValid = () => {
    if (!(restFormItem?.name && typeof form?.getFieldError === 'function')) {
      return true;
    }

    const errors = form.getFieldError(restFormItem.name);

    if (Array.isArray(errors) && errors.length) return false;

    return true;
  };

  /**
   * Input props
   */
  const inputProps = {
    ref: refInput,
    open: isOpen,
    mode: 'multiple',
    options: Array.isArray(options) && options.length ? options : [],
    optionFilterProp: 'label',
    showSearch: true,
    allowClear: true,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: disabled,
    onDropdownVisibleChange: val => {
      if (!val) {
        handleSave(selectedItemValues);
        setTimeout(() => handleClose(), 200); // Check validation before close
      }

      setIsOpen(val);
    },
    onSearch: val => {
      if (typeof onSearch === 'function') onSearch(val);
    },
    onChange: val => {
      if (typeof onChange === 'function') onChange(val); // => "val" is value list, example ["1", "2", ...]

      onSetSelectedItems(val);
    },
    onClear: () => {
      if (typeof onClear === 'function') onClear(null);

      handleClose();
      handleSave(null);
    },
    onKeyDown: e => {
      if (e?.key === 'Escape' || e?.keyCode === 27) {
        handleClose();
      }
    }
  };

  return (
    <div
      className={`c-editable-select-multi-option ${isEditing ? 'is-editing' : ''} ${
        isShowEditingIcon ? 'is-show-editing-icon' : ''
      } ${isReadOnly ? 'is-read-only' : ''} ${className}`}
      onClick={e => e.stopPropagation()}
      {...rest}
    >
      {isEditing && (
        <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem}>
          <Select {...inputProps} {...restField} />
        </Form.Item>
      )}

      {!isEditing && (
        <div
          className={`box-txt-label min-h-22 ${restBoxEditableText?.className || ''}`}
          onClick={handleOpen}
          {...restBoxEditableText}
        >
          <span className={`txt-label cursor-text ${restValueText?.className || ''}`} {...restValueText}>
            {!(Array.isArray(selectedItems) && selectedItems.length) && isReadOnly ? (
              <span className="text-gray">N/A</span>
            ) : !(Array.isArray(selectedItems) && selectedItems.length) ? (
              <span className="text-gray">{placeholder}</span>
            ) : Array.isArray(selectedItems) && selectedItems.length ? (
              <span title={selectedItems.map(item => item.label).join(', ')}>
                {selectedItems.map((item, idx) => (
                  <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
                    {item.label}
                  </Tag>
                ))}
              </span>
            ) : (
              ''
            )}
          </span>

          {!isReadOnly && (
            <Button
              type="link"
              icon={<EditOutlined />}
              size="small"
              className={`btn-open-editable ${restEditButton?.className || ''}`}
              onClick={handleOpen}
              {...restEditButton}
            />
          )}
        </div>
      )}
    </div>
  );
};
