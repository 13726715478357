import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'antd';

import env from '../../env';
import { SafeInnerHtml } from '../safe-inner-html';

import './style.scss';

export const OutOfFreeTransactionModal = ({ visible, className, onCancel, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Modal
      open={visible}
      footer={null}
      width={600}
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header"
      className={`c-out-of-free-transaction-modal hide-modal-header modal-content-rounded-10 modal-content-overflow-hidden p-0-modal-body ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 2px)' }}>
        <div className="form-wrapper p-4">
          <Row>
            <Col xs={16}>
              <h4 className="ant-typography text-primary mt-0 mb-3">
                {t('engineSummary.yourFreeTransitionsOfThisMonthIsRunningOut')}
              </h4>

              <div className="mb-2">
                <SafeInnerHtml html={t('engineSummary.yourFreeTransitionsOfThisMonthIsRunningOutNote')} />
              </div>
              <div className="text-italic">{t('engineSummary.pleaseContactWithUs')}</div>
            </Col>

            <Col xs={8}>
              <img
                src={`/${env.REACT_APP_PREFIX_PATH}images/out-of-free-transaction.jpg`}
                alt="Your free transitions of this month is running out!"
                className="w-100"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
