import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

import { BasicTable } from '../basic-table';

export const TestEnvironmentVariableListPopover = ({ testEnvironment, restPopover, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Variable list
   */
  const variableList = useMemo(() => {
    if (
      !(
        testEnvironment?.value !== null &&
        typeof testEnvironment?.value === 'object' &&
        Object.keys(testEnvironment?.value).length
      )
    ) {
      return [];
    }

    const newVariableList = Object.keys(testEnvironment?.value).map(key => {
      return {
        key,
        value: testEnvironment?.value?.[key]
      };
    });

    return newVariableList;
  }, [testEnvironment]);

  return (
    <Popover
      title={testEnvironment?.name || ''}
      content={
        <BasicTable
          columns={[
            { dataIndex: 'key', width: 150, ellipsis: true, className: 'font-weight-medium px-2 py-1' },
            { dataIndex: 'value', width: 150, ellipsis: true, className: 'px-2 py-1' }
          ]}
          dataSource={variableList}
          showHeader={false}
          pagination={false}
          bordered={true}
          locale={{ emptyText: t('testConfig.noEnvironmentVariable') }}
          style={{ width: 300 }}
          className={`no-overflow-x-scroll ${!(Array.isArray(variableList) && variableList.length) ? 'no-border' : ''}`}
        />
      }
      trigger="hover"
      placement="top"
      destroyTooltipOnHide={true}
      overlayInnerStyle={{ minWidth: 300 }}
      {...restPopover}
    >
      <UnorderedListOutlined className={`c-test-environment-variable-list-popover ${className}`} {...rest} />
    </Popover>
  );
};
