import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import ReactFlow, { Controls, MarkerType, Background, useNodesState, useEdgesState } from 'react-flow-renderer';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { NUMBER_PATTERN } from '../../constants';
import { checkValidRGBObject } from '../../common';
import { CustomNode } from './custom-node';

const nodeTypes = {
  customNode: CustomNode
};

export const WorkflowDiagram = ({
  fromModule,
  currentStatusList,
  currentStepList,
  showControl = true,
  isReadOnly = false,
  className = '',
  onNodeClick,
  onNodeDoubleClick,
  onEdgeClick,
  onEdgeDoubleClick,
  onConnectStatus,
  onChangeStatusList,
  restReactFlow,
  onDeleteStatus,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For react flow renderer
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  // Component state
  const [isConnecting, setIsConnecting] = useState(false);
  const [deleteKeyCode, setDeleteKeyCode] = useState([]);
  const [isDeleteNode, setIsDeleteNode] = useState(false);

  useEffect(() => {
    if (!fromModule) return;

    switch (fromModule) {
      case 'EDIT_PAGE': {
        setDeleteKeyCode([...deleteKeyCode, 'Backspace', 'Delete']);
        break;
      }
      default:
        break;
    }
  }, [fromModule]);

  /**
   * Set nodes
   */
  useEffect(() => {
    if (!(Array.isArray(currentStatusList) && currentStatusList.length)) {
      setNodes([]);
      setEdges([]);
      return;
    }

    const newNodes = [...currentStatusList].map(item => {
      const validPosition =
        new RegExp(NUMBER_PATTERN).test(item.position?.x) && new RegExp(NUMBER_PATTERN).test(item.position?.y);

      const node = {
        id: item.id,
        data: { ...item, label: item.name },
        position: validPosition ? item.position : { x: 0, y: 0 },
        style: {
          color: checkValidRGBObject(item.color) ? `rgb(${item.color.r}, ${item.color.g}, ${item.color.b})` : '#222',
          background: checkValidRGBObject(item.background)
            ? `rgb(${item.background.r}, ${item.background.g}, ${item.background.b})`
            : '#fff'
        },
        type: 'customNode'
      };

      return node;
    });

    setNodes(newNodes);
  }, [currentStatusList, setNodes, setEdges]);

  /**
   * Set edges
   */
  useEffect(() => {
    if (!(Array.isArray(currentStepList) && currentStepList.length)) {
      setEdges([]);
      return;
    }

    const newEdges = currentStepList.map(item => {
      const edge = {
        id: item.id ? `${item.id}` : uuidv4(),
        label: item.name,
        labelBgStyle: { y: 2, height: 12 },
        source: `${item.from}`,
        target: `${item.to}`,
        sourceHandle: item.fromHandle,
        targetHandle: item.toHandle,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed
        }
      };

      return edge;
    });

    setEdges(newEdges);
  }, [currentStepList, setEdges]);

  /**
   * On node drag stop
   */
  const onNodeDragStop = (e, node) => {
    if (isReadOnly || !node) return;

    const newStatusList = [...currentStatusList];
    const index = currentStatusList.findIndex(item => `${item.id}` === `${node.id}`);

    if (index === -1) return;

    newStatusList[index].position = node.position;

    onChangeStatusList(newStatusList);
  };

  /**
   * Handle delete node
   */
  const handleDelete = node => {
    if (!isDeleteNode) return;

    return Modal.confirm({
      title: t('common.confirmDelete'),
      content: t('message.areYouSureDelete', { name: node?.name }),
      autoFocusButton: null,
      maskClosable: true,
      okText: t('common.delete'),
      okButtonProps: { danger: true, type: 'primary', icon: <DeleteOutlined /> },
      cancelText: t('common.cancel'),
      onOk: () => {
        onDeleteStatus(node);
        setIsDeleteNode(false);
      }
    });
  };

  return (
    <>
      <div className={`c-workflow-diagram ${className}`} {...rest}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          nodesConnectable={!isReadOnly}
          fitView={isReadOnly}
          className={`${isReadOnly ? 'is-read-only' : ''} ${isConnecting ? 'is-connecting' : ''}`}
          onNodesChange={nodes => {
            let isDelete = false;
            nodes.forEach(node => {
              if (node.type === 'remove') {
                isDelete = true;
                setIsDeleteNode(true);
                const nodeTemp = currentStatusList.find(n => n.id === node.id);
                handleDelete(nodeTemp);
                return;
              }
            });

            if (!isDelete) onNodesChange(nodes);
          }}
          onNodeClick={(e, node) => {
            e.stopPropagation();
            if (typeof onNodeClick === 'function') onNodeClick(e, node);
          }}
          onNodeDoubleClick={(e, node) => {
            e.stopPropagation();
            if (typeof onNodeDoubleClick === 'function') onNodeDoubleClick(e, node);
          }}
          onEdgeClick={(e, node) => {
            e.stopPropagation();
            if (typeof onEdgeClick === 'function') onEdgeClick(e, node);
          }}
          onEdgeDoubleClick={(e, node) => {
            e.stopPropagation();
            if (typeof onEdgeDoubleClick === 'function') onEdgeDoubleClick(e, node);
          }}
          onNodeDragStop={(e, node) => {
            e.stopPropagation();
            if (typeof onNodeDragStop === 'function') onNodeDragStop(e, node);
          }}
          onConnect={(e, node) => {
            if (typeof onConnectStatus === 'function' || !isReadOnly) onConnectStatus(e, node);
          }}
          deleteKeyCode={deleteKeyCode}
          onConnectStart={e => {
            e.stopPropagation();
            setIsConnecting(true);
          }}
          onConnectEnd={e => {
            e.stopPropagation();
            setIsConnecting(false);
          }}
          onInit={e => {
            if (!isReadOnly) {
              e.zoomTo(1);
            }
          }}
          {...restReactFlow}
        >
          {showControl && <Controls />}
          {<Background color="#aaa" gap={16} />}
        </ReactFlow>
      </div>
    </>
  );
};
