import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { notification } from 'antd';

import { BASE64_IMAGE_PATTERN } from '../../constants';
import { useFile, getFileListFromPastedBase64Text } from '../../common';
import { PrepareAttachScreenshotModal } from '../prepare-attach-screenshot-modal';

export const BasicPasteUploadFile = ({
  attachments,
  acceptMineTypes, // Example: ['image/', 'video/']
  isProtectedFile = true,
  uploadPath,
  acceptFileTypes, // Extension of files, string[]
  maxFileSize,
  disabledWhenOpeningAnyModal,
  disabledWhenHasSelectors,
  onUploaded,
  onChangeAttachments,
  onShowPrepareAttachScreenshotModal
}) => {
  // For upload
  const { uploadFile, checkValidType, checkValidSize } = useFile();

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);

  // Component state
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [visiblePrepareAttachScreenshotModal, setVisiblePrepareAttachScreenshotModal] = useState(false);

  /**
   * 
   * Check before upload
   */
  const checkValidFilesBeforeUpload = file => {
    if (!file) return;

    if (!uploadPath) {
      notification.error({ message: t('common.error'), description: 'There are no uploadPath' });
      return;
    }

    if (isProtectedFile && (!globalTenant?.tenantKey || !globalProject?.projectKey)) {
      notification.error({ message: t('common.error'), description: 'There are no tenant key or no project key' });
      return;
    }

    const isValidType = checkValidType({ file, acceptFileTypes });
    const isValidSize = checkValidSize({ file, maxFileSize });

    return isValidType && isValidSize;
  };

  /**
   * Handle upload
   */
  const handleUpload = async () => {
    if (!currentFile) return;

    const newAttachments = [];

    try {
      setLoadingUpload(true);

      const formData = {
        file: currentFile,
        uploadPath,
        isProtectedFile
      };

      if (formValues?.fileName !== currentFile.name) {
        formData.fileName = formValues?.fileName;
      }

      const res = await uploadFile(formData);
      await getFullFilesInfo([res]);

      if (res) newAttachments.push(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpload(false);
    }

    // Reset
    setVisiblePrepareAttachScreenshotModal(false);
    setCurrentFile(null);

    if (typeof onUploaded === 'function') onUploaded(res);

    if (typeof onChangeAttachments === 'function') {
      const oldAttachments = Array.isArray(attachments) && attachments.length ? [...attachments] : [];
      onChangeAttachments([...oldAttachments, ...newAttachments]);
    }
  };

  /**
   * On paste
   */
  const onPaste = async e => {
    const prepareAttachScreenshotModal = document.querySelector('.c-prepare-attach-screenshot-modal');
    const basicEditorHasFocus = document.querySelector('.c-basic-editor.has-focus');

    if (prepareAttachScreenshotModal || basicEditorHasFocus) return;

    if (disabledWhenOpeningAnyModal) {
      const modalWrapList = document.querySelectorAll('.ant-modal-wrap');

      if (modalWrapList.length) {
        for (let i = 0; i < modalWrapList.length; i++) {
          if (window.getComputedStyle(modalWrapList[i]).display === 'block') return;
        }
      }
    }

    if (Array.isArray(disabledWhenHasSelectors) && disabledWhenHasSelectors.length) {
      for (let i = 0; i < disabledWhenHasSelectors.length; i++) {
        const element = document.querySelector(disabledWhenHasSelectors[i]);
        if (element) return;
      }
    }

    const clipboardData = e?.clipboardData || e?.originalEvent?.clipboardData;
    const text = clipboardData?.getData('Text');
    const files = clipboardData?.files;
    let fileList = null;

    if (new RegExp(BASE64_IMAGE_PATTERN).test(text)) {
      fileList = await getFileListFromPastedBase64Text({ text });
    } else if (Array.isArray(Object.values(files)) && Object.values(files).length) {
      fileList = Object.values(files);
    } else {
    }

    if (!(Array.isArray(fileList) && fileList.length)) return;

    const validFile = fileList.find((item, index) => {
      if (!(Array.isArray(acceptMineTypes) && acceptMineTypes.length)) {
        return index === 0;
      }

      return acceptMineTypes.some(sub => new RegExp(`^${sub}`, 'g').test(item?.type));
    });

    const isValid = checkValidFilesBeforeUpload(validFile);

    if (isValid) {
      setCurrentFile(validFile);
      setVisiblePrepareAttachScreenshotModal(true);

      if (typeof onShowPrepareAttachScreenshotModal === 'function') onShowPrepareAttachScreenshotModal();
    }
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('paste', onPaste);

    return () => {
      window.removeEventListener('paste', onPaste);
    };
  });

  return (
    <>
      {visiblePrepareAttachScreenshotModal && (
        <PrepareAttachScreenshotModal
          visible={visiblePrepareAttachScreenshotModal}
          file={currentFile}
          loadingUpload={loadingUpload}
          onFormChange={values => setFormValues({ ...formValues, ...values })}
          onSubmit={handleUpload}
          onCancel={() => {
            setVisiblePrepareAttachScreenshotModal(false);
            setCurrentFile(null);
          }}
        />
      )}
    </>
  );
};
