import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'antd';
import { FileOutlined, DownloadOutlined } from '@ant-design/icons';

import { FileBlock } from '../../assets/svg-icons';

const OtherFile = ({ fileInfo, onlyPreviewFileOnClient, onDownload }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Row
      justify="center"
      align="middle"
      className={`c-other-file h-100 ${onlyPreviewFileOnClient ? 'only-preview-file-on-client' : ''}`}
    >
      <div className="box-other-file">
        {fileInfo?.invalid ? (
          <FileBlock className="gray ic-file" />
        ) : onlyPreviewFileOnClient ? (
          <FileOutlined className="gray ic-file" />
        ) : (
          <>
            <div className="txt">{t('common.couldNotPreviewThisFileType')}</div>

            <Button
              type="primary"
              size="large"
              shape="round"
              icon={<DownloadOutlined />}
              className="mt-4"
              onClick={onDownload}
            >
              {t('common.download')}
            </Button>
          </>
        )}
      </div>
    </Row>
  );
};

export default OtherFile;
