import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Popover, Row } from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';

import { AVATAR_BACKGROUND_COLORS } from '../../constants';
import { convertUserForSubmitData, getTotalUnicodeIndexOfString } from '../../common';

import './style.scss';

export const UserAvatar = ({
  user,
  className = '',
  showFullname,
  hideName,
  showInfoDropdown = true,
  inactive,
  restAvatar,
  restName,
  restInfoDropdown,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Render avatar and name
   */
  const renderAvatarAndName = () => {
    if (!user) return null;

    const size = restAvatar?.size || 18;
    const newUser = convertUserForSubmitData(user);

    return (
      <>
        <Popover
          content={
            <Row className="p-3" onClick={e => e.stopPropagation()}>
              <Col style={{ flex: '0 0 50px', maxWidth: 50 }}>
                <UserAvatar user={user} hideName={true} showInfoDropdown={false} restAvatar={{ size: 40 }} />
              </Col>

              <Col style={{ flex: '1 1 calc(100% - 50px)', maxWidth: 'calc(100% - 50px)' }}>
                <div className={`font-size-16 font-weight-medium mb-1 ${inactive ? 'text-gray' : ''}`}>
                  {newUser?.name || user.name || user.username}
                  {inactive && <span className="font-size-14 font-weight-normal">{` - ${t('common.inactive')}`}</span>}
                </div>

                <Row title={`${t('user.username')}: ${user.username}`} align="middle" wrap={false} className="mb-1">
                  <UserOutlined className="mr-1" /> {user.username}
                </Row>

                <Row title={`${t('user.email')}: ${user.email}`} align="middle" wrap={false}>
                  <MailOutlined className="mr-1" /> <a href={`mailto:${user.email}`}>{user.email}</a>
                </Row>
              </Col>
            </Row>
          }
          placement="bottom"
          mouseEnterDelay={showInfoDropdown ? 1 : 86400}
          overlayStyle={{ minWidth: 300 }}
          destroyTooltipOnHide={true}
          overlayClassName="c-user-avatar-popover"
          zIndex={9999}
          {...restInfoDropdown}
        >
          <div className="box-avatar text-truncate">
            <Avatar
              src={newUser?.avatar || null}
              size={size}
              style={{
                flex: `0 0 ${size}px`,
                fontSize: size * 0.6,
                backgroundColor: newUser?.avatar
                  ? '#dfdfdf'
                  : user.username
                  ? AVATAR_BACKGROUND_COLORS[
                      getTotalUnicodeIndexOfString(user.username) % AVATAR_BACKGROUND_COLORS.length
                    ]
                  : '#dfdfdf'
              }}
              className={`${hideName ? '' : 'mr-1'}`}
              {...restAvatar}
            >
              {newUser?.name && /\p{Extended_Pictographic}/u.test(newUser?.name)
                ? user.username.trim().charAt(0).toUpperCase()
                : newUser?.name && !/\p{Extended_Pictographic}/u.test(newUser?.name)
                ? newUser?.name.trim().charAt(0).toUpperCase()
                : user.username || user.email
                ? (user.username || user.email).trim().charAt(0).toUpperCase()
                : 'G'}
            </Avatar>

            {!hideName && (
              <span
                {...restName}
                className={`txt-name text-truncate ${inactive ? 'text-gray' : ''} ${restName?.className || ''}`}
              >
                {showFullname ? newUser?.name || user.username || user.email : user.username || user.email}
                {inactive ? ` - ${t('common.inactive')}` : null}
              </span>
            )}
          </div>
        </Popover>
      </>
    );
  };

  return (
    <div className={`c-user-avatar ${className}`} {...rest}>
      {renderAvatarAndName()}
    </div>
  );
};
