import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import objectPath from 'object-path';
import { useStoreActions } from 'easy-peasy';
import { Modal } from 'antd';

import {
  COMPONENT_TYPE,
  SS_NEW_RECORDS,
  SS_EDITING_CELL,
  SYSTEM_FIELD_KEY,
  SS_NEW_TEST_STEPS,
  SS_LAST_SAVED_VALUE,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_VERSION,
  SYSTEM_FIELD_PRIORITY,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_TEST_CASE,
  SYSTEM_FIELD_SCRIPT_PATH
} from '../../constants';
import { reactSessionStorage } from '../session-storage';
import { removeDuplicate, checkIsUserInProject, convertRawHtmlToPlainText, convertMinutesToShortTime } from '../utils';
import { useUnassignedUser } from './use-unassigned-user';
import { UserAvatar, SafeInnerHtml } from '../../components';

export const useEditableCell = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For global store
  const setIsShownConfirmDiscardChangesModal = useStoreActions(
    action => action.global.setIsShownConfirmDiscardChangesModal
  );

  /**
   * Get last user option in session storage
   */
  const getLastUserOptionInSessionStorage = ({ formItemName, projectUserList }) => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    let lastOption = undefined;

    if (lastValue !== undefined) {
      const isUserInProject = checkIsUserInProject({ user: lastValue, projectUserList });

      lastOption = {
        label: <UserAvatar user={lastValue} inactive={!isUserInProject} />,
        value: lastValue?.username,
        disabled: !isUserInProject,
        user: lastValue
      };
    }

    return lastOption;
  };

  /**
   * Handle sort end
   */
  const handleSortEnd = ({ data, dropInfo, setLoading }) => {
    if (
      !dropInfo ||
      dropInfo?.oldIndex === dropInfo?.newIndex ||
      dropInfo?.oldIndex === -1 ||
      dropInfo?.newIndex === -1
    ) {
      return;
    }

    let newPosition = dropInfo.newIndex;
    let oldPosition = dropInfo.oldIndex;
    let isMoveUp = newPosition < oldPosition;
    let newData = Array.isArray(data) && data.length ? [...data] : [];
    const indexOfIsFirstNewRow = newData.findIndex(item => item?.isNew);
    const body = [];

    // Disable drag bellow isNew row
    if (indexOfIsFirstNewRow !== -1 && newPosition >= indexOfIsFirstNewRow) return;

    // Set loading
    if (typeof setLoading === 'function') setLoading(true);

    // Calculate topOrderId
    let bottomOrderId = isMoveUp ? newData[oldPosition]?.orderId : newData[newPosition]?.orderId;
    bottomOrderId = /^[0-9]*$/.test(bottomOrderId) ? Number(bottomOrderId) : 1;
    let topOrderId = bottomOrderId + Math.abs(oldPosition - newPosition);

    // Has invalid order ID => Re-order all of rows on current page
    if (newData.some(item => !/^[0-9]*$/.test(item?.orderId))) {
      const firstOrderId = newData[0]?.orderId;

      newPosition = 0;
      oldPosition = newData.length - 1;
      isMoveUp = newPosition < oldPosition;

      // Calculate topOrderId
      if (/^[0-9]*$/.test(firstOrderId)) {
        topOrderId = Number(firstOrderId);
      } else {
        topOrderId = newData.length;
      }
    }

    newData = arrayMoveImmutable(newData, oldPosition, newPosition);

    newData = newData.map((item, idx) => {
      const newItem = { ...item };

      const isInScope =
        (isMoveUp && idx >= newPosition && idx <= oldPosition) ||
        (!isMoveUp && idx >= oldPosition && idx <= newPosition);

      if (isInScope) {
        newItem.orderId = topOrderId;
        topOrderId = topOrderId - 1;
        body.push({ key: newItem.key, orderId: newItem.orderId });
      }

      return newItem;
    });

    return { newData, body };
  };

  /**
   * Check has new items
   */
  const checkHasNewItems = () => {
    const recordsInSession = reactSessionStorage.getObject(SS_NEW_RECORDS, {});
    const testStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    const newHasNewItems = Object.keys(recordsInSession).length > 0 || Object.keys(testStepsInSession).length > 0;

    return newHasNewItems;
  };

  /**
   * Show confirm discard changes modal
   */
  const showConfirmDiscardChangesModal = ({ onOk, onCancel }) => {
    if (document.querySelector('.c-confirm-discard-changes-modal')) return;

    setIsShownConfirmDiscardChangesModal(true);

    Modal.confirm({
      title: t('common.confirmDiscardChanges'),
      content: <SafeInnerHtml html={t('message.ifYouCancelNowYouWillLoseYourChanges')} />,
      width: 600,
      autoFocusButton: null,
      maskClosable: true,
      className: 'c-confirm-discard-changes-modal',
      okText: t('common.discardChanges'),
      cancelText: t('common.noAction'),
      onOk: () => {
        Modal.destroyAll();
        setIsShownConfirmDiscardChangesModal(false);
        if (typeof onOk === 'function') onOk();
      },
      onCancel: () => {
        Modal.destroyAll();
        setIsShownConfirmDiscardChangesModal(false);
        if (typeof onCancel === 'function') onCancel();
      }
    });
  };

  /**
   * On set new values to session
   */
  const onSetNewValuesToSession = async ({ formData, row, field }) => {
    if (!formData || !row?.key || !field) return;

    const newRecordsInSession = reactSessionStorage.getObject(SS_NEW_RECORDS, {});
    let newRecord = newRecordsInSession[row.key] || {};

    newRecord = {
      key: row.key,
      ...newRecord,
      ...formData
    };

    reactSessionStorage.setObject(SS_NEW_RECORDS, { ...newRecordsInSession, [row.key]: { ...newRecord } });
  };

  /**
   * Get active columns
   */
  const getActiveColumns = ({ columns, defaultRefNameShows }) => {
    if (
      !(Array.isArray(columns) && columns.length) ||
      !(Array.isArray(defaultRefNameShows) && defaultRefNameShows.length)
    ) {
      return [];
    }

    const keyColumn = columns.find(c => c?.dataIndex === SYSTEM_FIELD_KEY);
    const leftActionColumn = columns.find(c => c?.dataIndex === 'leftActionColumn');
    const actionColumn = columns.find(c => c?.dataIndex === 'action');

    let newColumns = defaultRefNameShows
      .map(refName => columns.find(c => c?.dataIndex === refName && c?.dataIndex !== SYSTEM_FIELD_KEY))
      .filter(c => c?.dataIndex);

    newColumns = [leftActionColumn, keyColumn, ...newColumns, actionColumn];
    newColumns = removeDuplicate(newColumns, 'dataIndex');

    return newColumns;
  };

  /**
   * On reset editable cells
   */
  const onResetEditableCells = ({ tableForm }) => {
    if (typeof tableForm?.resetFields !== 'function') return;

    tableForm.resetFields();
    reactSessionStorage.remove(SS_NEW_RECORDS);
    reactSessionStorage.remove(SS_NEW_TEST_STEPS);
    reactSessionStorage.remove(SS_EDITING_CELL);
    reactSessionStorage.remove(SS_LAST_SAVED_VALUE);
  };

  /**
   * Handle set field value
   * For set values to form
   */
  const handleGetFieldValue = ({ field, item, ticketListData }) => {
    if (!field) return;

    const val = objectPath.get(item, field?.refName);
    let newVal = null;

    switch (field?.refName) {
      case new RegExp(`${SYSTEM_FIELD_PRIORITY}$`, 'g').test(field?.refName) ? field?.refName : false: {
        newVal = val;
        break;
      }

      case new RegExp(`${SYSTEM_FIELD_ASSIGN_TO}$`, 'g').test(field?.refName) ? field?.refName : false: {
        newVal = val?.username || UNASSIGNED.value;
        break;
      }

      case new RegExp(`${SYSTEM_FIELD_TAG}$`, 'g').test(field?.refName) ? field?.refName : false: {
        newVal = val && typeof val === 'string' ? val.split(',') : [];
        break;
      }

      case new RegExp(`${SYSTEM_FIELD_SCRIPT_PATH}$`, 'g').test(field?.refName) ? field?.refName : false: {
        newVal = objectPath.get(item, `${SYSTEM_FIELD_TEST_CASE}.${SYSTEM_FIELD_SCRIPT_PATH}`) || val;
        break;
      }

      case new RegExp(`${SYSTEM_FIELD_VERSION}$`, 'g').test(field?.refName) ? field?.refName : false: {
        newVal = `${val}`;
        break;
      }

      default: {
        switch (field?.componentType) {
          case COMPONENT_TYPE.HTML: {
            if (field?.keepRawHtml) {
              newVal = val;
            } else {
              newVal = convertRawHtmlToPlainText(val);
            }

            break;
          }

          case COMPONENT_TYPE.DATE:
          case COMPONENT_TYPE.DATE_TIME: {
            newVal = val && moment(val).isValid() ? moment(val) : null;
            break;
          }

          case COMPONENT_TYPE.TIME_TRACKING: {
            const et = convertMinutesToShortTime(val);
            newVal = et || null;

            break;
          }

          case COMPONENT_TYPE.USER: {
            newVal = val?.username;
            break;
          }

          case COMPONENT_TYPE.STATUS: {
            const defaultState = ticketListData?.[field?.workTicketId]?.workFlow?.defaultState;
            newVal = val?.id || defaultState?.id || null;
            break;
          }

          case COMPONENT_TYPE.PICKLIST: {
            newVal = Array.isArray(val) && val.length ? [...val].filter(v => v) : [];
            break;
          }

          case COMPONENT_TYPE.SUGGESTION: {
            newVal = objectPath.get(val, field?.refName);

            if (val !== null && typeof val === 'object' && Object.keys(val).length) {
              newVal = objectPath.get(val, field?.data?.displayField);
            }

            break;
          }

          case COMPONENT_TYPE.RELATION: {
            newVal = objectPath.get(val, field?.data?.fieldValue);

            break;
          }

          default: {
            newVal = val;
          }
        }
      }
    }

    return newVal;
  };

  return {
    getLastUserOptionInSessionStorage,
    handleSortEnd,
    checkHasNewItems,
    showConfirmDiscardChangesModal,
    onSetNewValuesToSession,
    getActiveColumns,
    onResetEditableCells,
    handleGetFieldValue
  };
};
