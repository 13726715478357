import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS, TREE_ROOT } from '../../../../constants';
import { Http, handleError, removeDuplicate } from '../../../../common';

export const model = {
  /**
   * State
   */
  tree: null, //  Tree for show on UI,
  repository: null,
  editingRepository: {},
  selectedTreeItem: {},
  expandedKeys: null,
  loadingRepository: false,
  loadingRepositoryItem: false,

  /**
   * Action: Set list repository (ant design tree, tree on UI)
   */
  setRepository: action((state, payload) => {
    if (state?.repository === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.repository = [];
      return;
    }

    state.repository = payload.rows;
  }),

  /**
   * Action: Set tree (ant design tree, tree on UI)
   */
  setTree: action((state, payload) => {
    if (state?.tree === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.tree = [];
      return;
    }

    state.tree = payload;
  }),

  /**
   * Action: Set editing folder
   */
  setEditingRepository: action((state, payload) => {
    if (state?.editingRepository === undefined) return;

    state.editingRepository = payload;
  }),

  /**
   * Action: Set selected tree item
   */
  setSelectedTreeItem: action((state, payload) => {
    if (state?.selectedTreeItem === undefined) return;

    state.selectedTreeItem = payload;
  }),

  /**
   * Action: Set expanded keys
   */
  setExpandedKeys: action((state, payload) => {
    if (state?.expandedKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedKeys = [TREE_ROOT];
      return;
    }

    state.expandedKeys = removeDuplicate([TREE_ROOT, ...payload], '');
  }),

  /**
   * Action: Set loading repo data
   */
  setLoadingRepository: action((state, payload) => {
    if (state?.loadingRepository === undefined) return;

    state.loadingRepository = payload;
  }),

  /**
   * Action: Set loading repository item
   */
  setLoadingRepositoryItem: action((state, payload) => {
    if (state?.loadingRepositoryItem === undefined) return;

    state.loadingRepositoryItem = payload;
  }),

  /**
   * Action: Call api to get repository tree data
   */
  getRepositoryList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingRepository(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/get-tree`;
      const res = await Http.get(url).then(res => res.data);

      action.setRepository(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepository(false);
    }
  }),

  /**
   * Action: Call api to get repository by Id
   */
  getRepositoryById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingRepositoryItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingRepository(res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to create repository
   */
  createRepository: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingRepositoryItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageCreatingFolder'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to update repository
   */
  updateRepository: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('Invalid Payload');

      action.setLoadingRepositoryItem(true);

      const newPayload = { ...payload };
      const { id, noGetRepositoryList } = newPayload;
      delete newPayload.id;
      delete newPayload.noGetRepositoryList;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageUpdatingFolder'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      if (!noGetRepositoryList) {
        action.getRepositoryList();
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to delete repository
   */
  deleteRepository: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingRepositoryItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageDeletingFolder'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to move repository
   */
  moveRepository: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Move repository: Invalid params');
      }

      if (!payload?._id) throw new Error('Move repository: No Id');

      action.setLoadingRepositoryItem(true);

      const newPayload = { ...payload };
      const { _id, noGetRepositoryList } = newPayload;
      delete newPayload._id;
      delete newPayload.noGetRepositoryList;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${_id}/move`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testRepo.messageUpdatingFolder'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      if (!noGetRepositoryList) {
        action.getRepositoryList();
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to assign for repository folder
   */
  assignForRepositoryFolder: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload?.id) throw new Error('Invalid Payload');

      action.setLoadingRepositoryItem(true);

      const newPayload = { ...payload };
      const { id } = newPayload;
      delete newPayload.id;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${id}/auto-assign`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:common.success'),
        description: i18next.t('akaat:message.yourChangesHasBeenSaved')
      });

      action.getRepositoryList();

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepositoryItem(false);
    }
  }),

  /**
   * Action: Call api to update field config test repo by key
   */
  updateFieldConfigTestRepoByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.key) throw new Error('Update field config test repo by key: No key');

      action.setLoadingRepository(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}/folders/${payload.key}`;
      const res = await Http.put(url, payload.body).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRepository(false);
    }
  })
};
