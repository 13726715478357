import env from '../env';

/**
 * Endpoint declaration
 */
export const ENDPOINTS = {
  _HOST: /^http/.test(env.REACT_APP_API_ENDPOINT)
    ? env.REACT_APP_API_ENDPOINT
    : `${window.location.protocol}//${window.location.hostname}${env.REACT_APP_API_ENDPOINT}`,

  // For project
  MANAGER: '/manager',
  BIG_DATA: '/big-data',
  IDENTITY: '/identity',
  SOCKET_NAMESPACE: '/web-client-ms',
  TESTMAN_NAMESPACE: '/testMan-ws',
  PERMISSION: '/permission',
  MANAGER_COMMON: '/common',
  DASHBOARD: '/dashboard',
  CHART: '/chart',
  TEST_MANAGEMENT: '/test-management',
  TESTCASE: '/testcase',
  TESTCASE_STATUS: '/status',
  TESTCASE_CLONE: '/testcase/clone',
  TESTCASE_VERSION: '/testcase-version',
  TESTCASE_ROLLBACK_VERSION: '/roll-back',
  TESTCASE_AUTO: '/test-step-auto',
  TEST_PLAN_RELEASE: '/release',
  TEST_PLAN_CYCLE: '/cycle',
  TEST_PLAN_TEST_SUITE: '/test-suite',
  TEST_PLAN_TEST_RUN: '/test-run',
  LIST_TEST_RUN_BY_KEYS: '/list-test-run-by-keys',
  TEST_PLAN_TEST_RUN_SUMMARY: '/test-run/summary',
  REPORT_TREND_CHART_BY_STATUS: '/chart-by-status',
  REPORT_TREND_CHART_BY_USER: '/chart-by-user',
  REPORT_MOST_EXECUTED_TEST_CASES: '/chart-by-most-executed-testcase',
  REPORT_MOST_FAILED_TEST_CASES: '/chart-by-most-failed-testcase',
  REPORT_PLANNED_VS_EXECUTED: '/chart-by-plan-actual',
  REPORT_DAILY_MEMBER: '/chart-member-daily-report',
  REPORT_TESTING_BURNDOWN: '/testing-burndown',
  REPORT: '/report',
  REPORTING: '/reporting',
  REPORT_PREVIEW: '/report/preview',
  IMPORT_MAPPING: '/import-mapping',
  EXPORT_MAPPING: '/export-mapping',
  EXPORT_MAPPING_TEMPLATE: '/export-mapping/template',
  IMPORT_PREVIEW: '/import/preview',
  IMPORT_FILE: '/manager/import/file',
  TESTCASE_IMPORT: '/testcase/import',
  TESTRUN_IMPORT: '/test-run/import',
  TESTRUN_WORK_TICKET: '/ticket/multiple',
  FILE_UPLOAD: '/automation/upload',
  FILES: '/files',
  FILE: '/file',
  FIRST_UPLOAD_PATH: 'akaat-manager',
  COMMENT: '/comment',
  HISTORY: '/history',
  TEST_RESULT: '/test-result',
  SEARCH_GET_FIELD: '/manager/search/get-field',
  EXPORT_GET_FIELD: '/manager/export/get-field',
  WORK_TICKET: '/work-ticket',
  WORK_TICKET_CLONE: '/ticket/clone',
  WORK_ITEM_SYSTEM: 'manager/system',
  WORK_FLOW: '/work-flow',
  SYSTEM: '/system',
  PROCESS: '/process',
  TEMPLATE: '/ticket-type',
  RELATION_TYPE: '/relation-type',
  OBJECTIVE: '/objective',
  RELATION: '/relation',
  EXPORT: '/export',
  TRACEABILITY: '/traceability',

  // Integration
  INTEGRATION: '/integrate',
  AZURE: '/azure',
  JIRA: '/jira',

  // Jira Integration
  JIRA_AUTH_URL: '/integrate/jira/auth-url',
  JIRA_ACCESS_TOKEN: '/integrate/jira/access-token',
  JIRA_ISSUE: '/integrate/jira/issue',
  JIRA_ISSUE_COMPONENT_LIST: '/integrate/jira/componentList',
  JIRA_ISSUE_USER_LIST: '/integrate/jira/userList',
  JIRA_ISSUE_VERSION_LIST: '/integrate/jira/versionList',
  JIRA_ISSUE_STATUS_LIST: '/integrate/jira/statusList',
  JIRA_ISSUE_STATUS: '/integrate/jira/issue/status',
  JIRA_ISSUE_CREATE_META: '/integrate/jira/issue/createmeta',
  JIRA_ISSUE_USER_ASSIGNABLE: '/integrate/jira/user-assignable',
  JIRA_ISSUE_USER_ATTACHMENT: '/integrate/jira/attachment',

  JIRA_REST_API_ISSUE_LINK_TYPES: '/rest/api/2/issueLinkType',

  // Engine
  AUTOMATION: '/automation',
  AUTOMATION_JOB: '/job',
  AUTOMATION_JOB_HISTORY: '/job-history',
  AUTOMATION_JOB_EXECUTE: '/job/execute',
  AUTOMATION_TEST_CONFIG: '/test-config',
  AUTOMATION_TEST_CONFIG_VARIABLE: '/test-config-variable',
  AUTOMATION_JOB_RUNNER: '/job-runner',
  AUTOMATION_JOB_RUNNER_LOGS: '/job-runner-logs',
  AUTOMATION_AGENT_POOL: '/agent-pool',
  AUTOMATION_AGENT: '/agent',
  AUTOMATION_TEST_RESULT: '/test-result',
  AUTOMATION_COLLECTION: '/collection',
  AUTOMATION_COMMENT: '/comment',
  AUTOMATION_IMPORT_PREVIEW_TEST_RESULT: '/import/preview-test-result',
  AUTOMATION_EXPORT_EXCEL: '/export/excel',
  AUTOMATION_EXPORT_CSV: '/export/csv',
  AUTOMATION_FILTER: '/filter',
  AUTOMATION_EXPORT_TEST_RESULT: '/export/test-result',
  AUTOMATION_REPORT_TEST_CASE_EXECUTION_PROGRESS: '/report/chart/test-case-execution-progress',
  AUTOMATION_REPORT_TREND_CHART_BY_STATUS: '/report/chart/trend-chart-by-status',
  AUTOMATION_REPORT_MOST_EXECUTED_TEST_CASES: '/report/chart/trend-chart-by-most-executed-testcase',
  AUTOMATION_REPORT_MOST_FAILED_TEST_CASES: '/report/chart/trend-chart-by-most-failed-testcase',
  AUTOMATION_REPORT_CONFIG: '/report-config',
  AUTOMATION_REPORT_PREVIEW: '/report/preview',
  LICENSE_TENANT_TRANSACTION: '/transaction',
  AUTOMATION_IMPORT_FILE: '/import/file',
  AUTOMATION_EXTENSION: '/extention',

  // authen-service
  MY_TENANTS: '/my/tenants',
  MY_PROJECTS: '/my/projects',
  PROJECT: '/project',
  TENANT: '/tenant',
  TEAM: '/teams',
  USER: '/user',
  USERS: '/users',
  TENANT_ROLES: '/tenant-roles',
  PROJECT_ROLES: '/project-roles',
  MY_PERMISSIONS: '/my-permissions',
  USER_INFO: '/user/user-info',

  // licenses
  LICENSE_MANAGEMENT: '/license-management',
  LICENSES: '/licenses',
  BILL_HISTORY: '/bill-history',
  PRODUCTS: '/products',

  // For event
  EVENT: '/event',
  EVENT_EMAIL_SEND: '/event/email/send',
  EVENT_NOTIFICATION: '/notification',

  // strapi support api
  DOWNLOAD_AGENT:
    '/api/versions/?sort=id:DESC&filters[type][$eq]=Agent&pagination[page]=1&pagination[pageSize]=10&locale=en&populate=*',
  DOWNLOAD_TEST_FLOW_AGENT:
    '/api/test-flow-resources/?sort=id:DESC&pagination[page]=1&pagination[pageSize]=10&locale=en&populate=*',

  // Web socket event
  SOCKET_EVENT_TASK_STATUS: 'task-status',

  // Sync data jira to TestMan
  SYNC_JIRA_ONE_PROJECT: 'sync-jira-one-project'
};
