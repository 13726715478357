import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Popover, Tooltip } from 'antd';

import './style.scss';

export const BasicProgressBar = ({
  progressData,
  summaryTooltipTitle,
  showSummaryTooltip,
  showItemTooltip,
  className = '',
  restPopover,
  children,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Render bar
   */
  const Bar = ({ className = '' }) => {
    return (
      <Row justify="end" className={`c-basic-progress-bar-wrapper ${className}`}>
        {Array.isArray(progressData?.data) &&
          progressData?.data.length > 0 &&
          !isNaN(progressData?.total) &&
          progressData?.data.map(item => {
            if (isNaN(item.value)) return null;

            const percent = `${((item.value / progressData?.total) * 100).toFixed(2)}%`;

            return (
              <Col
                key={item.label}
                className={`progress-bar ${typeof item.className === 'string' ? item.className : ''}`}
                style={{ width: `${(item.value / progressData?.total) * 100}%`, backgroundColor: item.color || null }}
              >
                {showItemTooltip && (
                  <Tooltip
                    placement="top"
                    title={<div className="font-size-16 font-weight-medium">{`${percent} ${item.label}`}</div>}
                    destroyTooltipOnHide={true}
                  >
                    <div className="d-block w-100 h-100"></div>
                  </Tooltip>
                )}
              </Col>
            );
          })}
      </Row>
    );
  };

  return (
    <div className={`c-basic-progress-bar ${className}`} {...rest}>
      {showSummaryTooltip && (
        <Popover
          content={
            <div className="box-content" onClick={e => e.stopPropagation()}>
              {summaryTooltipTitle && (
                <div className="font-weight-medium text-white mt-0 mb-2" style={{ fontSize: 18 }}>
                  {summaryTooltipTitle}
                </div>
              )}

              <Bar className="mb-2" />

              <ul className="list">
                {Array.isArray(progressData?.data) && progressData?.data.length > 0 && !isNaN(progressData?.total) ? (
                  progressData?.data.map(item => {
                    if (isNaN(item.value)) return null;

                    const percent = `${item.value}/${((item.value / progressData?.total) * 100).toFixed(2)}%`;

                    return (
                      <li key={item.label} className="item">
                        <div title={item.label} className="txt-label text-truncate pr-2" style={{ color: item.color }}>
                          {item.label}
                        </div>
                        <div className="txt-percent text-nowrap">{percent}</div>
                      </li>
                    );
                  })
                ) : (
                  <div className="text-center text-white pt-2 pb-3">{t('common.noData')}</div>
                )}
              </ul>
            </div>
          }
          mouseEnterDelay={0.3}
          destroyTooltipOnHide={true}
          overlayClassName="c-basic-progress-bar-popover"
          {...restPopover}
        >
          <div className="progress-bar-popover"></div>
        </Popover>
      )}

      {children ? children : <Bar />}
    </div>
  );
};
