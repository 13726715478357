import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Dropdown, Tooltip, DatePicker, Select } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { COMPONENT_TYPE, FULL_DATE_FORMAT, SHORT_DATE_FORMAT } from '../../constants';

export const FieldDate = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentOperation, setCurrentOperation] = useState(null);

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.refName || !(Array.isArray(currentValues) && currentValues.length)) return null;

    const newField = [...currentValues].find(item => item.refName === field.refName);
    const isRange = newField?.operation === 'in range' || newField?.operation === 'not in range';

    if (
      isRange &&
      !(
        Array.isArray(newField?.value) &&
        newField.value.length &&
        newField.value.every(sub => sub && moment(sub).isValid())
      )
    ) {
      return null;
    } else if (!isRange && !(newField?.value && moment(newField.value).isValid())) {
      return null;
    } else {
    }

    if (isRange) {
      newField.value = [...newField.value].map(sub => moment(sub));
    } else {
      newField.value = moment(newField.value);
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Compute date format
   */
  const dateFormat = useMemo(() => {
    return field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
  }, [field]);

  /**
   * Set current operation
   */
  useEffect(() => {
    if (!field?.refName) return;

    const newCurrentOperation = currentField?.operation
      ? currentField.operation
      : Array.isArray(field.supportedOperations) && field.supportedOperations.length
      ? field.supportedOperations[0]
      : null;

    setCurrentOperation(newCurrentOperation);
  }, [field, currentField]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) setCurrentDate(currentField?.value || null);
  };

  /**
   * Handle apply
   */
  const handleApply = () => {
    let newDate = null;
    const isRange = currentOperation === 'in range' || currentOperation === 'not in range';

    if (
      isRange &&
      Array.isArray(currentDate) &&
      currentDate.length &&
      currentDate[0] &&
      currentDate[1] &&
      moment(currentDate[0].isValid() && moment(currentDate[1].isValid()))
    ) {
      newDate = [currentDate[0], currentDate[1]];
    } else if (!isRange && currentDate && moment(currentDate.isValid())) {
      newDate = currentDate;
    }

    onApply(newDate, currentOperation);
    setTimeout(() => setVisibleDropdown(false), 300);
  };

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const items = [
      {
        key: 'field',
        label: (
          <span className="ant-input-group-wrapper">
            <span className="ant-input-wrapper ant-input-group">
              <span className="ant-input-group-addon">
                <Select
                  value={currentOperation}
                  options={
                    Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
                      ? field.supportedOperations.map(sub => {
                          return {
                            label: sub,
                            value: sub
                          };
                        })
                      : []
                  }
                  dropdownMatchSelectWidth={false}
                  onSelect={val => {
                    setCurrentDate(null);
                    setCurrentOperation(val);
                  }}
                />
              </span>

              {currentOperation === 'in range' || currentOperation === 'not in range' ? (
                <DatePicker.RangePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  showTime={{ format: 'HH:mm' }}
                  showNow
                  className="w-100 111"
                  onChange={setCurrentDate}
                />
              ) : field.componentType === COMPONENT_TYPE.DATE_TIME ? (
                <DatePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={{ format: 'HH:mm' }}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                />
              ) : (
                <DatePicker
                  value={currentDate}
                  format={SHORT_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={false}
                  showNow
                  className="w-100 333"
                  onChange={setCurrentDate}
                />
              )}
            </span>
          </span>
        ),
        className: 'bg-transparent'
      }
    ];

    items.push(
      { type: 'divider' },
      {
        key: 'box-footer',
        label: (
          <>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setCurrentDate(null);
                onClear();
              }}
            >
              {t('common.clear')}
            </Button>

            <Button type="primary" size="small" className="w-auto ml-2" onClick={handleApply}>
              {t('common.apply')}
            </Button>
          </>
        ),
        className: 'bg-transparent text-right cursor-default'
      }
    );

    return items;
  }, [currentOperation, field, currentDate, dateFormat]);

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field || !dateFormat) return;

    const isRange = currentField?.operation === 'in range' || currentField?.operation === 'not in range';
    const dateText =
      currentField && isRange && Array.isArray(currentField?.value) && currentField?.value.length
        ? `${moment(currentField.value[0]).format(dateFormat)} ⇀ ${moment(currentField.value[1]).format(dateFormat)}`
        : currentField
        ? moment(currentField.value).format(dateFormat)
        : 'N/A';

    return (
      <Button
        title={
          currentField
            ? `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${currentField.operation} ${dateText}`
            : `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${t('common.all')}`
        }
        className={`btn-field field-date ${className}`}
        {...rest}
      >
        {currentField ? (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {currentField.operation} {dateText}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{ items: menus, selectable: false }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ minWidth: 290 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
