import { Form, Select } from 'antd';
import { useStoreState } from 'easy-peasy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_WORK_ITEM_ICONS, TICKET_WITH_RELATED_TYPE } from '../../../constants';
import { getObjectByValue } from '../../../common';
import { BugSearching } from '../../../assets/svg-icons';

export default ({ setRelation, relation, form, onChangeWorkTicketType }) => {
  const [t] = useTranslation('akaat');

  // For global store
  const globalProcessList = useStoreState(state => state.global.globalProcessList);
  const relationTypeList = useStoreState(state => state.global.relationTypeList);

  useEffect(() => {
    if (!Array.isArray(globalProcessList) || !globalProcessList.length || !form) return;

    form.setFieldsValue({
      workTicketType: globalProcessList[0]?.workTicketId
    });

    setRelation({ ...relation, workTicketType: globalProcessList[0]?.workTicketId });
  }, []);

  /**
   * Handle mapping from ticket type with realted type
   */
  const handleMappingTicketWithRelatedType = ticketId => {
    if (!Array.isArray(relationTypeList) || !relationTypeList.length) return;
    const relatedTypes = TICKET_WITH_RELATED_TYPE?.[ticketId] || [];

    let newRelationTypeList = relationTypeList.filter(relation => relatedTypes.some(type => type === relation.refName));

    if (!newRelationTypeList.length) {
      newRelationTypeList = relationTypeList;
    }

    // Set relation to ticket data
    setRelation({ ...relation, workTicketType: ticketId });

    // Set relation list
    // setCurrentRelationList(newRelationTypeList);
  };
  return (
    <>
      <Form.Item
        label={t('process.workTicketType')}
        name="workTicketType"
        rules={[{ required: true, message: t('message.required') }]}
      >
        <Select
          allowClear
          placeholder={t('common.pleaseSelect')}
          onChange={val => {
            handleMappingTicketWithRelatedType(val);
            onChangeWorkTicketType();
          }}
        >
          {Array.isArray(globalProcessList) &&
            globalProcessList.length > 0 &&
            globalProcessList.map(workTicket => {
              const iconWorkTicket = getObjectByValue(workTicket?.icon, DEFAULT_WORK_ITEM_ICONS);

              return (
                <Select.Option
                  value={workTicket?.workTicketId}
                  key={workTicket?.workTicketId}
                  title={workTicket?.name}
                  id={workTicket?.workTicketId}
                >
                  <span className="mr-1">{iconWorkTicket?.icon ? iconWorkTicket?.icon : <BugSearching />}</span>
                  {workTicket?.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    </>
  );
};
