import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import env from '../../env';
import {
  LS_PRODUCTION_KEY,
  HOME_MENU,
  PROJECT_MANAGER_MENU,
  PROJECT_SETTING_MENU,
  PROJECT_ENGINE_MENU,
  PRODUCT_KEY_ON_URL_MANAGER,
  PRODUCT_KEY_ON_URL_ENGINE,
  ROLE_APP_ACCESS_MANAGER,
  ROLE_APP_ACCESS_TESTENGINE
} from '../../constants';
import { reactLocalStorage, getParentMenuByPathname, buildBreadcrumbListByMenus } from '../../common';
import { BugSearching } from '../../assets/svg-icons';

import './style.scss';

let PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU];

export const BasicBreadcrumb = ({ className = '', routes = [], addedRoutes = [], ...rest }) => {
  const productionKeyLocal = reactLocalStorage.get(LS_PRODUCTION_KEY);
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);
  const globalProductionKey = useStoreState(state => state.global.globalProductionKey);

  // Component state
  const [menuList, setMenuList] = useState([]);
  const [workItemList, setWorkItemList] = useState([]);

  /**
   * Set work item list
   */
  useEffect(() => {
    if (!(Array.isArray(globalProcessList) && globalProcessList.length)) return;

    const list = globalProcessList.map(item => {
      return {
        id: `${item?._id}`,
        parentId: null,
        groupId: 'workItems',
        path: `/${
          env.REACT_APP_PREFIX_PATH
        }${tenantPath}/${projectPath}/manager/work-item/${item?.workTicket?.name?.toLowerCase()}`,
        name: `${item?.name}`,
        icon: <BugSearching />
      };
    });

    setWorkItemList(list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, globalProcessList]);

  /**
   * Set menu list
   */
  useEffect(() => {
    if (!(Array.isArray(routes) && routes.length)) {
      const belongToHomePages = getParentMenuByPathname(location.pathname, HOME_MENU);
      const belongToProjectSettingPage = getParentMenuByPathname(location.pathname, [...PROJECT_SETTING_MENU]);

      if (Array.isArray(workItemList) && workItemList.length) {
        PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU, ...workItemList];
      }

      const projectMenus = [...PROJECT_MANAGER_MENU_EXTEND, ...PROJECT_ENGINE_MENU];

      const newMenuList = belongToHomePages
        ? HOME_MENU
        : belongToProjectSettingPage
        ? [...PROJECT_SETTING_MENU]
        : projectMenus;

      if (JSON.stringify(newMenuList) !== JSON.stringify(menuList)) {
        setMenuList(newMenuList);
      }

      return;
    }

    setMenuList(routes);
  }, [routes, location, menuList, workItemList]);

  /**
   * On click to route link
   */
  const onClickToRouteLink = (e, path) => {
    if (
      window.location.pathname.includes('/_settings') &&
      (/\/manager\/dashboard$/.test(path) || /\/engine\/summary$/.test(path))
    ) {
      e?.stopPropagation();
      window.location.href = path;
    }
  };

  /**
   * Render breadcrumb
   */
  const renderBreadcrumb = () => {
    if (!(Array.isArray(menuList) && menuList.length)) return null;

    if (Array.isArray(workItemList) && workItemList.length) {
      PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU, ...workItemList];
    }

    const belongToHomePages = getParentMenuByPathname(location.pathname, HOME_MENU);
    const belongToProjectSettingPages = getParentMenuByPathname(location.pathname, PROJECT_SETTING_MENU);
    const belongToProjectPages = getParentMenuByPathname(location.pathname, [
      ...PROJECT_MANAGER_MENU_EXTEND,
      ...PROJECT_ENGINE_MENU
    ]);

    const homeLink = {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}`,
      name: 'menu.home',
      icon: <HomeOutlined />,
      isHome: true
    };
    const tenantLink = {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}`,
      name: globalTenant?.name || 'Tenant',
      keepLink: true,
      noTranslate: true
    };
    const managerDashboardLink = {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/dashboard`,
      name: globalProject?.name || 'Project',
      keepLink: true,
      noTranslate: true
    };
    const testEngineDashboardLink = {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/summary`,
      name: globalProject?.name || 'Project',
      keepLink: true,
      noTranslate: true
    };
    const defaultBreadcrumbs = [homeLink];

    // Home link
    if (belongToHomePages) {
      defaultBreadcrumbs.push(tenantLink);
    }

    // Project setting link
    else if (belongToProjectSettingPages) {
      const hasManagerRole =
        Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_MANAGER);

      const hasTestengineRole =
        Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_TESTENGINE);

      defaultBreadcrumbs.push(tenantLink);

      if (productionKeyLocal === PRODUCT_KEY_ON_URL_MANAGER && hasManagerRole) {
        defaultBreadcrumbs.push(managerDashboardLink);
      } else if (productionKeyLocal === PRODUCT_KEY_ON_URL_ENGINE && hasTestengineRole) {
        defaultBreadcrumbs.push(testEngineDashboardLink);
      } else if (!productionKeyLocal && hasTestengineRole) {
        defaultBreadcrumbs.push(testEngineDashboardLink);
      } else if (!productionKeyLocal && hasTestengineRole) {
        defaultBreadcrumbs.push(testEngineDashboardLink);
      } else {
      }
    }

    // Project link (Management)
    else if (belongToProjectPages && globalProductionKey === PRODUCT_KEY_ON_URL_MANAGER) {
      defaultBreadcrumbs.push(managerDashboardLink);
    }

    // Project link (Test Engine)
    else if (belongToProjectPages && globalProductionKey === PRODUCT_KEY_ON_URL_ENGINE) {
      defaultBreadcrumbs.push(testEngineDashboardLink);
    } else {
    }

    return (
      <Breadcrumb className={`c-basic-breadcrumb ${className}`} {...rest}>
        {[...defaultBreadcrumbs, ...buildBreadcrumbListByMenus(location.pathname, menuList), ...addedRoutes].map(
          (item, index, arr) => {
            const isLast = index === arr.length - 1;

            const RouteText = () => (
              <>
                {item.isHome ? item.icon : ''} {item.noTranslate ? item.name : t(`${item.name}`)}
              </>
            );

            const RouteLink = ({ className = '' }) => (
              <Link
                to={{ pathname: item.path || '/' }}
                className={className}
                onClick={e => onClickToRouteLink(e, item.path)}
              >
                <RouteText />
              </Link>
            );

            return (
              <Breadcrumb.Item key={item.path}>
                {isLast && item.keepLink ? (
                  <RouteLink className="keep-link" />
                ) : item.noLink || isLast ? (
                  <RouteText />
                ) : (
                  <RouteLink />
                )}
              </Breadcrumb.Item>
            );
          }
        )}
      </Breadcrumb>
    );
  };

  return <>{renderBreadcrumb()}</>;
};
