import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import './style.scss';

export const MainFooter = ({ className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');
  const currentYear = moment().year();
  return (
    <div className={`c-main-footer ${className}`} {...rest}>
      <Row justify="space-between">
        <Col>
          <a href="http://akaat.com/" target="_blank" rel="noopener noreferrer">
            akaAT
          </a>{' '}
          {t('footer.copyright', { year: currentYear })}
        </Col>

        <Col>
          {t('footer.poweredBy')} <span className="powered">IVS.PRO-FPTSoftware</span>
        </Col>
      </Row>
    </div>
  );
};
