import React from 'react';
import DOMPurify from 'dompurify';

const DEFAULT_OPTIONS = {
  ADD_ATTR: ['target']
};

export const SafeInnerHtml = ({ html, options = DEFAULT_OPTIONS, className = '', ...rest }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, options) }}
      className={`safe-inner-html ${className}`}
      {...rest}
    />
  );
};
