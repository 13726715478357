import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../../constants';
import { Http, buildQuery, handleError } from '../../../../../common';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  editingTemplate: {},
  query: {},
  previewData: [],
  loadingList: false,
  loadingItem: false,
  loadingPreviewImportTestResultExcel: false,
  loadingImportTestResultExcel: false,

  /**
   * Action: Set template list
   */
  setTemplateList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing template
   */
  setEditingTemplate: action((state, payload) => {
    if (state?.editingTemplate === undefined) return;

    state.editingTemplate = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set preview data
   */
  setPreviewData: action((state, payload) => {
    if (state?.previewData === undefined) return;

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.previewData = [];
      return;
    }

    state.previewData = payload.data.map((item, index) => {
      return { ...item, index };
    });
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading preview import test result excel
   */
  setLoadingPreviewImportTestResultExcel: action((state, payload) => {
    if (state?.loadingPreviewImportTestResultExcel === undefined) return;

    state.loadingPreviewImportTestResultExcel = payload;
  }),

  /**
   * Action: Set loading import test result excel
   */
  setLoadingImportTestResultExcel: action((state, payload) => {
    if (state?.loadingImportTestResultExcel === undefined) return;

    state.loadingImportTestResultExcel = payload;
  }),

  /**
   * Action: Call api to get template list
   */
  getTemplateList: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.IMPORT_MAPPING}?q=${buildQuery(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTemplateList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get template by Id
   */
  getTemplateById: thunk(async (action, payload) => {
    try {
      if (!payload) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTemplate(res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create template
   */
  createTemplate: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = ENDPOINTS.IMPORT_MAPPING;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageCreatingTemplate'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update template
   */
  updateTemplate: thunk(async (action, payload) => {
    try {
      if (!payload?.id) throw new Error('Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.IMPORT_MAPPING}/${payload.id}`;
      const res = await Http.put(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageUpdatingTemplate'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete template
   */
  deleteTemplate: thunk(async (action, payload) => {
    try {
      if (!payload) throw new Error('Invalid Payload');

      const url = `${ENDPOINTS.IMPORT_MAPPING}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageDeletingTemplate'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to preview import test result excel
   */
  previewImportTestResultExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingPreviewImportTestResultExcel(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_IMPORT_PREVIEW_TEST_RESULT}`;
      const res = await Http.post(url, payload).then(res => res.data);

      action.setPreviewData(res);
      return res;
    } catch (err) {
      if (err?.status === 400 && err.data?.message.includes('Sheet name is not exist')) {
        return err;
      }

      handleError(err);
    } finally {
      action.setLoadingPreviewImportTestResultExcel(false);
    }
  }),

  /**
   * Action: Call api to import test result excel
   */
  importTestResultExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload) && payload.length)) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingImportTestResultExcel(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:template.messageImporting'),
        description: i18next.t('akaat:message.importedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingImportTestResultExcel(false);
    }
  })
};
