import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';

const AssignToFormItem = ({ field, formItemName, selectProps, restFormItem, restField }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Select {...selectProps} {...restField} />
    </Form.Item>
  );
};

export default AssignToFormItem;
