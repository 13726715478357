/**
 * Permissions
 */

// tenant_setting
export const P_TENANT_SETTING_UPDATE_INFORMATION = 'tenant_setting.update_information';
export const P_TENANT_SETTING_PROCESS = 'tenant_setting.process';
export const P_TENANT_SETTING_USER_SETUP = 'tenant_setting.user_setup';
export const P_TENANT_SETTING_PROJECT_SETUP = 'tenant_setting.project_setup';

// project_setting
export const P_PROJECT_SETTING_UPDATE_INFORMATION = 'project_setting.update_information';
export const P_PROJECT_SETTING_USER_SETUP = 'project_setting.user_setup';
export const P_PROJECT_SETTING_SETUP_TEAM = 'project_setting.setup_team';
export const P_PROJECT_SETTING_NOTIFICATION_SETUP = 'project_setting.notification_setup';
export const P_PROJECT_SETTING_INTEGRATION_SETUP = 'project_setting.integration_setup';
export const P_PROJECT_SETTING_TEST_CONFIG_SETUP = 'project_setting.test_config_setup';
export const P_PROJECT_SETTING_IMPORT_EXPORT_SETUP = 'project_setting.import_export_setup';
export const P_PROJECT_SETTING_PROCESS = 'project_setting.process';

// project
export const P_TEST_MANAGER_REPOSITORY_FOLDER_SETUP = 'test_manager.repository.folder_setup';
export const P_TEST_MANAGER_REPOSITORY_TESTCASE_SETUP = 'test_manager.repository.testcase_setup';
export const P_TEST_MANAGER_PLANNING_RELEASE_SETUP = 'test_manager.planning.release_setup';
export const P_TEST_MANAGER_PLANNING_TEST_PLAN_SETUP = 'test_manager.planning.test_plan_setup';
export const P_TEST_MANAGER_PLANNING_TEST_SUITE_SETUP = 'test_manager.planning.test_suite_setup';
export const P_TEST_MANAGER_PLANNING_TEST_RESULT_DELETE = 'test_manager.planning.test_result_delete';
export const P_TEST_MANAGER_EXECUTION_ALLOW_EXECUTION_ALL = 'test_manager.execution.allowExecution';
export const P_PROJECT_MANAGER_WORK_TICKET_UPDATE = 'project_manager.work_ticket_update';
export const P_TEST_MANAGER_ALLOW_ASSIGN_TO = 'test_manager.allowAssignTo';
export const P_TEST_MANAGER_TRACEABILITY_SETUP = 'test_manager.traceability.setup';
export const P_PROJECT_CHART_SETUP = 'project_chart.setup';
