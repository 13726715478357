import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Spin } from 'antd';
import { InfoCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import './style.scss';

export const TypeToConfirmModal = ({
  visible,
  title = '',
  icon,
  contentText,
  confirmText,
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  loading = false,
  onOk,
  onCancel,
  className = '',
  ...rest
}) => {
  const buttonRef = useRef();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [typingText, setTypingText] = useState('');

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        buttonRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [buttonRef]);

  return (
    <Modal
      open={visible}
      header={null}
      footer={null}
      width={600}
      maskClosable={true}
      keyboard={true}
      closeIcon={<></>}
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header"
      className={`c-type-to-confirm-modal ant-modal-confirm ant-modal-confirm-confirm ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <div className="ant-modal-confirm-body-wrapper">
        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 114px)' }}>
          <div className="ant-modal-confirm-body">
            <span className="anticon anticon-exclamation-circle">{icon || <InfoCircleOutlined />}</span>

            <span className="ant-modal-confirm-title">{title || t('common.warning')}</span>

            <div className="ant-modal-confirm-content">
              <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
                <div className="mb-2">
                  {contentText && typeof contentText === 'string' ? (
                    <h3 className="mb-0">{contentText}</h3>
                  ) : (
                    contentText
                  )}
                </div>

                <div className="text-gray-2 mb-2">{t('message.confirmByEnteringTheTextBelow')}:</div>

                <div className="mb-2">
                  <span className="ant-typography">
                    <pre className="d-inline-block min-h-22 py-0 m-0">{confirmText}</pre>
                  </span>
                </div>

                <Input onChange={e => setTypingText(e?.target?.value)} className="w-100" />
              </Spin>
            </div>
          </div>
        </div>

        <div className="ant-modal-confirm-btns">
          <Button type="text" disabled={loading} onClick={onCancel} {...cancelButtonProps}>
            {cancelText || t('common.cancel')}
          </Button>

          <Button
            type="primary"
            loading={loading}
            onClick={onOk}
            disabled={typingText !== confirmText}
            {...okButtonProps}
            ref={buttonRef}
          >
            {okText || t('common.ok')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
