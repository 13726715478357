import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreActions, useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import {
  FIELD_STEP_RESULT,
  SS_LAST_SAVED_VALUE,
  SYSTEM_FIELD_STATUS,
  WORK_ITEM_TEST_RUN_ID,
  SYSTEM_FIELD_ORDER_ID,
  SYSTEM_FIELD_TEST_STEPS,
  WORK_ITEM_TEST_RESULT_ID,
  SYSTEM_FIELD_TEST_CASE_VERSION,
  WORK_ITEM_TEST_RUN_STATUS_COMPLETED,
  WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED
} from '../../../../constants';
import {
  getObjectByValue,
  reactSessionStorage,
  trackEventAzureWithDuration,
  handleSetLastSavedValueToSession
} from '../../../../common';
import { onOutsideClick } from '../../editable-cell-utils';
import { StatusLabel } from '../../../status-label';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import LatestResultStatusKeyboardEvents from './latest-result-status-keyboard-events';
import LatestResultStatusFormItem from './latest-result-status-form-item';

const LatestResultStatusField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  const refInput = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // For statistics and measurement
  const appInsights = useAppInsightsContext();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For test run store
  const executeTestRun = useStoreActions(action => action.testRun.executeTestRun);
  const updateStatusTestRun = useStoreActions(action => action.testRun.updateStatusTestRun);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [triggerRender, setTriggerRender] = useState(0);

  /**
   * On change editing cell
   */
  useEffect(() => {
    if (typeof onChangeEditingCell === 'function') onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Compute completed status (for test run)
   */
  const completedStatus = useMemo(() => {
    let newListStates = ticketListData?.[WORK_ITEM_TEST_RUN_ID]?.workFlow?.listStates;
    newListStates = Array.isArray(newListStates) && newListStates.length ? [...newListStates] : [];

    const newCompletedStatus = newListStates.find(item => item?.id === WORK_ITEM_TEST_RUN_STATUS_COMPLETED);

    return newCompletedStatus;
  }, [ticketListData]);

  /**
   * State list
   */
  const listStates = useMemo(() => {
    let newListStates = ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow?.listStates;
    return Array.isArray(newListStates) && newListStates.length ? [...newListStates] : [];
  }, [ticketListData]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const newOptions = [...listStates].map(item => {
      return {
        label: item?.name,
        value: item?.id,
        status: item,
        disabled: item?.id === WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED
      };
    });

    return newOptions;
  }, [field, listStates]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) return null;

    const val = objectPath.get(row, field?.refName);
    let newOption = getObjectByValue(val?.id, options);

    if (!newOption?.value) {
      newOption = getObjectByValue(WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED, options);
    }

    return newOption;
  }, [row, field, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastOption = getObjectByValue(lastValue, options);

    return lastValue !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') return;

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newLastSavedValueSession = {
        [formItemName]: opt?.value || null
      };

      // Execute one
      let testSteps = row?.[SYSTEM_FIELD_TEST_CASE_VERSION]?.[SYSTEM_FIELD_TEST_STEPS];
      testSteps = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];

      if (testSteps.length) {
        testSteps = testSteps.map((s, idx) => {
          const newStep = { ...s, [SYSTEM_FIELD_STATUS]: opt?.value || null, [SYSTEM_FIELD_ORDER_ID]: idx + 1 };
          newLastSavedValueSession[`key-${s?.key}-${FIELD_STEP_RESULT}`] = opt?.value || null;
          delete newStep.key;
          return newStep;
        });
      }

      const formData = {
        key: row?.key,
        [SYSTEM_FIELD_STATUS]: opt?.value || null,
        [SYSTEM_FIELD_TEST_STEPS]: testSteps
      };
      executeTestRun(formData);
      trackEventAzureWithDuration(appInsights, 'execute_test_case');
      if (typeof onSave === 'function') onSave({}); // No need to call function "onSave" to the outside

      // Handle update status test run
      const testRunStatusFormItemName = `key-${row?.key}-${SYSTEM_FIELD_STATUS}`;
      newLastSavedValueSession[testRunStatusFormItemName] = completedStatus?.id || null;
      updateStatusTestRun({
        body: [{ key: row?.key, [SYSTEM_FIELD_STATUS]: completedStatus?.id || null }],
        noShowSuccessMessage: true
      });
      setTimeout(() => {
        const querySelector = `.trigger-render-field-element[data-form-item-name="${testRunStatusFormItemName}"]`;
        document.querySelector(querySelector)?.click();
      }, 500);

      // Handle set last saved value to session
      if (Object.keys(newLastSavedValueSession).length) {
        handleSetLastSavedValueToSession(newLastSavedValueSession);
      }

      // Add className: need-to-reload to trigger reload element
      const triggerReload = document.querySelector('.trigger-reload-table-data-helper:not(.need-to-reload)');
      triggerReload?.classList.add('need-to-reload');

      // Render children table
      setTimeout(() => {
        const querySelector = `.trigger-render-children-table-element[data-parent-row-key="${row?.key}"]`;
        document.querySelector(querySelector)?.click();
      }, 500);
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    ref: refInput,
    open: isOpenDropdown,
    optionFilterProp: 'label',
    autoFocus: true,
    showSearch: true,
    allowClear: false,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: isReadOnly,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => handleSave(option),
    onClear: () => handleSave(null),
    onClick: () => handleOpen()
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const option = getCurrentOption(currentOption);

    // Editable
    if (isEditableField && !isReadOnly && !disableEditingCells) {
      return (
        <div title={option?.label || ''} className="cell-text cursor-text" onClick={handleOpen}>
          <span className="cell-text-value text-truncate">
            <StatusLabel status={option?.status} />
          </span>
        </div>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return (
        <RelationLink row={row} field={field}>
          <StatusLabel status={option?.status} />
        </RelationLink>
      );
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return (
        <div className="cell-text cursor-default hide-after">
          <RelationLink row={row} field={field}>
            <StatusLabel status={option?.status} />
          </RelationLink>
        </div>
      );
    }

    // Readonly
    else if (disableEditingCells) {
      return <StatusLabel status={option?.status} />;
    }

    // Readonly
    else {
      return (
        <div className="cell-text cursor-default hide-after">
          <span className="cell-text-value text-truncate">
            <StatusLabel status={option?.status} />
          </span>
        </div>
      );
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <LatestResultStatusFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <LatestResultStatusKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText()}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper latest-result-status-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>

        <div
          data-form-item-name={formItemName}
          className="trigger-render-field-element d-none"
          onClick={() => setTriggerRender(pre => ++pre)}
        >
          {triggerRender}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default LatestResultStatusField;
