import { Modal, Form, Button, Select, Input, Spin, Row, Divider, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  LinkOutlined,
  SaveOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TurndownService from 'turndown';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  JIRA_PLATFORM_ID,
  ROLE_APP_ACCESS_MANAGER,
  INTEGRATION_DEFECT_SYSTEM,
  PRODUCT_KEY_ON_URL_MANAGER,
  RELATED_TYPE_LINKS_TO,
  RELATED_TYPE_SYSTEM_DEFECT,
  RELATED_TYPE_SYSTEM_TEST_FOR,
  RELATED_TYPE_SYSTEM_TEST_BY,
  TESTMAN_PLATFORM_ID,
  INTEGRATION_FROM_TEST_PLAN_FOLDER,
  INTEGRATION_FROM_REPOSITORY_FOLDER,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_TEST_STEP_RESULT,
  WORK_ITEM_TEST_RESULT_ID,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TESTCASE_ID
} from '../../constants';
import { usePermission, scrollToFirstClassName } from '../../common';
import ManagerWorkTicketType from './work-ticket-type/manager-work-ticket-type';
import JiraIssueType from './work-ticket-type/jira-issue-type';
import ExistWorkTicket from './work-ticket-insert-type/exist-work-ticket';

import './style.scss';

const WORK_TICKET_TYPE_CREATE = 1;
const WORK_TICKET_TYPE_EXIST = 2;

const OPTION_CREATE_WORK_TICKET = [
  {
    key: WORK_TICKET_TYPE_CREATE,
    title: 'common.newItem'
  },
  {
    key: WORK_TICKET_TYPE_EXIST,
    title: 'common.existingItem'
  }
];

const RelatedToRelationshipModal = ({
  fromModule,
  visible,
  workItem,
  relation,
  className = '',
  setRelation,
  onCancel,
  onSubmit,
  onNext,
  ...rest
}) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();
  const saveButtonRef = useRef();

  // For hooks
  const { hasRepositoryTestcasePermission } = usePermission();

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      saveButtonRef.current.focus();
    }
  };
  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalUserTenant = useStoreState(state => state.global.globalUserTenant);
  const getRelationTypeList = useStoreActions(action => action.global.getRelationTypeList);
  const relationTypeList = useStoreState(state => state.global.relationTypeList);
  const createObjective = useStoreActions(action => action.global.createObjective);
  const loadingObjectiveItem = useStoreState(state => state.global.loadingObjectiveItem);
  const loadingRelationItem = useStoreState(state => state.global.loadingRelationItem);
  const loadingRelationTypeList = useStoreState(state => state.global.loadingRelationTypeList);
  const setObjectiveList = useStoreActions(action => action.global.setObjectiveList);

  // For integration store
  const getIntegrationItem = useStoreActions(action => action.integration.getIntegrationItem);
  const integrationItem = useStoreState(state => state.integration.integrationItem);

  // For jira store
  const updateIssue = useStoreActions(action => action.jiraIntegration.updateIssue);
  const loadingItemIssue = useStoreState(state => state.jiraIntegration.loadingItem);

  // For component state
  const [typeCreateWorkTicket, setTypeCreateWorkTicket] = useState(WORK_TICKET_TYPE_EXIST);
  const [integrationSystemList, setIntegrationSystemList] = useState([]);
  const [jiraIssueTypeId, setJiraIssueTypeId] = useState();
  const [resetCreateTicketType, setResetCreateTicketType] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Get list relation type
   */
  useEffect(() => {
    getRelationTypeList();
  }, [getRelationTypeList]);

  /**
   * Set initial state
   */
  useEffect(() => {
    setRelation({
      ...relation,
      img: Array.isArray(relationTypeList) && relationTypeList[0]?.guideline?.img,
      relationType: Array.isArray(relationTypeList) && relationTypeList[0]?._id,
      // workTicketTypeId: null,
      // workTicketType: null,
      integrationSystem: TESTMAN_PLATFORM_ID
      // jiraIssueType: null
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationTypeList]);

  /**
   * Set initial form
   */
  useEffect(() => {
    form.setFieldsValue(relation);
  }, [form, relation, relationTypeList]);

  /**
   * Get integration system
   */
  useEffect(() => {
    if (!globalProject?.projectKey || !globalTenant.tenantKey) return;

    getIntegrationItem({ projectKey: globalProject.projectKey, tenantKey: globalTenant.tenantKey });

    // eslint-disable-next-line
  }, [globalProject, globalTenant]);

  /**
   * Set data integration system list
   */
  useEffect(() => {
    const listSystem = [];

    // push product manager to list
    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER)) {
      listSystem.push(INTEGRATION_DEFECT_SYSTEM[PRODUCT_KEY_ON_URL_MANAGER]);
    }

    // push integration jira when relation is link to
    if (Array.isArray(relationTypeList) && relationTypeList.length && integrationItem) {
      const relationData = relationTypeList.find(value => value._id == relation.relationType);

      Object.keys(integrationItem).forEach(value => {
        if (relationData?.refName !== RELATED_TYPE_LINKS_TO) return;

        if (INTEGRATION_DEFECT_SYSTEM[value]) {
          switch (value) {
            case JIRA_PLATFORM_ID: {
              if (integrationItem[value]) {
                listSystem.push(INTEGRATION_DEFECT_SYSTEM[value]);
                setJiraIssueTypeId(integrationItem[value]?.issueType);
              }

              break;
            }
          }
        }
      });
    }

    setIntegrationSystemList(listSystem);

    // eslint-disable-next-line
  }, [integrationItem, globalUserTenant, relation, relationTypeList]);

  /**
   * On change work ticket type
   */
  const onChangeWorkTicketType = () => {
    setTypeCreateWorkTicket(WORK_TICKET_TYPE_EXIST);
    setObjectiveList([]);
    form.setFieldsValue({ item: '' });

    setResetCreateTicketType(true);
    setTimeout(() => setResetCreateTicketType(false), 0);
  };

  /**
   * Handle submit
   */
  const hanldeSubmit = async values => {
    if (!values || !globalProject.projectKey || !globalTenant?.tenantKey) return;

    // create new ticket type
    if (typeCreateWorkTicket === WORK_TICKET_TYPE_CREATE) {
      onNext({
        ...values,
        workTicketType: relation.workTicketType
      });
    }

    if (typeCreateWorkTicket === WORK_TICKET_TYPE_EXIST) {
      if (!values?.item) return;

      switch (relation.integrationSystem) {
        case JIRA_PLATFORM_ID: {
          if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
            const objectiveObj = {
              key: values.item.key,
              integrationSystem: JIRA_PLATFORM_ID,
              [JIRA_PLATFORM_ID]: {
                id: values.item.id,
                name: values.item.key,
                link: `${values.item.self?.split('/rest/api/2')[0]}/browse/${values.item.key}`
              }
            };

            onSubmit(objectiveObj);
          } else {
            const rawHtml =
              (workItem?.[SYSTEM_FIELD_TEST_STEPS] || workItem?.[SYSTEM_FIELD_TEST_STEP_RESULT])?.map(
                (value, index) => {
                  const step = `<p>${index + 1}. ${value.step || ''} </p>`;
                  const testData = `<li>Test Data: ${value.testData || ''} </li>`;
                  const expectedResult = `<li>Expected Results: ${value.expectedResult || ''} </li>`;
                  const status = `<li>Status: ${value.status?.name || ''} </li>`;
                  return `${step}<ul>${testData + expectedResult + status}</ul>`;
                }
              ) || [];

            const turndownService = new TurndownService();
            const markdown = turndownService.turndown(rawHtml.join(''));

            const remoteLink = {
              title: `${window.location.origin}${window.location.pathname}`,
              link: window.location.href
            };

            let formData = {};

            if (markdown !== '') {
              formData = {
                description: markdown
              };
            }

            const res = await updateIssue({
              id: values.item.id,
              projectKey: globalProject.projectKey,
              tenantKey: globalTenant.tenantKey,
              remoteLink,
              formData
            });

            if (res || values.item.self?.split('/rest/api/2')[0]) {
              const defectLink = {
                id: values.item.id,
                name: values.item.key,
                type: JIRA_PLATFORM_ID,
                link: `${values.item.self?.split('/rest/api/2')[0]}/browse/${values.item.key}`
              };
              const objectiveObj = await createObjective({
                name: values.item.key,
                workTicketType: workItem?._id,
                external: true,
                externalData: defectLink
              });

              if (objectiveObj?.key) {
                onSubmit(objectiveObj);
              }
            }
          }

          break;
        }

        case TESTMAN_PLATFORM_ID: {
          if (values.item?._id) {
            const formData = { ...values.item };

            if (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER) {
              formData.integrationSystem = TESTMAN_PLATFORM_ID;
            }

            onSubmit(formData);
          }

          break;
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={visible}
        keyboard={false}
        maskClosable={false}
        width={700}
        footer={null}
        forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
        className={`c-related-to-relationship-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <Form form={form} layout="vertical" onFinish={hanldeSubmit}>
          <div className="px-4 pt-3">
            <Row justify="space-between" align="middle">
              <h3 className="text-primary font-weight-medium font-size-16 m-0">
                <LinkOutlined /> {t('relationTo.relateToTicket')}
              </h3>

              <Row align="middle" justify="end" wrap={false} className="box-extra">
                <Tooltip
                  open={visibleFullScreenTooltip}
                  title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                  placement={fullScreen ? 'bottomRight' : 'bottom'}
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                  onOpenChange={setVisibleFullScreenTooltip}
                >
                  <Button
                    icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    type="link"
                    className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                    onClick={() => {
                      setFullScreen(!fullScreen);
                      setVisibleFullScreenTooltip(false);
                    }}
                  />
                </Tooltip>
              </Row>
            </Row>

            <Divider className="mt-3 mb-0" />
          </div>

          <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
            <div className="form-wrapper p-4">
              <Spin
                indicator={<Loading3QuartersOutlined spin />}
                spinning={loadingObjectiveItem || loadingRelationItem || loadingItemIssue}
              >
                <Form.Item
                  label={t('relationTo.relatedType')}
                  name="relationType"
                  rules={[{ required: true, message: t('message.required') }]}
                >
                  <Select
                    loading={loadingRelationTypeList}
                    placeholder={t('common.pleaseSelect')}
                    onSelect={(val, option) =>
                      setRelation({
                        ...relation,
                        relationType: val,
                        img: option['img-link'],
                        integrationSystem: integrationSystemList[0]?.id
                      })
                    }
                  >
                    {Array.isArray(relationTypeList) &&
                      relationTypeList.length &&
                      relationTypeList
                        .filter(
                          r =>
                            r.refName !== RELATED_TYPE_SYSTEM_DEFECT &&
                            r.refName !== RELATED_TYPE_SYSTEM_TEST_FOR &&
                            r.refName !== RELATED_TYPE_SYSTEM_TEST_BY
                        )
                        .map(item => {
                          return (
                            <Select.Option
                              value={item?._id}
                              key={item?._id}
                              title={t(item?.name)}
                              img-link={item?.guideline?.img}
                            >
                              {item?.name}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <img src={relation.img} alt="Related Type" className="w-100" />
                </Form.Item>

                <Form.Item
                  label={t('relationTo.integrationSystem')}
                  name="integrationSystem"
                  rules={[{ required: true, message: t('message.required') }]}
                >
                  <Select
                    placeholder={t('common.pleaseSelect')}
                    onChange={val =>
                      setRelation({
                        ...relation,
                        integrationSystem: val
                      })
                    }
                  >
                    {Array.isArray(integrationSystemList) &&
                      integrationSystemList.length &&
                      integrationSystemList.map(platform => {
                        return (
                          <Select.Option value={platform.id} key={platform.id} title={platform.title}>
                            <span className="mr-1">{platform.icon}</span> {platform.title}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                {relation.integrationSystem === TESTMAN_PLATFORM_ID && (
                  <ManagerWorkTicketType
                    setRelation={setRelation}
                    relation={relation}
                    form={form}
                    onChangeWorkTicketType={onChangeWorkTicketType}
                  />
                )}

                {relation.integrationSystem === JIRA_PLATFORM_ID && (
                  <JiraIssueType
                    typeCreateWorkTicket={typeCreateWorkTicket}
                    setRelation={setRelation}
                    relation={relation}
                    jiraIssueTypeId={jiraIssueTypeId}
                    form={form}
                  />
                )}

                {!resetCreateTicketType && (
                  <Form.Item
                    className="create-ticket-type"
                    name="item"
                    rules={[{ required: true, message: t('message.required') }]}
                    label={
                      <Select
                        style={{ minWidth: 120 }}
                        defaultValue={WORK_TICKET_TYPE_EXIST}
                        onChange={val => {
                          form.setFieldsValue({ item: '' });
                          setTypeCreateWorkTicket(val);
                        }}
                      >
                        {OPTION_CREATE_WORK_TICKET.filter(type => {
                          if (
                            ((relation?.workTicketType === WORK_ITEM_TESTCASE_ID && !hasRepositoryTestcasePermission) ||
                              relation?.workTicketType === WORK_ITEM_TEST_RUN_ID ||
                              relation?.workTicketType === WORK_ITEM_TEST_RESULT_ID) &&
                            type?.key === WORK_TICKET_TYPE_CREATE
                          ) {
                            return false;
                          }

                          return true;
                        }).map(type => {
                          return (
                            <Select.Option value={type.key} key={type.key} title={type.title}>
                              {t(type.title)}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    }
                  >
                    {typeCreateWorkTicket === WORK_TICKET_TYPE_CREATE && (
                      <Input
                        placeholder={t('process.enterWorkItemName')}
                        onChange={e => form.setFieldsValue({ item: e?.target?.value })}
                        onPressEnter={handleKeyPress}
                      />
                    )}

                    {typeCreateWorkTicket === WORK_TICKET_TYPE_EXIST && (
                      <ExistWorkTicket
                        relation={relation}
                        workItem={workItem}
                        form={form}
                        onPressEnter={handleKeyPress}
                      />
                    )}
                  </Form.Item>
                )}
              </Spin>
            </div>
          </div>

          <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
            <Divider className="mt-0 mb-3" />

            <div className="text-right">
              <Button type="text" onClick={onCancel}>
                {t('common.cancel')}
              </Button>

              {(typeCreateWorkTicket === WORK_TICKET_TYPE_CREATE ||
                typeCreateWorkTicket === WORK_TICKET_TYPE_EXIST) && (
                <Button
                  className="ml-2"
                  type="primary"
                  icon={<SaveOutlined />}
                  ref={saveButtonRef}
                  onClick={() => {
                    form.submit();
                    scrollToFirstClassName('ant-form-item-has-error');
                  }}
                >
                  {typeCreateWorkTicket === WORK_TICKET_TYPE_CREATE ? t('common.next') : t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default RelatedToRelationshipModal;
