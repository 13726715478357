import React, { Suspense } from 'react';
import { Layout } from 'antd';
import { useStoreState } from 'easy-peasy';

import { LeftSidebar, MainHeader, MainFooter, RouterLoading } from '../components';

const { Content } = Layout;

export default ({ children }) => {
  // For global tenant store
  const myTenantList = useStoreState(state => state.global.myTenantList);

  return (
    <Suspense fallback={<RouterLoading />}>
      <Layout className="l-home">
        <MainHeader />

        <Layout className="main-body">
          {Array.isArray(myTenantList) && myTenantList.length > 0 && <LeftSidebar />}

          <Layout>
            <Content
              className="main-content"
              style={{
                background: !(Array.isArray(myTenantList) && myTenantList.length > 0) ? '#fff' : null
              }}
            >
              {/* <MaintenanceAlert className="mt-3 mx-3" /> */}
              {children}
            </Content>
            <MainFooter />
          </Layout>
        </Layout>
      </Layout>
    </Suspense>
  );
};
