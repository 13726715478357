import React from 'react';
import { UserOutlined, ProfileOutlined, AppstoreOutlined } from '@ant-design/icons';

import env from '../../env';
import { P_TENANT_SETTING_PROJECT_SETUP, P_TENANT_SETTING_USER_SETUP, P_TENANT_SETTING_PROCESS } from '../permissions';
import { FinancialDocument, ProcessIcon } from '../../assets/svg-icons';

const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];

/**
 * Home menu group
 */
export const HOME_MENU_GROUP = [
  {
    id: 'customizations',
    name: 'menuGroup.customizations'
  },
  {
    id: 'userSetup',
    name: 'menuGroup.userSetup'
  },
  {
    id: 'projectSetup',
    name: 'menuGroup.projectSetup'
  },
  {
    id: 'subscription',
    name: 'menuGroup.subscription'
  }
];

/**
 * Organization menu
 */
export const HOME_MENU = [
  {
    id: 'overview',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/tenant-overview`,
    name: 'menu.overview',
    icon: <AppstoreOutlined />
  },

  // ==========> Customize Fields
  {
    id: 'workItem',
    parentId: null,
    groupId: 'customizations',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/process-tenant`,
    name: 'menu.workItem',
    icon: <ProcessIcon />,
    permission: P_TENANT_SETTING_PROCESS
  },
  {
    id: 'workItemDetail',
    parentId: 'workItem',
    name: 'WorkItemDetail',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/process-tenant/:id`,
    isHideOnSidebar: true,
    permission: P_TENANT_SETTING_PROCESS
  },

  // ==============================> GROUP: User Setup <==============================
  {
    id: 'userSetup',
    parentId: null,
    groupId: 'userSetup',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/user`,
    name: 'menu.userSetup',
    icon: <UserOutlined />,
    permission: P_TENANT_SETTING_USER_SETUP
  },

  // ==============================> GROUP: Project <==============================
  {
    id: 'projectSetup',
    parentId: null,
    groupId: 'projectSetup',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/project`,
    name: 'menu.projectSetup',
    icon: <ProfileOutlined />,
    permission: P_TENANT_SETTING_PROJECT_SETUP
  },
  {
    id: 'projectDetail',
    parentId: 'project-setup',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/project/detail/:projectKey`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_TENANT_SETTING_PROJECT_SETUP
  },
  {
    id: 'projectCreate',
    parentId: 'project-setup',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/project/create`,
    name: 'menu.create',
    isHideOnSidebar: true,
    permission: P_TENANT_SETTING_PROJECT_SETUP
  },

  // ==============================> GROUP: Subscription <==============================
  {
    id: 'subscription',
    parentId: null,
    groupId: 'subscription',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/subscription`,
    name: 'menu.subscription',
    icon: <FinancialDocument />
  }
];
