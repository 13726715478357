import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form, Input, Select, DatePicker, Button, TreeSelect } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
import objectPath from 'object-path';
import _debounce from 'lodash/debounce';

import {
  PRIORITIES,
  URL_PATTERN,
  COMPONENT_TYPE,
  NUMBER_PATTERN,
  PRIORITY_MEDIUM,
  FULL_DATE_FORMAT,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_NAME,
  SHORT_DATE_FORMAT,
  ORDER_BY_KEY_DESC,
  SYSTEM_FIELD_PATH,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_ASSIGN_TO,
  ESTIMATED_TIME_PATTERN,
  SYSTEM_FIELD_REFERENCE_FOLDER
} from '../../constants';
import {
  debounce,
  useField,
  removeDuplicate,
  removeTokenToRawHtml,
  filterOptionForUserField,
  convertMinutesToShortTime,
  convertEstimatedTimeToMinutes
} from '../../common';
import { UserAvatar } from '../user-avatar';
import { BasicEditor } from '../basic-editor';
import { SafeInnerHtml } from '../safe-inner-html';
import { TestStepDraggableRowTable } from '../test-step-draggable-row-table';
import { BasicUploadMultipleFiles } from '../basic-upload-multiple-files';

import './style.scss';

let typingTimerOfSearch = 0;

export const WorkItemField = ({
  field,
  editingItemKey,
  form,
  workTicketId,
  isReadOnly = false,
  noValidate,
  showRepositoryFolderField,
  currentTestStepList,
  onChangeCurrentTestStepList,
  currentTestStepRaw,
  hasGetFullFilesInfo,
  uploadPath,
  currentAttachmentList,
  onChangeCurrentAttachmentList,
  onEditorAttachFiles,
  onChangeDeteledAttachmentIds,
  onChange,
  className = '',
  restField,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For hooks
  const { getSuggestionData, getRelationLabel } = useField();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Global store
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const loadingProjectUserList = useStoreState(state => state.global.loadingProjectUserList);
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const setSuggestion = useStoreActions(action => action.global.setSuggestion);
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // For repository tree action
  const repository = useStoreState(state => state.repositoryTree.repository);

  // Component state
  const [repositoryTree, setRepositoryTree] = useState([]);
  const [visibleEditor, setVisibleEditor] = useState(false);
  const [suggestionData, setSuggestionData] = useState({});
  const [suggestionParams, setSuggestionParams] = useState({});
  const [suggestionKeyword, setSuggestionKeyword] = useState({});

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  /**
   * Convert treeData to tree
   */
  useEffect(() => {
    if (!(Array.isArray(repository) && repository.length)) return;

    // Function: Convert to tree
    const convertToTree = (list, treeLevel) => {
      treeLevel++;

      return list.map(item => {
        const hasChildren = Array.isArray(item.subs) && item.subs.length;
        let newChildren = hasChildren
          ? item.subs.sort((a, b) => {
              if (moment(a.createdAt) > moment(b.createdAt)) return 1;
              if (moment(a.createdAt) < moment(b.createdAt)) return -1;
              return 0;
            })
          : []; // Sort by createdAt: ASC

        return {
          title: item.name,
          value: item._id || item.id,
          icon: <FolderOutlined className="text-primary" />,
          children: convertToTree(newChildren, treeLevel)
        };
      });
    };

    const tree = convertToTree(repository, 0);

    setRepositoryTree(tree);
  }, [repository]);

  /**
   * Get form data
   */
  const getFormData = async (field, keyword) => {
    if (!field?.data) return;

    switch (field?.componentType) {
      case COMPONENT_TYPE.RELATION:
      case COMPONENT_TYPE.SUGGESTION: {
        const select = `_id ${field?.data.displayField} ${field?.data.fieldLabel} ${field?.data.fieldValue}`.split(' ');
        const query = {
          referenceField: field?.refName,
          url: field?.data.url,
          filter: field?.data.filter || null,
          limit: 10,
          page: 1,
          select: removeDuplicate(select, '').join(' '),
          group: field?.data.fieldValue,
          order: ORDER_BY_KEY_DESC
        };

        if (keyword) {
          query.filter = {
            ...(field?.data.filter || {}),
            [field?.refName]: { $regex: `.*${keyword}.*`, $options: 'i' }
          };
        }

        const res = await getSuggestion(query);

        const newSuggestion = {
          ...(suggestion || {}),
          [field?.refName]: {
            ...suggestion?.[field?.refName],
            data: Array.isArray(res?.rows) && res?.rows.length ? [...res?.rows] : []
          }
        };

        setSuggestion(newSuggestion);

        break;
      }

      default:
        break;
    }
  };

  /**
   * Handle get suggestion data
   */
  const handleGetSuggestionData = ({ field, page, keyword, isLoadType }) => {
    getSuggestionData({
      field,
      page,
      keyword,
      isLoadType,
      setSuggestionData,
      suggestionData,
      setSuggestionParams,
      suggestionParams,
      suggestionKeyword
    });
  };

  /**
   * On search suggestion data
   */
  const onSearchSuggestionData = useCallback(
    _debounce(({ field, keyword, isLoadType }) => {
      handleGetSuggestionData({ field, keyword, isLoadType });
    }, 500),
    []
  );

  /**
   * On search suggestion by key word
   */
  const onSearchSuggestionByKeyWord = (field, keyword) => {
    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => getFormData(field, keyword)),
      300
    );
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    const name = rest?.name ? rest?.name : field?.refName;
    const titleText = i18n.exists(`akaat:workItem.${field?.refName}`) ? t(`workItem.${field?.refName}`) : field?.name;
    const title = (
      <span id={`label_${field?.refName}-field`} title={titleText}>
        {titleText}
      </span>
    );

    if (
      field?.componentType === COMPONENT_TYPE.STRING &&
      field?.refName === SYSTEM_FIELD_NAME &&
      workTicketId === WORK_ITEM_TESTCASE_ID
    ) {
      const nameRules = [
        {
          required: field?.mandatory,
          whitespace: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      return (
        <>
          {showRepositoryFolderField && (
            <Form.Item
              label={t('workItem.repositoryFolder')}
              name={SYSTEM_FIELD_REFERENCE_FOLDER}
              rules={[
                {
                  required: true,
                  message: <span id={`required-error-mesage_${SYSTEM_FIELD_PATH}-field`}>{t('message.required')}</span>
                }
              ]}
              wrapperCol={{ span: 24 }}
              className={`c-work-item-field type-ref-name-path repository-folder ${className}`}
            >
              <TreeSelect
                treeData={repositoryTree}
                treeDefaultExpandAll
                allowClear={false}
                treeIcon={true}
                placeholder={!isReadOnly ? t('workItem.selectRepositoryFolder') : ''}
                className="w-100"
              />
            </Form.Item>
          )}

          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? nameRules : []}
            className={`c-work-item-field type-string ${className}`}
            {...rest}
          >
            <Input
              autoFocus={SYSTEM_FIELD_NAME}
              autoComplete="off"
              placeholder={!isReadOnly ? t('common.enterValue') : ''}
              allowClear={!field?.mandatory}
              readOnly={isReadOnly}
              className="w-100"
              onChange={e => {
                if (typeof onChange === 'function') onChange(e);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.STRING && field?.refName === SYSTEM_FIELD_TAG) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-ref-name-tag ${className}`}
            {...rest}
          >
            <Select
              mode="tags"
              options={[]}
              optionFilterProp="label"
              showSearch
              allowClear={!field?.mandatory}
              placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
              disabled={isReadOnly}
              className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
              onChange={val => {
                if (typeof onChange === 'function') onChange(val);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.STRING) {
      const rules = [
        {
          required: field?.mandatory,
          whitespace: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-string ${className}`}
            {...rest}
          >
            <Input
              autoFocus={field?.refName === SYSTEM_FIELD_NAME}
              autoComplete="off"
              placeholder={!isReadOnly ? t('common.enterValue') : ''}
              allowClear={!field?.mandatory}
              readOnly={isReadOnly}
              className="w-100"
              onChange={e => {
                if (typeof onChange === 'function') onChange(e);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.URL) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        },
        {
          pattern: URL_PATTERN,
          message: <span id={`invalid-format-error-mesage_${field?.refName}-field`}>{t('message.invalidFormat')}</span>
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-url ${className}`}
            {...rest}
          >
            <Input
              autoComplete="off"
              placeholder={!isReadOnly ? t('common.enterValue') : ''}
              allowClear={!field?.mandatory}
              readOnly={isReadOnly}
              className="w-100"
              onChange={e => {
                if (typeof onChange === 'function') onChange(e);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.HTML) {
      const rules = [
        {
          required: field?.mandatory,
          whitespace: true,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      return (
        visibleEditor && (
          <>
            <Form.Item
              label={title}
              name={name}
              rules={!noValidate ? rules : []}
              className={`c-work-item-field type-html ${className}`}
              {...rest}
            >
              <BasicEditor
                rawHtml={field?.currentValue}
                uploadPath={uploadPath}
                isReadOnly={isReadOnly}
                restEditor={{ placeholder: !isReadOnly ? t('common.enterValue') : '' }}
                onBlur={() => form.validateFields([name])}
                onEditorAttachFiles={file => {
                  if (typeof onEditorAttachFiles === 'function') onEditorAttachFiles(file);
                }}
                onEditorRawHtmlChange={val => {
                  const newRawHtml = removeTokenToRawHtml({ rawHtml: val });

                  form.setFieldsValue({ [name]: newRawHtml });
                  if (typeof onChange === 'function') onChange(newRawHtml);
                }}
                {...restField}
              />
            </Form.Item>
          </>
        )
      );
    }

    if (field?.componentType === COMPONENT_TYPE.PRIORITY) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];
      const excludeValues =
        Array.isArray(field?.excludeValues) && field?.excludeValues.length ? [...field?.excludeValues] : [];

      return (
        <Form.Item
          label={title}
          name={name}
          rules={!noValidate ? rules : []}
          initialValue={PRIORITY_MEDIUM}
          className={`c-work-item-field type-priority ${className}`}
          {...rest}
        >
          <Select
            optionFilterProp="label"
            showSearch
            allowClear={!field?.mandatory}
            placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
            disabled={isReadOnly}
            className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
            onChange={val => {
              if (typeof onChange === 'function') onChange(val);
            }}
            {...restField}
          >
            {PRIORITIES.filter(item => !excludeValues.includes(item?.value)).map(item => (
              <Select.Option key={item?.value} title={item?.label} value={item?.value}>
                {item?.icon}
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.PICKLIST || field?.componentType === COMPONENT_TYPE.OPTION) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];
      const mode = field?.componentType === COMPONENT_TYPE.PICKLIST ? 'multiple' : '';
      const excludeValues =
        Array.isArray(field?.excludeValues) && field?.excludeValues.length ? [...field?.excludeValues] : [];

      return (
        <Form.Item
          label={title}
          name={name}
          rules={!noValidate ? rules : []}
          className={`c-work-item-field type-picklist-option ${className}`}
          {...rest}
        >
          <Select
            mode={mode}
            options={
              Array.isArray(field?.data) && field?.data.length
                ? [...field?.data].filter(item => !excludeValues.includes(item?.value))
                : []
            }
            optionFilterProp="label"
            showSearch
            allowClear={!field?.mandatory}
            placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
            disabled={isReadOnly}
            className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
            onChange={val => {
              if (typeof onChange === 'function') onChange(val);
            }}
            {...restField}
          />
        </Form.Item>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.USER) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];
      const excludeValues =
        Array.isArray(field?.excludeValues) && field?.excludeValues.length ? [...field?.excludeValues] : [];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-user ${className}`}
            extra={
              field?.refName === SYSTEM_FIELD_ASSIGN_TO && !isReadOnly ? (
                <Button
                  type="link"
                  className="btn-assign-to-me border-transparent text-hover-dark-primary p-0"
                  onClick={() => form.setFieldsValue({ [name]: globalUserInfo?.username })}
                >
                  {t('common.assignToMe')}
                </Button>
              ) : null
            }
            {...rest}
          >
            <Select
              options={
                Array.isArray(projectUserList) && projectUserList.length
                  ? [...projectUserList]
                      .filter(item => !excludeValues.includes(item?.username))
                      .map(item => ({
                        label: <UserAvatar user={item} />,
                        value: item?.username,
                        item: item
                      }))
                  : []
              }
              loading={loadingProjectUserList}
              showSearch
              allowClear={!field?.mandatory}
              placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
              filterOption={filterOptionForUserField}
              disabled={isReadOnly}
              className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
              onChange={val => {
                if (typeof onChange === 'function') onChange(val);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.DATE_TIME || field?.componentType === COMPONENT_TYPE.DATE) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-date-time-date ${className}`}
            {...rest}
          >
            <DatePicker
              format={`${field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT}`}
              placeholder={`${field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT}`}
              showTime={field?.componentType === COMPONENT_TYPE.DATE_TIME ? { format: 'HH:mm:ss' } : false}
              allowClear={!field?.mandatory}
              disabled={isReadOnly}
              className="w-100"
              onChange={val => {
                if (typeof onChange === 'function') onChange(val);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.NUMBER) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        },
        {
          pattern: NUMBER_PATTERN,
          message: (
            <span id={`invalid-number-error-mesage_${field?.refName}-field`}>{t('message.fieldMustBeANumber')}</span>
          )
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            name={name}
            rules={!noValidate ? rules : []}
            className={`c-work-item-field type-number ${className}`}
            {...rest}
          >
            <Input
              placeholder={!isReadOnly ? t('common.enterValue') : ''}
              allowClear={!field?.mandatory}
              readOnly={isReadOnly}
              className="w-100"
              onChange={e => {
                if (typeof onChange === 'function') onChange(e);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        },
        {
          pattern: ESTIMATED_TIME_PATTERN,
          message: <span id={`invalid-format-error-mesage_${field?.refName}-field`}>{t('message.invalidFormat')}</span>
        }
      ];

      return (
        <>
          <Form.Item
            label={title}
            tooltip={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
            className={`c-work-item-field type-time-tracking ${className}`}
            name={name}
            rules={!noValidate ? rules : []}
            validateTrigger={['onBlur']}
            {...rest}
          >
            <Input
              placeholder={!isReadOnly ? t('common.enterValue') : ''}
              allowClear={!field?.mandatory}
              readOnly={isReadOnly}
              className="w-100"
              onBlur={async e => {
                await form.validateFields([name]);
                const et = convertMinutesToShortTime(convertEstimatedTimeToMinutes(e?.target?.value));
                form.setFieldsValue({ [name]: et || null });
              }}
              onChange={e => {
                if (typeof onChange === 'function') onChange(e);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.TEST_STEPS) {
      return (
        <Form.Item label={title} className={`c-work-item-field type-test-steps ${className}`} {...rest}>
          <TestStepDraggableRowTable
            workTicketId={workTicketId}
            form={form}
            editingItemKey={editingItemKey}
            currentTestStepList={currentTestStepList}
            onChangeTestStepList={list => {
              if (typeof onChangeCurrentTestStepList === 'function') onChangeCurrentTestStepList(list);
              if (typeof onChange === 'function') onChange(list);
            }}
            currentTestStepRaw={currentTestStepRaw}
            uploadPath={uploadPath}
            hasGetFullFilesInfo={hasGetFullFilesInfo}
            directlyDeleteAttachment={false}
            isReadOnly={isReadOnly}
            onChangeDeteledAttachmentIds={ids => {
              if (typeof onChangeDeteledAttachmentIds === 'function') onChangeDeteledAttachmentIds(ids);
            }}
          />
        </Form.Item>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.ATTACHMENTS) {
      return (
        <>
          <Form.Item label={title} className={`c-work-item-field type-attachments ${className}`} {...rest}>
            <BasicUploadMultipleFiles
              attachments={
                Array.isArray(currentAttachmentList) && currentAttachmentList.length ? [...currentAttachmentList] : []
              }
              uploadPath={uploadPath}
              directlyDeleteAttachment={false}
              hasGetFullFilesInfo={hasGetFullFilesInfo}
              isReadOnly={isReadOnly}
              onChangeAttachments={list => {
                if (typeof onChangeCurrentAttachmentList === 'function') onChangeCurrentAttachmentList(list);
                if (typeof onChange === 'function') onChange(list);
              }}
              onChangeDeteledAttachmentIds={list => {
                if (typeof onChangeDeteledAttachmentIds === 'function') onChangeDeteledAttachmentIds(list);
              }}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.RELATION) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      let fields = ticketListData?.[field?.lookup?.workTicketId]?.fields;
      fields = Array.isArray(fields) && fields.length ? [...fields] : [];
      const workFlow = ticketListData?.[field?.lookup?.workTicketId]?.workFlow;

      const fieldToDisplay = fields.find(f => f?.refName === field?.data?.displayField);
      const relationItem = field?.currentValue;
      const relationItemKey = field?.currentValue?.[SYSTEM_FIELD_KEY];
      const relationLabel = getRelationLabel({ relationItem, fieldToDisplay, workFlow });
      const visualFieldId = uuidv4();

      const defaultValueForSuggestionField = relationItem?.[SYSTEM_FIELD_KEY] ? (
        <>
          {relationItemKey}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
        </>
      ) : isReadOnly ? (
        <span className="text-gray">N/A</span>
      ) : (
        <span className="text-gray">{t('common.pleaseSelect')}</span>
      );

      const options =
        Array.isArray(suggestionData?.[field?.refName]?.rows) && suggestionData?.[field?.refName]?.rows?.length
          ? suggestionData?.[field?.refName]?.rows?.map(item => {
              const key = item?.[SYSTEM_FIELD_KEY];
              const relationLabel = getRelationLabel({ relationItem: item, fieldToDisplay, workFlow });

              return {
                title: `${key} - ${relationLabel?.plainText || ''}`,
                label: (
                  <div className="text-truncate">
                    {key}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
                  </div>
                ),
                value: item?.[field?.data?.fieldValue]
              };
            })
          : [];

      return (
        <>
          <Form.Item
            label={field?.name}
            name={name}
            rules={!noValidate ? rules : []}
            extra={
              relationItem?.[SYSTEM_FIELD_KEY] && (
                <div id={visualFieldId} className="ant-input visual-field position-absolute">
                  {defaultValueForSuggestionField}
                </div>
              )
            }
            className={`c-work-item-field type-relation ${className}`}
            {...rest}
          >
            <Select
              options={options}
              optionFilterProp="title"
              filterOption={false}
              showSearch
              allowClear={!field?.mandatory}
              placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
              loading={loadingSuggestion}
              disabled={isReadOnly}
              className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
              onFocus={() => {
                const el = document.getElementById(visualFieldId);
                if (el) el.remove();

                handleGetSuggestionData({ field, page: 1, isLoadType: 'SCROLL' });
              }}
              onPopupScroll={e => {
                if (loadingSuggestion) return;

                if (
                  e?.target?.scrollTop + e?.target?.offsetHeight === e?.target?.scrollHeight &&
                  !suggestionParams?.[field?.refName]?.isLoadedAll
                ) {
                  const page = suggestionParams?.[field?.refName]?.page;
                  handleGetSuggestionData({ field, page: page + 1, isLoadType: 'SCROLL' });
                }
              }}
              onSearch={keyword => {
                setSuggestionKeyword({ ...suggestionKeyword?.[field?.refName], [field?.refName]: keyword });
                onSearchSuggestionData({ field, keyword, isLoadType: 'SEARCH' });
              }}
              onChange={val => {
                if (typeof onChange === 'function') onChange(val);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    if (field?.componentType === COMPONENT_TYPE.SUGGESTION) {
      const rules = [
        {
          required: field?.mandatory,
          message: <span id={`required-error-mesage_${field?.refName}-field`}>{t('message.required')}</span>
        }
      ];

      const options = suggestion?.[field?.refName]?.data;
      const excludeValues =
        Array.isArray(field?.excludeValues) && field?.excludeValues.length ? [...field?.excludeValues] : [];

      let fields = ticketListData[field?.lookup?.workTicketId]?.fields;
      fields = Array.isArray(fields) && fields.length ? [...fields] : [];

      const currentField = fields.find(f => f?.refName === field?.data?.displayField);

      let defaultValueForSuggestionField = field?.currentValue?.[SYSTEM_FIELD_KEY]
        ? objectPath.get(field?.currentValue, currentField?.refName)
        : '';
      defaultValueForSuggestionField = defaultValueForSuggestionField || 'N/A';

      const visualFieldId = uuidv4();

      return (
        <>
          <Form.Item
            label={field?.name}
            name={name}
            rules={!noValidate ? rules : []}
            extra={
              field?.currentValue?.[SYSTEM_FIELD_KEY] && (
                <div id={visualFieldId} className="ant-input visual-field position-absolute">
                  {defaultValueForSuggestionField}
                </div>
              )
            }
            className={`c-work-item-field type-suggestion ${className}`}
            {...rest}
          >
            <Select
              options={
                Array.isArray(options) && options?.length
                  ? options
                      .filter(item => !excludeValues.includes(item?.[field?.data?.fieldValue]))
                      .map(item => {
                        return {
                          label: item?.[field?.data?.fieldLabel],
                          value: item?.[field?.data?.fieldValue]
                        };
                      })
                  : []
              }
              optionFilterProp="label"
              showSearch
              allowClear={!field?.mandatory}
              placeholder={!isReadOnly ? t('common.pleaseSelect') : ''}
              loading={loadingSuggestion}
              disabled={isReadOnly}
              className={`w-100 ${isReadOnly ? 'is-read-only' : ''}`}
              onFocus={() => {
                const el = document.getElementById(visualFieldId);
                if (el) el.remove();

                getFormData(field);
              }}
              onSearch={val => onSearchSuggestionByKeyWord(field, val)}
              onChange={val => {
                if (typeof onChange === 'function') onChange(val);
              }}
              {...restField}
            />
          </Form.Item>
        </>
      );
    }

    return null;
  };

  return renderFormItem();
};
