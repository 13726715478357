import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import ReactPlayer from 'react-player';

import { NO_PREVIEW_SUPPORTED_FILES } from '../../constants';
import { DeletedFile } from '../../assets/svg-icons';
import { ReactPanZoomImage } from '..';
import OtherFile from './other-file';

const ViewFile = ({ fileInfo, setFileInfo, onlyPreviewFileOnClient, onDownload }) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * File type
   */
  const isImage = useMemo(() => fileInfo?.type.includes('image/'), [fileInfo]);
  const isVideo = useMemo(() => fileInfo?.type.includes('video/'), [fileInfo]);
  const isAudio = useMemo(() => fileInfo?.type.includes('audio/'), [fileInfo]);

  return (
    <div
      className={`c-view-file ${
        isImage ? 'type-image' : isVideo ? 'type-video' : isAudio ? 'type-audio' : 'type-other'
      }`}
    >
      {fileInfo?.fileNotFound ? (
        <Row justify="center" align="middle" className="h-100">
          <div>
            <DeletedFile className="ic-other-file" style={{ fontSize: 70, color: '#c2c2c2' }} />
            <div style={{ fontSize: 16, color: '#fff', marginTop: 5 }}>{t('common.fileNotFound')}</div>
          </div>
        </Row>
      ) : NO_PREVIEW_SUPPORTED_FILES.includes(fileInfo?.extension) ? (
        <OtherFile fileInfo={fileInfo} onDownload={onDownload} />
      ) : isImage ? (
        <ReactPanZoomImage src={fileInfo?.src} alt="Preview" />
      ) : isVideo || isAudio ? (
        <ReactPlayer
          url={fileInfo?.src}
          controls
          width="100%"
          height="100%"
          className="box-video-player"
          onError={error => {
            if (error) setFileInfo({ ...fileInfo, error });
          }}
        />
      ) : (
        <OtherFile fileInfo={fileInfo} onlyPreviewFileOnClient={onlyPreviewFileOnClient} onDownload={onDownload} />
      )}
    </div>
  );
};

export default ViewFile;
