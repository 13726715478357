import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';
import qs from 'qs';

import {
  ENDPOINTS,
  WORK_ITEM_TEST_RESULT_ID,
  SYSTEM_FIELD_STATUS,
  SYSTEM_FIELD_LATEST_RESULT
} from '../../../../constants';
import {
  Http,
  handleError,
  removeDuplicate,
  buildQueryForParamsUrl,
  mappingDataForPreviewChart
} from '../../../../common';

export const model = {
  /**
   * State
   */
  data: null,
  editingChart: null,
  query: null,
  previewChart: null,
  previewPayload: null,
  isEditingMode: false,
  loadingList: false,
  loadingItem: false,
  loadingPreviewChart: false,

  /**
   * Action: Set chart list
   */
  setChartList: action((state, payload) => {
    if (state?.data === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.data = payload;
  }),

  /**
   * Action: Set editing chart
   */
  setEditingChart: action((state, payload) => {
    if (state?.editingChart === undefined) return;

    state.editingChart = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set preview chart
   */
  setPreviewChart: action((state, payload) => {
    if (state?.previewChart === undefined) return;

    if (!(Array.isArray(payload) && payload.length && Array.isArray(payload[0].data) && payload[0].data.length)) {
      state.previewChart = null;
      return;
    }

    state.previewChart = payload;
  }),

  /**
   * Action: Set preview payload
   */
  setPreviewPayload: action((state, payload) => {
    if (state?.previewPayload === undefined) return;

    state.previewPayload = payload;
  }),

  /**
   * Action: Set is editing mode
   */
  setIsEditingMode: action((state, payload) => {
    if (state?.isEditingMode === undefined) return;

    state.isEditingMode = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading preview chart
   */
  setLoadingPreviewChart: action((state, payload) => {
    if (state?.loadingPreviewChart === undefined) return;

    state.loadingPreviewChart = payload;
  }),

  /**
   * Action: Call api to get chart list
   */
  getChartList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingList(true);
      action.setQuery(payload);

      let isNotGetPreview = false;

      if (payload?.isNotGetPreview) {
        isNotGetPreview = true;
      }
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.REPORT
      }?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);
      let newData =
        Array.isArray(res?.data?.rows) && res.data.rows.length
          ? [...res.data.rows].filter(item => item.previewPayload?.source)
          : [];

      if (Array.isArray(newData) && newData.length && !isNotGetPreview) {
        newData = await Promise.all(
          [...newData].map(async item => {
            try {
              const url2 = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT_PREVIEW}`;
              const newPayload2 = {
                ...(item.previewPayload || {}),
                filter: qs.parse(item.previewPayload?.filter)
              };
              const res2 = await Http.post(url2, newPayload2).then(res => res.data);
              let previewChart = null;

              if (
                Array.isArray(res2?.data) &&
                res2.data.length &&
                Array.isArray(res2.data[0].data) &&
                res2.data[0].data.length
              ) {
                previewChart = mappingDataForPreviewChart({ previewPayload: newPayload2, previewChart: res2?.data });
              }

              return Promise.resolve({ ...item, previewChart });
            } catch (err) {
              return Promise.resolve({ ...item, previewChart: null });
            }
          })
        );

        let workTicketIdsForStatus = newData
          .filter(item => {
            return (
              item?.previewPayload?.group?.refName === SYSTEM_FIELD_STATUS ||
              item?.previewPayload?.x?.refName === SYSTEM_FIELD_STATUS
            );
          })
          .map(item => item?.previewPayload?.source);

        if (
          newData.some(item => {
            return (
              item?.previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT ||
              item?.previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT
            );
          })
        ) {
          workTicketIdsForStatus.push(WORK_ITEM_TEST_RESULT_ID);
        }

        workTicketIdsForStatus = removeDuplicate(workTicketIdsForStatus, '');

        await Promise.all(
          [...workTicketIdsForStatus].map(async item => {
            await helpers.getStoreActions()?.global?.getTicketTypeById(item);
          })
        );
      }

      action.setChartList(newData);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get chart by Id
   */
  getChartById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload.id) throw new Error('Get chart by id: No Chart ID');

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT}/${payload.id}`;
      const res = await Http.get(url).then(res => res.data);

      const newData = { ...(res?.data || {}) };

      if (res?.data?.previewPayload?.source && payload.isGetPreviewChart) {
        const newPreviewPayload = {
          ...res.data.previewPayload,
          filter: qs.parse(res.data.previewPayload.filter)
        };

        const res2 = await action.getPreviewChart(newPreviewPayload);

        if (res2) newData.previewChart = res2;
      }

      if (
        (res?.data?.previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT ||
          res?.data?.previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT) &&
        !helpers.getStoreState()?.global?.ticketListData?.[WORK_ITEM_TEST_RESULT_ID]
      ) {
        await helpers.getStoreActions()?.global?.getTicketTypeById(WORK_ITEM_TEST_RESULT_ID);
      }

      action.setEditingChart(newData);

      return newData;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create chart
   */
  createChart: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create chart: Invalid Payload');
      }

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:report.messageCreatingChart'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update chart
   */
  updateChart: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) throw new Error('Update chart: No Chart ID');

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const _id = payload._id;
      delete newPayload._id;

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:report.messageUpdatingChart'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete chart
   */
  deleteChart: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) throw new Error('Delete chart: Invalid Payload');

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT}/${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:report.messageDeletingChart'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      action.getChartList(helpers.getState()['query']);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get preview chart
   */
  getPreviewChart: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.source) throw new Error('Get preview chart: There are no source');

      const newPayload = {
        ...payload,
        filter: { ...(payload.filter || {}) }
      };

      action.setLoadingPreviewChart(true);
      action.setPreviewPayload(newPayload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}/reporting/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.REPORT_PREVIEW}`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      let newData = res?.data;

      if (Array.isArray(res?.data)) {
        newData = mappingDataForPreviewChart({ previewPayload: newPayload, previewChart: res?.data });
      }

      action.setPreviewChart(newData);

      return newData;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingPreviewChart(false);
    }
  })
};
