import { action, thunk } from 'easy-peasy';
import { notification } from 'antd';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, buildQueryForParamsUrl, handleError, sleep } from '../../../../common';

export const model = {
  /**
   * State
   */
  data: null,
  total: 0,
  query: {},
  loadingList: false,
  editingTestConfig: {},
  loadingItem: false,
  visibleTestConfigModal: false,

  /**
   * Action: Set list test config
   */
  setTestConfigList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set total
   */
  setTotal: action((state, payload) => {
    if (state?.total === undefined) return;

    state.total = !isNaN(payload) ? payload : 0;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set editing test config
   */
  setEditingTestConfig: action((state, payload) => {
    if (state?.editingTestConfig === undefined) return;

    state.editingTestConfig = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set visible test config modal
   */
  setVisibleTestConfigModal: action((state, payload) => {
    if (state?.visibleTestConfigModal === undefined) return;

    state.visibleTestConfigModal = payload;
  }),

  /**
   * Get list test config
   */
  getTestConfigList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test config list: Invalid Params');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_TEST_CONFIG
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestConfigList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Get detail test config
   */
  getTestConfigById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test config by id: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Get test config by id: There are no id');
      }

      const { id } = payload;
      action.setLoadingItem(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_CONFIG}/${id}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTestConfig(res?.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create test config
   */
  createTestConfig: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create test config: Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create test config: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_CONFIG}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testConfig.messageCreatingEnvironment'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update test config
   */
  updateTestConfig: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update test config: Invalid Params');
      }

      if (!payload?.id) throw new Error('Update test config: Invalid Payload');

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_CONFIG}/${payload.id}`;
      const res = await Http.put(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testConfig.messageUpdatingEnvironment'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete test config
   */
  deleteTestConfig: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload?.ids) && payload?.ids.length)) {
        throw new Error('Delete test config: No ids');
      }

      const promises = [];
      let isDone = false;

      for (let i = 0; i < payload?.ids.length; i++) {
        const id = payload?.ids[i];
        const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_CONFIG}/${id}`;
        const res = await Http.delete(url).then(res => res.data);

        promises.push(res);
      }

      if (promises.length === payload?.ids.length) {
        isDone = true;

        notification.success({
          message: i18next.t('akaat:testConfig.messageDeletingEnvironment'),
          description: i18next.t('akaat:message.deletedSuccessfully')
        });
      }

      return isDone;
    } catch (err) {
      handleError(err);
    }
  })
};
