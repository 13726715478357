import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Modal, Button, Tooltip, Collapse, Spin, Form, Input } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  CommentOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons';

import { FULL_DATE_FORMAT, JIRA_PLATFORM_ID } from '../../constants';
import { useKeycloak, addTokenToRawHtml, removeTokenToRawHtml } from '../../common';
import { UserAvatar, BasicEditor, SafeInnerHtml } from '../../components';
import { PlusOutlined } from '../../assets/svg-icons';
import { UpdateCommentModal } from './update-comment-modal';

import './style.scss';

const { Panel } = Collapse;

export const BasicComment = ({
  fromModule,
  visible,
  data,
  total,
  page,
  limit,
  editingComment,
  visibleUpdateCommentModal,
  loadingList,
  loadingItem,
  className = '',
  isReadOnly,
  onSaveComment,
  onDeleteComment,
  onShowDetail,
  onCloseDetail,
  onShowMore,
  onReload,
  onOpenChange,
  uploadPath,
  onEditorAttachFiles,
  restBasicEditor,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For keycloak
  const { keycloak } = useKeycloak();

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  // Component state
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([]);
  const [content, setContent] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [visibleEditor, setVisibleEditor] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  /**
   * Set is visible
   */
  useEffect(() => {
    if (typeof visible !== 'boolean') return;

    setIsVisible(visible);
  }, [visible]);

  /**
   * Set active collapse keys
   */
  useEffect(() => {
    if (!(Array.isArray(data) && data.length)) return;

    const ids = data.map(item => item?._id);

    setActiveCollapseKeys(ids);
  }, [data]);

  /**
   * On add
   */
  const onAdd = () => {
    const formData = {
      content: content
    };

    onSaveComment(formData);

    if (typeof visible !== 'boolean') {
      setIsVisible(false);

      if (typeof onOpenChange == 'function') onOpenChange(false);
    }
  };

  /**
   * Handle delete comment
   */
  const handleDeleteComment = item => {
    if (!item) return;

    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject =
      projectUsers.some(u => u?.username === item.createdBy?.username) || item?.externalSystem === JIRA_PLATFORM_ID;

    Modal.confirm({
      title: t('comment.deleteComment'),
      content: (
        <>
          <div className="mb-3">{t('comment.areYouSureYouWantToDeleteThisComment')}</div>

          <Form labelCol={{ sm: 6, md: 4 }} wrapperCol={{ sm: 18, md: 20 }} labelAlign="left">
            <Form.Item label={t('workItem.createdBy')} className="mb-0">
              <UserAvatar
                user={item?.createdBy}
                inactive={!isUserInProject}
                restName={{ className: 'font-weight-medium' }}
              />
            </Form.Item>

            <Form.Item label={t('workItem.createdAt')} className="mb-0">
              <span className="font-weight-medium">
                {item?.createdAt && moment(item?.createdAt).isValid()
                  ? moment(item?.createdAt).format(FULL_DATE_FORMAT)
                  : ''}
              </span>
            </Form.Item>

            <Form.Item label={t('comment.comment')} className="mb-0">
              <SafeInnerHtml
                html={addTokenToRawHtml({ rawHtml: item.content, token: keycloak?.token })}
                className="pt-1"
              />
            </Form.Item>
          </Form>
        </>
      ),
      width: 800,
      autoFocusButton: null,
      maskClosable: true,
      okText: t('common.delete'),
      okButtonProps: { danger: true, type: 'primary', icon: <DeleteOutlined /> },
      cancelText: t('common.cancel'),
      onOk: () => onDeleteComment(item)
    });
  };

  /**
   * Render panel header
   */
  const renderPanelHeader = item => {
    if (!item) return;

    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject =
      projectUsers.some(u => u?.username === item.createdBy?.username) || item?.externalSystem === JIRA_PLATFORM_ID;
    const isValidCreatedAt = item.createdAt && moment(item.createdAt).isValid();

    return (
      <div className="txt-name">
        <UserAvatar user={item.createdBy} inactive={!isUserInProject} />
        <div className="mx-1">-</div>
        <div title={isValidCreatedAt ? moment(item.createdAt).format(FULL_DATE_FORMAT) : ''}>
          {`${isValidCreatedAt ? moment(item.createdAt).fromNow() : ''}`}
        </div>
      </div>
    );
  };

  /**
   * Render extra button
   */
  const renderExtraButton = item => {
    if (!item) return;

    return (
      <>
        {!isReadOnly &&
          (item.createdBy?.email === globalUserInfo?.email ||
            item.createdBy?.username === globalUserInfo?.username ||
            item.externalSystem === JIRA_PLATFORM_ID) && (
            <>
              <Tooltip placement="top" title={t('common.edit')} destroyTooltipOnHide={true}>
                <Button
                  type="link"
                  icon={<EditOutlined className="font-size-14" />}
                  className="text-gray-2 text-hover-primary w-auto h-auto line-height-1 border-0 ml-2"
                  onClick={e => {
                    e.stopPropagation();
                    onShowDetail(item);
                  }}
                />
              </Tooltip>

              <Tooltip placement="top" title={t('common.delete')} destroyTooltipOnHide={true}>
                <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined className="font-size-14" />}
                  className="text-gray-2 text-hover-danger w-auto h-auto line-height-1 border-0 ml-2"
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteComment(item);
                  }}
                />
              </Tooltip>
            </>
          )}
      </>
    );
  };

  return (
    <>
      <div className={`c-basic-comment ${className}`} {...rest}>
        <div className="position-relative">
          {fromModule == 'SUBMIT_OUTSISE' ? (
            ' '
          ) : (
            <Tooltip placement="bottom" title={t('common.reload')} destroyTooltipOnHide={true}>
              <Button
                id="reload-comment-button"
                type="link"
                icon={<ReloadOutlined />}
                className="btn-reload-on-detail-page"
                onClick={onReload}
              />
            </Tooltip>
          )}
        </div>

        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingList}>
          {Array.isArray(data) && data.length > 0 && (
            <Collapse
              activeKey={activeCollapseKeys}
              destroyInactivePanel={true}
              bordered={false}
              onChange={setActiveCollapseKeys}
            >
              {data.map(item => (
                <Panel
                  key={item?._id}
                  id={`comment-${item?._id}`}
                  header={renderPanelHeader(item)}
                  extra={renderExtraButton(item)}
                >
                  <SafeInnerHtml html={addTokenToRawHtml({ rawHtml: item?.content, token: keycloak?.token })} />
                </Panel>
              ))}
            </Collapse>
          )}

          {!(Array.isArray(data) && data.length) && <div className="text-gray-2 py-2">{t('comment.noComment')}</div>}
        </Spin>

        <div className="text-center mt-2">
          {Array.isArray(data) && data.length > 0 && page < Math.ceil(total / limit) && (
            <span
              className="text-primary text-hover-dark-primary cursor-pointer text-center"
              onClick={e => {
                e.preventDefault();
                onShowMore();
              }}
            >
              {loadingList && <Loading3QuartersOutlined spin />} {t('common.showMore')}
            </span>
          )}
        </div>

        {!isReadOnly && (
          <>
            {!isVisible && (
              <Button
                id="open-comment-editor-button"
                icon={<CommentOutlined />}
                className="mt-2"
                onClick={() => {
                  setIsVisible(true);
                  if (typeof onOpenChange == 'function') onOpenChange(true);
                }}
              >
                {t('comment.comment')}
              </Button>
            )}

            {isVisible && (
              <div className="box-editor pt-2">
                <p className="font-weight-medium mb-2">{t('comment.comment')}:</p>

                <Input id="comment-content-field" value={content} hidden />

                {visibleEditor && (
                  <BasicEditor
                    restEditor={{ placeholder: t('comment.enterComment') }}
                    autoFocus
                    uploadPath={uploadPath}
                    onEditorAttachFiles={file => {
                      if (typeof onEditorAttachFiles === 'function') onEditorAttachFiles(file);
                    }}
                    onEditorRawHtmlChange={val => setContent(removeTokenToRawHtml({ rawHtml: val }))}
                    {...restBasicEditor}
                  />
                )}

                <div className="text-right">
                  <Button
                    id="add-comment-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    size="small"
                    className="min-w-80 mt-2 ml-1"
                    loading={loadingItem}
                    disabled={!content}
                    onClick={onAdd}
                  >
                    {t('common.add')}
                  </Button>

                  <Button
                    id="cancel-comment-button"
                    size="small"
                    onClick={() => {
                      setIsVisible(false);
                      if (typeof onOpenChange == 'function') onOpenChange(false);
                    }}
                    className="min-w-80 mt-2 ml-1"
                  >
                    {t('common.cancel')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {visibleUpdateCommentModal && (
        <UpdateCommentModal
          visible={visibleUpdateCommentModal}
          editingComment={editingComment}
          uploadPath={uploadPath}
          isReadOnly={isReadOnly}
          loadingItem={loadingItem}
          onSaveComment={onSaveComment}
          onEditorAttachFiles={onEditorAttachFiles}
          onCancel={onCloseDetail}
        />
      )}
    </>
  );
};
