import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import slug from 'slug';

import {
  ENDPOINTS,
  COMPONENT_TYPE,
  SYSTEM_FIELD_PATH,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_TEST_STEPS
} from '../../../../constants';
import {
  Http,
  jsonParse,
  handleError,
  removeDuplicate,
  generateTestStepKey,
  getPrePathLinkToTicket,
  buildQueryForFilterMongo
} from '../../../../common';

export const model = {
  /**
   * State
   */
  fieldConfig: null,
  fieldList: [],
  allSubFieldList: [],
  data: [],
  total: 0,
  editingTestCase: {},
  query: {},
  selectedRows: [],
  selectedRowKeys: [],
  expandedRowKeys: [],
  collapseKeys: [], // For collapse/expand boxes on detail form
  activeActivityTab: '',
  loadingList: false,
  loadingItem: false,
  testcaseWorkItem: {},
  resetView: false,
  fullScreen: false,
  deteledAttachmentIds: [],
  tokenGenerateTestCase: null,
  testStepRawInfo: null,

  /**
   * Action: Set field config
   */
  setFieldConfig: action((state, payload) => {
    if (state?.fieldConfig === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length)) {
      state.fieldConfig = null;
    }

    state.fieldConfig = payload;
  }),

  /**
   * Action: Set test case field list
   */
  setFieldList: action((state, payload) => {
    if (state.fieldList === undefined || payload === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.fieldList = [];
      return;
    }

    state.fieldList = payload;
  }),

  /**
   * Action: Set test case field list contain field relation
   */
  setAllSubFieldList: action((state, payload) => {
    if (state.allSubFieldList === undefined || payload === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.allSubFieldList = [];
      return;
    }

    state.allSubFieldList = payload;
  }),

  /**
   * Action: Set test case list
   */
  setTestCaseList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) return;

    if (!(Array.isArray(payload?.rows) && payload?.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    let newData = [];

    if (payload?.forceSetList) {
      newData = [...payload?.rows];
    } else {
      newData = [...payload?.rows].map((item, index) => {
        let testSteps = item?.[SYSTEM_FIELD_TEST_STEPS];

        testSteps =
          Array.isArray(testSteps) && testSteps.length
            ? [...testSteps].map(s => ({ ...s, key: generateTestStepKey(item?.key) }))
            : [];

        return {
          ...item,
          xNumber: index + 1,
          [SYSTEM_FIELD_TEST_STEPS]: testSteps
        };
      });
    }

    state.data = newData;
    state.total = payload?.count;
  }),

  /**
   * Action: Set editing test case
   */
  setEditingTestCase: action((state, payload) => {
    if (state?.editingTestCase === undefined) return;

    state.editingTestCase = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) return;

    state.query = payload;
  }),

  /**
   * Action: Set selected row
   */
  setSelectedRows: action((state, payload) => {
    if (state?.selectedRows === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRows = [];
      return;
    }

    state.selectedRows = payload;
  }),

  /**
   * Action: Set selected row keys
   */
  setSelectedRowKeys: action((state, payload) => {
    if (state?.selectedRowKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRowKeys = [];
      return;
    }

    state.selectedRowKeys = payload;
  }),

  /**
   * Action: Set expanded row keys
   */
  setExpandedRowKeys: action((state, payload) => {
    if (state?.expandedRowKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedRowKeys = [];
      return;
    }

    state.expandedRowKeys = payload;
  }),

  /**
   * Action: Set collapse keys
   */
  setCollapseKeys: action((state, payload) => {
    if (state?.collapseKeys === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.collapseKeys = [];
      return;
    }

    state.collapseKeys = payload;
  }),

  /**
   * Action: Set active activity tab
   */
  setActiveActivityTab: action((state, payload) => {
    if (state?.activeActivityTab === undefined) return;

    state.activeActivityTab = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) return;

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set reset view
   */
  setResetView: action((state, payload) => {
    if (state?.resetView === undefined) return;

    state.resetView = payload;
  }),

  /**
   * Action: Set full screen
   */
  setFullScreen: action((state, payload) => {
    if (state?.fullScreen === undefined) return;

    state.fullScreen = payload;
  }),

  /**
   * Action: Set deteled attachment ids
   */
  setDeteledAttachmentIds: action((state, payload) => {
    if (state?.deteledAttachmentIds === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.deteledAttachmentIds = [];
      return;
    }

    state.deteledAttachmentIds = payload;
  }),

  /**
   * Action: Set editing test case
   */
  setTokenGenerateTestCase: action((state, payload) => {
    if (state?.tokenGenerateTestCase === undefined) return;

    state.tokenGenerateTestCase = payload;
  }),

  /**
   * Action: Set test step raw info
   */
  setTestStepRawInfo: action((state, payload) => {
    if (state?.testStepRawInfo === undefined) return;

    state.testStepRawInfo = payload;
  }),

  /**
   * Action: Set editing test case
   */
  setTokenGenerateTestCase: action((state, payload) => {
    if (state?.tokenGenerateTestCase === undefined) return;

    state.tokenGenerateTestCase = payload;
  }),

  /**
   * Action: Set test step raw info
   */
  setTestStepRawInfo: action((state, payload) => {
    if (state?.testStepRawInfo === undefined) return;

    state.testStepRawInfo = payload;
  }),

  /**
   * Action: Call api to get testCase list by filter qs
   */
  getTestCaseListByFilterQs: thunk(async (action, payload, helpers) => {
    const query = jsonParse(JSON.stringify(payload)) || {};
    const { noSetDataToStore } = query;
    delete query.noSetDataToStore;

    try {
      if (!noSetDataToStore) {
        action.setLoadingList(true);
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!noSetDataToStore) {
        action.setQuery(payload);
      }

      if (!query?.filter?.path) {
        query.filter = {
          ...query.filter,
          path: {
            $options: 'si',
            $regex: `${slug(tenant?.tenantKey)}/${slug(project?.projectKey)}/`
          }
        };
      }

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.TESTCASE
      }?${buildQueryForFilterMongo(query)}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setTestCaseList(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!noSetDataToStore) {
        action.setLoadingList(false);
      }
    }
  }),

  /**
   * Action: Call api to get test case by Id
   */
  getTestCaseByKey: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingItem(true);

      const { key } = payload;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/${key}`;
      const res = await Http.get(url).then(res => res.data);

      let testSteps = res?.data?.[SYSTEM_FIELD_TEST_STEPS];

      testSteps =
        Array.isArray(testSteps) && testSteps.length
          ? [...testSteps].map(s => ({ ...s, key: generateTestStepKey(res?.data?.key) }))
          : [];

      const newData = { ...res?.data, [SYSTEM_FIELD_TEST_STEPS]: testSteps };

      action.setEditingTestCase(newData);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get test case version by Id
   */
  getTestCaseVersionByKey: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingItem(true);

      const { key } = payload;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_VERSION}/${key}`;
      const res = await Http.get(url).then(res => res.data);

      let testSteps = res?.data?.[SYSTEM_FIELD_TEST_STEPS];

      testSteps =
        Array.isArray(testSteps) && testSteps.length
          ? [...testSteps].map(s => ({ ...s, key: generateTestStepKey(res?.data?.key) }))
          : [];

      const newData = { ...res?.data, [SYSTEM_FIELD_TEST_STEPS]: testSteps };

      action.setEditingTestCase(newData);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create test case
   */
  createTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }
      let newPayload;
      let noShowSuccessMessage = false;
      if (Array.isArray(payload) && payload.length) {
        newPayload = payload;
      } else {
        newPayload = { ...payload };
        noShowSuccessMessage = newPayload?.noShowSuccessMessage;
        delete newPayload.noShowSuccessMessage;
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageCreatingTestCase'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to add testcases to test suite
   */
  addTestcasesToTestSuite: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.data) throw new Error('Add testcases to test suite: Invalid Array');

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { data, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${payload.testSuiteId}${ENDPOINTS.TESTCASE}`;
      const res = await Http.post(url, data).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageAddingTestCases'),
          description: i18next.t('akaat:message.addedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update test case
   */
  updateTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { key, noShowSuccessMessage } = newPayload;

      delete newPayload.key;
      delete newPayload.isUpgradeVersion;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/${key}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageUpdatingTestCase'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update test case
   */
  updateTestCaseVersion: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { key, noShowSuccessMessage } = newPayload;

      delete newPayload.key;
      delete newPayload.isUpgradeVersion;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_VERSION}/${key}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageUpdatingTestCase'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete test case
   */
  deleteTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      const newPayload = { ...payload };
      const { keys, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const body = {
        data: { keys } // Example: keys is ["6", "8", "99"]
      };

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/delete-many`;
      await Http.delete(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageDeletingTestCase'),
          description: i18next.t('akaat:message.deletedSuccessfully')
        });
      }

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to clone multi test cases
   */
  cloneTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!Array.isArray(payload?.keys)) throw new Error('Invalid Array');

      action.setLoadingList(true);

      const { keys, noShowSuccessMessage } = payload;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_CLONE}`;
      const res = await Http.post(url, { keys }).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageCloningTestCase'),
          description: i18next.t('akaat:message.clonedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to create multi test case
   */
  createMultiTestCase: thunk(async (action, payload, helpers) => {
    try {
      if (!Array.isArray(payload?.body)) {
        throw new Error('Create multi test case: Invalid payload');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingList(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/multiple`;
      const res = await Http.post(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageCreatingTestCase'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res;
    } catch (error) {
      handleError(error);
      return { error };
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to update multi test case
   */
  updateMultiTestCase: thunk(async (action, payload, helpers) => {
    try {
      if (!(Array.isArray(payload?.body) && payload?.body.length)) {
        throw new Error('Update multi test case: Invalid payload');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingList(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}`;
      const res = await Http.put(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:message.yourChangesHasBeenSaved')
        });
      }

      return res;
    } catch (error) {
      handleError(error);
      return { error };
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to change status testcase
   */
  changeStatusTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      const newPayload = { ...payload };
      const { key, noShowSuccessMessage } = newPayload;
      delete newPayload.key;
      delete newPayload.noShowSuccessMessage;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/${key}${ENDPOINTS.TESTCASE_STATUS}`;
      const res = Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:message.yourChangesHasBeenSaved')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to change status testcase
   */
  changeStatusTestCaseVersion: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) throw new Error('Invalid Payload');

      const newPayload = { ...payload };
      const { key, noShowSuccessMessage } = newPayload;
      delete newPayload.key;
      delete newPayload.noShowSuccessMessage;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_VERSION}/${key}${ENDPOINTS.TESTCASE_STATUS}`;
      const res = Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageUpdatingTestCase'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: On change collapse
   */
  onChangeCollapse: thunk(async (action, payload, helpers) => {
    if (!payload) throw new Error('There are no key');

    let collapseKeys = helpers.getState()['collapseKeys'];
    let newKeys = [];

    collapseKeys = Array.isArray(collapseKeys) && collapseKeys.length ? [...collapseKeys] : [];

    if (collapseKeys.includes(payload)) {
      newKeys = [...collapseKeys].filter(item => item !== payload);
    } else {
      newKeys = [...collapseKeys, payload];
    }

    action.setCollapseKeys(newKeys);
  }),

  /**
   * Get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    const { visibleDetailRelation, fieldListData } = { ...(payload || {}) };

    let processList = helpers.getStoreState()?.global?.globalProcessList;
    processList = Array.isArray(processList) && processList.length ? [...processList] : [];

    let testcaseFieldList = fieldListData?.[WORK_ITEM_TESTCASE_ID] || [];
    const relationField = [];
    let newFieldList = [];

    for (let index = 0; index < testcaseFieldList.length; index++) {
      const field = testcaseFieldList[index];

      if (field?.refName === SYSTEM_FIELD_PATH) {
        field.name = i18next.t('akaat:workItem.repositoryFolder');
      }

      if (field?.componentType === COMPONENT_TYPE.RELATION && visibleDetailRelation) {
        if (fieldListData?.[field?.lookup?.workTicketId]) {
          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;
          const list = convertSubRelationField(field, fieldListData?.[field?.lookup?.workTicketId], workTicketName);

          newFieldList.push(...list);
        } else if (fieldListData && !Object.keys(fieldListData).includes(field?.lookup?.workTicketId)) {
          const res = await helpers.getStoreActions()?.global?.getTicketTypeById(field?.lookup?.workTicketId);
          fieldListData[field?.lookup?.workTicketId] = res?.fields;

          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;
          const list = convertSubRelationField(field, res?.fields, workTicketName);

          newFieldList.push(...list);
        }
      }

      newFieldList.push({
        ...field,
        workTicketId: WORK_ITEM_TESTCASE_ID
      });
    }

    let list = [...newFieldList, ...relationField].filter(field => field && !field?.invisible);

    if (visibleDetailRelation) {
      list = list.filter(field => field?.componentType !== COMPONENT_TYPE.RELATION);
    }

    list = removeDuplicate(list, 'refName');

    return list;
  }),

  /**
   * Action: Call api to delete test case
   */
  addedTestCase2TestRun: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      const newPayload = { ...payload };
      const { keys } = newPayload;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/check-testcase-existed`;
      const res = await Http.post(url, { keys }).then(res => res.data);

      return res.data;
    } catch (err) {
      handleError(err);
    }
  })
};
/**
 * Handle return sub field relation
 */
const convertSubRelationField = (rootField, list = [], workTicketName) => {
  let relationField = [];

  list.forEach(fieldRelation => {
    relationField.push({
      ...fieldRelation,
      isInsert: false,
      refName: `${rootField?.refName}.${fieldRelation.refName}`,
      name: i18next.exists(`akaat:workItem.${fieldRelation.name}`)
        ? `${workTicketName || rootField?.name} - ${i18next.t(`akaat:workItem.${fieldRelation.name}`)}`
        : `${workTicketName || rootField?.name} - ${fieldRelation.name}`,
      workTicketId: rootField?.lookup?.workTicketId,
      refNameParent: rootField?.refName,
      isRelationDisplayField: rootField?.data.displayField === fieldRelation.refName,
      prePath: getPrePathLinkToTicket({ workTicketId: rootField?.lookup?.workTicketId })
    });
  });

  return relationField;
};
