import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Tooltip, Divider, Row } from 'antd';
import { ReloadOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

import { WorkflowDiagram } from '../../components';

export const WorkflowDiagramModal = ({
  visible,
  currentStatusList,
  currentStepList,
  className = '',
  onCancel,
  ...rest
}) => {
  const refBoxWrapper = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isVisibleDiagram, setIsVisibleDiagram] = useState(false);
  const [diagramHeight, setDiagramHeight] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [visibleFullScreenTooltip, setVisibleFullScreenTooltip] = useState(false);

  /**
   * Waiting for data: currentStatusList, currentStepList
   */
  useEffect(() => {
    setTimeout(() => setIsVisibleDiagram(true), 200);
  }, []);

  return (
    <>
      <Modal
        open={visible}
        width={1200}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
        className={`c-workflow-diagram-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${
          fullScreen ? 'full-screen-modal' : ''
        } ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">{t('process.workflow')}</h3>

            <Row align="middle" justify="end" wrap={false} className="box-extra">
              <Tooltip placement="bottom" title={t('common.reload')} destroyTooltipOnHide={true}>
                <Button
                  type="link"
                  icon={<ReloadOutlined />}
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    if (refBoxWrapper?.current) {
                      setDiagramHeight(refBoxWrapper.current.offsetHeight);
                      setIsVisibleDiagram(false);
                    }

                    setTimeout(() => {
                      setIsVisibleDiagram(true);
                      setDiagramHeight(null);
                    }, 200);
                  }}
                ></Button>
              </Tooltip>

              <Tooltip
                open={visibleFullScreenTooltip}
                title={fullScreen ? t('common.exitFullScreen') : t('common.fullScreen')}
                placement={fullScreen ? 'bottomRight' : 'bottom'}
                destroyTooltipOnHide={true}
                overlayStyle={{ pointerEvents: 'none' }}
                onOpenChange={setVisibleFullScreenTooltip}
              >
                <Button
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  type="link"
                  className="text-gray-2 text-hover-primary border-0 bg-transparent w-auto h-auto p-0 ml-3"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                    setVisibleFullScreenTooltip(false);
                  }}
                />
              </Tooltip>
            </Row>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div ref={refBoxWrapper} className="form-wrapper p-4" style={{ height: diagramHeight || null }}>
            {isVisibleDiagram && (
              <WorkflowDiagram
                currentStatusList={currentStatusList}
                currentStepList={currentStepList}
                isReadOnly={true}
                restReactFlow={{
                  style: {
                    height: 'calc(100vh - 156px)',
                    minHeight: 350
                  }
                }}
              />
            )}
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button type="text" onClick={onCancel}>
              {t('common.close')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
