import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Row, Col, Tooltip, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { WORK_ITEM_TEST_RESULT_ID } from '../../constants';
import { useStatus } from '../../common';

import './style.scss';

export const BasicProgressBarV2 = ({
  node,
  summaryData,
  summaryTooltipTitle,
  showSummaryTooltip,
  showItemTooltip,
  hideProgressBar,
  summaryTooltipLoading = false,
  noGetStatusFromWorkflowById,
  className = '',
  onShowSummaryTooltip,
  children,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For status
  const { getStatusFromWorkflowById } = useStatus();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  /**
   * Compute current summary data
   */
  const currentSummaryData = useMemo(() => {
    if (!summaryData?.total) return;

    if (noGetStatusFromWorkflowById) return summaryData;

    const summaryList =
      Array.isArray(summaryData.summary) && summaryData.summary.length
        ? [...summaryData.summary].map(item => {
            return {
              ...item,
              status: getStatusFromWorkflowById(item.status, ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow)
            };
          })
        : [];

    return {
      ...summaryData,
      summary: summaryList
    };
  }, [summaryData]);

  /**
   * Render bar
   */
  const renderBar = currentSummary => {
    return (
      <Row justify="end" className="c-basic-progress-bar-wrapper-v2">
        {Array.isArray(currentSummary?.summary) &&
          currentSummary?.summary.length > 0 &&
          !isNaN(currentSummary?.total) &&
          currentSummary?.summary.map(item => {
            if (!item || isNaN(item?.count)) return null;

            const percent = `${((item.count / currentSummary.total) * 100).toFixed(2)}%`;

            return (
              <Col
                key={item.status?.id}
                className="progress-bar"
                style={{
                  width: `${(item.count / currentSummary.total) * 100}%`,
                  background: `rgb(${item.status?.background?.r}, ${item.status?.background?.g}, ${item.status?.background?.b})`
                }}
              >
                {showItemTooltip && (
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        {item.title && <div className="font-weight-medium mt-0 mb-2">{item.title}</div>}

                        <div className="d-flex flex-wrap-nowrap text-truncate">
                          <div title={item.status?.name || ''} className="flex-grow-1 text-truncate">
                            <span
                              className="dot-status-style mr-1"
                              style={{
                                background: `rgb(${item.status?.background?.r}, ${item.status?.background?.g}, ${item.status?.background?.b})`,
                                width: 12,
                                height: 12,
                                verticalAlign: '-0.1em'
                              }}
                            ></span>
                            {item.status?.name || ''}
                          </div>

                          <div className="font-weight-medium pl-2">{percent || '0%'}</div>
                        </div>
                      </>
                    }
                    destroyTooltipOnHide={true}
                    color="#f0f2f5"
                    overlayInnerStyle={{ color: '#383838' }}
                    overlayClassName="ant-tooltip-inner-rounded-6"
                    overlayStyle={{ minWidth: 220 }}
                  >
                    <div className="d-block w-100 h-100"></div>
                  </Tooltip>
                )}
              </Col>
            );
          })}
      </Row>
    );
  };

  /**
   * Render summary tooltip
   */
  const renderSummaryTooltip = currentSummary => {
    return (
      <Tooltip
        placement="top"
        title={
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={summaryTooltipLoading}>
            <div className="px-3 pt-2 pb-3" style={{ minHeight: 100 }} onClick={e => e.stopPropagation()}>
              {summaryTooltipTitle && <div className="font-weight-medium mt-0 mb-2">{summaryTooltipTitle}</div>}

              {Array.isArray(currentSummary?.summary) &&
              currentSummary.summary.length > 0 &&
              !isNaN(currentSummary.total) ? (
                <ul className="list-style-none p-0 m-0">
                  {currentSummary.summary.map(item => {
                    if (!item || isNaN(item?.count)) return null;

                    const percent = `${((item.count / currentSummary.total) * 100).toFixed(2)}%`;

                    return (
                      <li key={item.status?.id} className="mb-1">
                        <div className="d-flex flex-wrap-nowrap text-truncate">
                          <div title={item.status?.name || ''} className="flex-grow-1 text-truncate">
                            <span
                              className="dot-status-style mr-1"
                              style={{
                                background: `rgb(${item.status?.background?.r}, ${item.status?.background?.g}, ${item.status?.background?.b})`,
                                width: 12,
                                height: 12,
                                verticalAlign: '-0.1em'
                              }}
                            ></span>
                            {item.status?.name || ''}
                          </div>
                          <div className="font-weight-medium pl-2">{percent || '0%'}</div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <div className="text-center text-gray pt-2 pb-3" style={{ opacity: summaryTooltipLoading ? 0 : null }}>
                  {t('common.noData')}
                </div>
              )}

              {!hideProgressBar && renderBar(currentSummaryData)}
            </div>
          </Spin>
        }
        destroyTooltipOnHide={true}
        mouseEnterDelay={0.8}
        color="#f0f2f5"
        overlayStyle={{ minWidth: 250 }}
        overlayInnerStyle={{ color: '#383838', padding: 0 }}
        overlayClassName="c-basic-progress-bar-summary-tooltip-v2 ant-tooltip-inner-rounded-6"
        onOpenChange={visible => {
          if (visible && typeof onShowSummaryTooltip === 'function') onShowSummaryTooltip(node);
        }}
      >
        <div className="progress-bar-tooltip-v2"></div>
      </Tooltip>
    );
  };

  return (
    <div className={`c-basic-progress-bar-v2 ${className}`} {...rest}>
      {showSummaryTooltip && renderSummaryTooltip(currentSummaryData)}

      {children ? children : renderBar(currentSummaryData)}
    </div>
  );
};
