import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  editingWorkflow: {},
  workflowByWorkItem: {},
  isEditingMode: 'DETAIL',
  editingStatus: {},
  editingStep: {},
  loadingItem: false,
  fullScreen: false,

  /**
   * Action: Set editing workflow
   */
  setEditingWorkflow: action((state, payload) => {
    if (state?.editingWorkflow === undefined) return;

    state.editingWorkflow = payload;
  }),

  /**
   * Action: Set workflow by project id
   */
  setWorkflowByWorkItem: action((state, payload) => {
    if (state?.workflowByWorkItem === undefined) return;

    if (!(payload !== null && typeof payload === 'object' && Object.keys(payload).length) || !payload?.workTicketId) {
      state.workflowByWorkItem = {};
      return;
    }

    state.workflowByWorkItem[payload.workTicketId] = payload.data;
  }),

  /**
   * Action: Set is editing mode
   */
  setIsEditingMode: action((state, payload) => {
    if (state?.isEditingMode === undefined) return;

    state.isEditingMode = payload;
  }),

  /**
   * Action: Set editing status
   */
  setEditingStatus: action((state, payload) => {
    if (state?.editingStatus === undefined) return;

    state.editingStatus = payload;
  }),

  /**
   * Action: Set editing step
   */
  setEditingStep: action((state, payload) => {
    if (state?.editingStep === undefined) return;

    state.editingStep = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) return;

    state.loadingItem = payload;
  }),

  /**
   * Action: Set full screen
   */
  setFullScreen: action((state, payload) => {
    if (state?.fullScreen === undefined) return;

    state.fullScreen = payload;
  }),

  /**
   * Action: Call api to create workflow
   */
  createWorkflow: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const body = {
        listStates: payload.listStates,
        listSteps: payload.listSteps
      };

      let url = '';

      if (globalProject?.projectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEMPLATE}/${payload.workticketId}${ENDPOINTS.WORK_FLOW}`;
      } else {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenant?.tenantKey}${ENDPOINTS.TEMPLATE}/${payload.workticketId}${ENDPOINTS.WORK_FLOW}`;
      }

      const res = await Http.post(url, body).then(res => res.data);

      helpers.getStoreActions().process.getTemplate({ id: payload.workticketId });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api revert to defaul workflow
   */
  revertToDefaultWorkflow: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!payload?.workticketId) {
        throw new Error('There are no ticketTypeId');
      }

      action.setLoadingItem(true);

      const { workticketId } = payload;

      let url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant?.tenantKey}${ENDPOINTS.TEMPLATE}/${workticketId}`;

      if (project?.projectKey) {
        url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant.tenantKey}/${project.projectKey}${ENDPOINTS.TEMPLATE}/${workticketId}`;
      }

      await Http.put(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:process.messageRevertWorkflow'),
        description: i18next.t('akaat:message.revertedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
