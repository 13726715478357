import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Button, Dropdown, Empty, Tooltip, Tree } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined, FolderOutlined } from '@ant-design/icons';

import { debounce, findItemAndParentsOnTree, getValueNestedObject } from '../../common';

let typingTimerOfSearch = 0;

export const FieldFolder = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  const repository = useStoreState(state => state.repositoryTree.repository);
  const loadingTreeData = useStoreState(state => state.repositoryTree.loadingRepository);
  const getTestPlanTreeData = useStoreActions(action => action.repositoryTree.getRepositoryList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [tree, setTree] = useState([]);

  /**
   * Get test plan data
   */
  useEffect(() => {
    if (!(Array.isArray(repository) && repository.length) || loadingTreeData) {
      getTestPlanTreeData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!field) return;

    getSuggestion({
      referenceField: field.refName,
      url: field.data.url,
      page: null,
      limit: null,
      order: null,
      group: field.data.fieldValue
    });
  }, []);

  /**
   * Convert treeData to tree
   */
  useEffect(() => {
    if (!(Array.isArray(repository) && repository.length)) return;

    // Function: Convert to tree
    const convertToTree = (list, treeLevel, treeType, parentKey, parentKeys = []) => {
      treeLevel++;
      const newParentKeys = parentKey ? [...parentKeys, parentKey] : [...parentKeys];

      return Array.isArray(list)
        ? list.map(item => {
            const hasChildren = Array.isArray(item.subs) && item.subs.length ? true : false;
            let newChildren = hasChildren
              ? item.subs.sort((a, b) => {
                  if (moment(a.createdAt) > moment(b.createdAt)) return 1;
                  if (moment(a.createdAt) < moment(b.createdAt)) return -1;
                  return 0;
                })
              : []; // Sort by id: DESC
            const key = `${parentKey ? parentKey + '_' : ''}id-${item._id}`; // Example: id-21_id-3

            return {
              ...item,
              key,
              id: item._id,
              parentKey,
              parentKeys: newParentKeys,
              treeLevel,
              treeType,
              title: (
                <span title={item.name}>
                  <FolderOutlined /> {item.name}
                </span>
              ),
              isLeaf: !hasChildren,
              children: hasChildren ? convertToTree(newChildren, treeLevel, treeType, key, newParentKeys) : []
            };
          })
        : [];
    };

    const data = convertToTree(repository, 0, null, []);

    setTree(data); // Set tree to store
  }, [repository]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (
      !field?.refName ||
      !field?.data?.url ||
      !(Array.isArray(suggestion?.[field?.refName]?.data) && suggestion?.[field?.refName]?.data.length)
    ) {
      setOptions([]);
      return;
    }

    const newOptionList = suggestion[field.refName].data.map(item => {
      return {
        label: getValueNestedObject(item, field.data.fieldLabel),
        value: getValueNestedObject(item, field.data.fieldValue),
        path: item.path
      };
    });

    setOptions(newOptionList);
  }, [field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (
      !field?.refName ||
      !(Array.isArray(currentValues) && currentValues.length) ||
      !(Array.isArray(options) && options.length)
    ) {
      setCheckedKeys([]);
      setSelectedKeys([]);

      return [];
    }

    let newCurrentValues = [];
    currentValues.forEach(currentValue => {
      if (currentValue.refName === field.refName) {
        const newValue = currentValue.value?.[field.refName]?.$regex?.split('|') || [];
        newCurrentValues = newCurrentValues.concat(newValue);
      }
    });

    const newSelectedOptions = [];

    const checkedKeys = [];

    newCurrentValues.forEach(value => {
      const node = findItemAndParentsOnTree(tree, value, 'path');

      newSelectedOptions.push(node?.item);
      checkedKeys.push(node?.item?.key);
    });

    setCheckedKeys(checkedKeys);
    setSelectedKeys(checkedKeys);

    return newSelectedOptions;
  }, [field, options, currentValues]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) setSelectedOptions(currentSelectedOptions);

    if (visible && field?.refName && field?.data?.url && !suggestion?.[field.refName]?.loadedCount) {
      getSuggestion({
        referenceField: field.refName,
        url: field.data.url,
        page: null,
        limit: null,
        order: null,
        group: field.data.fieldValue
      });
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (selectedKeysValue, info) => {
    if (loadingSuggestion || !info?.node) return;

    setSelectedKeys(selectedKeysValue);
    setCheckedKeys(selectedKeysValue);

    let newSelectedValues = selectedKeysValue.map(selectedKey => {
      const node = findItemAndParentsOnTree(tree, selectedKey, 'key');
      return node.item;
    });

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) return;

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const queryObj = { $or: [] };

        if (field.data?.suggestionBy && Array.isArray(field.data.suggestionBy) && field.data.suggestionBy.length) {
          field.data.suggestionBy.forEach(sub => {
            queryObj.$or.push({
              [sub]: { $regex: val, $options: 'i' }
            });
          });
        }

        getSuggestion({
          referenceField: field.refName,
          url: field.data?.url,
          page: null,
          limit: null,
          order: null,
          filter: val === '' ? {} : queryObj,
          group: field.data.fieldValue
        });
      }),
      300
    );
  };

  const handleApply = selectedOptions => {
    const paths = {
      [field.refName]: { $regex: selectedOptions.map(selected => selected.path).join('|'), $options: 'si' }
    };
    onApply(paths);
  };

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const items = [];

    if (!(Array.isArray(options) && options.length)) {
      items.push({
        key: 'empty',
        label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />,
        className: 'bg-transparent'
      });
    } else {
      items.push({
        key: 'tree',
        label: (
          <div className="c-directory-tree" style={{ maxHeight: '280px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Tree
              className="mt-2"
              checkable
              onCheck={handleChangeSelected}
              checkedKeys={checkedKeys}
              onSelect={handleChangeSelected}
              selectedKeys={selectedKeys}
              treeData={tree}
            />
          </div>
        ),
        className: 'bg-transparent'
      });
    }

    items.push(
      { type: 'divider' },
      {
        key: 'box-footer',
        label: (
          <>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
                handleSearch('');
                setSelectedKeys([]);
                setCheckedKeys([]);
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                !selectedOptions.length && handleSearch('');
                handleApply(selectedOptions);
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </>
        ),
        className: 'bg-transparent text-right cursor-default'
      }
    );

    return items;
  }, [options, checkedKeys, selectedKeys, tree, selectedOptions]);

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          Array.isArray(currentSelectedOptions) && currentSelectedOptions.length
            ? `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${currentSelectedOptions.map(item => item?.name).join(', ')}`
            : `${
                i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name
              }: ${t('common.all')}`
        }
        className={`btn-field field-folder btn-toggle-dropdown-with-checkbox-list ${className}`}
        {...rest}
      >
        {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {currentSelectedOptions.map(item => item?.name).join(', ')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="txt-label text-truncate">
              {i18n.exists(`akaat:workItem.${field.originRefName}`) ? t(`workItem.${field.originRefName}`) : field.name}
              : {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{ items: menus, selectable: false }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style"
        overlayStyle={{ width: 250 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
