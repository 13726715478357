import { useEffect } from 'react';

import { ALLOWED_CHARACTER_CODES, handleKeyboardEvents } from '../../editable-cell-utils';

const LatestResultStatusKeyboardEvents = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  setEditingCell,
  handleOpen,
  handleSave,
  closeAndSetLastValue
}) => {
  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const hasDisabledOutsideClick = document.querySelector('.disabled-outside-click');
    if (hasDisabledOutsideClick) return;

    const code = e.code;
    const ctrl = e.ctrlKey ? e.ctrlKey : code === 'Control' ? true : false;
    const alt = e.altKey ? e.altKey : code === 'Alt' ? true : false;
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');
    const boxVisualInput = document.querySelector('.c-editable-cell .box-visual-input');
    const hasOpenedSelectDropdown = document.querySelector('.ant-select-open');

    // Option dropdown is opening => Stop propagation
    if (
      hasOpenedSelectDropdown &&
      (code === 'Enter' || code === 'NumpadEnter' || code === 'ArrowUp' || code === 'ArrowDown' || code === 'Tab')
    ) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Enter => No action
    else if (!boxVisualInput && (code === 'Enter' || code === 'NumpadEnter')) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Delete & has "focused" class => Don't open, keep "focused" class, save
    if (!field?.mandatory && hasFocused && (code === 'Delete' || code === 'NumpadDecimal')) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.resetFields([formItemName]);
      handleSave(null);
      return;
    }

    // When show "box-visual-input"
    else if (hasFocused && boxVisualInput) {
      // Open
      if (code === 'Enter' || code === 'NumpadEnter' || ctrl || alt || ALLOWED_CHARACTER_CODES.includes(code)) {
        handleOpen();
        return;
      }
    }

    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return null;
};

export default LatestResultStatusKeyboardEvents;
