import React from 'react';
import { useTranslation } from 'react-i18next';
import mime from 'mime-types';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { notification } from 'antd';

import { ENDPOINTS, MAX_FILE_SIZE, FIELD_ATTACHMENTS, EXCEPT_FILE_TYPES_UPLOAD } from '../../constants';
import { Http } from '../../core/http';
import { handleError } from '../../core/handle-error';
import { SafeInnerHtml } from '../../components';

export const useFile = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For file store
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);

  /**
   * Get upload path
   */
  const getUploadPath = workItemId => {
    if (!globalTenant?.tenantKey || !workItemId) return;

    const path = `${ENDPOINTS.FIRST_UPLOAD_PATH}/${globalTenant?.tenantKey}/${workItemId}`;

    return path;
  };

  /**
   * Check valid type
   */
  const checkValidType = ({
    file,
    acceptFileTypes,
    exceptFileTypes = EXCEPT_FILE_TYPES_UPLOAD,
    showNotificationError = true
  }) => {
    if (!file) return true;

    const hasExceptContentTypes = Array.isArray(exceptFileTypes) && exceptFileTypes.length > 0;
    const exceptContentTypes = hasExceptContentTypes
      ? [...exceptFileTypes].map(extension => mime.lookup(extension))
      : [];

    const hasAcceptFileTypes = Array.isArray(acceptFileTypes) && acceptFileTypes.length > 0;
    const acceptContentTypes = hasAcceptFileTypes ? [...acceptFileTypes].map(extension => mime.lookup(extension)) : [];

    const isValidExceptType = exceptContentTypes.length ? !exceptContentTypes.includes(file.type) : true;
    const isValidAcceptType = acceptContentTypes.length ? acceptContentTypes.includes(file.type) : true;

    if (!isValidExceptType && hasExceptContentTypes && showNotificationError) {
      notification.error({
        message: (
          <div className="font-weight-medium font-size-14" style={{ lineHeight: 1.5 }}>
            {t('message.fileHasFailedToUpload', { name: file?.name })}
          </div>
        ),
        description: <SafeInnerHtml html={t('message.uploadExceptTypes', { types: exceptFileTypes.join(', ') })} />
      });
    } else if (!isValidAcceptType && hasAcceptFileTypes && showNotificationError) {
      notification.error({
        message: (
          <div className="font-weight-medium font-size-14" style={{ lineHeight: 1.5 }}>
            {t('message.fileHasFailedToUpload', { name: file?.name })}
          </div>
        ),
        description: <SafeInnerHtml html={t('message.uploadAcceptTypes', { types: acceptFileTypes.join(', ') })} />
      });
    } else {
    }

    return isValidExceptType && isValidAcceptType;
  };

  /**
   * Check valid size
   */
  const checkValidSize = ({ file, maxFileSize = MAX_FILE_SIZE, showNotificationError = true }) => {
    if (!file || !maxFileSize) return true;

    const isValidSize = maxFileSize > 0 ? file.size / 1024 / 1024 < maxFileSize : true;

    if (!isValidSize && showNotificationError) {
      notification.error({
        message: (
          <div className="font-weight-medium font-size-14" style={{ lineHeight: 1.5 }}>
            {t('message.fileHasFailedToUpload', { name: file.name })}
          </div>
        ),
        description: t('message.uploadMaxSize', { max: `${maxFileSize}MB` })
      });
    }

    return isValidSize;
  };

  /**
   * Check valid max count
   */
  const checkValidMaxCount = ({ file, index, maxCount, currentAttachments, showNotificationError = true }) => {
    if (!file || !/^[0-9]*$/.test(maxCount) || maxCount <= 0) return true;

    const currentCount = Array.isArray(currentAttachments) ? currentAttachments.length : 0;
    const isValidMaxCount = index + 1 <= maxCount - currentCount;

    if (!isValidMaxCount && showNotificationError) {
      notification.error({
        message: (
          <div className="font-weight-medium font-size-14" style={{ lineHeight: 1.5 }}>
            {t('message.fileHasFailedToUpload', { name: file.name })}
          </div>
        ),
        description: t('message.maximumCountAttachments', { max: maxCount })
      });
    }

    return isValidMaxCount;
  };

  /**
   * Upload file
   */
  const uploadFile = async ({
    file,
    fileName,
    uploadPath,
    isProtectedFile = true,
    noShowSuccessMessage,
    editingItem
  }) => {
    if (!file || !uploadPath) return;

    try {
      const formData = new FormData();

      if (fileName) {
        formData.append('file', file, fileName);
      } else {
        formData.append('file', file);
      }

      formData.append('path', `${uploadPath}/${moment().format('YYYYMMDDHHmmssSSS')}`);

      const url = isProtectedFile
        ? `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.FILE}`
        : `${ENDPOINTS._HOST}${ENDPOINTS.FILE_UPLOAD}`;

      const res = await Http.post(url, formData);
      let newData = null;

      // if (editingItem?.externalSystem == JIRA_PLATFORM_ID) {
      //   await Http.post(
      //     `${ENDPOINTS._HOST}${ENDPOINTS.INTEGRATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}/jira/${editingItem?.source?.jira?.id}/attachments`,
      //     formData
      //   );
      // }
      if (isProtectedFile) {
        newData = {
          id: res?.data?.data?.id,
          previewUrl: res?.data?.data?.previewUrl,
          downloadUrl: res?.data?.data?.downloadUrl,
          size: res?.data?.data?.size,
          createdAt: res?.data?.data?.createdAt
        };
      } else {
        newData = res?.data?.url;
      }

      if (!noShowSuccessMessage) {
        notification.success({
          description: t('message.fileHasBeenUploadedSuccessfully', { name: fileName || file?.name || 'File' })
        });
      }

      return newData;
    } catch (err) {
      handleError(err);
    }
  };

  /**
   * Deteled attachments after delete
   */
  const deleteAttachments = attachments => {
    if (!(Array.isArray(attachments) && attachments.length)) return;

    const ids = attachments.filter(item => item?.id).map(item => item?.id);

    if (!(Array.isArray(ids) && ids.length)) return;

    deleteFiles({ globalTenant, globalProject, ids });
  };

  /**
   * Delete test step attachments
   */
  const deleteTestStepAttachments = ({ testSteps, directlyDeleteAttachment, onChangeDeteledAttachmentIds }) => {
    if (
      !(
        Array.isArray(testSteps) &&
        testSteps.length &&
        testSteps.some(s => Array.isArray(s?.[FIELD_ATTACHMENTS]) && s?.[FIELD_ATTACHMENTS].length)
      )
    ) {
      return;
    }

    const ids = [...testSteps].reduce((accumulator, current) => {
      const currentAttachments = current?.[FIELD_ATTACHMENTS];

      if (Array.isArray(currentAttachments) && currentAttachments.length) {
        const attachments = currentAttachments.filter(item => item?.id);
        attachments.forEach(item => accumulator.push(item?.id));
      }

      return accumulator;
    }, []);

    if (ids.length) {
      if (directlyDeleteAttachment) deleteFiles({ globalTenant, globalProject, ids });
      if (typeof onChangeDeteledAttachmentIds === 'function') onChangeDeteledAttachmentIds(ids);
    }
  };

  return {
    getUploadPath,
    checkValidType,
    checkValidSize,
    checkValidMaxCount,
    uploadFile,
    deleteAttachments,
    deleteTestStepAttachments
  };
};
