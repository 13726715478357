import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';

import { SYSTEM_FIELD_KEY } from '../../constants';

const RelationLink = ({ row, field, children, className = '', ...rest }) => {
  /**
   * Key
   */
  const key = useMemo(() => objectPath.get(row, `${field?.refNameParent}.${SYSTEM_FIELD_KEY}`), [row, field]);

  return (
    <div className={`relation-link allow-click text-truncate ${className}`} {...rest}>
      {key === undefined ? (
        children
      ) : (
        <Link to={{ pathname: `${field?.prePath}${key}` || '/' }} target="_blank" onClick={e => e.stopPropagation()}>
          {children}
        </Link>
      )}
    </div>
  );
};

export default RelationLink;
