import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { debounce, getValueNestedObject } from '../../common';
import { WORK_ITEM_SEARCH_FIELD_LABEl_RENDER } from '../../constants/fields/engine-fields';

let typingTimerOfSearch = 0;

export const FieldSelect = ({
  field,
  currentValues,
  allowSearch = true,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (field?.data?.url || !(Array.isArray(field?.data) && field?.data.length)) {
      setOptions([]);
      return;
    }

    const newOptionList = field.data.map(item => {
      return {
        label: t(item.label),
        value: item.value
      };
    });

    setOptions(newOptionList);
  }, [t, field]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (
      !field?.referenceField ||
      !field?.data?.url ||
      !(Array.isArray(suggestion?.[field?.referenceField]?.data) && suggestion?.[field?.referenceField]?.data.length)
    ) {
      return;
    }

    const newOptionList = suggestion[field.referenceField].data.map(item => {
      return {
        label: getValueNestedObject(item, field.data.fieldLabel),
        value: getValueNestedObject(item, field.data.fieldValue)
      };
    });

    setOptions(newOptionList);
  }, [field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (
      !field?.referenceField ||
      !(Array.isArray(currentValues) && currentValues.length) ||
      !(Array.isArray(options) && options.length)
    ) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.referenceField === field.referenceField);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(
      item => currentValue.includes(item.value) || currentValue.includes(Number(item.value))
    );

    return newSelectedOptions;
  }, [field, options, currentValues]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) setSelectedOptions(currentSelectedOptions);

    if (visible && field?.referenceField && field?.data?.url && !suggestion?.[field.referenceField]?.loadedCount) {
      getSuggestion({
        referenceField: field.referenceField,
        url: field.data.url,
        page: null,
        limit: null,
        order: null,
        group: field.data.fieldValue
      });
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (loadingSuggestion || !currentOption?.value) return;

    let newSelectedValues =
      Array.isArray(selectedOptions) && selectedOptions.length
        ? [...selectedOptions].filter(item => item.value !== currentOption.value)
        : [];

    if (checked) newSelectedValues = [...newSelectedValues, currentOption];

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.referenceField) return;

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const suggestionList =
          Array.isArray(suggestion?.[field.referenceField]?.data) && suggestion?.[field.referenceField]?.data.length
            ? suggestion[field.referenceField].data.map(item => {
                return {
                  label: getValueNestedObject(item, field.data.fieldLabel),
                  value: getValueNestedObject(item, field.data.fieldValue)
                };
              })
            : [];
        const selectionList = Array.isArray(field.data) && field.data.length ? field.data : [];
        const originList = field.data?.url ? suggestionList : selectionList;

        const newOptionList = originList
          .filter(item => typeof item.label === 'string' && item.label.toLowerCase().indexOf(val.toLowerCase()) !== -1)
          .map(item => {
            return {
              label: t(item.label),
              value: `${item.value}`
            };
          });

        setOptions(newOptionList);
      }),
      300
    );
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    const selectedValue =
      Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.map(item => item.value) : [];

    return (
      <div className="ant-dropdown-menu c-field-select-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingSuggestion}>
          {allowSearch && (
            <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
              <Input
                placeholder={t('common.search')}
                suffix={<SearchOutlined />}
                autoFocus
                autoComplete="off"
                allowClear
                size="small"
                onChange={e => handleSearch(e?.target?.value)}
              />
            </div>
          )}

          {!(Array.isArray(options) && options.length > 0) && (
            <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                onClick={e => e.stopPropagation()}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
              </li>
            </ul>
          )}

          {Array.isArray(options) && options.length > 0 && (
            <ul
              className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
              onClick={e => e.stopPropagation()}
            >
              {/* Selected Item */}
              {options
                .filter(item => selectedValue.includes(item.value))
                .map(item => (
                  <li
                    key={item.value}
                    title={'item.label'}
                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                    onClick={e => e.stopPropagation()}
                  >
                    <Checkbox
                      checked={selectedValue.includes(item.value)}
                      className="w-100"
                      onChange={e => handleChangeSelected(e?.target?.checked, item)}
                    >
                      {WORK_ITEM_SEARCH_FIELD_LABEl_RENDER(field?.referenceField, item) || item.label}
                    </Checkbox>
                  </li>
                ))}

              {/* None Selected Item */}
              {options
                .filter(item => !selectedValue.includes(item.value))
                .map(item => (
                  <li
                    key={item.value}
                    title={item.label}
                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                    onClick={e => e.stopPropagation()}
                  >
                    <Checkbox
                      checked={selectedValue.includes(item.value)}
                      className="w-100"
                      onChange={e => handleChangeSelected(e?.target?.checked, item)}
                    >
                      {WORK_ITEM_SEARCH_FIELD_LABEl_RENDER(field?.referenceField, item) || item.label}
                    </Checkbox>
                  </li>
                ))}
            </ul>
          )}

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setVisibleDropdown(false);
                onApply(selectedOptions.map(item => item.value));
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Render toggle button dropdown
   */
  const renderToggleButtonDropdown = () => {
    if (!field) return;

    return (
      <Button
        title={
          Array.isArray(currentSelectedOptions) && currentSelectedOptions.length
            ? `${t(`workItem.${field.refName}`)}: ${currentSelectedOptions.map(item => item.label).join(', ')}`
            : `${t(`workItem.${field.refName}`)}: ${t('common.all')}`
        }
        className={`btn-toggle-dropdown-with-checkbox-list btn-field field-select ${className}`}
        {...rest}
      >
        {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
          <>
            <span className="text-truncate">
              {t(`workItem.${field.refName}`)}: {currentSelectedOptions.map(item => item.label).join(', ')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        ) : (
          <>
            <span className="text-truncate">
              {t(`workItem.${field.name}`)}: {t('common.all')}
            </span>{' '}
            <CaretDownOutlined className="ic-caret-down" />
          </>
        )}

        {!field.isDefaultSearch && (
          <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
            <CloseCircleOutlined
              className="ic-close"
              onClick={e => {
                e.stopPropagation();
                if (typeof onRemove === 'function') onRemove();
              }}
            />
          </Tooltip>
        )}
      </Button>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        {renderToggleButtonDropdown()}
      </Dropdown>
    </>
  );
};
