import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button, Layout, Menu, Spin, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined, SettingOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import env from '../../env';
import {
  LS_PRODUCTION_KEY,
  ROLE_APP_ACCESS_TESTENGINE,
  ROLE_APP_ACCESS_MANAGER,
  PROJECT_MANAGER_MENU,
  PROJECT_MANAGER_MENU_GROUP,
  PROJECT_ENGINE_MENU,
  PRODUCT_KEY_ON_URL_MANAGER,
  PRODUCT_KEY_ON_URL_ENGINE,
  DEFAULT_WORK_ITEM_ICONS,
  WORK_TYPE_TYPE_WORK_TICKET
} from '../../constants';
import {
  useMenu,
  useKeycloak,
  getObjectByValue,
  reactLocalStorage,
  convertToNestedMenu,
  buildSubPathNameList,
  convertNestedMenuToGroupMenu
} from '../../common';
import { BugSearching, PlusCircle } from '../../assets/svg-icons';

import './style.scss';

export const ProjectManagerSidebar = () => {
  const location = useLocation();

  // For keycloak
  const { keycloak } = useKeycloak();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For language
  const [t] = useTranslation('akaat');

  // For menu
  const { convertGroupMenuToAntMenuItems } = useMenu();

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const loadingGlobalUserInfo = useStoreState(state => state.global.loadingGlobalUserInfo);
  const globalProductionKey = useStoreState(state => state.global.globalProductionKey);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);

  // For extensions store
  const getExtensionsList = useStoreActions(action => action.extensions.getExtensionsList);
  const extensionData = useStoreState(state => state.extensions.data);

  // Component state
  const [collapsed, setCollapsed] = useState(true);
  const [openKeys, setOpenKeys] = useState([]);
  const [workItemList, setWorkItemList] = useState([]);

  /**
   * Get Extensions list
   */
  useEffect(() => {
    getExtensionsList();
  }, [getExtensionsList]);

  /**
   * Set work item list
   */
  useEffect(() => {
    if (!(Array.isArray(globalProcessList) && globalProcessList.length)) return;

    const processListWorkTicket = globalProcessList?.filter(process => process?.isType === WORK_TYPE_TYPE_WORK_TICKET);
    const list = processListWorkTicket.map(item => {
      const iconWorkTicket = getObjectByValue(item?.icon, DEFAULT_WORK_ITEM_ICONS);

      return {
        id: `${item?.workTicketId}`,
        workTicketId: `${item?.workTicketId}`,
        parentId: null,
        groupId: 'workItems',
        path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${item?.workTicketId}`,
        name: `${item?.name || ''}`,
        icon: iconWorkTicket ? iconWorkTicket.icon : <BugSearching />,
        isWorkTicket: true
      };
    });

    setWorkItemList(list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalProcessList]);

  /**
   * Project manager menus
   */
  const projectManagerMenus = useMemo(() => {
    if (
      globalProductionKey !== PRODUCT_KEY_ON_URL_MANAGER ||
      !(Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER))
    ) {
      return [];
    }

    let allMenus = [...PROJECT_MANAGER_MENU];

    if (Array.isArray(workItemList) && workItemList.length) {
      allMenus = [...allMenus, ...workItemList].filter(item => item?.id !== 'workItem');
    }

    // Add extension to menu
    if (Array.isArray(extensionData) && extensionData.length) {
      extensionData.forEach(item => {
        const project = Array.isArray(item?.project) && item?.project.length ? [...item?.project] : [];
        const filteredList = project.filter(p => p?.projectKey === projectPath && p?.tenantKey === tenantPath);

        if (filteredList.length) {
          allMenus.push({
            id: item?.id,
            parentId: null,
            groupId: 'extensions',
            name: item?.name,
            path: `${item?.path}?x-akaat-token=${keycloak?.token}`,
            target: '_blank',
            isAbsolutePath: true,
            icon: <PlusCircle />,
            isHideOnSidebar: item?.isHideOnSidebar
          });
        }
      });
    }

    const userPermissions =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.length
        ? [...globalUserInfo?.permissions]
        : [];

    allMenus = allMenus.filter(item => {
      if (item?.isHideOnSidebar) return false;
      if (item?.permission && !globalUserInfo.permissions.includes(item?.permission)) return false;
      if (Array.isArray(item?.permissions) && !userPermissions.some(p => item?.permissions.includes(p))) return false;
      return true;
    });
    const nestedMenu = convertToNestedMenu(allMenus, null);
    const groupMenu = convertNestedMenuToGroupMenu(nestedMenu, PROJECT_MANAGER_MENU_GROUP);

    const newMenus = convertGroupMenuToAntMenuItems({
      groupMenu,
      restSubMenu: { popupClassName: 'c-left-sidebar-sub-menu-popup' }
    });

    return newMenus;
  }, [
    keycloak?.token,
    location,
    projectPath,
    tenantPath,
    globalUserInfo,
    globalProductionKey,
    workItemList,
    extensionData
  ]);

  /**
   * Project engine menus
   */
  const projectEngineMenus = useMemo(() => {
    if (
      globalProductionKey !== PRODUCT_KEY_ON_URL_ENGINE ||
      !(Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_TESTENGINE))
    ) {
      return [];
    }

    const userPermissions =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.length
        ? [...globalUserInfo?.permissions]
        : [];

    const allMenus = PROJECT_ENGINE_MENU.filter(item => {
      if (item?.isHideOnSidebar) return false;
      if (item?.permission && !userPermissions.includes(item?.permission)) return false;
      if (Array.isArray(item?.permissions) && !userPermissions.some(p => item?.permissions.includes(p))) return false;
      return true;
    });
    const nestedMenu = convertToNestedMenu(allMenus, null);
    const groupMenu = convertNestedMenuToGroupMenu(nestedMenu, []);

    const newMenus = convertGroupMenuToAntMenuItems({
      groupMenu,
      restSubMenu: { popupClassName: 'c-left-sidebar-sub-menu-popup' }
    });

    return newMenus;
  }, [location, globalUserInfo, globalProductionKey]);

  /**
   * Set open keys when expand sidebar menus
   */
  useEffect(() => {
    if (
      !(
        (Array.isArray(projectManagerMenus) && projectManagerMenus.length) ||
        (Array.isArray(projectEngineMenus) && projectEngineMenus.length)
      )
    ) {
      return;
    }

    if (collapsed) {
      setOpenKeys([]);
      return;
    }

    const allOpenKeys = [];

    [...projectManagerMenus, ...projectEngineMenus].forEach(group => {
      if (Array.isArray(group.children) && group.children.length) {
        group.children.forEach(item => {
          allOpenKeys.push(item?.key);
        });
      }
    });

    setTimeout(() => {
      setOpenKeys(allOpenKeys);
    }, 300);
  }, [collapsed, location.pathname, projectManagerMenus, projectEngineMenus]);

  /**
   * Render aciton bottom
   */
  const renderActionBottom = () => {
    return (
      <div
        className="box-toggle-collapse"
        style={{ width: collapsed ? 80 : 220 }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Tooltip title={collapsed ? t('menu.projectSettings') : null} placement="right" destroyTooltipOnHide={true}>
          <a
            href={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/general`}
            className="btn-link text-hover-primary min-h-22"
            onClick={e => {
              e.stopPropagation();
              reactLocalStorage.set(LS_PRODUCTION_KEY, globalProductionKey);
            }}
          >
            <SettingOutlined />
            <span className="text-truncate">{collapsed ? null : t('menu.projectSettings')}</span>
          </a>
        </Tooltip>

        <Tooltip
          title={collapsed ? t('common.expand') : t('common.collapse')}
          placement="right"
          destroyTooltipOnHide={true}
        >
          <Button
            type="link"
            icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
            className="btn-toggle-collapse text-hover-primary"
          ></Button>
        </Tooltip>
      </div>
    );
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      theme="light"
      width={220}
      className="c-left-sidebar"
      onCollapse={() => setCollapsed(!collapsed)}
    >
      {!globalUserInfo?.id && <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingGlobalUserInfo} />}

      <Menu
        items={[...projectManagerMenus, ...projectEngineMenus]}
        openKeys={openKeys}
        selectedKeys={buildSubPathNameList(location.pathname)}
        mode="inline"
        theme="light"
        onOpenChange={setOpenKeys}
      />

      {renderActionBottom()}
    </Layout.Sider>
  );
};
