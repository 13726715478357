import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Collapse, Popconfirm, Spin, Tooltip, Col, Row } from 'antd';
import { LinkOutlined, Loading3QuartersOutlined, DeleteOutlined } from '@ant-design/icons';

import { JIRA_PLATFORM_ID, DEFAULT_WORK_ITEM_ICONS, INTEGRATION_DEFECT_SYSTEM } from '../../constants';
import { jsonParse, useStatus, getObjectByValue, getPrePathLinkToTicket } from '../../common';
import { BugSearching } from '../../assets/svg-icons';
import { StatusLabel } from '../status-label';

import './style.scss';

const { Panel } = Collapse;

export const BasicRelationItem = ({ data, loadingList, isReadOnly, className = '', onReload, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For status
  const { getStatusFromWorkflowById } = useStatus();

  // For ticket type store
  const getTicketTypeById = useStoreActions(action => action.global.getTicketTypeById);
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For global store
  const deleteRelation = useStoreActions(action => action.global.deleteRelation);

  // For engine test result store
  const getTestResultById = useStoreActions(action => action.engineTestResult.getTestResultById);
  const updateTestResult = useStoreActions(action => action.engineTestResult.updateTestResult);

  // Component state
  const [relationList, setRelationList] = useState({});
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([]);

  /**
   * Get ticket type by id
   */
  useEffect(() => {
    if (!(Array.isArray(data) && data.length)) return;

    (async () => {
      const workTicketIds = [...data].reduce((accumulator, current) => {
        const newIds = [current?.source?.workTicketType?.id, current?.target?.workTicketType?.id];

        newIds.forEach(id => {
          if (id && !accumulator.includes(id)) accumulator.push(id);
        });

        return accumulator;
      }, []);

      for (let i = 0; i < workTicketIds.length; i++) {
        const id = workTicketIds[i];

        if (!Object.keys(ticketListData || {}).includes(id)) {
          await getTicketTypeById(id);
        }
      }
    })();
  }, [data, ticketListData]);

  /**
   * Set active collapse keys
   */
  useEffect(() => {
    if (!(Array.isArray(data) && data.length)) {
      setRelationList({});
      setActiveCollapseKeys([]);
      return;
    }

    const group =
      data
        .filter(item => item?.source?.key && item?.target?.key)
        .reduce((hash, obj) => {
          if (obj?.relType?.name === undefined) return hash;
          return Object.assign(hash, { [obj.relType.name]: (hash[obj.relType.name] || []).concat(obj) });
        }, {}) || [];

    setRelationList(group);
  }, [data]);

  /**
   * Render panel header
   */
  const renderPanelHeader = (key, relationList) => {
    if (!key) return;

    return (
      <div className="display-inline-flex align-items-center user-select-none">
        {key} {Array.isArray(relationList) && relationList.length ? `(${relationList.length})` : '(0)'}
      </div>
    );
  };

  /**
   * Handle delete defect for test engine
   */
  const handleDeleteDefectForTestEngine = async item => {
    const testResultId = item?.target?.externalData?.id;
    const res = await getTestResultById({
      id: testResultId,
      noSetEditingTestResult: true
    });

    if (!(Array.isArray(res?.defectLinks) && res?.defectLinks.length)) {
      return;
    }

    const defectLinks = res?.defectLinks.filter(link => link?.id !== item?.source?._id);

    updateTestResult({
      id: res?.id,
      defectLinks,
      noShowSuccessMessage: true
    });
  };

  /**
   * Handle delete relation
   */
  const handleDeleteRelation = async item => {
    if (!item?._id) return;

    const res = await deleteRelation(item?._id);

    if (item?.target?.externalData?.type === 'engine') {
      handleDeleteDefectForTestEngine(item);
    }

    if (res) onReload();
  };

  /**
   * Render panel content
   */
  const renderPanelContent = data => {
    if (!data) return;

    let newItem = jsonParse(JSON.stringify(data));
    let iconWorkTicket;
    const targetStatus = getStatusFromWorkflowById(
      newItem?.target?.status,
      ticketListData?.[newItem?.target?.workTicketType?.id]?.workFlow
    );

    if (data?.target?.external && newItem) {
      switch (data?.target?.externalData?.type) {
        case JIRA_PLATFORM_ID: {
          iconWorkTicket = INTEGRATION_DEFECT_SYSTEM[JIRA_PLATFORM_ID];
          break;
        }

        default: {
          iconWorkTicket = {
            icon: <LinkOutlined className="text-primary text-hover-dark-primary font-size-14" />
          };

          break;
        }
      }

      newItem.link = data?.target?.externalData?.link;
    } else if (newItem) {
      iconWorkTicket = getObjectByValue(data?.target?.workTicketType?.icon, DEFAULT_WORK_ITEM_ICONS);

      newItem.link = `${getPrePathLinkToTicket({ workTicketId: data?.target?.workTicketType?.id })}${
        data?.target?.key
      }`;
    } else {
    }

    return (
      <Row className="panel-content-item">
        <Col span={1}>
          <div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center' }}>
            {iconWorkTicket?.icon ? iconWorkTicket?.icon : <BugSearching />}
          </div>
        </Col>

        <Col span={18}>
          <div className="wrapper-content">
            <Tooltip title={t('common.viewDetail')} placement="top" destroyTooltipOnHide={true}>
              <a
                id={`go-to-related-ticket_${newItem?.target?.workTicketType?.id}_${newItem?.target?.key}`}
                href={newItem?.link}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-primary over"
              >
                {`${
                  !newItem?.target?.external
                    ? `${newItem?.target?.workTicketType?.name} - ${newItem?.target?.key} ${
                        newItem?.target?.name ? '- ' + newItem?.target?.name : ''
                      } `
                    : `${newItem?.target?.externalData?.name}`
                } `}
              </a>
            </Tooltip>
          </div>
        </Col>

        <Col span={4} className="status-style">
          <StatusLabel status={targetStatus} />
        </Col>

        <Col span={1}>
          {!isReadOnly && (
            <div className="btn-delete">
              <Popconfirm
                title={t('message.areYouSureDelete2')}
                okText={t('common.yes')}
                cancelText={t('common.no')}
                zIndex={1080}
                onConfirm={() => handleDeleteRelation(newItem)}
              >
                <Tooltip
                  title={t('common.delete')}
                  placement="top"
                  destroyTooltipOnHide={true}
                  overlayStyle={{ pointerEvents: 'none' }}
                >
                  <Button
                    id={`delete-related-ticket-button_${newItem?.target?.workTicketType?.id}_${newItem?.target?.key}`}
                    type="text"
                    icon={<DeleteOutlined className="font-size-14" />}
                    size="small"
                    className="text-gray-2 text-hover-danger border-0 bg-transparent w-auto h-auto p-0"
                  />
                </Tooltip>
              </Popconfirm>
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className={`c-basic-relation-item ${className}`} {...rest}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingList}>
          {Array.isArray(Object.keys(relationList)) && Object.keys(relationList).length > 0 ? (
            <Collapse
              activeKey={activeCollapseKeys}
              destroyInactivePanel={true}
              bordered={false}
              className="mt-2 mb-2"
              onChange={setActiveCollapseKeys}
            >
              {Object.keys(relationList).map(key => (
                <Panel key={key} id={`relation-${key}`} header={renderPanelHeader(key, relationList[key])}>
                  <>
                    {Array.isArray(relationList[key]) &&
                      relationList[key].length &&
                      relationList[key].map((relation, index) => {
                        return (
                          <div key={relation?._id} className={`${index > 0 ? 'mt-2 relation-item' : 'relation-item'}`}>
                            {renderPanelContent(relation)}
                          </div>
                        );
                      })}
                  </>
                </Panel>
              ))}
            </Collapse>
          ) : (
            <div className="text-gray-2 mb-2 mt-2">{t('relationTo.noRelation')}</div>
          )}
        </Spin>
      </div>
    </>
  );
};
