import React, { useEffect, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Tooltip, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { OPERATION_MAPPING, COMPONENT_TYPE } from '../../constants';
import { jsonParse, getKeyByValueInObject, parseCodeQSKeepType } from '../../common';
import { ClearFilter } from '../../assets/svg-icons';
import { DataSourceSelection } from './data-source-selection';
import { MoreDropdown } from './more-dropdown';
import { FieldStringNumber } from './field-string-number';
import { FieldDate } from './field-date';
import { FieldSelect } from './field-select';
import { FieldSuggestion } from './field-suggestion';
import { FieldUser } from './field-user';

import './style.scss';

export const BasicSearch = ({
  fieldList,
  allowChangeQueryParamsUrl = true,
  hideDataSourceSelection,
  noPageParam,
  loading,
  className = '',
  onChangeFilter,
  onSearch,
  currentDataSource,
  setCurrentDataSource,
  ...rest
}) => {
  const [searchForm] = Form.useForm();

  // For query params on url
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    filter: StringParam
  });

  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const setSuggestion = useStoreActions(action => action.global.setSuggestion);

  // For test data list
  const loadingList = useStoreState(state => state.engineTestData.loadingList);

  // Component state
  const [isSetCurrentValues, setIsSetCurrentValues] = useState(false);
  const [currentValues, setCurrentValues] = useState([]); // Example: [{ referenceField: 'status', value: ['PASS'] }]
  const [currentMoreFields, setCurrentMoreFields] = useState([]);
  const [filter, setFilter] = useState('');
  const [fieldData, setFieldData] = useState([]);

  /**
   * Set field list
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) return;

    setFieldData(fieldList);
  }, [fieldList]);
  /**
   * Handle set current values
   */
  const handleSetCurrentValues = params => {
    if (!params || !(Array.isArray(fieldData) && fieldData.length)) return;

    setFilter(params.filter);

    // For basic
    // Parse value filter keep type
    const filterObj = parseCodeQSKeepType(params.filter);
    const newCurrentValues = [];

    Object.keys(filterObj).forEach(async key => {
      const filterValue = filterObj[key];
      const found = fieldData.find(fi => fi.referenceField === key);

      if (!found) return;

      // For string, number
      if (
        (found.componentType === COMPONENT_TYPE.STRING || found.componentType === COMPONENT_TYPE.NUMBER) &&
        filterValue !== null &&
        typeof filterValue === 'object' &&
        Object.keys(filterValue).length
      ) {
        const newValue = Object.values(filterValue)[0];
        const operationValue = Object.keys(filterValue)[0];
        const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

        newCurrentValues.push({
          referenceField: key,
          value: newValue,
          componentType: found.componentType,
          operation: operationLabel
        });
      }

      // For date, datetime
      else if (found.componentType === COMPONENT_TYPE.DATE || found.componentType === COMPONENT_TYPE.DATE_TIME) {
        const lteValue = Object.keys(filterValue)?.includes('$lte') ? filterValue?.$lte : null;
        const gteValue = Object.keys(filterValue)?.includes('$gte') ? filterValue?.$gte : null;
        const ltValue = filterValue?.['$not']
          ? Object.keys(filterValue?.['$not'])?.includes('$lt')
            ? filterValue?.['$not']?.$lt
            : null
          : null;
        const gtValue = filterValue?.['$not']
          ? Object.keys(filterValue?.['$not'])?.includes('$gt')
            ? filterValue?.['$not']?.$gt
            : null
          : null;

        const newValue = Object.values(filterValue)[0];
        const newDate = newValue && moment(newValue).isValid() ? moment(newValue).local() : null;

        // For in range
        if (lteValue && moment(lteValue).isValid() && gteValue && moment(gteValue).isValid()) {
          newCurrentValues.push({
            referenceField: key,
            value: [moment(lteValue).local(), moment(gteValue).local()],
            componentType: found.componentType,
            operation: 'in range'
          });
        }

        // For not in range
        else if (ltValue && moment(ltValue).isValid() && gtValue && moment(gtValue).isValid()) {
          newCurrentValues.push({
            referenceField: key,
            value: [moment(ltValue).local(), moment(gtValue).local()],
            componentType: found.componentType,
            operation: 'not in range'
          });
        }

        // For other: Example: =, <, >, <=, >=
        else if (newDate) {
          const operationValue = Object.keys(filterValue)[0];
          const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

          newCurrentValues.push({
            referenceField: key,
            value: newDate,
            componentType: found.componentType,
            operation: operationLabel
          });
        } else {
        }
      }

      // For picklist
      else if (
        (found.componentType === COMPONENT_TYPE.PICKLIST ||
          found.componentType === COMPONENT_TYPE.SUGGESTION ||
          found.componentType === COMPONENT_TYPE.OPTION ||
          found.componentType === COMPONENT_TYPE.USER) &&
        Array.isArray(filterValue.$in) &&
        filterValue.$in.length
      ) {
        const operationLabel = getKeyByValueInObject('$in', OPERATION_MAPPING);

        newCurrentValues.push({
          referenceField: key,
          value: filterValue.$in,
          componentType: found.componentType,
          operation: operationLabel
        });

        if (found.data?.url) {
          await getSuggestion({
            referenceField: key,
            url: found.data.url,
            page: null,
            limit: null,
            order: null,
            // filter: {
            //   [found.data.fieldValue]: filterValue
            // },
            group: found.data.fieldValue
          });
        }
      } else {
      }
    });

    setCurrentValues(newCurrentValues);
    handleCurrentMoreFields(filterObj);
  };

  /**
   * Handle current more fields
   */
  const handleCurrentMoreFields = filterObj => {
    const newCurrentMoreFields = [];
    const newFieldData = Array.isArray(fieldData) && fieldData.length ? fieldData : [];

    Object.keys(filterObj).forEach(key => {
      const found = newFieldData.find(item => !item.isDefaultSearch && item.referenceField === key);
      if (found) newCurrentMoreFields.push(key);
    });

    setCurrentMoreFields(newCurrentMoreFields);
  };

  /**
   * Watching change of queryParams on url
   * Watching change of
   */
  useEffect(() => {
    if (!queryParams || !(Array.isArray(fieldData) && fieldData.length)) return;

    let newQuery = {};

    if (queryParams?.filter) {
      newQuery.filter = queryParams?.filter;
    }

    if (!isSetCurrentValues) {
      handleSetCurrentValues(newQuery);
      setIsSetCurrentValues(true);

      return;
    } else if (
      // ==========> For change query params on url
      newQuery?.filter !== filter
    ) {
      handleSetCurrentValues(newQuery);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, searchForm, queryParams, filter, isSetCurrentValues, setQueryParams]);

  /**
   * Validate valid JSON
   *
   * @param {object} rule - Rule of validation
   * @param {string} value - Value of input
   */
  const validateValidJson = async (rule, value) => {
    if (!value) return Promise.resolve();

    value = value.trim();

    if (!jsonParse(value)) return Promise.reject(t('message.invalidFormat'));

    return Promise.resolve();
  };

  /**
   * Convert to filter string
   */
  const convertToFilterString = values => {
    if (!(Array.isArray(values) && values.length)) return;

    // ==========> Search
    const queryObj = {};

    values.forEach(item => {
      const operation = OPERATION_MAPPING[item.operation];
      let value = item.value;

      if (!operation) {
        queryObj[item.referenceField] = value;
        return;
      }

      if (item.operation !== 'isNull' && (value === null || value === undefined)) return;

      if (item.componentType === COMPONENT_TYPE.DATE || item.componentType === COMPONENT_TYPE.DATE_TIME) {
        if (Array.isArray(value) && value.every(sub => sub && moment(sub).isValid())) {
          value = value.map(val => moment.utc(val).format());
        } else if (value && moment(value).isValid()) {
          value = moment.utc(value).format();
        } else {
        }
      }

      if (item.operation === 'in range' && Array.isArray(item.value) && item.value.length) {
        value = {
          $gte: value[0], // >=
          $lte: value[1] // <=
        };
      } else if (item.operation === 'not in range' && Array.isArray(item.value) && item.value.length) {
        value = {
          $not: {
            $gt: value[0], // <
            $lt: value[1] // >
          }
        };
      } else if (item.operation === '~') {
        value = { $regex: value, $options: 'i' };
      } else {
        value = { [operation]: value };
      }

      queryObj[item.referenceField] = value;
    });

    const newFilter = qs.stringify(queryObj, { encode: false }) || undefined;

    return newFilter;
  };
  /**
   * On apply
   */
  const onApply = ({ field, value, operation }) => {
    if (!field?.referenceField) return;

    // ==========> Get values
    let values = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];
    const newVal = { referenceField: field.referenceField, componentType: field.componentType, value, operation };

    values = [...values].filter(item => item.referenceField !== field.referenceField); // Remove first
    values = [...values, newVal]; // Then, add new

    const newFilter = convertToFilterString(values);

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: newFilter });
    } else {
      onSearch({ filterString: newFilter });
    }

    if (typeof onChangeFilter === 'function') onChangeFilter(newFilter);
  };

  /**
   * On clear value
   */
  const onClearValue = field => {
    if (!field?.referenceField) return;

    onApply({ field, value: undefined });
  };

  /**
   * On remove from more fields
   */
  const onRemoveFromMoreFields = field => {
    if (!field?.referenceField) return;

    const newCurrentMoreFields =
      Array.isArray(currentMoreFields) && currentMoreFields.length
        ? [...currentMoreFields].filter(item => item !== field.referenceField)
        : [];

    onClearValue(field);
    setCurrentMoreFields(newCurrentMoreFields);
  };

  /**
   * On clear all filters
   */
  const onClearAllFilters = () => {
    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: undefined });
    } else {
      onSearch({ filterString: undefined });
    }

    if (typeof onChangeFilter === 'function') onChangeFilter(undefined);
  };

  /**
   * Render field item
   */
  const renderFieldItem = field => {
    if (!field?.referenceField) return null;

    if (field.componentType === COMPONENT_TYPE.STRING || field.componentType === COMPONENT_TYPE.NUMBER) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.DATE || field.componentType === COMPONENT_TYPE.DATE_TIME) {
      return (
        <FieldDate
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.PICKLIST || field.componentType === COMPONENT_TYPE.OPTION) {
      return (
        <FieldSelect
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.SUGGESTION) {
      return (
        <FieldSuggestion
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.USER) {
      return (
        <FieldUser
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }
  };

  /**
   * Render more button
   */
  const renderMoreButton = () => {
    const optionData =
      Array.isArray(fieldData) && fieldData.length
        ? fieldData
            .filter(item => !item.isDefaultSearch)
            .map(item => {
              return {
                label: t(`workItem.${item.name}`),
                value: item.referenceField
              };
            })
        : [];

    return (
      <MoreDropdown
        currentValues={currentMoreFields}
        optionData={optionData}
        onChangeSelected={setCurrentMoreFields}
        onClear={fields => {
          const newFieldData = Array.isArray(fieldData) && fieldData.length ? fieldData : [];
          const newFields = Array.isArray(fields) && fields.length ? fields : [];

          newFields.forEach(sub => {
            const found = newFieldData.find(fi => fi.referenceField === sub);
            if (found) onApply({ field: found, value: undefined });
          });

          setCurrentMoreFields([]);
        }}
      />
    );
  };

  /**
   * Render right button
   */
  const RightButton = () => {
    return (
      <Row align="middle">
        <Tooltip title={t('search.clearAllFilters')} placement="topRight" destroyTooltipOnHide={true}>
          <Button
            type="link"
            className="border-transparent text-hover-dark-primary my-1 ml-2 px-0"
            onClick={onClearAllFilters}
          >
            <ClearFilter style={{ fontSize: '18px' }} />
          </Button>
        </Tooltip>
      </Row>
    );
  };

  /**
   * On submit and search
   */
  const onSubmit = values => {
    if (loading) return;

    const queryParsed = jsonParse(`${values.filterInput}`);
    const newFilter = qs.stringify(queryParsed, { encode: false });

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: newFilter });
    } else if (typeof onSearch === 'function') {
      onSearch({ filterString: newFilter });
    } else {
    }

    if (typeof onChangeFilter === 'function') onChangeFilter(newFilter);
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setSuggestion([]);
    };
  }, [setSuggestion]);

  return (
    <div className={`c-basic-search ${className}`} {...rest}>
      <Row>
        {!hideDataSourceSelection && (
          <Col className="col-data-source">
            <Row align="middle">
              <span className="text-primary font-weight-medium mr-1">{t('search.dataSource')}:</span>

              <DataSourceSelection
                currentDataSource={currentDataSource}
                setCurrentDataSource={val => {
                  if (typeof setCurrentDataSource === 'function') setCurrentDataSource(val);
                }}
              />
            </Row>
          </Col>
        )}

        <Col flex="1 1 auto" className="col-fields">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingList}>
            <div>
              <Row gutter="20">
                <Col flex="1 1 auto">
                  {/* For default fields */}
                  {Array.isArray(fieldData) && fieldData.length > 0 && (
                    <Row align="middle">
                      {fieldData
                        .filter(item => item.isDefaultSearch)
                        .map(item => (
                          <div key={item.referenceField} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}

                      <div className="my-1 mr-2">{renderMoreButton()}</div>
                    </Row>
                  )}

                  {/* For more fields */}
                  {Array.isArray(fieldData) &&
                    fieldData.length > 0 &&
                    Array.isArray(currentMoreFields) &&
                    currentMoreFields.length > 0 && (
                      <Row align="middle">
                        {fieldData
                          .filter(item => currentMoreFields.includes(item.referenceField))
                          .map(item => (
                            <div key={item.referenceField} className="my-1 mr-2">
                              {renderFieldItem(item)}
                            </div>
                          ))}
                      </Row>
                    )}
                </Col>

                <Col>
                  <RightButton />
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
