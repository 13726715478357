export default {
  // Generic
  'generic.add': 'Thêm',
  'generic.cancel': 'Hủy bỏ',

  // BlockType
  'components.controls.blocktype.h1': 'Thẻ H1',
  'components.controls.blocktype.h2': 'Thẻ H2',
  'components.controls.blocktype.h3': 'Thẻ H3',
  'components.controls.blocktype.h4': 'Thẻ H4',
  'components.controls.blocktype.h5': 'Thẻ H5',
  'components.controls.blocktype.h6': 'Thẻ H6',
  'components.controls.blocktype.blockquote': 'Đoạn trích dẫn',
  'components.controls.blocktype.code': 'Đoạn mã',
  'components.controls.blocktype.blocktype': 'Khối',
  'components.controls.blocktype.normal': 'Chữ thường',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Chọn màu',
  'components.controls.colorpicker.text': 'Chữ',
  'components.controls.colorpicker.background': 'Làm nổi bật',

  // Embedded
  'components.controls.embedded.embedded': 'Nhúng',
  'components.controls.embedded.embeddedlink': 'Nhúng đường dẫn',
  'components.controls.embedded.enterlink': 'Nhập đường dẫn',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Phông chữ',

  // FontSize
  'components.controls.fontsize.fontsize': 'Cỡ chữ',

  // History
  'components.controls.history.history': 'Lịch sử',
  'components.controls.history.undo': 'Hoàn tác',
  'components.controls.history.redo': 'Làm lại',

  // Image
  'components.controls.image.image': 'Hình ảnh',
  'components.controls.image.fileUpload': 'Tải ảnh lên',
  'components.controls.image.byURL': 'Đường dẫn URL',
  'components.controls.image.dropFileText': 'Thả tệp hoặc nhấp để tải lên',

  // Inline
  'components.controls.inline.bold': 'In đậm',
  'components.controls.inline.italic': 'In nghiêng',
  'components.controls.inline.underline': 'Gạch chân',
  'components.controls.inline.strikethrough': 'Gạch ngang',
  'components.controls.inline.monospace': 'Dạng Monospace',
  'components.controls.inline.superscript': 'Chỉ số trên',
  'components.controls.inline.subscript': 'Chỉ số dưới',

  // Link
  'components.controls.link.linkTitle': 'Tiêu đề liên kết',
  'components.controls.link.linkTarget': 'Target của liên kết',
  'components.controls.link.linkTargetOption': 'Mở liên kết trong cửa sổ mới',
  'components.controls.link.link': 'Liên kết',
  'components.controls.link.unlink': 'Hủy liên kết',

  // List
  'components.controls.list.list': 'Danh sách',
  'components.controls.list.unordered': 'Không sắp xếp',
  'components.controls.list.ordered': 'Sắp xếp',
  'components.controls.list.indent': 'Thụt lề',
  'components.controls.list.outdent': 'Nhô lề',

  // Remove
  'components.controls.remove.remove': 'Loại bỏ',

  // TextAlign
  'components.controls.textalign.textalign': 'Căn chỉnh văn bản',
  'components.controls.textalign.left': 'Căn trái',
  'components.controls.textalign.center': 'Căn giữa',
  'components.controls.textalign.right': 'Căn phải',
  'components.controls.textalign.justify': 'Căn đều'
};
