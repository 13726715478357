import React from 'react';
import { FileDoneOutlined, CalendarOutlined, CarryOutOutlined, FileTextOutlined } from '@ant-design/icons';

import env from '../../env';
import { CICD, Monitors, BarChart, PieChart, AnalysisChart, SquareBarChart } from '../../assets/svg-icons';

const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

/**
 * Project engine menu
 */
export const PROJECT_ENGINE_MENU = [
  {
    id: 'summary',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/summary`,
    name: 'menu.summary',
    icon: <PieChart />
  },
  {
    id: 'planning',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning`,
    name: 'menu.automationPlanning',
    icon: <CalendarOutlined />,
    noLink: true,
    isHideOnSidebar: true
  },
  {
    id: 'planningSchedule',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/schedule`,
    name: 'menu.jobSchedule',
    icon: <CarryOutOutlined />
  },
  {
    id: 'planningCreateSchedule',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/schedule/create`,
    name: 'menu.addSchedule',
    isHideOnSidebar: true
  },
  {
    id: 'planningCloneSchedule',
    parentId: 'planningSchedule',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/schedule/clone/:id`,
    name: 'menu.cloneJob',
    isHideOnSidebar: true
  },
  {
    id: 'planningCicd',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/cicd`,
    name: 'menu.cicdPipelines',
    icon: <CICD />
  },
  {
    id: 'planningCreateCicd',
    parentId: 'planningCicd',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/cicd/create`,
    name: 'menu.addPipeline',
    isHideOnSidebar: true
  },
  {
    id: 'planningCreateCicd',
    parentId: 'planningCicd',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/cicd/clone/:id`,
    name: 'menu.cloneJob',
    isHideOnSidebar: true
  },
  {
    id: 'planningCicdWebhook',
    parentId: 'planningCicd',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/cicd/webhook/:_id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'planningJobRunner',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner`,
    name: 'menu.jobRunner',
    icon: <FileTextOutlined />
  },
  {
    id: 'planningJobRunnerDetail',
    parentId: 'planningJobRunner',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'planningJobRunnerTestResultDetail',
    parentId: 'planningJobRunner',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner/test-result-detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'planningJobRunnerTestcaseDetail',
    parentId: 'planningJobRunner',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner/testcase-detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'planningJobRunnerEdit',
    parentId: 'planningJobRunner',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner/edit/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'planningJobRunnerWebhook',
    parentId: 'planningJobRunner',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner/webhook/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testAnalysis',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis`,
    name: 'menu.testAnalysis',
    icon: <AnalysisChart />,
    noLink: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testData',
    parentId: 'testAnalysis',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/test-data`,
    name: 'menu.testData',
    icon: <FileDoneOutlined />
  },
  {
    id: 'testResults',
    parentId: 'testAnalysis',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/test-results`,
    name: 'menu.testData',
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    icon: <FileDoneOutlined />
  },
  {
    id: 'testResultsDetail',
    parentId: 'testResults',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/test-results/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingCommonReports',
    parentId: 'testAnalysis',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/common-reports`,
    name: 'menu.commonReports',
    icon: <SquareBarChart />
  },
  {
    id: 'reportingCommonReportsDetail',
    parentId: 'reportingCommonReports',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/common-reports/detail/:_id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingMyReports',
    parentId: 'testAnalysis',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports`,
    name: 'menu.myReports',
    icon: <BarChart />
  },
  {
    id: 'reportingMyReportsCreate',
    parentId: 'reportingMyReports',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports/create`,
    name: 'menu.create',
    isHideOnSidebar: true
  },
  {
    id: 'reportingMyReportsCreateFromTemplate',
    parentId: 'reportingMyReports',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports/create/template/:id`,
    name: 'menu.create',
    isHideOnSidebar: true
  },
  {
    id: 'reportingMyReportsDetail',
    parentId: 'reportingMyReports',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingMyReportsClone',
    parentId: 'reportingMyReports',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports/clone/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'agentPools',
    parentId: null,
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/agent-pools`,
    name: 'menu.agentPools',
    icon: <Monitors />
  },
  {
    id: 'agentPoolsDetail',
    parentId: 'agentPools',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/agent-pools/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'agentDetail',
    parentId: 'agentPools',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/agent-pools/agent/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  }
];
