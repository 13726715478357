import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../common';

export const model = {
  /**
   * State
   */
  jobStatuses: [],
  loadingJobStatuses: false,

  /**
   * Action: Set job statuses
   */
  setJobStatuses: action((state, payload) => {
    if (state?.jobStatuses === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.jobStatuses = [];
      return;
    }

    state.jobStatuses = payload;
  }),

  /**
   * Action: Set loading job statuses
   */
  setLoadingdJobStatuses: action((state, payload) => {
    if (state?.loadingJobStatuses === undefined) return;

    state.loadingJobStatuses = payload;
  }),

  /**
   * Action: Call api to get job statuses
   */
  getJobStatuses: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingdJobStatuses(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB}/summary/count-by-type?type=latestRunner.status`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobStatuses(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingdJobStatuses(false);
    }
  })
};
