import React from 'react';
import {
  EyeOutlined,
  StopOutlined,
  SyncOutlined,
  TableOutlined,
  DeleteOutlined,
  FolderOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';

import env from '../env';
import {
  Dot,
  Mail,
  Zalo,
  Slack,
  EnFlag,
  FrFlag,
  JaFlag,
  VnFlag,
  Webhook,
  UpArrow,
  TowLines,
  PieChart2,
  LineChart,
  DownArrow,
  // AreaChart,
  // UserCircle,
  // MinusCircle,
  // ScatterChart,
  // DoughnutChart,
  DoubleUpArrow,
  MicrosoftTeams,
  // AreaStackedChart,
  MultipleLineChart,
  SingleColumnChart,
  GroupedColumnChart,
  StackedColumnChart,
  // HorizontalBarChart,
  // MultipleStackColumnChart,
  // Icon100StackedColumnChart,
  // HorizontalGroupedBarChart,
  // HorizontalStackedBarChart,
  // HorizontalMultipleStackBarChart,
  // Icon100HorizontalStackedBarChart,
  // AzureDevOps,
  // JiraAtlassian,
  TestManLogo,
  // BugIcon,
  // DefectIcon,
  // BugSearching,
  JiraAtlassian,
  Bug,
  Requirement,
  Defect,
  Epic,
  Feature,
  Task,
  TestCase,
  TestResult,
  TestRun,
  UserStory,
  Issue,
  ChangeRequest,
  Risk,
  DoubleDownArrow,
  AzureDevOps,
  TextboxIcon,
  NumberIcon,
  PeopleIcon,
  DateIcon,
  DateTimeIcon,
  UrlIcon,
  PicklistIcon,
  HtmlIcon,
  RelationIcon,
  CheckboxIcon,
  EditableMode,
  Disconnect,
  InfoCircle
} from '../assets/svg-icons';

const relatedToBlockedBy = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/blocked_by.png`;
const relatedToDuplicate = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/duplicate.png`;
const relatedToLinksTo = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/links_to.png`;
const relatedToParentChild = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/parent_child.png`;
const relatedToDependOnEffectBy = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/depend_on_effect_by.png`;
const relatedToTestsForTestedBy = `/${env.REACT_APP_PREFIX_PATH}images/relatedTo_illustration/tests_for_tested_by.png`;

export * from './roles';
export * from './permissions';
export * from './menus';
export * from './endpoints';

/**
 * Config localStorage, sessionStorage variables
 */
export const LS_LANGUAGE = 'lang';
export const LS_CURRENT_PROJECT = 'currentProject';
export const LS_PRODUCTION_KEY = 'productionKey';
export const LS_TEST_PLANNING_FOLDER = 'testPlanningFolder';
export const LS_REPOSITORY_FOLDER = 'repositoryFolder';
export const SS_TEST_PLANNING_CONFIG_GRID_VIEW = 'testPlanningConfigGridView';
export const SS_REPOSITORY_CONFIG_GRID_VIEW = 'repositoryConfigGridView';
export const SS_WORK_TICKET_CONFIG_GRID_VIEW = 'workTicketConfigGridView';
export const SS_TEST_DATA_CONFIG_GRID_VIEW = 'testDataConfigGridView';
export const SS_LAST_SAVED_VALUE = 'lastSavedValue';
export const SS_LOADED_USER_LIST_BY_ROLE_INFO = 'loadedUserListByRoleInfo';
export const SS_LOADED_ALL_SUGGESTION_INFO = 'loadedAllSuggestionInfo';
export const SS_EDITING_CELL = 'editingCell';
export const SS_NEW_RECORDS = 'newRecords';
export const SS_NEW_TEST_STEPS = 'newTestSteps';
export const SS_CLIPBOARD_TEST_STEPS = 'clipboardTestSteps';
export const SS_LOADED_FULL_FILE_INFO = 'loadedFullFileInfo';
export const INVISIBLE_PROGRESS_BAR = 'invisible_progress_bar';

/**
 * Http status code list
 */
export const HTTP_STATUS_CODES = [
  { label: 'Bad Request', value: 400 },
  { label: 'Unauthorized', value: 401 },
  { label: 'Payment Required', value: 402 },
  { label: 'Forbidden', value: 403 },
  { label: 'Not Found', value: 404 },
  { label: 'Internal Server Error', value: 500 }
];

/**
 * Language list
 */
export const LANGUAGES = [
  { label: 'common.english', value: 'en', flag: <EnFlag /> },
  { label: 'common.french', value: 'fr', flag: <FrFlag /> },
  { label: 'common.japanese', value: 'ja', flag: <JaFlag /> },
  { label: 'common.vietnamese', value: 'vi', flag: <VnFlag /> }
];

/**
 * Product key (On url)
 */
export const PRODUCT_KEY_ON_URL_ENGINE = 'engine';
export const PRODUCT_KEY_ON_URL_MANAGER = 'manager';

/**
 * Product key
 */
export const PRODUCT_KEY_TESTENGINE = 'testengine';
export const PRODUCT_KEY_MANAGER = 'manager';
export const PRODUCT_KEY_STUDIO = 'studio';

/**
 * Tenant
 */
export const TENANT_KEY_TRIAL = 'trial';

/**
 * List of number record in one page
 */
export const PAGE_SIZES = [10, 20, 50, 100];
export const PAGE_SIZE = PAGE_SIZES[0];
export const PAGE_SIZE_5 = 5;
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;

/**
 * Default sort value
 */
export const DEFAULT_ORDER = 'updatedAt|DESC';
export const ORDER_BY_ID_DESC = 'id|DESC';
export const ORDER_BY_KEY_DESC = 'key|DESC';
export const ORDER_BY_ORDER_ID_DESC = 'orderId|DESC';
export const ORDER_BY_CREATED_AT_DESC = 'createdAt|DESC';
export const ORDER_BY_EXECUTED_AT_DESC = 'executedAt|DESC';
export const ORDER_BY_START_TIME_DESC = 'startTime|DESC';
export const ORDER_BY_USERNAME_ASC = 'username|ASC';

/**
 * Patterns
 */
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
export const URL_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\-.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
export const ESTIMATED_TIME_PATTERN = /^([0-9]+([.][0-9]+)?[wdhm])([ ][0-9]+([.][0-9]+)?[wdhm])*$/; // Example: 1w 1.2h 5d 2.5h 32m 8h
export const NUMBER_PATTERN = /^-?[0-9]\d*(\.\d+)?$/; // Integer, Float
export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
export const SPECIAL_CHARACTERS_PATTERN = /^[^*()\[\]{}]*$/;
export const IMAGE_PATTERN = /<img[^>]*src=\"([^"]+)\"[^>]*>/gim;
export const BASE64_IMAGE_PATTERN = /<img[^>]*src=\"data:image\/([a-zA-Z]*);base64,([^\"]*)\"[^>]*>/gim;

/**
 * Date format
 */
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const FULL_DATE_FORMAT = 'DD/MMM/YY hh:mm A';
export const SHORT_DATE_FORMAT = 'DD/MMM/YY';
export const FULL_DATE_FORMAT_2 = 'YYYY/MM/DD HH:mm';
export const SHORT_DATE_FORMAT_2 = 'YYYY/MM/DD';
export const UTC_DATE_FORMAT = 'YYYY-MM-DDTHH:mmZ';

/**
 * No preview supported files
 */
export const NO_PREVIEW_SUPPORTED_FILES = ['avi', 'wmv', '3gp', 'mpeg', 'ts', 'mid', 'midi'];

/**
 * Not allowed to upload these file types
 */
export const EXCEPT_FILE_TYPES_UPLOAD = ['bat', 'exe', 'bin', 'html'];

/**
 * Maximum upload file size limit
 */
export const MAX_FILE_SIZE = 10; // 10MB

/**
 * Operation mapping
 */
export const OPERATION_MAPPING = {
  '~': '$regex',
  '=': '$eq',
  '<': '$lt',
  '>': '$gt',
  '<=': '$lte',
  '>=': '$gte',
  in: '$in',
  'in range': 'in range',
  'not in range': 'not in range',
  'not in': '$nin',
  or: '$or',
  and: '$and',
  isNull: { $exists: false }
};

/**
 * Data Source Fields
 */
export const DATA_SOURCE_TEST_RESULT = 'test-results';
export const DATA_SOURCE_AGENT = 'agents';
export const DATA_SOURCE_JOB = 'jobs';
export const DATA_SOURCE_JOB_RUNNER = 'job-runners';
export const DATA_SOURCE_DEFECT = 'defect';
export const DATA_SOURCE_TEST_CASE = 'system_wi_testcase';
export const DATA_SOURCE_TEST_RUN = 'system_wi_testrun';
export const DATA_SOURCES = {
  ENGINE: {
    [DATA_SOURCE_TEST_RESULT]: DATA_SOURCE_TEST_RESULT,
    [DATA_SOURCE_AGENT]: DATA_SOURCE_AGENT,
    [DATA_SOURCE_JOB]: DATA_SOURCE_JOB,
    [DATA_SOURCE_JOB_RUNNER]: DATA_SOURCE_JOB_RUNNER,
    [DATA_SOURCE_DEFECT]: DATA_SOURCE_DEFECT
  },
  MANAGER: {
    [DATA_SOURCE_TEST_CASE]: DATA_SOURCE_TEST_CASE,
    [DATA_SOURCE_TEST_RUN]: DATA_SOURCE_TEST_RUN
  }
};

export const DATA_SOURCES_LABEL = {
  [DATA_SOURCE_TEST_RESULT]: 'search.testResult',
  [DATA_SOURCE_AGENT]: 'search.agent',
  [DATA_SOURCE_JOB]: 'search.job',
  [DATA_SOURCE_JOB_RUNNER]: 'search.jobRunner',
  [DATA_SOURCE_DEFECT]: 'search.defect'
};
export const DATA_SOURCE_FIELDS = [
  {
    value: DATA_SOURCE_TEST_RESULT,
    label: 'search.testResult'
  },
  {
    value: DATA_SOURCE_AGENT,
    label: 'search.agent'
  },
  {
    value: DATA_SOURCE_JOB,
    label: 'search.job'
  },
  {
    value: DATA_SOURCE_JOB_RUNNER,
    label: 'search.jobRunner'
  }
];

/**
 * Background color list
 */
export const AVATAR_BACKGROUND_COLORS = [
  '#f67019',
  '#4dc9f6',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#8549ba'
];

/**
 * Integrate type
 */
export const INTEGRATE_TYPE_JIRA = 1;
export const INTEGRATE_TYPE_AZURE = 2;

/**
 * Jira authentication mode list
 */
export const JIRA_AUTH_MODE_O_AUTH = 1;
export const JIRA_AUTH_MODE_COOKIE_BASED_AUTH = 2;
export const JIRA_AUTH_MODES = [
  { value: JIRA_AUTH_MODE_O_AUTH, label: 'OAuth (1.0a)' }
  // { value: JIRA_AUTH_MODE_COOKIE_BASED_AUTH, label: 'Cookie-based auth' }
];

/**
 * Internal defect system
 */
export const INTERNAL_DEFECT_SYSTEM_ID = -1;
export const INTERNAL_DEFECT_SYSTEM = {
  value: INTERNAL_DEFECT_SYSTEM_ID,
  label: 'project.internalDefect'
};

/**
 * Priority list
 */
export const PRIORITY_MEDIUM = 3;
export const PRIORITIES = [
  { value: 1, label: 'Highest', icon: <DoubleUpArrow className="mr-1" style={{ color: '#ce0000' }} /> },
  { value: 2, label: 'High', icon: <UpArrow className="mr-1" style={{ color: '#ff5630' }} /> },
  { value: PRIORITY_MEDIUM, label: 'Medium', icon: <TowLines className="mr-1" style={{ color: '#ffab00' }} /> },
  { value: 4, label: 'Low', icon: <DownArrow className="mr-1" style={{ color: '#2684ff' }} /> },
  { value: 5, label: 'Lowest', icon: <DoubleDownArrow className="mr-1" style={{ color: '#2684ff' }} /> }
];

/**
 * Priority list
 */

export const JIRA_PRIORITY_MEDIUM = 'Medium';
export const JIRA_PRIORITIES = [
  { value: 'Highest', label: 'Highest' },
  { value: 'High', label: 'High' },
  { value: JIRA_PRIORITY_MEDIUM, label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'Lowest', label: 'Lowest' }
];

/**
 * Test result status
 */
export const TEST_RESULT_STATUS_NOT_EXECUTED = 'Not Executed';
export const TEST_RESULT_STATUS_PASS = 'Pass';
export const TEST_RESULT_STATUS_FAIL = 'Fail';
export const TEST_RESULT_STATUS_WIP = 'WIP';
export const TEST_RESULT_STATUS_BLOCKED = 'Blocked';
export const TEST_RESULT_STATUS_LIST = [
  {
    value: TEST_RESULT_STATUS_NOT_EXECUTED,
    label: 'Not Executed',
    icon: <StopOutlined />,
    color: '#b7b49e'
  },
  {
    value: TEST_RESULT_STATUS_PASS,
    label: 'Pass',
    icon: <CheckCircleOutlined />,
    color: '#79b204'
  },
  {
    value: TEST_RESULT_STATUS_FAIL,
    label: 'Fail',
    icon: <CloseCircleOutlined />,
    color: '#cc3202'
  },
  {
    value: TEST_RESULT_STATUS_WIP,
    label: 'WIP',
    icon: <ClockCircleOutlined />,
    color: '#f1b100'
  },
  {
    value: TEST_RESULT_STATUS_BLOCKED,
    label: 'Blocked',
    icon: <MinusCircleOutlined />,
    color: '#8fc5fb'
  }
];

/**
 * Test result status
 */
export const TEST_RESULT_AUTOMATION_STATUS_PASSED = 'PASS';
export const TEST_RESULT_AUTOMATION_STATUS_FAILED = 'FAIL';
export const TEST_RESULT_AUTOMATION_STATUS_STOPPED = 'STOP';
export const TEST_RESULT_AUTOMATION_STATUS_LIST = [
  {
    value: TEST_RESULT_AUTOMATION_STATUS_PASSED,
    label: 'PASSED',
    icon: <CheckCircleOutlined />,
    color: '#79b204'
  },
  {
    value: TEST_RESULT_AUTOMATION_STATUS_FAILED,
    label: 'FAILED',
    icon: <CloseCircleOutlined />,
    color: '#cc3202'
  },
  {
    value: TEST_RESULT_AUTOMATION_STATUS_STOPPED,
    label: 'STOPPED',
    icon: <MinusCircleOutlined />,
    color: '#8fc5fb'
  }
];

/**
 * Defect statuses
 */
export const DEFECT_STATUS_NEW = 1;
export const DEFECT_STATUSES = [
  { value: DEFECT_STATUS_NEW, label: 'NEW' },
  { value: 2, label: 'ASSIGNED' },
  { value: 3, label: 'FIXED' },
  { value: 4, label: 'REJECTED' },
  { value: 5, label: 'VERIFIED' },
  { value: 6, label: 'REOPENED' },
  { value: 7, label: 'CLOSED' }
];

/**
 * Defect severities
 */
export const DEFECT_SEVERITIES = [
  { value: '1', label: 'Fatal' },
  { value: '2', label: 'Major' },
  { value: '3', label: 'Minor' },
  { value: '4', label: 'Trivial' },
  { value: '5', label: 'Suggestion' },
  { value: '6', label: 'Blocker' }
];

/**
 * OS list
 */
export const OS_LIST = [
  { value: '-1', label: 'common.all', isChangedLanguage: true },
  { value: '1', label: 'Windows' },
  { value: '2', label: 'Mac OS' },
  { value: '3', label: 'Linux' },
  { value: '4', label: 'common.others', isChangedLanguage: true }
];

/**
 * Hardware list
 */
export const HARDWARE_LIST = [
  { value: '-1', label: 'common.all', isChangedLanguage: true },
  { value: '2', label: 'PC' },
  { value: '3', label: 'Macintosh' },
  { value: '4', label: 'common.others', isChangedLanguage: true }
];

/**
 * Test result Most Failed  Test Cases
 */
export const TEST_RESULT_MOST_FAILED_TESTCASE = 'Most Failed';
export const TEST_RESULT_MOST_FAILED_LIST = [
  {
    value: TEST_RESULT_MOST_FAILED_TESTCASE,
    label: 'Most Failed'
  }
];

/**
 * Test result Most Executed Test Cases
 */
export const TEST_RESULT_MOST_EXECUTED_TESTCASE = 'Most Executed';
export const TEST_RESULT_MOST_EXECUTED_LIST = [
  {
    value: TEST_RESULT_MOST_EXECUTED_TESTCASE,
    label: 'Most Executed'
  }
];

/**
 * Test result plan vs executed
 */
export const TEST_RESULT_PLAN = 'dataPlanning';
export const TEST_RESULT_ACTUAL = 'dataActual';
export const TEST_RESULT_LIST = [
  { value: TEST_RESULT_PLAN, label: 'Plan' },
  { value: TEST_RESULT_ACTUAL, label: 'Actual' }
];

/**
 * Test result chart by user
 */
export const TEST_RESULT_USER_SCHEDULE = 'Schedule';
export const TEST_RESULT_USER_EXECUTED = 'Executed';
export const TEST_RESULT_USER_LIST = [
  { value: TEST_RESULT_USER_SCHEDULE, label: 'Schedule' },
  { value: TEST_RESULT_USER_EXECUTED, label: 'Executed' }
];

/**
 * Planning calendar type list
 */
export const PLANNING_CALENDAR_TYPE_MONTH = 'month';
export const PLANNING_CALENDAR_TYPE_WEEK = 'week';

/**
 * Cycle statuses
 */
export const CYCLE_STATUS_ACTIVE = 1;
export const CYCLE_STATUS_DEACTIVATED = 0;
export const CYCLE_STATUSES = [
  { value: CYCLE_STATUS_ACTIVE, label: 'common.yes' },
  { value: CYCLE_STATUS_DEACTIVATED, label: 'common.no' }
];

/**
 * View and view type list
 */
export const VIEW_SEARCH_BASIC = 'basic';
export const VIEW_SEARCH_ADVANCED = 'advanced';

/**
 * Tree type list
 */
export const TREE_ROOT = 'root';

/**
 * Test planning tree types
 */
export const TEST_PLAN_TREE_TYPE_RELEASE = 'release';
export const TEST_PLAN_TREE_TYPE_CYCLE = 'cycle';
export const TEST_PLAN_TREE_TYPE_SUITE = 'test-suite';

/**
 * Status of release/cycle/test suite in test plan
 * Test plan workflow
 */
export const TEST_PLAN_ITEM_STATUS_DRAFT = 'system.wi.state.742a';
export const TEST_PLAN_ITEM_STATUS_UNDER_REVIEW = 'system.wi.state.87d8';
export const TEST_PLAN_ITEM_STATUS_IN_PROGRESS = 'system.wi.state.5f1f';
export const TEST_PLAN_ITEM_STATUS_RELEASED = 'system.wi.state.0017';
export const TEST_PLAN_ITEM_STATUS_CANCELLED = 'custom.wi.state.7206';

const TEST_PLAN_ITEM_STATUS_DRAFT_ITEM = {
  name: 'testPlan.draft',
  value: TEST_PLAN_ITEM_STATUS_DRAFT,
  id: 'system.wi.state.742a',
  type: 'open',
  color: { r: 22, g: 56, b: 113 },
  background: { r: 218, g: 218, b: 218 },
  position: { x: 397.879, y: -19.799 }
};

export const TEST_PLAN_WORKFLOW = {
  defaultState: TEST_PLAN_ITEM_STATUS_DRAFT_ITEM,
  listStates: [
    TEST_PLAN_ITEM_STATUS_DRAFT_ITEM,
    {
      name: 'testPlan.underReview',
      value: TEST_PLAN_ITEM_STATUS_UNDER_REVIEW,
      id: 'system.wi.state.87d8',
      type: 'inprogress',
      position: { x: 386, y: 58.5 },
      color: { r: 255, g: 255, b: 255, a: 1 },
      background: { r: 242, g: 167, b: 40, a: 1 }
    },
    {
      name: 'testPlan.inProgress',
      value: TEST_PLAN_ITEM_STATUS_IN_PROGRESS,
      id: 'system.wi.state.5f1f',
      type: 'inprogress',
      position: { x: 391, y: 143.5 },
      color: { r: 255, g: 255, b: 255, a: 1 },
      background: { r: 65, g: 117, b: 5, a: 1 }
    },
    {
      name: 'testPlan.released',
      value: TEST_PLAN_ITEM_STATUS_RELEASED,
      id: 'system.wi.state.0017',
      type: 'done',
      position: { x: 398, y: 214 },
      color: { r: 252, g: 252, b: 255, a: 1 },
      background: { r: 107, g: 173, b: 250, a: 1 }
    },
    {
      name: 'testPlan.cancelled',
      value: TEST_PLAN_ITEM_STATUS_CANCELLED,
      id: 'custom.wi.state.7206',
      type: 'done',
      position: { x: 591.051, y: 56.243 },
      color: { r: 0, g: 62, b: 132, a: 1 },
      background: { r: 155, g: 155, b: 155, a: 1 }
    }
  ],
  listSteps: [
    {
      name: 'testPlan.review',
      from: 'system.wi.state.742a',
      to: 'system.wi.state.87d8',
      fromHandle: 'source-bottom-center',
      toHandle: 'target-top-center'
    },
    {
      name: 'testPlan.startTestPlan',
      from: 'system.wi.state.87d8',
      to: 'system.wi.state.5f1f',
      fromHandle: 'source-bottom-left',
      toHandle: 'target-top-left'
    },
    {
      name: 'testPlan.reReview',
      from: 'system.wi.state.5f1f',
      to: 'system.wi.state.87d8',
      fromHandle: 'source-top-right',
      toHandle: 'target-bottom-right'
    },
    {
      name: 'testPlan.release',
      from: 'system.wi.state.5f1f',
      to: 'system.wi.state.0017',
      fromHandle: 'source-bottom-left',
      toHandle: 'target-top-left'
    },
    {
      name: 'testPlan.forcedUpdate',
      from: 'system.wi.state.0017',
      to: 'system.wi.state.5f1f',
      fromHandle: 'source-top-right',
      toHandle: 'target-bottom-right'
    },
    {
      name: 'testPlan.cancel',
      from: 'system.wi.state.5f1f',
      to: 'custom.wi.state.7206',
      fromHandle: 'source-right-center',
      toHandle: 'target-bottom-center'
    },
    {
      name: 'testPlan.review',
      from: 'custom.wi.state.7206',
      to: 'system.wi.state.87d8',
      fromHandle: 'source-left-top',
      toHandle: 'target-right-top'
    },
    {
      name: 'testPlan.cancel',
      from: 'system.wi.state.87d8',
      to: 'custom.wi.state.7206',
      fromHandle: 'source-right-bottom',
      toHandle: 'target-left-bottom'
    },
    {
      name: 'testPlan.cancel',
      from: 'system.wi.state.742a',
      to: 'custom.wi.state.7206',
      fromHandle: 'source-right-center',
      toHandle: 'target-top-center'
    }
  ]
};

/**
 * Comment type list
 */
export const COMMENT_TYPE_TESTCASE = 'testcase';
export const COMMENT_TYPE_TEST_RESULT = 'test_result';

/**
 * Type of status list (For workflow)
 */
export const WORKFLOW_STATUS_TYPE_OPEN = 'open';
export const WORKFLOW_STATUS_TYPE_INPROGRESS = 'inprogress';
export const WORKFLOW_STATUS_TYPE_DONE = 'done';
export const WORKFLOW_STATUS_TYPES = [
  {
    value: WORKFLOW_STATUS_TYPE_OPEN,
    label: 'process.typeOfStatusOpen',
    background: { r: 218, g: 218, b: 218 },
    color: { r: 22, g: 56, b: 113 },
    position: { x: 252, y: 0 }
  },
  {
    value: WORKFLOW_STATUS_TYPE_INPROGRESS,
    label: 'process.typeOfStatusInProgress',
    background: { r: 213, g: 230, b: 255 },
    color: { r: 22, g: 56, b: 113 },
    position: { x: 246, y: 150 }
  },
  {
    value: WORKFLOW_STATUS_TYPE_DONE,
    label: 'process.typeOfStatusDone',
    background: { r: 4, g: 153, b: 104 },
    color: { r: 255, g: 255, b: 255 },
    position: { x: 252, y: 300 }
  }
];

/**
 * Workflow default color list
 */
export const WORKFLOW_DEFAULT_COLOR_LIST = [
  { value: '208-2-27', label: '' },
  { value: '245-166-35', label: '' },
  { value: '248-231-28', label: '' },
  { value: '139-87-42', label: '' },
  { value: '126-211-33', label: '' },
  { value: '65-117-5', label: '' },
  { value: '189-16-226', label: '' },
  { value: '144-19-254', label: '' },
  { value: '22-56-113', label: '' },
  { value: '74-144-256', label: '' },
  { value: '213-230-255', label: '' },
  { value: '80-227-194', label: '' },
  { value: '4-153-104', label: '' },
  { value: '184-233-134', label: '' },
  { value: '255-255-255', label: '' },
  { value: '218-218-218', label: '' },
  { value: '155-155-155', label: '' },
  { value: '74-74-74', label: '' },
  { value: '0-0-0', label: '' }
];

/**
 * Entity name of customize field
 */

export const WORK_TICKET = 'work-ticket';
export const TEST_TICKET = 'test-ticket';
export const CUSTOMIZE_FIELD_ENTITY_NAME_FIELDS = 'fields';
export const CUSTOMIZE_FIELD_ENTITY_NAME_LIST_VIEW = 'listView';
export const CUSTOMIZE_FIELD_ENTITY_NAME_WORKFLOWS = 'workflows';

/**
 * Engine planning job type list
 */
export const ENGINE_PLANNING_JOB_SCHEDULE = 'schedule';
export const ENGINE_PLANNING_JOB_CICD = 'cicd';
export const ENGINE_PLANNING_JOB_TYPES = [
  {
    value: ENGINE_PLANNING_JOB_SCHEDULE,
    label: 'menu.jobSchedule'
  },
  {
    value: ENGINE_PLANNING_JOB_CICD,
    label: 'menu.cicdPipelines'
  }
];

/**
 * Engine planning job type list
 */
export const ENGINE_PLANNING_TEST_CONFIG = 'test-config';
export const ENGINE_PLANNING_TEST_CONFIG_VARIABLE = 'test-config-variable';

/**
 * Automation command type list (ENGINE_PLANNING_JOB_SCHEDULE includes SCRIPT and SUITE)
 */
export const AUTOMATION_COMMAND_TYPE_SUITE = 0;
export const AUTOMATION_COMMAND_TYPE_SCRIPT = 1;
export const AUTOMATION_COMMAND_TYPE_AZURE = 2;
export const AUTOMATION_COMMAND_TYPES = [
  {
    value: AUTOMATION_COMMAND_TYPE_SUITE,
    label: 'automation.suiteAutomation'
  },
  {
    value: AUTOMATION_COMMAND_TYPE_SCRIPT,
    label: 'automation.scriptAutomation'
  },
  {
    value: AUTOMATION_COMMAND_TYPE_AZURE,
    label: 'automation.azureDevOps'
  }
];

/**
 * Test item colors
 */
export const TEST_ITEM_COLORS = [
  '#f67019',
  '#4dc9f6',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#8549ba',
  '#a13f00',
  '#008dc1',
  '#d9026b',
  '#1f55b7',
  '#889c1b',
  '#045174',
  '#00813d',
  '#7129b1'
];

/**
 * Engine job statuses
 */
export const ENGINE_JOB_STATUS_PREPARING = 'PREPARING';
export const ENGINE_JOB_STATUS_WAITING = 'WAITING';
export const ENGINE_JOB_STATUS_RUNNING = 'RUNNING';
export const ENGINE_JOB_STATUS_COMPLETED = 'COMPLETED';
export const ENGINE_JOB_STATUS_ERROR = 'ERROR';
export const ENGINE_JOB_STATUS_CANCELLED = 'CANCELLED'; // Not used yet
export const ENGINE_JOB_STATUS_STOPPED = 'STOPPED';
export const ENGINE_JOB_STATUS_DISCONNECTED = 'DISCONNECTED';

export const ENGINE_JOB_STATUS_INPROGRESS = [ENGINE_JOB_STATUS_WAITING, ENGINE_JOB_STATUS_RUNNING];
export const ENGINE_JOB_STATUS_DONE = [
  ENGINE_JOB_STATUS_COMPLETED,
  ENGINE_JOB_STATUS_ERROR,
  ENGINE_JOB_STATUS_STOPPED,
  ENGINE_JOB_STATUS_DISCONNECTED,
  ENGINE_JOB_STATUS_WAITING
];
export const REACT_APP_AGENT_SOCKET_VERSIONS = ['1', '2'];

export const ENGINE_JOB_STATUSES = [
  {
    label: 'PREPARING',
    value: ENGINE_JOB_STATUS_PREPARING,
    icon: <CheckCircleFilled className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    label: 'WAITING',
    value: ENGINE_JOB_STATUS_WAITING,
    icon: <ClockCircleFilled className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    label: 'RUNNING',
    value: ENGINE_JOB_STATUS_RUNNING,
    icon: <SyncOutlined spin className="font-size-18" />,
    color: '#75a9f9'
  },
  {
    label: 'COMPLETED',
    value: ENGINE_JOB_STATUS_COMPLETED,
    icon: <CheckCircleFilled className="font-size-18" />,
    color: '#5aae61'
  },
  {
    label: 'ERROR',
    value: ENGINE_JOB_STATUS_ERROR,
    icon: <CloseCircleFilled className="font-size-18" />,
    color: '#b60d01'
  },
  {
    label: 'STOPPED',
    value: ENGINE_JOB_STATUS_STOPPED,
    icon: <StopOutlined className="font-size-18" />,
    color: '#b0b0b0'
  },
  {
    label: 'DISCONNECTED',
    value: ENGINE_JOB_STATUS_DISCONNECTED,
    icon: <Disconnect className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    label: 'CANCELLED',
    value: ENGINE_JOB_STATUS_CANCELLED,
    icon: <MinusCircleOutlined className="font-size-18" />,
    color: '#bfbfbf'
  }
];

/**
 * Engine task job statuses
 *
 * PREPARING => Extra status, only mean about display
 */
export const ENGINE_TASK_JOB_STATUS_PREPARING = 'PREPARING';
export const ENGINE_TASK_JOB_STATUS_WAITING = 'WAITING';
export const ENGINE_TASK_JOB_STATUS_RUNNING = 'RUNNING';
export const ENGINE_TASK_JOB_STATUS_COMPLETED = 'COMPLETED';
export const ENGINE_TASK_JOB_STATUS_ERROR = 'ERROR';
export const ENGINE_TASK_JOB_STATUS_STOPPED = 'STOPPED';
export const ENGINE_TASK_JOB_STATUS_DISCONNECTED = 'DISCONNECTED';

export const ENGINE_TASK_JOB_STATUS_DONE = [ENGINE_TASK_JOB_STATUS_COMPLETED, ENGINE_TASK_JOB_STATUS_ERROR];
export const ENGINE_TASK_JOB_STATUS_IMPROGRESS = [ENGINE_TASK_JOB_STATUS_WAITING, ENGINE_TASK_JOB_STATUS_RUNNING];
export const ENGINE_TASK_JOB_STATUSES = [
  {
    label: 'PREPARING',
    value: ENGINE_TASK_JOB_STATUS_PREPARING,
    icon: <CheckCircleFilled className="font-size-18" />,
    color: '#bfbfbf',
    isExtra: true
  },
  {
    label: 'WAITING',
    value: ENGINE_TASK_JOB_STATUS_WAITING,
    icon: <ClockCircleFilled className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    label: 'RUNNING',
    value: ENGINE_TASK_JOB_STATUS_RUNNING,
    icon: <SyncOutlined spin className="font-size-18" />,
    color: '#75a9f9'
  },
  {
    label: 'COMPLETED',
    value: ENGINE_TASK_JOB_STATUS_COMPLETED,
    icon: <CheckCircleFilled className="font-size-18" />,
    color: '#5aae61'
  },
  {
    label: 'ERROR',
    value: ENGINE_TASK_JOB_STATUS_ERROR,
    icon: <CloseCircleFilled className="font-size-18" />,
    color: '#b60d01'
  },
  {
    label: 'STOPPED',
    value: ENGINE_TASK_JOB_STATUS_STOPPED,
    icon: <StopOutlined className="font-size-18" />,
    color: '#b0b0b0'
  },
  {
    label: 'DISCONNECTED',
    value: ENGINE_TASK_JOB_STATUS_DISCONNECTED,
    icon: <Disconnect className="font-size-18" />,
    color: '#bfbfbf'
  }
];

/**
 * Engine test result statuses
 */
export const ENGINE_TEST_RESULT_STATUS_PASSED = 'PASS';
export const ENGINE_TEST_RESULT_STATUS_FAILED = 'FAIL';
export const ENGINE_TEST_RESULT_STATUS_STOPPED = 'STOP';
export const ENGINE_TEST_RESULT_STATUS_NOT_EXECUTE = 'NOT EXECUTE';
export const ENGINE_TEST_RESULT_STATUS_DELETED = 'DELETED';
export const ENGINE_TEST_RESULT_STATUS_DISCONNECTED = 'DISCONNECTED';
export const ENGINE_TEST_RESULT_STATUS_RUNNING = 'RUNNING';
export const ENGINE_TEST_RESULT_STATUSES = [
  {
    value: ENGINE_TEST_RESULT_STATUS_PASSED,
    label: 'testResult.statusPassed',
    icon: <CheckCircleFilled className="font-size-18" />,
    color: '#5aae61'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_FAILED,
    label: 'testResult.statusFailed',
    icon: <CloseCircleFilled className="font-size-18" />,
    color: '#b60d01'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_STOPPED,
    label: 'testResult.statusStopped',
    icon: <StopOutlined className="font-size-18" />,
    color: '#b0b0b0'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_NOT_EXECUTE,
    label: 'testResult.statusNotExecuted',
    icon: <InfoCircleOutlined className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_DELETED,
    label: 'testResult.statusDeleted',
    icon: <DeleteOutlined className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_DISCONNECTED,
    label: 'testResult.statusDisconnected',
    icon: <Disconnect className="font-size-18" />,
    color: '#bfbfbf'
  },
  {
    value: ENGINE_TEST_RESULT_STATUS_RUNNING,
    label: 'testResult.statusRunning',
    icon: <SyncOutlined className="font-size-18" />,
    color: '#4dc9f6'
  }
];

/**
 * Engine agent statuses
 */
export const ENGINE_AGENT_STATUS_READY = 'READY';
export const ENGINE_AGENT_STATUS_OFFLINE = 'OFFLINE';
export const ENGINE_AGENT_STATUS_NOT_READY = 'NOT-READY';
export const ENGINE_AGENT_STATUSES = [
  {
    value: ENGINE_AGENT_STATUS_READY,
    label: 'agentPool.statusOnline',
    icon: <Dot />,
    color: '#629c44'
  },
  {
    value: ENGINE_AGENT_STATUS_OFFLINE,
    label: 'agentPool.statusOffline',
    icon: <StopOutlined />,
    color: '#b0b0b0'
  },
  {
    value: ENGINE_AGENT_STATUS_NOT_READY,
    label: 'agentPool.statusNotReady',
    icon: <Dot />,
    color: 'red'
  }
];

/**
 * Schedule type list
 */
export const SCHEDULE_TYPE_ONE_TIME = 'one_time';
export const SCHEDULE_TYPE_DAILY = 'daily';
export const SCHEDULE_TYPE_WEEKLY = 'weekly';
export const SCHEDULE_TYPE_ADVANCE = 'advance';

/**
 * Import mapping
 */
export const TYPE_IMPORT_MAPPING_TESTCASE = 1;
export const TYPE_IMPORT_MAPPING_REQUIREMENT = 2;
export const TYPE_IMPORT_MAPPING_TEST_RESULT = 3;
export const TYPE_IMPORT_MAPPING_ENGINE_TEST_RESULT = 4;

/**
 * Schedule week day list
 */
export const SCHEDULE_WEEK_DAYS = [
  { label: 'automation.daySunday', value: 0 },
  { label: 'automation.dayMonday', value: 1 },
  { label: 'automation.dayTuesday', value: 2 },
  { label: 'automation.dayWednesday', value: 3 },
  { label: 'automation.dayThursday', value: 4 },
  { label: 'automation.dayFriday', value: 5 },
  { label: 'automation.daySaturday', value: 6 }
];

/**
 * Type of chart on dashboard
 */
export const CHART_DYNAMIC = 'dynamic';
export const CHART_COMMON = 'common';

/**
 * Y axis list
 */
export const Y_AXIS_COUNT = 'count';
export const Y_AXIS_SUM = 'sum';
export const Y_AXIS_AVG = 'avg';
export const Y_AXIS_MIN = 'min';
export const Y_AXIS_MAX = 'max';
export const Y_AXIS_LIST = [
  { label: 'report.count', value: Y_AXIS_COUNT }
  // { label: 'report.summary', value: Y_AXIS_SUM },
  // { label: 'report.average', value: Y_AXIS_AVG },
  // { label: 'report.min', value: Y_AXIS_MIN },
  // { label: 'report.max', value: Y_AXIS_MAX }
];

/**
 * Chart type list
 */
export const CHART_TYPE_LIST = [
  {
    label: 'report.typeColumn',
    value: 'column',
    icon: <SingleColumnChart />,
    children: [
      {
        label: 'report.typeSingle',
        value: 'single',
        type: 'bar',
        icon: <SingleColumnChart />,
        attributes: ['groupType', 'x', 'customY', 'customX'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'x'
          },
          scales: {
            x: {
              stacked: false
            },
            y: {
              position: 'left',
              title: {
                display: true
              },
              type: 'linear',
              beginAtZero: true,
              grace: '10%'
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: value => value // Display the data value
            }
          }
        }
      },
      {
        label: 'report.typeGrouped',
        value: 'grouped',
        type: 'bar',
        icon: <GroupedColumnChart />,
        attributes: ['groupType', 'x', 'group', 'customY', 'customX', 'customGroup'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'x'
          },
          scales: {
            x: {
              stacked: false
            },
            y: {
              stacked: false,
              position: 'left',
              title: {
                display: true
              },
              type: 'linear',
              beginAtZero: true,
              grace: '10%'
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: value => value.y // Display the data value
            }
          }
        }
      },
      {
        label: 'report.typeStacked',
        value: 'stacked',
        type: 'bar',
        icon: <StackedColumnChart />,
        attributes: ['groupType', 'x', 'group', 'customY', 'customX', 'customGroup'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'x',
            reverse: true
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              position: 'left',
              title: {
                display: true
              },
              type: 'linear',
              beginAtZero: true,
              grace: '10%'
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: 'black',
              font: {
                weight: 'bold'
              },
              formatter: (value, ctx) => {
                let datasets = ctx.chart.data.datasets; // Tried `.filter(ds => !ds._meta.hidden);` without success
                if (ctx.datasetIndex === datasets.length - 1) {
                  let sum = 0;
                  datasets.map(dataset => {
                    sum += dataset.data.map(item => item.y)[ctx.dataIndex];
                  });
                  return sum.toLocaleString(/* ... */);
                } else {
                  return '';
                }
              }
            }
          }
        }
      }
      // {
      //   label: 'report.typeMultipleStack',
      //   value: 'multipleStack',
      //   type: 'bar',
      //   icon: <MultipleStackColumnChart />,
      //   attributes: ['groupType', 'x', 'group', 'stack', 'customY', 'customX', 'customGroup', 'customStack'],
      //   options: {
      //     responsive: true,
      //     maintainAspectRatio: false,
      //     interaction: {
      //       mode: 'x'
      //     },
      //     scales: {
      //       x: {
      //         stacked: true
      //       },
      //       y: {
      //         stacked: true,
      //         position: 'left',
      //         title: {
      //           display: true,
      //           text: 'Test Results'
      //         },
      //         type: 'linear',
      //         beginAtZero: true
      //       }
      //     },
      //     plugins: {
      //       legend: {
      //         display: true,
      //         position: 'top'
      //       }
      //     }
      //   },
      // },
      // {
      //   label: 'report.type100Stacked',
      //   value: '100% Stacked',
      //   type: 'bar',
      //   icon: <Icon100StackedColumnChart />,
      //   attributes: ['groupType', 'x', 'customY', 'customX'],
      //   options: {
      //     responsive: true,
      //     maintainAspectRatio: false,
      //     interaction: {
      //       mode: 'x'
      //     },
      //     scales: {
      //       x: {
      //         stacked: true
      //       },
      //       y: {
      //         stacked: true,
      //         position: 'left',
      //         title: {
      //           display: true
      //           // text: 'Test Results'
      //         },
      //         type: 'linear',
      //         beginAtZero: true
      //       }
      //     },
      //     plugins: {
      //       legend: {
      //         display: true,
      //         position: 'top'
      //       }
      //     }
      //   },
      // }
    ]
  },
  // {
  //   label: 'report.typeBar',
  //   value: 'bar',
  //   icon: <HorizontalBarChart />,
  //   children: [
  //     {
  //       label: 'report.typeSingle',
  //       value: 'single',
  //       type: 'bar',
  //       icon: <HorizontalBarChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     },
  //     {
  //       label: 'report.typeGrouped',
  //       value: 'grouped',
  //       type: 'bar',
  //       icon: <HorizontalGroupedBarChart />,
  //       attributes: ['groupType', 'x', 'group', 'stack', 'customY', 'customX', 'customGroup', 'customStack']
  //     },
  //     {
  //       label: 'report.typeStacked',
  //       value: 'stacked',
  //       type: 'bar',
  //       icon: <HorizontalStackedBarChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     },
  //     {
  //       label: 'report.typeMultipleStack',
  //       value: 'multipleStack',
  //       icon: <HorizontalMultipleStackBarChart />,
  //       attributes: ['groupType', 'x', 'group', 'stack', 'customY', 'customX', 'customGroup', 'customStack']
  //     },
  //     {
  //       label: 'report.type100Stacked',
  //       value: '100% Stacked',
  //       icon: <Icon100HorizontalStackedBarChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     }
  //   ]
  // },
  {
    label: 'report.typeLine',
    value: 'line',
    icon: <LineChart />,
    children: [
      {
        label: 'report.typeSingle',
        value: 'single',
        type: 'line',
        icon: <LineChart />,
        attributes: ['groupType', 'x', 'customY', 'customX', 'smoothLine'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'top'
          },
          scales: {
            y: {
              display: true,
              ticks: {
                beginAtZero: true
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: 'white',
              font: {
                weight: 'bold'
              },
              padding: 6,
              formatter: value => value.y // Display the data value
            }
          }
        }
      },
      {
        label: 'report.typeMultiple',
        value: 'multiple',
        type: 'line',
        icon: <MultipleLineChart />,
        attributes: ['groupType', 'x', 'group', 'customY', 'customX', 'customGroup', 'smoothLine'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'top'
          },
          scales: {
            y: {
              display: true,
              ticks: {
                beginAtZero: true
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'top'
            },
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.backgroundColor;
              },
              borderRadius: 4,
              color: 'white',
              font: {
                weight: 'bold'
              },
              padding: 6,
              formatter: value => value.y // Display the data value
            }
          }
        }
      }
    ]
  },
  {
    label: 'report.typeCircular',
    value: 'circular',
    icon: <PieChart2 />,
    children: [
      {
        label: 'report.typePie',
        value: 'pie',
        type: 'pie',
        icon: <PieChart2 />,
        attributes: ['groupType', 'x', 'customY', 'customX'],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: true },
            datalabels: {
              color: 'white',
              font: {
                weight: 'bold'
              },
              padding: 6,
              formatter: value => value // Display the data value
            }
          }
        }
      }
      // {
      //   label: 'report.typeDoughnut',
      //   value: 'doughnut',
      //   type: 'doughnut',
      //   icon: <DoughnutChart />,
      //   attributes: ['groupType', 'x', 'group', 'stack', 'customY', 'customX', 'customGroup', 'customStack'],
      //   options: {
      //     responsive: true,
      //     maintainAspectRatio: false,
      //     cutout: '60%',
      //     plugins: { legend: { display: true } }
      //   },
      // }
    ]
  },
  // ,{
  //   label: 'report.typeArea',
  //   value: 'area',
  //   icon: <AreaChart />,
  //   children: [
  //     {
  //       label: 'report.typeSingle',
  //       value: 'single',
  //       icon: <AreaChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     },
  //     {
  //       label: 'report.typeStacked',
  //       value: 'stacked',
  //       icon: <AreaStackedChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     },
  //     {
  //       label: 'report.type100Stacked',
  //       value: '100% Stacked',
  //       icon: <AreaStackedChart />,
  //       attributes: ['groupType', 'x', 'customY', 'customX']
  //     }
  //   ]
  // },
  // {
  //   label: 'report.typeGrid',
  //   value: 'grid',
  //   icon: <ScatterChart />,
  //   children: [
  //     {
  //       label: 'report.typeScatter',
  //       value: 'scatter',
  //       type: 'scatter',
  //       icon: <ScatterChart />,
  //       attributes: ['groupType', 'x', 'group', 'stack', 'customY', 'customX', 'customGroup', 'customStack'],
  //       options: {
  //         scales: {
  //           y: {
  //             beginAtZero: true
  //           }
  //         }
  //       }
  //     }
  //   ]
  // },
  {
    label: 'report.typeDatatable',
    value: 'datatable',
    type: 'datatable',
    icon: <TableOutlined className="text-primary" />,
    attributes: [],
    options: {},
    data: {},
    children: [
      {
        label: 'report.typeDatatable',
        value: 'datatable',
        type: 'datatable',
        icon: <TableOutlined className="text-primary" />,
        attributes: ['column', 'row', 'groupTable'],
        options: {}
      }
    ]
  }
];

/**
 * Chart time units
 */
export const CHART_TIME_UNITS = [
  { label: 'day', value: 'day' },
  { label: 'week', value: 'week' },
  { label: 'month', value: 'month' },
  { label: 'quarter', value: 'quarter' },
  { label: 'year', value: 'year' }
];

/**
 * Range date selection list
 */
export const RANGE_DATE_SELECTION_1_MONTH = '1-month';
export const RANGE_DATE_SELECTION_LIST = [
  { value: '7-day', label: 'report.7d' },
  { value: '1-month', label: 'report.1m' },
  { value: '3-month', label: 'report.3m' },
  { value: '1-year', label: 'report.1y' }
];

/**
 * Range status selection list
 */
export const RANGE_DATE_SELECTION_DEFAULT = 1;
export const RANGE_STATUS_SELECTION_LIST = [
  { value: 1, label: 'Incremental' },
  { value: 0, label: 'Normal' }
];

/**
 * Report tab type list
 */
export const REPORT_TAB_COMMON = 'common';
export const REPORT_TAB_MY = 'my';

/**
 * Report test case limit list
 */
export const REPORT_TEST_CASE_LIMIT_LIST = [10, 20, 30, 40, 50];

/**
 * Common report list
 */

export const TEST_CASE_EXECUTION_PROGRESS = 'test-case-execution-progress';
export const CHART_BY_STATUS = 'chart-by-status';
export const CHART_BY_USER = 'chart-by-user';
export const CHART_BY_MOST_EXCECUTED_TEST_CASES = 'chart-by-most-executed-testcase';
export const CHART_BY_MOST_FAILED_TEST_CASES = 'chart-by-most-failed-testcase';
export const CHART_BY_PLANNED_VS_EXECUTED = 'chart-by-plan-actual';
export const CHART_TESTING_BURNDOWN = 'chart-testing-burndown';
export const CHART_MEMBER_DAILY_REPORT = 'chart-member-daily-report';
export const COMMON_REPORT_LIST = [
  {
    label: 'report.testCaseExecutionProgress',
    value: TEST_CASE_EXECUTION_PROGRESS
  },
  {
    label: 'report.trendChartByStatus',
    value: CHART_BY_STATUS
  },
  {
    label: 'report.trendChartByUser',
    value: CHART_BY_USER
  },
  {
    label: 'report.mostExecutedTestcases',
    value: CHART_BY_MOST_EXCECUTED_TEST_CASES
  },
  {
    label: 'report.mostFailedTestcases',
    value: CHART_BY_MOST_FAILED_TEST_CASES
  },
  {
    label: 'report.plannedVsExecuted',
    value: CHART_BY_PLANNED_VS_EXECUTED
  },
  {
    label: 'report.testingBurndownChart',
    value: CHART_TESTING_BURNDOWN
  },
  {
    label: 'report.memberDailyReport',
    value: CHART_MEMBER_DAILY_REPORT
  }
];

/**
 * Common automation report list
 */
export const COMMON_AUTOMATION_REPORT_LIST = [
  {
    label: 'report.testCaseExecutionProgress',
    value: 'common-test-case-execution-progress'
  },
  {
    label: 'report.trendChartByStatus',
    value: 'common-trend-chart-by-status'
  },
  {
    label: 'report.mostExecutedTestcases',
    value: 'common-most-executed-test-cases'
  },
  {
    label: 'report.mostFailedTestcases',
    value: 'common-most-failed-test-cases'
  },
  {
    label: 'report.memberDailyReport',
    value: 'common-most-member-daily-report'
  }
];

/**
 * Chart type list
 */
export const CHARTS = [
  {
    title: 'report.basicCharts',
    list: [
      {
        value: 'bar',
        label: 'report.barChart',
        description: 'report.barChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-bar.png`
      },
      {
        value: 'line',
        label: 'report.lineChart',
        description: 'report.lineChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-line.png`
      },
      {
        value: 'doughnut',
        label: 'report.doughnutChart',
        description: 'report.doughnutChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-doughnut.png`
      },
      {
        value: 'pie',
        label: 'report.pieChart',
        description: 'report.pieChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-pie.png`
      },
      {
        value: 'polarArea',
        label: 'report.polarAreaChart',
        description: 'report.polarAreaChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-polar-area.png`
      },
      {
        value: 'radar',
        label: 'report.radarChart',
        description: 'report.radarChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-radar.png`
      }
    ]
  },
  {
    title: 'report.timeSeriesCharts',
    list: [
      {
        value: 'timeSeries',
        label: 'report.timeSeriesChart',
        description: 'report.timeSeriesChartDesctiption',
        picture: `/${env.REACT_APP_PREFIX_PATH}images/default-chart-time-series.png`
      }
    ]
  }
];

/**
 * Chart condition list
 */
export const CHART_CONDITIONS = [
  { value: '~', label: '~', rsqlValue: '==' },
  { value: '>', label: '>', rsqlValue: '>' },
  { value: '<', label: '<', rsqlValue: '<' },
  { value: '=', label: '=', rsqlValue: '==' },
  { value: '!=', label: '!=', rsqlValue: '!=' }
];

/**
 * Device status list
 */
export const DEVICE_STATUS_ACTIVE = 1;
export const DEVICE_STATUS_INACTIVATED = 0;
export const DEVICE_STATUS_LIST = [
  {
    value: DEVICE_STATUS_ACTIVE,
    label: 'common.active',
    className: 'text-success'
  },
  {
    value: DEVICE_STATUS_INACTIVATED,
    label: 'common.inactivate',
    className: 'text-gray'
  }
];

/**
 * List domain
 */
export const LIST_DOMAIN = [
  { val: 'aerospace', label: 'aerospace' },
  { val: 'automotive', label: 'automotive' },
  { val: 'banking', label: 'banking' },
  {
    val: 'engineering and Construction',
    label: 'engineering and Construction'
  },
  { val: 'energy', label: 'energy' },
  { val: 'education', label: 'education' },
  { val: 'finance', label: 'finance' },
  { val: 'hospitality and Leisure', label: 'hospitality and Leisure' },
  { val: 'healthcare', label: 'healthcare' },
  { val: 'insurance', label: 'insurance' },
  { val: 'other', label: 'other' }
];

/**
 * Step jobs
 */
export const TASK_JOB_PREPARING = 'preparing';
export const TASK_JOB_GIT_COMMAND = 'git';
export const TASK_JOB_AZURE = 'azure';
export const TASK_JOB_TEST_MANAGEMENT = 'management';
export const TASK_JOB_RUN_TEST_AUTO = 'engine';
export const TASK_JOB_QTEST = 'qTest';

export const TASK_JOBS = [
  {
    type: TASK_JOB_GIT_COMMAND,
    label: 'job.typeGitCommand'
  },
  {
    type: TASK_JOB_AZURE,
    label: 'job.typeAzure'
  },
  {
    type: TASK_JOB_TEST_MANAGEMENT,
    label: 'job.typeTaskManagement'
  },
  {
    type: TASK_JOB_RUN_TEST_AUTO,
    label: 'job.typeRunDirectPath'
  },
  {
    type: TASK_JOB_QTEST,
    label: 'job.typeQTest'
  },
  {
    type: TASK_JOB_PREPARING,
    label: 'job.preparing'
  }
];

export const TYPE_TASK_GIT_COMMAND_LOGIN = {
  authen: {
    key: 'password',
    title: 'job.username/password'
  },
  token: {
    key: 'token',
    title: 'job.token'
  }
};
/**
 * Type of git command
 */
export const TYPE_GIT_COMMAND_SSH = 'ssh';
export const TYPE_GIT_COMMAND_HTTPS = 'https';
export const TYPE_GIT_COMMAND = [
  {
    label: 'job.ssh',
    value: 'ssh'
  },
  {
    label: 'job.https',
    value: 'https'
  }
];

export const SCHEDULE_JOB_ACCEPT_STRINGS = {
  month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  dayOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
};

export const SCHEDULE_JOB_ADVANCE_NOTES = [
  {
    label: 'job.minute',
    note: 'job.noteMinute',
    min: 0,
    max: 59,
    pattern:
      /^(\*|\*\/[1-9][0-9]*|([0-9]|[1-5][0-9])(-([0-9]|[1-5][0-9]))?(,([0-9]|[1-5][0-9])(-([0-9]|[1-5][0-9]))?)*(\/[1-9][0-9]*)?)$/
  },
  {
    label: 'job.hour',
    note: 'job.noteHour',
    min: 0,
    max: 23,
    pattern:
      /^(\*|\*\/[1-9][0-9]*|([0-9]|1[0-9]|2[0-3])(-([0-9]|1[0-9]|2[0-3]))?(,([0-9]|1[0-9]|2[0-3])(-([0-9]|1[0-9]|2[0-3]))?)*(\/[1-9][0-9]*)?)$/
  },
  {
    label: 'job.dayOfMonth',
    note: 'job.noteDayOfMonth',
    min: 1,
    max: 31,
    pattern:
      /^(\*|\*\/[1-9][0-9]*|([1-9]|[12][0-9]|3[01])(-([1-9]|[12][0-9]|3[01]))?(,([1-9]|[12][0-9]|3[01])(-([1-9]|[12][0-9]|3[01]))?)*(\/[1-9][0-9]*)?)$/
  },
  {
    label: 'job.month',
    note: 'job.noteMonth',
    min: 1,
    max: 12,
    pattern:
      /^(\*|\*\/[1-9][0-9]*|([1-9]|1[0-2])(-([1-9]|1[0-2]))?(,([1-9]|1[0-2])(-([1-9]|1[0-2]))?)*(\/[1-9][0-9]*)?)$/,
    acceptString: SCHEDULE_JOB_ACCEPT_STRINGS.month
  },
  {
    label: 'job.dayOfWeek',
    note: 'job.noteDayOfWeek',
    min: 0,
    max: 7,
    pattern: /^(\*|\*\/[1-9][0-9]*|([0-7])(-([0-7]))?(,([0-7])(-([0-7]))?)*(\/[1-9][0-9]*)?)$/,
    acceptString: SCHEDULE_JOB_ACCEPT_STRINGS.dayOfWeek
  }
];

/**
 * Tab job runner detail
 */
export const JOB_RUNNER_DETAIL_TAB_TASK = 'task';
export const JOB_RUNNER_DETAIL_TAB_TEST_RESULT = 'test_result';

/**
 * Integration platform
 */
export const TESTMAN_PLATFORM_ID = 'manager';
export const JIRA_PLATFORM_ID = 'jira';
export const EXTERNAL_ID = 'external';
export const AZURE_DEVOPS_PLATFORM_ID = 'azure';
export const INTEGRATION_DEFECT_SYSTEM = {
  [TESTMAN_PLATFORM_ID]: {
    id: TESTMAN_PLATFORM_ID,
    title: 'TestMan',
    icon: <TestManLogo />
  },
  [JIRA_PLATFORM_ID]: {
    id: JIRA_PLATFORM_ID,
    title: 'Jira',
    icon: <JiraAtlassian />
  }
  // [AZURE_DEVOPS_PLATFORM_ID]: {
  //   id: AZURE_DEVOPS_PLATFORM_ID,
  //   title: 'Azure DevOps',
  //   icon: <AzureDevOps />
  // }
};

/**
 * Integration: From module
 */
export const INTEGRATION_FROM_REPOSITORY_FOLDER = 'REPOSITORY_FOLDER';
export const INTEGRATION_FROM_TEST_PLAN_FOLDER = 'TEST_PLAN_FOLDER';

/**
 * Integration system
 */
export const INTEGRATION_SYSTEM = [
  {
    id: JIRA_PLATFORM_ID,
    title: 'Jira',
    icon: <JiraAtlassian />
  },
  {
    id: AZURE_DEVOPS_PLATFORM_ID,
    title: 'Azure DevOps',
    icon: <AzureDevOps />
  }
];

/**
 * Work item
 */
export const WORK_TYPE_TYPE_WORK_TICKET = 'work-ticket';
export const WORK_TYPE_TYPE_TEST_TICKET = 'test-ticket';
export const WORK_ITEM_TESTCASE_ID = 't3-test-case';
export const WORK_ITEM_TEST_RUN_ID = 't3-test-run';
export const WORK_ITEM_TEST_RESULT_ID = 't3-test-result';
export const WORK_ITEM_DEFECT_ID = 'wt2-defect';
export const WORK_ITEM_TYPE = [
  {
    id: WORK_ITEM_DEFECT_ID,
    name: 'Defect',
    refName: 'system_wi_defect',
    icon: 'Defect',
    _id: '632be73bda757b65f293465c'
  }
];

/**
 * From module
 */
export const FROM_MODULE_TEST_PLAN = 'testPlan';
export const FROM_MODULE_TEST_REPO = 'testRepo';
export const FROM_MODULE_TEST_DATA = 'testData';
export const FROM_MODULE_WORK_TICKET = 'workTicket';

/**
 * Related type
 */
export const RELATED_TYPE_SYSTEM_DEFECT = 'relatedtype_system_defect';
export const RELATED_TYPE_SYSTEM_TEST_FOR = 'relatedtype_system_testsFor';
export const RELATED_TYPE_SYSTEM_TEST_BY = 'relatedtype_system_testedBy';

/**
 * Work item test run statuses
 */
export const WORK_ITEM_TEST_RUN_STATUS_EXECUTING = 'system.wi.state.3f5f';
export const WORK_ITEM_TEST_RUN_STATUS_COMPLETED = 'system.wi.state.f835';
export const WORK_ITEM_TEST_RUN_STATUS_INCOMPLETED = 'system.wi.state.80dc';

/**
 * Work item test result statuses
 */
export const WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED = 'system.wi.state.bad8';
export const WORK_ITEM_TEST_RESULT_STATUS_PASS = 'system.wi.state.65c7';
export const WORK_ITEM_TEST_RESULT_STATUS_FAIL = 'system.wi.state.cea6';
export const WORK_ITEM_TEST_RESULT_STATUS_WIP = 'system.wi.state.5d33';
export const WORK_ITEM_TEST_RESULT_STATUS_BLOCKED = 'system.wi.state.9243';

/**
 * Default work item icons
 */
export const DEFAULT_WORK_ITEM_ICONS = [
  { label: 'Bug', value: 'Bug', icon: <Bug /> },
  { label: 'Requirement', value: 'Requirement', icon: <Requirement /> },
  { label: 'Defect', value: 'Defect', icon: <Defect /> },
  { label: 'Epic', value: 'Epic', icon: <Epic /> },
  { label: 'Feature', value: 'Feature', icon: <Feature /> },
  { label: 'Task', value: 'Task', icon: <Task /> },
  { label: 'Test Case', value: 'TestCase', icon: <TestCase /> },
  { label: 'Test Result', value: 'TestResult', icon: <TestResult /> },
  { label: 'Test Execution', value: 'TestRun', icon: <TestRun /> },
  { label: 'User Story', value: 'UserStory', icon: <UserStory /> },
  { label: 'Issue', value: 'Issue', icon: <Issue /> },
  { label: 'Change Request', value: 'ChangeRequest', icon: <ChangeRequest /> },
  { label: 'Risk', value: 'Risk', icon: <Risk /> }
];

export const OPTION_DELETE = 'delete';
export const OPTION_EXPAND_SELETED_WORKITEM = [
  {
    value: OPTION_DELETE,
    label: 'common.delete'
  }
];

/**
 * Notification events
 */
export const ENGINE_NOTIFICATION_EVENT = {
  COMPLETE: 'eng_complete',
  ERROR: 'eng_error',
  START: 'eng_start',
  FAIL: 'eng_fail',
  PASS: 'eng_pass'
};

/**
 * Notification channels
 */
export const NOTIFICATION_CHANNEL_MAIL = 'mail';
export const NOTIFICATION_CHANNEL_WEBHOOK = 'webhook';
export const NOTIFICATION_CHANNEL_SLACK = 'slack';
export const NOTIFICATION_CHANNEL_MICROSOFT_TEAMS = 'microsoftTeams';
export const NOTIFICATION_CHANNEL_ZALO = 'zalo';
export const NOTIFICATION_CHANNELS = [
  {
    value: NOTIFICATION_CHANNEL_MAIL,
    label: 'notification.mail',
    icon: <Mail style={{ color: '#157efb' }} />
  },
  {
    value: NOTIFICATION_CHANNEL_WEBHOOK,
    label: 'notification.webhook',
    icon: <Webhook />
  },
  {
    value: NOTIFICATION_CHANNEL_SLACK,
    label: 'notification.slack',
    icon: <Slack />
  },
  {
    value: NOTIFICATION_CHANNEL_MICROSOFT_TEAMS,
    label: 'notification.microsoftTeams',
    icon: <MicrosoftTeams />
  },
  {
    value: NOTIFICATION_CHANNEL_ZALO,
    label: 'notification.zalo',
    icon: <Zalo />
  }
];

/**
 * Notification channel statuses
 */
export const NOTIFICATION_CHANNEL_ACTIVE = 1;
export const NOTIFICATION_CHANNEL_INACTIVATED = 0;

/**
 * Notification channel email to
 */
export const NOTIFICATION_CHANNEL_EMAIL_TO_PEOPLE = 'people';
export const NOTIFICATION_CHANNEL_EMAIL_TO_PROJECT = 'project';
export const NOTIFICATION_CHANNEL_EMAIL_TO_CUSTOM = 'custom';
export const NOTIFICATION_CHANNEL_EMAIL_TO = [
  {
    value: NOTIFICATION_CHANNEL_EMAIL_TO_PEOPLE,
    label: 'notification.peopleFields'
  },
  {
    value: NOTIFICATION_CHANNEL_EMAIL_TO_PROJECT,
    label: 'notification.projectMembers'
  },
  {
    value: NOTIFICATION_CHANNEL_EMAIL_TO_CUSTOM,
    label: 'notification.customEmailAddress'
  }
];

/**
 * Notification channel email to: people group
 */
export const NOTIFICATION_CHANNEL_EMAIL_TO_GROUP = [
  {
    value: 'job-creator',
    label: 'notification.jobCreator'
  },
  {
    value: 'last-job-updater',
    label: 'notification.lastJobUpdater'
  }
];

/**
 * Jira custom field data types
 */
export const JIRA_DATA_TYPE_ARRAY = 'array';
export const JIRA_DATA_TYPE_OPTION = 'option';
export const JIRA_DATA_TYPE_STRING = 'string';
export const JIRA_DATA_TYPE_TEXT = 'text';
export const JIRA_DATA_TYPE_DATE = 'date';
export const JIRA_DATA_TYPE_DATE_TIME = 'datetime';
export const JIRA_DATA_TYPE_NUMBER = 'number';
export const JIRA_DATA_TYPE_USER = 'user';
export const JIRA_DATA_TYPE_PRIORITY = 'priority';
export const JIRA_DATA_TYPE_TIMETRACKING = 'timetracking';
export const JIRA_DATA_TYPE_ATTACHMENT = 'attachment';
export const JIRA_DATA_TYPE_ANY = 'any';
export const JIRA_DATA_TYPE_ISSUELINKS = 'issuelinks';
export const JIRA_DATA_TYPE_ISSUELINK_TYPES_ID = 'issueLinkTypes';
export const JIRA_DATA_TYPE_SPRINT_ID = 'customfield_10100';
export const JIRA_DATA_EPIC_LINK_ID = 'customfield_10101';
export const JIRA_DATA_PRODUCT_ID = 'customfield_10220';
export const JIRA_DATA_TYPE_LABELS = 'labels';

/**
 * Type body jira create issue
 */
export const JIRA_TYPE_BODY_STRING = 'string';
export const JIRA_TYPE_BODY_OBJECT_ID = 'object.id';
export const JIRA_TYPE_BODY_OBJECT_KEY = 'object.key';
export const JIRA_TYPE_BODY_ARRAY_ID = 'array.id';
export const JIRA_TYPE_BODY_ARRAY = 'array';
export const JIRA_TYPE_BODY_RAW_ARRAY = 'raw_array';
export const JIRA_TYPE_BODY_DATE = 'date';
export const JIRA_TYPE_BODY_USER = 'user';
export const JIRA_TYPE_BODY_HTML = 'html';
export const JIRA_TYPE_BODY_OPTION = 'option';
export const JIRA_TYPE_BODY_ISSUELINKS = 'issuelinks';
export const JIRA_TYPE_BODY_ISSUELINK = 'issuelink';
export const JIRA_TYPE_BODY_PRIORITY = 'priority';

export const JIRA_FIELD_TYPE_DEFAULT = {
  key: JIRA_TYPE_BODY_STRING,
  summary: JIRA_TYPE_BODY_STRING,
  project: JIRA_TYPE_BODY_OBJECT_ID,
  issuetype: JIRA_TYPE_BODY_OBJECT_ID,
  parent: JIRA_TYPE_BODY_OBJECT_KEY,
  components: JIRA_TYPE_BODY_ARRAY_ID,
  description: JIRA_TYPE_BODY_HTML,
  reporter: JIRA_TYPE_BODY_USER,
  fixVersions: JIRA_TYPE_BODY_ARRAY_ID,
  priority: JIRA_TYPE_BODY_OBJECT_ID,
  labels: JIRA_TYPE_BODY_ARRAY,
  timetracking: JIRA_TYPE_BODY_STRING,
  security: JIRA_TYPE_BODY_OBJECT_ID,
  environment: JIRA_TYPE_BODY_STRING,
  versions: JIRA_TYPE_BODY_ARRAY_ID,
  duedate: JIRA_TYPE_BODY_DATE,
  assignee: JIRA_TYPE_BODY_USER,
  issuelinks: JIRA_TYPE_BODY_ISSUELINKS,
  [JIRA_TYPE_BODY_RAW_ARRAY]: JIRA_TYPE_BODY_RAW_ARRAY,
  [JIRA_DATA_TYPE_LABELS]: JIRA_TYPE_BODY_RAW_ARRAY
  // [JIRA_DATA_TYPE_SPRINT_ID]: JIRA_TYPE_BODY_OBJECT_ID,
  // [JIRA_DATA_EPIC_LINK_ID]: JIRA_TYPE_BODY_OBJECT_KEY,
  // [JIRA_DATA_PRODUCT_ID]: JIRA_TYPE_BODY_OBJECT_KEY
};

export const JIRA_TYPE_CLOUD = 'cloud';
export const JIRA_TYPE_ON_PREMISE = 'on-premise';
export const JIRA_TYPES = [
  {
    label: 'Jira cloud',
    value: JIRA_TYPE_CLOUD
  },
  {
    label: 'Jira on-premise',
    value: JIRA_TYPE_ON_PREMISE
  }
];

export const JIRA_DEFAULT_AUTH_BASIC = 'basic';
export const JIRA_DEFAULT_AUTH_BEARER = 'bearer';
export const JIRA_DEFAULT_AUTH_PASSWORD = 'password';

export const JIRA_AUTH_TYPES = [
  {
    label: 'Basic token',
    value: JIRA_DEFAULT_AUTH_BASIC,
    type: JIRA_TYPE_CLOUD
  },
  {
    label: 'Bearer token',
    value: JIRA_DEFAULT_AUTH_BEARER,
    type: JIRA_TYPE_ON_PREMISE
  },
  {
    label: 'Username/Password',
    value: JIRA_DEFAULT_AUTH_PASSWORD,
    type: JIRA_TYPE_ON_PREMISE
  }
];

/**
 * Work item field type
 */
export const FIELD_SYSTEM = 'system';
export const FIELD_COMMON = 'common';
export const FIELD_CUSTOM = 'custom';
export const FIELD_PARENT = 'parent';

export const USER_DATA = {
  fieldLabel: 'name',
  fieldValue: 'id'
};

/**
 * List history type
 */
export const HISTORY_TYPE_CREATE = 'create';
export const HISTORY_TYPE_UPDATE = 'update';
export const HISTORY_TYPE_UPDATE_STATUS = 'update-status';
export const HISTORY_TYPE_LIST = {
  [HISTORY_TYPE_CREATE]: 'created',
  [HISTORY_TYPE_UPDATE]: 'updated',
  [HISTORY_TYPE_UPDATE_STATUS]: 'updated'
};

/**
 * List relatedto type
 */

export const RELATED_TYPE_LINKS_TO_ID = '633ea41978bb22058000d3b9';
export const RELATED_TYPE_DEFECT_ID = '63c61d5623119158a5fa32a8';
export const TESTFLOW_EXTENSION_ID = '89082d4c-bfd1-41c0-91d1-7860f7f9fdfe';

export const RELATED_TYPE_LINKS_TO = 'relatedtype_system_link';
export const RELATED_TYPE_BLOCKED_BY = 'relatedtype_system_block';
export const RELATED_TYPE_DUPLICATED_OF = 'relatedtype_system_duplicateOf';
export const RELATED_TYPE_DUPLICATE_TO = 'relatedtype_system_duplicatesTo';
export const RELATED_TYPE_PARENT_OF = 'relatedtype_system_parent';
export const RELATED_TYPE_CHILD_OF = 'relatedtype_system_child';
export const RELATED_TYPE_DEPEND_ON = 'relatedtype_system_depend';
export const RELATED_TYPE_EFFECT_BY = 'relatedtype_system_effect';
export const RELATED_TYPE_TESTS_FOR = 'relatedtype_system_testsFor';
export const RELATED_TYPE_TESTED_BY = 'relatedtype_system_testedBy';

export const RELATED_TYPE_LIST = [
  {
    value: RELATED_TYPE_LINKS_TO,
    label: 'relationTo.linksTo',
    img: relatedToLinksTo
  },
  {
    value: RELATED_TYPE_BLOCKED_BY,
    label: 'relationTo.blockedBy',
    img: relatedToBlockedBy
  },
  {
    value: RELATED_TYPE_DUPLICATED_OF,
    label: 'relationTo.duplicatedOf',
    img: relatedToDuplicate
  },
  {
    value: RELATED_TYPE_DUPLICATE_TO,
    label: 'relationTo.duplicateTo',
    img: relatedToDuplicate
  },
  {
    value: RELATED_TYPE_PARENT_OF,
    label: 'relationTo.parentOf',
    img: relatedToParentChild
  },
  {
    value: RELATED_TYPE_CHILD_OF,
    label: 'relationTo.childOf',
    img: relatedToParentChild
  },
  {
    value: RELATED_TYPE_DEPEND_ON,
    label: 'relationTo.dependOn',
    img: relatedToDependOnEffectBy
  },
  {
    value: RELATED_TYPE_EFFECT_BY,
    label: 'relationTo.effectBy',
    img: relatedToDependOnEffectBy
  },

  {
    value: RELATED_TYPE_TESTS_FOR,
    label: 'relationTo.testsFor',
    img: relatedToTestsForTestedBy
  },
  {
    value: RELATED_TYPE_TESTED_BY,
    label: 'relationTo.testedBy',
    img: relatedToTestsForTestedBy
  }
];

/**
 * Component type
 */
export const COMPONENT_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  PICKLIST: 'picklist',
  OPTION: 'option',
  USER: 'user',
  DATE: 'date',
  DATE_TIME: 'datetime',
  HTML: 'HTML',
  SUGGESTION: 'suggestion',
  STATUS: 'status',
  URL: 'url',
  RELATION: 'relation',
  TIME_TRACKING: 'timetracking',
  ATTACHMENTS: 'attachments',
  TEST_STEPS: 'testSteps',
  TEST_PLANNING: 'testPlanning',
  FOLDER: 'folder',
  PRIORITY: 'priority'
};

/**
 * Component type list
 */
export const COMPONENT_TYPE_LIST = [
  {
    icon: TextboxIcon,
    title: 'Text Box',
    value: COMPONENT_TYPE.STRING
  },
  {
    icon: NumberIcon,
    title: 'Number',
    value: COMPONENT_TYPE.NUMBER
  },
  {
    icon: PeopleIcon,
    title: 'People',
    value: COMPONENT_TYPE.USER
  },
  {
    icon: DateIcon,
    title: 'Date',
    value: COMPONENT_TYPE.DATE
  },
  {
    icon: DateTimeIcon,
    title: 'Date Time',
    value: COMPONENT_TYPE.DATE_TIME
  },
  {
    icon: UrlIcon,
    title: 'URL',
    value: COMPONENT_TYPE.URL
  },
  {
    icon: PicklistIcon,
    title: 'Pick List',
    value: COMPONENT_TYPE.PICKLIST
  },
  {
    icon: CheckboxIcon,
    title: 'Option',
    value: COMPONENT_TYPE.OPTION
  },
  {
    icon: HtmlIcon,
    title: 'HTML',
    value: COMPONENT_TYPE.HTML
  },
  {
    icon: RelationIcon,
    title: 'Relation',
    value: COMPONENT_TYPE.RELATION
  },
  {
    icon: InfoCircle,
    title: 'Status',
    value: COMPONENT_TYPE.STATUS
  },
  {
    icon: CheckboxIcon,
    title: 'Priority',
    value: COMPONENT_TYPE.PRIORITY
  },
  {
    icon: FolderOutlined,
    title: 'Folder',
    value: COMPONENT_TYPE.FOLDER
  },
  {
    icon: ClockCircleOutlined,
    title: 'Time Tracking',
    value: COMPONENT_TYPE.TIME_TRACKING
  }
];

/**
 * List field system
 */
export const SYSTEM_FIELD_DESCRIPTION = 'description';
export const SYSTEM_FIELD_CREATED_AT = 'createdAt';
export const SYSTEM_FIELD_UPDATED_AT = 'updatedAt';
export const SYSTEM_FIELD_CREATED_BY = 'createdBy';
export const SYSTEM_FIELD_UPDATED_BY = 'updatedBy';
export const SYSTEM_FIELD_NAME = 'name';
export const SYSTEM_FIELD_KEY = 'key';
export const SYSTEM_FIELD_PRIORITY = 'priority';
export const SYSTEM_FIELD_STATUS = 'status';
export const SYSTEM_FIELD_LATEST_RESULT = 'latestResult';
export const SYSTEM_FIELD_LATEST_RESULT_STATUS = 'latestResult.status';
export const SYSTEM_FIELD_ESTIMATEDTIME = 'estimatedTime';
export const SYSTEM_FIELD_ASSIGN_TO = 'assignTo';
export const SYSTEM_FIELD_ORDER_ID = 'orderId';
export const SYSTEM_FIELD_TAG = 'tag';
export const SYSTEM_FIELD_VERSION = 'version';
export const SYSTEM_FIELD_EXECUTED_AT = 'executedAt';
export const SYSTEM_FIELD_EXECUTED_BY = 'executedBy';
export const SYSTEM_FIELD_SCRIPT_PATH = 'scriptPath';
export const SYSTEM_FIELD_RELEASE = 'release';
export const SYSTEM_FIELD_CYCLE = 'cycle';
export const SYSTEM_FIELD_TEST_SUITE = 'testSuite';
export const SYSTEM_FIELD_TEST_PLANNING = 'testPlanning';
export const SYSTEM_FIELD_TEST_RUN = 'testRun';
export const SYSTEM_FIELD_TEST_CASE = 'testcase';
export const SYSTEM_FIELD_TEST_CASE_VERSION = 'testCaseVersion';
export const SYSTEM_FIELD_TEST_CONFIG = 'testConfig';
export const SYSTEM_FIELD_RUN_TYPE = 'runType';
export const SYSTEM_FIELD_TEST_STEPS = 'testSteps';
export const SYSTEM_FIELD_TEST_STEP_RESULT = 'testStepResult';
export const SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY = 'testCaseVersion.externalKey';
export const SYSTEM_FIELD_COMMENT = 'comment';
export const SYSTEM_FIELD_REFERENCE_FOLDER = 'references.folderId';
export const SYSTEM_FIELD_PATH = 'path';
export const SYSTEM_FIELD_EXTERNAL_KEY = 'externalKey';
export const SYSTEM_FIELD_ATTACHMENTS = 'attachments';
export const SYSTEM_FIELD_AUTOMATION_STATUS = 'automationStatus';
export const SYSTEM_FIELD_TEST_ENVIRONMENT = 'testenvironment';

export const PARENT_FIELD_TEST_ROUND = 'testRound';

// For test steps
export const FIELD_STEP = 'step';
export const FIELD_TEST_PRECONDITION = 'precondition';
export const FIELD_TEST_DATA = 'testData';
export const FIELD_EXPECTED_RESULT = 'expectedResult';
export const FIELD_DURATION = 'duration';
export const FIELD_ATTACHMENTS = 'attachments';
export const FIELD_STEP_RESULT = 'stepResult';
export const FIELD_TEST_STEP_RAW = 'testStepRaw';
export const FIELD_TEST_STEP_ERRORS = 'testStepErrors';

/**
 * Folder types
 */
export const FOLDER_TYPES = [
  SYSTEM_FIELD_RELEASE,
  SYSTEM_FIELD_CYCLE,
  SYSTEM_FIELD_TEST_SUITE,
  SYSTEM_FIELD_REFERENCE_FOLDER
];

/**
 * Data type
 */
export const DATA_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
  USER: 'user',
  STATUS: 'status',
  ARRAY: 'array'
};

/**
 * Collection name
 */
export const WORK_TICKET_COLLECTION = 'work-ticket';
export const TESTCASE_COLLECTION = 'test-cases';
export const TEST_RUN_COLLECTION = 'test-runs';

/**
 * Test step field list
 */
export const TEST_STEP_FIELD_LIST = [
  {
    name: 'workItem.id',
    refName: SYSTEM_FIELD_ORDER_ID,
    dataType: DATA_TYPE.STRING,
    componentType: COMPONENT_TYPE.STRING,
    mandatory: true,
    isInsert: false,
    isTestStepField: true
  },
  {
    name: 'workItem.step',
    refName: FIELD_STEP,
    dataType: DATA_TYPE.STRING,
    componentType: COMPONENT_TYPE.HTML,
    mandatory: true,
    isInsert: true,
    isTestStepField: true
  },
  {
    name: 'workItem.precondition',
    refName: FIELD_TEST_PRECONDITION,
    dataType: DATA_TYPE.STRING,
    componentType: COMPONENT_TYPE.HTML,
    mandatory: false,
    isInsert: true,
    isTestStepField: true
  },
  {
    name: 'workItem.testData',
    refName: FIELD_TEST_DATA,
    dataType: DATA_TYPE.STRING,
    componentType: COMPONENT_TYPE.HTML,
    mandatory: false,
    isInsert: true,
    isTestStepField: true
  },
  {
    name: 'workItem.expectedResult',
    refName: FIELD_EXPECTED_RESULT,
    dataType: DATA_TYPE.STRING,
    componentType: COMPONENT_TYPE.HTML,
    mandatory: false,
    isInsert: true,
    isTestStepField: true
  }
];

export const DEFAULT_SEPARATE_TEST_STEP = 'newline_character';
export const SEPARATE_TEST_STEP_RULE = [
  {
    key: 'newline_character',
    label: 'Newline Character',
    regex: /\n/g,
    description: `In an Excel file, within a test case, each test step must be divided into separate rows, Ex:
    Step 1
    Step 2
    Step 3
    `
  },
  {
    key: 'numbered_list_markers',
    label: 'Numbered List Markers',
    regex: /^[0-9]+./,
    description: `In an Excel file, within a test case, multiple test steps can be merged into one row and must be presented in format where order number is followed by a period, Ex:
      1. Step 1
      2. Step 2
      3. Step 3
      `
  },
  {
    key: 'outline_numbered_list_markers',
    label: 'Outline numbered list markers',
    regex: /^\b[IVXLCDM]+\b\./gm,
    description: `In an Excel file, within a test case, multiple test steps can be merged into one row and must be presented in format of Roman numeral order followed by a period
      I. Step 1
      II. Step 2
      III. Step 3
      `
  }
];
/**
 * Run type list
 */
export const RUN_TYPES = [
  { value: 'manual', label: 'Manual' },
  { value: 'automation', label: 'Automation' }
];

export const CUSTOM_EXPORT_FIELD_TEST_RUN = [
  {
    name: 'testResult',
    refName: 'testResult',
    description: 'Test result',
    dataType: 'collection',
    componentType: 'collection',
    active: true,
    supportedOperations: [],
    isSearch: false,
    isDefaultSearch: false,
    mandatory: false,
    isInsert: false,
    datatable: {
      available: false,
      default: false,
      sortable: false
    },
    export: {
      exportable: true,
      default: false
    },
    import: { importable: false, default: false },
    data: {},
    type: 'system'
  }
];

/**
 * License statuses
 */
export const LICENSE_STATUSES = [
  {
    value: 'active',
    name: 'common.active',
    color: { r: 255, g: 255, b: 255 },
    background: { r: 3, g: 96, b: 40 }
  },
  {
    value: 'inactive',
    name: 'common.waiting',
    color: { r: 255, g: 255, b: 255 },
    background: { r: 188, g: 191, b: 17 }
  },
  {
    value: 'expired',
    name: 'common.expired',
    color: { r: 255, g: 255, b: 255 },
    background: { r: 160, g: 15, b: 15 }
  }
];

/**
 * auto statuses
 */
export const AUTOMATION_STATUS = {
  READY: {
    name: 'READY',
    type: 'READY',
    background: { r: 234, g: 251, b: 246, a: 1 },
    color: { r: 4, g: 153, b: 104 }
  },
  'NOT-READY': {
    name: 'NOT-READY',
    type: 'NOT-READY',
    background: { r: 255, g: 224, b: 224, a: 1 },
    color: { r: 213, g: 46, b: 32, a: 1 }
  }
};

export const TAB_EXPORT = 'export';
export const TAB_IMPORT = 'import';

/**
 * Mapping related type
 */
export const TICKET_WITH_RELATED_TYPE = {
  // 'wt2-defect': ['relatedtype_system_defect']
};

export const INTEGRATION_SYSTEM_WITH_RELATED_TYPE = {
  [JIRA_PLATFORM_ID]: ['relatedtype_system_link']
};

export const REST_API_STATUS_CODE = {
  conflic: 409,
  success: 200
};

export const TRACEABILITY_TYPE = {
  MATRIX: 'Matrix',
  BROWSER: 'Browser'
};

export const TEST_SUITE_TYPE = {
  BLANK: 1,
  REPOSITORY_FOLDER: 2,
  TEST_CASE: 3,
  PARENT: 4
};

export const METRIC_TYPE = {
  MANAGE_TEST: 'Manage Tests',
  MANAGE_WORKS: 'Manage Works',
  MANAGE_MEMBERS: 'Manage Members',
  MANAGE_BROWSER: 'Browser'
};

export const EDIT_TRACEABILITY = 'edit';
export const CLONE_TRACEABILITY = 'clone';
export const VIEW_TRACEABILITY = 'view';

export const JIRA_COLOR_MAPPING = {
  'jira-issue-status-uncategorized-color': '#172b4d',
  'jira-issue-status-uncategorized-bgcolor': '#ebecf0',
  'jira-issue-status-hover-uncategorized-color': '#172b4d',
  'jira-issue-status-hover-uncategorized-bgcolor': '#dfe1e5',
  'jira-issue-status-default-color': '#172b4d',
  'jira-issue-status-default-bgcolor': '#dfe1e5',
  'jira-issue-status-hover-default-color': '#172b4d',
  'jira-issue-status-hover-default-bgcolor': '#c1c7d0',
  'jira-issue-status-inprogress-color': '#fff',
  'jira-issue-status-inprogress-bgcolor': '#0052cc',
  'jira-issue-status-hover-inprogress-color': '#fff',
  'jira-issue-status-hover-inprogress-bgcolor': '#0747a6',
  'jira-issue-status-success-color': '#fff',
  'jira-issue-status-success-bgcolor': ' #00875a',
  'jira-issue-status-hover-success-color': ' #fff',
  'jira-issue-status-hover-success-bgcolor': '#064'
};

/**
 * Agent type list
 */
export const AGENT_TYPES = {
  ENGINE: 'engine',
  TESTMAN: 'testman'
};

/**
 * Monaco mode
 */
export const MONACO_MODE_VIEW = 'view-mode';
export const MONACO_MODE_EDITABLE = 'editable-mode';
export const MONACO_MODE = [
  { value: MONACO_MODE_VIEW, label: 'process.viewMode', icon: <EyeOutlined className="mr-1" /> },
  { value: MONACO_MODE_EDITABLE, label: 'process.editorMode', icon: <EditableMode className="mr-1" /> }
];

/**
 * Test execution dropdown statuses (On execution modal)
 */
export const TEST_EXECUTION_DROPDOWN_STATUS_ALL = 'ALL';
export const TEST_EXECUTION_DROPDOWN_STATUS_INCOMPLETE = 'INCOMPLETE';
