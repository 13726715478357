import { action, thunk } from 'easy-peasy';
import qs from 'qs';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../common';

const licenses = {
  /**
   * State
   */
  licenseData: null,
  customerData: null,
  loadingLicenseData: false,
  loadingOrderLicense: false,

  /**
   * Action: Set license data
   */
  setLicenseData: action((state, payload) => {
    if (state?.licenseData === undefined) return;

    if (!payload) {
      state.licenseData = null;
      return;
    }

    state.licenseData = payload;
  }),

  /**
   * Action: Set license data
   */
  setCustomerData: action((state, payload) => {
    if (state?.customerData === undefined) return;

    if (!payload) {
      state.customerData = null;
      return;
    }

    state.customerData = payload;
  }),

  /**
   * Action: Set loading license data
   */
  setLoadingLicenseData: action((state, payload) => {
    if (state?.loadingLicenseData === undefined) return;

    state.loadingLicenseData = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingOrderLicense: action((state, payload) => {
    if (state?.loadingOrderLicense === undefined) return;

    state.loadingOrderLicense = payload;
  }),

  /**
   * Action: Call api to get license data
   */
  getLicenseData: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingLicenseData(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.LICENSE_MANAGEMENT}${ENDPOINTS.TENANT}/${globalTenant?.tenantKey}${ENDPOINTS.LICENSES}`;
      const res = await Http.get(url).then(res => res.data);

      action.setLicenseData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingLicenseData(false);
    }
  }),

  getCustomerById: thunk(async (action, payload) => {
    try {
      action.setLoadingOrderLicense(true);

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.LICENSE_MANAGEMENT}/customers/${payload.customerId}`;
      const res = await Http.get(url).then(res => res.data);

      action.setCustomerData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingOrderLicense(false);
    }
  })
};

const requestOrderLicense = {
  /**
   * State
   */
  loadingSendRequestOrderLicense: false,

  /**
   * Action: Set loading send request order license
   */
  setLoadingSendRequestOrderLicense: action((state, payload) => {
    if (state?.loadingSendRequestOrderLicense === undefined) return;

    state.loadingSendRequestOrderLicense = payload;
  }),

  /**
   * Action: Call api to send request order license
   */
  sendRequestOrderLicense: thunk(async (action, payload) => {
    try {
      action.setLoadingSendRequestOrderLicense(true);

      const url = `${ENDPOINTS.EVENT_EMAIL_SEND}`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSendRequestOrderLicense(false);
    }
  })
};

const products = {
  /**
   * State
   */
  productList: null,
  loadingProductList: false,

  /**
   * Action: Set product list
   */
  setProductList: action((state, payload) => {
    if (state?.productList === undefined) return;

    if (!(Array.isArray(payload?.data) && payload?.data.length)) {
      state.productList = null;
      return;
    }

    state.productList = payload.data;
  }),

  /**
   * Action: Set loading product list
   */
  setLoadingProductList: action((state, payload) => {
    if (state?.loadingProductList === undefined) return;

    state.loadingProductList = payload;
  }),

  /**
   * Action: Call api to get product list
   */
  getProductList: thunk(async (action, payload) => {
    try {
      action.setLoadingProductList(true);

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.LICENSE_MANAGEMENT}${
        ENDPOINTS.PRODUCTS
      }?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setProductList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProductList(false);
    }
  })
};

const billHistory = {
  /**
   * State
   */
  billHistoryList: null,
  loadingBillHistoryList: false,

  /**
   * Action: Set bill history list
   */
  setBillHistoryList: action((state, payload) => {
    if (state?.billHistoryList === undefined) return;

    if (!(Array.isArray(payload) && payload.length)) {
      state.billHistoryList = null;
      return;
    }

    state.billHistoryList = payload;
  }),

  /**
   * Action: Set loading bill history list
   */
  setLoadingBillHistoryList: action((state, payload) => {
    if (state?.loadingBillHistoryList === undefined) return;

    state.loadingBillHistoryList = payload;
  }),

  /**
   * Action: Call api to get bill history list
   */
  getBillHistoryList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingBillHistoryList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.LICENSE_MANAGEMENT}${ENDPOINTS.TENANT}/${globalTenant?.tenantKey}${ENDPOINTS.BILL_HISTORY}`;
      const res = await Http.get(url).then(res => res.data);

      action.setBillHistoryList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingBillHistoryList(false);
    }
  })
};

export const model = {
  ...licenses,
  ...requestOrderLicense,
  ...products,
  ...billHistory
};
