import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import { Button, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { checkValidRGBObject } from '../../common';

export const ColorPickerButton = ({ rgb, isReadOnly, className = '', children, onChange, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [currentColor, setCurrentColor] = useState({});
  const [visibleColorPickerPopover, setVisibleColorPickerPopover] = useState(false);

  /**
   * Set current color
   */
  useEffect(() => {
    if (!checkValidRGBObject(rgb)) return;

    setCurrentColor(rgb);
  }, [rgb]);

  /**
   * Render color button
   */
  const renderColorButton = () => {
    return (
      <div
        style={{
          width: 16,
          height: 16,
          background: checkValidRGBObject(rgb) ? `rgb(${rgb?.r}, ${rgb?.g}, ${rgb?.b})` : '#e0e0e0'
        }}
        className={`c-color-picker-button ${isReadOnly ? '' : 'cursor-pointer'} ${className}`}
        {...rest}
      >
        {children}
      </div>
    );
  };

  return isReadOnly ? (
    renderColorButton()
  ) : (
    <Popover
      open={visibleColorPickerPopover}
      title={
        <div className="position-relative text-center">
          {t('common.colorPicker')}

          <Button
            icon={<CloseOutlined />}
            size="small"
            className="border-transparent btn-close"
            style={{ position: 'absolute', right: -8, top: -1 }}
            onClick={() => setVisibleColorPickerPopover(false)}
          ></Button>
        </div>
      }
      content={
        <SketchPicker color={currentColor} disableAlpha={true} width={220} onChange={val => onChange(val?.rgb)} />
      }
      trigger="click"
      placement="bottom"
      destroyTooltipOnHide={true}
      overlayClassName="c-color-picker-button-popover"
      onOpenChange={setVisibleColorPickerPopover}
    >
      {renderColorButton()}
    </Popover>
  );
};
