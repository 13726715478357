import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { ENDPOINTS } from '../../../../constants';
import { Http, handleError, sleep } from '../../../../common';

export const model = {
  /**
   * State
   */
  previewData: [],
  percentImport: 0,
  loadingImportTicketExcel: false,
  loadingPreviewImportTicketExcel: false,

  /**
   * Action: Set preview data
   */
  setPreviewData: action((state, payload) => {
    if (state?.previewData === undefined) return;

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.previewData = [];
      return;
    }

    state.previewData = payload.data;
  }),

  /**
   * Action: Set percent import
   */
  setPercentImport: action((state, payload) => {
    state.percentImport = payload;
  }),

  /**
   * Action: Set loading import ticket excel
   */
  setLoadingImportTicketExcel: action((state, payload) => {
    if (state?.loadingImportTicketExcel === undefined) return;

    state.loadingImportTicketExcel = payload;
  }),

  /**
   * Action: Set loading preview import ticket excel
   */
  setLoadingPreviewTicketExcel: action((state, payload) => {
    if (state?.loadingPreviewImportTicketExcel === undefined) return;

    state.loadingPreviewImportTicketExcel = payload;
  }),

  /**
   * Action: Call api to preview import ticket excel
   */
  previewImportTicketExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      action.setLoadingPreviewTicketExcel(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_PREVIEW}`;
      const res = await Http.post(url, payload).then(res => res.data);

      if (res?.data) {
        action.setPreviewData(res.data);
        return res.data;
      }
      action.setLoadingPreviewTicketExcel(false);
    } catch (err) {
      if (err?.status === 400 && err.data?.message.includes('Sheet name is not exist')) {
        return err;
      }
      handleError(err);
    } finally {
      action.setLoadingPreviewTicketExcel(false);
    }
  }),

  /**
   * Action: Call api to import test case
   */
  importWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload) && payload.length)) return;

      action.setLoadingImportTicketExcel(true);

      const rootPayload = Array.isArray(payload) && payload.length ? [...payload] : [];

      let countChunk = 0;
      let index = 0;
      while (payload.length) {
        const newPayload = payload.splice(0, 50);
        countChunk = newPayload.length + countChunk;

        if (index === 0) {
          setTimeout(() => {
            action.setPercentImport(Math.round((countChunk * 100) / rootPayload.length));
          }, 1000);
        }
        const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTRUN_WORK_TICKET}`;
        await Http.post(url, newPayload).then(res => res.data);

        if (index > 0) {
          action.setPercentImport(((countChunk * 100) / rootPayload.length).toFixed(2));
        }
        index++;
      }

      await sleep(1000);
      action.setLoadingImportTicketExcel(false);

      notification.success({
        message: i18next.t('akaat:common.newItem'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      await sleep(1000);
      action.setLoadingImportTicketExcel(false);
    }
  })
};
