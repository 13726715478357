import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import { handleKeyboardEvents } from '../../editable-cell-utils';

const ScriptPathFormItem = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  inputProps,
  setEditingCell,
  handleSave,
  closeAndSetLastValue,
  restFormItem,
  restField
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) return;

    const code = e.code;
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');

    // Delete & has "focused" class => Don't open, keep "focused" class, save
    if (!field?.mandatory && hasFocused && (code === 'Delete' || code === 'NumpadDecimal')) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.resetFields([formItemName]);
      handleSave('');
      return;
    }

    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Form.Item
      name={formItemName}
      rules={[{ required: field?.mandatory, whitespace: field?.mandatory, message: t('message.required') }]}
      wrapperCol={{ xs: 24 }}
      {...restFormItem}
    >
      <Input {...inputProps} {...restField} />
    </Form.Item>
  );
};

export default ScriptPathFormItem;
