import { Form, Button, Input, Spin, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { SaveOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TurndownService from 'turndown';
import moment from 'moment';
import { BasicEditor } from '../basic-editor';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  JIRA_DATA_TYPE_DATE,
  JIRA_DATA_TYPE_USER,
  JIRA_PLATFORM_ID,
  JIRA_FIELD_TYPE_DEFAULT,
  JIRA_TYPE_BODY_ARRAY,
  JIRA_TYPE_BODY_ARRAY_ID,
  JIRA_TYPE_BODY_HTML,
  JIRA_TYPE_BODY_OBJECT_ID,
  JIRA_TYPE_BODY_OBJECT_KEY,
  JIRA_TYPE_BODY_OPTION,
  JIRA_DATA_TYPE_ISSUELINKS,
  JIRA_DATA_TYPE_SPRINT_ID,
  JIRA_DATA_EPIC_LINK_ID,
  JIRA_DATA_PRODUCT_ID,
  JIRA_DATA_TYPE_LABELS,
  JIRA_TYPE_BODY_RAW_ARRAY,
  JIRA_TYPE_BODY_ISSUELINKS
} from '../../constants';
import { useFile, scrollToFirstClassName } from '../../common';
import { JiraCustomField } from '../custom-field/jira-custom-field';

export default ({
  customFields,
  defect,
  onPrevious,
  handleCreateRelation,
  remoteLink = null,
  testStep = [],
  loading = false
}) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For upload
  const { getUploadPath } = useFile();

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // For jira store
  const createIssue = useStoreActions(action => action.jiraIntegration.createIssue);
  const getFieldListCreateMeta = useStoreActions(action => action.jiraIntegration.getFieldListCreateMeta);
  const loadingItem = useStoreState(state => state.jiraIntegration.loadingItem);
  const integrationItem = useStoreState(state => state.integration.integrationItem);
  const suggestionList = useStoreState(state => state.jiraIntegration.suggestionList);

  // Component state
  const [description, setDescription] = useState('');
  const [visibleEditor, setVisibleEditor] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  useEffect(() => {
    if (!defect?.jiraIssueType) return;

    getFieldListCreateMeta({
      issuetype: defect?.jiraIssueType?.id
    });
  }, [defect]);

  useEffect(() => {
    if (!defect?.jiraIssueType) return;

    const rawHtml = Array.isArray(testStep) ? testStep.join('') : '';

    setDescription(rawHtml);
    form.setFieldsValue({
      summary: defect?.item,
      // project: defect?.jiraIssueType?.fields?.project?.allowedValues[0]?.id,
      // issuetype: defect?.jiraIssueType?.fields?.issuetype?.allowedValues[0]?.id,
      description: rawHtml
    });

    // let otherFieldList = Object.keys(defect?.jiraIssueType.fields)
    //   .map(key => defect?.jiraIssueType.fields[key])
    //   .filter(
    //     field =>
    //       field.required &&
    //       field.key !== 'summary' &&
    //       field.key !== 'project' &&
    //       field.key !== 'issuetype' &&
    //       field.key !== 'description'
    //   );
    // setCustomFields(otherFieldList);
  }, [testStep, form, defect]);

  const handleSubmit = async values => {
    if (!values || !globalProject?.projectKey || !globalTenant?.tenantKey) return;
    let formData = {};
    let update = {};

    customFields.forEach(field => {
      // Ignore special field
      if (
        field.fieldId !== JIRA_DATA_TYPE_ISSUELINKS &&
        field.fieldId !== JIRA_DATA_TYPE_SPRINT_ID &&
        field.fieldId !== JIRA_DATA_EPIC_LINK_ID &&
        field.fieldId !== JIRA_DATA_PRODUCT_ID
      ) {
        if (
          (field.schema.customId && field.schema.type === JIRA_TYPE_BODY_ARRAY) ||
          field.schema.system === JIRA_DATA_TYPE_LABELS
        ) {
          JIRA_FIELD_TYPE_DEFAULT[field.fieldId] = JIRA_TYPE_BODY_RAW_ARRAY;
        } else JIRA_FIELD_TYPE_DEFAULT[field.fieldId] = field.schema.type;
      }
    });

    Object.keys(values).forEach(key => {
      switch (JIRA_FIELD_TYPE_DEFAULT[key]) {
        case JIRA_DATA_TYPE_USER: {
          formData[key] = {
            name: values[key],
            id: values[key]
          };
          break;
        }
        case JIRA_TYPE_BODY_OPTION:
        case JIRA_TYPE_BODY_OBJECT_ID: {
          formData[key] = {
            id: values[key]
          };
          break;
        }
        case JIRA_TYPE_BODY_OBJECT_KEY: {
          formData[key] = {
            key: values[key]
          };
          break;
        }
        case JIRA_TYPE_BODY_ARRAY_ID: {
          formData[key] = values[key].map(value => ({ id: value }));
          break;
        }
        case JIRA_DATA_TYPE_DATE: {
          formData[key] = moment(values[key]).format();
          break;
        }
        case JIRA_TYPE_BODY_ARRAY: {
          formData[key] = values[key]?.map(value => ({
            id: value
          }));
          break;
        }
        case JIRA_TYPE_BODY_HTML: {
          const turndownService = new TurndownService();
          const markdown = turndownService.turndown(values[key]);
          formData[key] = markdown;
          break;
        }
        case JIRA_TYPE_BODY_ISSUELINKS: {
          const issue = suggestionList?.issuelinks?.sections?.[0]?.issues.find(
            issue => issue.id === values?.issuelinks?.issue || issue.key === values?.issuelinks?.issue
          );
          const linkedType = suggestionList?.issueLinkTypes?.find(type => type.id === values?.issuelinks?.linkedType);

          if (!issue || !linkedType) return;

          if (linkedType.type === 'outward') {
            update = {
              issuelinks: [
                {
                  add: {
                    outwardIssue: {
                      key: issue.key
                    },
                    type: {
                      name: linkedType.typeName,
                      inward: linkedType.inward,
                      outward: linkedType.name
                    }
                  }
                }
              ]
            };
          } else {
            update = {
              issuelinks: [
                {
                  add: {
                    inwardIssue: {
                      key: issue.key
                    },
                    type: {
                      name: linkedType.typeName,
                      outward: linkedType.outward,
                      inward: linkedType.name
                    }
                  }
                }
              ]
            };
          }
          break;
        }
        default: {
          formData[key] = values[key];
          break;
        }
      }
    });

    if (defect?.jiraIssueType?.id) {
      formData.issuetype = {
        id: defect.jiraIssueType.id
      };
    }

    let newRemoteLink = {
      title: `${window.location.origin}${window.location.pathname}`,
      link: window.location.href
    };

    if (remoteLink) {
      newRemoteLink = remoteLink;
    }

    const res = await createIssue({
      projectKey: globalProject.projectKey,
      tenantKey: globalTenant.tenantKey,
      formData,
      update,
      remoteLink: newRemoteLink
    });

    if (res) {
      const defectLink = {
        id: res.id,
        name: res.key,
        type: JIRA_PLATFORM_ID,
        link: `${defect?.jiraIssueType?.self?.split('/rest/api/2')[0]}/browse/${res.key}`,
        formData
      };

      handleCreateRelation(defectLink);
    }
  };

  return (
    <>
      <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
        <div className="form-wrapper p-4">
          <Form id="defectJiraForm" form={form} layout="vertical" onFinish={handleSubmit}>
            <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingItem || loading}>
              <Form.Item
                label={t('common.summary')}
                name="summary"
                rules={[{ required: true, whitespace: true, message: t('message.required') }]}
              >
                <Input placeholder={t('defect.enterDefectName')} />
              </Form.Item>

              {visibleEditor && (
                <Form.Item
                  label={t('workItem.description')}
                  name="description"
                  rules={[{ required: true, whitespace: true, message: t('message.required') }]}
                >
                  <BasicEditor
                    rawHtml={description}
                    restEditor={{ placeholder: t('workItem.enterDescription') }}
                    isProtectedFile={false}
                    uploadPath={getUploadPath(defect?.jiraIssueType?.id)}
                    onEditorRawHtmlChange={val => {
                      if (val === '<p></p>\n') {
                        form.setFieldsValue({ description: null });
                      } else form.setFieldsValue({ description: val });
                    }}
                  />
                </Form.Item>
              )}

              {Array.isArray(customFields) && customFields.length > 0
                ? customFields.map((field, index) => {
                    return <JiraCustomField config={integrationItem?.jira} key={index} form={form} field={field} />;
                  })
                : ''}
            </Spin>
          </Form>
        </div>
      </div>

      <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
        <Divider className="mt-0 mb-3" />

        <div className="text-right">
          <Button onClick={onPrevious}>{t('common.previous')}</Button>

          <Button
            form="defectJiraForm"
            htmlType="submit"
            className="ml-2"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => scrollToFirstClassName('ant-form-item-has-error')}
          >
            {t('common.create')}
          </Button>
        </div>
      </div>
    </>
  );
};
