import queryString, { stringify } from 'query-string';
import qs from 'qs';
import moment from 'moment';
import mime from 'mime-types';
import { parse as rsqlParser } from '@rsql/parser';
import { encodeQueryParams, StringParam } from 'use-query-params';
import Joi from 'joi';
import DateExtension from '@hapi/joi-date';
import { ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import i18next, { t } from 'i18next';
import DOMPurify from 'dompurify';
import slug from 'slug';
import { v4 as uuidv4 } from 'uuid';

import env from '../env';
import {
  PAGE_SIZE,
  PRIORITIES,
  DEFAULT_ORDER,
  NUMBER_PATTERN,
  ROLE_PROJECT_OWNER,
  SERVER_DATE_FORMAT,
  SCHEDULE_TYPE_DAILY,
  SCHEDULE_TYPE_WEEKLY,
  ESTIMATED_TIME_PATTERN,
  COMPONENT_TYPE,
  SYSTEM_FIELD_PRIORITY,
  SYSTEM_FIELD_STATUS,
  TEST_ITEM_COLORS,
  SYSTEM_FIELD_LATEST_RESULT,
  WORK_ITEM_TEST_RESULT_ID,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TESTCASE_ID,
  SYSTEM_FIELD_ESTIMATEDTIME,
  SYSTEM_FIELD_DESCRIPTION,
  SYSTEM_FIELD_TEST_STEPS,
  WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED,
  SYSTEM_FIELD_ASSIGN_TO,
  TEST_RESULT_STATUS_NOT_EXECUTED,
  FIELD_STEP,
  FIELD_TEST_DATA,
  FIELD_EXPECTED_RESULT,
  FIELD_STEP_RESULT,
  FIELD_ATTACHMENTS,
  BASE64_IMAGE_PATTERN,
  JIRA_PLATFORM_ID,
  JIRA_COLOR_MAPPING,
  SS_NEW_RECORDS,
  SS_EDITING_CELL,
  SS_LAST_SAVED_VALUE,
  SS_LOADED_ALL_SUGGESTION_INFO,
  SS_LOADED_USER_LIST_BY_ROLE_INFO,
  WORK_ITEM_TEST_RESULT_STATUS_FAIL,
  WORK_ITEM_TEST_RESULT_STATUS_PASS,
  WORK_ITEM_TEST_RESULT_STATUS_BLOCKED,
  WORK_ITEM_TEST_RESULT_STATUS_WIP,
  TESTFLOW_EXTENSION_ID,
  SYSTEM_FIELD_ORDER_ID,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_RELEASE,
  SYSTEM_FIELD_CYCLE,
  SYSTEM_FIELD_TEST_SUITE,
  SYSTEM_FIELD_SCRIPT_PATH,
  SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY,
  SYSTEM_FIELD_TEST_CONFIG
} from '../constants';
import { reactSessionStorage } from './session-storage';

const joi = Joi.extend(DateExtension);

/**
 * Get parent menu in menu config by location pathname
 */
export const getParentMenuByPathname = (locationPathname, menuList) => {
  if (!locationPathname || !(Array.isArray(menuList) && menuList.length)) return;

  let menu = undefined;

  // ==========> Find menu item
  const findMenuItem = (menus, pathname) => {
    if (!(Array.isArray(menus) && menus.length)) return;

    for (let i = 0; i < menus.length; i++) {
      const item = menus[i];
      const regexForCheckSLug = /\/:[a-zA-Z0-9_-]+[/]*/g;
      const hasSlug = new RegExp(regexForCheckSLug).test(item.path); // Example match with /:id or /:id/ ===> Example: /automation/:id or Example: /automation/:id/edit

      if (hasSlug) {
        const beforeSlug = item.path.split(new RegExp(regexForCheckSLug))[0]; // Example: '/automation'

        if (pathname.includes(beforeSlug)) {
          menu = item;
          break;
        }
      }

      if (item.path === pathname) {
        menu = item;
        break;
      }
    }
  };

  findMenuItem(menuList, locationPathname);

  return menu;
};

/**
 * Build sub path name list, for active nav link
 *
 * Example - Before: /parent/child-1/child-2
 *
 * Example - After: ['/', '/parent', '/parent/child-1', '/parent/child-1/child-2']
 */
export const buildSubPathNameList = locationPathname => {
  if (!locationPathname) return [];

  const pathList = []; // Example: ['/', '/parent', '/parent/child-1', '/parent/child-1/child-2']
  const pathArray = locationPathname.substr(locationPathname.indexOf('/') + 1).split('/'); // Example: ['parent', 'child-1', 'child-2']

  pathArray.forEach((item, index) => {
    if (!pathList[index - 1]) {
      pathList.push('/' + item);
      return;
    }

    pathList.push(pathList[index - 1] + '/' + item);
  });

  return pathList;
};

/**
 * Build breadcrumb list by menus
 *
 * Example - Before: /parent/child-1/child-2
 *
 * Example - After:
 * [
 *   { path: "/", name: "Home" },
 *   { path: "/parent", name: "Parent" },
 *   { path: "/parent/child-1", name: "Child 1" },
 *   { path: "/parent/child-1/child-2", name: "Child 2" }
 * ]
 */
export const buildBreadcrumbListByMenus = (locationPathname, menuList) => {
  if (!locationPathname || !(Array.isArray(menuList) && menuList.length)) return [];

  const pathList = buildSubPathNameList(locationPathname); // Example: ['/', '/parent', '/parent/child-1', '/parent/child-1/child-2']
  const breadcrumbList = menuList.filter(item => !item.isHideOnBreadcrumb && pathList.includes(item.path));

  return breadcrumbList;
};

/**
 * Convert nested menu to group menu
 */
export const convertNestedMenuToGroupMenu = (nestedMenu, menuGroupList) => {
  if (!(Array.isArray(nestedMenu) && nestedMenu.length)) return;

  const groupMenu = [];
  const clonedNenuGroupConfig = Array.isArray(menuGroupList) && menuGroupList.length ? [...menuGroupList] : [];

  nestedMenu.forEach(item => {
    if (groupMenu.some(sub => sub.type === 'group' && sub.id === item.groupId)) return;

    const groupFound = clonedNenuGroupConfig.find(g => g.id === item.groupId);

    if (!groupFound) {
      groupMenu.push({
        id: `group-${item.id}`,
        name: null,
        type: 'group',
        children: [item]
      });

      return;
    }

    groupMenu.push({
      id: groupFound.id,
      name: groupFound.name,
      type: 'group',
      children: nestedMenu.filter(sub => sub.groupId === groupFound.id)
    });
  });

  return groupMenu;
};

/**
 * Convert to nested menu
 */
export const convertToNestedMenu = (menu, id) => {
  const clonedMenu = Array.isArray(menu) && menu.length ? [...menu] : [];

  const newMenu = [...clonedMenu]
    .filter(item => item.parentId === id)
    .map(item => {
      const children = convertToNestedMenu(clonedMenu, item.id);

      return {
        ...item,
        children: Array.isArray(children) && children.length ? children : []
      };
    });

  return newMenu;
};

/**
 * Convert order string to sorted info
 */
export const convertOrderToSortedInfo = str => {
  if (!(str && typeof str === 'string')) return {};

  const orderPaths = str.split('|');
  const newSortedInfo = {
    field: orderPaths[0],
    order: orderPaths[1] === 'ASC' ? 'ascend' : 'descend'
  };

  return newSortedInfo;
};

/**
 * Check permission
 */
export const checkPermission = (val, userInfo) => {
  if (!val || !(Array.isArray(userInfo?.permissions) && userInfo?.permissions.length)) return;

  return userInfo.permissions.includes(val);
};

/**
 * Build and encode URI query for api
 * Ref: https://sequelize.org/v3/docs/querying/
 *
 * Example:
 * {
 *   offset: 0,
 *   limit: 10,
 *   order: [
 *     ['name', 'ASC']
 *   ],
 *   where: {
 *     id: 123,
 *     name: {
 *       $iLike: '%abc%'
 *     },
 *     $or: [ // search on multi attribute
 *       {
 *         name: {
 *           $iLike: '%tony%'
 *         }
 *       },
 *       {
 *         email: {
 *           $iLike: '%tony%'
 *         }
 *       }
 *     ],
 *     $and: [ // exclude record
 *       {
 *         id: { $ne: 63 }
 *       },
 *       {
 *         id: { $ne: 150 }
 *       }
 *     ]
 *   }
 * }
 *
 */
export const buildQuery = (params = {}) => {
  const query = {
    offset: 0, // Same page = 1
    limit: PAGE_SIZE // If limit is null => Don't send limit => Get all
  };

  // Add offset
  if (+params.page >= 1) {
    query.offset = (params.page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.limit = params.limit;
  }

  // Add order (sort)
  if (params.order && typeof params.order === 'string') {
    query.order = params.order.split(',').map(item => {
      const paths = item.split('|');

      return [paths[0], paths[1]];
    }); // Example: order:[["updatedAt","DESC"],["id","ASC"]]
  } else {
    const paths = DEFAULT_ORDER.split('|');
    query.order = [[paths[0], paths[1]]]; // Example: order:[["updatedAt","DESC"]]
  }

  // Add where
  if (params.where !== null && typeof params.where === 'object' && Object.keys(params.where).length) {
    query.where = params.where;
  }

  // Add attributes
  if (
    (Array.isArray(params.attributes) && params.attributes.length) ||
    (typeof params.attributes === 'object' && Object.keys(params.attributes).length)
  ) {
    query.attributes = params.attributes;
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.offset;
    } else if (params[key] === null) {
      delete query[key];
    } else {
    }
  });

  return encodeURI(JSON.stringify({ ...query }));
};

/**
 * Build query for params url
 *
 * Example: ?limit=10&name=abc&offset=0&sort=createdAt%7CDESC
 */
export const buildQueryForParamsUrlV1 = (params = {}) => {
  const query = {
    offset: 0, // Same page = 1
    limit: PAGE_SIZE // If limit is null => Don't send limit => Get all
  };

  // Add offset
  if (+params.page >= 1) {
    query.offset = (params.page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.limit = params.limit;
  }

  // Add order (sort)
  query.sort = /(\|(ASC|DESC))+$/g.test(params.order) ? params.order : DEFAULT_ORDER;

  // Add filter
  if (params.where !== null && typeof params.where === 'object' && Object.keys(params.where).length) {
    Object.keys(params.where).forEach(key => {
      query[key] = params.where[key];
    });
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.offset;
    } else if (key === 'order' && params.order === null) {
      delete query.sort;
    } else if (params[key] === null) {
      delete query[key];
    } else {
    }
  });

  return queryString.stringify({ ...query });
};

/**
 * Build query for params url
 */
export const buildQueryForFilterMongo = (params = {}) => {
  if (['jobCreator.id', 'createdBy.id'].some(value => value === params?.referenceField)) return;

  const query = {
    option: {
      sort: {},
      offset: 0, // Same page = 1
      limit: PAGE_SIZE, // If limit is null => Don't send limit => Get all,
      group: {},
      select: null
    },
    filter: {}
  };

  // Add offset
  if (+params.page >= 1) {
    query.option.offset = isNaN((params.page - 1) * (params.limit || query.limit))
      ? null
      : (params.page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.option.limit = params.limit;
  }

  // Add group
  if (params.group) {
    query.option.group = params.group;
  }

  // Add order (sort)
  query.option.sort = /(\|(ASC|DESC))+$/g.test(params.order) ? params.order : DEFAULT_ORDER;
  const orderObject = {};

  query.option.sort.split(',').forEach(item => {
    const paths = item.split('|');
    orderObject[paths[0]] = paths[1];
  });

  query.option.sort = orderObject;

  // Add filter
  if (params.filter !== null) {
    query.filter = params.filter;
  }

  // Add select
  if (params.select !== null) {
    query.option.select = params.select;
  }

  // Add more option
  if (params.moreOption !== null && typeof params.moreOption === 'object' && Object.keys(params.moreOption).length) {
    Object.keys(params.moreOption).forEach(key => {
      query.option[key] = params.moreOption[key];
    });
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.option.offset;
    } else if (key === 'order' && params.order === null) {
      delete query.option.sort;
    } else if (params[key] === null) {
      delete query.option[key];
    } else {
    }
  });

  return qs.stringify(query, { skipNulls: true });
};

/**
 * Build query for aql
 *
 * Example:
 *
 *  {
      limit: 10,
      offset: 0,
      order: 'executedDate|DESC',
      aql: 'testCase.id == 1'
    }
 */
export const buildQueryForParamsUrl = (params = {}) => {
  const query = {
    offset: 0, // Same page = 1
    limit: PAGE_SIZE // If limit is null => Don't send limit => Get all
  };

  // Add offset
  if (+params.page >= 1) {
    query.offset = (params.page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.limit = params.limit;
  }

  // Add order (sort)
  const orderString = /(\|(ASC|DESC))+$/g.test(params.order) ? params.order : DEFAULT_ORDER;

  if (params.orderType === 'object') {
    const orderObject = {};

    orderString.split(',').forEach(item => {
      const paths = item.split('|');
      orderObject[paths[0]] = paths[1];
    });

    query.order = JSON.stringify(orderObject);
  } else {
    query.order = orderString;
  }

  // Add aql
  if (params.aql) {
    query.aql = params.aql;
  }

  // Add filters
  if (params.filters !== null && typeof params.filters === 'object' && Object.keys(params.filters).length) {
    Object.keys(params.filters).forEach(key => {
      query[key] = params.filters[key];
    });
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.offset;
    } else if (params[key] === null) {
      delete query[key];
    } else {
    }
  });

  let newQueryString = queryString.stringify({ ...query });

  if (params.queryString) {
    newQueryString = `${newQueryString}&${params.queryString}`;
  }

  return newQueryString;
};

/**
 * Check valid rsql string by rsql parse
 * More: https://github.com/piotr-oles/rsql#packages
 */
export const parseRsql = rsql => {
  if (rsql === undefined || rsql === null) return;

  try {
    return rsqlParser(rsql);
  } catch (err) {
    return null;
  }
};

/**
 * Get conditions by ast
 * More: https://github.com/piotr-oles/rsql#packages
 */
export const getConditionsByAql = aql => {
  if (!aql) return;

  const astObj = parseRsql(aql);

  if (!astObj) return;

  const conditionList = [];
  const logicList = [];

  const convert = obj => {
    const newItem = {};

    if (obj.type === 'COMPARISON') {
      newItem.selector = obj.left.selector;
      newItem.operator = obj.operator;
      newItem.value = obj.right.value;

      conditionList.push(newItem);
    }

    if (obj.type === 'LOGIC') {
      logicList.push(obj.operator);

      convert(obj.left);
      convert(obj.right);
    }
  };

  convert(astObj);

  return { conditionList, logicList };
};

/**
 * Get object in array by value
 */
export const getObjectByValue = (val, list, byAttribute = 'value') => {
  if (!(Array.isArray(list) && list.length)) return;

  return list.find(item => item[byAttribute] === val);
};

/**
 * Get key by value in object
 */
export const getKeyByValueInObject = (val, obj) => {
  if (!(obj !== null && typeof obj === 'object' && Object.keys(obj).length)) return;

  return Object.keys(obj).find(key => obj[key] == val);
};

/**
 * Debounce function
 */
export const debounce = (callback, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) callback.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) callback.apply(context, args);
  };
};

/**
 * Custom JSON parse
 * Use try catch to avoid errors
 */
export const jsonParse = data => {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * Remove duplicate item in array
 */
export const removeDuplicate = (list, byAttribute = 'id') => {
  if (!(Array.isArray(list) && list.length)) return [];

  // For object array
  if (byAttribute) {
    return [...list]
      .filter(item => item)
      .reduce((accumulator, current) => {
        if (!accumulator.some(sub => sub[byAttribute] === current[byAttribute])) {
          accumulator.push(current);
        }

        return accumulator;
      }, []);
  }

  // For string array or number array
  else if (byAttribute === '') {
    return [...list].reduce((accumulator, current) => {
      if (!accumulator.some(sub => sub === current)) {
        accumulator.push(current);
      }

      return accumulator;
    }, []);
  }

  return [];
};

/**
 * Add number to exists item in array
 * Example:
 *   Before: ['A', 'B', 'A', 'A', 'B', 'C']
 *   After: ['A', 'B', 'A (2)', 'A (3)', 'B (2)', 'C']
 */
export const addNumberToExistsItemInArray = (list, byAttribute) => {
  if (!(Array.isArray(list) && list.length)) return [];

  const count = {};

  if (byAttribute) {
    return [...list].reduce((accumulator, current) => {
      if (accumulator.some(i => i?.[byAttribute] === current?.[byAttribute])) {
        const newCount = count[current?.[byAttribute]] ? count[current?.[byAttribute]] + 1 : 2;
        const newItem = { ...current, [byAttribute]: `${current?.[byAttribute]} (${newCount})` };
        count[current?.[byAttribute]] = newCount;

        return [...accumulator, newItem];
      } else {
        return [...accumulator, current];
      }
    }, []);
  }

  // For string array or number array
  else {
    return [...list].reduce((accumulator, current) => {
      if (accumulator.includes(current)) {
        const newCount = count[current] ? count[current] + 1 : 2;
        count[current] = newCount;

        return [...accumulator, `${current} (${newCount})`];
      } else {
        return [...accumulator, current];
      }
    }, []);
  }
};

/**
 * Check if array is unique
 */
export const checkIfArrayIsUnique = arr => {
  if (!(Array.isArray(arr) && arr.length)) return;

  return arr.length === new Set(arr).size;
};

/**
 * For directory tree
 * Re add expanded keys, because when you click to tree item, this tree item is collapsed or expanded
 *
 * Expect: Always expand item when click it
 */
export const reAddExpandedKeys = (keys, expandedKeys, callback) => {
  if (!(Array.isArray(keys) && keys.length) || !Array.isArray(expandedKeys) || typeof callback !== 'function') {
    return;
  }

  let newExpandedKeys = [...expandedKeys];

  keys.forEach(key => {
    const count = expandedKeys.reduce((accumulator, current) => {
      return current === key ? accumulator + 1 : accumulator;
    }, 0);

    if (count >= 0) {
      newExpandedKeys = [...newExpandedKeys.filter(item => item !== key), key];
    }
  });

  callback(newExpandedKeys);
};

/**
 * Find item and parents on tree
 */
export const findItemAndParentsOnTree = (list, val, byAttribute = 'key') => {
  if (!(Array.isArray(list) && list.length)) return;

  let found = null;
  const allParents = [];
  const parentList = [];

  // ==========> Find item
  const findItem = (_list, _val) => {
    if (found || !(Array.isArray(_list) && _list.length)) return;

    for (let i = 0; i < _list.length; i++) {
      const item = _list[i];
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (item[byAttribute] === _val) {
        found = item;
        break;
      }

      if (hasChildren) {
        allParents.push(item);
        findItem(item.children, _val);
      }
    }
  };

  findItem(list, val);

  // ==========> Find parent nested of found item
  if (found && Array.isArray(allParents)) {
    let currentKey = found[byAttribute];
    const reverseList = [...allParents].reverse();

    reverseList.forEach(p => {
      if (Array.isArray(p?.children) && p?.children.length && p?.children.some(c => c[byAttribute] === currentKey)) {
        parentList.push(p);
        currentKey = p?.[byAttribute];
      }
    });
  }

  return {
    item: found,
    parentList: Array.isArray(parentList) ? parentList.reverse() : []
  };
};

/**
 * Search items by keyword on tree
 *
 * "list" are the found items
 * "parentKeys" for expand row on tree
 */
export const searchItemsByKeywordOnTree = (list, keyword) => {
  if (!(Array.isArray(list) && list.length)) return;

  let itemKeys = [];
  let parentKeys = [];

  // Remove duplicate in string array (string[]). Also remove: null
  const customRemoveDuplicate = stringArr => {
    return [...stringArr].reduce((accumulator, current) => {
      if (!accumulator.some(sub => sub === current)) {
        current && accumulator.push(current);
      }

      return accumulator;
    }, []);
  };

  // Find item keys and parent keys
  const findItemKeys = (_list, _keyword) => {
    if (!(Array.isArray(_list) && _list.length)) return;

    for (let i = 0; i < _list.length; i++) {
      const item = _list[i];
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (
        typeof _keyword === 'string' &&
        typeof item.title === 'string' &&
        item.title.toString().toLowerCase().indexOf(_keyword.toString().toLowerCase()) > -1
      ) {
        itemKeys.push(item.key);
      }

      if (hasChildren) {
        findItemKeys(item.children, _keyword);
      }
    }
  };

  // Run findItemKeys
  findItemKeys(list, keyword);

  // Build parent keys
  // Example: Before itemKey = "id-5_id-21_id-3". After: parentKeys = ["id-5", "id-5_id-21"]
  const convertItemKeyToParentKeys = key => {
    if (!(typeof key === 'string' && key !== '')) return;

    const lastIndexOfUnderscore = key.lastIndexOf('_');
    const newKey = key.substring(0, lastIndexOfUnderscore);
    parentKeys.push(newKey);

    if (newKey.split('_').length >= 2) {
      return convertItemKeyToParentKeys(newKey);
    }
  };

  // Run convertItemKeyToParentKeys on each itemKey
  itemKeys.forEach(key => convertItemKeyToParentKeys(key));

  // Merge itemKeys and parentKeys, remove duplicate keys
  const mergeKeys = customRemoveDuplicate([...parentKeys, ...itemKeys]);

  // Remove item on tree if it does not contain mergeKeys
  const removeItemsNotContainKeys = _list => {
    return _list.map(item => {
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (hasChildren) {
        const children = item.children.filter(child => mergeKeys.includes(child.key));

        return {
          ...item,
          children: removeItemsNotContainKeys(children)
        };
      }

      return { ...item };
    });
  };

  // Remove items in first lever
  const list2 = [...list].filter(item => mergeKeys.includes(item.key));
  const newList = removeItemsNotContainKeys([...list2]);

  return {
    list: newList,
    parentKeys: customRemoveDuplicate([...parentKeys])
  };
};

/**
 * Search nodes by keyword on tree
 *
 * @returns list => found items
 * @returns parentKeys => parent keys of found items
 */
export const searchNodesByKeywordOnTree = (currentTree, keyword, searchKey = 'title') => {
  if (!(Array.isArray(currentTree) && currentTree.length)) return;

  let itemKeys = [];
  let parentKeys = [];

  // Find item keys and parent keys
  const findItemKeys = (_list, _keyword) => {
    if (!(Array.isArray(_list) && _list.length)) return;

    for (let i = 0; i < _list.length; i++) {
      const item = _list[i];
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (
        typeof _keyword === 'string' &&
        typeof item[searchKey] === 'string' &&
        item[searchKey].toString().toLowerCase().indexOf(_keyword.toString().toLowerCase()) > -1
      ) {
        itemKeys.push(item.key);
        parentKeys = [...parentKeys, ...item.parentKeys];
      }

      if (hasChildren) findItemKeys(item.children, _keyword);
    }
  };

  // Run find item keys
  findItemKeys(currentTree, keyword);

  // Get new tree
  const getNewTree = (_list, keys) => {
    if (!(Array.isArray(_list) && _list.length)) return;

    return _list.map(item => {
      if (Array.isArray(item.children) && item.children.length) {
        const children = item.children.filter(child => keys.includes(child.key));

        return {
          ...item,
          children: getNewTree(children, keys)
        };
      }

      return { ...item };
    });
  };

  parentKeys = removeDuplicate([...parentKeys], '');

  const foundKeys = [...parentKeys, ...itemKeys];
  const tree = getNewTree(
    currentTree.filter(item => foundKeys.includes(item.key)),
    foundKeys
  );

  return {
    tree,
    parentKeys
  };
};

/**
 * Get parent keys
 */
export const getParentKeys = (list, byAttribute = 'id') => {
  if (!(Array.isArray(list) && list.length)) return;

  const parentKeys = [];

  const getKeys = _list => {
    for (let i = 0; i < _list.length; i++) {
      const item = _list[i];

      if (item.children && item.children.length) {
        parentKeys.push(item[byAttribute]);
        getKeys(item.children);
      }
    }
  };

  getKeys(list);

  return parentKeys;
};

/**
 * Get expanded keys by tree key
 */
export const getExpandedKeysByTreeKey = (tree, treeKey) => {
  if (!treeKey || !(Array.isArray(tree) && tree.length)) return;

  // Find item tree
  const treeItem = findItemAndParentsOnTree(tree, treeKey);
  const { item, parentList } = treeItem;
  let keys = [];

  if (!treeItem?.item?.key) return;

  if (Array.isArray(item?.children) && item?.children.length) {
    keys = [treeKey, item.key];
  }

  if (Array.isArray(parentList) && parentList.length) {
    keys = [treeKey, ...keys, ...parentList.map(parent => parent.key)];
  }

  return { item, parentList, expandedKeyList: removeDuplicate(keys, '') || [] };
};

/**
 * Check different between two list
 * Check list: length of two list, values, order
 */
export const checkDifferentTwoList = (originList, currentList, compareByAttributes, sortBy) => {
  if (!(Array.isArray(compareByAttributes) && compareByAttributes.length)) {
    throw new Error('There are no attributes to compare');
  }

  if (!Array.isArray(originList) || !Array.isArray(currentList)) return true;

  if (originList.length !== currentList.length) return true;

  // Get new list
  const getNewList = list => {
    let newList = [];

    newList = list.map(item => {
      const obj = {};

      compareByAttributes.forEach(attr => {
        obj[attr] = item[attr];
      });

      return obj;
    });

    if (sortBy) {
      newList = [...newList].sort((a, b) => {
        if (+a[sortBy] > +b[sortBy]) return 1;
        if (+a[sortBy] < +b[sortBy]) return -1;
        return 0;
      });
    }

    return newList;
  };

  const newOriginList = getNewList([...originList]);
  const newCurrentList = getNewList([...currentList]);

  return JSON.stringify(newOriginList) !== JSON.stringify(newCurrentList);
};

/**
 * On click to row and then select this row
 * Same click to checkbox on row
 */
export const onClickRow = (record, selectedRecords, callback, rowKey = 'id') => {
  if (!(record && record[rowKey]) || !Array.isArray(selectedRecords)) return;

  try {
    let selectedRows = [];

    if (selectedRecords.length && selectedRecords.some(item => item[rowKey] === record[rowKey])) {
      selectedRows = [...selectedRecords].filter(item => item[rowKey] !== record[rowKey]);
    } else {
      selectedRows = [...selectedRecords, record];
    }

    const selectedRowKeys = selectedRows.map(item => item[rowKey]);

    callback(selectedRowKeys, selectedRows);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Convert estimated time to minute
 * For send data to server
 * Working hour: 1 week = 5 days, 1 day = 8 hours, 1 hour = 60 minutes
 *
 * Example - Before: 1w 1.2h 5d 2.5h 3.2m 8h
 * Example - After: 5505 (minutes)
 */
export const convertEstimatedTimeToMinutes = timeString => {
  if (typeof timeString === 'string') timeString = timeString.trim();

  if (!timeString || !new RegExp(ESTIMATED_TIME_PATTERN).test(timeString)) return '';

  let estimatedTime = timeString;

  if (/^(([0-9]\d*(\.\d+)?))$/.test(timeString)) {
    estimatedTime = `${+timeString}d`;
  }

  const timeArr = estimatedTime.split(' ').filter(item => item);
  let minute = 0;

  timeArr.forEach(item => {
    const num = +item.match(/\d+(\.\d+)?/g); // Get float number in string

    if (item.includes('w')) {
      minute += num * 5 * 8 * 60;
    }

    if (item.includes('d')) {
      minute += num * 8 * 60;
    }

    if (item.includes('h')) {
      minute += num * 60;
    }

    if (item.includes('m')) {
      minute += Math.round(num);
    }
  });

  return Math.round(minute);
};

/**
 * Convert minutes to short time
 * For view time on browser
 *
 * Example - Before: 630 (minutes)
 * Example - After: 1d 3h 30m
 */
export const convertMinutesToShortTime = minutes => {
  if (!minutes || !/^[0-9]*$/.test(minutes)) return '';

  const minutesInAWeek = 5 * 8 * 60;
  const minutesInADay = 8 * 60;
  const minutesInAHour = 60;

  const w = Math.floor(minutes / minutesInAWeek);
  const d = Math.floor((minutes - w * minutesInAWeek) / minutesInADay);
  const h = Math.floor((minutes - w * minutesInAWeek - d * minutesInADay) / minutesInAHour);
  const m = minutes - w * minutesInAWeek - d * minutesInADay - h * minutesInAHour;

  const timeArr = [
    { label: 'w', value: w },
    { label: 'd', value: d },
    { label: 'h', value: h },
    { label: 'm', value: m }
  ];

  const timeString = timeArr
    .filter(item => item.value >= 1)
    .map(item => `${Math.floor(item.value)}${item.label}`)
    .join(' ');

  return timeString;
};

/**
 * Count between dates
 */
export const countBetweenDates = (start, end, unit = 'days', format = SERVER_DATE_FORMAT) => {
  if (!(start && moment(start).isValid() && end && moment(end).isValid())) return;

  const startDate = moment(end, format).clone().endOf('day');
  const endDate = moment(start, format).clone().endOf('day');

  return startDate.diff(endDate, unit);
};

/**
 * Convert schedule string to date
 *
 * Example:
 *   Before: 8 10 * * 0,2,6 (Use UTC time)
 *   After: Weekly: 17:8' - Sunday, Tuesday, Saturday (For local time +7)
 *
 *   Before: 26 11 * * * (Use UTC time)
 *   After: Daily: 18:26' (For local time +7)
 *
 * Ref: https://www.npmjs.com/package/node-schedule
 * In this project, no use second
 */
export const convertScheduleStringToDate = (scheduleStr, scheduleType) => {
  if (
    !(
      scheduleStr &&
      typeof scheduleStr === 'string' &&
      (scheduleType === SCHEDULE_TYPE_DAILY || scheduleType === SCHEDULE_TYPE_WEEKLY)
    )
  ) {
    return;
  }

  let scheduleDate = null;
  let daysOfWeek = null;
  const paths = scheduleStr.split(' ');

  if (!(Array.isArray(paths) && paths.length)) return;

  if (scheduleType === SCHEDULE_TYPE_DAILY) {
    const dailyDate = moment.utc(`0 ${paths[0]} ${paths[1]}`, 's m H');
    scheduleDate = moment(dailyDate).isValid() ? moment.utc(dailyDate).local() : null;
  }

  if (scheduleType === SCHEDULE_TYPE_WEEKLY) {
    const utc = moment.utc(`0 ${paths[0]} ${paths[1]}`, 's m H');
    const utcDate = moment(utc).isValid() ? moment(utc).startOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
    const localDate = moment(utc).isValid() ? moment(utc).local().startOf('day') : null;
    const diff = localDate.diff(moment(utcDate, 'YYYY/MM/DD HH:mm:ss'), 'days');
    const dayArray = typeof paths[4] === 'string' ? paths[4].split(',') : [];

    scheduleDate = moment(utc).isValid() ? moment(utc).local() : null;
    daysOfWeek = Array.isArray(dayArray)
      ? dayArray
          .map(item => {
            let newDay = +item + diff;

            if (newDay < 0) return 6;
            if (newDay > 6) return 0;
            return newDay;
          })
          .sort()
      : [];
  }

  return {
    scheduleDate,
    daysOfWeek
  };
};

/**
 * Scroll into hash
 */
export const scrollToLocationHash = locationHash => {
  if (!locationHash) return;

  const elementId = locationHash.split('#')[1];
  const element = document.getElementById(elementId);

  if (!element) return;

  element.scrollIntoView();
};

/**
 * Scroll to first class name
 */
export const scrollToFirstClassName = (className, timer = 500) => {
  if (!className) return;

  setTimeout(() => {
    const items = document.body.getElementsByClassName(className);
    const visibleItems = [...items].filter(el => {
      return el.offsetWidth || el.offsetHeight || el.getClientRects().length;
    });

    if (visibleItems.length > 0) {
      items[0].scrollIntoView();
    }
  }, timer);
};

/**
 * Scroll to selector
 */
export const scrollToSelector = ({ selectorString, selector, delay = 500 }) => {
  if (!(selectorString || selector)) return;

  setTimeout(() => {
    let elm = null;

    if (selector && typeof selector === 'object') {
      elm = selector;
    } else {
      elm = document.querySelector(selectorString);
    }

    if (elm?.offsetWidth || elm?.offsetHeight || elm?.getClientRects().length) {
      elm?.scrollIntoView();
    }
  }, delay);
};

/**
 * Toggle full screen class name for body
 * Disable scroll of the body when full screen
 */
export const toggleFullScreenClassNameForBody = fullScreen => {
  if (fullScreen) {
    document.body.style.overflow = 'hidden';
    document.body.classList.add('full-screen-body');
  } else {
    document.body.style.overflow = null;
    document.body.classList.remove('full-screen-body');
  }
};

/**
 * Format bytes
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (isNaN(bytes) || bytes <= 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Convert milliseconds to duration
 */
export const convertMillisecondsToDuration = millisecondNumber => {
  if (isNaN(millisecondNumber) || +millisecondNumber < 50) return '0s';

  const newMilliseconds = +millisecondNumber;

  const d = Math.floor(newMilliseconds / 1000 / 86400);
  const h = Math.floor((newMilliseconds / 1000 - d * 86400) / 3600);
  const m = Math.floor((newMilliseconds / 1000 - d * 86400 - h * 3600) / 60);
  const s = newMilliseconds / 1000 - d * 86400 - h * 3600 - m * 60;

  let result = '';

  if (d > 0) {
    result = `${d}d`;
  }

  if (h > 0) {
    result = `${result ? `${result} ` : ''}${h}h`;
  }

  if (m > 0) {
    result = `${result ? `${result} ` : ''}${m}m`;
  }

  if (s > 0) {
    result = `${result ? `${result} ` : ''}${s % 1 === 0 ? s : s.toFixed(1)}s`;
  }

  return result;
};

/**
 * Get total unicode index of a string
 *
 * Example: Unicode index of "A" = 65
 */
export const getTotalUnicodeIndexOfString = str => {
  if (!(str && typeof str === 'string')) return 0;

  const total = [...Array(str.length)].reduce((accumulator, current, index) => {
    return accumulator + str.charCodeAt(index);
  }, 0);

  return total;
};

/**
 * Check valid RGB object
 */
export const checkValidRGBObject = rgb => {
  return (
    rgb &&
    new RegExp(NUMBER_PATTERN).test(rgb?.r) &&
    new RegExp(NUMBER_PATTERN).test(rgb?.g) &&
    new RegExp(NUMBER_PATTERN).test(rgb?.b) &&
    +rgb?.r >= 0 &&
    +rgb?.r <= 255 &&
    +rgb?.g >= 0 &&
    +rgb?.g <= 255 &&
    +rgb?.b >= 0 &&
    +rgb?.b <= 255
  );
};

export const getColorStatusJira = state => {
  return JIRA_COLOR_MAPPING[`jira-issue-status-${state}-color`] || null;
};

export const getBgColorStatusJira = state => {
  return JIRA_COLOR_MAPPING[`jira-issue-status-${state}-bgcolor`] || null;
};

/**
 * Check valid RGB object
 */
export const convertHexToRGBObject = hex => {
  if (!(hex && typeof hex === 'string')) return;

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
};

/**
 * Check valid RGB object
 */
export const convertRGBObjectToHex = rgb => {
  if (!checkValidRGBObject(rgb)) return;

  const hexColor = [rgb.r, rgb.g, rgb.b]
    .map(item => {
      const hex = (+item).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');

  return `#${hexColor}`;
};

/**
 * Control url param by state
 * Push state to url
 * Or remove state from url if state is falsy
 */
export const controlUrlParamByState = (state, attribute, location, history) => {
  if (!attribute || !location || !(history && typeof history.push === 'function')) {
    return;
  }

  const queryUrl = queryString.parse(location?.search);
  const newQueryUrl = { ...queryUrl };

  if (state && state !== queryUrl[attribute]) {
    // [attribute] is empty, push [attribute] to url
    newQueryUrl[attribute] = state;
    history.push({ search: queryString.stringify(newQueryUrl) });
  } else if (queryUrl[attribute] && !state) {
    // [attribute] is not empty, remove [attribute] on url
    delete newQueryUrl[[attribute]];
    history.push({ search: queryString.stringify(newQueryUrl) });
  } else {
  }
};

/**
 * Handle before submit form
 * Scroll to first error field
 */
export const handleBeforeSubmitForm = form => {
  const fieldsError = form.getFieldsError();

  if (Array.isArray(fieldsError) && fieldsError.length) {
    const firstErrorField = fieldsError.find(item => {
      return Array.isArray(item.errors) && item.errors.length;
    });

    if (firstErrorField) {
      const fieldName = firstErrorField.name[0];
      form.scrollToField(fieldName);

      return;
    }
  }

  form.submit();
};

/**
 * Get query after delete
 */
export const getQueryAfterDelete = (idsLength, helpers) => {
  if (!(helpers && helpers.getState() && Array.isArray(helpers.getState()['data']) && helpers.getState()['query'])) {
    return;
  }

  const dataLength = helpers.getState()['data'].length;
  const oldPage = helpers.getState()['query'].page;
  const page = dataLength === idsLength ? oldPage - 1 : oldPage;
  const query = { ...helpers.getState()['query'], page };

  return query;
};

/**
 * Get value for object dynamic nested
 */
export const getValueNestedObject = (obj, path) => {
  if (!path) return obj;

  const properties = path.split('.');
  const first = properties.shift();

  return obj && getValueNestedObject(obj[first], properties.join('.'));
};

/**
 * Truncate string
 */
export const truncateString = (str, num = 30) => {
  if (typeof str !== 'string' || str.length <= num) return str;

  return str.slice(0, num) + '...';
};

/**
 * encode full query paramaters to url
 */
export const encodeFilter = obj => {
  const filter = qs.stringify(obj, { encode: false });
  const encodedQuery = encodeQueryParams({ filter: StringParam }, { filter });
  const filterQuery = stringify(encodedQuery);
  return filterQuery;
};

/**
 * Parse code query string and return exact type
 */
export const parseCodeQSKeepType = params => {
  return qs.parse(params, {
    decoder(value) {
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
      }

      let keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined
      };

      if (value in keywords) {
        return keywords[value];
      }

      return value;
    }
  });
};

/**
 * Get folder id to query from tree test repository
 * @param {*} treeItem
 * @returns
 */
export const getFolderIdQuery = treeItem => {
  if (!treeItem?.subs?.length) return treeItem?._id;
  return [
    treeItem?._id,
    ...treeItem?.subs
      .map(sub => {
        return getFolderIdQuery(sub);
      })
      .flat()
  ];
};

/**
 * Custom validation date
 * @param {*} value
 * @param {*} helpers
 * @returns
 */
const customDateValidator = (value, helpers) => {
  const validFormats = ['YYYY/MM/DD', 'YYYY/mm/dd', 'dd/mm/YYYY', 'DD/MM/YYYY', 'MM/DD/YY'];

  const isValid = validFormats.some(format => {
    return joi.date().format(format).validate(value).error === undefined;
  });

  if (!isValid) {
    return helpers.error('date.invalid');
  }

  return value;
};

/**
 * Get validataion field
 * @param {*} field
 * @returns
 */
export const getValidationForField = (field = {}) => {
  let joiField;

  if (field.componentType === COMPONENT_TYPE.TIME_TRACKING) {
    return Joi.string().label(`${field.name}`);
  }

  switch (field?.refName) {
    case SYSTEM_FIELD_PRIORITY: {
      joiField = Joi.string().label(`${field.name}`);
      break;
    }

    default:
      switch (field?.dataType) {
        case COMPONENT_TYPE.NUMBER: {
          joiField = Joi.number().label(`${field.name}`);
          break;
        }
        case COMPONENT_TYPE.STRING: {
          joiField = Joi.string().label(`${field.name}`);
          break;
        }
        case COMPONENT_TYPE.BOOLEAN: {
          joiField = Joi.boolean().label(`${field.name}`);
          break;
        }
        case COMPONENT_TYPE.DATE:
        case COMPONENT_TYPE.DATE_TIME: {
          joiField = Joi.extend(DateExtension).custom(customDateValidator).label(`${field.name}`);
          break;
        }
      }
  }

  if (field.mandatory && joiField && !field.defaultValue) {
    joiField = joiField.required().label(`${field.name}`);
  }
  return joiField;
};

//This Func to handle value importByTemplate, it will check value has been already exit in system yet?
//If not it will show message for user on Preview Template
const handleValidationField = (field, excelValue, listData) => {
  let error;
  const valueImport = listData.find(item => item.name?.toUpperCase() === excelValue?.toUpperCase());
  if (field.mandatory && !excelValue) {
    error = `"${field.refName}" is required`;
  } else if (excelValue && !valueImport) {
    error = `"${excelValue}" is not in the system`;
  } else if (!excelValue && !valueImport) {
    error = 'Data is Empty';
  }
  return error;
};
export const getCustomValidationMessage = (
  field = {},
  excelValue = '',
  projectUserList = [],
  listStates = [],
  listTestEnvironment = []
) => {
  let error;

  if (field.refName === SYSTEM_FIELD_PRIORITY) {
    const priorityImport = PRIORITIES.find(p => p.label.toUpperCase() === excelValue?.toUpperCase());
    if (excelValue && !priorityImport) {
      error = `"${excelValue}" is not mapping with system priority`;
    }
    return error;
  }

  if (field.refName === SYSTEM_FIELD_LATEST_RESULT && Array.isArray(listStates) && listStates.length) {
    error = handleValidationField(field, excelValue, listStates);
    return error;
  }

  if (field.refName === SYSTEM_FIELD_TEST_CONFIG && Array.isArray(listTestEnvironment) && listTestEnvironment.length) {
    error = handleValidationField(field, excelValue, listTestEnvironment);
    return error;
  }

  switch (field?.componentType) {
    case COMPONENT_TYPE.USER: {
      const userImport = projectUserList.find(
        u =>
          u.username?.toUpperCase() === excelValue.toUpperCase() || u.email?.toUpperCase() === excelValue.toUpperCase()
      );

      if (field.mandatory && !excelValue) {
        error = `"${field.refName}" is required`;
      } else if (excelValue && !userImport) {
        error = `"${excelValue}" is not in the system`;
      } else if (!excelValue && !userImport) {
        // error = 'Data is Empty';
      }

      return error;
    }

    case COMPONENT_TYPE.STATUS: {
      const statusImport = listStates.find(u => u.name?.toUpperCase() === excelValue?.toUpperCase());

      if (field.mandatory && !excelValue) {
        error = `"${field.refName}" is required`;
      } else if (excelValue && !statusImport) {
        error = `"${excelValue}" is not in the system`;
      } else if (!excelValue && !statusImport) {
        error = 'Data is Empty';
      }

      return error;
    }

    case COMPONENT_TYPE.OPTION: {
      const statusImport = field?.data?.find(u => u.label?.toUpperCase() === excelValue?.toUpperCase());

      if (field.mandatory && !excelValue) {
        error = `"${field.refName}" is required`;
      } else if (excelValue && !statusImport) {
        error = `"${excelValue}" is not in the system`;
      } else if (!excelValue && !statusImport) {
        error = 'Data is Empty';
      }

      return error;
    }

    case COMPONENT_TYPE.TIME_TRACKING: {
      const timeTracking = convertEstimatedTimeToMinutes(excelValue);

      if (field.mandatory && !excelValue) {
        error = `"${field.refName}" is required`;
      } else if (excelValue && !timeTracking) {
        error = `"${excelValue}" must be type time tracking. eg: 3w 4d 12h`;
      } else if (!excelValue) {
        error = 'Data is Empty';
      }

      return error;
    }

    default:
      break;
  }
};

export const parseToPrimitive = value => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value.toString();
  }
};

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

/**
 * For draftjs: Convert raw html to content state
 */
export const convertRawHtmlToContentState = ({
  rawHtml,
  token,
  hasAddTokenToEntityMaps,
  hasRemoveTokenToEntityMaps
}) => {
  if (!rawHtml) return;

  let cleanRawHtml = DOMPurify.sanitize(rawHtml);

  try {
    if (/<figure>/g.test(cleanRawHtml)) {
      // Replace <figure></figure> tag to <p></p> tag
      cleanRawHtml = cleanRawHtml.replace(/<figure[^<>]+>/g, '<p>').replace(/<\/figure>/g, '</p>');
    }

    if (!/^<p>/.test(cleanRawHtml)) {
      // draft js requires p tag
      cleanRawHtml = `<p>${cleanRawHtml}</p>`;
    }

    const blocksFromHtml = htmlToDraft(cleanRawHtml);
    let contentState = ContentState.createFromBlockArray(blocksFromHtml?.contentBlocks, blocksFromHtml?.entityMap);

    if (hasAddTokenToEntityMaps) {
      contentState = addTokenToEntityMaps({ contentState, token });
    }

    if (hasRemoveTokenToEntityMaps) {
      contentState = removeTokenToEntityMaps({ contentState });
    }

    return contentState;
  } catch (err) {
    console.error(err);
  }
};

/**
 * For draftjs: Convert raw html to plain text
 */
export const convertRawHtmlToPlainText = rawHtml => {
  if (!rawHtml) return '';

  const contentState = convertRawHtmlToContentState({ rawHtml });
  const plainText = contentState ? contentState.getPlainText() : '';

  return plainText;
};

/**
 * For draftjs: Add token to entity maps
 *    + Add token to image
 */
export const addTokenToEntityMaps = ({ contentState, token }) => {
  if (!contentState) return;

  if (!token) return contentState;

  try {
    let newContentState = contentState;
    const rawContent = convertToRaw(contentState);

    if (
      Object.keys(rawContent?.entityMap).length &&
      Object.keys(rawContent?.entityMap).some(key => {
        const item = rawContent?.entityMap[key];
        return item?.type === 'IMAGE' && item.data?.src && typeof item.data?.src === 'string';
      })
    ) {
      Object.keys(rawContent?.entityMap).forEach(key => {
        const item = rawContent?.entityMap[key];

        if (item?.type === 'IMAGE' && item.data?.src && typeof item.data?.src === 'string') {
          rawContent.entityMap[key].data = {
            ...item.data,
            src: `${item.data.src.split('?')[0]}?authorization=${token}`
          };
        }
      });

      newContentState = convertFromRaw(rawContent);
    }

    return newContentState;
  } catch (err) {
    console.error(err);
  }
};

/**
 * For draftjs: Remove token to entity maps
 *    + Remove token to image
 */
export const removeTokenToEntityMaps = ({ contentState }) => {
  if (!contentState) return;

  try {
    let newContentState = contentState;
    const rawContent = convertToRaw(contentState);

    if (
      Object.keys(rawContent?.entityMap).length &&
      Object.keys(rawContent?.entityMap).some(key => {
        const item = rawContent?.entityMap[key];
        return item?.type === 'IMAGE' && item.data?.src && /\?authorization=/.test(item.data?.src);
      })
    ) {
      Object.keys(rawContent?.entityMap).forEach(key => {
        const item = rawContent?.entityMap[key];

        if (item?.type === 'IMAGE' && item.data?.src && /\?authorization=/.test(item.data?.src)) {
          rawContent.entityMap[key].data = {
            ...item.data,
            src: item.data.src.split(/\?authorization=/)[0]
          };
        }
      });

      newContentState = convertFromRaw(rawContent);
    }

    return newContentState;
  } catch (err) {
    console.error(err);
  }
};

/**
 * For draftjs: Add token to HTML
 *    + Add token to image
 */
export const addTokenToRawHtml = ({ rawHtml, token }) => {
  if (!rawHtml) return null;

  if (!token) return rawHtml;

  const contentState = convertRawHtmlToContentState({ rawHtml, token, hasAddTokenToEntityMaps: true });
  const newRawHtml = contentState ? draftToHtml(convertToRaw(contentState)) : null;

  return newRawHtml;
};

/**
 * For draftjs: Remove token to HTML
 *    + Remove token from image
 */
export const removeTokenToRawHtml = ({ rawHtml }) => {
  if (!rawHtml) return null;

  const contentState = convertRawHtmlToContentState({ rawHtml, hasRemoveTokenToEntityMaps: true });
  const newRawHtml = contentState ? draftToHtml(convertToRaw(contentState)) : null;

  return newRawHtml;
};

/**
 * Convert Base64 to file
 */
export const convertBase64ToFile = async ({ base64, preFileName, mimeType }) => {
  try {
    if (base64.startsWith('data:')) {
      const arr = base64.split(',');
      const newMimeType = arr[0].match(/:(.*?);/)[1] || mimeType;
      const extension = mime.extension(newMimeType);
      const defaultPreFileName = `file-${moment().format('YYYY-MM-DD-HH-mm-ss-SSS')}`;
      const fileName = `${preFileName || defaultPreFileName}${extension ? `.${extension}` : ''}`;

      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const file = new File([u8arr], fileName, { type: newMimeType });

      return Promise.resolve(file);
    } else {
      const extension = mime.extension(mimeType);
      const defaultPreFileName = `file-${moment().format('YYYY-MM-DD-HH-mm-ss-SSS')}`;
      const fileName = `${preFileName || defaultPreFileName}${extension ? `.${extension}` : ''}`;

      return fetch(base64)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], fileName, { type: mimeType }));
    }
  } catch (err) {
    console.error(err);
    return false;
  }
};

/**
 * Get file list from pasted base64 text
 */
export const getFileListFromPastedBase64Text = async ({ text, preFileName }) => {
  if (!new RegExp(BASE64_IMAGE_PATTERN).test(text)) return;

  try {
    const base64SrcList = text.match(BASE64_IMAGE_PATTERN).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));

    const newFileList = await Promise.all(
      base64SrcList.map(async base64 => {
        const arr = base64.split(',');
        const mimeType = arr[0].match(/:(.*?);/)[1];
        const defaultPreFileName = `image-${moment().format('YYYY-MM-DD-HH-mm-ss-SSS')}`;
        const res = await convertBase64ToFile({
          base64,
          preFileName: preFileName || defaultPreFileName,
          mimeType
        });

        return res;
      })
    );

    return newFileList;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get mime type from base64
 */
export const getMimeTypeFromBase64 = base64 => {
  if (!base64.startsWith('data:')) return;

  const arr = base64.split(',');
  const mimeType = arr[0].match(/:(.*?);/)[1];

  if (!mime.extension(mimeType)) return;

  return mimeType;
};

export function escapeRegExpMongodb(string) {
  if (!string || typeof string !== 'string') return '';
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function sortByAttribute(array, attr, sortBy = 'DESC') {
  sortBy = sortBy.toUpperCase();
  let newArray = array;

  if (sortBy === 'ASC') {
    newArray = array.sort((a, b) => {
      if (a[attr] > b[attr]) return 1;
      if (a[attr] < b[attr]) return -1;
      return 0;
    });
  } else if (sortBy === 'DESC') {
    newArray = array.sort((a, b) => {
      if (a[attr] < b[attr]) return 1;
      if (a[attr] > b[attr]) return -1;
      return 0;
    });
  } else {
  }

  return newArray;
}

/**
 * Get base64 from blob/input file
 */
export const getBase64FromBlob = async blob => {
  if (!blob) return;

  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
    reader.onerror = error => {
      console.error(error);
      reject(error);
    };
  });

  return base64;
};

/**
 * Get full file info from input file
 */
export const getFullFileInfoFromInputFile = async ({ file, hasGetBase64 = true }) => {
  if (!file) return;

  try {
    const newFileInfo = {};

    const fileName = file.name;
    const type = mime.lookup(fileName);
    const extensionInFilename = /\./.test(fileName) ? fileName.split('.').pop() : undefined;

    newFileInfo.file = file;
    newFileInfo.id = uuidv4();
    newFileInfo.src = hasGetBase64 ? await getBase64FromBlob(file) : null;
    newFileInfo.fileName = file.name;
    newFileInfo.type = type || extensionInFilename;
    newFileInfo.extension = extensionInFilename;
    newFileInfo.createdAt = moment().format();
    newFileInfo.size = file.size;

    return newFileInfo;
  } catch (error) {
    console.error(error);
    return error;
  }
};

/**
 * Filter option for user field
 */
export const filterOptionForUserField = (keyword, option) => {
  let firstName = option?.item?.firstName;
  let lastName = option?.item?.lastName;

  keyword = `${typeof keyword === 'string' && keyword ? keyword : ''}`;
  firstName = `${typeof firstName === 'string' && firstName ? `${firstName} ` : ''}`;
  lastName = `${typeof lastName === 'string' && lastName ? `${lastName} ` : ''}`;

  return (
    (typeof option?.value === 'string' && option?.value.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
    (firstName + lastName).toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
    (typeof option?.item?.name === 'string' &&
      option?.item?.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
    (typeof option?.item?.email === 'string' &&
      option?.item?.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1) ||
    (typeof option?.item?.username === 'string' &&
      option?.item?.username.toLowerCase().indexOf(keyword.toLowerCase()) !== -1)
  );
};

/**
 * Convert to full name
 */
export const convertToFullName = user => {
  if (!user?.id) return '';

  const firstName = `${user.firstName && typeof user.firstName === 'string' ? `${user.firstName} ` : ''}`;
  const lastName = `${user.lastName && typeof user.lastName === 'string' ? `${user.lastName}` : ''}`;

  return firstName + lastName;
};

/**
 * Convert user for submit data
 */
export const convertUserForSubmitData = user => {
  if (!user?.id) return {};

  const avatar = Array.isArray(user.attributes?.avatar) && user.attributes?.avatar[0] ? user.attributes?.avatar[0] : '';

  return {
    id: user.id,
    username: user.username,
    name: user.name || convertToFullName(user) || user.email || user.username,
    email: user.email,
    avatar: user.avatar || avatar
  };
};

/**
 * Mapping data for preview chart
 */
export const mappingDataForPreviewChart = ({ previewPayload, previewChart }) => {
  if (!(Array.isArray(previewChart) && Array.isArray(previewChart?.[0]?.data))) return previewChart;

  let newData = previewChart;

  // Convert display to string
  const convertDisplayToString = ({ display, labelField, componentType }) => {
    let displayStr = display;

    switch (labelField) {
      default: {
        switch (componentType) {
          case COMPONENT_TYPE.TIME_TRACKING: {
            displayStr = convertMinutesToShortTime(display) || i18next.t('akaat:common.unestimated');
            break;
          }

          case COMPONENT_TYPE.USER: {
            displayStr = display?.username ? display?.username : display;
            break;
          }

          case COMPONENT_TYPE.STATUS: {
            displayStr = display?.name ? display?.name : display;
            break;
          }

          case COMPONENT_TYPE.PRIORITY: {
            displayStr = getObjectByValue(display, PRIORITIES)?.label;
            break;
          }

          default:
            break;
        }

        break;
      }
    }

    return displayStr;
  };

  // Check if need to convert
  const checkIfNeedToConvert = componentType => {
    return (
      componentType === COMPONENT_TYPE.TIME_TRACKING ||
      componentType === COMPONENT_TYPE.USER ||
      componentType === COMPONENT_TYPE.STATUS ||
      componentType === COMPONENT_TYPE.PRIORITY
    );
  };

  // For: Grouped, Stacked
  if (checkIfNeedToConvert(previewPayload?.group?.componentType)) {
    newData = [...previewChart].map(item => {
      const display = convertDisplayToString({
        display: item?.label?.display,
        labelField: previewPayload?.group?.labelField,
        componentType: previewPayload?.group?.componentType
      });
      let data = Array.isArray(item?.data) && item?.data.length ? [...item?.data] : [];

      if (checkIfNeedToConvert(previewPayload?.x?.componentType)) {
        data = data.map(sub => {
          const display = convertDisplayToString({
            display: sub?.x?.display,
            labelField: previewPayload?.x?.labelField,
            componentType: previewPayload?.x?.componentType
          });

          return {
            ...sub,
            x: {
              ...sub?.x,
              display
            }
          };
        });
      }

      return {
        ...item,
        data,
        label: {
          ...item?.label,
          display
        }
      };
    });
  }

  // X Axis
  else if (checkIfNeedToConvert(previewPayload?.x?.componentType)) {
    newData = [...previewChart].map(item => {
      if (!(Array.isArray(item?.data) && item?.data.length)) return item;

      return {
        ...item,
        data: [...item?.data].map(sub => {
          const display = convertDisplayToString({
            display: sub?.x?.display,
            labelField: previewPayload?.x?.labelField,
            componentType: previewPayload?.x?.componentType
          });

          return {
            ...sub,
            x: {
              ...sub?.x,
              display
            }
          };
        })
      };
    });
  } else {
  }

  return newData;
};

/**
 * Get default chart label
 */
export const getDefaultChartLabel = refName => {
  let label = '';

  if (refName === SYSTEM_FIELD_LATEST_RESULT) {
    label = TEST_RESULT_STATUS_NOT_EXECUTED;
  } else if (refName === SYSTEM_FIELD_ASSIGN_TO) {
    label = i18next.t('akaat:common.unassigned');
  } else {
    label = i18next.t('akaat:common.unknown');
  }

  return label;
};

/**
 * Get chart colors
 */
export const getChartColors = ({ previewPayload, previewChart, editingChart, ticketListData, chartInfo }) => {
  if (!previewPayload || !previewChart || !ticketListData || !chartInfo) return;

  let workTicket = null;
  let colors = null;

  if (
    previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT ||
    previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT
  ) {
    workTicket = ticketListData?.[WORK_ITEM_TEST_RESULT_ID];
  } else {
    workTicket = ticketListData?.[previewPayload?.source];
  }

  const hasListState = Array.isArray(workTicket?.workFlow?.listStates) && workTicket?.workFlow?.listStates.length;
  const defaultState = workTicket?.workFlow?.defaultState;

  const isMutipleData =
    previewPayload?.x?.valueField &&
    previewPayload?.group?.valueField &&
    Array.isArray(previewChart) &&
    previewChart.length;

  const isSingleData =
    previewPayload?.x?.valueField &&
    !previewPayload?.group?.valueField &&
    Array.isArray(previewChart) &&
    previewChart.length === 1 &&
    Array.isArray(previewChart[0]?.data) &&
    previewChart[0]?.data.length;

  // For single line
  if (chartInfo.chartType === 'line' && chartInfo.childrenChartType === 'single') {
    const hasColors = editingChart?._id && Array.isArray(editingChart?.colors) && editingChart?.colors.length;

    colors = [{ value: hasColors ? editingChart?.colors[0]?.value : TEST_ITEM_COLORS[0] }];
  }

  // For mutiple: grouped, stacked
  // Fixed color when type is status
  else if (
    hasListState &&
    isMutipleData &&
    (previewPayload?.group?.refName === SYSTEM_FIELD_STATUS ||
      previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT)
  ) {
    colors = previewChart.map((item, idx) => {
      const state = workTicket?.workFlow?.listStates.find(s => s?.id === item?.label?.value) || defaultState;
      const value = state ? convertRGBObjectToHex(state.background) : TEST_ITEM_COLORS[idx % TEST_ITEM_COLORS.length];

      return { label: state?.name || '', value };
    });
  }

  // For single
  // Fixed color when type is status
  else if (
    hasListState &&
    isSingleData &&
    (previewPayload?.x?.refName === SYSTEM_FIELD_STATUS || previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT)
  ) {
    colors = previewChart[0]?.data.map((item, idx) => {
      const state = workTicket?.workFlow?.listStates.find(s => s?.id === item?.x?.value) || defaultState;
      const value = state ? convertRGBObjectToHex(state.background) : TEST_ITEM_COLORS[idx % TEST_ITEM_COLORS.length];

      return { label: state?.name || '', value };
    });
  }

  // For detail chart: Validate color list
  else if (
    editingChart?._id &&
    Array.isArray(editingChart?.colors) &&
    editingChart?.colors.length &&
    editingChart?.colors.every(c => c?.value) &&
    ((isMutipleData && editingChart?.colors.length === removeDuplicate([...previewChart], 'label').length) ||
      (isSingleData && editingChart?.colors.length === removeDuplicate([...previewChart[0]?.data], 'x').length))
  ) {
    colors = [...editingChart?.colors];
  }

  // For mutiple: grouped, stacked
  else if (isMutipleData) {
    const newData = removeDuplicate([...previewChart], 'label');

    colors = newData.map((item, idx) => {
      const label = item?.label?.display || getDefaultChartLabel(previewPayload?.group?.refName);

      return { label, value: TEST_ITEM_COLORS[idx % TEST_ITEM_COLORS.length] };
    });
  }

  // For single
  else if (isSingleData) {
    const newData = removeDuplicate([...previewChart[0]?.data], 'x');

    colors = newData.map((item, idx) => {
      const label = item?.x?.display || getDefaultChartLabel(previewPayload?.x?.refName);

      return { label, value: TEST_ITEM_COLORS[idx % TEST_ITEM_COLORS.length] };
    });
  }

  // Else
  else {
    colors = TEST_ITEM_COLORS.map(value => ({ value }));
  }

  return colors;
};

/**
 * Get pre path link to ticket
 */
export const getPrePathLinkToTicket = ({ workTicketId, noGoToTestcaseVersion }) => {
  const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  let preLink;

  switch (workTicketId) {
    case WORK_ITEM_TESTCASE_ID: {
      if (noGoToTestcaseVersion) {
        preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo/detail/`;
      } else {
        preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo/testcase-version/`;
      }

      break;
    }

    case WORK_ITEM_TEST_RUN_ID: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan/detail/`;
      break;
    }

    case WORK_ITEM_TEST_RESULT_ID: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/test-data/detail/`;
      break;
    }

    default: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${workTicketId}/detail/`;
    }
  }

  return preLink || '';
};

/**
 * Get pre path link to ticket list
 */
export const getPrePathLinkToTicketList = ({ workTicketId }) => {
  const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  let preLink;

  switch (workTicketId) {
    case WORK_ITEM_TESTCASE_ID: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo`;

      break;
    }

    case WORK_ITEM_TEST_RUN_ID: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan`;
      break;
    }

    case WORK_ITEM_TEST_RESULT_ID: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/test-data`;
      break;
    }

    default: {
      preLink = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${workTicketId}`;
    }
  }

  return preLink || '';
};

/**
 * Get fields by box
 * For detail page
 */
export const getFieldsByBox = fieldList => {
  if (!(Array.isArray(fieldList) && fieldList.length)) return;

  const newBox = {
    detail: [],
    people: [],
    date: []
  };

  fieldList.forEach(field => {
    switch (field?.refName) {
      case SYSTEM_FIELD_DESCRIPTION:
      case SYSTEM_FIELD_TEST_STEPS: {
        break;
      }

      default: {
        switch (field?.componentType) {
          case COMPONENT_TYPE.USER: {
            newBox.people.push(field);
            break;
          }

          case COMPONENT_TYPE.TIME_TRACKING: {
            newBox.date.push(field);
            break;
          }

          case COMPONENT_TYPE.DATE:
          case COMPONENT_TYPE.DATE_TIME: {
            newBox.date.push(field);
            break;
          }

          default: {
            newBox.detail.push(field);
            break;
          }
        }
      }
    }
  });

  return newBox;
};

/**
 * Get scrollbar width
 */
export const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');

  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

/**
 * Get resize column
 */
export const getResizeColumns = ({ activeColumns, setActiveColumns, onResizeStart, onResizeStop }) => {
  return Array.isArray(activeColumns) && activeColumns.length
    ? activeColumns.map((col, index) => {
        return {
          ...col,
          onHeaderCell: column => ({
            width: column?.width,
            onResize: (_, { size }) => {
              const newActiveColumns = [...activeColumns];

              newActiveColumns[index] = {
                ...newActiveColumns[index],
                width: size.width
              };

              if (typeof setActiveColumns === 'function') setActiveColumns(newActiveColumns);
            },
            onResizeStart: (e, data) => {
              const scrollbarWidth = getScrollbarWidth();
              document.body.classList.add('resizing');
              document.body.style.paddingRight = scrollbarWidth ? `${scrollbarWidth}px` : null;
              document.body.style.overflow = 'hidden';

              if (typeof onResizeStart === 'function') onResizeStart(e, data);
            },
            onResizeStop: (e, data) => {
              setTimeout(() => {
                document.body.classList.remove('resizing');
                document.body.style.paddingRight = null;
                document.body.style.overflow = null;
              }, 200);

              if (typeof onResizeStop === 'function') onResizeStop(e, data);
            }
          })
        };
      })
    : [];
};

/**
 * Handle set test step to table form
 */
export const handleSetTestStepToTableForm = ({ tableForm, parentKey, testStep = {} }) => {
  if (!tableForm || !parentKey || !testStep) return;

  const step = `key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${FIELD_STEP}-${testStep.orderId}`;
  const testData = `key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${FIELD_TEST_DATA}-${testStep.orderId}`;
  const expectedResult = `key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${FIELD_EXPECTED_RESULT}-${testStep.orderId}`;
  const attachments = `key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${FIELD_ATTACHMENTS}-${testStep.orderId}`;
  const stepResult = `key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${FIELD_STEP_RESULT}-${testStep.orderId}`;

  tableForm.setFieldsValue({
    [step]: testStep[FIELD_STEP],
    [testData]: testStep[FIELD_TEST_DATA],
    [expectedResult]: testStep[FIELD_EXPECTED_RESULT],
    [attachments]: testStep[FIELD_ATTACHMENTS],
    [stepResult]: testStep.status?.id || WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED
  });
};

/**
 * Sleep
 */
export const sleep = ms => new Promise(r => setTimeout(r, ms));

/**
 * Handle track event azure with duration
 */
export const trackEventAzureWithDuration = (appInsights, name, properties = {}) => {
  const startTime = new Date(); // event recording start time

  // Log event via appInsights.trackEvent()
  appInsights.trackEvent({
    name: name
  });

  const endTime = new Date(); // event recording end time
  let duration = endTime - startTime;

  // Record the event execution time information in the event properties
  appInsights.trackEvent({
    name: name,
    properties: {
      ...properties,
      timeDuration: duration
    }
  });
};

/**
 * Check ticket type for relation field
 */
export const checkTicketTypeForRelationField = ({ fieldList, ticketListData }) => {
  if (!(Array.isArray(fieldList) && fieldList.length)) return;

  const relationFields = fieldList.filter(field => field?.componentType === COMPONENT_TYPE.RELATION);

  const relationWorkTicketIds = removeDuplicate(
    relationFields.map(field => field?.lookup?.workTicketId),
    ''
  );

  const valid = relationWorkTicketIds.every(workTicketId => ticketListData?.[workTicketId]?.id);

  return valid;
};

/**
 * Check project owner
 */
export const checkProjectOwner = ({ globalUserInfo, projectUserList }) => {
  if (!globalUserInfo?.id || !(Array.isArray(projectUserList) && projectUserList.length)) return;

  const currentUser = projectUserList.find(item => item?.username === globalUserInfo.username);

  return currentUser?.role?.roleKey === ROLE_PROJECT_OWNER;
};

export const compareAgentVersions = (version1, version2) => {
  if (!version2 || !version1) return -1;

  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  const minLength = Math.min(v1.length, v2.length);

  for (let i = 0; i < minLength; i++) {
    if (v1[i] < v2[i]) {
      return -1; // phiên bản 1 nhỏ hơn phiên bản 2
    } else if (v1[i] > v2[i]) {
      return 1; // phiên bản 1 lớn hơn phiên bản 2
    }
  }

  // Nếu các phần từ đến độ dài ngắn nhất đều bằng nhau, so sánh độ dài của các phiên bản
  if (v1.length < v2.length) {
    return -1; // phiên bản 1 ngắn hơn phiên bản 2
  } else if (v1.length > v2.length) {
    return 1; // phiên bản 1 dài hơn phiên bản 2
  }

  return 1; // Các phiên bản bằng nhau
};

// Function to move object to the first position based on a key
export function moveObjectToFirst(array, key, value) {
  const index = array.findIndex(obj => obj[key] === value);
  if (index !== -1) {
    // Remove the object from its current position
    const removedItem = array.splice(index, 1)[0];
    // Add the object at the first position
    array.unshift(removedItem);
  }
  return array;
}

/**
 * Check is user in project
 */
export const checkIsUserInProject = ({ user, projectUserList }) => {
  if (!user?.id || !(Array.isArray(projectUserList) && projectUserList.length)) return;

  return projectUserList.some(u => u?.username === user.username);
};

/**
 * Get user list by role from store
 */
export const getUserListByRoleFromStore = ({ roleKeys, userListByRole }) => {
  const newUserListByRole = userListByRole?.[JSON.stringify(roleKeys)];
  const userList = Array.isArray(newUserListByRole) && newUserListByRole.length ? [...newUserListByRole] : [];

  return userList;
};

/**
 * Mapping jira user
 */
export function mappingJiraUser(info) {
  return {
    ...info,
    label: info?.displayName || info?.name,
    value: info?.accountId || info?.key,
    id: info?.accountId || info?.key,
    email: info?.emailAddress,
    username: info?.emailAddress || slug(info?.displayName || '') || slug(info?.name || ''),
    fullName: info?.displayName || info?.name,
    systemType: JIRA_PLATFORM_ID,
    attributes: {
      avatar: [
        info.avatarUrls['24x24'] || info.avatarUrls['16x16'] || info.avatarUrls['32x32'] || info.avatarUrls['48x48']
      ]
    }
  };
}

/**
 * Covert to utc local
 */
export const convertTimeToUtc = (date, type) => {
  if (date) {
    if (type === 'start') {
      return moment(date).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    } else {
      return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    }
  }
};

export function covertToUtcLocal(time) {
  let convertTime = moment.utc(time);

  const localTimeZoneOffset = moment().utcOffset();
  const localTime = convertTime.clone().subtract(localTimeZoneOffset, 'minutes');
  const utcFormatted = localTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

  return utcFormatted;
}

/**
 * Toggle editing class to body
 */
export const toggleEditingClassToBody = ({ isEditing }) => {
  const body = document.body;
  const hasEditingClass = body?.classList.contains('is-editing-cell');

  if (isEditing && !hasEditingClass) {
    body?.classList.add('is-editing-cell');
  } else if (!isEditing && hasEditingClass) {
    body?.classList.remove('is-editing-cell');
  }
};

/**
 * Toggle editing test step class to body
 */
export const toggleEditingTestStepClassToBody = ({ isEditing }) => {
  const body = document.body;
  const hasEditingClass = body?.classList.contains('is-editing-test-step-field');

  if (isEditing && !hasEditingClass) {
    body?.classList.add('is-editing-test-step-field');
  } else if (!isEditing && hasEditingClass) {
    body?.classList.remove('is-editing-test-step-field');
  }
};

/**
 * Toggle editing class to expanded table
 */
export const toggleEditingClassToExpandedTable = ({ isEditing, parentKey }) => {
  if (!parentKey) return;

  const tables = document.querySelectorAll(`.children-table-on-expanded-row[data-parent-row-key="${parentKey}"]`);

  if (!tables.length) return;

  tables.forEach(elm => {
    const hasEditingClass = elm?.classList.contains('is-editing-test-step-table');

    if (isEditing && !hasEditingClass) {
      elm?.classList.add('is-editing-test-step-table');
    } else if (!isEditing && hasEditingClass) {
      elm?.classList.remove('is-editing-test-step-table');
    }
  });
};

/**
 * Toggle editing class to test step row
 */
export const toggleEditingClassToTestStepRow = ({ isEditing, x }) => {
  if (isNaN(x)) return;

  const tr = document.querySelector(`[data-x="${x}"]`)?.closest('tr');

  if (!tr) return;

  const hasEditingClass = tr.classList.contains('is-editing-row');

  if (isEditing && !hasEditingClass) {
    tr.classList.add('is-editing-row');
  } else if (!isEditing && hasEditingClass) {
    tr.classList.remove('is-editing-row');
  }
};

/**
 * Remove session for editing cell
 */
export const removeSessionForEditingCell = ({ isEditing, row, field }) => {
  if (!field?.refName) return;

  // After exit editing
  if (!isEditing) {
    // For: Loaded user list by role info
    const userListByRoleInfoLocal = reactSessionStorage.getObject(SS_LOADED_USER_LIST_BY_ROLE_INFO, null);
    if (userListByRoleInfoLocal) reactSessionStorage.remove(SS_LOADED_USER_LIST_BY_ROLE_INFO);

    // For: Loaded all suggestion info
    const allSuggestionInfoLocal = reactSessionStorage.getObject(SS_LOADED_ALL_SUGGESTION_INFO, null);
    if (allSuggestionInfoLocal) reactSessionStorage.remove(SS_LOADED_ALL_SUGGESTION_INFO);

    // Remove editing cell session
    if (!row?.isNew) reactSessionStorage.remove(SS_EDITING_CELL);
  } else if (
    isEditing &&
    !row?.isNew &&
    !(field.refName === SYSTEM_FIELD_ASSIGN_TO || field.componentType === COMPONENT_TYPE.SUGGESTION)
  ) {
    reactSessionStorage.remove(SS_EDITING_CELL);
  }
};

/**
 * Remove loading icon on editable row
 */
export const removeLoadingIconOnEditableRow = () => {
  const elements = document.querySelectorAll('.btn-action-editable-cell-wrapper .loading-icon.show-loading');

  if (!elements.length) return;

  elements.forEach(elm => {
    elm?.classList.remove('show-loading');
  });
};

/**
 * Reset error fields
 */
export const resetErrorFields = form => {
  if (!form) return;

  const errors = form.getFieldsError();

  if (Array.isArray(errors) && errors.length && errors.some(e => Array.isArray(e?.errors) && e?.errors.length)) {
    const errorFields = errors
      .filter(e => Array.isArray(e?.errors) && e?.errors.length && Array.isArray(e?.name) && e?.name.length)
      .map(e => e?.name[0]);

    form.resetFields(errorFields);
  }
};

/**
 * Generate short unique id
 */
export const generateShortUniqueId = () => {
  return Math.random().toString(36).substring(2, 12);
};

/**
 * Generate test step key
 */
export const generateTestStepKey = parentKey => {
  return `${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-${generateShortUniqueId()}`;
};

/**
 * Handle set one last saved value
 */
export const handleSetLastSavedValueToSession = formData => {
  if (!formData) return;

  const oldLastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});

  reactSessionStorage.setObject(SS_LAST_SAVED_VALUE, { ...oldLastSavedValue, ...formData });
};

/**
 * Remove last saved value by RegExp
 */
export const removeLastSavedValueByRegExp = regExp => {
  if (!regExp) return;

  const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});

  Object.keys(lastSavedValue)
    .filter(k => new RegExp(regExp, 'g').test(k))
    .forEach(k => delete lastSavedValue[k]);

  reactSessionStorage.setObject(SS_LAST_SAVED_VALUE, lastSavedValue);
};

/**
 * Handle set new record values to session
 */
export const handleSetNewRecordValuesToSession = newItems => {
  if (!(Array.isArray(newItems) && newItems.length)) return;

  const oldRecordsInSession = reactSessionStorage.getObject(SS_NEW_RECORDS, {});
  const newRecords = {};

  newItems.forEach(item => {
    if (!item?.key) return;

    newRecords[item.key] = item;
  });

  reactSessionStorage.setObject(SS_NEW_RECORDS, { ...oldRecordsInSession, ...newRecords });
};

/**
 * Get field config for modal config grid view
 */
export const getFieldConfigForModalConfigGridView = ({ treeKey, tree, workTicketId, ticketListData }) => {
  if (!treeKey || !(Array.isArray(tree) && tree.length) || !ticketListData?.[workTicketId]?.id) {
    return {};
  }

  const treeItem = findItemAndParentsOnTree(tree, treeKey);

  const itemConfig = treeItem?.item?.fieldConfig;
  let parentConfig = {};

  const parents = Array.isArray(treeItem?.parentList) && treeItem?.parentList.length ? [...treeItem?.parentList] : [];

  parents.forEach(p => {
    if (p?.fieldConfig) parentConfig = p?.fieldConfig;
  });

  const newFieldConfig = {
    ...(ticketListData?.[workTicketId]?.fieldConfig || {}),
    ...(parentConfig || {}),
    ...(itemConfig || {})
  };

  return newFieldConfig;
};

/**
 * Get latest result status by test step status list
 */
export const getLatestResultStatusIdByTestStepStatusList = testSteps => {
  if (!(Array.isArray(testSteps) && testSteps.length)) return;

  let latestResultStatusId = null;
  const firstStatusId = testSteps[0].status;

  // All test steps have the same status
  if (testSteps.every(s => s?.status === firstStatusId)) {
    latestResultStatusId = firstStatusId;
  }

  // If 1 test step is set to FAIL, then the Execution will be set to FAIL
  else if (testSteps.some(s => s?.status === WORK_ITEM_TEST_RESULT_STATUS_FAIL)) {
    latestResultStatusId = WORK_ITEM_TEST_RESULT_STATUS_FAIL;
  }

  // If 1 test step is set to BLOCKED but there are no FAILs, then the Execution will be set to BLOCKED
  else if (
    testSteps.some(s => s?.status === WORK_ITEM_TEST_RESULT_STATUS_BLOCKED) &&
    testSteps.every(s => s?.status !== WORK_ITEM_TEST_RESULT_STATUS_FAIL)
  ) {
    latestResultStatusId = WORK_ITEM_TEST_RESULT_STATUS_BLOCKED;
  }

  // If 1 test step is set to WIP/PASS but there are no FAILs and nothing BLOCKED, then the Execution will be set to WIP
  else if (
    testSteps.some(
      s => s?.status === WORK_ITEM_TEST_RESULT_STATUS_WIP || s?.status === WORK_ITEM_TEST_RESULT_STATUS_PASS
    ) &&
    testSteps.every(
      s => s?.status !== WORK_ITEM_TEST_RESULT_STATUS_FAIL && s?.status !== WORK_ITEM_TEST_RESULT_STATUS_BLOCKED
    )
  ) {
    latestResultStatusId = WORK_ITEM_TEST_RESULT_STATUS_WIP;
  }

  // If 1 test step is set to custom status, the remaining test steps are set to NOT_EXECUTED
  // Then the Execution will be set to custom status
  else if (
    testSteps.every(
      s =>
        s?.status !== WORK_ITEM_TEST_RESULT_STATUS_PASS &&
        s?.status !== WORK_ITEM_TEST_RESULT_STATUS_FAIL &&
        s?.status !== WORK_ITEM_TEST_RESULT_STATUS_WIP &&
        s?.status !== WORK_ITEM_TEST_RESULT_STATUS_BLOCKED
    )
  ) {
    const found = testSteps.find(s => s?.status !== WORK_ITEM_TEST_RESULT_STATUS_NOT_EXECUTED);
    latestResultStatusId = found?.status;
  }

  return latestResultStatusId;
};

export const recognizeExtensions = (
  list,
  // tenantPath, projectPath
  project
) => {
  const extension = list?.find(obj => obj?.id === TESTFLOW_EXTENSION_ID);

  return (
    extension?.project.length > 0 &&
    extension?.project.some(info => info.projectKey === project?.projectKey && info.tenantKey === project?.tenantKey)
  );
};

/**
 * Check valid field
 */
export const checkValidField = async ({ form, formItemName }) => {
  try {
    if (typeof form?.validateFields !== 'function' || !formItemName) return false;

    const valid = await form.validateFields([formItemName]);

    return valid;
  } catch (_) {
    return false;
  }
};

export const addTimeWorkingPlan = times => {
  return times.map(timestamp => {
    const serverOffsetInMinutes = moment().utcOffset();
    const serverOffsetInHours = (serverOffsetInMinutes / 60) * 3600 * 1000;
    return timestamp + serverOffsetInHours;
  });
};

export const subtractTimeWorkingPlan = times => {
  return times.map(timestamp => {
    const serverOffsetInMinutes = moment().utcOffset();
    const serverOffsetInHours = (serverOffsetInMinutes / 60) * 3600 * 1000;
    return timestamp - serverOffsetInHours;
  });
};

/**
 * Filter test result fields
 */
export const filterTestResultFields = fields => {
  if (!(Array.isArray(fields) && fields.length)) return [];

  const newFields = [...fields].filter(field => {
    return !(
      field?.refName === SYSTEM_FIELD_ORDER_ID ||
      field?.refName === SYSTEM_FIELD_LATEST_RESULT ||
      field?.refName === SYSTEM_FIELD_PRIORITY ||
      field?.refName === SYSTEM_FIELD_TAG ||
      field?.refName === SYSTEM_FIELD_ESTIMATEDTIME ||
      field?.refName === SYSTEM_FIELD_TEST_CASE_EXTERNAL_KEY ||
      field?.refName === SYSTEM_FIELD_SCRIPT_PATH ||
      field?.refName === SYSTEM_FIELD_RELEASE ||
      field?.refName === SYSTEM_FIELD_CYCLE ||
      field?.refName === SYSTEM_FIELD_TEST_SUITE
    );
  });

  return newFields;
};
